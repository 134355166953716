import { contents } from '../data';
import { Stats } from '../components/Stats';

export const Contents = () => (
  <div className="section" id="contents">
    <h2>Contents</h2>
    <ul className="contents">
      {contents.map(({ title, chapters}, i) => (
        <li key={`chapterGroup_${i}`}>
          <h3>{title}</h3>
          {chapters.map(({ id, title }, j) => <a href={`#${id}`} key={`chapter_${j}`}>{title}</a>)}
        </li>
      ))}
    </ul>
    <Stats />
  </div>
);
