import { ChapterLink } from '../models';

export const links: {[key: string]: ChapterLink[]} = {
  AbuseOfChildren: [
    {
      description: [
        `<b>Dr. José Luis Gettor on the adverse effects of prolonged mask use in children</b>`,
        `Children, moreover, have an oxygen consumption that goes from two to three and more times that of an adult.`,
        `This is easy. When an adult has 60 beats per minute, a child has 120.`,
        `Why? Because he needs more oxygen; because he has an increased metabolism; because he's growing; because he's developing, and the cell needs more food. The cell's food is oxygen.`,
        `<b>It's indeed harmful to cover a child's face so that he doesn't breathe.</b>`,
        `Some will say to me, "Doctor, but I see many children who breathe with it, and it doesn't hurt them." What type of harm are we talking about?`,
        `We can talk about immediate harm, which I have seen. I've seen them: loss of consciousness, head trauma, seizures, delirium. I have seen this because I attend 20 schools here in San Rafael.`,
        `These are immediate damages, but we can also talk about mid-term damages. Mid-term damage includes depersonalization, lack of facial expression, no communicating with the teacher or classmates. These are mid-term damages which can be seen after a few weeks.`,
        `And there are also long-term damages, which have been studied. There's research done by Margareta Griesz-Brisson, who's a German neurophysiologist on long-term damage in children. Because of this chronic hypoxia. And with the consequence of hypercapnia.`,
        `<b>Hypoxia is the lack of oxygen. Hypercapnia is excess carbon dioxide. Excess carbon dioxide kills neurons.</b>`,
        `And children, as time goes by... Tests have been done, for example, in Asian countries. In China, masks have been used for decades. And it can be seen how children's IQ <b>decreases</b> over time.`,
        `It's important to see who's interested in children not having a high IQ.`,
        `I'll defend until the last day of my life that human beings need oxygen to be able to live.`,
      ],
      urls: [
        `https://www.bitchute.com/video/Lu0181B6aFwo/`
      ],
    },
    {
      description: [
        `“Not seeing someone's facial expressions activate our stress hormones because we can't decode them. It generates anxiety for babies seeing a mother with a neutral face and it impairs their mirror neuron growth, which are essential to form to be able to feel empathy. What do you think a c hild who grows up without empathy will be? Can you imagine being a child today? Looking at faces that don't smile.”`,
      ],
      urls: [
        `https://twitter.com/4taranov20/status/1454858129896706052`,
        `https://twitter.com/4taranov20/status/1454859231492853762`,
      ]
    },
    {
      description: [
        `<strong>Pfizer ad telling children to be trials, telling them they'd be superheros.</strong>`,
      ],
      urls: [
        `https://www.bitchute.com/video/9ZL5p4OXs85l/`,
      ]
    },
    {
      description: [
        `Justin Trudeau, Prime Minister of Canada, telling 4 year old children how excited they should be to take the vaccine.`,
        `"You're gonna be able to get your vaccine as soon as you turn five ... I know you're excited, I know you're eager"`,
        `Justin Trudeau grooming 5 year olds`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1466877465733783557`,
        `https://twitter.com/gddub/status/1466912898513526790`
      ]
    },
    {
      description: [
        `Direct marketing to children is illegal in pretty much every country with civil laws and a moral compass.`,
        `<strong>Justin Trudeau deliberately and knowingly targets children directly, imploring them to ask their parents to get vaccinated.</strong> Disgusting, immoral, and arguably unlawful.`,
        `"We need to get more, so please ask your parents if you can get vaccinated." - Justin Trudeau`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1482405138031689735`,
      ]
    },
    {
      description: [
        `Is using a QR Code vaccine passport innocent? It's not. Look at this video of small children and tell me you're ok with this society.`,
        `Children scanning their QR codes, taking their own temperature, wearing masks, just to enter school.`,
      ],
      urls: [
        `https://twitter.com/canmericanized/status/1492387036782804993`,
      ]
    },
    {
      description: [
        `Children hate masks. Screaming with joy when told they don't have to wear masks anymore.`,
      ],
      urls: [
        `https://twitter.com/Breaking911/status/1492010506537873424`,
      ],
    },
    {
      description: [
        `Mom Confronts Superintendent with Her Own Lies on Mask Rule`,
        `"Unfortunately politics leads us to believe there is only one solution: masks.`,
        `Yet thousands of schools around the country have been open during the entire pandemic without masks, and <strong>no corresponding rise in serious illness.</strong>`,
        `There were no child coffins lined up, as some educators in this county suggested would be the case.`,
        `We are segregating children by vaccination status, and by religious exemption status, <strong>despite the wearing of masks.</strong>`,
        `So I ask you, if masks work, why don't they?"`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=2KuuyI1OWhk`,
      ],
    },
    {
      description: [
        `Man's story of a little girl telling her mom "look mom, no mask", then tells him "I really like your face."`,
        `<strong>"It got to me, about how much damage we're doing to these kids, that it's not even normal for them to see somebody's face."</strong>`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1491231878636097536`
      ],
    },
    {
      description: [
        `<strong>Teacher Speaks Out: The Children Are Not Alright</strong>`,
        `Stacey Lance | Speakeasy VIII w/Kate Wand`,
        `Kids telling teachers, "Miss, I know you can't do anything.. but I can't do this anymore. I can't live like this anymore."`
      ],
      urls: [
        `https://www.youtube.com/watch?v=AKm3rN6PZs8`
      ],
    },
    {
      description: [
        `<strong>Kindergarteners made to mask between bites during silent lunch</strong>`,
        `Queen Anne Elementary in Seattle is forcing kids to eat lunch outside in freezing temperatures. Sadly, that's nothing new. What is new is when they go inside because it is too wet/cold they:`,
        `have their own 'eating spots'`,
        `slide their mask on & off between bites`,
        `'eat silently'`,
      ],
      urls: [
        `https://thepostmillennial.com/kindergarteners-made-to-mask-between-bites-during-silent-lunch/`,
        `https://twitter.com/thehoffather/status/1480570132023939076`,
      ],
    },
    {
      description: [
        `French television show asks kids:`,
        `"What should we do with the people who don't want the vaccine?"`,
        `"We should call the police."`,
        `"YES, YES!"`,
        `"If they don't have the vaccine it can make a lot of people in danger, so like what the government does right now, we should cut everything from them <strong>little by little until they submit and get vaccinated.</strong>"`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=l92Qkh1qmps`,
        `https://www.bitchute.com/video/8q6owhbzxmCX/`,
      ],
    },
    {
      description: [
        `British news program. A commentator who is student reacts to the announcement that Britain is removing masks.`,
        `Her breakdown is what almost ALL students feel about masks.`,
        `"The damage that they've done to my generation has been unbelievable.. what they've done to the rest of our lives, to our education. I sat there in August and literally watched my future crumble in front of my eyes."`,
        `"<strong>This makes me so angry... they've literally ruined our lives, and nobody spoke about it at all</strong>."`,
      ],
      urls: [
        `https://twitter.com/justin_hart/status/1483923977223348224`
      ],
    },    
    {
      description: [
        `<b>NYPD kicks out child from restaurant for not having a vaccine passport.</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1475841496322555906`
      ],
    },
    {
      description: [
        `Jan Crawford:`,
        `<b>"It's the crushing impact that our covid policies have had on young kids and children.</b>`,
        `By far the least serious risk for serious illness, but - I mean, even teenagers. You know, a healthy teenager has a one in a million chance of getting and dying from covid, which is way lower than dying in a car wreck on a road trip.`,
        `But the have suffered and sacrificed the most... and now we have the surgeon general saying there's a mental health crisis among our kids. The risk of suicide attempts among girls now up 51% this year. School closures, lockdowns, cancellation of sports. You couldn't even go on a playground in the DC area without cops scurrying, shooing the kids off.`,
        `<b>Tremendous negative impact on kids, and it's been an afterthought. It's hurt their dreams, their future, learning loss, risk of abuse, their mental health.</b>`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1475498100680650755`
      ],
    },
    {
      description: [
        `Another woman was told that if she did not wear her mask, she would be refused medical assistance. Her partner, viewing her obvious distress, removed her mask at some point, but the staff told him off.`,
        `“I had the same mask during the whole labour; I was unable to breathe properly and therefore unable to push for 12 hours. I suffered a lot and the gynaecologist had to use medical spatulas to help my son out.`,
        `“As a result, my son was born with a lump and with amniotic fluid in his lungs and he was transferred to another hospital. As for me, I have a tear that is still healing and trauma for me and my partner.`,
        `<b>I don't imagine I will ever be able to give birth again.”</b>`,
        `If that is not institutionalized abuse, I do not know what is.`,
      ],
      urls: [
        `https://katewand.substack.com/p/obstetrical-violence`
      ],
    },
    {
      description: [
        `The Post Millennial's Libby Emmons on vaccine requirements for children:`,
        `<b>"We're forcing our children to pay the price for our own fears."</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1468429047328047106`
      ],
    },
    {
      description: [
        `Amina Sherazee Operational Co-Director of CRC`,
        `<b>Defending Children's Rights - Post Press Conference Interview</b>`,
        `"School closures and online learning are ocurring without assessments of the effectiveness of school closures with respect to transmission or infection. Online learning is occurring without any assessment or evidence with respect to the effectiveness and the harms of online learning for children."`
      ],
      urls: [
        `https://www.youtube.com/watch?v=s84fOqRHrTU`
      ],
    },
    {
      description: [
        `Kate Wand speaking aboout the student who got dragged out of his classroom for not wearing a mask.`,
        `<b>Who Will Be The Leaders of Tomorrow? | Harry Wade & Kate Wand</b>`
      ],
      urls: [
        `https://twitter.com/katewand/status/1466897031562158088`,
        `https://www.youtube.com/watch?v=VmdEZyGBclU`,
      ],
    },
    {
      description: [
        `Kids are being offered vaccine incentives in LA schools.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=LwNM2T39RSo`
      ],
    },
    {
      description: [
        `Mother goes for the jugular of the school board over mask mandates.`,
        
      ],
      urls: [
        `https://gettr.com/post/pvzff44d60`
      ],
    },
    {
      description: [
        `<b>“At some point, we all have to say, if not now, when? Our children are watching us, that's what bothers me the most. Our children are watching us sit back and say nothing.'</b>`,
        `It's okay to not be okay with this, it is okay to have questions. It is okay to say my child is not okay.”`,
        `<b>Because pre-pandemic, if you did <i>not</i> do that, you would be labeled as neglectful.`
      ],
      urls: [
        `https://twitter.com/katewand/status/1486450830958075906`
      ],
    },
    {
      description: [
        `It's our job to NOT accommodate their fears. Bret Weinstein's Dark Horse Podcast.`,
        `"You've disordered the development of children, and now you're going to take what they claim as the god's honest truth, and we need to change what we do to accommodate their desires and fears?`,
        `No, it's our job to do the <b>opposite</b>. It's our job to NOT accommodate their fears and to help them undo some of the damage that we have done to them."`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=cfdeaxpTUD8`
      ],
    },
    {
      description: [
        `Justin Trudeau to school children:`,
        `"Every time you wore a mask, every time you stayed safe and stayed home, you were making things better...you were sacrificing for the good of all."`,
        `This is manipulation. No child should have to sacrifice like this.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1517191260783759363`
      ],
    },
    {
      description: [
        `Given we know many professionals, including doctors, have made the case that oxygen deprivation + carbon dioxide overconsumption = brain cell death, as well as the psychological damage in not seeing faces or able to express with their own face, enforcing a mask on a newborn baby should be considered child abuse.`,
        `From the parents:`,
        `We sourced some tiny KF94 style masks from Hong Kong (Savewo 3D bear) & even though they were DAMN CUTE they were a fail because:`,
        `a) her soft baby ears kept folding forward and releasing the ear loop`,
        `b) she kept pulling them off`,
        `c) she kept sucking on the mask from inside`,
      ],
      urls: [
        `https://twitter.com/drgregkelly/status/1536980479219748865`,
        `https://threadreaderapp.com/thread/1536980479219748865.html`,
      ],
    },
    {
      description: [
        `<b>'I would never support a mask mandate now': Five questions with an infectious diseases expert</b>`,
        `In an effort to gain a better understanding of the situation and the calls for a return to mask mandates, I turned to a well-known infectious diseases expert and physician who has worked tirelessly to better inform the public during this challenging era of both viral and social contagion.`,
        `<b>Q: There's a lot of noise out there right now, with that same group of 'Covid zero' activists calling for a return to mask mandates, particularly for kids. Would you support a return to mask mandates this winter?</b>`,
        `A: Definitely not. We have gotten to almost the three-year mark of this pandemic. The whole world has some form of immunity or the other. What is the goal of a mask mandate at this point? On a population level, we are not going to stop or even slow down transmission. The mask mandate of the past has outlived its expiry date. There is no need for one going forward. I would never support a mask mandate now.`,
      ],
      urls: [
        `https://acceptableviews.substack.com/p/i-would-never-support-a-mask-mandate`
      ],
    },
    {
      description: [
        `New pre-print: "Pediatric COVID-19 cases were generally mild. ... Pediatric reinfections rarely led to hospitalization (0.5% vs. 1.3% during primary infections) and none resulted in death."`,
        `--Medic et al. 2022`,
        `Medic et al. Incidence, risk and severity of SARS-CoV-2 reinfections in children and adolescents: a population-level study between March 2020 and July 2022. medRxiv 2022.10.09.22280690; doi:`,
      ],
      urls: [
        `https://twitter.com/DrJBhattacharya/status/1591970162210922496`,
        `https://twitter.com/METRICStanford/status/1591499142043381760`,
        `https://www.medrxiv.org/content/10.1101/2022.10.09.22280690v1`
      ],
    },
    {
      description: [
        `Retired pediatrician:`,
        `"This started in February 2021 when I saw Oxford University advertising on the 6-o-clock news, the region where I worked, and <b>they were advertising for children to join a trial when there was no adult safety data.</b>`,
        `As a pediatrician I have done a lot of research and it is really difficult to get trials past an ethics committee. You have to show that the thing you're treating is common in children and isn't common in adults. You always have to go for adults first.`
      ],
      urls: [
        `https://twitter.com/ladyTbarber1/status/1589180126335758339`
      ],
    },
    {
      description: [
        `<b>"I don't think the kids are OK. Do you?"</b>`,
        `In November 2021, Pfizer released this video while recruiting kids for their next vaccine trial.`,
        `Child speaking: "All of us want to be superheroes, and the most important heroes are those that help others`,
        `This year, thousands of kids like us, around the world joined the COVID 19 vaccine trial.`,
        `To all the kids who volunteered, we'd like to say: Thank you.`,
        `- Brought to you by Pfizer -`,
        `A year later a hostpital runs this ad for treating kids with heart damage..`,
        `Professionals giving testimony.. etc..`,
      ],
      urls: [
        `https://twitter.com/LeeLee317/status/1578032093891698688`
      ],
    },
    {
      description: [
        `<b>Re: adding the mRNA covid injection to a child's vaccination schedule, starting at 6 months old. "What do you think will happen?"</b>`,
        `<b>John O'Looney: Funeral director / Embalmer:</b>`,
        `I think it can only end in one way: civil war.`,
        `And when the masses have caught on, in a land full of guns, it can only end one way.`,
        `It can only end one way, because you've lost your family, you've got nothing else to live for.. At least to my mind, that's the way I look at, if I lost my family, I got nothing to live for. Nothing.`,
        `So, I would be full of deadly rage, and I think that is what is going to happen.`,
        `And it's very interesting, when I met with Sir Graham Brady.. at the end of the meeting it was put to him by more than one person that was there, [high calibre doctors], and it was put to him that <b>when the masses find out what you've done.. what you've done, there'll be civil war.</b>`,
        `<b>And he acknowledged that and he said, the government are preparing. They are preparing. They're actually surprised it hasn't already kicked off. And this was in September 2021.</b>`,
        `So I would suggest, that the way this is going, when the masses do finally catch on, there'll be civil war, I genuinely believe that.. and what a terrible thought. What a terrible thought. I hope these people have got deep bunkers.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1633578529802575874`
      ],
    },
    {
      description: [
        `<b>Dr. Martin Makary: Select Subcommittee Roundtable: Examining COVID Policy Decisions</b>`,
        `The CDC and the FDA and people at the NIH, made up their mind before the trials were completed.`,
        `They decided babies were gonna get vaccines before the study was done, and when it came out it found no statistically significant different in efficacy between the 2 groups, and they just authorized it anyway.`,
        `Why are we even doing trials, why do we even have an FDA, why not just have king Fauci decree that everyone's going to need this?`,
        `And public health excluded anyone who had a different opinion. Ask any one of us that had different opinions than the establishment, if we were invited, ever, to the Public Health Association or university forums.`,
        `We had published collectively, 400 articles on COVID, including peer reviewed articles in JAMA, and yet we were excluded. So of course it looks like there's a consensus. But do you know that 40% of rural pediatricians in America do NOT recommend the COVID vaccine? How would you know that? That's a number that comes from a CDC discussion forum.`,
        `Public health officials and the CDC are proposing that a 12 year old girl get 65 COVID vaccines in her average lifetime. Based on what?`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1631918064244756480`,
        `https://rumble.com/v2bc3bi-select-subcommittee-roundtable-examining-covid-policy-decisions-full-unedit.html`,
      ],
    },
    {
      description: [
        `CDC guidelines for children aged 6 months to 5 years is THREE DOSES of this experimental mRNA shot`,
        `While Pfizer & Moderna are conducting trials for myocarditis`,
        `This is an atrocity`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1616796204288950273`
      ],
    },
    {
      description: [
        `Biden: "We need more money... for childhood vaccines... to plan for a second pandemic. Another pandemic is inevitable. We have to think ahead."`,
      ],
      urls: [
        `https://twitter.com/Xx17965797N/status/1642825908531625989`
      ],
    },
    {
      description: [
        `Korean news item describing damage done to children's language development by masking.`,
        `Once again 180 degree turn by 3 year cheerleader for vaccination and masking.`,
      ],
      urls: [
        `https://twitter.com/ptr12343/status/1605748504889942017`
      ],
    },
    {
      description: [
        `New Zealand parents wanted unvaccinated blood for their baby but were forcibly refused.`,
        `Many unvaccinated people turned up offering matching blood type but of course it was not allowed.`,
        `---`,
        `Jacinda Ardern's government has taken guardianship of baby Will. He will be forced to use mRNA tainted blood during his heart operation, even though the *parents* had hundreds of unvaccinated blood donors ready.`,
        `JUDGE GRANTS TEMPORARY GUARDIANSHIP OF 'BABY WILL'. Auckland High Court, at 18:00hrs and defying all logic and science, Justice Gault granted 'temporary custody' of 'baby Will'.`,
        `---`,
        `NEW ZEALAND- The State owns your children now. The judge has ruled in favour of health authorities & have given them guardianship of a baby boy, who's parents simply wanted unvaccinated blood for a transfusion. Even though the blood was available. State control is absolute.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1601000335127945216`,
        `https://twitter.com/_Janey_J/status/1601000463637565441`,
        `https://twitter.com/_Janey_J/status/1601000580545056769`,
        `https://rumble.com/v1zq1m2-statement-by-cole-and-sam-9th-dec-2022.html`,
        `https://twitter.com/Resist_05/status/1600442248771956738`,
        `https://twitter.com/UnitedFreePres/status/1600385525923610625`,
        `https://twitter.com/BernieSpofforth/status/1600400220558196736`,
      ],
    },
    {
      description: [
        `December 2022 - Pfizer/BioNTech COVID-19 vaccine authorised for use in infants and children aged 6 months to 4 years`,
      ],
      urls: [
        `https://www.gov.uk/government/news/pfizerbiontech-covid-19-vaccine-authorised-for-use-in-infants-and-children-aged-6-months-to-4-years`
      ],
    },
    {
      description: [
        `"I don't know that there has ever been a time in the history of humankind where adults have sacrificed the well-being of the youth for their own protection. At some point, we will all have to answer to a higher authority for this injustice."`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1441928766335459335`
      ],
    },
  ],
  AdverseEffectsGenerally: [
    {
      description: [
        `<strong>Open VAERS data</strong>`,
        `To put it in perspective, scroll down and see the graphs. The amount of reported deaths to VAERS in 2019 (which is generally the same as all previous 30 years) was 605. In 2021 alone, there have been 19,633 vaccine-related deaths reported. (at the time of this writing)`,
      ],
      urls: [
        `https://openvaers.com/covid-data`,
        `https://openvaers.com`,
      ],
    },
    {
      description: [
        `CAERS - Canadian Adverse Event Reporting System`,
        `Not just statistics, but references to dozens of serious side effects.`,
      ],
      urls: [
        `https://www.caers.info/adverse-events-reference-guide`
      ],
    },
    {
      description: [
        `<strong>Toronto's Medical Officer of Health acknowledges myocarditis risk in males,</strong> but then downplays: cites NACI's "rare" characterization; "occurring in 0.01% to &lt;0.1%" of vax recipients".`,
        `That is <strong>in 1,000 to 1 in 10,000!</strong>`,
        `This is unacceptable risk; particularly for healthy young men!`,
        `(For comparison, from calculations in a later point under “Case Number Inflation”, the risk of death <strong>related to covid19</strong> for people in Canada <strong>under the age of 30</strong> is more like <strong>1 in 416,000.</strong>)`,
      ],
      urls: [
        `https://twitter.com/rubiconcapital_/status/1461011938712989708`,
        `https://twitter.com/rubiconcapital_/status/1461012423075373060`,
        `https://twitter.com/rubiconcapital_/status/1461012670472151047`,
      ],
    },
    {
      description: [
        `BMJ Latest editor Dr. Peter Doshi gives evidence at an expert panel on COVID vaccine mandates & injuries.`,
        `Are the “vaccines” safe and effective? See for yourself.`,
        `“the attitude of 'everybody knows _[blank]_' shuts down intellectual curiosity.”`,
      ],
      urls: [
        `https://twitter.com/Ruben_DeHerdt/status/1455976583009873928`,
        `https://twitter.com/Ruben_DeHerdt/status/1455976692976136195`,
        `https://twitter.com/Ruben_DeHerdt/status/1455976816422838272`,
        `https://twitter.com/RubyRLee/status/1455645291483475972`,
      ],
    },
    {
      description: [
      `Alberta lawyer shares Pfizer vaccine Averse Events Report which says 1,223 people are known to have died from the vaccine from Dec 1 until Feb 28, 2021.`,
      ],
      urls: [
      `https://twitter.com/derekjamesfrom/status/1466171575296684034`,
      ],
    },
    {
      description: [
        `Montage of many people who had severe outcomes from the vaccine, though doctors won't acknowledge the connection.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=R6hk2hEnfG8`
      ],
    },
    {
      description: [
        `The Miami Open. Every tennis player, fully vaccinated.`,
        `<b>Fifteen players</b> could not finish the tournament, including two favourites to win the tournament.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1511407209514782722`,
        `https://twitter.com/ChickenGate/status/1511407214279413767`
      ],
    },
    {
      description: [
        `Soccer players / professional athletes / young healthy people collapsing on field, dying, heart conditions, retiring early, hospitalized, etc..`,
        `What's Going On?`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=RhvjXeYEJnU`,
      ],
    },
    {
      description: [
        `Oilers' Archibald diagnosed with myocarditis, out indefinitely.`,
      ],
      urls: [
        `https://www.tsn.ca/josh-archibald-edmonton-oilers-covid-myocarditis-1.1701724`,
      ],
    },
    {
      description: [
        `<b>339 athlete cardiac arrests, serious issues, 193 dead, after covid shot.</b> (Dec 19, 2021)`,
        `Many high school sports players collapsing during playing game.`,
        `"She was in the best shape of her life."`,
      ],
      urls: [
        `https://rumble.com/vr3ben-339-athlete-cardiac-arrests-serious-issues-193-dead-after-covid-shot.html`
      ],
    },
    {
      description: [
        `Matt Le Tissier: <b>"I don't think I saw one person in 17 years coming off the pitch with breathing problems, clutching their heart...</b>`,
        `...footballers, tennis players, basketballers... at some point you have to say this isn't right, this has to be investigated”`,
      ],
      urls: [
        `https://gettr.com/post/przhd28fd0`
      ],
    },
    {
      description: [
        `<strong>Worldwide Heart Attack Deaths among Pro-Footballers in 2021 were 300% higher than the 12-year-average</strong>`,
        `An investigation of available data shows that worldwide professional football / soccer match cardiovascular deaths in 2021 were 300% higher than the 12-year average, with the number of deaths occurring in December 2021 alone equalling the 2009-2020 average.`,
      ],
      urls: [
        `https://dailyexpose.uk/2022/01/15/2021-footballer-deaths-increased-by-300-percent/`
      ],
    },
    {
      description: [
        `Shane Warne, Australian cricket legend, dies aged 52`,
        `<b>Australian icon's management team confirm death Warne understood to have died after heart attack</b>`,
      ],
      urls: [
        `https://www.theguardian.com/sport/2022/mar/04/shane-warne-australian-cricket-legend-dies-aged-52`
      ],
    },
    {
      description: [
        `Top Belgian cyclist dies aged 28 following a heart attack in Spain`,
      ],
      urls: [
        `https://euroweeklynews.com/2022/03/16/cyclist-dies-heart-attack/`
      ],
    },
    {
      description: [
        `Young swimmer Mariasofia Paparo dead from a heart attack`,
      ],
      urls: [
        `https://afipn.com.au/young-swimmer-mariasofia-paparo-dead-from-a-heart-attack/`
      ],
    },
    {
      description: [
        `Sunday foot show AFL edition speak about vaccine injury.`,
      ],
      urls: [
        `https://twitter.com/9Metacus9/status/1512997299274027012`
      ],
    },
    {
      description: [
        `This week on "died suddenly" (there are too many to keep up with):`,
        `Cameron Milton, age 18, UK student and rugby player died suddenly in his sleep.`,
        `Red Og Murphy, age 21, Irish footballer, died suddenly`,
        `Ryan Heffernan, age 12, died from a heart attack while playing on a field at lunch.`,
        `Eshe Symonds,31, died after having chest tightness, while in Jamaica.`,
        `Belgian cyclist Cedric Baekeland, 28, died following a heart attack.`,
        `Thoman Rankin, 20s, UK soccer player, died suddenly.`,
      ],
      urls: [
        `https://threadreaderapp.com/thread/1512143847593320462.html`,
        `https://twitter.com/fynn_fan/status/1512143847593320462`,
        `https://twitter.com/fynn_fan/status/1512143852391616519`,
      ],
    },
    {
      description: [
        `Anti-vax Olympic champion dies from Covid aged 51 after finally agreeing to take jab`,
      ],
      urls: [
        `https://www.mirror.co.uk/sport/other-sports/anti-vax-olympic-champion-dead-26051796`
      ],
    },
    {
      description: [
        `Mountain biker Rab Wardell has died in his sleep aged 37 - two days after winning the Scottish championship.`,
        `He was on The Nine just last night speaking about his win. `,
      ],
      urls: [
        `https://twitter.com/BBCScotNine/status/1562139125662318594`
      ],
    },
    {
      description: [
        `1,330 Athlete Cardiac Arrests, Serious Issues, 905 Dead, After COVID Injection`,
      ],
      urls: [
        `https://goodsciencing.com/covid/athletes-suffer-cardiac-arrest-die-after-covid-shot/`
      ],
    },
    {
      description: [
        `Teen athlete dies from stroke complications on Christmas Day`,
      ],
      urls: [
        `https://kstp.com/kstp-news/local-news/teen-athlete-dies-from-stroke-complications-on-christmas-day/`
      ],
    },
    {
      description: [
        `Another fully vaccinated athlete having another stroke. This time it's Kris Letang.`,
        `We will immediately see people saying he had a pre-existing condition, and had suffered a stroke in 2014. Which is true. But if the jab triggers underlying conditions, that's also a problem…`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1598038170435997696`
      ],
    },
    {
      description: [
        `New Brunswick curler Brannen passes away suddenly at age 47`,
        `Brannen's father, Bob Brannen, also died suddenly this weekend.`,
      ],
      urls: [
        `https://twitter.com/ianrodenppc/status/1573880447041511424`,
        `https://www.tsn.ca/new-brunswick-curler-jamie-brannen-passes-away-1.1853285`
      ],
    },
    {
      description: [
        `NFL Carolina Panthers Defensive End Henry Anderson, 31, Hospitalized for "STROKE"...`,
        `"BLOOD CLOT" in his BRAIN... Numbness in his legs...`,
        `Was placed on reserve/non-Football injury list on October 25th...`,
      ],
      urls: [
        `https://twitter.com/DrJamesOlsson/status/1600614913147371521`
      ],
    },
    {
      description: [
        `Ed Sheeran spoke to Shane Warne shortly before his death.`,
        `This story was hid and the gutless sports commentators in Australia never mention that he died of a heart attack following complaining of chest pains shortly after taking the booster.`,
      ],
      urls: [
        `https://twitter.com/GioCheryl/status/1574333851400568832`
      ],
    },
    {
      description: [
        `“This slide is showing the porportion of breakthrough cases in the province experienced by first nations people. Keep in mind we make up roughly 10% of the population. What we can is that the porportion among breakthrough cases, hospitalizations, icu outcomes and fatalities <strong>remains higher after vaccination.</strong>`,
      ],
      urls: [
        `https://twitter.com/gddub/status/1450844662365818884`,
      ],
    },
    {
      description: [
        `Dr. Malone (creator of mRNA technology) says he suffered serious adverse effects from the vaccine`,
        `"The data are quite clear. Vaccinating after natural immunity has been generated yields many more side effects —the prevalence and the severity —than vaccinating the naive individual. Do not vaccinated the previously infected —full stop." `,
      ],
      urls: [
        `https://twitter.com/VacSafety/status/1457007782977277956`,
      ],
    },
    {
      description: [
        `Ontario doctor resigns over forced vaccines, says 80% of ER patients with mysterious issues had both shots.`,
      ],
      urls: [
        `https://www.lifesitenews.com/news/ontario-er-doctor-resigns-over-mandatory-vaccines-and-falsehoods/`,
      ],
    },
    {
      description: [
        `Canadian Dr. Charles Hoffe explains how he has tested his patients who have received the COVID-19 shots and that 62% of them show blood clotting and permanent damage as a result.`,
      ],
      urls: [
        `https://www.bitchute.com/video/ChQwQBggc8TL/`,
      ],
    },
    {
      description: [
        `"We have substantial data showing that we saw for example, <strong>miscarriages increase over 300% over the five year average almost."</strong>`,
        `"We saw almost 300% increase in cancer, over the five-year average."`,
        `We saw, this one's amazing.. neuological.. <strong>neurological issues which could affect our pilots, over 1,000% increase. A thousand.</strong>"`
      ],
      urls: [
        `https://www.youtube.com/watch?v=ciuRLFLoLL0`
      ],
    },
    {
      description: [
        `Public Health England / NHS: A guide to COVID-19 vaccination`,
        `<strong>All women of childbearing age, those currently pregnant or breastfeeding.</strong>`,
        `The vaccines have not yet been tested in pregnancy, so until more information is available, those who are pregnant should not routinely have this vaccine.`,
        `Non-clinical evidence is required before any clinical studies in pregnancy can start, and before that, it is usual to not recommend routine vaccination during pregnancy.`
      ],
      urls: [
        `https://twitter.com/PadelGirl/status/1493143581150052353/photo/1`,
      ],
    },
    {
      description: [
        `"Hello Pfizer" dozens of women calling out vaccine manufacturers and showing their injuries.`,
        `Real Not Rare, new website for covid19 vaccine injured.`
      ],
      urls: [
        `https://rumble.com/vqjs2d-real-not-rare-new-website-for-covid-19-vaccine-injured.html`
      ],
    },
    {
      description: [
        `Evidence That US Government Targeted Red States With Deadly Batches of Vaccine`,
        `howbadismybatch.com, a database of over 700,000 adverse reactions caused by the Pfizer, Moderna and Janssen vaccines. These vaccines have been deployed in different batches, officially referred to as "lots". This has allowed Former Head of Respiratory Research at Pfizer, Mike Yeadon's team to back-engineer big pharma's covid 19 vaccine deployment agenda in the US. What we learn is quite telling. Around 0.5% of all the batches are highly toxic, resulting in hospitalization, disability and death."`,
        `"Some states are experiencing as much as eleven times more vaccine deaths than other states... Red states received at least twice as much of the lethal batches as blue states did."`,
      ],
      urls: [
        `https://rumble.com/vt0deq-evidence-that-us-government-targeted-red-states-with-deadly-batches-of-vacc.html`,
        `https://howbadismybatch.com`,
      ],
    },
    {
      description: [
        `Perspective of nurse working locally. Publicly says every one of her patients has had covid. Whereas last year it was only 1 in 5. The only thing which she and her colleagues notice changed, is the introduction of the vaccine. “very few unvaccinated”.`,
        `Talks about microclotting, heart attacks, strokes, myocarditis, heart issues, etc.`,
        `Talks about Remdesivere, which is known to cause kidney failure, which is being forced on patients.`,
        `“They're not dying of covid. They're dying from the treatments”.`,
      ],
      urls: [
        `https://twitter.com/Kukicat7/status/1461462838019596297`,
      ],
    },
    {
      description: [
        `Pro-vaccine nurse injured by the Pfizer booster talks about it`,
      ],
      urls: [
        `https://twitter.com/mRNA_Death_Toll/status/1447295807682662402`,
      ],
    },
    {
      description: [
        `"Only the VAERS numbers are statistically significant, but the other numbers are troubling."`,
        `<strong>"Even if the vaccines had 100% protection, it still means we killed two people to save one life."</strong>`
      ],
      urls: [
        `https://twitter.com/vegandad76/status/1490381880511938566`
      ],
    },
    {
      description: [
        `Dr who led the lab of anti-viral drug mechanisms from 1999 - 2001, before going to advanced epigenetics cancer biology program, where they saw clear mechanisms for forming of cancers.`,
        `Doctors telling patients if they get a positive cancer test, to ignore it.`,
      ],
      urls: [
        `https://twitter.com/555SWH/status/1449746163175886863`,
      ],
    },
    {
      description: [
        `Dr Ryan Cole, MD: Covid injections are downregulating a receptor that is critical to keeping cancers in check.`,
      ],
      urls: [
        `https://twitter.com/DrP_MD/status/1463292938436370433`,
      ],
    },
    {
      description: [
        `22 year old calls ambulance, tells dad he had a burning chest, fever and couldn't stand`,
      ],
      urls: [
        `https://twitter.com/HiDearzaki/status/1459779210323677185`,
      ],
    },
    {
      description: [
        `Lady posts photo of her in hospital after giving into the pressure to get vaccinated. Instagram puts a covid warning on it.`,
        `Retweeted by Alberta lawyer representing large groups of firemen fighting for their jobs.`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1455024498403545093/photo/1`,
      ],
    },
    {
      description: [
        `"Tiny fragment of HIV... acts a bit like a clamp" to hold the artifical spike protein in place.`,
      ],
      urls: [
        `https://twitter.com/TheNo1Waffler/status/1490380381455069188`
      ],
    },
    {
      description: [
        `<strong>Israel: The vast majority of the deceased are vaccinated.</strong>`,
        `They have seemingly gone through 'immune erosion'.`,
        `We must gather more data and then decide (what to do).`,
      ],
      urls: [
        `https://twitter.com/daemondave/status/1489940656847921153`
      ],
    },
    {
      description: [
        `<strong>1000 Peer Reviewed Studies Questioning Covid-19 Vaccine Safety</strong>`,
      ],
      urls: [
        `https://www.informedchoiceaustralia.com/post/1000-peer-reviewed-studies-questioning-covid-19-vaccine-safety`
      ],
    },
    {
      description: [
        `Ontario doctor resigns over forced vaccines, says 80% of ER patients with mysterious issues had both shots`,
      ],
      urls: [
        `https://www.lifesitenews.com/news/ontario-er-doctor-resigns-over-mandatory-vaccines-and-falsehoods/`
      ],
    },
    {
      description: [
        `If you have covid and you end up in the hospital, you are put through a rigid protocol and there's high mortality rate in the hosital, and your family is kept in the dark as to what's happening. So what's going on here?`,
        `Number one, the Cares Act is providing bonus payments to hositals whenever you have a diagnosis for covid.`,
        `Number two, the centre for medicare and medicaid services is <strong>waiving patient rights</strong>. This is a deadly combination. So here's what happens:`,
        `You get a payment because you offer a free covid test in the emergency room. You get a boost payment if you have a diagnosis of covid... You get another bonus payment if you are admitted with covid... You get another bonus payment if you're put on Remdesivir... Another bonus payment if you're put on a mechanical ventilator... Another 20% bonus if the diagnosis on your death certificate says covid, even though may not have died from covid... There's bonus payments to coroners.`
      ],
      urls: [
        `https://twitter.com/Chase_2100/status/1487169536264708099`
      ],
    },
    {
      description: [
        `Bayer Board Member says it's gene therapy.`,
        `"Ultimately the mRNA vaccines are an example for that sell of gene therapy.`,
        `I always like to say,<strong> if we had surveyed two years ago in the public, "would you be willing to take gene or cell therapy and inject it into your body", we would have probably had a 95% refusal rate."</strong>`,
      ],
      urls: [
        `https://twitter.com/EMC2_Relativity/status/1474971216767336449`
      ],
    },
    {
      description: [
        `Even the mainstream media starts to tell the truth.`,
        `<strong>"Seven more people in the Atlantic region have died as a result of the vaccine."</strong>`
      ],
      urls: [
        `https://twitter.com/CrzyGmaof3/status/1485131020336697345`
      ],
    },
    {
      description: [
        `Doctor Warns Stillbirths Are Rampant Among Fully Vaccinated Mothers, Launches Investigation`,
      ],
      urls: [
        `https://www.thegatewaypundit.com/2021/12/video-doctor-warns-stillbirths-rampant-among-fully-vaccinated-mothers-launches-investigation/`
      ],
    },
    {
      description: [
        `"Giving young people COVID vaccines will likely cause an “alarming increase in several major neurodegenerative diseases”`,
        `"The particles basically get your cells to produce lots and lots of spike protein in a hurry. <strong>Spike is the most toxic part of a virus.</strong>"`,
        `- Stephanie Seneff, Ph.D. MIT`,
        `<strong>COVID Shots Could Cause "Crippling" Neurodegenerative Disease in Young People, MIT Scientist Warns</strong>"`,
      ],
      urls: [
        `https://twitter.com/Binnur2345/status/1482869362105061377`,
        `https://childrenshealthdefense.org/defender/mit-scientist-stephanie-seneff-neurodegenerative-disease-young-people-covid-shots/`,
      ],
    },
    {
      description: [
        `Army physician and aerospace medicine specialist calls on Pentagon to order all pilots who have received COVID-19 vaccine to be grounded - Sept 2021`,
        `"After I reported to my command my concerns that, in one morning I had to ground three out of three pilots due to vaccine injuries, the next day my patients were cancelled, my charts were pulled for review, and I was told that I would not be seeing acute patients anymore, just healthy pilots for their flight physical."`,
      ],
      urls: [
        `https://twitter.com/BuzzKent1776/status/1477317112607780864`
      ],
    },
    {
      description: [
        `Australian medical whistleblowers speak out about horrific reactions right after vaccines.`,
      ],
      urls: [
        `https://www.bitchute.com/video/k75tzK1XEjht/`
      ],
    },
    {
      description: [
        `<b>The Pfizer mRNA vaccine: pharmacokinetics and toxicity</b>`,
        `Michael Palmer, MD and Sucharit Bhakdi, MD`,
        `We summarize the findings of an animal study which Pfizer submitted to the Japanese health authorities in 2020, and which pertained to the distribution and elimination of a model mRNA vaccine. <b>We show that this study clearly presaged grave risks of blood clotting and other adverse effects.</b>`,
        `The failure to monitor and assess these risks in the subsequent clinical trials, and the grossly negligent review process in conjunction with the emergency use authorizations, have <b>predictably resulted in an unprecedented medical disaster.</b>`
      ],
      urls: [
        `https://d3n8a8pro7vhmx.cloudfront.net/randyhillier/pages/958/attachments/original/1628198186/Pfizer-pharmacokinetics-and-toxicity.pdf`
      ],
    },
    {
      description: [
        `Vaccine "dramatically" increases heart risk, says new study`,
        `"We conclude that the mRNA vaccines dramatically increase inflammation of the endothelium and T-cell infiltration of cardiac muscle and may account for the observations of increased thrombosis, cardiomyopathy, and other vascular events following vaccination."`,
      ],
      urls: [
        `https://www.conservativewoman.co.uk/vaccine-dramatically-increases-heart-risk-says-new-study/`
      ],
    },
    {
      description: [
        `Fauci: "There's another element to safety, and that is if you vaccinate someone, and they make an antibody response, and then they get exposed and infected, <b>does the response that you induce actually enhance the infection and make it worse?"</b>`,
      ],
      urls: [
        `https://twitter.com/PoAmKa/status/1469311384810885124`
      ],
    },
    {
      description: [
        `Fauci: "There are diseases in which you vaccinated someone, they get infected with what you're trying to protect them with, and you actually enhance the infection."`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=77KQMh0E3J8`
      ],
    },
    {
      description: [
        `<b>Cumulative Analysis of Post-authorization Adverse Event Reports</b>`,
        `CUMULATIVE ANALYSIS OF POST-AUTHORIZATION ADVERSE EVENT REPORTS OF PF-07302048 (BNT162B2) RECEIVED THROUGH 28-FEB-2021`,
      ],
      urls: [
        `https://phmpt.org/wp-content/uploads/2021/11/5.3.6-postmarketing-experience.pdf`
      ],
    },
    {
      description: [
        `Dr. Charles Hoffe:`,
        `<b>"I have been horrified to see what the covid shots have done to my own patients."</b>`
      ],
      urls: [
        `https://gettr.com/post/pvjmom9b88`
      ],
    },
    {
      description: [
        `RN: I'm seeing more shingles, herpes rashes...all vaxed.`,
        `Never that many in 1 unit.`,
        `They are sick enough to be admitted, high fever, in severe pain, blood work shows severe infection.they are normally sent home from the E.R. they are supposed to be rare.`,
      ],
      urls: [
        `https://twitter.com/Rockenjud/status/1496212556725293060`
      ],
    },
    {
      description: [
        `Fully Vaccinated account for 7 in every 10 Covid-19 Deaths across Canada according to Government of Canada data`,
      ],
      urls: [
        `https://dailyexpose.uk/2022/02/15/canada-covid-deaths-70-percent-vaccinated/`
      ],
    },
    {
      description: [
        `If your child was killed by a vaccine, would you ever be pro vaxx again`,
        `32 year old - covid 19 vaccination on death certificate`,
      ],
      urls: [
        `https://twitter.com/PamelaE28888080/status/1497064253672472580`
      ],
    },
    {
      description: [
        `CTV News: Father still waiting for Astrozeneca compensation after becoming seriously ill, needing 2 metres of intestine removed.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=HMTYgScCNHw`
      ],
    },
    {
      description: [
        `<b>Really pissed off nurse just realized she's been lied to about the Pfizer injection.</b>`,
        `9 pages of adverse effects.`,
      ],
      urls: [
        `https://www.bitchute.com/video/B6oqK4p24r2x/`
      ],
    },
    {
      description: [
        `<b>The triple vaccinated are the most likely to die from covid19.</b>`,
        `Immunity exists for at least 18 years after infection of sars cov1 and cov2, has long brought durable immunity. Immunity is likely life long.`,
        `The vaccine is not stopping transmissions, it's not stopping infections.`,

      ],
      urls: [
        `https://twitter.com/DrLoupis/status/1500447953328435202`
      ],
    },
    {
      description: [
        `Dr. Robert Malone says the initial batch of Pfizer documents show the “government has been well aware that they [the vaccines] are not fully safe and has hidden this information from us”`,
      ],
      urls: [
        `https://twitter.com/TheRaiderRandy/status/1500538132663463937`
      ],
    },
    {
      description: [
        `<b>9 in 10 COVID Deaths Are in Vaccinated People: Report</b>`,
        `A report released by the UK government has confirmed that 9 out of every 10 deaths related to COVID-19 are found in those who are fully vaccinated. Although the virus variant is the same and the UK approved only one different vaccine (AstraZeneca) from the United States, the data in the U.S. are different. This may be due in large part to the CDC definition used to identify who is “vaccinated.”`,
      ],
      urls: [
        `https://www.theepochtimes.com/9-in-10-covid-deaths-are-in-vaccinated-people-report_4339503.html`,
        `https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1016465/Vaccine_surveillance_report_-_week_36.pdf`,
      ],
    },
    {
      description: [
        `Young guy has stroke after vaccination. Didn't want to get it but felt he couldn't participate in life without it. Thinks it shouldn't be mandated, but should be a personal choice.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=0mzIbl1Wako`
      ],
    },
    {
      description: [
        `They don't want to talk about Covid deaths in Canada anymore because of this chart right here.`,
        `<b>Canada's Pandemic of the Fully Vaccinated | 7 in every 10 Covid-19 Deaths are among the Fully Vaccinated according to official data and Trudeau's Government are trying to cover it up</b>`,
      ],
      urls: [
        `https://twitter.com/WakeAwake1/status/1506305497560887302/photo/1`
      ],
    },
    {
      description: [
        `U.S. Senate expert witness testimony Dr. Peter McCullough states that 95% of American deaths could have been avoided. Autopsy confirms two teenage boys die on days 3 and 4 after the shot, the parents are horrified. This push is resulting in deaths of children.`,
      ],
      urls: [
        `https://twitter.com/LakovosJustice/status/1507570790530752515`
      ],
    },
    {
      description: [
        `Small town doctor sees devastating effects of the vaccine in his small town of 1500 people.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=qMLkrEfkx4A`
      ],
    },
    {
      description: [
        `Dr. Richard Fleming - How The Vaxx is Breaking Down the Body`,
      ],
      urls: [
        `https://www.theburningplatform.com/tag/dr-richard-fleming/`,
        `https://threadreaderapp.com/thread/1504574674021367812.html`,
        `https://twitter.com/GhostOfNakadai/status/1504574674021367812`,
        `https://twitter.com/GhostOfNakadai/status/1504581674587377668`,
        `https://twitter.com/GhostOfNakadai/status/1504584294844579840`,
        `https://twitter.com/GhostOfNakadai/status/1504585203880284164`,
        `https://twitter.com/GhostOfNakadai/status/1504588070590595081`,
        `https://twitter.com/GhostOfNakadai/status/1504589408921604105`,
        `https://twitter.com/GhostOfNakadai/status/1504594797306290179`,
        `https://twitter.com/GhostOfNakadai/status/1504596364432445445`,
      ],
    },
    {
      description: [
        `Miscarriage 50% in vaccinated women.`,
        `Miscarriage 75% in vaccinated women in the 1st trimester.`,
      ],
      urls: [
        `https://twitter.com/themood2020/status/1512004809666306050`
      ],
    },
    {
      description: [
        `We are at a dangerous moment and these mRNA products need to be withdrawn.`,
      ],
      urls: [
        `https://twitter.com/john88354238/status/1514761575131152387`
      ],
    },
    {
      description: [
        `<b>There was an unexpected 40% increase in 'all cause deaths' in 2021</b>`,
        `"US life insurance companiues reported an overwhelming and unexplainable increase in all cause deaths among 18 to 49 year olds"`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=-GXvV6Pybns`
      ],
    },
    {
      description: [
        `American Airline Pilot Bob Snow: "under duress I received the vaccine."`,
        `Forced jab destroyed his life. Had he collapsed 30 minutes earlier it would have destroyed 300 others lives.`,
        `I'm in the ICU in Dallas, this is what the vaccine has done for me. I will probably never fly again, based on the criteria the FAA establishes for pilots.`,
        `I was hoping to teach my daughter to fly, she wants to be a pilot.. that will probably never happen.`,
        `All courtesy of the vaccine.`,
        `This is unacceptable and I am one of the victims.`
      ],
      urls: [
        `https://twitter.com/fynn_fan/status/1516887105145819147`
      ],
    },
    {
      description: [
        `Naomi Wolf's Explosive Reveal On The Pfizer Investigation`,
        `"When everyone was saying 'safe and effective' ..this screenshot [from VAERS] shows 3,385 deaths reported in 3 months..`,
        `Not only did Pfizer know it, but the FDA knew it, they knew they were harming people in the first months of 2021."`,
      ],
      urls: [
        `https://rumble.com/v13bg59-naomi-wolfs-explosive-reveal-on-the-pfizer-investigation.html`
      ],
    },
    {
      description: [
        `B.C. man among first in Canada to receive compensation for vaccine injury`,
      ],
      urls: [
        `https://edmontonjournal.com/news/canada/b-c-man-among-first-in-canada-to-receive-compensation-for-vaccine-injury/wcm/72d8188f-74c3-4735-84c7-323332461f22`
      ],
    },
    {
      description: [
        `<b>Playing Russian Roulette: The NHS Data Tells You All You Need to Know</b>`,
        `<b>Dr. Richard Urso: "... 92% of the deaths in England have been in the triple-vaccinated.</b>`,
        `"I get asked all the time, 'how come I got it and nothing happened?' Well you're basically playing Russian Roulette. You know, there's one bullet in the gun, 5 people pull the trigger and nothing happens. That's all. Simple as that.`,
        `Some people are going to do better than others. You're always going to have problems with foreign proteins going into tissues they're not supposed go into. It's never going to be a strategy.`,
        `And for people who don't know.. <b>92% of the deaths in England have been triple vaccinated, 52 times higher risk of death in kids from 10 to 14</b>.`,
        `Thank god for the National Health Service data that's actually been helpful, our CDC has been a complete fabricated bunch of lies.. <b>giving people data that's completely fabricated, and basically pushing this on 6 month olds. to 5 year olds now in our country.. it's horrible.</b>`,
        `They're the people that are creating this illusion, that they're doing something good. They're not doing something good.`,
      ],
      urls: [
        `https://twitter.com/irqvet/status/1534176442757840896`
      ],
    },
    {
      description: [
        `One of the 1st Canadians to be compensated for an injury that was confirmed to be a direct result of the mRNA products.`,
      ],
      urls: [
        `https://twitter.com/TexasLindsay/status/1535304647904309248`
      ],
    },
    {
      description: [
        `2.9M people in the US now have a disability (ages 16+). Increase in trend occurred: Jan 2021-Jan 2022 `,
      ],
      urls: [
        `https://twitter.com/TexasLindsay/status/1535076107195920386`,
        `https://twitter.com/TexasLindsay/status/1535076107195920386/photo/1`,
      ],
    },
    {
      description: [
        `Pathologist Dr. Ryan Cole:`,
        `"We are literally doing the largest experiment on humanity ever done, not knowing the long term outcomes.`,
        `We only have a year and a half's data. And we have in the VAERS system, 30,000 deaths, well over 1,000,000 adverse reactions. We know that that system highly under-reports..`,
        `We have the EudraVigilance data, we have millions of adverse reactions, we have countless deaths from around the world, and in addition to that we have <b>all-cause death higher now in those who have received these shots, than those who didn't,</b> so we know there are very bad signals from this experimental therapy.`,
        `<b>These shot are all risk, no benefit.</b>`,
      ],
      urls: [
        `https://twitter.com/BLNewsMedia/status/1536044051966922755`,
        `https://rumble.com/v17c2ud-interview-a-lipid-nanoparticle-a-gene-is-a-nuclear-bomb-dr-ryan-cole-md.html`,
        `https://brightlightnews.com/a-lipid-nanoparticle-2/`,
      ],
    },
    {
      description: [
        `British Columbia Dr. Charles Hoffe, MD`,
        `<b>"I have been horrified to see what the covid shots have done to my own patients."</b>`,
        `In April 2021 Dr Hoffe alerted B.C. public health & Bonnie Henry to serious vx injuries in his own practice of 2000 people. 12 were disabled.`,
        `He also discovered the use of the D dimer test to detect blood clotting caused covid vaccines.`,
        `Bonnie Henry silenced him.`,
        `<b>Bonnie Henry knew there was a problem with the vx. They ALL knew.</b>`,
        `Dr Hoffe is a hero.`,
        `Bonnie Henry is a criminal.`,
      ],
      urls: [
        `https://rumble.com/vuysnl-people-are-waking-to-govt-deceit-and-control-dr.-charles-hoffe-md.html`,
        `https://threadreaderapp.com/thread/1538030278228299777.html`,
        `https://twitter.com/howisthismylif/status/1538030278228299777`,
        `https://twitter.com/howisthismylif/status/1538030286562131968`,
        `https://twitter.com/howisthismylif/status/1538030286562131968/photo/1`,
        `https://twitter.com/howisthismylif/status/1538030286562131968/photo/2`,
      ],
    },
    {
      description: [
        `Peer reviewed research conclusively shows a significant and prolonged decline in sperm concentration and motility following the jab.`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1538590221847207937`,
        `https://onlinelibrary.wiley.com/doi/pdf/10.1111/andr.13209`,
        `https://threadreaderapp.com/thread/1538590221847207937.html`,
        `https://threadreaderapp.com/thread/1452342997295771649.html`,
        `https://threadreaderapp.com/thread/1538590221847207937.html`,
      ],
    },
    {
      description: [
        `The Australian government has admitted there have been at least 79,000 side effects from the COVID-19 vaccine and is now offering compensation for those seriously injured.`,
      ],
      urls: [
        `https://twitter.com/Southerncomman1/status/1541447967424630784`
      ],
    },
    {
      description: [
        `'The money is secondary, it's not going to change my life. Nothing is going to get my leg back.'`,
        `Alex Mitchell joins Dan Wootton to discuss the £120,000 payout he received after losing his leg following his AstraZeneca Covid jab.`,
      ],
      urls: [
        `https://twitter.com/GBNEWS/status/1542636041793847297`
      ],
    },
    {
      description: [
        `Dr. William Bay apologises for not speaking out sooner.`,
        `He was afraid of losing his livelihood if he spoke out sooner than he did re the mRNA vaccines.`,
        `It should have been sooner. That would have been the more ethical thing to do.`,
      ],
      urls: [
        `https://twitter.com/haboczki/status/1556417952420925443`
      ],
    },
    {
      description: [
        `On Wednesday, a woman dropped dead at this Saskatoon Shoppers Drug Mart after her booster. A friend of mine witnessed it. Two days later, they are refusing to be honest with people about the potential harms`,
        `"I heard that someone died here two days ago. If I want to get a shot, shouldn't I know these things first?"`,
        `"We're not commenting on that, that's what my manager told us."`,
      ],
      urls: [
        `https://twitter.com/Heart4Truth/status/1571176990694313984`
      ],
    },
    {
      description: [
        `"Experts say more and more younger women are suffering from strokes"`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1595254232940175360`
      ],
    },
    {
      description: [
        `Canada Nurse whistle blower says she's seen:`,
        `- Others forcing COVID vaccine on elderly while they scream "no"`,
        `- Suicide patients after they died were tested for COVID`,
        `- Many dying of heart attacks shortly after getting vaccine`,
        `- Full-term miscarriages 5-days after vaccine`,
      ],
      urls: [
        `https://threadreaderapp.com/convos/1588769481069072384`
      ],
    },
    {
      description: [
        `"Clotting of the arteries just does not happen, this has not been seen in the last 150 years of embalmers' reports.`,
        `These are being seen in the people who died suddenly post covid injection"`,
        `- Dr Chris Shoemaker`,
        `"But there is a very strong pathology group out of Germany which is analyzing all of this, and I've seen their slides, and the slides show this incredible, what they call, lymphosoytidic reaction - lymphosytosis reaction - to the vaccine, this is only to the vaccine that they're looking at.`,
        `The entire lymphatic system, lymphosytosis system of your body, all the lipidtites, they're driven crazy by the presence of what's on this vaccine.`,
        `And they're driven so crazy that they start tearing into the spleen, or tearing into the wall of the aorta, or tearing into the kidney, doing <b>ridiculous thing to multiple organs..</b>`,
        `And the dead people, and believe me, these are <b>dead people</b> that the pathologists are looking at, some of the dead people have horrific lymphocytic changes in their brain. Some, it's the heart. Some, it's their aorta. Some, it's the spleen.`,
        `<b>Some, it's ovaries. Horrific changes in the ovaries and the reproductive systems of women.</b>`,
        `And it's horrible to look at under the microscope.`
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1590054491210256384`,
        `https://twitter.com/_Janey_J/status/1590054667580772354`,
        `https://rumble.com/v1s8le2-world-council-for-health-general-assembly-64.html`,
      ],
    },
    {
      description: [
        `<b>Children are 100X more likely to die after just 1 shot within 8 months (according to UK data)</b>`,
        `This is not a mistake; this is a mass crime against humanity, mass murder and assault`,
        `<b>~ Dr. Mark Trozzi</b>`,
        `"Please do not let your child be poisoned, damaged, have their life shortened, or be killed.`,
        `These fraudulently promoted, dangerous genetic injections alter human cell activity, such that our own cells manufacture, on mass, the most poisonous, and patented, element of the SARS COV 2 virus: the spike protein.`,
        `In addition to poisoning many ACE2 receptor-rich tissues and organs throughout our bodies, this spike protein also causes mass immune system failure.`,
        `It worsens coronavirus infections, poisons many organs such as our brain, heart, and reproductive organs.`,
        `And as bas as all that is, it also causes a massive, pseudo-autoimmune disease.. <b>imagine a child's own immune system attacks their own cells, even their immune system cells, because they have been genetically hacked and are producing the foreign, poison spike protein, which marks them for attack by our own body's immune system.</b>`
      ],
      urls: [
        `https://rumble.com/v1rr2uc-line-in-the-sand-children-war-and-duty.html`,
        `https://twitter.com/_Janey_J/status/1588964562774421505`,
        `https://twitter.com/_Janey_J/status/1588964607640866816`,

      ],
    },
    {
      description: [
        `"It's my duty as a cardiologist to urgently inform every Dr, patient & member of the public that the mRNA product is a likely contributory factor in all unexpected cardiac arrests,heart attacks,strokes,cardiac arrhythmia & heart failure since 2021 until proven otherwise"`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1582595328867729409`
      ],
    },
    {
      description: [
        `Today, we released an analysis on COVID-19 mRNA vaccines the public needs to be aware of. This analysis showed an increased risk of cardiac-related death among men 18-39.`,
      ],
      urls: [
        `https://twitter.com/FLSurgeonGen/status/1578515633159180289`,
        `https://floridahealthcovid19.gov/wp-content/uploads/2022/10/20221007-guidance-mrna-covid19-vaccines-doc.pdf`,
        `https://content.govdelivery.com/accounts/FLDOH/bulletins/3312697`,

      ],
    },
    {
      description: [
        `John suffered adverse reactions from the covid vaccine. He did what GOV insisted & suffered life changing injuries, & then GOV ignored him.`,
        `They lied about the need for healthy to be vaxxed & are now lying about extent of harm`,
      ],
      urls: [
        `https://twitter.com/JamesfWells/status/1579041985222291458`
      ],
    },
    {
      description: [
        `Dr. Roger Hodkinson`,
        `20 Million Dead from the Jab, 2.2 Billion Injuries - Analyst Estimates`,
        `"What I'm about to tell you is intuitively ridiculous. That's why it's so incredibly important that I say this.`,
        `These numbers are best estimates at this time, using government data, for the global consequences of the clot-shot, in terms of death and morbidity, otherwise known as serious adverse events, such as heart attacks, strokes, .. etc.`,
        `We've been focusing, for good reason, on North America's statistics during the last two and a half years, but this has extrapolated that into the total negative effect of the clot-shots, and these are the numbers:`,
        `Global deaths directly attributed to the vaccine: 20 million.`,
        `2 Billion serious adverse reactions of the type I've described.`
      ],
      urls: [
        `https://vigilantfox.substack.com/p/20-million-dead-from-the-jab-22-billion`
      ],
    },
    {
      description: [
        `Dr. Drew changes his tune on Covid Vaccines.`,
      ],
      urls: [
        `https://twitter.com/TheMilkBarTV/status/1579807052884770816`
      ],
    },
    {
      description: [
        `<b>mRNA 'Vaccine' Genocide 2021-2022: Testimonies from the Victims and Medical Staff</b>`,
        `Tells the stories of people around the world who have been injured by the COVID jab.`,
      ],
      urls: [
        `https://www.bitchute.com/video/iZAnOd3LPC5w/`
      ],
    },
    {
      description: [
        `<b>“Pfizer warns that vaccinated men to NOT have intercourses with child-bearing aged women…and if they do…to USE 2 FORMS OF CONTRACEPTION…” -Dr. Naomi Wolf regarding the PFIZER DOCUMENTS that were NOT supposed to see the light of day.</b>`,
        `"..For example, Pfizer warns the men engaged in the study, vaccinated men, not to have intercourse with child-bearing-age women, and if they do, to use two reliable methods of contraception.`,
        `And they define exposure to the vaccine as sexual intercourse, as well as breathing and skin contact, but sexual intercourse especially at the moment of conception.`,
        `<b>So they knew there was risk to fetuses, and to babies, and to embryos through this methodology, this intervention, and they knew it.</b>`,
        `There's also these really creepy focuses in the Pfizer documents on reproduction, on mammalian reproduction, for example they sacrificed male and female vaccinated rats and studied their reproductive tissues. So this is like, not an 'oh my god, we had no idea,' this is like.. it's almost as if this injection was designed to do that.`,
        `And let me tell you other things that emerged from our studies:`,
        `Lipid nanoparticles are designed to go through every membrane in the human body, and pregnant women were told over and over by paid spokespeople, 'your baby can't be affected by the mrna injection, it doesn't cross the placenta,' well.. people have known for 10 years that lipid nanoparticles will cross every membrane, including the placenta. It crosses the blood/brain barrier.. this is why we're seeing damage the testes of little boys and baby boys in utero."`,
        `-Dr. Naomi Wolf`,
      ],
      urls: [
        `https://twitter.com/liz_churchill8/status/1608012358005460992`,
        `https://rumble.com/v21t1em-shocking-vaers-data-reveals-4070-increase-in-miscarriages-naomi-wolf-interv.html`,
      ],
    },
    {
      description: [
        `<b>"Military vaccine mandates are dangerous and deadly and they must stop immediately" ~ Lt. Col. Theresa Long, MD</b>`,
        `"Senator, in 2021 I came before you as a whistleblower reporting catastrophic increases in illnesses, and injuries across the DoD being reported in the DMed.`,
        `These concerns were brushed aside by the DoD as the 'data glitches' in our defense medical surveillance sytem. It was reported that this computer glitch was fixed.`,
        `Per your request, for an update, last night I ran a query in the defence medical epidemioligy database, looking at all illnesses and injuries across the DoD.`,
        `As you can see, the total number of reportable events went from 110,000 in 2020 to over 200,000 in 2022. The vaccine was introduced into the military in Janurary of 2021.`,
        `A reportable event is defined as 'an inherit significant threat to public health and military operations.' A reportable event represents severe life-threatening, clinical manifestations that disrupt military training and deployment.`,
        `These numbers are consistent with the statistically significant rise in vaccine adverse events on service members as provided to me by the CDC, showing 34,000 reports and 119 deaths. Compare this, Senator, to the 93 deaths of service member that were attributed to SARS-COV2 infection.`,
        `<b>Clearly the risk of the vaccine has already outweighed the benefit.</b>`,
        `<b>Military vaccine mandates are dangerous and deadly, and they must stop immediately."</b>`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600940304781127681`
      ],
    },
    {
      description: [
        `Morning news show guest's husband reports on the missing guest:`,
        `Yes, I was on my way here and she called me. She had a violently bad reaction to her last covid shot. And she's really down for the count.`,
      ],
      urls: [
        `https://twitter.com/CKellyUAP/status/1634169949622255617`
      ],
    },
    {
      description: [
        `<b>COVID-19 Vaccines Can Cause "Permanent Disabilities": German Health Minister</b>`,
        `German Minister of Health Karl Lauterbach, who once claimed that COVID-19 vaccination was free of side effects, admitted last week that he was wrong, saying adverse reactions occur at a rate of one in 10,000 doses and can cause “severe disabilities.”`,
        `On Aug. 14, 2021, Lauterbach said on Twitter that the vaccines had “no side effects,” further questioning why some Germans refused to get vaccinated against COVID-19.`,
        `During an interview with German channel ZDF's “Heute Journal” on March 12, Lauterbach was asked by anchor Christian Sievers about the claim he made in the summer of 2021, confronting the health minister with his previous tweet that stated the shots are virtually free of side effects.`,
        `Lauterbach responded that the tweet was “misguided” and an “exaggeration” he made at the time, noting that it “did not represent my true position.”`,
      ],
      urls: [
        `https://www.theepochtimes.com/covid-19-vaccines-can-cause-permanent-disabilities-says-german-health-minister_5129027.html`
      ],
    },
    {
      description: [
        `<b>Penis Injury from the Shot: "It's Very Disturbing"</b>`,
        `- Penile vein thobosis (blood clot) - Mondor's disease`,
        `- Penile neoplasm (malignant lesion)`,
        `- Penile squamous cell carcinoma (cancer) - If it goes undetected, it can spread to the lymph system and then start circulating throughout the body.`,
        `- Penile infection [ulcers, rashes, sore, pain, etc]`,
        `"Amy Kelly: "These harms were known during the [Pfizer] clinical trials, and they were not brought to the attention of people before the vaccines were rolled out."`,
      ],
      urls: [
        `https://twitter.com/TexasLindsay_/status/1633548136093196290`,
        `https://twitter.com/VigilantFox/status/1631014583367286785`,
      ],
    },
    {
      description: [
        `Top Insurance Analyst Finds a 7% Increase In Aggregate Mortality for Each C19 Dose Received`,
        `Josh Stirling: "The more doses ... you have in a [US] region ... the bigger increase in mortality ..."`,
        `"If you're over the age of 50, and you took all 5 doses, that'd be a 35% increase."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1632413781023244292`
      ],
    },
    {
      description: [
        `Vaccine injured lady speaks out. Believed vaccine was the right thing to do.`,
        `Probably had blood clot, but weeks later hadn't heard conclusions from doctors.`,
        `<b>Proceeded to have second injection and had back to back strokes.</b>`,
      ],
      urls: [
        `https://twitter.com/karma44921039/status/1631947803541872643`
      ],
    },
    {
      description: [
        `<b>Autopsies Reveal Medical Atrocities of Genetic Therapies Being Used Against a Respiratory Virus</b>`,
        `Dr. Naomi Wolf: Horrifying Report: Autopsies show the mRNA vaccine SHREDS people from within.`,
        `Conclusion of presenter: "If I were a woman at fertile age, I would not plan motherhood [with] a man who has been vaccinated."`,
      ],
      urls: [
        `https://twitter.com/naomirwolf/status/1631799487453601792`,
        `https://dailyclout.io/report-58-part-2-autopsies-reveal-medical-atrocities-of-genetic-therapies-being-used-against-a-respiratory-virus/`,
      ],
    },
    {
      description: [
        `The Pfizer documents shows the rate of miscarriage of women in their first trimester is around the 80-82% rate.`,
        `“It actually works better than the abortion pill” ~ Dr Elizabeth Mumper`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1630275380547317760`
      ],
    },
    {
      description: [
        `Moderna Shot Causes SIX TIMES More Myocarditis Cases in Men Under 40 Than COVID Itself, According to American Heart Association`,
        `• Second Dose of Moderna - <b>97 additional myocarditis cases per million.</b>`,
        `• COVID-19 Itself - <b>16 additional myocarditis cases per million.</b>`,
        `Jimmy Dore: "So that guy [Stéphane Bancel] is pretending to not know what I know. Now I'm a pothead comedian in my garage, and somehow I know that, but the head of Moderna doesn't know that."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1639701146141958148`
      ],
    },
    {
      description: [
        `CDC Director says "no safety concerns were observed for pregnant women in the third trimester."`,
        `A massive increase in miscarriages and sillbirths in 2021 and 2022 shows she wasn't telling the truth.`
      ],
      urls: [
        `https://twitter.com/TexasLindsay_/status/1629574437736439811`
      ],
    },
    {
      description: [
        `<b>Sudden Dead Doctor Syndrome: 'Unknown' Causes Overtakes Cancer and Heart Disease as the Leading Cause of Death</b>`,
        `Dr. Roger Hodkinson: "Sudden adult death syndrome, of which these physicians are probably examples of, is now the commonest cause of death, at least in Alberta, where I live. That means more people are dying unexpectedly with no cause than are dying from strokes and heart attacks, and diabetes, for example. It's never, ever happened before."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1629520453671813120`
      ],
    },
    {
      description: [
        `<b>Pathologist, Arne Burkhardt: I would not plan motherhood with a vaccinated man</b>`,
        `“If I were a woman in fertile age, I would not plan a motherhood from a man who has been vaccinated” ~ Pathologist Arne Burkhardt, studying mRNA spike proteins being expressed in testes.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1629219651140845570`,
        `https://rumble.com/v290q9s-histopathological-reevaluation-serious-adverse-events-and-deaths-following-.html`,
      ],
    },
    {
      description: [
        `<b>Poker player Aaron Duczak caught on hot mic:</b>`,
        `<b>"I wish I never got the Vaccine. I've been having chest pains ever since I had that thing.”</b>`,
        `They're saying that it hardens arteries.. I need to go and get it checked out. But ever since, dude it's just.. been weird.`,
      ],
      urls: [
        `https://twitter.com/CitizenFreePres/status/1625592660827447296`
      ],
    },
    {
      description: [
        `<b>“Sudden Adult Death Syndrome is now the most common cause of death in Alberta on a death certificate” ~ Dr Roger Hodkinson</b>`,
        `The measures taken to protect the population are now killing the population.`
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1625298102348390400`,
        `https://twitter.com/_Janey_J/status/1625298150918352897`,
        `https://rumble.com/v28wiue-interview-medical-colleges-are-out-of-control-dr.-roger-hodkinson-michael-a.html`,
        `https://brightlightnews.com/interview-medical-colleges-out-of-control-dr-hodkinson-alexander/`,
      ],
    },
    {
      description: [
        `<b>The Most Shocking Testimony I've Seen in the Last 3 Years</b>`,
        `Post-partum nurse: ICU for babies usually has about 50 babies in there. And all of a sudden in March 2021 about 2 months after the vaccines started to roll out, there were 80 babies. So that's a very high number.`,
      ],
      urls: [
        `https://texaslindsay.substack.com/p/the-most-shocking-testimony-ive-seen?r=kp3ob&utm_medium=web`,
        `https://twitter.com/texaslindsay_/status/1614306700533846016`,
      ],
    },
    {
      description: [
        `Bret Weinstein:`,
        `The mRNA vaccines, and this is not true for the DNA version, but for the mRNA vaccines like the Pfizer and Moderna, that after 3 doses they saw a radical elevation in the percentage of the antibodies that are part of this class called IGG4. IG it just means immunoglobulin, it's a synonym for antibody. Now all things in biology are complex and so it has multiple implications, but IGG4 is actually part of a system in which the body attenuates its own response to an antigen.`,
        `So, the mind blowing, and it's an early result maybe it doesn't get replicated, but it was published in Science Immunology top flight journal, the implication is that the evolutionary path we have traveled is <b>causing a pathogen now to be able to trigger a response that will cause the immune system not to fight.</b>`,
        `Nobody knows what happens next. It's a very dangerous discovery.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1624767430705790978`
      ],
    },
    {
      description: [
        `New paper published in BMJ shows Covid vaccine causes 5x more myocarditis than Covid itself (Vax:530 vs Covid:109).`,
        `For young people the risk is 10x higher (Vax:202 vs Covid:19).`,
        `Anyone interested in this, should look into the following large-scale study as well, which concludes: <b>“Covid infection is not associated with myocarditis”</b>`,
        `A large-scale Israeli study of 196,992 unvaccinated adults after Covid infection showed that “Covid infection was not associated with either myocarditis or pericarditis.”`,
        `So we have strong independent evidence that Covid vaccines cause more myocarditis than Covid itself.`,
      ],
      urls: [
        `https://twitter.com/DrEliDavid/status/1622290909369962496`,
        `https://bmjmedicine.bmj.com/content/2/1/e000373`,
        `https://twitter.com/DrEliDavid/status/1589992021561073669`,
      ],
    },
    {
      description: [
        `50 MILLION doses of the bioweapon triggered “false positive” HIV results.`,
        `Right.. “false positive”.`,
        `Professor Trent Munro: University of Queensland: "Everybody who had the vaccine did show some level of false positive results."`,
      ],
      urls: [
        `https://twitter.com/realstewpeters/status/1622255095755702272`
      ],
    },
    {
      description: [
        `“Among men ages 18 to 29 who received the Moderna vaccine, the rate [of myocarditis] was 22.9 per 100,000 doses.”`,
        `Do you know what 100,000÷23 is? 4,347.`,
        `Myocarditis in men aged 18 to 29 who received Moderna is 1 in 4347.`,
        `"Safe and effective."`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1610327814859022336`
      ],
    },
    {
      description: [
        `Project Vertias:`,
        `@Pfizer Director Concerned Over Women's Reproductive Heath After COVID-19 Vaccinations`,
        `"There is something irregular about their menstrual cycles...concerning...The vaccine shouldn't be interfering with that...It has to be affecting something hormonal..."`,
        `“I hope we don't find out that somehow this mRNA lingers in the body and like -- because it has to be affecting something hormonal to impact menstrual cycles.”`,
      ],
      urls: [
        `https://twitter.com/Project_Veritas/status/1621274788734943233`,
        `https://twitter.com/Project_Veritas/status/1621333409422315520`,
      ],
    },
    {
      description: [
        `A new rapidly progressing human version of mad cow disease and subsequent death in 26 patients is directly correlated with the jab in this newly published paper.`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1618098168763420673`,
        `https://threadreaderapp.com/thread/1618098168763420673.html`,
      ],
    },
    {
      description: [
        `<b>Looks like your body can't get rid of spike proteins in your brain and heart. Possibly ever.</b>`,
        `Dr. Peter McCullough:`,
        `Another disturbing finding, there's 2 papers ... demonstrating the vaccines, because they stay in the body so long, it looks like they do permanently install into the human genome.`,
        `The government as they developed these vaccines, they kind of rushed it through the final stages, there was no assurances that these were safe, there was no genotoxicity studies, now to learn in autopsy studies, the spike protein produced from the genetic material is found in the heart. It's found in the brain.`,
        `So I can tell you everyone who's taken the vaccine has this material in their brain, in their heart, in their adrenal glands, reproductive organs.. it's really a terrible thought.. my heart goes out to people taking the vaccine.`,
        `The vaccines, messenger RNA vacines for sure, they've never been demonstrated to actually leave the body. They look like they're permanent. As well as the spike protein that's produced after them, this is very disturbing, so it's hard to guide.. I would say, 30 days refrain from kissing, sexual contact with a vaccinated person, but I'm extending that to at least 90 days and conservatively maybe extend it from a point forward. I know there's married couples and all kinds of personal implications there but this messenger RNA looks like it's for keep..`,
        `<b>Every shot is accumulating in the body with no ability for the body to get rid of it.</b>`,
      ],
      urls: [
        `https://twitter.com/stkirsch/status/1616501901385957376`
      ],
    },
    {
      description: [
        `<b>“Stroke season”.</b>`,
        `I am genuinely curious how a doctor only became aware of this so-called “stroke season” last year… The timing seems awful suspect. `,
        `---`,
        `“I never knew there was a stroke season after flu season, until my bosses & big Pharma told me there is a stroke season after flu season”.`,
        `So am I now allowed asking if people collapsing on air is a stroke? It's the season, after all, right?`
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1616853226258563072`,
        `https://twitter.com/thevivafrei/status/1616825564802826241`,
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan: “In 10 of 108 HCV patient samples, full-length or traces of SARS-CoV-2 spike mRNA vaccine sequences were found in blood up to 28 days after COVID-19 vaccination.”`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1616639679897694209`,
        `https://onlinelibrary.wiley.com/doi/10.1111/apm.13294`,
      ],
    },
    {
      description: [
        `CDC admits mRNA shots can cause myocarditis and blot clots. Also admits natural immunity is real. And concedes that the shots don't prevent infection or spread of the virus.`,
        `Therefore COVID “vaccine” mandates are immoral and unethical even according to govt. End all of them now.`,
      ],
      urls: [
        `https://twitter.com/RepThomasMassie/status/1616249150248452099`
      ],
    },
    {
      description: [
        `BBC News:`,
        `Cardiologist says likely contributory factor to excess cardiovascular deaths is covid mRNA vaccine and roll out should be suspended pending an inquiry.`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1613837487796850688`
      ],
    },
    {
      description: [
        `"The disability that we are going to see due to these vaccines will go down in history as an unbelievable atrocity" ~ Dr Peter McCullough`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1612049480701939713`
      ],
    },
    {
      description: [
        `<b>Rational harm-benefit assessments by age group are required for continued COVID-19 vaccination</b>`,
        `The authors estimate that at least 18.5 serious adverse events could occur for every COVID-19 hospitalization prevented.`,
        `From January 2021 to the time of writing, 1598 athletes suffered cardiac arrest, 1101 of which with deadly outcome.`,
        `Notably, in a 38-years timespan (1966-2004), 1101 athletes under the age of 35 died (~29/years) due to various heart-related conditions, 50% of whom had congenital anatomical heart disease and cardiomyopathies and 10% had atherosclerotic heart disease with early onset.`,
        `According to a study done on 301 teenagers between the ages of 13 and 18 who had received two doses of the Pfizer/BioNTech vaccine, 29.24% of participants experienced cardiovascular complications such tachycardia, palpitations and 2.33% suffered myopericarditis.`,
        `It is noteworthy, that no statistically significant increase in the incidence of myocarditis or pericarditis was observed in un-vaccinated subjects after SARS-CoV-2 infection, in a large population study.`,
        `Since the end of 2021 and throughout 2022, young age excess mortality has substantially increased in many European countries, in concert with the vaccine program`,
      ],
      urls: [
        `https://focusfm.gr/wp-content/uploads/2023/01/Scand-J-Immunol-2022-Polykretis-Rational-harm%E2%80%90benefit-assessments-by-age-group-are-required-for-continued-COVID%E2%80%90191.pdf`
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan:`,
        `Out of all vaccine-related deaths reported to the VAERS database over the last 32 years, 77% of them are associated with the COVID jab in the last 2 years.`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1608968259763834880`,
        `https://twitter.com/ScienceWDrDoug/status/1608968278923423744`,
        `https://twitter.com/ScienceWDrDoug/status/1608970469977522176`,
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan:`,
        `This is a secondary reevaluation of the safety data from the clinical trials. They find a serious adverse event ratio of ~13 per 10,000 doses. This extrapolates to 341,000 Serious Adverse Events in the U.S. They also find potential net harm`,
        `“In both Pfizer and Moderna trials, the largest excess risk occurred amongst the Brighton category of coagulation disorders.”`,
        `In the Moderna trial, the excess risk of serious AESIs (15.1 per 10,000 participants) was higher than the risk reduction for COVID-19 hospitalization relative to the placebo group (6.4 per 10,000 participants).`,
        `In the Pfizer trial, the excess risk of serious AESIs (10.1 per 10,000) was higher than the risk reduction for COVID-19 hospitalization relative to the placebo group (2.3 per 10,000 participants).`,
        `“We found excess risk of serious AESIs to exceed the reduction in COVID-19 hospitalizations in both Pfizer and Moderna trials.”`,
        `In other words, they found that the vaccines contribute more to causing a serious adverse event than they do to prevent a COVID hospitalization.`,
        `Keep in mind that the serious adverse events were calculated based on the cut-off window. Moderna reported SAEs from dose 1 whereas Pfizer limited reporting from dose 1 to 1 month after dose 2.  What about adverse events that occur 2, 3, 4, etc, months afterwards?`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1607457271730028545`,
        `https://www.sciencedirect.com/science/article/pii/S0264410X22010283`,
      ],
    },
    {
      description: [
        `<b>New research shows that the type of antibody radically shifts upon the 3rd dose of mRNA. Potential implication is losing the ability to launch a viable immune response against future infections.</b>`,
        `There are four subtypes of IgG antibodies, labeled IgG1, IgG2, IgG3, and IgG4. By far, IgG1 is the predominant subclass of antibody which is present after an infection. IgG1 antibodies can neutralize an infection, and also help clear an infection.`,
        `In this paper, they show the normal IgG1 response after the first 2 doses, but after the third dose an unusual and sharp increase in IgG4 is observed in almost all vaccinees. An IgG4 response is not normally seen with other vaccines or repeated infections.`,
        `IgG4 is a non-inflammatory antibody, with the ability to bind and neutralize soluble or membrane bound spike protein. However, unlike IgG1 antibodies, IgG4 are terrible at launching effector functions that mobilize cellular clearance of the virus.`,
        `More specifically, IgG4 exhibits  decreased Fc-mediated antibody effector functions including decreased antibody-dependent cellular phagocytosis (ADCP), cellular cytotoxicity (ADCC) and complement deposition (ADCD). These are required to help with viral clearance.`,
        `“In our study, antibody-mediated phagocytic activity and complement deposition were reduced in sera after the third immunization, in parallel to higher proportions of anti-spike IgG4 antibodies.”`,
        `“Since Fc-mediated effector function could be critical for viral clearance, an increase in IgG4 subclasses might result in longer viral persistence in case of infection.”`,
        `“However, it is also conceivable that non-inflammatory Fc-mediated effector functions reduce immunopathology while virus is still being neutralized via high-avidity antibody variable regions.”`,
        `“In a cohort of vaccinees with breakthrough infections, we did not obtain any evidence for an alteration of disease severity, which was mild in almost all of our cases. Larger cohorts with differential disease severities will be needed to address this aspect in the future.”`,
        `“Nevertheless, a Brazilian study during the early phase of the pandemic correlated an early onset and high levels of anti-spike IgG4 antibodies with a more severe COVID-19 progression after SARS-CoV-2 infection, which might indicate a less effective antibody response.”`,
        `“Additionally, Della-Torre et al. reported on a significant association of high IgG4/IgG1 ratios with poor disease outcome.”`,
        `If repeated booster doses further skew the antibody pool towards IgG4, this could translate into an impaired ability to clear future infections.`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1607895172301393921`,
        `https://www.science.org/doi/10.1126/sciimmunol.ade2798`,
      ],
    },
    {
      description: [
        `James Thorp, MD discusses the unprecedented harms he has seen in his practice from the Covid-19 vaccines.`,
        `Unprecedented increase in menstrual abnormalities.`
      ],
      urls: [
        `https://rumble.com/v1zgtmd-ive-seen-an-unprecedented-increase-in-menstrual-abnormalities.-dr.-james-th.html`
      ],
    },
    {
      description: [
        `The U.S. Vaccine Adverse Events Reporting System VAERS gutted its safety data by removing crucial foreign data, and Dr. Jessica Rose helped discover a troubling and under-reported finding:`,
        `mRNA-injection-induced myocarditis is happening in all ages.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1601897106075164679`,
        `https://brightlightnews.com/interview-vaers-adverse-events-data-gutted-dr-jessica-rose/`,
        `https://rumble.com/v2022dg-interview-vaers-adverse-events-data-gutted-dr.-jessica-rose.html`,
      ],
    },
    {
      description: [
        `Canadian woman from Saskatchewan tells her long, terrifying, and agonizing Pfizer story.`,
      ],
      urls: [
        `https://rumble.com/v1zpesy-canadian-woman-from-saskatchewan-tells-her-long-terrifying-and-agonizing-pf.html`
      ],
    },
    {
      description: [
        `FOIA reveals that as early as August 2020, the CDC was expecting up to 1,000 VAERS reports **per day** for COVID Vaccines "with up to 40% of the reports serious in nature." This represents a 600% increase with 8 times the rate of serious reports.`,
        `In late August 2020, the CDC contracted with General Dynamics to handle VAERS reports for COVID-19 vaccines. The contract anticipated up to 1,000 reports per day, with up to 40% of them serious in nature. The value of the year-long contract was $9.45 million.`,
        `This means that months before the EUA of any COVID vaccines, the CDC anticipated up to a 600% increase over the average annual number of VAERS reports in recent years with 8 times the rate of serious reports.`,
        `How could the CDC be so confident that any of the vaccines would reach the FDA's 50% efficacy target or pass safety standards? It seems the impending emergency use authorization of at least one COVID-19 vaccine was already a foregone conclusion in August, 2020.`,
        `In early March 2021, the contract was amended to process an estimated 115,000 backlogged reports received up to Feb. 28, with an increased capacity to 25,000 reports per week.  The plan was to clear the backlog within 6 months. These overruns cost an additional $21.5 million.`,
        `Another contract was signed with Eagle Health Analytics for similar work from July 2021 to Jan 2022 for nearly $6 million. An amendment in late October increased hours for VAERS support from 16K to nearly 20K hours in the wake of booster & workplace mandates in the fall of 2021.`,
        `All told, we are looking at outlays of at least $45 million over 2 years for VAERS processing & analysis, described in the contract as a "nationally critical function." So the next time you hear somebody say VAERS is worthless, ask them why the government pays so much for it.`,
      ],
      urls: [
        `https://twitter.com/joshg99/status/1601639594264760321`,
        `https://jackanapes.substack.com/p/foiad-contracts-show-cdc-expected`,
      ],
    },
    {
      description: [
        `<b>NIH publication: SARS-CoV-2 Vaccination and Myocarditis in a Nordic Cohort Study of 23 Million Residents</b>`,
        `Findings:`,
        `In a cohort study of 23.1 million residents across 4 Nordic countries, risk of myocarditis after the first and second doses of SARS-CoV-2 mRNA vaccines was highest in young males aged 16 to 24 years after the second dose. For young males receiving 2 doses of the same vaccine, data were compatible with between 4 and 7 excess events in 28 days per 100,000 vaccinees after second-dose BNT162b2, and between 9 and 28 per 100,000 vaccinees after second-dose mRNA-1273.`,
        `Meaning:`,
        `<b>The risk of myocarditis in this large cohort study was highest in young males after the second SARS-CoV-2 vaccine dose, and this risk should be balanced against the benefits of protecting against severe COVID-19 disease.</b>`,
      ],
      urls: [
        `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9021987/`
      ],
    },
    {
      description: [
        `Vaccine injured man: I'm absolutely disgusted, it's horrendous how they treated human beings that were told to do the "right thing".`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600233433548017665`
      ],
    },
    {
      description: [
        `<b>Sperm Is Replaced Completely By Spike Protein In “Vaccinated”</b>`,
        `"If I were a woman in fertile age, I would not plan a motherhood from a man who was vaccinated."`,
        `Dr. Burkhardt says “I think these pictures are very disturbing, very disturbing.”`,
      ],
      urls: [
        `https://twitter.com/Xx17965797N/status/1644760959670534145`
      ],
    },
    {
      description: [
        `Dr. Ryan Cole: Pathologist and Virology Expert`,
        `That spike protein, literally causing the lymphocytes to chew a hole in the aorta. This is the biggest blood vessel in your body, coming off your heart. When that ruptures, you're gone in minutes`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1599830644678238208`
      ],
    },
    {
      description: [
        `Vaccine victims memorial: Glasgow, Argyle Street`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1599465259550445568`
      ],
    },
    {
      description: [
        `Using Massachusetts state death certificates, John Beaudoin was able to match unexpected deaths to corresponding VAERS reports uncovering when each deceased individual received a COVID-19 vaccine.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1599376417778647040`
      ],
    },
    {
      description: [
        `“Pfizer warns that vaccinated men to NOT have intercourses with child-bearing aged women…and if they do…to USE 2 FORMS OF CONTRACEPTION…”`,
        `-Dr. Naomi Wolf with @ManInAmericaUS regarding the PFIZER DOCUMENTS that were NOT supposed to see the light of day.`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1608012358005460992`
      ],
    },
    {
      description: [
        `Airline Pilot, Josh Joder, discussing vaccine injured pilots.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1598062766400892928`
      ],
    },
    {
      description: [
        `Actress Sharon Stone insisted everyone on her set was Covid vaccinated, but has recently shared about the passing of her brother that #diedsuddenly age 57 -Feb 23`,
        `#diedsuddenly 11month old nephew organ failure - Aug 21`,
        `#strokes Sharon's mother suffered 2 strokes - Nov 21`,
      ],
      urls: [
        `https://twitter.com/DVATW/status/1625983387398815746`
      ],
    },
    {
      description: [
        `The Elephant in the Cockpit`,
        `Pilot Reveals Worrying Trend Caused by Vaccines`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1598059884054204418`
      ],
    },
    {
      description: [
        `Dr. Peter A. McCullough, MD`,
        `Skidmore BMJ valid survey of Americans finds estimate of C19 vaccine deaths lands at 289K by December 2021.  Cohesive with Columbia study by Pantazatos and Seligman.  Biological product safety disaster must end with vaccine withdrawal.`,
      ],
      urls: [
        `https://twitter.com/P_McCulloughMD/status/1617899364705525763`
      ],
    },
    {
      description: [
        `"It's Horrific" Embalmer in Canada speaks out at The National Citizen's Inquiry in Toronto`,
      ],
      urls: [
        `https://twitter.com/gddub/status/1645730718469828613`,
        `https://www.youtube.com/watch?v=aqZkCibeIx4`,
      ],
    },
    {
      description: [
        ` It appears VERY possible that Pfizer & Moderna vaccines may be altering the human genome. Dr. Peter McCullough found & shared this study that demonstrates one of our greatest fears - mRNA may well be altering the human genome. This is terrifying in light of what we are finding about #mRNA vaccines in our food supply.`,
        `Peter A. McCullough, MD: McKernan et al, Pfizer vaccine "DNA was deeply sequenced using two different methods" Worrisome levels of reverse coded DNA plasmids in Pfizer potentially could insert into human genome.  Needs investigation.`,
        `<b>Sequencing of bivalent Moderna and Pfizer mRNA vaccines reveals nanogram to microgram quantities of expression vector dsDNA per dose</b>`,
      ],
      urls: [
        `https://twitter.com/RenzTom/status/1646309859816603648`,
        `https://twitter.com/P_McCulloughMD/status/1646305001428402178`,
        `https://osf.io/b9t7m/`,
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan:`,
        `New paper demonstrates that exposure to Spike protein causes the destruction of synapses in the brain, neuro-inflammation, memory loss, and cognitive dysfunction 30-45 days after exposure. This neurotoxicity of the Spike protein appears to be mediated by TLR4, and is therefore a byproduct of the immune response to Spike.`,
        `---`,
        `A new rapidly progressing human version of mad cow disease and subsequent death in 26 patients is directly correlated with the jab in this newly published paper. `,
        `He died 3 weeks after the jab. “Surprisingly, only spike protein but no nucleocapsid protein could be detected within the foci of inflammation in both the brain and the heart, particularly in the endothelial cells of small blood vessels.” `,
        `The spike S1 subunit induces sickness behavior, and induces a subacute (24h) and chronic (7d) neuroinflammatory response. S1 directly induces a proinflammatory response in primary microglia (macrophages in Brain). S1 activates TLR2 and TLR4 receptors. `,
        `In this study, 100% of people with MS who received the mRNA vaccine developed CNS demyelination of the optic nerve, brain, and/or spinal cord. This study was only in 7 patients, but 100% developed neurological symptoms within 1-21 days of the shot.  `,
        `“The 2nd RBD region of interest potentially allows spike to bind to amyloid-forming heparin-binding proteins, which could lead to accelerated agg. of amyloid proteins within the brain. This supports Classen’s concern that C-19 vaccines could potentially induce prion disease.”`,
        `And in this study in mice, they demonstrate a “novel pathogenic mechanism for the COVID-19-associated neurological symptoms that involves glia activation and non-cell autonomous hippocampal neuronal death by the brain-infiltrating S1 protein.”`,
        `And we know that S1 protein crosses blood brain barrier via adsorptive mediated transcytosis in mice and is taken up into the parenchymal brain space. `,
        `New research paper shows that Spike protein activates neuro-inflammation via different receptors. Full length Spike releases pro-inflammatory IL-1b, CXCL8, IL-6 & MMP-9 from microglia via TLR4. The RBD portion of Spike releases TNF-α, IL-18 and S100B via ACE2 signaling.`,
        `The long term neurotoxic/neurodegenerative effects of the Spike protein was not considered in vaccine design or safety studies. There is a growing body of data showing that the Spike protein may cause neurotoxicity, fibril formation, and prion-like disease.`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1647082019501879298`,
        `https://www.arca.fiocruz.br/bitstream/handle/icict/57715/LucianeLeon_FabriciaDantas_etal_IOC_2023.pdf`,
        `https://twitter.com/ScienceWDrDoug/status/1618098168763420673`,
        `https://twitter.com/ScienceWDrDoug/status/1576635747826040832`,
        `https://twitter.com/ScienceWDrDoug/status/1471592331627352075`,
        `https://twitter.com/ScienceWDrDoug/status/1437946866071658504`,
        `https://twitter.com/ScienceWDrDoug/status/1465875285669302276`,
        `https://twitter.com/ScienceWDrDoug/status/1598785321172406291`,
        `https://twitter.com/ScienceWDrDoug/status/1440883050829783040`,
        `https://twitter.com/ScienceWDrDoug/status/1615133496502099969`,
        `https://twitter.com/ScienceWDrDoug/status/1598785227656253441`,
      ],
    },
  ],
  AdverseEffectsInChildren: [
    {
      description: ['Dr. David Martin PhD: <strong>“We are on the precipice of the mass murder of 5-11 year-olds.”</strong>'],
      urls: ['https://twitter.com/Dr_RohenKapur/status/1459222563654184961']
    },
    {
      description: [
        `Dr. David Martin - Follow the Patents, Then You Will Understand Covid`,
      ],
      urls: [`https://rumble.com/vov60h-dr.-david-martin-follow-the-patents-then-you-will-understand-covid.html`],
    },     
    {
      description: [
        `Alberta laywer points out that ZERO (0) children 5-11 have died of covid 19 in Alberta.`,
        `Statistically they are not at risk at all from covid 19, and therefore pushing vaccines on children is unnecessary at best.`,
      ],
      urls: [`https://twitter.com/derekjamesfrom/status/1463373665865662468`],
    },
    {
      description: [
        `Dr Peter McCullough:`,
        `<b>"I can tell you there's heart damage occurring now in children below the age of puberty. We've never seen this before."</b>`,
      ],
      urls: [
        `https://gettr.com/post/pvzvese45e`
      ],
    },
    {
      description: [
        `Texas Father Who Lost 16-Year-Old Son to the Pfizer Vaccine`,
        `"My government lied to me"`,
      ],
      urls: [`https://twitter.com/TheChiefNerd/status/1455743667927502853`],
    },     
    {
      description: [
        `Examples of myocarditis (imflammation of the heart) in children. Including VAERS (Vaccine Adverse Event Reporting System) report #`,
        `Anomalous cases of stillbirths in Ontario and BC (86 stillbirths in 6 months from fully vaccinated mothers, ON. 13 stillbirths in 24 hours in BC).`,
      ],
      urls: [`https://twitter.com/WasdalElaine/status/1463582918949937152`],
    },     
    {
      description: [
        `January-June 2021 there were 86 stillbirths when the usual number is 5-6.`,
        `13 stillbirths in a 24 hour period in BC.`,
      ],
      urls: [`https://twitter.com/randyhillier/status/1461167248371036162`],
    },     
    {
      description: [
        `Ontario Health Minister doesn't address excessive numbers of stillbirths in vaccinated women. `,
        `86 stillbirths in ~6 months, when there should only been ~3 stillbirths.`,
      ],
      urls: [`https://twitter.com/ChickenGate/status/1466551406874087427`],
    },     
    {
      description: [
        `George Watts Jr died of "covid 19 vaccine-related myocarditis" from the Pfizer injection, all because he wanted to take class in person but they wouldn't let him without it.`,
      ],
      urls: [`https://gettr.com/post/psvt0s7a25`],
    },     
    {
      description: [
        `Dr. David Martin interviewed by Dr. Reiner Fuellmich. German corona investigative.`,
        `Any other of Dr. Martin or Dr. Fuellmich's videos and/or interviews are valuable as well.`,
      ],
      urls: [`https://www.bitchute.com/video/Fyoh6Px2bRuv/`],
    },     
    {
      description: [
        `Video collage of all the children already subjected to the experimental vaccines.`,
      ],
      urls: [`https://www.bitchute.com/video/rmOQMfij1Pkb/`],
    },     
    {
      description: [
        `Pfizer lawsuit regarding fraudulent and misleading data in their 5-11 year old trials.`,
        `Pfizer claims there were 0 myocarditis deaths,but simultaneously presents a table showing 53-106 myocarditis cases, 29-58 myocarditis hospitalizations, and 17-34 myocarditis related ICU admissions.`,
      ],
      urls: [`https://twitter.com/ArmstrongKarla2/status/1460798430528167939/photo/1`],
    },     
    {
      description: [
        `Scientific paper: Spontaneous Abortions and Policies on COVID-19 mRNA Vaccine Use During Pregnancy`,
      ],
      urls: [`https://cf5e727d-d02d-4d71-89ff-9fe2d3ad957f.filesusr.com/ugd/adf864_2bd97450072f4364a65e5cf1d7384dd4.pdf`],
    },     
    {
      description: [
        `Researchers Call for Halt on COVID-19 Vaccines for Pregnant Women After Re-analysis of CDC Study`,
      ],
      urls: [`https://www.theepochtimes.com/researchers-call-for-halt-on-covid-19-vaccines-for-pregnant-women-after-re-analysis-of-cdc-study_4081606.html`],
    },     
    {
      description: [
      `Is hospital protocol killing covid patients?`,
      `Dr. Bryan Ardis talks about misconceptions, and dangers of current treatments, like Rimdesivere, which shuts down the kidneys, shows how Ivermectin is on an official list of tratments for c19, among other hospital protocols which could have killed many people.`,
      ],
      urls: [`https://www.bitchute.com/video/3q9xaLOuiSww/`],
    },     
    {
      description: [
        `ER physician sounds alarm bells on Pfizer's vaccine being approved for children`,
      ],
      urls: [`https://rumble.com/vhfbmh-experienced-er-physician-sounds-alarm-bells-on-pfizers-vaccine-being-approv.html`],
    },     
    {
      description: [
        `Child has seizure. Covid misinformation warning.`,
      ],
      urls: [`https://www.bitchute.com/video/hWTrbxMqQxfC/`],
    },     
    {
      description: [
        `Normalisation of anomalous current events re: kids having strokes.`,
      ],
      urls: [`https://twitter.com/adinski/status/1456078410955042820/photo/1`],
    },     
    {
      description: [
        `<strong>Pfizer ad telling children to be trials, telling them they'd be superheros.</strong>`,
      ],
      urls: [`https://www.bitchute.com/video/9ZL5p4OXs85l/`],
    },     
    {
      description: [
        `Justin Trudeau, Prime Minister of Canada, telling 4 year old children how excited they should be to take the vaccine.`,
      ],
      urls: [`https://twitter.com/TPostMillennial/status/1466877465733783557`],
    },     
    {
      description: [
        `MRNA technology inventor Dr. Robert Malone:`,
        `"There are three key issues that parents need to understand.`,
        `The first is that a viral gene will be injected into your children's cells. This gene forces your child's body to make toxic spike proteins. These proteins can cause permanent damage in children's critical organs, including their brain and nervous system, their heart and blood vessels including blood clots, their reproductive system, and this vaccine can trigger fundamental changes to their immune system.`,
        `The most alarming part about this is once these damages occur, they are irrepairable. You can't fix lesions in their brains, you can't repair heart tissue scarring, you can't repair a genetically reset immune system, and this vaccine can cause reproductive damage that could affect future generations of your family.`,
        `The second thing which you need to know about this, is the fact that this novel technology has not been adequately tested. We typically need at least five years of testing and research before we can really understand the risks of new medicines and vaccines. Harms and risks from new medicines often become revealed many years later. So ask yourself, if you want your own child to be part of the most radical medical experiment in human history.`,
        `One final point, the reason they're giving you to vaccinate your child is a lie. Your children represent no danger to their parents or grandparents. It's actually the opposite. Their immunity after getting covid, is critical to save your family, if not the world, from this disease.`,
        `In summary, there is not benefit for your children or your family to be vaccinating your children aaginst the small risks of this virus. Given the known health risks of the vaccine, that is apparent, you and your children may have to live with for the rest of their lives.`,
        `<strong>The risk/benefit analysis for this vaccine isn't even close for children.</strong>"`,
      ],
      urls: [`https://www.bitchute.com/video/kbSwlR9UbxTU/`],
    },
    {
      description: [
        `Louisiana Nurse (RN) with 17 years experience:`,
        `"We have had more children die from the covid vaccine than of covid itself."`,
        `"I am extremely concerned with the idea of mandating this vaccine for our children."`,
      ],
      urls: [`https://www.bitchute.com/video/mGqjRvYsBhyl/`],
    },
    {
      description: [
        `Devastated parents warn how the covid shots killed or crippled their children`,
      ],
      urls: [`https://www.bitchute.com/video/fK3i0JRZE3RL/`],
    },
    {
      description: [
        `"The emergency use authorization vaccines have liability protection under the prep act, and the cares act. So as long as you take an emergency use, you can't sue them.`,
        `Once they get approved, Now you can sue them. Unless.. they can get it recommended for children. Because all vaccines that are officially recommended for children get liability protection.`,
        `<strong>That's why their going after kids. They know this is going to kill and injure a huge number of children, but they need to do it for the liability protection.</strong>" RFK Jr.`,
      ],
      urls: [`https://twitter.com/more_unicorns_g/status/1475466577340342276`],
    },
    {
      description: [
        `Petition against Covid vaccination in children: Georgiu, Perronne, Yim, Ochs testify at the Luxembourg parliament`,
        `"You write in your petition that there is no justification to use Genetic Therapy vaccines on children, because according to you the risk of the vaccines is probably, or possibly greater than covid illness in children."`,
        `"These vaccines use new technology and are still in Phase 3 clinical trials. Healthy children run a low covid 19 risk, but are confronted with known and unknown risks from covid 19 vaccines."`,
      ],
      urls: [`https://odysee.com/luxembourg:1`],
    },
    {
      description: [
        `"...brown circles around his eyes. They sent him home, and in the morning his mother found him dead on the floor beside his bed.`,
        `<strong>And all he wanted to do was play hockey.</strong>`,
        `So I'm wondering, just how safe this is, and why not deaths are being reported.."`
      ],
      urls: [
        `https://twitter.com/Liberal_Parent/status/1484597144308924421`
      ],
    },
    {
      description: [
        `Top cardiologist & Covid specialist Dr. Peter McCullough:`,
        `Myocarditis in children post vaccine is far more dangerous than anything covid induced.`,
        `And the vaccine is directly there, now there's preclinical studies suggesting the lipid nanoparticles actually <b>go right into the heart, the heart expresses the spike protein, the body attacks the heart</b>, there are dramatic EKG changes.`,
        `<b>When the kids get myocarditis after the vaccine, 90% have to be hospitalized, they have dramatic EKG changes, chest pain, early heart failure...</b>`
      ],
      urls: [
        `https://twitter.com/flexgirl9/status/1467237585571307526`
      ],
    },
    {
      description: [
        `Vaccinated children faced breakthrough infections.`,
        `So they're put in danger from the shot, were not highly susceptible to the virus in the first place, but are now getting infected? Could it be because we know the shot has compromised their immune systems already?`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=EnpsiOzM1lY`
      ],
    },
    {
      description: [
        `My 17 year old son is back in the hospital with heart problems that started when he was vaccinated last summer.`,
        `I've never regretted anything more than encouraging him to get vaccinated.`,
      ],
      urls: [
        `https://twitter.com/menard_ray/status/1503447076125884418`
      ],
    },
    {
      description: [
        `Babies refusing breast milk of vaccinated mothers.`,
        `Dr Carrie Madej.`,
        `“Mother's breastmilk of a double vacc¡nated mother was tested after the baby was failing to thrive. The test showed the breast milk to be 80% Inorganic and the toxicity levels 8 out of 10.”`,
      ],
      urls: [
        `https://twitter.com/TheNo1Waffler/status/1510941747815325701`
      ],
    },
    {
      description: [
        `<b>British children up to 52 times more likely to die following a COVID shot: gov't report.</b>`,
        `“Data from Britain's Office for National Statistics show a stark increase in deaths among children both single- and double-jabbed compared to their un-jabbed counterparts.”`,
      ],
      urls: [
        `https://www.lifesitenews.com/news/children-in-britain-up-to-52-times-more-likely-to-die-following-a-covid-shot-report-finds/`,
        `https://twitter.com/KLVeritas/status/1516082627819839488`,
      ],
    },
    {
      description: [
        `CDC issues alert for rare liver damage in children.`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1517288934530850816`,
        `https://www.nbcnews.com/health/health-news/cdc-issues-alert-rare-liver-damage-children-rcna25106`,
      ],
    },
    {
      description: [
        `The human genome has been poisoned`,
      ],
      urls: [
        `https://twitter.com/Octomom4/status/1536142652118388736`
      ],
    },
    {
      description: [
        `17 month old baby in the Vax trial had a seizure after the first dose, a rash and a 104+ temp...and ANOTHER seizure.`,
        `They still gave her Dose #2`,
        `"She went on to stay in the study and receive a second dose without subsequent seizure."`,
      ],
      urls: [
        `https://twitter.com/Bronson76023518/status/1537599300401758210`,
        `https://rumble.com/v18s8bh-horrific-story-of-a-17-month-old-baby-in-vax-trial.html`,
      ],
    },
    {
      description: [
        `Dr. Clare Craig explains why the FDA should NOT have granted approval for roll-out in the 6 month to 4 yr old children cohort`,
        `<b>"This trial should have been deemed null and void. The regulators are failing to do their job."</b>`,
        `"I want to take you through the evidence that Pfizer just presented to the FDA on 6 month to 4 year old children.`,
        `There's an awful lot about this trial that has shocked me and I think will shock you too.`,
        `The trial recruited 4,526 children, age from 6 months to 4 years old. 3,000 of these children did not make it to the end of the trial.`,
        `That is a huge number, two thirds of them.`,
        `Why was there this drop off? That needs to be answered, and without an answer to that, on that basis alone, this trial should be deemed null and void.`,
        `So what did the trial show?`,
        `Well, they defined severe covid as children who had a slightly raised heart rate, or fewer breaths per minute.`,
        `There were 6 children aged 2 to 4 who had severe covid in the vaccine group, but only one in the placebo group. So on that basis, the likelihood that this vaccine is actually causing severed covid is higher than the likelihood that it isn't.`,
        `There was actually one child who was hospitalized in this trial. They had a fever and a seizure. THey had been vaccinated.`,
        `So now let's turn to what the defined as "any covid", and what they did was to utterly twist the data.`,
        `They vaccinated the children and they waited three weeks after the first dose before the second dose. In that three week period, 34 of the vaccinated children got covid, and only 13 in the placebo group, <b>which worked out as a 30% increased chance of catching covid in that three week period if you were vaccinated.</b> So they ignored that data.`,
        `And then there was an eight week gap between the second dose and the third dose, where again, children were getting plenty of covid in the vaccine arm. So they ignored that data.`,
        `There was then seceral weeks after the third dose which they also ignored, which meant that <b>in the end they had ignored 97% if the covid that occurred during the trial</b> and they just looked at tiny numbers - so tiny. <b>In the end they were comparing 3 children in the vaccine arm who had covid with 7 in the placebo arm and they said that this showed the vaccine was effective.</b>`,
        ``,
        `So they measured how many of these children actually managed to catch covid twice in the two-month follow up period. And there were 12 children who had covid twice, and all but once of them were vaccinated. Mostly with three doses.`,
        `So you have to wonder what on earth they're thinking when the claim of reduction in covid was only 4 children and here we have 12 children who got covid twice, 11 of them vaccinated.`,
        `So let's just recap.`,
        `They recruited 4,500 children, 3,000 of them dropped out. And in the end they're claiming this vaccine works on the basis of three covid cases versus 7, a difference of 4 children only.`,
        `And all of this on a backdrop of a disease which doesn't affect children, and with no long-term safety data.`,
        `We have to ask how an ethics committee could have approved this trial in babies. Babies are not at risk from covid.`,
        `And now we have Pfizer, who are presenting this as evidence to the FDA in order to apply for an Emergency Use Authorization.`,
        `Emergency Use Authorization is meant for a situation where there's a risk of serious injury or death.`,
        `Now, children under 5 are not at risk of serious injury or death from covid, in fact in their own trial, they had to make up other ways of measuring the problem, because there was no serious injury or death.`,
        `Now, originally these products were sold as actually also reducing transmission.`,
        `Now it would be completely unethical to use young children as a human shield, but we know know that they don't reduce transmission. The WHO have stopped claiming that they reduce transmission. So that argument doesn't apply either.`,
        `Now, if we just turn to safety, what they did is they followed up patients for 6 weeks before unblinding them and vaccinating them. So the children who'd had placebo, the control group, were followed up for an average fo 6 weeks and then given the vaccine.`,
        `So that's your safety control gone forever.`,
        `The fact that this trial existed at all is unbelievable.`,
        `There are other issues in there which I haven't highlighted, but those are the key ones.`,
        `<b>Parents should be demanding that the decision makers explain themselves.</b>`,
      ],
      urls: [
        `https://twitter.com/hartgroup_org/status/1537458392414969856`,
        `https://twitter.com/hartgroup_org/status/1537458427349303299`,
      ],
    },
    {
      description: [
        `Dad of child with Myocarditis tears pharmacist a new one!`,
      ],
      urls: [
        `https://rumble.com/v1f1j4p-dad-of-child-with-myocarditis-tears-pharmacist-a-new-one.html`
      ],
    },
    {
      description: [
        `<b>Youth Cannot Be Mandated to Get COVID-19 Vaccine, Ontario Court Rules</b>`,
      ],
      urls: [
        `https://www.theepochtimes.com/youth-cannot-be-mandated-to-get-covid-19-vaccine-ontario-court-rules_4670680.html`
      ],
    },
    {
      description: [
        `New preprint from institutions like Oxford, Harvard, & Johns Hopkins. The jab causes net harm in 18-29 year olds.:`,
        `“per COVID-19 hospitalisation prevented in previously uninfected young adults, we anticipate 18 to 98 serious adverse events..” `,
      ],
      urls: [
        `https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4206070`,
        `https://twitter.com/ScienceWDrDoug/status/1570480296662810626/`,
      ],
    },
    {
      description: [
        `U.S. Doctor testifies under oath that mRNA injections are killing children. Two teenage boys die after injection and the parents are horrified. He declares that all mRNA injections should stop immediately and be pulled from the market before more children die.`,
        `"The risks far outweigh any potential benefits of vaccinating children."`,
      ],
      urls: [
        `https://twitter.com/SpartacusJustic/status/1571401998217404416`,
      ],
    },
    {
      description: [
        `First child myocarditis ad I've ever seen`,
      ],
      urls: [
        `https://twitter.com/anish_koka/status/1571186794644873217`
      ],
    },
    {
      description: [
        `- Here's the manipulative ad pushing vaccination on children that the City of Toronto published then deleted today.`,
        `- This video isn't a one-off. It's part of a multi-part campaign. In this video, the City of Toronto suggests you should keep family members from your infant unless the infant is vaccinated.`,
        `- In this edition of the City of Toronto's child vaccination campaign, you have to vaccinate your child so they can leave your house for school while you work from home.`,
        `- In this video, the City of Toronto tells you that unvaccinated children - not government lockdowns - are responsible for having to learn outside the classroom.`,
        `- In this ad, the City of Toronto concedes human connection is better than virtual connections - but of course this is only to tell you that you can get your kids vaccinated from six months of age onward.`,
        `The City of Toronto says the campaign "was intended to highlight the negative impact (the pandemic) has had on children and drive home the benefits of vaccine."`,
        `UPDATE: The City of Toronto has now pulled the whole series of videos off of its YouTube page.`,
        `It wasn't a lack of childhood vaccines that prevented children from going to school, experiencing human connection, and spending time with relatives. Government restrictions did this.`,
        `<b>Toronto's child jab ad campaign is despicable and dishonest</b>`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1572351263748005891`,
        `https://twitter.com/AndrewLawton/status/1572360439484358656`,
        `https://twitter.com/AndrewLawton/status/1572363695803748354`,
        `https://twitter.com/AndrewLawton/status/1572364236554117120`,
        `https://twitter.com/AndrewLawton/status/1572364581946953729`,
        `https://andrewlawton.substack.com/p/torontos-child-jab-ad-campaign-is`,
      ],
    },
    {
      description: [
        `Remember Maddie De Garay. Maddie was only 12 years old when she took part in the pfizer trial 12-15 year olds. The forgotten little girl brushed aside by big pharma and Government.`,
      ],
      urls: [
        `https://twitter.com/Cawthorn12James/status/1573682885663621127`
      ],
    },
    {
      description: [
        `<b>“Breast milk…turned blue-green” -Dr. Naomi Wolf</b>`,
        `"Breast milk of vaccinated women, in the Pfizer documents, four (4) women's breast milk turned blue-green... which the doctor says, could signify a problem with blood.`,
        `And one poor baby who was nursing from a vaccinated mom went into convulsions and died at the ER, in the Pfizer documents.`,
      ],
      urls: [
        `https://twitter.com/liz_churchill1_/status/1574551182315593728`
      ],
    },
    {
      description: [
        `Myocarditis After Vaccines, 54% Youngsters Had Cardiac MRI Abnormalities after 90 Days.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=JQyogVXHhFs`
      ],
    },
    {
      description: [
        `Children's jab centre closed down`,
      ],
      urls: [
        `https://gettr.com/post/p1su43o7544`
      ],
    },
    {
      description: [
        `<b>4070% increase in VAERS miscarriage reports from 2019 to 2021.</b>`,
      ],
      urls: [
        `https://twitter.com/PeterSweden7/status/1590685952930091009/photo/1`
      ],
    },
    {
      description: [
        `New pre-print: "Pediatric COVID-19 cases were generally mild. ... Pediatric reinfections rarely led to hospitalization (0.5% vs. 1.3% during primary infections) and none resulted in death."`,
        `--Medic et al. 2022`,
        `Medic et al. Incidence, risk and severity of SARS-CoV-2 reinfections in children and adolescents: a population-level study between March 2020 and July 2022. medRxiv 2022.10.09.22280690; doi:`,
      ],
      urls: [
        `https://twitter.com/DrJBhattacharya/status/1591970162210922496`,
        `https://twitter.com/METRICStanford/status/1591499142043381760`,
        `https://www.medrxiv.org/content/10.1101/2022.10.09.22280690v1`
      ],
    },
    {
      description: [
        `Moderna/Pfizer's C19-V clinical trials involving children identified elevated rate of Respiratory Syncytial Virus (RSV). FDA's vaccine committee even voiced concerns. RSV now spiking in children. CDC's befuddling advice on RSV is to recommend more C19-V.`,
      ],
      urls: [
        `https://twitter.com/AaronSiriSG/status/1589722232842223616`,
        `https://icandecide.org/press-release/ican-demands-answers-from-the-cdc-about-spike-in-rsv-rates/`,
      ],
    },
    {
      description: [
        `<b>Children are 100X more likely to die after just 1 shot within 8 months (according to UK data)</b>`,
        `This is not a mistake; this is a mass crime against humanity, mass murder and assault`,
        `<b>~ Dr. Mark Trozzi</b>`,
        `"Please do not let your child be poisoned, damaged, have their life shortened, or be killed.`,
        `These fraudulently promoted, dangerous genetic injections alter human cell activity, such that our own cells manufacture, on mass, the most poisonous, and patented, element of the SARS COV 2 virus: the spike protein.`,
        `In addition to poisoning many ACE2 receptor-rich tissues and organs throughout our bodies, this spike protein also causes mass immune system failure.`,
        `It worsens coronavirus infections, poisons many organs such as our brain, heart, and reproductive organs.`,
        `And as bas as all that is, it also causes a massive, pseudo-autoimmune disease.. <b>imagine a child's own immune system attacks their own cells, even their immune system cells, because they have been genetically hacked and are producing the foreign, poison spike protein, which marks them for attack by our own body's immune system.</b>`
      ],
      urls: [
        `https://rumble.com/v1rr2uc-line-in-the-sand-children-war-and-duty.html`,
        `https://twitter.com/_Janey_J/status/1588964562774421505`,
        `https://twitter.com/_Janey_J/status/1588964607640866816`,

      ],
    },
    {
      description: [
        `Unvaccinated Children Are Healthier Than Vaccinated`,
        `— New Evidence by Russell Blaylock, M.D`,
      ],
      urls: [
        `https://twitter.com/amandah32014963/status/1578697618510970880`
      ],
    },
    {
      description: [
        `<b>Dr. Peter McCullough MD: "Babies Are Ingesting mRNA in Milk"</b>`,
        `"If it's in breast milk, it's everywhere in the body. Vaccines were supposed to stay on the arm and cause immunity and be broken down ... and get out of the body. Never should they be circulatory or in breast milk. Never."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1635070455349649411`
      ],
    },
    {
      description: [
        `New paper published in BMJ shows Covid vaccine causes 5x more myocarditis than Covid itself (Vax:530 vs Covid:109).`,
        `For young people the risk is 10x higher (Vax:202 vs Covid:19).`,
        `Anyone interested in this, should look into the following large-scale study as well, which concludes: <b>“Covid infection is not associated with myocarditis”</b>`,
        `A large-scale Israeli study of 196,992 unvaccinated adults after Covid infection showed that “Covid infection was not associated with either myocarditis or pericarditis.”`,
        `So we have strong independent evidence that Covid vaccines cause more myocarditis than Covid itself.`,
      ],
      urls: [
        `https://twitter.com/DrEliDavid/status/1622290909369962496`,
        `https://bmjmedicine.bmj.com/content/2/1/e000373`,
        `https://twitter.com/DrEliDavid/status/1589992021561073669`,
      ],
    },
    {
      description: [
        `CDC guidelines for children aged 6 months to 5 years is THREE DOSES of this experimental mRNA shot`,
        `While Pfizer & Moderna are conducting trials for myocarditis`,
        `This is an atrocity`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1616796204288950273`
      ],
    },
    {
      description: [
        `Sign taped up in pediatrician's office:`,
        `<b>Student Athletes:</b>`,
        `Sports physicals are done primarily to make sure you not at high risk for sudden cardiac death on the playing field.`,
        `<b>Covid vaccination affects your risk.</b>`,
        `In response to worldwide evidence and vaccine adverse-event monitoring, we are adopting a more precautionary sports physical sign-off policy.`,
        `<b>If you have received doses of any covid shot, we will not be able to clear you to compete in sports without performing lab work and possibly an echocardiogram to rule out potential heart damage.</b>`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1612185704162803712`
      ],
    },
    {
      description: [
        `Teen athlete dies from stroke complications on Christmas Day`,
      ],
      urls: [
        `https://kstp.com/kstp-news/local-news/teen-athlete-dies-from-stroke-complications-on-christmas-day/`
      ],
    },
    {
      description: [
        `151 kids who had a cardiac MRI, at 90 days, 81 of them still had damage to their heart and the damage was of late gadolinium enhancement which is associated with sudden cardiac death`,
        `~ Dr. Kirk Milhoan, Pediatric Cardiologist`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1602242384993083392`
      ],
    },
    {
      description: [
        `<b>NIH publication: SARS-CoV-2 Vaccination and Myocarditis in a Nordic Cohort Study of 23 Million Residents</b>`,
        `Findings:`,
        `In a cohort study of 23.1 million residents across 4 Nordic countries, risk of myocarditis after the first and second doses of SARS-CoV-2 mRNA vaccines was highest in young males aged 16 to 24 years after the second dose. For young males receiving 2 doses of the same vaccine, data were compatible with between 4 and 7 excess events in 28 days per 100,000 vaccinees after second-dose BNT162b2, and between 9 and 28 per 100,000 vaccinees after second-dose mRNA-1273.`,
        `Meaning:`,
        `<b>The risk of myocarditis in this large cohort study was highest in young males after the second SARS-CoV-2 vaccine dose, and this risk should be balanced against the benefits of protecting against severe COVID-19 disease.</b>`,
      ],
      urls: [
        `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9021987/`
      ],
    },
    {
      description: [
        `Western University in London Ontario has reversed their vaccine mandates after two  vaccinated students died suddenly.`,
      ],
      urls: [
        `https://twitter.com/AreOhEssEyeEe/status/1600657003059322883`,
        `https://twitter.com/AreOhEssEyeEe/status/1600676725050216448`,
      ],
    },
    {
      description: [
        `New paper makes strong case that COVID booster mandates in young adults (as in many US universities), caused net harm, and must be judged unethical.`,
        `This should not have happened.`,
        `---`,
        `Analysis suggests Covid-19 boosters likely to cause a net clinical harm to young adults(18-29 yr), where total severe adverse events (SAEs) will outweigh Covid hospitalizations averted.`,
        `Booster mandates are unethical because:`,
        `In April, Paul Offit stated in a NEJM editorial:"It is now incumbent on CDC to determine who most benefits from booster dosing & educate the public about the limits of mucosal vaccines"`,
        `CDC/FDA has NOT conducted risk-benefit analysis for young people`,
        `So, we took up the challenge.`,
        `Over 300 universities & colleges were (as of Sept 2022) mandating boosters across North America (1000 with 2-dose Covid-19 mandates still in place). Is this a science-based policy? Is it ethically justified?`,
        `Using CDC/Pfizer data,we estimate 31,207-42,836 previously UNINFECTED adults 18-29 yrs must be boosted to prevent 1 C19 hospitalization over 6 months.But we also estimate 18.5 serious adverse events`,
        `We also anticipate 1,430-4,626 cases of grade ≥3 reactogenicity per C19 hospitalization averted, which prevents people from conducting daily activities. `,
        `We anticipate 1.5 to 4.6 booster-associated myopericarditis cases in males (typically requiring hospitalisation) per Covid hospitalization averted.`,
        `We then outline 5 ethical arguments against booster mandates at institutions of higher education in North America.`,
        `1) The CDC has been negligent and it is incumbent on the agency to conduct an updated Omicron risk-benefit analysis for this age group. `,
        `2) Our analysis (see above) shows the alarming possibility that mandates may result in a net expected harm to young people. This violates the 'do no harm principle.' Our estimates assume NO ONE has been infected w Covid. So: real-world benefits likely to be less favourable.`,
        `3) Vaccine mandates are not proportionate public policy. The benefits are unlikely to outweigh the harms by any significant degree to justify liberty restrictions. Current vaccines do not durably stop transmission. `,
        `4) Booster mandates violate the reciprocity principle. US vaccine injury schemes are completely inadequate and have failed to provide support for rare but severe Covid-19 vaccine injuries.`,
        `5) Booster mandates have wider social harms. Students and faculty risk being dis-enrolled or fired. Mandates have caused backlash, resistance & anger. This has further damaged the trustworthiness & credibility of scientific institutions/universities`,
        `We consider counter-arguments against our position, such as a desire to 'socialize' compliance & increase safety on campus. We find these arguments sorely lacking in a scientific or ethical basis. European countries have more appropriate policies for boosters in young pls`,
        `Lastly, we make the point that although our risk-benefit analysis is focused on boosters in young adults, our analysis is relevant to other university, workplace & school Covid-19 vaccine mandates, esp given high prior infection rate among the US population. `,
      ],
      urls: [
        `https://twitter.com/jflier/status/1599896697479778304`,
        `https://twitter.com/KevinBardosh/status/1599866819988578304`,
        `https://jme.bmj.com/content/early/2022/12/05/jme-2022-108449`,
        `https://www.nejm.org/doi/full/10.1056/NEJMe2203329`,
      ],
    },
    {
      description: [
        `<b>This is the New York health commissioner, Dr. Mary Bassett, on camera admitting that she lied in order to push for a procedure that is particularly dangerous for young boys to take</b>`,
        `And the numbers we gave on pediatric admissions, weren't intended to make it seem that children were having, you know, uh, having a, an epidemic of infection, these were small numbers that we reported in our health alert.`,
        `That was based on 50 hospitalizations and I have now given you some larger numbers, but there's still small numbers.`,
        `<b>It really is to motivate pediatricians and families to seek the protection of vaccination.</b>`,
      ],
      urls: [
        `https://twitter.com/Lukewearechange/status/1548873705329156101`
      ],
    },
    {
      description: [
        `Study Finds Massive Disparity in Chronic Disease Between Vaccinated and Unvaccinated Children`,
        `"These are shocking numbers." (Vaxed vs Unvaxed)`,
        `• Allergic Rhinitis - 10.4% vs 0.4%`,
        `• Other Allergies - 22.2% vs 6.9%`,
        `• Eczema - 9.5% vs 3.6%`,
        `• Learning Disability, ADHD, or ASD - 10.5% vs 3.1%`,
        `• Any Chronic Illness - 44.0% vs 25.0%`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1627680272593432578`
      ],
    },
    {
      description: [
        `Father who lost his son because of Trudeau's vaccine mandate reacts to Trudeau lying under oath.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1597922984823771140`
      ],
    },
    {
      description: [
        `<b>Toronto Board of Health edits out public meeting speaker Dan Harman's testimony about his 17 year old son's death after receiving his first dose of Pfizer.</b>`,
      ],
      urls: [
        `https://rumble.com/vt9kjy--censored-what-does-toronto-board-of-health-not-want-you-to-hear-about-the-.html`
      ],
    },
    {
      description: [
        `High School students have cardiac arrests at their desks - 2nd incident - 16 year old Justus Danielli collapsed at calculus exam on Mar.23, 2023 - shocked back to life with defibrillator 5 times!`,
      ],
      urls: [
        `https://twitter.com/MakisMD/status/1646185813510873088/photo/1`,
        `https://makismd.substack.com/p/high-school-students-collapsing-at`,
      ],
    },
    {
      description: [
        `2 babies suffered from STROKE (central nervous haemorrhages and cerebrovascular accidents) BUT were not included in EU's safety review discussion since they were exposed to Pfizer/BioNTech #mRNA shot indirectly via (trans-mammary route) BREAST MILK.`,
      ],
      urls: [
        `https://twitter.com/sonia_elijah/status/1646575938245738497`
      ],
    },
  ],
  AlbertasOwnData: [
    {
      description: [
        `This table represents the difference between different hospital and mortality statistics for influenza two years before covid, and during covid, derived from Alberta's own public statistics for demographics of past influenza years, and covid years.`,
        `<a href="/c19/C19-Alberta-ActualNumbers.pdf">Read the pdf</a> for the full report which includes these additional statistics.`,
        `<table class="ab-table">
          <tr>
            <td rowspan="3"></td>
            <td colspan="9" class="lightheading">Change in rate (per 100,000) compared to influenza seasons</td>
          </tr>
          <tr>
            <td colspan="4" class="lightheading">2018 - 2019</td>
            <td></td>
            <td colspan="4" class="lightheading">2019 - 2020</td>
          </tr>
          <tr class="heading">
            <td class="subheading">Hospital Admission<br/>(incl ICU)</td>
            <td>Hospital Admission<br/>(no ICU)</td>
            <td>ICU</td>
            <td>Death</td>
            <td class="blank"></td>
            <td class="subheading">Hospital Admission<br/>(incl ICU)</td>
            <td>Hospital Admission<br/>(no ICU)</td>
            <td>ICU</td>
            <td>Death</td>
          </tr>
          <tr>
            <td class="subheading">0 - 4 yrs</td>
            <td class="green">-93.7</td>
            <td class="green">-84.4</td>
            <td class="green">-9.4</td>
            <td class="green">0.0</td>
            <td></td>
            <td class="green">-77.7</td>
            <td class="green">-71.4</td>
            <td class="green">-6.4</td>
            <td class="green">0.0</td>
          </tr>
          <tr>
            <td class="subheading">5 - 9 yrs</td>
            <td class="green">-47.9</td>
            <td class="green">-43.5</td>
            <td class="green">-4.5</td>
            <td class="green">0.0</td>
            <td></td>
            <td class="green">-26.9</td>
            <td class="green">-21.8</td>
            <td class="green">-5.2</td>
            <td class="green">0.0</td>
          </tr>
          <tr>
            <td class="subheading">10 - 19 yrs</td>
            <td class="green">-4.5</td>
            <td class="green">-4.0</td>
            <td class="green">-0.5</td>
            <td class="green">0.0</td>
            <td></td>
            <td class="green">-6.5</td>
            <td class="green">-5.9</td>
            <td class="green">-0.6</td>
            <td class="green">-0.2</td>
          </tr>
          <tr class="extrapolated">
            <td class="subheading">20 - 29 yrs*</td>
            <td class="red">15.6</td>
            <td class="red">13.3</td>
            <td>2.3</td>
            <td>0.3</td>
            <td class="blank"></td>
            <td class="red">16.2</td>
            <td class="red">13.8</td>
            <td>2.4</td>
            <td>0.3</td>
          </tr>
          <tr class="extrapolated">
            <td class="subheading">30 - 39 yrs*</td>
            <td class="red">29.5</td>
            <td class="red">24.6</td>
            <td>4.9</td>
            <td>0.7</td>
            <td class="blank"></td>
            <td class="red">30.4</td>
            <td class="red">25.3</td>
            <td class="yellow">5.1</td>
            <td>0.6</td>
          </tr>
          <tr class="extrapolated">
            <td class="subheading">40 - 49 yrs*</td>
            <td class="red">34.6</td>
            <td class="red">26.4</td>
            <td>8.3</td>
            <td>1.7</td>
            <td class="blank"></td>
            <td class="red">35.7</td>
            <td class="red">27.3</td>
            <td class="yellow">8.5</td>
            <td>1.5</td>
          </tr>
          <tr>
            <td class="subheading">50 - 64 yrs</td>
            <td class="yellow">7.5</td>
            <td>1.1</td>
            <td class="yellow">6.5</td>
            <td>2.8</td>
            <td class="blank"></td>
            <td class="red">28.5</td>
            <td class="red">17.3</td>
            <td class="yellow">11.3</td>
            <td>4.3</td>
          </tr>
          <tr>
            <td class="subheading">65 + yrs</td>
            <td class="red">32.9</td>
            <td class="yellow">10.7</td>
            <td class="red">22.2</td>
            <td class="red">61.7</td>
            <td></td>
            <td class="red">80.9</td>
            <td class="red">56.4</td>
            <td class="red">24.5</td>
            <td class="red">61.7</td>
          </tr>
        </table>`,
        `<table class="ab-table simple">
          <tr><td>
            * Ages 20 to 49 dont have as detailed data for seasonal flu demographics as the covid stats.
            <br/>These numbers are therefore inferred with back-calculated possible trend results based on the results of the trends we can see in the data.
            <br/>The complete tables can be found in the full report.
          </td></tr>
        </table>`,
        `<table class="ab-table simple">
          <tr>
            <td class="green">green:</td>
            <td class="green">c19 lower rate than flu</td>
          </tr>
          <tr>
            <td>grey:</td>
            <td>c19 comparable to flu</td>
          </tr>
          <tr>
            <td class="yellow">orange:</td>
            <td class="yellow">c19 noticably higher rate than flu</td>
          </tr>
          <tr>
            <td class="red">red:</td>
            <td class="red">c19 higher rate than flu</td>
          </tr>
        </table>`,
        `<br/>We can see that children and young adults <b>under the age of 20 have less of a risk of all negative outcomes</b> (hospitalization, ICU admission, and death) per 100,000 people, than the seasonal flu. In the case of a hospital admission with no further complications, the rate is down drastically for covid as opposed to the flu.`,
        `We may be unsettled by the higher rates of reporting for hospital and ICU admissions, indicated by red text. However if the ultimate indicator of deadliness is death, and the hospital and ICU admission numbers <b>not resulting in death</b> are very inconsistent with their corresponding death rates, this suggests hospital and ICU admission rates are therefore not indicative of the death rate we should expect to see. The most pertinent column to pay closest attention to then, is the actual death rate.`,
        `Even though some of the death rates in this table are inferred, they still form a reasonable linear connection from 20 to 50 year olds, and stay within the limits of the 0 - 19 and 50 + demographic data which we <b>do</b> know. It is not unreasonable to assume these sorts of numbers for these demographics.`,
        `We do know and can see here that the flu is more deadly than covid for people under 20 years old. We can also see that even for 50 - 64 year olds, the death rate is only slightly higher than past flu seasons, indicated by grey text.`,
        `We can also compare the flu deaths rates between 2018-2019 and 2019-2020. We can see that per 100,000 people, the death rate barely changes for 0 - 49 year olds, across either year.`,
        `This suggests, across varying flu seasons, those under 50 years old wouldn't see much of a difference in death rates when compared against covid years. And if a difference could be seen, it would only be slightly above what we expect to see in a flu year. Only 0.3 to 1.5 per 100,000.`,
        `We can also see that covid had a higher related-death count compared to the flu in 2019-2020 than in 2018-2019. However covid shows only a slightly higher related-death rate compared to 2018-2019, than 2018-2019 does to 2019-2020.
        <br/>2018-2019 was about 1.5 times as deadly of a flu season as 2019-2020. Covid is less than twice that same ratio in relation to 2018-2019, equating to only about 123 excess deaths in 50 to 64 year olds for the entirety of covid, than that year's flu season.`,
        `Though we do see high death rates for covid in 65+ year olds (also broken out into finer demographics in the full report), there are many articles and dicussions linked on this page which speak to inflated "death with covid" counts, and the neglect for the true health of the elderly throughout this time. We've seen provable pre-treatment solutions be condemned and forbidden, spoken out about by high profile doctors and professionals. With correct pre-treatment, many of them firmly believe the deaths counts in the elderly didn't have to be as high as reported, or any higher than any other year.`,
        `<br/>There are many facets to the interpretation of this data and table, as it relates to the hundreds of articles linked here.`,
        `<b>But if we can speak clearly on anything, it's that children, and young adults under 20 years old have, measurably, by the province's own data, little to no increased chance of severe adverse events happening to them in the midst of covid, than they would any other flu season.</b>`,
        `We can also see that flu seasons vary in deadliness, and past flu season death rate ratios align similarly with what we see for covid in 20 to 65 year olds. Some past flu seasons were significantly milder, and some have been much more severe. The covid death rate per 100,000 numbers sit in a "middle" range of documented past flu seasons; deadlier than some, and milder than others.`,
        `This is also interesting considering the apparent zero-case situation of any other type of influenza (consisting of multiple strains) for the past few covid years. The province's own covid numbers seem to fill in this anomalous vacancy in flu season cases, which apparently affected every different influenza strain at once, the same year(s) covid was active.`,
        `<br/>`,
      ],
      urls: [
        `https://www.alberta.ca/stats/covid-19-alberta-statistics.htm#vaccinations`,
        `https://www.alberta.ca/stats/covid-19-alberta-statistics.htm`,
        `https://open.alberta.ca/publications/2561-3154`,
        `https://open.alberta.ca/dataset/9044e65d-a97e-43cb-8357-9c890422f069/resource/a8d0a6cd-97a2-48e2-8ec1-f5a11463bc33/download/health-influenza-summary-report-2019-2020.pdf`,
        `https://open.alberta.ca/dataset/9044e65d-a97e-43cb-8357-9c890422f069/resource/dcd1cc27-57c2-4cf4-8078-3869f19b6390/download/health-influenza-summary-report-2018-2019.pdf`,
        `https://open.alberta.ca/dataset/9044e65d-a97e-43cb-8357-9c890422f069/resource/bb5edfa6-a417-4f75-84d2-3b70c40c6eca/download/influenza-summary-report-2017-2018.pdf`,
        `https://open.alberta.ca/dataset/9044e65d-a97e-43cb-8357-9c890422f069/resource/d0480715-cc89-4689-bfef-b9ae5be71173/download/influenza-summary-report-2016-2017.pdf`,
        `https://open.alberta.ca/dataset/9044e65d-a97e-43cb-8357-9c890422f069/resource/64116c72-e3bf-4e8e-ac3c-eb572af0c3b1/download/influenza-summary-report-2015-2016.pdf`,
        `https://open.alberta.ca/dataset/9044e65d-a97e-43cb-8357-9c890422f069/resource/1d154e53-0124-4e58-9516-2761b5ca9ecb/download/5642952-influenza-summary-report-2014-2015.pdf`,
        `https://open.alberta.ca/dataset/9044e65d-a97e-43cb-8357-9c890422f069/resource/fc09f829-dd22-4831-8da4-8660df731f37/download/5642952-2013-2014-influenza-summary-report.pdf`,
        `https://open.alberta.ca/dataset/9044e65d-a97e-43cb-8357-9c890422f069/resource/ea0e655d-4459-40f4-9906-2153ca9e2c95/download/5642952-2012-2013-influenza-summary-report.pdf`,
        `https://open.alberta.ca/dataset/04c9d31a-963f-46c5-858a-485cbdd084cb/resource/8a09ad64-ad21-441f-89b0-18c3226ddaf2/download/5642952-2011-2012-influenza-summary-report.pdf`,
        `https://open.alberta.ca/dataset/04c9d31a-963f-46c5-858a-485cbdd084cb/resource/fa8002ee-8bef-4fe8-a2d5-d6b1d5b30013/download/5642952-2010-2011-influenza-summary-report.pdf`,
        `https://open.alberta.ca/dataset/9044e65d-a97e-43cb-8357-9c890422f069/resource/a8d0a6cd-97a2-48e2-8ec1-f5a11463bc33/download/health-influenza-summary-report-2019-2020.pdf`,
        `https://open.alberta.ca/publications/annual-population-report`,
        `https://open.alberta.ca/publications/4420320`,
        `https://open.alberta.ca/publications/4170782`,
        `https://www.alberta.ca/population-statistics.aspx`,
        `https://open.alberta.ca/opendata/life-expectancy-in-alberta-at-birth-and-age-65`,
      ],
    },
  ],
  CanadianGovernment: [
    {
      description: [
        `The World Economic Forum (WEF) has more control than anyone can imagine.`,
        `Bought and paid for politicians, company CEOs, financial institutions, tech moguls.`,
        `The list is expansive, and here it goes.`,
      ],
      urls: [
        `https://threadreaderapp.com/thread/1491988554569945090.html`,
        `https://twitter.com/NailHard/status/1491988554569945090`,
      ],
    },
    {
      description: [
        `As hard as it is to believe, this woman (Chrystia Freeland) is the deputy of BOTH Justin Trudeau and Klaus Schwab, at the same time.`,
        `<strong>She's deputy prime minister of Canada and a director of Schwab's World Economic Forum (WEF).</strong>`,
        `For years she also covered up the fact that her grandfather was an actual Nazi.`
      ],
      urls: [
        `https://twitter.com/ezralevant/status/1494881071359565827`,
        `https://ottawacitizen.com/news/national/defence-watch/chrystia-freelands-granddad-was-indeed-a-nazi-collaborator-so-much-for-russian-disinformation/wcm/11196169-fd23-4643-94b4-08039235c595`
      ],
    },
    {
      description: [
        `An MP was asked by a constituent to ask about the WEF and its' ties to our government. He was cut off, citing bad audio and video quality, and it was deemed disinformation. They moved on.`,
        `Disinformation? Well that's odd because he says it right here. Now why would the govt lie about it being disinformation? `,
      ],
      urls: [
        `https://twitter.com/Greyinggeek1/status/1495043467529105409`,
        `https://twitter.com/ChickenGate/status/1495044643356086275`,
      ],
    },
    {
      description: [
        `Trudeau pledging Canada's support to WEF`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1491157431069855746`,
        `https://t.me/OfficialFreedomCanadaConvoy/191619`,
      ],
    },
    {
      description: [
        `Terence Corcoran: <strong>In Canada, follow the money + the ideas</strong>`,
        `In a short 2017 video clip circulating on YouTube, Klaus Schwab, head of the World Economic Forum and the global promoter of corporatist stakeholder capitalism, outlines how his subversive WEF movement has, to use his word, “infiltrated” governments all over the world.`,
        `He makes special note of Canada.`,
      ],
      urls: [
        `https://financialpost.com/opinion/terence-corcoran-in-canada-follow-the-money-the-ideas`
      ],
    },
    {
      description: [
        `Klaus Schwab:`,
        `<strong>“I also want to thank our Canadian constituency, which always has been a very loyal and very much engaged constituency.”</strong>`
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1494331062830264328`
      ],
    },
    {
      description: [
        `<strong>Canadian Constitutional Crisis | Brian Peckford</strong>`,
        `The last living writer of the Canadian Charter of Rights and Freedoms`,
        `The Jordan B. Peterson Podcast S4: E78`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=EdhFuMDLBDM`
      ],
    },
    {
      description: [
        `Bolsonaro was right all along.`,
      ],
      urls: [
        `https://twitter.com/DIEGOALANPESSO1/status/1489033355589046272`
      ],
    },
    {
      description: [
        `The Canadian government accessed roughly 87% of Canadians' mobile devices without consent or knowledge the government was accessing data during covid.`,
        `Score Another One for the Tin Foil Hats`,
      ],
      urls: [
        `https://twitter.com/LeighStewy/status/1482030840695689229`,
        `https://www.youtube.com/watch?v=sTOaxhJocyU`,
      ],
    },
    {
      description: [
        `Saskatchewan Premier Scott Moe slams lockdowns indicating they cause economic, social and psychological harm as well as infringe on rights & freedoms with little benefit.`,
      ],
      urls: [
        `https://twitter.com/canmericanized/status/1481341765940613123`
      ],
    },
    {
      description: [
        `Justin Trudeau's careless words have become internationally scandalous`,
        `<strong>"He's setting people up as being lesser, as being other, as being despicable, as being guilty of things that are socially unacceptable"</strong>`,
        `Neil Oliver discusses Canadian PM Justin Trudeau's claims about the unvaccinated with Dan Wootton.`,
      ],
      urls: [
        `https://twitter.com/jordanbpeterson/status/1478266011283378177`
      ],
    },
    {
      description: [
        `<b>Kate Wand: Canada's Covid Coup</b>`,
        `"In a crisis - and politics is one permanent crisis - those in power could always declare a state of emergency to justify exceptional measures." A. Koestler`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=M3hxk4M8qbg`
      ],
    },
    {
      description: [
        `Quebec imposing curfews... again.`,
        `<b>Quebec Descending Into FULL FASCISM - Viva Frei Vlawg</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=hmNKZ6W4zLM`
      ],
    },
    {
      description: [
        `Pierre Poilievre: How many people living outside Canada got CERB?`,
        `I asked Canada's most expensive cassette player, Randy Boissonnault, for an answer.`,
        `Liberal minister speaks in dismissive circles, avoiding actually answering any questions. Waste of time and taxpayer money.`
      ],
      urls: [
        `https://twitter.com/PierrePoilievre/status/1475247551842504709`,
        `https://twitter.com/PierrePoilievre/status/1475798691298512898`
      ],
    },
    {
      description: [
        `Teresa Tam telling kids Santa has his mask fitted. Rudolph has had his covid19 test.`,
        `<b>This is how far Canada has fallen</b>`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1474229901578412033`
      ],
    },
    {
      description: [
        `"I know how difficult it is..."`,
        `"I know we've all made many scarifies..."`,
        `<b>Here is a collection of 15 clips of Ontario Premier Doug Ford since March 2020</b>`,
      ],
      urls: [
        `https://twitter.com/rubiconcapital_/status/1472267887759040515`
      ],
    },
    {
      description: [
        `<b>Canada's parliament is already working on plans for future lockdowns.</b>`,
        `Chrystia Freeland: "Programs like Local Lockdown Support & the 🇨🇦 Worker Lockdown Benefit will help in the event of a new lockdown."`
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1471271221580873730`
      ],
    },
    {
      description: [
        `Will it take more than two shots to be considered fully vaccinated?`,
        `Canada's new Health Minister: "Probably...almost certainly."`,
      ],
      urls: [
        `https://twitter.com/Roman_Baber/status/1468561576840024071`
      ],
    },
    {
      description: [
        `<b>ilikestarvingnewbrunswickers.ca</b>`,
        `Meet the Honourable Minister of Health for New Brunswick, Dorothy Shephard`,
        `Unlike every other Health Minister across Canada, Dorothy Shephard does't believe that access to food is a basic human right, as outlined in pesky international treaties, such as the United Nations International Covenant of Economic, Social and Cultural Rights (1976) and the Universal Declaration of Human Rights (1948). Besides, these are old treaties from before the pandemic.`,
        `Indeed, Minister Shephard public policy vision also appears to ignore the Guidance on Essential Services and Functions in Canada During the COVID-19 Pandemic, which designates food as part of Canada's basic critical infrastructure, and therefore deemed “essential”.`,
        `That's why she announced in early December 2021 that New Brunswick will be first province in Canada to allow grocery stores to ban the unvaccinated from purchasing food, in exchange for higher store capacities.`,
        `<b>NEWS UPDATE: December 17, 2021</b>`,
        `We are pleased to report that the Minister of Health, Dorothy Shephard, has reversed her disgraceful health guidance calling for the banning of the vaccine-free in exchange for higher capacity limits at grocery stores across the province.`,
        `The new guidance, published on Friday, December 17, 2021, shows that grocery stores are again an essential service that can't be denied to any New Brunswicker.`,
        `<b>The lesson in this success story is clear - all Canadians must remain vigilant and speak out against government overreach. Our voices together will make a difference.</b>`,
      ],
      urls: [
        `https://ilikestarvingnewbrunswickers.ca/`
      ],
    },
    {
      description: [
        `Originally when NB grocery stores could require proof of vaccination:`,
      ],
      urls: [
        `https://twitter.com/Bret_Sears/status/1466863661704749057`,
        `https://www.cbc.ca/news/canada/new-brunswick/covid-19-new-brunswick-winter-plan-christmas-shephard-1.6272151`,
      ],
    },
    {
      description: [
        `Pierre Poilievre asks 10 top government officials where government money comes from`,
        `"How is the government paying for the 7 billion dollar bill. Directed at whoever wants to answer it"`,
      ],
      urls: [
        `https://rumble.com/vqfarf-december-8-2021.html`
      ],
    },
    {
      description: [
        `Mayor of Ottawa suggests selling vehicles confiscated from freedom protesters`,
      ],
      urls: [
        `https://thepostmillennial.com/mayor-of-ottawa-suggests-selling-vehicles-confiscated-from-freedom-protesters`
      ],
    },
    {
      description: [
        `Here you go. I put together a little video for you. This is Justin Trudeau taking a knee & bowing to BLM & antifa at a time when they were occupying & legitimately terrorizing neighborhoods in Canada. All riot footage seen here was in Canada.`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1496232800101748738`
      ],
    },
    {
      description: [
        `"Non-affiliated" Senator Marilou McPhedran says that elements inside the freedom convoy were "publicly proclaiming that they want a racial war" and that "if there isn't a racial war then we will all soon be forced to speak hebrew.", she adds, "it's an approximate quote".`,
        `Calls it a "seige of Ottawa".`
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1496290561598365706`
      ],
    },
    {
      description: [
        `<b>Liberal ad condemning Prime Minsiter Stephen Harper for announcing he wants to increase military presence in our cities.</b>`,
        `"Stephen Harper actually announced he wants to increase military presence in our citites.`,
        `Canadian cities.`,
        `Soldiers with guns.`,
        `In our cities.`,
        `In Canada.`,
        `We did not make this up.`
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1496557656521203718`
      ],
    },
    {
      description: [
        `Donald Neil Plett Makes Historic Speech In Senate Over PM Trudeau's Emergencies Invocation`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=YIZO3OhqCUY`
      ],
    },
    {
      description: [
        `Vy now we know that the WEF has "penetrated" the Canadian Government on a multitude of levels and positions throughout parliament. This one implicates how they are getting away with it.`,
        `Conflicts of interest up the wazoo.`
      ],
      urls: [
        `https://threadreaderapp.com/thread/1496661666439835650.html`
      ],
    },
    {
      description: [
        `<b>The media is priming us for Freeland becoming PM.</b>`,
        `The Globe and Mail - Chrystia Freeland, Minister of Everything, has big plans for Canada's economic future`,
      ],
      urls: [
        `https://twitter.com/teririch/status/1496931794234445836`,
        `https://www.theglobeandmail.com/business/rob-magazine/article-chrystia-freeland-interview-canada-economy-future/`,
      ],
    },
    {
      description: [
        `<b>Military Insider Tells Jordan Peterson That Trudeau's Actions Have Killed Canada's Banking System</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/BNNIPD6AbiOe/`
      ],
    },
    {
      description: [
        `Chinese style social credit system under the guide of Universal Basic Income - National Standard of Health Framework - mandatory vaccinations in Canada - trying to pass with`,
        `<b>BILL S-233</b>`,
        `<b>Red Alert Canada.`
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1497696527648706565`
      ],
    },
    {
      description: [
        `Liberals cite CBC 'analysis' to justify freezing bank accounts`,
      ],
      urls: [
        `https://torontosun.com/opinion/columnists/furey-liberals-cite-cbc-analysis-to-justify-freezing-bank-accounts`
      ],
    },
    {
      description: [
        `National Post:`,
        `<b>Without fiscal course correction, Ottawa could be posting deficits until 2070, PBO says</b>`,
        `New permanent federal spending programs and drastically weakened fiscal positions in the provinces has substantially extended the timeline for a return to balance, the report says`,
      ],
      urls: [
        `https://nationalpost.com/news/without-fiscal-course-correction-ottawa-could-be-posting-deficits-until-2070-pbo-says/wcm/25cbe0ac-62bd-4440-a679-dd8bfe550fb9/`
      ],
    },
    {
      description: [
        `How the World Economic Forum Controls the World | Klaus Schwab and The Great Reset | Young Global Leaders Program`,
        `<b>Re: The World Economic Forum penetrated directly into Canadian government and policies: "In the future we strengthen the cooperation [between the WEF and Canada] even more"`,
        `"I wonder how Canadians feel about that cooperation.."`,
        `<b>"And remember, an NGO isn't really accountable to anybody other than its own leaders. It isn't accountable to public vote, or a particular government. And when one NGO can influence this many world leaders, that's a problem."</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=c6EP87lvaGU`
      ],
    },
    {
      description: [
        `Randy Hillier will not run for reelection.`,
        `Statements on the corruption through Canadian politics.`,
        `"<b>Our system is broken. There is no sense spending any more time trying to fix a broken system from within, when the problem lies without."</b>`
      ],
      urls: [
        `https://odysee.com/@RandyHillier/20220303_statement`
      ],
    },
    {
      description: [
        `Inside out and upside down.`,
        `TRUDEAU: "<b>we see a bit of a slippage in our democracies.</b> Countries turning towards slightly more authoritarian leaders. Countries allowing increasing misinformation and disinformation to be shared on social media"`,
        `<b>Look who's talking.</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1500237989489696772`
      ],
    },
    {
      description: [
        `"Fuck Trudeau" flags not allowed on Parliament Hill, says police."`,
      ],
      urls: [
        `https://thepostmillennial.com/police-fck-trudeau-flags-banned-parliament-hill`
      ],
    },
    {
      description: [
        `RCMP gave banks police info on Ottawa protesters with list of accounts to freeze`,
      ],
      urls: [
        `https://www.ctvnews.ca/canada/rcmp-gave-banks-police-info-on-ottawa-protesters-with-list-of-accounts-to-freeze-1.5809615`
      ],
    },
    {
      description: [
        `Rex Murphy: 'Slippages' in democracy? Justin Trudeau knows a lot about that`,
      ],
      urls: [
        `https://nationalpost.com/opinion/rex-murphy-slippages-in-democracy-justin-trudeau-knows-a-lot-about-that`
      ],
    },
    {
      description: [
        `Where's the outrage over Trudeau's trip to Britain?`,
        `"Trudeau invoked emergency powers to inflict extreme punishment on those who objected to his Covid policies while denying them proper due process. Does no one in the UK government find that troubling?"`,      ],
      urls: [
        `https://www.spectator.co.uk/article/where-s-the-outrage-over-trudeau-s-trip-to-britain-`
      ],
    },
    {
      description: [
        `Russia includes Canada on list of 'unfriendly countries'`,
      ],
      urls: [
        `https://nationalpost.com/news/canada/russian-says-deals-with-unfriendly-countries-will-require-approval`
      ],
    },
    {
      description: [
        `Warning: Bill 67 - Jordan Peterson`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=iwQy-MVP4u0`
      ],
    },
    {
      description: [
        `Trudeau, Freeland met with Ukrainian neo-Nazi party cofounder`,
        `Prime Minister Justin Trudeau and Deputy Prime Minister Chrystia Freeland met with the co-founder of a far-right fascist party in Ukraine, which was styled off of Hilter's Nazi Party, True North has learned.`,
        `Andriy Parubiy served as the equivalent of the legislative speaker of Ukrainian Parliament from 2016 to 2019, and during that time he personally met with Trudeau and Freeland several times.`,
        `Earlier in his career, Parubiy was an influential member of Ukrain's far-right neo-Nazi movement. In 1991, he co-founded the Nazi-styled Social-National Party of Ukraine (SNPU) - a party focused on “racial nationalism” that even adopted the Nazi Wolfsangel symbol as its logo.`,
      ],
      urls: [
        `https://tnc.news/2022/03/15/trudeau-freeland-met-with-ukrainian-neo-nazi-party-cofounder/`
      ],
    },
    {
      description: [
        `Justin Trudeau is getting roasted by the British press while he is in the UK.`,
        `"He gave this great long diatribe today against Putin, saying he wants respect for sovereignty, he wants to have democracy stood for, he wants to stay true to these values, he wants to fight for them all over the world..`,
        `This was a man that basically froze the bank accounts of truckers, decided that he was going to forcibly round them up, he took tactics directly out of the rulebook of Vladimir Putin to do with his own people, and then he has the audacity and the lack of self-awareness to stand at a podium and tell us that he wants to fight for sovereignty and democracy.`,
        `He's a hypocrite, he has a neck made of brass, and he's a moron.`,
      ],
      urls: [
        `https://twitter.com/stephen_taylor/status/1501409521448726530`
      ],
    },
    {
      description: [
        `Canadian government had pre-bought 10+ shots per person in Canada.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=dD5nDKuTee8`
      ],
    },
    {
      description: [
        `<b>Statement from Conservative Leader Candice Bergen on NDP-Liberal Coalition</b>`,
        `The NDP-Liberal coalition is nothing more than a callous attempt by Trudeau to hold on to power.`,
        `Canadians did not vote for an NDP government. This is little more than backdoor socialism. Trudeau is truly polarizing politics which is what he likes.`,
        `This is an NDP-Liberal attempt at government by blackmail. Nation-building is replaced by vote-buying; secret deal-making over parliamentary debate; and opportunism over accountability.`,
        `With rising inflation, out-of-control cost of living, and national unity at stake, Trudeau knows he is losing the condfidence of Canadians.`,
        `His answer is to stay in power at all costs including implementing the even harsher and more extreme policies of the NDP.`,
        `If this NDP-Liberal coalition stands, Canada is in for a very rough ride.`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1506103981839429635/photo/1`
      ],
    },
    {
      description: [
        `Government moves forward with open banking and names a lead `,
      ],
      urls: [
        `https://www.canada.ca/en/department-finance/news/2022/03/government-moves-forward-with-open-banking-and-names-a-lead.html`
      ],
    },
    {
      description: [
        `European MP Christine Anderson slams Justin Trudeau as a "disgrace to democracy".`,
        `..It would have been more appropriate for Mr. Trudeau, Prime Minister of Canada, to address this house according to Article 144, an article which was specifically designed to debate violations of human rights, democracy and the rule of law, which is clearly the case with Mr. Trudeau.`,
        `Then again, the Prime Minister, who openly admires the Chinese "basic dictatorship", who tramples on fundamental rights, by persecuting and criminalizing his own citizens as terrorists, just because they dare to stand up to his perverted concept of democracy, should not be allowed to speak in this house at all.`,
        `Mr. Trudeau, you are a disgrace for any democracy, please spare us your presence. Thank you.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1506996655924600836`,
        `https://rumble.com/vycaij-mr.-trudeau-you-are-a-disgrace-for-any-democracy.-please-spare-us-your-pres.html`,
        `https://thepostmillennial.com/european-mp-slams-trudeau-as-disgrace-to-democracy/`,
      ],
    },
    {
      description: [
        `INTERVIEW: Christine Anderson reveals why she scorched Trudeau in front of the European Parliament`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=xlxItPULQns`
      ],
    },
    {
      description: [
        `Justin Trudeau gets shredded by Candice Bergen for forming a new NDP-Liberal government`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=AmZm_5-Ngtk`
      ],
    },
    {
      description: [
        `TRUDEAU HUMILIATED at EU Parliament by THREE Anti-DICTATOR MEPs: Watching the Watchers`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=4pWg6aSOFq0`
      ],
    },
    {
      description: [
        `<b>Full mandate debate on Parliament Hill in Ottawa, Canada.</b>`,
        `A very important discussion of all aspects of Canadian vaccine mandates and the actual science of adverse effects.`
      ],
      urls: [
        `https://www.youtube.com/watch?v=DeTdxUSVD7A`,
        `https://parlvu.parl.gc.ca/Harmony/en/PowerBrowser/PowerBrowserV2/20220326/-1/36722`,
      ],
    },
    {
      description: [
        `Crazed Trudeau is really excited about getting the vaccine.`,
        `"It it it it HITS YOU"`,
      ],
      urls: [
        `https://twitter.com/stillgray/status/1510467380413353989`
      ],
    },
    {
      description: [
        `While you were distracted by poop water, Ukraine and Will Smith, a concerted effort to bring in this economically disastrous & deeply immoral policy in Canada has been gaining steam.`,
        `<b>"CERB showed that Basic Income works. We have the data."</b>`,
      ],
      urls: [
        `https://twitter.com/DavidAnber/status/1511791952404140045`
      ],
    },
    {
      description: [
        `I kid you not this is Justin Trudeau`,
      ],
      urls: [
        `https://twitter.com/RWTaylors/status/1512822785533427714`
      ],
    },
    {
      description: [
        `Freeland: “The most fundamental fact is our population is growing faster than our supply of housing, it's just a problem of numbers, and of math.”`,
        `<b>She finally admits that mass immigration is the main reason house prices have doubled under Trudeau.</b>`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1513157267478167557`
      ],
    },
    {
      description: [
        `Man who invoked Emergencies Act to criminalize peaceful protest claims he supports right to protest.`,
        `Justin Trudeau claims he supports the right to protest.`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1513592259811463169`
      ],
    },
    {
      description: [
        `<b>Written in 2014: HuffPost</b>`,
        `Why Canada Will Become a Dictatorship Under Trudeau`,
        `"The leader of that party does what he wants, when he wants, and no one dares question him. Would a Prime Minister Trudeau arbitrarily whip the vote and outlaw certain moral questions? Could Prime Minister Trudeau be trusted to make decisions for the good of the country, not just for his personal self-worth? Would Trudeau call in the police to enforce his vision? Let's hope we never have the opportunity to ask those questions."`,
      ],
      urls: [
        `https://www.huffpost.com/archive/ca/entry/trudeau-dictator_b_6314494 `
      ],
    },
    {
      description: [
        `Justin Trudeau is a dismissive psychopath.`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1516965607991828480`
      ],
    },
    {
      description: [
        `Justin Trudeau earlier today  (April 20,2022) blames immigration for Canada's housing bubble. Suggests millennials rent to own or find a small condo near transit as a starter home.`,
      ],
      urls: [
        `https://twitter.com/inklessPW/status/1516940474375880705`,
        `https://www.youtube.com/watch?v=xrUWiz5CoXQ`,
      ],
    },
    {
      description: [
        `Ottawa faces blowback for plan to regulate internet`,
        `<b>Newly released documents reveal Twitter Canada told government officials that a federal plan to create a new internet regulator with the power to block specific websites is comparable to drastic actions used in authoritarian countries like China, North Korea and Iran.</b>`,
      ],
      urls: [
        `https://www.theglobeandmail.com/politics/article-twitter-compared-liberal-governments-online-harms-plan-to-china-north/`
      ],
    },
    {
      description: [
        `Docs reveal Alberta lobbied to bring The Bachelorette days after cancelling Christmas`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=NwKyumEDjuQ`
      ],
    },
    {
      description: [
        `<b>Ukranians are exempt from Canada's COVID-19 vaccination entry requirements</b>`,
        `Canada-Ukraine Authorization for Emergency Travel: Government of Canada`,
      ],
      urls: [
        `https://www.canada.ca/en/immigration-refugees-citizenship/news/2022/03/canada-ukraine-authorization-for-emergency-travel.html`
      ],
    },
    {
      description: [
        `CRTC Chair Ian Scott Confirms It Yet Again: Bill C-11 Includes Regulatory Power On User Content`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=He96W52QBnk`,
        `https://www.michaelgeist.ca/2022/06/crtc-chair-ian-scott-confirms-it-yet-again-bill-c-11-includes-regulatory-power-on-user-content/`,
        `https://twitter.com/mgeist/status/1531984841268396033`,
      ],
    },
    {
      description: [
        `J.J McCullough speaks against Bill C-11 in the House of Commons.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=_oqVnzwXsV0`
      ],
    },
    {
      description: [
        `Travel and Tourism industry pleading with Trudope to stop the mandates that are destroying tourism in Canada.`,
      ],
      urls: [
        `https://twitter.com/RiseOfTheProles/status/1532174104283041793`,
        `https://twitter.com/RiseOfTheProles/status/1532174812453474305`,
        `https://twitter.com/RiseOfTheProles/status/1532174940782436352`,
      ],
    },
    {
      description: [
        `Former Canadian NHL player Theo Fleury, regarding Justin Trudeau going after Canadian guns:`,
        `"This ideology has been tried twenty four times in the history of the planet and the only thing that comes out of it is death"`,
      ],
      urls: [
        `https://twitter.com/3sidedstory/status/1532157961706975232`
      ],
    },
    {
      description: [
        `"You don't have an absolute right to own private property in Canada," says Liberal Justice Minister David Lametti when asked about seizing and selling off Russian assets.`,
      ],
      urls: [
        `https://twitter.com/RebelNewsOnline/status/1534559279348334592`
      ],
    },
    {
      description: [
        `The Public Health Agency says there "aren't any specific numbers" to justify keeping the mandates in place.`,
        `Why? Because it's not science. It's political science.`,
      ],
      urls: [
        `https://twitter.com/MikeBarrettON/status/1535049994562043907`
      ],
    },
    {
      description: [
        `<b>Canada declares "fully vaccinated" now means 3 injections, stating "two doses don't work anymore."</b>`,
        `Trudeau's health minister Jean-Yves Duclos says two-doses of the Covid vaccine “doesn't work anymore.”`,
        `The government is “transitioning” to a new definition of what it means to be “fully-vaccinated.”#cdnpoli`,
        `Where's peer-reviewed evidence to back any of these claims from a man with ZERO medical/scientific training/experience?`,
        `Where's acknowledgement that natural Tcell immunity is robust, broad, durable?`,
        `Where's clinical evidence for “booster” vac based SOLELY on lab antibody titre?`,
      ],
      urls: [
        `https://twitter.com/disclosetv/status/1537018213170651137`,
        `https://twitter.com/TrueNorthCentre/status/1536814753825402884`,
        `https://twitter.com/dockaurG/status/1536818055082295296`,
      ],
    },
    {
      description: [
        `Carl Brock grills Chrystia Freeland about her own words. She doesn't answer, giving non-responsive replies.`,
      ],
      urls: [
        `https://twitter.com/adamscrabble/status/1536894003286876160`
      ],
    },
    {
      description: [
        `Nearly 15 million doses of Canada's COVID-19 vaccines have expired`,
        `Nearly 13.6 million doses of AstraZeneca that the government donated to other countries sat in warehouses until they expired`,
      ],
      urls: [
        `https://nationalpost.com/news/canada/nearly-15-million-doses-of-canadas-covid-19-vaccines-have-expired-including-13-6-million-destined-for-covax`
      ],
    },
    {
      description: [
        `Six years of disinformation from Prime Minister Justin Trudeau in two bite sized minutes.`,
      ],
      urls: [
        `https://twitter.com/cosminDZS/status/1535032119704969216`
      ],
    },
    {
      description: [
        `Canadian Liberals Welcome Chinese Election Interference`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=iHLtSiaR7aI`
      ],
    },
    {
      description: [
        `"Tell us about Justin Trudeau. Who is Justin Trudeau?"`,
        `Theo Fleury: "He is the worst of the worst that Canada has to offer. He has no substance. He has no soul. He doesn't care about humanity. He only cares about himself and power."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1542621512565346304`
      ],
    },
    {
      description: [
        `Could Canadian Liberals Be Benefiting from China Money?`,
        `China Unscripted with Sam Cooper`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=EEFt81ykx8E`
      ],
    },
    {
      description: [
        `Never forget drama teacher faking the mask off and then on and taking it off. Little did he know the camera was recording his charade.`,
      ],
      urls: [
        `https://twitter.com/5dme81/status/1555757732166336512`
      ],
    },
    {
      description: [
        `In 2019 NSICOP found ample evidence that Justin Trudeau & Chrystia Freeland were under the influence of foreign governments (Germany & UN) & state actors (WEF). 8 days after Justin Trudeau was presented with NSICOP report Trudeau called 2019 federal election on 11 September 2019`,
      ],
      urls: [
        `https://twitter.com/ncio_canada/status/1478855651732463619`,
        `https://threadreaderapp.com/thread/1478855651732463619.html`,
        `https://www.youtube.com/watch?v=OOUkq791x7s`,
      ],
    },
    {
      description: [
        `Trudeau’s Canadian medical professionals are now pressuring the mentally or physically ill, disabled, infirm and elderly to kill themselves by euthanasia (MAID) because of their “burden on the healthcare system”.`,
      ],
      urls: [
        `https://twitter.com/katewand/status/1563215021987201028`
      ],
    },
    {
      description: [
        `Video from exactly a year ago. This shows Trudeau's temper. He was interviewed by Neetu Garcha of Global and he didn't like the fact that she controlled the agenda, and not him. Trudeau hates strong people in general, but strong women especially. We need mor journalist like her.`,
      ],
      urls: [
        `https://twitter.com/Martyupnorth_2/status/1570067961263849472`
      ],
    },
    {
      description: [
        `Ivermectin emails inside Public Health Agency of Canada`,
        `For treating covid, the Canadian government says ivermectin is unsafe, ineffective, and not to use it… but the science says otherwise, and so do some PHAC staff.`,
        `Did Dr. Theresa Tam - Canada's so-called Chief Public Health Officer - know of this finding by her own staffer that ivermectin could save lives? Yes, it appears the above overview was put together at the chief's request.`
      ],
      urls: [
        `https://scoopsmcgoo.substack.com/p/ivermectin-emails-from-the-public`,
        `https://scoopsmcgoo.substack.com/p/phac-emails-re-ivermectin-2`,
      ],
    },
    {
      description: [
        `Miller: So the purpose [of the regulations the federal government put into place] was to encourage Canadians  vaccinated? To compel them to get vaccinated? Is that fair?`,
        `Freeland: Thats right.`,
      ],
      urls: [
        `https://twitter.com/RealAndyLeeShow/status/1595850779445059584`
      ],
    },
    {
      description: [
        `Canadian Heritage Minister Pablo Rodriguez acknowledged before the Senate yesterday that Bill C-11 would empower gov to indirectly manipulate social media algorithms to comply with regulation.`,
      ],
      urls: [
        `https://twitter.com/cosminDZS/status/1595527457792491520`
      ],
    },
    {
      description: [
        `Health Canada, Top doctors, Politicians knew prior to June 30th 2021 that these vaccines could and would damage people's hearts yet they still coerced and forced people to take it.`,
      ],
      urls: [
        `https://twitter.com/WeHaveNoRights2/status/1593084274202521600`
      ],
    },
    {
      description: [
        `Canadian intelligence warned PM Trudeau that China covertly funded 2019 election candidates`,
      ],
      urls: [
        `https://globalnews.ca/news/9253386/canadian-intelligence-warned-pm-trudeau-that-china-covertly-funded-2019-election-candidates-sources/`
      ],
    },
    {
      description: [
        `Trudeau "the suspension of people's rights is something that you should only do in the most exceptional circumstances and I really hope that all politicians call out the overuse of the notwithstanding clause to suspend people's rights and freedoms."`,
      ],
      urls: [
        `https://twitter.com/realmonsanto/status/1587504020779737088`
      ],
    },
    {
      description: [
        `<b>William Makis states that the College of Physicians and Surgeons of Ontario 🇨🇦 is suggesting to Ontario doctors that "unvaccinated" people are mentally ill and in need of psychiatric medication.</b>`,
        `<b>Makis: "That is a very dangerous slippery slope that we're on."</b>`,
        `"So this has come out recently out of the College of Physicians and Surgeons of Ontario.`,
        `The College sent out a letter or a memo to all the doctors in Ontario, suggesting to them.. now so far they're not "mandating" it, they're just "suggesting" it.. that any of their unvaccinated patients, that they should consider that <b>they have a mental problem, and that they should be put on psychiatric medication.</b>`,
        `So far it's just a suggestion, but the College of Physicians and Surgeons of Ontario should not be making these kinds of suggestions, this is extremely unethical, and this is a very, very slippery slope, if they're suggesting that people who wish to have bodily automony, and don't want an experimental vaccine, that there may be something mentally wrong with them? That is a very very dangerous, slippery slope that we're on."`
      ],
      urls: [
        `https://twitter.com/PaulMitchell_AB/status/1593890035388284928`
      ],
    },
    {
      description: [
        `Tone-deaf hypocrite Trudeau joking about the last few years.. he know it's been tough.`,
      ],
      urls: [
        `https://twitter.com/angeloisidorou/status/1584581054282989568`
      ],
    },
    {
      description: [
        `Asked again about her views on WEF, Premier Danielle Smith says she finds it distasteful when “billionaires brag about how much control they have over political leaders.”`,
      ],
      urls: [
        `https://twitter.com/Emmanuel_Rach/status/1584613342278463488`
      ],
    },
    {
      description: [
        `Alberta Premier @ABDanielleSmith is doubling down, saying she is not changing course, and if Ottawa gets in Alberta's way - she will kick them aside. Alberta First.`,
      ],
      urls: [
        `https://twitter.com/TheRealKeean/status/1584013429735849984`
      ],
    },
    {
      description: [
        `<b>Chairman Xi dresses down Justin Trudeau like a junior employee for leaking their private conversation to the media. Trudeau can barely walk after.</b>`,
        `Xi's translator: "Everything we discussed has been leaked to the paper, that's not appropriate."`,
        `Trudeau nods.`,
        `Xi's translator: "And that's not how the way the conversation was conducted.`,
        `If there is sincerity on your s- "`,
        `* gets cut off by Trudeau *`,
        `Trudeau: "We believe in free and open and frank dialogue and this is what we will continue to have."`,
        `Xi smiles and looks around. Barely looks at Trudeau. Annoyed.`,
        `Trudeau: "We will continue to look and work constructively together but there will be things we will disagree on. -"`,
        `Xi interrupts Trudeau. Translator doesn't have a chance to relay what Trudeau said to Xi.`,
        `Xi's translator: "That's great, the conditions first."`,
        `Xi walks away.. shakes Trudeau's hand.. walks away.`,
        `Trudeau walks into a back room, away from the rest of the crowd.`,
      ],
      urls: [
        `https://twitter.com/JackPosobiec/status/1592900274447753216`
      ],
    },
    {
      description: [
        `<b>Chinese Communist Part interferes in Canada's elections</b>`,
        `China Uncensored`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=5u7Ltyug7dQ`
      ],
    },
    {
      description: [
        `<b>"I did not call people who are unvaccinated names."</b>`,
        `- Justin Trudeau`,
        `Watch this video. He did.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1596440340248293378`
      ],
    },
    {
      description: [
        `Trudeau did everything he could to make sure Canadians couldn't get a hold of Ivermectin or Hydroxychloroquine, but he made sure every hospital had plenty of Remdesivir. Here is a list of evidence.`,
        `- Remdesivir is causing many diagnosed Covid Patients to Die.`,
        `- Summary of the Evidence for Ivermectin in COVID-19`,
        `- 187 ivermectin COVID-19 studies, 138 peer reviewed, 93 comparing treatment and control groups.`,
        `- How Billions in COVID Stimulus Funds Led Hospitals to Prioritize 'Treatments' That Killed, Rather Than Cured`,
        `- There are now significant clinical trial data (see summaries at http://c19early.com) to support use of medications such as ivermectin and/or fluvoxamine`,
        `- Detroit's Ford Hospital Defends Hydroxychloroquine Study`,
        `- Attempt to discredit landmark British ivermectin study. Medical disinformation from the BBC.`,
        `- Dr. Fauci elevated remdesivir as the best drug for COVID while at the same time poisoning all debate about hydroxychloroquine (HCQ).`,
        `- Is Hospital Protocol Harming Covid Patients?`,
        `- The apparent safety and low cost suggest that ivermectin is likely to have a significant impact on the SARS-CoV-2 pandemic globally.`,
        `- Does Ivermectin really work against Covid-19?`,
        `- Outrageous! FDA Backtracks During Trial and Now Claims 'Not Taking Ivermectin for COVID-19' was Merely a Recommendation`,
        `- Remdesivir shouldn't be used on hospitalized Covid-19 patients, WHO advises. Remdesivir has no meaningful effect on mortality or reducing the need for mechanical ventilation, an expert panel said.`,
        `- <b>Covid 19 deaths, or Remdesivir deaths?</b> - At six months of this one-year study, the safety board for the study found Remdesivir had the highest death rate of all four experimental drugs and pulled the drug “It didn't even make it to the end of the study because it was so deadly.`,
      ],
      urls: [
        `https://twitter.com/JohnLeePettim13/status/1594723313108008961`,
        `https://threadreaderapp.com/thread/1594723313108008961.html`,
        `https://oh17.com/2021/12/14/remdesivir-is-causing-many-diagnosed-covid-patients-to-die/`,
        `https://covid19criticalcare.com/wp-content/uploads/2021/08/SUMMARY-OF-THE-EVIDENCE-BASE-FINAL.pdf`,
        `https://c19ivm.org/`,
        `https://alethonews.com/2022/01/25/how-billions-in-covid-stimulus-funds-led-hospitals-to-prioritize-treatments-that-killed-rather-than-cured/`,
        `https://c19early.org/`,
        `https://canadiancovidcarealliance.org/wp-content/uploads/2021/07/DEAR_DOCTOR_INFORMED_CONSENT_for_IVM_and_FLVUSEforCOVID_v520210618.pdf`,
        `https://www.deadlinedetroit.com/articles/25291/detroit_s_ford_hospital_defends_study_on_hydroxychloroquine_in_wake_of_trump_s_controversial_disclosure`,
        `https://www.hartgroup.org/bbc-ivermectin/`,
        `https://deeprootsathome.com/dr-bryan-ardis-gives-life-saving-protocol-here/`,
        `https://rumble.com/vnge19-is-hospital-protocol-harming-covid-patients.html`,
        `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8248252/`,
        `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8248252/pdf/ajt-28-e434.pdf`,
        `https://www.indiatoday.in/india-today-insight/story/does-ivermectin-really-work-against-covid-19-1806807-2021-05-25`,
        `https://survivalmagazine.org/news/outrageous-fda-backtracks-during-trial-and-now-claims-not-taking-ivermectin-for-covid-19-was-merely-a-recommendation/`,
        `https://www.nbcnews.com/health/health-news/remdesivir-shouldn-t-be-used-hospitalized-covid-19-patients-who-n1248320`,
        `https://myvalleynews.com/blog/2022/02/03/covid-19-deaths-or-remdesivir-deaths/`,
      ],
    },
    {
      description: [
        `<b>Dr. Teresa Tam - Canada:</b>`,
        `"REGARDLESS OF RISK FACTORS, we encourage anyone who has not yet received their primary covid19 series, or their fourth, 2022 booster doses, to get these vaccinations."`,
      ],
      urls: [
        `https://twitter.com/AntonioTweets2/status/1635501193123225601`
      ],
    },
    {
      description: [
        `<b>Canada is shamefully becoming a world-wide example of a democracy being manipulated by China.</b>`,
        `<b>On Monday, Australian Senator James Patterson discussed China's meddling to get Justin Trudeau re-elected.</b>`,
        `The most recent case study that we have is from Canada. With leaked documents from the Canadian Intelligence Agency, sets out pretty clearly, what was the Chinese Communist Party's objectives of intervening in previous Canadian elections.`,
        `Most interesting, I thought, from those leaked documents is the assessment of the Canadian Intelligence community, that the outcome that the Chinese government most preferred was, not just the reelection of the Trudeau government, but the reelection of the Trudeau government in minority.`,
        `And a comment was attributed to a Chinese goverment official as part of that intelligence assessment, that quote, "We like it when parties are fighting between each other," as if division and discord and disagreement and an objective and an end in and of itself for our potential adversaries.`,
      ],
      urls: [
        `https://twitter.com/cosminDZS/status/1637883818060443648`
      ],
    },
    {
      description: [
        `Chair of the Toronto Association for Democracy Cheuk Kwan delivers a heartbreaking and chilling testimony in committee about how he has been threatened on Canadian soil by the Beijing regime:`,
        `“You get a phone call asking, "So, how are your parents doing back in Sichuan, China?"”`,
      ],
      urls: [
        `https://twitter.com/RealAndyLeeShow/status/1634422453777887232`
      ],
    },
    {
      description: [
        `A group in Taiwan made a video depicting Trudeau as a lacky of the CCP 7 years ago.`,
        `Chinese panda drags teabags across Trudeau's face.`,
        `Taiwan knew. Why did it take Canada so long to catch on?`,
      ],
      urls: [
        `https://twitter.com/CoryBMorgan/status/1631347614498320386`,
        `https://www.youtube.com/watch?v=yAtWxi6esRQ`,
      ],
    },
    {
      description: [
        `Trudeau on Han Dong and suspected election interference from China:`,
        `"In a free democracy it is not up to unelected security officials to dictate to political parties who can and cannot run."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1630294983944163329`
      ],
    },
    {
      description: [
        `The biggest scandal in Canadian history. The Communist Party of China has been interferring with our elections.`,
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1629960394449235969`
      ],
    },
    {
      description: [
        `Adriana La Grange - Alberta "minister of education" exposed for FORCING CHILD PORN "curriculum" after Fort McMurray school board just said no!`,
      ],
      urls: [
        `https://twitter.com/chrissaccoccia1/status/1623772530514485248`
      ],
    },
    {
      description: [
        `Justin Trudeau has threatened Provincial Premiers that he will cut off healthcare funding unless they agree to Digital ID.`,
        `This Digital ID system will allow government to control every aspect of your life.`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1623807764320645120`
      ],
    },
    {
      description: [
        `Trudeau's Minister in charge of investigating Liberals over McKinsey consulting contracts tells Committee she wasn't aware of the company's criminal charges and immoral practices.`,
        `Incompetence or ignorance?`,
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1622964680451842051`
      ],
    },
    {
      description: [
        `WION Priyanka talks about how Trudeau won't say who got $240 billion in aid. The money has been distributed, but no one knows who received this money.`,
      ],
      urls: [
        `https://twitter.com/midnightriderV2/status/1622394943296905218`
      ],
    },
    {
      description: [
        `Canadian internet censorship bill C-11.`,
        `Every Canadian needs to know how Trudeau's censorship bill will radically change how they use the internet.`,
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1622652885963091968`
      ],
    },
    {
      description: [
        `Theresa Tam and Mrs. Claus advertise vaccines for children and want you to get vaccinated and wear a mask at your indoor gatherings.`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1605754986549215235`
      ],
    },
    {
      description: [
        `Canadian Dairy farmers are forced to dump enormous quantities of milk in order to keep prices high.`,
        `I've been denouncing this for years. It continues because all the establishment parties are afraid of the supply management mafia.`,
        `---`,
        `Canadian government makes this dairy farmer in Ontario throw out 30,000L of milk, all because he's gone over his quota. The milk could be donated to the hospitals or homeless shelters or even food banks but nope pour it out. So we as consumers can pay $7L.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1621506289175072768`,
        `https://twitter.com/Fisherlady111/status/1620896460173877249`
      ],
    },
    {
      description: [
        `Land Use Bylaws for Canadian farmers and land owners get sneakily updated implying excessive future control.`,
        `Thorhild AB got the draft land use bylaw rescinded, but not without effort and worry.`
      ],
      urls: [
        `https://twitter.com/cravecreative/status/1620531487518105601`,
        `https://twitter.com/cravecreative/status/1622970747617697799`,
      ],
    },
    {
      description: [
        `<b>Even KallMeKris, the 24th biggest TikToker in the world, is against C-11.</b>`,
        `I am scared. I have been paying really close attention to Bill C-11, aka the Online Streaming Act. It's something my fellow Canadian creators should be paying attention to and all the viewers as well.`,
        `Bill C-11 was supposed to be promoting Canadian storytelling online. In reality the Bill has ended up so broadly worded that it lets the CRTC interfere with every part of your online life. That include manipulating your feeds and search results, requiring you to provide proof of your age to access platforms like Reddit and Twitter, and controlling your favourite YouTube channels, TikToks and other streaming services. And even your own uploads.`,
        `Earlier this month the Canadian Senate passed Bill C-11, with a helpful amendment that better protects Canadian creators. But on March 7th the government gave notice that it would reject this amendment that would protect digital creators.`,
        `This is terrifying and honestly wild for the government to just ignore digital creators and its viewers.`,
        `Luckily there is still time to make an impact: www.digitalfirstcanada.ca`,
      ],
      urls: [
        `https://twitter.com/gddub/status/1642684396762177537`,
        `https://www.digitalfirstcanada.ca`,
      ],
    },
    {
      description: [
        `Justin Trudeau slams YOUTUBERS for spreading CONSPIRACY THEORIES about COVID`,
      ],
      urls: [
        `https://twitter.com/truckdriverpleb/status/1609632446521430016`
      ],
    },
    {
      description: [
        `McKinsey's influence explodes under Trudeau, especially on immigration`,
        `Justin Trudeau's government gave McKinsey 30 times more money than when the Conservatives were in power. Sometimes with untendered contracts.`,
      ],
      urls: [
        `https://ici-radio--canada-ca.translate.goog/nouvelle/1945915/mckinsey-influence-canada-trudeau-immigration-conseils`
      ],
    },
    {
      description: [
        `<b>Fraser Institute: It's reasonable to call Trudeau a modern-day socialist</b>`,
        `Justin Trudeau is a socialist. Redistribution, central planning etc. But Socialists can't attain their goals peacefully, since you can't take property away peacefully. So they turn into Communists. C-11 & Emergencies Act is how Communists start.`,
        `Socialism, at its core, remains an ideology of the benefits of top-down decision-making by elites, for and in the interests of the collective. Over time, the meaning of socialism has changed and evolved. Its original conception—and technical definition—is state ownership of the means of production, namely factories, machinery, etc. For the most part, there are very few socialists who now advocate for the government to nationalize industries.`,
        `These are all top-down decisions imposed on individuals and firms. This is the essence of the socialist approach. And like other forms of socialism in the past, this new brand will fail to deliver promised results and impose enormous costs on society.`,
      ],
      urls: [
        `https://twitter.com/Roman_Baber/status/1609595931288305666`,
        `https://www.fraserinstitute.org/article/its-reasonable-to-call-trudeau-a-modern-day-socialist`,
      ],
    },
    {
      description: [
        `Trudeau: "There are some guns, yes, that we're going to have to take away from people who were using them to hunt."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1605620141047283720`,
        `https://thepostmillennial.com/trudeau-will-ban-some-hunting-guns-that-are-too-dangerous-in-other-contexts`,
        `https://twitter.com/Harry__Faulkner/status/1605271548503400474`,
      ],
    },
    {
      description: [
        `Trudeau: Canada is not broken.`,
        `Yet more people are using food banks then ever before in our history.`,
        `Heating prices rising.`,
        `Emergency departments overwhelmed.`,
        `More Canadians loading up on debt and turning to credit cards.`
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1603451283855347712`
      ],
    },
    {
      description: [
        `Canada develops its own version of "psychosis"`,
        `How odd Possibly because of that small fringe minority...`,
      ],
      urls: [
        `https://twitter.com/Bel_B30/status/1603090993439555586`
      ],
    },
    {
      description: [
        `FLASHBACK: Trudeau says "This country is a country of openness, of respect, of compassion, of the rule of law, of the rights of the individuals, of freedom … Freedom to do what you want with your body"`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1600548071129354240`
      ],
    },
    {
      description: [
        `<b>A Legislative Onslaught Against Freedom</b>`,
        `Bills C-11, C-18, C-21, C-26, & C-36 will turn Canada into an authoritarian hellscape`,
      ],
      urls: [
        `https://karlstack.substack.com/p/a-legislative-onslaught-against-freedom`
      ],
    },
    {
      description: [
        `Keith Wilson, lawyer`,
        `I have reviewed Bill 1. Rachel Notley's comments below are hyperbole. Bill 1 is moderate in scope and goals. The truth is Danielle Smith's standing up to Ottawa's anti-Alberta policies will encourage investment and more jobs in Alberta.`,
      ],
      urls: [
        `https://twitter.com/ikwilson/status/1598570701723959296`
      ],
    },
    {
      description: [
        `Elizabeth May declares that she takes her "marching orders" from a foreign government, from the permanent representative of Palestine to Canada.`,
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1598489153980596224`
      ],
    },
    {
      description: [
        `CANADA - CCP playbook.`,
        `Public health Canada confirmed, it secretly accessed location data for 33 million mobile devices (87% of the population) to spy on their movements.`,
        `how very erm… China ish`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1598258257910431745`
      ],
    },
    {
      description: [
        `<b>Munk Debates - Nov 30, 2022: Douglas Murray's eloquent takedown of Canadian legacy media.</b>`,
        `Your Prime Minister decided in advance that these people were, all the modern excommunications, they were nazis, they were white supremacists, they were anti-semites, they were probably homophobes, they were misogynists, they were probably transphobes, etc etc etc, in all the things you do in the modern political age, you just want to defenestrate somebody who's awkward to you.`,
        `And then he brings in the Emergency Powers Act, now.. at such a time, what would the mainstream media do? It would question it. It would question it. The Canadian mainstream media did not. The Canadian mainstream media acted as an arm and chorus of the Canadian government.`,
        `I will give you a couple of examples, but ladies and gentlemen I could go on for hours with examples of this.`,
        `You had a CBC host describing the freedom convoy as quote, "a feral mob".`,
        `You had a Toronto Star columnist saying, quote, sorry for the language, "it's a homegrown hate farm that was then jet fuelled by an American right wing funded rat fucking operation." Jesus, they can't even write at these papers anymore..`,
        `CBC said that 2 indigenous women were so scared to go outside in Ottawa because of racist violence, didn't bother to mention that indigenous drummers had led the truckers in an O'Canada rendition.`,
        `The National Observer said that the many black and indigenous freedom convoy supporters were in fact, duped by the truckers.`,
        `The Globe and Mail reporter said, "my 13 year old son told me to tell protesters I'm not a Jew, after fear of anti-semetic violence," without mentioning that one of the leaders of the convoy was himself Jewish.`,
        `Now, why is this so rancid? Utterly utterly rancid and corrupt?`,
        `Because in this country, your media, your mainstream media is funded by the government. A totally corrupted system.`,
        `In 2018, oh an election year what a coincidence, the Canadian media has given $595 million over 5 years. The Toronto Star estimated it was going to be getting $3 million from the government in the first half of the year. It went on and on.`,
        `See, the government in Canada can tell people to, they can tell the banks to shut down peoples' bank accounts. Oh yeah. Your government can do that and if you're happy with that, just think about what would happen if the shoe was on the other foot. The government can do that, but in Canada, they can also tell the media what to do. And the media does the bidding of the Canadian government. That isn't a free society's media. I've seen free countries all my life, but this, in a developed, liberal democracy like Canada is a disgrace.`,
        `We're not saying don't read the mainstream media, we're just saying don't trust them.`,
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1598334823591866369`
      ],
    },
    {
      description: [
        `Re: Trudeau's gun ban, "You are a disgrace to Canada".`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=ik43-gccKSw`
      ],
    },
    {
      description: [
        `Canadian Veteran BANNED from Scotiabank`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=A9R6SaXqxTI`
      ],
    },
    {
      description: [
        `CISIS warnings re Toronto Liberal MPs and Beijing influence (alleged)`,
        `Global National News Friday Feb. 24, 2023 unedited`,
      ],
      urls: [
        `https://twitter.com/CanadaRecord/status/1629301376269316096`,
        `https://twitter.com/CanadaRecord/status/1629307083169382400`,
        `https://twitter.com/CanadaRecord/status/1629307604114591744`,
      ],
    },
    {
      description: [
        `Trudeau launches into an unhinged 5-minute tirade against Pierre Poilievre and Freedom Convoy supporters.`,
      ],
      urls: [
        `https://twitter.com/Harry__Faulkner/status/1615775453096067097`
      ],
    },
    {
      description: [
        `The Communist dictatorship in Beijing interfered in our last election on behalf of the Trudeau Liberals.`,
        `<b>CSIS documents reveal Chinese strategy to influence Canada's 2021 election</b>`,
      ],
      urls: [
        `https://twitter.com/strauss_matt/status/1626603744078045184`,
        `https://www.theglobeandmail.com/politics/article-china-influence-2021-federal-election-csis-documents`,
      ],
    },
    {
      description: [
        `Classic, blatant Trudeau hypocrisy.`,
        `PM Justin Trudeau: "Obviously it's really important that citizens be able to make themselves heard. They are protesting on a specific issue that touches on so many others, of government control of authoritarian states."`,
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1598011407068536833`
      ],
    },
    {
      description: [
        `<b>Scott Moe - Saskatchewan Premier:</b>`,
        `The federal Justice Minister says he will look at taking control over natural resources away from the provinces. It's an outrageous statement. Read my response below.`,
        `---`,
        `<b>These dangerous and divisive comments from the federal Justice Minister are a threat to the unity of our country.</b>`,
        `The federal Justice Minister says he will look at rescinding the 1930s Natural Resources Transfer Agreements that gave control over natural resources to Saskatchewan, Alberta and Manitoba.`,
        `This is an outrageous and ill-informed comment, as those agreements and the provinces' control over natural resources have been entrenched in the Canadian constitution since 1930.`,
        `On what basis does the federal Justice Minister think he has the authority to unilaterally strip Saskatchewan and the other western provinces of our constitutional authority over our natural resources?`,
        `Saskatchewan has always had reason to be concerned about this federal government's agenda to infringe on provincial jurisdiction and autonomy, and we will be relentless in defending our jurisdiction and autonomy.`,
        `The Prime Minister needs to immediately tell his Justice Minister he has no business even speculating about rescinding western provinces' constitutional authority to control our natural resources.`,
        `---`,
        `<b>Justice minister says rescinding resources transfer agreements will be controversial, but he'll look at it</b>`,
        `---`,
        `<b>Danielle Smith - Premier of Alberta:</b>`,
        `I just received word that the Federal Justice Minister may attempt to rescind the 1930 Natural Resources Transfer Agreement with the prairie provinces.`,
        `This would pose an unprecedented risk to national unity and Alberta condemns this federal threat in the strongest possible terms.`,
        `I will be contacting Premiers Scott Moe and Heather Stefanson to discuss next steps and call on the Prime Minister to immediately have his Justice Minister retract and apologize for these comments immediately. `,
      ],
      urls: [
        `https://twitter.com/PremierScottMoe/status/1645462787101949959`,
        `https://windspeaker.com/news/windspeaker-news/justice-minister-says-rescinding-resources-transfer-agreements-will-be`,
        `https://twitter.com/ABDanielleSmith/status/1645481047835176960`,
      ],
    },
    {
      description: [
        `<b>The Trudeau Liberal Government Is Now "Looking At" Seizing Control Over Western Canada's Natural Resources.</b>`,
      ],
      urls: [
        `https://twitter.com/SpencerFernando/status/1645497710471188481`,
        `https://spencerfernando.com/2023/04/10/the-trudeau-liberal-government-is-now-looking-at-seizing-control-over-western-canadas-natural-resources/`,
      ],
    },
    {
      description: [
        `Trudeau tells audience to go into debt on home renovations to help grow the economy.`,
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1645414900779425792`
      ],
    },
    {
      description: [
        `<b>Mislav Kolakusic MEP (Croatian Member of European Parliament)</b>`,
        `PM Trudeau, in recent months, under your quasi-liberal boot, Canada 🇨🇦 has become a symbol of civil rights violations. The methods we have witnessed may be liberal to you, but to many citizens around the world it seemed like a dictatorship of the worst kind.`,
        `---`,
        `Dear colleagues, dear citizens, Prime Minister Trudeau.`,
        `Freedom, the right to choose, the right to life, the right to health, the right to work, for many of us are fundamental human rights for which millions of citizens in Europe and the world have laid down their lives.`,
        `To defend our rights and the rights of our children, which we have acquired over the centuries, many of us, including myself are willing to risk our own freedom and our own lives.`,
        `Unfortunately, today there are those among us who tample on these fundamental values.`,
        `Canada, once a symbol of the modern world, has become a symbol of civil rights violations under your quasi-liberal boot in recent months.`,
        `We watch how you trample women with horses, how you block the bank accounts of single parents so that they can't even pay their children's education and medicine, that they can't pay utilities, mortgages for their homes.`,
        `To you, these may be liberal methods, for many citizens of the world, it is a dictatorship of the worst kind.`,
        `Rest assured that the citizens of the world, united, can stop any regime that wants to destroy the freedom of citizens, either by bombs or harmful pharmaceutical products. Thank you.`,
      ],
      urls: [
        `https://twitter.com/mislavkolakusic/status/1506702485225938949`
      ],
    },
    {
      description: [
        `<b>Christine Anderson MEP (German Member of European Parliament)</b>`,
        `Yesterday, Canada's Prime Minister Justin Trudeau visited the EU Parliament to give a speech. I took the opportunity to give him an appropriate "welcome" there.`,
        `---`,
        `Thank you. Based on Article 195 I would like to say that it would have been more appropriate for Mr. Trudeau, Prime Minister of Canada, to address this house, according to Article 144, and article which was specifically designed to debate violations of human rights, democracy and the rule of law, which is clearly the case with Mr. Trudeau.`,
        `Then again, a Prime Minister, who openly admires the Chinese "basic dictatorship", who tramples on fundamental rights by persecuting and criminalizing his own citizens as terrorists, just because they dared to stand up to his perverted concept of democracy, should NOT be allowed to speak in in this house at all.`,
        `Mr. Trudeau, you are a disgrace for any democracy. Please spare us your presence. Thank you.`,
      ],
      urls: [
        `https://twitter.com/AndersonAfDMdEP/status/1506905594623574020`
      ],
    },
    {
      description: [
        `Newspeak by Justin Trudeau in 2023:`,
        `“There are a lot of pressures on our system. Increasing the immigration levels interestingly will take some of the pressure off of the system.”`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1628177322909081600`
      ],
    },
    {
      description: [
        `As of September 23, 2021 in Canada:`,
        `---`,
        `Coronavirus cases: 1,591,679`,
        `Deaths: 27,559`,
        `Recovered: 1,519,306`,
        `---`,
        `Population of Canada: 38,000,000`,
        `---`,
        `1.73% death rate OF 4.19% infection rate = 0.07% chance of dying from covid.`,
        `In other words:`,
        `Your chances of surviving covid are 99.93% AT ANY AGE OR WITH ANY COMORBIDITIES</b>`,
      ],
      urls: [
        `https://twitter.com/TheManFromAunt/status/1441230858053062656`
      ],
    },
    {
      description: [
        `Former Alberta Chief Medical Officer of Health, Deena Hinshaw's psychotic rituals.`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1441954516010684420`,
        `https://twitter.com/thevivafrei/status/1441947129413464070`,
      ],
    },
    {
      description: [
        `The Liberals are throwing the doors wide open to mass immigration. They're changing the rules so that anyone who comes to Canada and claims to be a refugee will be accepted.`,
        `GUNTER: Liberals to make immigration to Canada much easier`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1442240194791428102`,
        `https://torontosun.com/news/national/gunter-liberals-plan-to-make-immigration-to-canada-much-easier`,
      ],
    },
    {
      description: [
        `<b>National Post: Military leaders saw pandemic as unique opportunity to test propaganda on Canadians: report</b>`,
        `A plan devised by the Canadian Joint Operations Command relied on propaganda techniques similar to those employed during the Afghan war`,
        `---`,
        `It walked like a psy-op, and talked like a psy-op. Now, we know for sure: it *was* a psy-op.`,
        `---`,
        `Wouldn't it be ironic if “trust the science” was the final version of the propaganda techniques the military tested and decided to recommend…`,
      ],
      urls: [
        `https://nationalpost.com/news/national/defence-watch/military-leaders-saw-pandemic-as-unique-opportunity-to-test-propaganda-techniques-on-canadians-forces-report-says/wcm/22733c97-39f0-4ba4-8a26-478af5e215f3`,
        `https://twitter.com/NatCitizens/status/1442514117680525325`,
        `https://twitter.com/thevivafrei/status/1442861187557957634`,
      ],
    },
    {
      description: [
        `Spencer Fernando: How Many Other Government Propaganda Techniques Have Been Used To Keep Canadians In A State Of Fear & Panic?`,
        `<b>A fearful populace = a more powerful government. Politicians know this, and they are exploiting this.</b>`,
      ],
      urls: [
        `https://spencerfernando.com/2021/09/28/how-many-other-government-propaganda-techniques-have-been-used-to-keep-canadians-in-a-state-of-fear-panic/`
      ],
    },
    {
      description: [
        `To maintain a mass psychology of panic and fear, Alberta's corrupt public health bureaucrat now automatically counts anyone staying home from any illness as a Covid case. (september 27, 2021)`,
      ],
      urls: [
        `https://twitter.com/ezralevant/status/1442464544740110342`,
        `https://twitter.com/thevivafrei/status/1442470025168838656`
      ],
    },
    {
      description: [
        `Bill 105 explanatory notes… Don't worry everyone, the Bill will expire when the health emergency ends. The health emergency that was declared in March 2020.`,
        `---`,
        `This bill proposes to regulate demonstrations in relation to the COVID-19 pendemic by prohibiting them within a 50-metre radius of the grounds of certain places, including places where COVID-19 testing or vaccination services are provided, facilities maintained by a health and social services institution, facilities of holders of a childcare centre of day care centre permit issue under the Educational Childcare Act and educational institutions providing preschool, elemenrary or secondary education. The bill also prohibits organizing or inciting anyone to organize such demonstrations.`,
        `The bill contains penal provisions for contravening its provisions and allows a judge of the Superior Court to grant an injunction to prevent any act prohibited by them.`,
        `Lastly, the provisions of the bill will cease to have effect when the public health emergency declared on 13 March 2020 ends.`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1442609877499535369`
      ],
    },
    {
      description: [
        `September 28, 2021: Former Alberta Premier Jason Kenney says Conservatives should "maintain stability" and resist the "instinct" to drop Erin O'Toole as leader after last week's electoral loss. `,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1443006658519748612`,
        `https://tnc.news/2021/09/28/jason-kenney-says-conservatives-should-resist-instinct-to-drop-leader-after-loss/`,
      ],
    },
    {
      description: [
        `CanadaTalks SiriusXM 167:`,
        `<b>INTERNET REGULATION “The govt will tell you there's a monster in the woods and only we can save you”</b>`,
      ],
      urls: [
        `https://soundcloud.com/canadatalks/internet-regulation-the-govt-will-tell-you-theres-a-monster-in-the-woods-and-only-we-can-save-you`
      ],
    },
    {
      description: [
        `<b>The Entire Trudeau Foundation Board Of Directors Has Resigned</b>`,
        `APRIL 11, 2023: President & CEO, along with entire board resign in the wake of reports of Communist China's election interference and donations linked to the Communist State.`,
      ],
      urls: [
        `https://spencerfernando.com/2023/04/11/the-entire-trudeau-foundation-board-of-directors-has-resigned/`
      ],
    },
    {
      description: [
        `<b>Flashback to Trudeau saying the federal government will pay for provincial vaccination programs.</b>`,
        `We're going to work with provinces and territories who want to move forward on vaccination certifications, on vaccination passports, so that everyone can be safe.`,
        `And what's more, the federal government has announced we're going to pay for the development of those <b>privilages that you get once you get vaccinated.</b>`,
        `<b>Because everyone needs to get vaccinated, and *points away* "those people" are putting us all at risk.</b>`,
      ],
      urls: [
        `https://twitter.com/Phil2284/status/1645660217936596992`
      ],
    },
    {
      description: [
        `Justin Trudeau begins answering a question on the Trudeau Foundation by rolling his eyes, and finishes off with his trademark smug, squinty smirk:`,
        `“As you well know, the Trudeau Foundation is a foundation with which I have absolutely no intersection.”`,
        `Remember this moment.`,
      ],
      urls: [
        `https://twitter.com/RealAndyLeeShow/status/1645842614728687616`
      ],
    },
    {
      description: [
        `Why should the Board and CEO resign, and not Justin Trudeau? He was still involved in the Trudeau Foundation when this bribe from the Chinese government was accepted, and this article proves it. It was made in June of 2014.`,
        `Before Justin recused himself.`,
      ],
      urls: [
        `https://twitter.com/RealAndyLeeShow/status/1645818923013783560`,
        `http://www.chncia.org/en/news-detail.php?mid=13&id=34`,
      ],
    },
    {
      description: [
        `<b>Justin Trudeau is now claiming he has zero ties to the TRUDEAU foundation. YEAH RIGHT!</b>`,
        `He didn't just say no “direct” connection… He said no “direct or *indirect* connection”.`,
        `He has opened the door to this being an easily demonstrable lie. I wonder who in Canadian legacy journalism will start looking into it…`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1645885305730351104`,
        `https://twitter.com/truckdriverpleb/status/1645882759234088965`,
      ],
    },
    {
      description: [
        `<b>Trudeau Foundation president, board resign, citing 'politicization' of China-linked donation</b>`,
        `Is that the rustle of rats fleeing a sinking ship?`,
      ],
      urls: [
        `https://twitter.com/jordanbpeterson/status/1645899591764541442`,
        `https://www.cbc.ca/news/politics/trudeau-foundation-resign-1.6806482`,
      ],
    },
    {
      description: [
        `Question: Is oil coming to Canada from Saudi Arabia, Nigeria, and Russia (over $4.5B in 2021) subject to the same rigorous regulations as oil coming from Alberta, Saskatchewan, and Newfoundland and Labrador?`,
        `How is the answer anything but, “Yes, of course it is”?`,
      ],
      urls: [
        `https://twitter.com/MikeLakeMP/status/1646343743401000960`,
      ],
    },
    {
      description: [
        `“Ukrainians right now are fighting for the United Nations charter” -Justin Trudeau`,
      ],
      urls: [
        `https://twitter.com/therobprimo1/status/1646610308436295681`
      ],
    },
    {
      description: [
        `Katie Telford just explained that the Prime Minister reads everything.... except maybe the highly damning information in his daily reading package.`,
        `<b>Daily Foreign Intelligence Brief, 21 February 2020</b>`,
        `2. China/Canada: Subtle but Effective Interference Networks:`,
        `<b>CA Assessment:</b> Investigations into activities linked to the Canadian federal election in 2019, reveal an active foreign interference (FI) network.`,
      ],
      urls: [
        `https://twitter.com/Cooper4SAE/status/1646920301983195149`
      ],
    },
    {
      description: [
        `"Quite possible" Trudeau briefed on election interference in January 2022, Katie Telford says`,
      ],
      urls: [
        `https://twitter.com/scoopercooper/status/1646937010391724033`,
        `https://globalnews.ca/news/9624045/trudeau-chief-staff-katie-telford-election-interference-committee-testimony/`
      ],
    },
    {
      description: [
        `KATIE TELFORD TESTIFIES`,
      ],
      urls: [
        `https://twitter.com/therobprimo1/status/1646913719509843968`
      ],
    },
    {
      description: [
        `November 20, 2022: Global News: Trudeau says he was not briefed on federal candidates allegedly receiving funds from China.`,
        `April 14, 2023: Global News: Trudeau was briefed at least 5 times on foreign interference since 2021`,
      ],
      urls: [
        `https://twitter.com/Roman_Baber/status/1647225114134470662`
      ],
    },
  ],
  CanadianTruckerConvoy: [
    {
      description: [
        `7 claims about the Freedom Convoy - DEBUNKED 8/20/22`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1561458253812334601`,
        `https://twitter.com/ChickenGate/status/1561458417075617798`,
      ],
    },
    {
      description: [
        `Trucker's last day.`,
        `<strong>"If we don't have bodily autonomy, if we don't have control over ourselves, if we don't own our own physical bodies, then we own nothing at all."</strong>`
      ],
      urls: [
        `https://twitter.com/TonyErb3/status/1482787382814457863`
      ],
    },
    {
      description: [
        `"Open your eyes to what is going on" (Jan 13, 2022)`,
        `"I've been put on unpaid leave thanks to Justin Trudeau."`,
        `"Ladies and gentlemen look around your house, look at everything you've got. If you've got it, a truck brought it."`
      ],
      urls: [
        `https://twitter.com/EzraKahan/status/1483134186919706626`
      ],
    },
    {
      description: [
        `"They spend 95% of their time in self isolation at it is, this is going to have an inperceptible effect on public health whatsoever, and a catastrophic effect on the food supply shortage."`,
      ],
      urls: [
        `https://twitter.com/YukonStrong/status/1481919587243331587`
      ],
    },
    {
      description: [
        `This convoy going to Trudeau's doorstep is longer than the convoy of Russian troops headed to the Ukraine border. Justin, you've got a problem on your hands. (Jan 21, 2022)`,
      ],
      urls: [
        `https://twitter.com/TheRealKeean/status/1484736734944391168`
      ],
    },
    {
      description: [
        `Une foule pour accourager le #FreedomConvoy2022 à Saint-Nicolas`,
        `Hundreds of people cheering on truckers at an overpass in Saint-Nicolas`,
      ],
      urls: [
        `https://twitter.com/djflex0425/status/1487163027065552898`
      ],
    },
    {
      description: [
        `An army of patriotic women make sandwiches to hand the trucker drivers of the convoy for freedom.`,
      ],
      urls: [
        `https://gettr.com/post/pqdr5zd99f`
      ],
    },
    {
      description: [
        `This is being reported online as 50k Canadian truckers against covid tyranny`,
      ],
      urls: [
        `https://gettr.com/post/pqbacl449c`
      ],
    },
    {
      description: [
        `Crying as I watch footage of the truckers freedom convoy, I am realizing how much trauma our canadian government has caused`,
      ],
      urls: [
        `https://twitter.com/EcoFashionByBG/status/1485657732879245312`
      ],
    },
    {
      description: [
        `Policewoman sends out message of support for the trucker convoy against covid tyranny rolling through Canada.`,
        `"You're fighting for our rights and freedoms, and those rights and freedoms are at stake."`
      ],
      urls: [
        `https://gettr.com/post/pqdpzjcfcc`
      ],
    },
    {
      description: [
        `Why Canadian Media Isn't Talking About Freedom Convoy 2022: Lawyer`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=K9K2-xFYj9c`
      ],
    },
    {
      description: [
        `People dancing wih each other, music playing, all ages.`,
        `Justin Trudeau called this protest “illegal”`,
      ],
      urls: [
        `https://twitter.com/TheMarieOakes/status/1492280933663268864`,
      ],
    },
    {
      description: [
        `A bunch of videos on the ground in Ottawa, of people singing, dancing. People of all ages and races singing Oh Canada, and cheering with each other.`,
      ],
      urls: [
        `https://threadreaderapp.com/thread/1492356083192668160.html`,
      ],
    },
    {
      description: [
        `Standing and singing "Oh Canada"`,
        `Even though police have been moving in on protesters in Windsor, pushing them back, the crowd size actually seems to be growing. More protesters are arriving and few are leaving.`,
      ],
      urls: [
        `https://twitter.com/thomasdaigle/status/1492546435396648964`,
      ],
    },
    {
      description: [
        `<strong>Video after video of Canadians being peaceful and helping the community, played behind audio of Justin Trudeau's lies.</strong>`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1489334216672305152`
      ],
    },
    {
      description: [
        `Rave Party straight on Ottawa Parliament. Multicultural peace, dancing and music.`,
      ],
      urls: [
        `https://twitter.com/Qc_Browno/status/1487819369484890121`
      ],
    },
    {
      description: [
        `Natives bang drums and sing outside Canadian Parliament.`,
      ],
      urls: [
        `https://twitter.com/Micheben/status/1487615005403103237`
      ],
    },
    {
      description: [
        `To the truckers: "You're everbody's hope."`,
      ],
      urls: [
        `https://www.bitchute.com/video/nF4rMmuVfaup/`
      ],
    },
    {
      description: [
        `Hundreds of Canadians of all ages dancing in the street.`,
      ],
      urls: [
        `https://twitter.com/jamiegagnon/status/1487632162547322880`
      ],
    },
    {
      description: [
        `Children leaving phone messages thanking the truckers for fighting for them.`,
      ],
      urls: [
        `https://twitter.com/NgyNina/status/1487820814636355587`
      ],
    },
    {
      description: [
        `Note from 11yr old girl hits trucker right in the heart`,
      ],
      urls: [
        `https://gettr.com/post/prt5h2cf61`
      ],
    },
    {
      description: [
        `Dozens of letters from children cheering the truckers on, taped to the outside of a truck.`,
      ],
      urls: [
        `https://gettr.com/post/ptpr66e7c6`
      ],
    },
    {
      description: [
        `Participants of the Freedom Convoy picking up trash from the streets.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1487833095940161536`
      ],
    },
    {
      description: [
        `Clan mother Noreen from Yellowknife offers blessing to convoy.`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1487824100907270149`
      ],
    },
    {
      description: [
        `Lots of crowd support for the #TruckersForFreedom in Acheson, Alberta on route to Ottawa. (Jan 23, 2022)`,
      ],
      urls: [
        `https://twitter.com/realmonsanto/status/1485465164967301124`
      ],
    },
    {
      description: [
        `Another overpass. In BC maybe. 2 minutes straight of highway honking.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1485358866896461829`
      ],
    },
    {
      description: [
        `Trucker Convoy Departs from British Columbia en Route to Ottawa for Arrival on Jan 29th (Jan 23 2022)`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=H9rKb5WaoeM`
      ],
    },
    {
      description: [
        `January 10, 2022. The beginnings of the entire movement..`,
        `"When the fuck is enough enough?"`,
        `"All those guys out there saying it'll never happen? It'll never happen if you have that fucking attitude."`
      ],
      urls: [
        `https://twitter.com/kacrn91/status/1480499998928293897`
      ],
    },
    {
      description: [
        `First images of the convoy <strong>Mandate Freedom</strong> (Jan 18, 2022)`,
      ],
      urls: [
        `https://twitter.com/risemelbourne/status/1483537748044947456`
      ],
    },
    {
      description: [
        `This Sikh family made me so proud. Sikhs for freedom!`,
        `"We came in yesterday for our freedom and for our rights. We should have a choice to choose whatever good for me, my kids.`,
        `<strong>That's all we're here for.</strong>"`
      ],
      urls: [
        `https://twitter.com/atwal_manjit/status/1487882022844448769`
      ],
    },
    {
      description: [
        `There are so many videos like this one - expressing love, hope and unity. It's so amazing to see.`,
        `Indian man talking about the overwhelming love and support, that they're not racists.`,
        `<strong>"This is real Canada. United, strong and free. And whoever is trying to divide us, let's show them we are one."</strong>`
      ],
      urls: [
        `https://twitter.com/PaolaQP1231/status/1487543702235004937`
      ],
    },
    {
      description: [
        `Mallorytown service station. Patriotic Canadians arriving as I get back on the road.`,
        `Diverse group of every skin colour and several different languages heard. <strong>Good luck with the racist dog whistle angle.</strong>`,
      ],
      urls: [
        `https://twitter.com/canmericanized/status/1487452111587753986`
      ],
    },
    {
      description: [
        `Convoy protesters carefully clean and take care of the Terry Fox memorial in downtown Ottawa.`,
        `They were not defacing it as the media proclaimed.`,
      ],
      urls: [
        `https://twitter.com/TheRealKeean/status/1487947493849632770`
      ],
    },
    {
      description: [
        `Guy asks Ottawa Police about possible violence. Police themselves are unaware of apparent provocateurs.`,
      ],
      urls: [
        `https://twitter.com/TransSplendor/status/1487943518521860102`
      ],
    },
    {
      description: [
        `Footage of convoy journey across Canada. Overpass after overpass of hundreds and thousands of peaceful supporters with Canada flags.`,
      ],
      urls: [
        `https://twitter.com/zillinator/status/1485580577373925378`
      ],
    },
    {
      description: [
        `Pierre Poilievre on an overpass: Freedom over fear.`,
      ],
      urls: [
        `https://twitter.com/PierrePoilievre/status/1487546518085197825`
      ],
    },
    {
      description: [
        `Convoy video compilation`,
      ],
      urls: [
        `https://rumble.com/vt891v-truckers-for-freedom-2022.html`
      ],
    },
    {
      description: [
        `Lady who came to Canada 25 years ago praising convoy, "This is real Canada"`,
      ],
      urls: [
        `https://twitter.com/DarkHorseRider4/status/1487132280241881089`
      ],
    },
    {
      description: [
        `Streets and roadsides are packed in Ottawa, Canada this morning as this weekend's #TruckersForFreedom protests against the COVID police state kick off. (Jan 29, 2022)`,
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1487473023133229063`
      ],
    },
    {
      description: [
        `This is just another peaceful rally like dozens of others I have attended in the past two years, with patriotic Canadians braving the bitter cold. Not a violent insurrection.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1487501579729186816`
      ],
    },
    {
      description: [
        `From a friend on the ground in Ottawa. Thousands of peaceful people.`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1487491355131596801`
      ],
    },
    {
      description: [
        `Prime Minister Justin Trudeau says the #TruckersForFreedom convoy is a`,
        `<strong>"small fringe minority" who hold "unacceptable views.</strong>"`,
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1486473753991299072`
      ],
    },
    {
      description: [
        `<strong>Justin Trudeau accuses the trucker convoy crowd of "hateful rhetoric" and "violence toward citizens,"</strong>`,
        `He rather prefers to attend BLM protests.`,
      ],
      urls: [
        `https://twitter.com/disclosetv/status/1488201136687431680`
      ],
    },
    {
      description: [
        `Canadian radio show host leaves a final message by speaking the truth on air before leaving the show.`,
        `<b>"If you think the Freedom Convoy in Ottawa is a racist movement... you have been tricked.</b>`,
      ],
      urls: [
        `https://gettr.com/post/ptw1282c47`
      ],
    },
    {
      description: [
        `To our magnificent heros`,
      ],
      urls: [
        `https://rumble.com/vteg30-truckers-for-freedom-to-our-magnificent-heroes.html`
      ],
    },
    {
      description: [
        `Mike from Canada nails it.`,
      ],
      urls: [
        `https://twitter.com/MilaKedrov/status/1487831312324984832`
      ],
    },
    {
      description: [
        `It's an absolute freedom festival in Ottawa. The spell is broken.`,
      ],
      urls: [
        `https://twitter.com/WG_Gervais/status/1487490765563568134`
      ],
    },
    {
      description: [
        `Trucks are far as the eye can see.`,
        `Outside of Regina, SK. You are NOT alone. You were NEVER alone.`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1487495824040886273`
      ],
    },
    {
      description: [
        `Peaceful protesters linking arms and standing in place in Milk River, Alberta.`,
        `Sign reads, "SO MANY LIES".`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1492553671715995650`,
      ],
    },
    {
      description: [
        `Regardless of the outcome, teach your kids about the day CDNs stood together in unity against tyranny. (January 29, 2022)`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1487494473017819139`
      ],
    },
    {
      description: [
        `MP Philip Lawrence statement about convoy.`,
        `"They want to know that this government understands their pain."`,
        `"However, when politicians argue about the behaviours of a small group within this protest, or what exactly is on the list of grievances, we are missing the point."`
      ],
      urls: [
        `https://twitter.com/PLawrenceMP/status/1487886587874656256`
      ],
    },
    {
      description: [
        `Dr. Roger Hodkinson: "It relied on guys with dirty fingernails to move the needle. Medicine, politics, media have failed."`,
      ],
      urls: [
        `https://twitter.com/TransSplendor/status/1487957669805707266`
      ],
    },
    {
      description: [
        `Many pictures of many peaceful protesters of all ages and races, holding signs demanding an end to mandates and medical coersion.`,
        `Trudeau's empty threats: Freedom Convoy truckers are STILL on US-Canada bridge after both a 7 pm court AND midnight deadline from Ontario police to forcibly remove them came and went`,
      ],
      urls: [
        `https://www.dailymail.co.uk/news/article-10503779/Justin-Trudeau-says-table-crack-Freedom-Convoy.html`,
      ],
    },
    {
      description: [
        `Apparently Trudeau is “Watching in disgust and disbelief at this behaviour”?`,
        `Justin Trudeau, you dressed in blackface, groped a reporter, committed 2 ethics breaches, paid your family from a charity, donated our POE & killed our elderly… And you dare speak such words about good, patriotic Canadians!`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1488350706834853899`
      ],
    },
    {
      description: [
        `Maxime Bernier's reponse to Justin Trudeau calling his leadership "irresponsible".`,
        `You are saying we are promoting disinformation? <strong>You did it.</strong> You did it in the beginning of the pandemic. <strong>Now we know the facts, we know the truth.</strong>`,
        `Everyone wants to be free in Canada, and that was the message of the weekend here in Ottawa.`,
        `I was there with them. Vaccinated people, unvaccinated people, were with us in Ottawa. <strong>Where were you? You were not there. You were not listening.</strong>`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1488249466859761671`
      ],
    },
    {
      description: [
        `An MD lies on Twitter: "the saddest part of all this is that there are more people that died this week (442) than there are people on parliament hill in Ottawa right now."`,
        `Video proof of lies.`
      ],
      urls: [
        `https://twitter.com/Humanlty1o1/status/1487491729011920899`
      ],
    },
    {
      description: [
        `<strong>Ottawa police officer</strong> calls for the chief to resign after investigating the #ConvoyToOttawa protesters`,
        `"You sir are an embarassment."`,
        `"Treating the truckers like they're terrorists. I've been to Ottawa. I've seen what's gone on down there.`,
        `I've seen the people first hand shoveling the sidewalks, shoveling the roads, picking up garbage. I've watched the food be handed out to the homeless. Those truck drivers and the people of the convoy have done more for the homeless people in that city than that poor excuse for a mayor has done in I don't know how long.`,
        `I've watched blankets being given out. Food, friendship being offered to those that need it.`,
        `<strong>I don't see terrorists, I see people who give a care about their country.</strong>"`,
      ],
      urls: [
        `https://twitter.com/Krommsan/status/1492340604310753280`,
      ],
    },
    {
      description: [
        `Dr. Robert W Malone MD, MS`,
        `Open Letter to the Canadian Truckers`,
        `<strong>1. For the first time, we can clearly see that they are willing and able to collude with Silicon Valley to weaponize the banking system against us.</strong>`,
        `<strong>2. The censorship is ramping up, and our opponents are no longer really trying to justify it.</strong>`,
        `<strong>3. So desperate are these conspirators, they've now literally asked the US Department of Defense to commit a felony; changing data in the Defense Medical Epidemiological Database.</strong>`,
        `<strong>4. It's time to end the FAKE Emergency powers which have been used to suspend your constitutional and natural rights.</strong>`,
        `These genetic vaccines can damage your children.  They may damage their brain, their heart, their immune system, and their ability to have children in the future.  And many of these types of damages cannot be repaired.`,
      ],
      urls: [
        `https://rwmalonemd.substack.com/p/open-letter-to-the-canadian-truckers?utm_campaign=post&utm_medium=web`,
      ],
    },
    {
      description: [
        `The trucks are arriving in Ottawa`,
      ],
      urls: [
        `https://www.bitchute.com/video/Pm7IbnI6DA5r/`
      ],
    },
    {
      description: [
        `Farmers arriving to join the convoy for freedom in Ottawa after travelling 400 miles via tractor.`,
      ],
      urls: [
        `https://gettr.com/post/prt3uk5ec0`
      ],
    },
    {
      description: [
        `Protester to Ottawa Police: "How has it been down here?"`,
        `Ottawa Policeman: "Nothing but good. People are super good. The truckers have been having a good time, they've been getting their point across, and you know what, it's a beautiful city we're in and... <b>they can't ignore all this.</b>`,
      ],
      urls: [
        `https://gettr.com/post/prt3wh4c19`
      ],
    },
    {
      description: [
        `Neil Oliver's latest covid tyranny monologue, showing his support for the Canadian convoy for freedom too`,
      ],
      urls: [
        `https://gettr.com/post/prhu667bd0`
      ],
    },
    {
      description: [
        `Polish women bring food to the Canadian truckers:`,
        `"We come from a communist country and we came here because we didn't want to have oppression, we wanted to live in a free country...”`,
      ],
      urls: [
        `https://gettr.com/post/ps2jje8cff`
      ],
    },
    {
      description: [
        `Ottawa Council zoom meeting encouraging people to report the Canadian convoy for freedom go fund me, even calling them "unlawful mercenaries"!`,
      ],
      urls: [
        `https://gettr.com/post/ps1mc36a60`
      ],
    },
    {
      description: [
        `"It's hard to overstate the historical significance of what we're watching right here. The Canadian trucker convoy is the single most successful human rights protest in a generation."`,
        `Justin Trudeau is attacking human rights`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=ou5A8Glngr8`,
      ],
    },
    {
      description: [
        `A message to Justin Trudeau by a protester at Coutts, Alberta blockade:`,
        `“We are united. This is not a white supremacist group. We are not a small fringe minority.”`,
      ],
      urls: [
        `https://twitter.com/BezirganMocha/status/1490194308837740549`
      ],
    },
    {
      description: [
        `Protestors are urged to remain calm after police seized fuel and cleared Confederation Park.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1490502976141611011`
      ],
    },
    {
      description: [
        `Massive convoy of truckers have blockaded the US-Canada border crossing in Alberta (Feb 4, 2022)`,
      ],
      urls: [
        `https://twitter.com/biggs1_terry/status/1489613678273974277`
      ],
    },
    {
      description: [
        `Protesters are already starting to gather in Quebec City, Quebec, Canada for this weekends convoy protest in Quebec City. (Feb 3, 2022)`,
      ],
      urls: [
        `https://twitter.com/TheMarieOakes/status/1489411490608291844`
      ],
    },
    {
      description: [
        `Video on the ground of the peaceful protest in Ottawa.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=nMV4LdJgmH0`
      ],
    },
    {
      description: [
        `Retired RCMP Officer Speaks Out: Officer's Oath, Lawful Orders, People's Courage (Feb 13 2022)`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=9ZKzhWw3f38`
      ],
    },
    {
      description: [
        `Lawyer speaks about constitutional and mobility rights, comments on disturbing announcements from Ottawa Police Chief.`,
        `"The police chief essentially announced an assault on the protesters.`,
        `<strong>He announced very specific measures that we normally only see instituted by oppressive regimes around the world."</strong>`
      ],
      urls: [
        `https://twitter.com/ikwilson/status/1489732129105850368`
      ],
    },
    {
      description: [
        `Ottawa's police chief says he will investigate any police officer that provides any support to the Freedom Convoy truckers`,
      ],
      urls: [
        `https://rumble.com/vu1x1x-february-4-2022.html`
      ],
    },
    {
      description: [
        `REEDOM TRUCKER Liaisons RESPOND to OTTAWA Police Chief Sloly's CLAIMS`,
        `Watching the Watchers - Robert Gouveia Esq.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=cWkAmBK2Q0M`
      ],
    },
    {
      description: [
        `Kate Wand comments on the Ottawa Police taking fuel and condemning those bringing food, etc.`,
        `"Ottawa Stasi have gone in to remove fuel from peaceful, unarmed citizens exercising their civil rights, simultaneously buying out every empty hotel room, leaving them without heat or back-up shelter... in -25 below freezing temperature."`,
      ],
      urls: [
        `https://twitter.com/katewand/status/1490506464078843904`
      ],
    },
    {
      description: [
        `Police seizing fuel in Ottawa`,
      ],
      urls: [
        `https://twitter.com/CCryptonaire/status/1490485206742024192`
      ],
    },
    {
      description: [
        `Protesters filling fuel and commenting on the government preventing people from bringing fuel in -25C, and buying up all the hotels in the area.`,
        `"They've got families, they've got kids. And they're going to put these guys in the street."`,
         `"I don't know what kind of fight they're in but it's not fuckin' right."`,
         `"We're just one people trying to help each other, it doesn't matter what race, creed, gender, it doesn't matter, we're all in this together."`
      ],
      urls: [
        `https://twitter.com/ikwilson/status/1490475998067974146`
      ],
    },
    {
      description: [
        `Unvaccinated truckers delivering vaccines, COVID devices exempt from border rules. Absurd.`,
      ],
      urls: [
        `https://twitter.com/SpencerFernando/status/1494338339498987523`
      ],
    },
    {
      description: [
        `Re: the person who purposefully drove through a crowd of protesters:`,
        `Trudeau called for Canadians to marginalize the unvaccinated.`,
        `He said they should not be tolerated. He said they were racist, misogynist, violent.`,
        `Someone heard that, believed it, and was radicalized. And committed this act of terrorist violence.`,
      ],
      urls: [
        `https://twitter.com/ezralevant/status/1490036065629085696`
      ],
    },
    {
      description: [
        `"I'm sorry about the 'noise complaints', are you sorry about banishing me from society, and treating me like I'm some sort of leper, because I want to keep my immune system in tact? Sorry guys, the horns are staying."`,
        `<strong>"Stuff it up your ass, anyone that has a problem with loud noises. We have a problem with being banished from society."</strong>`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1489978251288014848`
      ],
    },
    {
      description: [
        `Ottawa may euthanize truckers' pets as punishment`,
        `<strong>From Ottawa By-law:</strong>`,
        `"Attention animal owners at demonstration. If you are unable to care for your animal as a result of enforcement actions, your animal will placed into protective care for 8 days, at your cost."`,
        `<strong>"After 8 days, if arrangements are not made, your animal will be considered relinquished."</strong>`,
      ],
      urls: [
        `https://www.thecountersignal.com/news/ottawa-police-may-euthanize-truckers-pets-as-punishment`,
        `https://twitter.com/TheRealKeean/status/1494357532474691585`,
        `https://twitter.com/OttawaBylaw/status/1494306645274509316`,
        `https://twitter.com/elonmusk/status/1494538963192729606`,
      ],
    },
    {
      description: [
        `Cops Turn On Cops Over Canadian Trucker Protest`,
        `Jimmy Dore`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=HuLxtunpjwc`
      ],
    },
    {
      description: [
        `Trudeau's Public Safety Minister wants you to believe that the protesters were “attacking” the National War Monument.`,
        `They were actually cleaning it, peacefully singing Oh Canada.`
      ],
      urls: [
        `https://twitter.com/Harry__Faulkner/status/1494343510102839299`
      ],
    },
    {
      description: [
        `Ottawa Freedom Convoy organizer Tamara Lich says there is a good chance she is going to jail`,
        `"I want you to know that I'm not afraid"`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1494346606300348418`
      ],
    },
    {
      description: [
        `Ex-RCMP officer Daniel Bulford on possible firearms being planted to discredit the protest, and to be used as a pretext to forcibly remove peaceful protesters.`,
      ],
      urls: [
        `https://www.bitchute.com/video/bTPUgELMyuWo/`
      ],
    },
    {
      description: [
        `Bouncy castle is being set up at the freedom protest in Ottawa, amid a heavy police presence.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1494384087674474515`
      ],
    },
    {
      description: [
        `Children jumping around on haybales. Ottawa "state of emergency"`,
      ],
      urls: [
        `https://gettr.com/post/psx07t75fa`
      ],
    },
    {
      description: [
        `Outside the Canadian embassy in New York City. Activist against mandate gather to show their support for the people of Canada , they sing and dance.`,
      ],
      urls: [
        `https://twitter.com/ViralNewsNYC/status/1493658764720943108`
      ],
    },
    {
      description: [
        `Trudeau said in 2020 “it's never appropriate” for Canada to use army on its own citizens`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1489029600441217024`
      ],
    },
    {
      description: [
        `Saskatchewan Premier Scott Moe:`,
        `The vaccine mandate imposed by the Trudeau government on the trucking industry is unnecessary, and will surely have significant impacts on the household goods and products that Canadians rely on.`
      ],
      urls: [
        `https://twitter.com/PremierScottMoe/status/1485799825614553088`
      ],
    },
    {
      description: [
        `Calgary is on fyre. Hundreds or thousands of people marching in the streets yelling "FREEDOM" (February 19, 2022)`,
        `Calgary, Canada out in force, this is fast becoming a nationwide fight.`
      ],
      urls: [
        `https://twitter.com/DerAchsenZeit/status/1495165441374707713`,
        `https://gettr.com/post/pvmoj46fb2`,
      ],
    },
    {
      description: [
        `Québec convoy for freedom blasting out that sound of freedom, Trudeau has opened a can of worms by going in hard on Ottawa.`,
      ],
      urls: [
        `https://gettr.com/post/pvmush5d55`
      ],
    },
    {
      description: [
        `Protester arrested at gunpoint in Ottawa from a truck with 'liberte'(freedom)painted on the side, speaks volumes in itself.`,
      ],
      urls: [
        `https://gettr.com/post/pvlhgaa9e5`
      ],
    },
    {
      description: [
        `Interim Ottawa police chief: "If you are involved in this protest, we will actively look to identify you and follow up with financial sanctions and criminal charges, absolutely, this investigation will go on for months to come."`,
        `Who is this guy? Why did the police chief quit and this dictator take over? This is insane behaviour, who is pulling the strings?`,
      ],
      urls: [
        `https://gettr.com/post/pvlsp2246e`
      ],
    },
    {
      description: [
        `For the second day in a row, Ottawa police physically attack peaceful protesters`,
      ],
      urls: [
        `https://gettr.com/post/pvkr6eef90`
      ],
    },
    {
      description: [
        `Ottawa protesters kneel in prayer as lines of riot units face off with them.`,
      ],
      urls: [
        `https://gettr.com/post/pvkrq2d9c7`
      ],
    },
    {
      description: [
        `"Look at our friends up north... protesting peacefully. And what the fuck is their media calling them?`,
        `They're calling them terrorists. Doxxing people who donate to their cause. Confiscating their money and their property. They're terrorists, they're calling them.`,
      ],
      urls: [
        `https://gettr.com/post/pvjn2z78ed`
      ],
    },
    {
      description: [
        `Armed units up high, water cannon, teargas, all for peaceful protesters!`,
        `"This is super intimidating. This is not the kind of thing that makes sense given what has actually gone down here."`
      ],
      urls: [
        `https://gettr.com/post/pvjz039e2c`
      ],
    },
    {
      description: [
        `LRAD sonic weapon spotted on the ground in Ottawa..`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1495608557370494976`
      ],
    },
    {
      description: [
        `Freeland: "The way to get your account unfrozen is to stop being part of the blockade and occupation."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1495810602580729861`,
        `https://twitter.com/aginnt/status/1495830438891630592`,
      ],
    },
    {
      description: [
        `If we lose Ottawa, we lose everything.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=UiBcXY4AbeQ`
      ],
    },
    {
      description: [
        `OTTAWA Police HARASS Canadians as TRUDEAU Craves POWER`,
        `Watching the Watchers`,
        `Officers threatening to break down windows and doors in businesses, and yelling at old ladies. No joke.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=z-PZ4fEqi0w`
      ],
    },
    {
      description: [
        `Mayor of Ottawa: “I've asked our solicitor and our city manager, "how can we keep the tow trucks and the campers and the vans and everything else that we've confiscated, and sell those pieces of equipment to help recoup some of the costs that our taxpayers are absorbing?"”`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1495840068359106568`
      ],
    },
    {
      description: [
        `Mayor of Ottawa suggests selling vehicles confiscated from freedom protesters`,
      ],
      urls: [
        `https://thepostmillennial.com/mayor-of-ottawa-suggests-selling-vehicles-confiscated-from-freedom-protesters`
      ],
    },
    {
      description: [
        `The only windows that were broken in 3 weeks were by cops`,
      ],
      urls: [
        `https://twitter.com/SuperMikhail1/status/1495771190249639938`
      ],
    },
    {
      description: [
        `Indigenous elder who was trampled during freedom protest crackdown says Trudeau can't be trusted`,
      ],
      urls: [
        `https://thepostmillennial.com/indigenous-elder-who-was-trampled-during-freedom-protest-crackdown-says-trudeau-cant-be-trusted`
      ],
    },
    {
      description: [
        `Canadian MP @YaaraSaks says that Honk Honk is an acronym for Heil Hitler.`,
        `She is literally saying that if you blow your car horn it makes you a Nazi.`,
        `<b>Words cannot express how insane this comment is from a member of parliament. - David Anber, Criminal Lawyer</b>`
      ],
      urls: [
        `https://twitter.com/DavidAnber/status/1495983177210511364`
      ],
    },
    {
      description: [
        `Andrew Lawton talks to organizaer Tom Marazzo.`,
        `"We've been punished without due process"`,
        `<b>"The government of Canada goes on air and literally brags, about the fact that despite the emergencies act, they have every intention of keeping this power.</b>`,
        `If you don't agree with them, if you have wrongthink, they're going to come for you."`,
        `"We're still fighting for the freedoms of the people who hate us."`
      ],
      urls: [
        `https://www.youtube.com/watch?v=JT32J2OAkpU`
      ],
    },
    {
      description: [
        `The price of Freedom in Canada`,
        `<b>Cops beat and almost suffocate protester.</b>`,
        `Cops give ultimatums.`,
        `Either sign this form which says you won't participate in Ottawa protests.`,
        `Or you'll lose your buisness, ability to cross the border, among other things.`,
        `Then after protester says "charge me", he was bought directly into release room and told he was free to go.`
      ],
      urls: [
        `https://twitter.com/DeniseS0718/status/1496332937582817283`
      ],
    },
    {
      description: [
        `"Non-affiliated" Senator Marilou McPhedran says that elements inside the freedom convoy were "publicly proclaiming that they want a racial war" and that "if there isn't a racial war then we will all soon be forced to speak hebrew.", she adds, "it's an approximate quote".`,
        `Calls it a "seige of Ottawa".`
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1496290561598365706`
      ],
    },
    {
      description: [
        `A Snake News Crew Gets Spit On And Chased Away By Pissed-Off Patriots At Surrey, BC Freedom Rally`,
      ],
      urls: [
        `https://www.bitchute.com/video/SqbekR65lz6S/`
      ],
    },
    {
      description: [
        `Almost surreal:`,
        `Barely a week after a Toronto mounted police officer trampled a freedom protestor in Ottawa, after the Stand With Ukraine rally a family was taking a pic as two mounted officers came down the street.`,
        `The officers brought the riot ponies closer for the photo.`,
      ],
      urls: [
        `https://twitter.com/PuffinsPictures/status/1498119587073867779`
      ],
    },
    {
      description: [
        `Bylaw officer taking pictures of a residential lawn sign supporting the truckers`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=qsuMP4LTM8U`
      ],
    },
    {
      description: [
        `Public safety minister says invoking the Emergencies Act was justified because Ottawa residents were subjected to “intimidation, harassment & threats of rape” by truckers`,
      ],
      urls: [
        `https://rumble.com/vw3891-february-28-2022.html`
      ],
    },
    {
      description: [
        `<b>Canadian Deputy PM holds fascist banner at Ukraine protest</b>`,
        `The media spent weeks harping over white supremacy after bad actors showed up for a 5 minute photo op on day one to slander.`,
        `Meanwhile deputy PM Chrystia Freeland literally marches with a fascist Ukraine banner`,
        `<b>After Deputy Prime Minister Chrystia Freeland tweeted and then removed a controversial photo in which she's seen carrying a flag of renowned Nazi collaborator 'Ukrainian Insurgent Army,' Canadians were outraged.</b>`,
      ],
      urls: [
        `https://twitter.com/YukonStrong/status/1498693709285793802`
      ],
    },
    {
      description: [
        `Truck impound post protest. Missing generators, broken windows, body damage, by police.`,
      ],
      urls: [
        `https://www.bitchute.com/video/SsK9ZNRKW_8/`
      ],
    },
    {
      description: [
        `Iranian man who was put in prison for peacefully protesting, warns that he doesn't want to see the same happen in Canada.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=6bjp_MVTUfk`
      ],
    },
    {
      description: [
        `Peaceful freedom convoy in Victoria, British Columbia (Mar 6, 2022)`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=G_bjZpYTwNI`
      ],
    },
    {
      description: [
        `The Freedom Convoy in Canada returns to Parliament Hill to call for Trudeau to resign.`,
      ],
      urls: [
        `https://twitter.com/aginnt/status/1500316383644725252`
      ],
    },
    {
      description: [
        `RCMP gave banks police info on Ottawa protesters with list of accounts to freeze`,
      ],
      urls: [
        `https://www.ctvnews.ca/canada/rcmp-gave-banks-police-info-on-ottawa-protesters-with-list-of-accounts-to-freeze-1.5809615`
      ],
    },
    {
      description: [
        `Protests still going on foot in Victoria BC.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=RY-T2iesuM4`,
        `https://www.youtube.com/watch?v=KEECbwg14Mo`,
        `https://www.youtube.com/watch?v=DXE6hFEb-4A`,
        `https://www.youtube.com/watch?v=44FTYasduGE`,
      ],
    },
    {
      description: [
        `<b>Five times Liberal claims about the Freedom Convoy were officially disputed</b>`,
        `No wealthy foreign donors`,
        `RCMP saw no evidence of terrorist financing`,
        `Convoy was funded by Canadians, not foreigners`,
        `FINTRAC official says fed-up people, not terrorists, funded convoy`,
        `Foreign extremists not involved in supporting convoy`,
      ],
      urls: [
        `https://tnc.news/2022/03/19/five-times-liberal-claims-about-the-freedom-convoy-were-officially-disputed/`
      ],
    },
    {
      description: [
        `Still going - March 26, 2022 - The weekly Freedom March makes its way through downtown Toronto.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1507787007283585033`
      ],
    },
    {
      description: [
        `TRUCKERS WERE RIGHT:`,
        `<b>Freedom Convoy protest was legal, judge says,</b> and Ottawa is now being sued`,
        `The Canadian government trampled on fundamental human rights with its COVID restrictions, then overreached on dealing with the Freedom Convoy and must now answer to numerous lawsuits, including one by a former premier, says a well-respected constitutional lawyer.`,
      ],
      urls: [
        `https://farmersforum.com/truckers-were-right-freedom-convoy-protest-was-legal-judge-says-and-ottawa-is-now-being-sued/`
      ],
    },
    {
      description: [
        `Toronto March 19, 2022.. still going.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=FPrxqGiGzwY`
      ],
    },
    {
      description: [
        `Disinformation about the trucker convoy already admitted.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=oe3jiPQFIHk`
      ],
    },
    {
      description: [
        `Conservative MP @GlenMotz calls out Public Safety Minister @MarcoMendicino for repeating the debunked claim that the Freedom Convoy was responsible for an attempted apartment arson in Ottawa.`,
        `"That statement right there has been proven false by the Ottawa police service, and there is no connection to the protesters whatsoever."`
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1519324321449136128`
      ],
    },
    {
      description: [
        `Who is actually spreading the MOST Misinformation/Disinformation in Canada?`,
        `<b>"I've never seem this amount of support in my life in this country for anything! If anything, this is actually the most unified and diverse group of Canadians I've ever seen, on message on the same point.</b>`,
        `<b>But $10 million, that most of these trickers have gotten, if all of this was a political party and a political movement it would be the most well funded one in the country. So what does that tell you?</b>`
      ],
      urls: [
        `https://twitter.com/CanAditude/status/1519464871359877120`
      ],
    },
    {
      description: [
        `Here is list to date of allegations against truckers convoy proven false by police & other federal authorities.`,
      ],
      urls: [
        `https://twitter.com/hollyanndoan/status/1520021212235190272`,
        `https://threadreaderapp.com/thread/1520021212235190272.html`,
      ],
    },
    {
      description: [
        `Deputy PM Chrystia Freeland giddy laughing & bouncing while smirking with joy about FREEZING CANADIAN CITIZEN BANK ACCOUNTS that Donated To The Freedom Convoy. Scary.`,
      ],
      urls: [
        `https://twitter.com/5dme81/status/1538714696081088512`
      ],
    },
    {
      description: [
        `Montage of thousands of non-violent Canadians at the 2022 trucker convoy.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1489334216672305152`
      ],
    },
    {
      description: [
        `<b>When you listen to the testimony I urge you to view this video compilation and never forget or forgive what Trudeau did using paramilitary tactics to trample on Citizens who had a legal right & justification to protest</b>`,
      ],
      urls: [
        `https://twitter.com/Concern70732755/status/1586252118045900802`
      ],
    },
    {
      description: [
        `Larry Brock MP - Brantford-Brant ON: "They were there to speak to a Government official, that didn't happen"`,
        `This is a must watch.`,
      ],
      urls: [
        `https://twitter.com/ryangerritsen/status/1586064968302010368`
      ],
    },
    {
      description: [
        `German MEP Christine Anderson speaks to hundreds of Canadians in Cambridge, Ontario.`,
      ],
      urls: [
        `https://twitter.com/canindependent/status/1627848196117483520`
      ],
    },
    {
      description: [
        `They apologize when it's the commercially expedient thing to do. Then continue with the abuse when they think nobody's looking.`,
        `<b>Scotiabank apologizes for freezing bank accounts during the emergencies act</b>`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1616783782555549696`
      ],
    },
    {
      description: [
        `<b>The feds were rolling back quarantine restrictions on illegal border crossers while imposing vax mandate on truckers</b>`,
      ],
      urls: [
        `https://www.rebelnews.com/exclusive_docs_the_feds_were_rolling_back_quarantine_restrictions_on_illegal_border_crossers_while_imposing_vax_mandate_on_truckers`
      ],
    },
    {
      description: [
        `Convoy Organizers Final Speech to the Commission`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=uQU-0kAeyOg`
      ],
    },
    {
      description: [
        `<b>Trudeau's invocation of the Emergencies Act was based on a hoax.</b>`,
        `Now that Ottawa Police has come out and said there's no evidence that the Feb. 6 residential building arson attempt was connected to the #FreedomConvoy2022 protest I have compiled every time a Liberal/NDP MP falsely smeared protestors as arsonists in the House of Commons`,
      ],
      urls: [
        `https://twitter.com/ezralevant/status/1506061591665688577`,
        `https://twitter.com/cosminDZS/status/1506039577651269634`,
      ],
    },
    {
      description: [
        `Trudeau Liberals and mainstream media reporters fell for an apparent hoax attempting to frame the protesters supporting the weeks-long Freedom Convoy as arsonists.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1506229205561925640`,
        `https://thepostmillennial.com/liberals-media-spread-hoax-that-freedom-protesters-committed-arson-attack-on-ottawa-apartment-building`,
      ],
    },
    {
      description: [
        `'This felt like an inside job': Ezra Levant on the Trucker Commission`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=IfiFpLNOvAs`
      ],
    },
    {
      description: [
        `Chris Sky in September 2021 at the Trucker Convoy:`,
        `If everybody goes and files notices of liability, if everbody goes and files lawsuits, oh and by the way if you're having trouble finding a lawyer here's why, the government won't tell you this, but government insiders tell me everything.. if you're having trouble finding a great lawyer it's because our government used our tax dollars to retain off the best law firms in the country.`,
        `All the ones that they knew had the power and resources to fight back against these wrongful dismissals and wrongful terminations, they retain them.`,
        `So when you end up pulling these law firms, they just simply tell you they can't help you. And it gives you the impression that there's no one out there to fight for you.`,
        `And it's because the government literally bought them off.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1441884103545167874`
      ],
    },
  ],
  Censorship: [
    {
      description: [
        `DuckDuckGo CENSORS Russian NEWS as EU Sanctions BAN Social Media POSTS. Lawyer comments.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=c8ncUWZVQ8I`
      ],
    },
    {
      description: [
        `Spotify removing Neil Young's music after Joe Rogan ultimatum`,
      ],
      urls: [
        `https://twitter.com/i/events/1486449828619960323`
      ],
    },
    {
      description: [
      `UFC President Dana White is asked about 200+ Doctors demanding Spotify censor Joe Rogan.`,
      `"It's one of the craziest things that I've ever witnessed in my life. And we're not talking about experimental drugs.. this stuff's been around.. the doctor won the Nobel Peace Prize."`,
      `"Are you a doctor?"`,
      `"No, but I took them and they both worked for me so why shouldn't I be able to take them again? Or other people?`,
      `You know what's scary? I can get some fuckin' pain pills quicker than I could get monocolonal anitbodies.. No, not maybe, that's a fact.. They hand out pain pills like they're tictacs."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1482775554080182276`
      ],
    },
    {
      description: [
        `CDC, the ones who said people like Dr. Robert Malone were spreading misinformation, were themselves the ones spreading misinformation, and may well have committed medical fraud.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=tEhjzTy70s0`
      ],
    },
    {
      description: [
        `Canadian Bill C-11 - Internet Censorship Bill`,
        `Every Canadian should watch to understand what it's about.`,
        `Don't ignore the message, just cause you don't like the messenger`,
      ],
      urls: [
        `https://twitter.com/CanAditude/status/1534989862301999104`
      ],
    },
    {
      description: [
        `New Canadian Bill C11 to control, regulate, and effectively censor online speech.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=pwI7qTNdjvE`
      ],
    },
    {
      description: [
        `TRUDEAU - Speaking at the B20 (the business leaders offshoot of the G20) Trudeau confirms only government approved free speech will be allowed online & monitored by Ai.`,
        `For your own safety.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1592427497668628482`
      ],
    },
    {
      description: [
        `Canadian internet censorship bill C-11.`,
        `Every Canadian needs to know how Trudeau's censorship bill will radically change how they use the internet.`,
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1622652885963091968`
      ],
    },
    {
      description: [
        `<b>Dr. Scott Jensen:</b>`,
        `Expressed concern about how death certificates were being issued during covid.`,
        `They are NOW using Keith Ellison and the Attorney General's Office to take away my Medical License - If this can happen to me it can happen to you!`,
        `---`,
        `It's more sinister than that, @drscottjensen. If they *can* do it to you, they *will* do it to others. Others who don't have the same voice to scream out in their own defense. This is the full weaponizing and discrediting of the field of medicine`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1620421050348765191`,
        `https://twitter.com/drscottjensen/status/1620203440713469952`,
      ],
    },
    {
      description: [
        `<b>Even KallMeKris, the 24th biggest TikToker in the world, is against C-11.</b>`,
        `I am scared. I have been paying really close attention to Bill C-11, aka the Online Streaming Act. It's something my fellow Canadian creators should be paying attention to and all the viewers as well.`,
        `Bill C-11 was supposed to be promoting Canadian storytelling online. In reality the Bill has ended up so broadly worded that it lets the CRTC interfere with every part of your online life. That include manipulating your feeds and search results, requiring you to provide proof of your age to access platforms like Reddit and Twitter, and controlling your favourite YouTube channels, TikToks and other streaming services. And even your own uploads.`,
        `Earlier this month the Canadian Senate passed Bill C-11, with a helpful amendment that better protects Canadian creators. But on March 7th the government gave notice that it would reject this amendment that would protect digital creators.`,
        `This is terrifying and honestly wild for the government to just ignore digital creators and its viewers.`,
        `Luckily there is still time to make an impact: www.digitalfirstcanada.ca`,
      ],
      urls: [
        `https://twitter.com/gddub/status/1642684396762177537`,
        `https://www.digitalfirstcanada.ca`,
      ],
    },
    {
      description: [
        `Dr. Jay Bhattacharya:`,
        `“We adopted a whole range of ridiculous policies that harmed children, working class ppl, vulnerable ppl for nothing. Those policies wouldn't had been adopted had there been legitimate debate. Censorship actually killed people."`,
      ],
      urls: [
        `https://twitter.com/dockaurG/status/1606096375044866048`
      ],
    },
    {
      description: [
        `<b>THE TWITTER FILES: HOW TWITTER RIGGED THE COVID DEBATE</b>`,
        `- By censoring info that was true but inconvenient to U.S. govt. policy`,
        `- By discrediting doctors and other experts who disagreed`,
        `- By suppressing ordinary users, including some sharing the CDC's *own data*`,
      ],
      urls: [
        `https://twitter.com/davidzweig/status/1607378386338340867`,
        `https://threadreaderapp.com/thread/1607378386338340867.html`,
      ],
    },
    {
      description: [
        `Canada develops its own version of "psychosis"`,
        `How odd Possibly because of that small fringe minority...`,
      ],
      urls: [
        `https://twitter.com/Bel_B30/status/1603090993439555586`
      ],
    },
    {
      description: [
        `"The idea that there's a central authority that can distinguish true from false, that can ex cathedra on high...And if you disagree, then you're a heretic. That is a dangerous, dangerous idea. Essentially, it's the return to a dark age."`,
        `Dr J Bhattacharya`,
      ],
      urls: [
        `https://twitter.com/katewand/status/1577965639750279169`,
        `https://www.youtube.com/watch?v=RChf-siETRY`,
      ],
    },
    {
      description: [
        `Don't believe the government wants to control what you watch online? Trudeau Senators voting moments ago not to include Senator Manning's observations on what is, in essence, a censorship bill is quite the flex.`,
      ],
      urls: [
        `https://twitter.com/SenatorHousakos/status/1600973960165740544`
      ],
    },
    {
      description: [
        `<b>A Legislative Onslaught Against Freedom</b>`,
        `Bills C-11, C-18, C-21, C-26, & C-36 will turn Canada into an authoritarian hellscape`,
      ],
      urls: [
        `https://karlstack.substack.com/p/a-legislative-onslaught-against-freedom`
      ],
    },
    {
      description: [
        `<b>Munk Debates - Nov 30, 2022: Douglas Murray's eloquent takedown of Canadian legacy media.</b>`,
        `Your Prime Minister decided in advance that these people were, all the modern excommunications, they were nazis, they were white supremacists, they were anti-semites, they were probably homophobes, they were misogynists, they were probably transphobes, etc etc etc, in all the things you do in the modern political age, you just want to defenestrate somebody who's awkward to you.`,
        `And then he brings in the Emergency Powers Act, now.. at such a time, what would the mainstream media do? It would question it. It would question it. The Canadian mainstream media did not. The Canadian mainstream media acted as an arm and chorus of the Canadian government.`,
        `I will give you a couple of examples, but ladies and gentlemen I could go on for hours with examples of this.`,
        `You had a CBC host describing the freedom convoy as quote, "a feral mob".`,
        `You had a Toronto Star columnist saying, quote, sorry for the language, "it's a homegrown hate farm that was then jet fuelled by an American right wing funded rat fucking operation." Jesus, they can't even write at these papers anymore..`,
        `CBC said that 2 indigenous women were so scared to go outside in Ottawa because of racist violence, didn't bother to mention that indigenous drummers had led the truckers in an O'Canada rendition.`,
        `The National Observer said that the many black and indigenous freedom convoy supporters were in fact, duped by the truckers.`,
        `The Globe and Mail reporter said, "my 13 year old son told me to tell protesters I'm not a Jew, after fear of anti-semetic violence," without mentioning that one of the leaders of the convoy was himself Jewish.`,
        `Now, why is this so rancid? Utterly utterly rancid and corrupt?`,
        `Because in this country, your media, your mainstream media is funded by the government. A totally corrupted system.`,
        `In 2018, oh an election year what a coincidence, the Canadian media has given $595 million over 5 years. The Toronto Star estimated it was going to be getting $3 million from the government in the first half of the year. It went on and on.`,
        `See, the government in Canada can tell people to, they can tell the banks to shut down peoples' bank accounts. Oh yeah. Your government can do that and if you're happy with that, just think about what would happen if the shoe was on the other foot. The government can do that, but in Canada, they can also tell the media what to do. And the media does the bidding of the Canadian government. That isn't a free society's media. I've seen free countries all my life, but this, in a developed, liberal democracy like Canada is a disgrace.`,
        `We're not saying don't read the mainstream media, we're just saying don't trust them.`,
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1598334823591866369`
      ],
    },
    {
      description: [
        `In Russia last year, 400 people were arrested for things that they said on social media. 400 people in Russia. Obviously this country is very different, how many people do you think were arrested in Britain for things they said on social media last year? Take a guess.`,
        `Interviewer: I have no idea.`,
        `J: 3,300.`,
        `I: Really? Arrested for what they said on social media? Really? What sort of things get you arrested?`,
        `J: Well one example I give on my show is there was a young woman from Liverpool called Chelsea Russell. Her friend was killed in a car crash, 19 year old woman, and she posted the lyrics of his favorite song on her Instagram, the lyrics. And it was a rap song. So the lyrics contain several instances of the n-word. She was arrested, prosecuted, found guilty, given 500 hours of community service and a fine, tagged, and for a year she was on the 8pm to 8am curfew.`,
        `I: My goodness. In Britain?`,
        `J: In Britain. In 2018`,
      ],
      urls: [
        `https://twitter.com/themaxstoic/status/1617542924023042048`
      ],
    },
    {
      description: [
        `<b>Toronto Board of Health edits out public meeting speaker Dan Harman's testimony about his 17 year old son's death after receiving his first dose of Pfizer.</b>`,
      ],
      urls: [
        `https://rumble.com/vt9kjy--censored-what-does-toronto-board-of-health-not-want-you-to-hear-about-the-.html`
      ],
    },
    {
      description: [
        `Bill 105 explanatory notes… Don't worry everyone, the Bill will expire when the health emergency ends. The health emergency that was declared in March 2020.`,
        `---`,
        `This bill proposes to regulate demonstrations in relation to the COVID-19 pendemic by prohibiting them within a 50-metre radius of the grounds of certain places, including places where COVID-19 testing or vaccination services are provided, facilities maintained by a health and social services institution, facilities of holders of a childcare centre of day care centre permit issue under the Educational Childcare Act and educational institutions providing preschool, elemenrary or secondary education. The bill also prohibits organizing or inciting anyone to organize such demonstrations.`,
        `The bill contains penal provisions for contravening its provisions and allows a judge of the Superior Court to grant an injunction to prevent any act prohibited by them.`,
        `Lastly, the provisions of the bill will cease to have effect when the public health emergency declared on 13 March 2020 ends.`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1442609877499535369`
      ],
    },
    {
      description: [
        `CanadaTalks SiriusXM 167:`,
        `<b>INTERNET REGULATION “The govt will tell you there's a monster in the woods and only we can save you”</b>`,
      ],
      urls: [
        `https://soundcloud.com/canadatalks/internet-regulation-the-govt-will-tell-you-theres-a-monster-in-the-woods-and-only-we-can-save-you`
      ],
    },
    {
      description: [
        `MSNBC speaks as if the things which we've discovered actually happened at Twitter, were simply ridiculous conspiracy theories.`,
        `Elon Musk: That is *actually* what they did. Going forward, this platform will be fair & transparent.`,
      ],
      urls: [
        `https://twitter.com/elonmusk/status/1647100828975603714`
      ],
    },
  ],
  DigitalId: [
    {
      description: [
        `WEF Speaker Yuval Noah Harari:`,
        `<b>"Covid is critical, because this is what convinces people to accept, to legitimize total biometric surveillance.</b>`,
        `The next phase is the surveillance going under our skin.... We are really acquiring divine powers"`,
      ],
      urls: [
        `https://twitter.com/FMnews_/status/1515446659294982144`
      ],
    },
    {
      description: [
        `A message from the Canadian Bankers Assoc referencing the World Economic Forum. The state wants to own you in the name of convenience.`,
        `President of the Canadian Bankers Association says a centralized digital ID system is coming. He says you can totally trust the banks and the government.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1496272056178159619`,
        `https://twitter.com/TPostMillennial/status/1496556896400158727`
      ],
    },
    {
      description: [
        `Maajid Nawaz leaves Joe Rogan speechless by explaining how the World Economic Forum (WEF) is infiltrating governments around the world.`,
      ],
      urls: [
        `https://twitter.com/Kirky861/status/1496349990154866695`
      ],
    },
    {
      description: [
        `Frightening Resurfaced World Economic Forum Clip- We Are One Of The Last Generations Of Homo Sapiens`,
      ],
      urls: [
        `https://www.bitchute.com/video/ktXFZvGHiFr7/`
      ],
    },
    {
      description: [
        `Chinese style social credit system under the guide of Universal Basic Income - National Standard of Health Framework - mandatory vaccinations in Canada - trying to pass with`,
        `<b>BILL S-233</b>`,
        `<b>Red Alert Canada.`
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1497696527648706565`
      ],
    },
    {
      description: [
        `The Hill - Federated Digital ID?`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=9cqC-Ha9yrA`
      ],
    },
    {
      description: [
        `KLAUS SCHWAB (IN 2016) EXPLAINS THE TIMETABLE FOR MICROCHIPPING EVERYONE BY 2026`,
      ],
      urls: [
        `https://www.bitchute.com/video/sMoJyL2BwDXV/`
      ],
    },
    {
      description: [
        `The General manager of BIS, the central bank of central banks, explains how your social credit system will influence your ability to use money under the coming Central Bank Digital Currencies (CBDCs) system.`,
        `<b>"The Central Bank will have absolute control under rules and regulations that we determine the use of that expression of central bank liability, and also we have the technology to enforce that."</b>`,
        `"Those issues are extremely important, and that makes a huge difference with respect to <b>what cash is."</b>`
      ],
      urls: [
        `https://twitter.com/WG_Gervais/status/1500929592504504320`
      ],
    },
    {
      description: [
        `Visions of things to come based on the rapid construction of the CCTV state world around us.`,
        `<b>"Just imagine the worst, most vindictive, controlling, hateful and unreasonable parent in the world, calling all the shots in your life, for the rest of your life.</b>`,
        `Making the most outrageous demands, stopping you doing things you enjoy just because they like depriving you of things.`,
        `Now, translate that to who oversees us currently.`,
        `No-one should have more right over your body than you. Full stop."`
      ],
      urls: [
        `https://mkwpublishing.wordpress.com/2021/10/07/monitoring/`
      ],
    },
    {
      description: [
        `"They Planned It All Along"`,
        `"Did you know the WEF were talking about Digital ID way back in 2016, 4 years before the pandemic?"`,
        `"Sleepwalking into a tech-enabled tyranny"`,
        `"It's not something new, it's something that's been in incubation for quite some time."`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=leFyK7JDDjU`
      ],
    },
    {
      description: [
        `China's social credit score system "pushes you to become a better citizen".`,
        `<b>Canada rolling out Chinese social credit scores for buisnesses.</b>`
      ],
      urls: [
        `https://www.youtube.com/watch?v=h2joFCzDUGU`
      ],
    },
    {
      description: [
        `I told You that the vaccine chaos would return and get worse`,
        `<b>The WEF is planning to launch CovidPass in SEPTEMBER of this year, which will require a blood sample to prove that You're safe for Yourself and others.</b>`,
      ],
      urls: [
        `https://twitter.com/Dragonsoul9Fire/status/1503449073549987846`
      ],
    },
    {
      description: [
        `Guangzhou Canton fair 2022.`,
        `One exhibition visitor scanned his /her QR code vaccine passport and it came back red.`,
        `Chinese authority locked up these people immediately`,
        `QR code vaccine passports are controlling every move of Chinese people!`,
      ],
      urls: [
        `https://twitter.com/songpinganq/status/1502259232002883586`,
        `https://threadreaderapp.com/thread/1502259232002883586.html`,
      ],
    },
    {
      description: [
        `Dystopian Surveillance State Is "Now Possible" - Yuval Noah Harari, Lead Advisor to Klaus Schwab`,
        `"Dictators always dreamt about completely eliminating privacy, monitoring everybody all the time and knowing everything you do, and not just everything you do, but even everything you think and everything you feel... They could never do it because it was technically impossible; now it's possible."`,
      ],
      urls: [
        `https://gettr.com/post/p10zv3g1722`
      ],
    },
    {
      description: [
        `I thought a cashless society was a conspiracy theory`,
      ],
      urls: [
        `https://twitter.com/3v3r6/status/1506409071972425731/photo/1`
      ],
    },
    {
      description: [
        `Social Credit Score proof of concept`,
        `MasterCard and the United Nations have joined forces to produce a credit card that measures your carbon footprint.. once you reach your limit you can no longer purchase`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1506540102587412484`
      ],
    },
    {
      description: [
        `Digital currency`,
        `<b>Employers will welcome the ability to help their employees make better decisions.</b>`,
        `So if you're an employer and you want to incentivize healthy eating, then you can allocate a certain amount of their money towards healthy eating, so if they go and try to spend too much on junk food, it just won't work.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=bkNh4MNw_MI`
      ],
    },
    {
      description: [
        `The Government of Canada, Air Canada and two major Canadian airports have partnered with the World Economic Forum (WEF) on a digital ID project that could see a social credit-like system be required for travelling.`,
      ],
      urls: [
        `https://twitter.com/TheRealKeean/status/1514729023913426948`,
        `https://thecountersignal.com/canada-is-partner-in-wef-program-to-bring-digital-id-to-travel/`,
        `https://ktdi.org/`,
      ],
    },
    {
      description: [
        `<b>Ontario Party exposes the dangers of Digital ID</b>`,
      ],
      urls: [
        `https://www.tiktok.com/@seanurban103/video/7089463255248145670`
      ],
    },
    {
      description: [
        `The Canadian and Dutch governments and airports are partnering with the #WorldEconomicForum to “unlock the potential of digital identity for secure and seamless travel.”`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1530240132917362689/photo/1`
      ],
    },
    {
      description: [
        `Digital currency and ID is already being made. If we relinquish cash in exchange for central bank digital currencies, it will be gone forever.`,
      ],
      urls: [
        `https://twitter.com/AMBULLDOG249/status/1555416564719144961`
      ],
    },
    {
      description: [
        `Digital Yuan will have an expiry date.`,
      ],
      urls: [
        `https://www.bitchute.com/video/6JxyRxxAl43n/`
      ],
    },
    {
      description: [
        `The gov't admitted that they have a $105.3 million contract with the World Economic Forum for the Known Traveler Digital ID. Take a look at this order paper.`,
        `It's a contractual fact.`,
      ],
      urls: [
        `https://twitter.com/LeslynLewis/status/1577744416290357253`,
        `https://leslynlewismp.ca/wp-content/uploads/sites/28/2022/10/Q-634-2022-09-20-Known-Traveller-Digital-ID.pdf`,
      ],
    },
    {
      description: [
        `<b>Bank of England official says nation needs centralized 'digital pound' after FTX collapse</b>`,
      ],
      urls: [
        `https://thepostmillennial.com/bank-of-england-official-says-britain-needs-centralized-digital-pound-after-ftx-collapse`
      ],
    },
    {
      description: [
        `<b>“We need a Single Global Order”</b>`,
        `Are you on the US or the Chinese side?`,
        `-Emmanuel Macron, November 2022`,
      ],
      urls: [
        `https://twitter.com/liz_churchill7/status/1594086309068406788`
      ],
    },
    {
      description: [
        `Mastercard, Wells Fargo, Citigroup, and others, launch a 12-week experimental digital dollar pilot with the New York Fed.`,
      ],
      urls: [
        `https://watcher.guru/news/global-banks-partner-with-new-york-fed-for-12-week-digital-dollar-pilot`,
        `https://twitter.com/WatcherGuru/status/1592547345841156096`
      ],
    },
    {
      description: [
        `<b>Are We Ready for a New World Order?: World Government Summit (2022)</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=CDDlWKSQvS8`
      ],
    },
    {
      description: [
        `World Economic Forum speech.`,
        `"<b>Individual carbon footprint trackers.</b> Stay tuned, we don't have this operational yet, but it's something that we're working on.`,
        `Make absolutely no mistake, <b>individual carbon footprint trackers</b> will be programmed to Central Bank Digital Currencies`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1591712443923050496`
      ],
    },
    {
      description: [
        `<b>Canada's Central Bank moving to create Central Bank Digital Currency</b>`,
        `"The distinguishing feature of the centralized archetype is that the total system state is within the trust zone of, and controlled by, one entity. This entity would have the power to approve and apply each update or deny it. Users connecting to the system do not hold any state, only credentials that authorize access. An operation, such as a transaction or issuance, could be fully recorded as a change to information within the zone. In practice, a CBDC system would not be deployed as a single instance. Instead, it would replicate state, possibly to different geographic sites, for backup and availability. Nevertheless, if the instances are under a single entity's authority, the system can be considered centralized because it preserves the essential characteristic of the total state controlled by one party."`,
      ],
      urls: [
        `https://thepostmillennial.com/canadas-central-bank-moves-to-create-central-bank-digital-currency`
      ],
    },
    {
      description: [
        `Klaus Schwab says the word won't be run by superpowers such as the US/China… it will be run by WEF stakeholders such as BlackRock/Bill Gates.`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1584344875964588033`
      ],
    },
    {
      description: [
        `China… a police drone comes towards you on the highway… you need to quickly scan the QR code`,
        `If it does not come back green, you can not pass the check point, free movement is being restricted, population is controlled using QR codes.`,
      ],
      urls: [
        `https://twitter.com/WallStreetSilv/status/1586296791141478400`
      ],
    },
    {
      description: [
        `WEF's Yuval Noah Harari “You will have a small elite that'll makes all the decisions even if it doesn't benefit the majority of the population…. If bad comes to worst, scientists will be the Noah's Ark for the elite… leaving the rest to drown"`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1585080574712373250`
      ],
    },
    {
      description: [
        `Central Bank Digital Currency will be "programmable… ultimately giving the issuer control over *how it is spent by the recipient*… your freedom to purchase fuel, meat, flights, fertiliser… will be limited to a carbon credit score.`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1585028310748499969`
      ],
    },
    {
      description: [
        `Globalist technocrats at the G20 are already preparing for the "next pandemic" by greasing the skids for a "global digital health passport".`,
        `Siregar: "So let's have a digital health certificate, acknowledged by the WHO, if you have been vaccinated or tested properly, then you can move around."`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=kMT1bdGLxfw`
      ],
    },
    {
      description: [
        `Russell Brand:`,
        `"Whether it's concern that you'll be able to travel during the next "inevitable lockdown" or "prevent a migrant crisis", digital ID appears to be suggested for the near future.`,
        `Thanks G20, at least we can rely on political opposition to represent an alternative case-- There is no opposition!`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=uehjfTZI6qw`
      ],
    },
    {
      description: [
        `CANADA - Your bank is preparing to roll out CBDCs.`,
        `Those vaccine passports didn't set you free, they forced you down a road of no return! Forever linked to your 'good' behaviour, even how you will spend your own money.`,
        `"Imagine a digital Loonie becoming legal tender. Well that day is getting closer.`,
        `<b>A recent survey found major banks are considering some form of digital currency within the next six (6) years.</b>`,
        `Three research teams have produced proposals for the Bank of Canada.."`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1595027824716423172`
      ],
    },
    {
      description: [
        `<b>Social Credit System: Dystopian scifi short film.</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/pQfD1cydjPvI/`
      ],
    },
    {
      description: [
        `<b>The Canadian Alibaba Group president J. Michael Evans boasts at the World Economic Forum about developing an individual carbon footprint tracker to monitor what you buy, what you eat, and where/how you travel.</b>`,
        `<b>That individual carbon footprint tracker, however, most likely won't apply to corporate jets, yachts, or emissions from homes greater than 5,000 sq ft.</b>`,
        `"We're developing, through technology, an ability for consumers to measure their own carbon footprint.`,
        `What does that mean?`,
        `It's, where are they travelling, how are they travelling, what are they eating, <b>what are they consuming on the platform?</b>`,
        `So, <b>"Individual Carbon Footprint Tracker"</b>`,
        `Stay tuned, we don't have it operational yet, but <b>this is something that we're working on.</b>`,
      ],
      urls: [
        `https://twitter.com/goddeketal/status/1629986426392842240`
      ],
    },
    {
      description: [
        `<b>Klaus Schwab's lead advisor, Yuval Harari:</b>`,
        `"Covid is critical because this is what convinces people to accept, to legitimise total biometric surveillance.`,
        `If we want to stop this epidemic, we need, not just to monitor people, we need to monitor what's happening under their skin."`,
        `<b>Harari also says that u can be seen sitting in your living room throught TV screens like in Orwells "1984":</b>`,
        `"The television could be monitoring you and knowing whether you're angry or not, just by analyzing the cues, the biological cues coming from your body. So now people are now watching us online, all over the world, this conversation.`,
        `Now, maybe even right now, the people who are watching us, are being watched an analyzed.. and you know, the thing is, it's not just people now watching this, the thing is, we know that you are watching this and we also know how you feel.`,
        `Are you angry about what you hear? Are you frightened? Are you bold?`,
        `This is the kind of power that Stalin didn't have, you know, when Stalin gave a speech everybody of course clapped their hands and smiled, now how do you know how you really think about stuff? It's very difficult.`,
        `You can't have a KGB agent following everybody all the time, even if you do it, he's just watching your outside behavior, he doesn't really know what's happening in your mind.`,
        `But in ten years, the future Stalins of the 21st century, they could be watching the minds, the brains of all the population, all the time, and also they will have the computing power to analyze all of it.`,
        `You know, it's not just having an agent following everybody all the time. The agent in Stalin's days, writes a paper report and you have millions of paper reports piling up in Moscow, somebody needs to read them, to analyze them, that's impossible.`,
        `No you don't need human agents, you don't need human analyzers, you just have a lot of sensors and an AI which analyzes it, and that's it, you have the world's first digital totalitarian regime in history, and covid is important because covid legitimizes [total biometric surveillance]."`,
      ],
      urls: [
        `https://twitter.com/unhealthytruth/status/1635492373051453441`,
        `https://twitter.com/TabuiRob/status/1635612954560192514`,
        `https://twitter.com/BernieSpofforth/status/1598779960466243584`,
        `,https://twitter.com/JamesMelville/status/1625853545231843331`,
      ],
    },
    {
      description: [
        `<b>15 minute cities in China.</b>`,
        `<b>This is what we are in store for if we don't say no.</b>`,
        `15 minute cities in China - each neighborhood zone is separated by a fence, with the entrance gate being guarded.`,
        `If you want to get in or out of your zone, you need permission and a face scan.`,
      ],
      urls: [
        `https://twitter.com/ninnyd101/status/1634541876761047041`
      ],
    },
    {
      description: [
        `TONY BLAIR - At the globalist heart of digital ID. Tracked, monitored and controlled. Every human, everywhere.`,
        `<b>Unelected elites deciding your life with NO democratic mandate.</b>`,
        `The technology revolution is central, and it's one of the reasons why India's done so well in these last years. Because I think, you know, Prime Minister Modi has understood the importance of it, and your Digital ID Program, I think it's one of the most important programs.. I keep saying to people around the world, you want to see an example of a reform that leads to real results and shows that government can work for people, look at India's Digital ID Program. It's been a remarkable transformation..`,
        `..So technology, yep, it's got its downsides, but it's a fact, we understand it, and if we master it properly, we can make it work for people.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1635280243597672450`
      ],
    },
    {
      description: [
        `<b>You've heard of #15minutecities but did you know your city council may be burying the 2030 agenda behind “5 Big Moves?”</b>`,
        `Ok I know we've all got 15 minute cities on the brain, but I want to alert you to this term, "The 5 big moves".`,
      ],
      urls: [
        `https://twitter.com/KloetMichelle/status/1638331474825838593`
      ],
    },
    {
      description: [
        `<b>James Melville: We cannot be manipulated by a greedy sociopaths towards central bank digital currencies & digital ID.</b>`,
        `<b>It's a gigantic con-trick to wrestle away even more civil liberties, power, wealth and control under the false auspices of virtue.</b>`,
        `I think where we are right now is much more concerning than even the problems over the last 2 years.`,
        `I think there's a mission-creep towards all-things-digitalization, under the auspice, again, of "safety", and also "convenience".`,
        `And I'm also concerned about people looking at this, especially through the cost of living crisis and wondering what's coming next, and getting angry with government, I think we need to find ways to stop the division and unite everyone against the people who are putting this mission-creep in place to lose even more freedoms in the future, and that is largely linked around digitalization`,
        `<b>All around the world, Central Bank Digital Currencies (CBDCs) are either in pilot scheme, or executive order stage in the states, or they're floating under the radar. Our previous chancellor now unelected prime minister, Rishi Sunak, is clearly a big fan. As is his frat boy, pen pal, best mate, Justin Trudeau.. these individuals are pushing it in this direction.</b>`,
        `I understand there is a need for digitalization, because the world moves on, but what we shouldn't be losing, is some of the <b>assets that we still need, such as cash.</b>`,
        `<b>Cash is vital, as a farmer's son I understand the values of a circular economy.</b>`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1633384120087658496`
      ],
    },
    {
      description: [
        `A brilliant commentary by Vandana Shiva. She calls out the "war on cash", social credit systems, the WEF, Bill Gates, Klaus Schwab and The Great Reset.`,
        `<b>"My country was the first to make cash illegal.</b>`,
        `“If ever there was a time for humanity to wake up, now is the time.”`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1633384585957343234`
      ],
    },
    {
      description: [
        `East Palestine, Ohio will be given digital ID wrist bands, "MyID", to access "important medical information".`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1631178242706087938`
      ],
    },
    {
      description: [
        `<b>Bank for International Settlements:</b>`,
        `The CBDC (Central Bank Digital Currency) will have <b>absolute control</b> on the rules and regulations that we determine the use of that expression of central bank viability, and also we have the technology to enforce that.`,
        `Those two issues are extremely important and that makes a huge difference with respect to what cash is.`,
      ],
      urls: [
        `https://twitter.com/DowdEdward/status/1624258852379320320`
      ],
    },
    {
      description: [
        `Justin Trudeau has threatened Provincial Premiers that he will cut off healthcare funding unless they agree to Digital ID.`,
        `This Digital ID system will allow government to control every aspect of your life.`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1623807764320645120`
      ],
    },
    {
      description: [
        `Explanation of Digital ID which the World Economic Forum (WEF) has planned.`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1621053299830161409`
      ],
    },
    {
      description: [
        `"We Need Some Sort of Digital Certificate. Because We Are Likely to Say to People Working In Nursing Homes, or Hospitals or Maybe Even Schools That They Need to Authenticate That They Have Been Vaccinated." - Bill Gates`,
      ],
      urls: [
        `https://twitter.com/TheClayClark/status/1621121506309967872`
      ],
    },
    {
      description: [
        `<b>Former UK Prime Minister Tony Blair Calls For A Digital Database To Monitor Who Is Vaccinated And Who Is Not For A Future “Pandemic” During Live Broadcast At Klaus Schwab's World Economic Forum</b>`,
        `You need to know who's been vaccinated and who hasn't been. Some of the vaccines that will come down the line will be multiple shots, so you've got to have, for reasons to do with healthcare more generally, but certainly for a pandemic or for vaccines, <b>you've got to have a proper digital infrastructure,</b> and many countries don't have that, in fact most countries don't have that.`,
      ],
      urls: [
        `https://twitter.com/healthbyjames/status/1616150104074747915`
      ],
    },
    {
      description: [
        `Forced vaccinations - brain implants - transhumanism - universal basic income - carbon tax - individual carbon footprint tracker`,
      ],
      urls: [
        `https://twitter.com/ronin19217435/status/1600984374367105024`
      ],
    },
    {
      description: [
        `Australia Digital ID linked vaccine verification certificates.`,
        `No vaccines, you lose everything, bank accounts, social networks, internet access... It's a reality in Australia and of course, soon in Europe.  Welcome to World Dictatorship!!`,
      ],
      urls: [
        `https://twitter.com/ronin19217435/status/1603495124910366721`
      ],
    },
    {
      description: [
        `Jacinda Ardern partners with Bill Gates to rollout Digital IDs across the country.`,
      ],
      urls: [
        `https://twitter.com/ronin19217435/status/1602364921181868040`
      ],
    },
    {
      description: [
        `AUSTRALIA - Social credit introduced to acces the internet, via your digital ID.`,
        `Citizens need 100 points of identification to use social media & the police will have access to your accounts including private messaging.`,
        `---`,
        `<b>Government considering 100 points of ID to get Facebook, Tinder account - The government could require you to hand over your passport to tech companies before posting online in a move that's been slammed by experts.</b>`,
        `This was included as one of 88 recommendations by a parliamentary committee aiming to curb bullying and domestic violence. The report was issued in April 2021, under the Morrison government, but the measure has not been enacted.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1602042530845057027`,
        `https://www.news.com.au/technology/online/security/government-considering-100-points-of-id-to-get-facebook-tinder-account/news-story/624550c621d662da7d3bd98ff3f0e888`
      ],
    },
    {
      description: [
        `Rebuilding #Leeds into a 15 min city conveniently adorned with 24/7 facial recognition cameras`,
      ],
      urls: [
        `https://twitter.com/TorontoWriter/status/1643811490850381825`
      ],
    },
    {
      description: [
        `Here's a personal account of how digital ID / central bank digital currencies / social credit systems operate in China.`,
        `Central banks and governments are nudging us towards this`,
        `---`,
        `If you go to China, a few years ago in Shenzen, I live overseas, if you go to Shenzen, back in 2017 they already had facial recognition cameras there.`,
        `So if you jaywalked in Shenzen, it would immediately detect that, send you a notification, and would deduct money from what they call our WeChat account, they use apps WeChat and AliPay. So they actually deduct your money directly from your account. This is 4 years ago (more now), so this is not some sort of conspiracy theory, it's not something that's going to happen 10 years into the future, this happened pre-corona, in China.`,
        `And so this system in China that exists, is extremely advanced, they can detect if it's a man or woman, they can guess your age, they can detect what kind of car model it is, who the manufacturer is. This is the sort of technology that we see in all these dystopian films and whatever, and people keep putting it off and thinking that this is something that's going to happen "later", my kid's lifetime or my grandkids. <b>It's already here.</b>`,
        `<b>And if this gets implemented anywhere, especially in Western nations, it's not going away.</b>`,
        `Israel's already talking about the 4th shot, then they're going to be going to herpes, and they're going to ask if you're gay, if you've eaten 3 pizzas this week because that's unhealthy. If you buy certain books in certain countries your country doesn't like, you'll lose points in the social credit score system. So this is already the reality of the world in certain places, and we need to be aware of that if we don't want that of if we do.`,
        `And to be perfectly honest, anyone who wants this vaccine passport is a misnomer. It's total control. Everything you do or don't do will be calculated. To the point where they can detect your GPS signal if you have a phone on you, they can detect who you're hanging out with. Oh you're hanging out with someone who said something bad nine years ago on Weibo (a Chinese equivalent to Facebook or something like that), oh you lose 2 points. Oh you bought Japanese books you lose 5 points because you don't like Japan.`,
        `This is what is going to come here, because it's already there.`,
        `Interviewer: And they're sort of doing that with contact tracing now.`,
        `Guy: The simple reality that CSIS has, and all these big tech corporations, they already know where you are. It's just that they don't want to shock the system too much and admit that they have it. Edward Snowden revealed all this back in 2013. He fled to Hong Kong, they wouldn't keep him there so now he's in Russia. But the NSA has all of your information. They know your Google searches from like February 2004 in a bunker in Colorado.`,
        `They have all this information but in the US with the 4th amendment, they can't, they're not supposed to track you forever right? so they "pretend" they don't have it, but they do, if they really want to utilize it.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1597282329181159425`
      ],
    },
    {
      description: [
        `NIGERIA - Has set a limit on all cash withdrawals to $45 per day to force the population to use digital money only.`,
        `When the nudging stops, social shaping will be done by force.`,
        `Don't think this can't happen here.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1600464222760075264`
      ],
    },
    {
      description: [
        `Canterbury City Council is proposing to divide the city into five different districts, with drivers unable to cross between zones without being fined. So much for freedom of movement. Instead this is another step towards petty authoritarianism.`,
        `<b>Controversial plans to stop cars travelling between neighbourhoods in Canterbury press ahead despite being branded a 'draconian ban on free movement'</b>`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1600076657615699968`,
        `https://www.dailymail.co.uk/news/article-11352725/Controversial-plans-stop-cars-travelling-neighbourhoods-Canterbury-press-ahead.html`,
      ],
    },
    {
      description: [
        `Commentary by Vandana Shiva - where she expresses her concerns over the "war on cash", digital id, social credit systems, WEF, Bill Gates, Klaus Schwab and The Great Reset.`,
        `“If ever there was a time for humanity to wake up, now is the time.”`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1599682183169380352`
      ],
    },
    {
      description: [
        `China - Citizens with a red QR code are not allowed to participate in society and are then rounded up and sent off to "quarantine" camps.`,
        `It's not about safety. It's all about totalitarian control.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1599743750888075264`
      ],
    },
    {
      description: [
        `<b>“What people will remember from the Covid Crisis is that we agreed to be surveilled… all the time… and most importantly… this is when surveillance… went under the skin…” -Unelected Eugenicist, Yuval Harari - lead advisor to Klaus Schwab</b>`,
        `I think maybe in a couple of decades when people look back, the thing they will remember from the covid crisis is, this is the moment when everything went digital, and this was the moment when everything became monitored.`,
        `That we agreed to be surveilled, all the time, not just in authoritarian regimes, but even in democracies, and maybe most importantly, we started going under the skin.`,
        `I think the big process that's happening right now in the world is "hacking human beings", we have the ability to hack humans, to understand deeply what's happening within you, what makes you go.`,
        `And for that, the most important data is not what you read and who you meet and what you buy, it's what is happening inside your body.`,
        `So we had these two big revolutions, the science revolution or the info-tech revolution, and the revolution in the biological sciences, <b>and they're still separated, but they're about to merge.</b>`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1599924213875458050`
      ],
    },
    {
      description: [
        `The introduction of the digital smart ID will ensure citizens are monitored from cradle to grave. The temporary vaccine passport didn't set you free, it enslaved you to the whims of State. Forever.`,
        `EU MP:`,
        `We are witnessing now the Chinification of Europe. Because we see what is happening in China right now with the social credit score, where the government is monitoring and surveilling all the people from beginning to end. Everything that they do, everywhere that they walk, they control everything and they watch everything.`,
        `This is an example of a tyranny, when a government knows everything about you, where you go, what you eat, where you enter.. that's a tyrannical system. And we're seeing this system being implemented right now.. slowly but surely in the European Union. The certificate was just the first step. There are a few more proposals right now under debate in the parliament.`,
        `The European wallet ID for example. The European social security card. All these things that are creating the system that will monitor, control, supervise, and condition the rights of the European citizens.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1598949509261758464`
      ],
    },
    {
      description: [
        `China:`,
        `The Chinese authorities have remotely switched these people's Covid digital QR passport to yellow or red to stop them from leaving Guangzhou. They are now stuck in Guangzhou and unable to travel home.`,
        `Welcome to tech totalitarianism.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1598670578390646784`
      ],
    },
    {
      description: [
        `A fully vaccinated Canadian senior was given a 14-day quarantine order because she did not use the Trudeau Government's ArriveCAN app. “I shouldn't have to go through this, no Canadian should," she said.`,
      ],
      urls: [
        `https://twitter.com/elie_mcn/status/1550175201085865993`
      ],
    },
    {
      description: [
        `12 year old girl expose the dystopian reality of 15-minute cities!`,
        `“How dare you steal my childhood and my future, and the future of our children, by enslaving us in your crazy digital surveillance prison.”`,
      ],
      urls: [
        `https://twitter.com/ChildrensHD/status/1627050833706905600`
      ],
    },
    {
      description: [
        `<b>"Who masters those technologies, in some way, will be the master of the world."</b>`,
        `~ Klaus Schwab (World Government Summit 2023)`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1625672137800396800`
      ],
    },
    {
      description: [
        `<b>David Icke in 1998</b>`,
        `Across the millenium years, is crunch time in this whole agenda, crunch time for the human race.`,
        `This is the time when this network of interbreeding bloodlines wants to bring in its global fascist structure of a world government, to which nation states would be administered to units. Of a world central bank and a world currency, and a currency that wouldn't be cash it would be merely electronic, which there are fundamental implications for human freedom.`,
        `And also the world army, which is designed to be NATO, expanding and expanding as it is now of course to become the fully fledged world army, world police force.`,
        `And underpinning that little lot is designed to be a microchipped population, in which we are microchipped with our financial details, our medical details, etc etc, and that would allow not only electronic tagging, people knowing where we are all the time, it would allow the external manipulation through this electronic means of our mental and emotional processes.`,
      ],
      urls: [
        `https://twitter.com/goddeketal/status/1617941550775902209`
      ],
    },
    {
      description: [
        `Hellish…`,
        `Jordan Peterson breaks down C40 and the 15-minute City Plan where you will need permission to enter and exit sectors`,
        `---`,
        `I've looked into the C40 websites, the C40 is the consortium of municipalities that have signed on to the 15 minute city plan and I read in their own documentation, this is relevant to the tri-state city idea, that their goals are to reduce caloric consumption to 2500 calories a day by force essentially, within the next 15 years.`,
        `To ensure that the peasant class which is everybody but the elitists, can't fly more than one time every 3 years.`,
        `To not merely shift private car ownership from fossil fuel to electric, which is fundamentally impossible because the grid can't handle it, but to eliminate 90% of private car ownership so that people are forced to take unbelievably expensive, in terms of time utilization and non-existent public transportation systems and to limit the amount of travel people can do outside of their neighbourhoods, you know and I find, and then I watch the legacy media claim that pointing that out is something akin to a right wing consipiracy, which it most certainly isn't, because you can just find the bloody documentation online..`,
        `And then I look at places like China, which have taken this to extreme, 600 million closed circuit TV cameras in China, one for every 1-1/2 persons, watching the Chinese all the time, able to monitor them 100% by face, and also to identify them by gait, and to limit their ability to do absolutely anything, to buy, to sell, to travel, to move, to leave their neighbourhood, with this top down surveillance system, that perversely and consciously, some of the Chinese engineers have actually named Skynet. And in a conscious attempt to product a positive versoin of you know, the absolutely catastrophic, apocalyptic artificial intelligence that was in the bloody Arnold Schwarzenegger movies. And you can't even talk about this stuff without sounding like a raving conspiratorialist, but there it is.`,
        `Now, tell me more about the tri-state city:`,
        `<b>Guest:</b>`,
        `I was reading a book about cholera a couple of years ago, I think it was called Ghost Map, and interestingly towards the end of the book, the author, in an otherwise excellent book, started talking about these smart cities, where you know, basically anything we don't have to worry about any disease anymore, and that sort of thing.`,
        `Last time I was in China, I got kicked out of Hong Kong in 2020, I was a bad boy, I was watching the protests and they finally kicked me out after 7 months, that was Hong Kong.. but the last time I was in mainland China, I was actually researching information. I was in places like Nanjing and Shanghai and that sort of thing, but I was in southern China, where the Uyghurs are, for part of that trip. And I was talking with restaurant owners and farmers and that sort of thing, and various restaurant owners told me that when a Uyghur comes in, they are mandated by law to call the police immediately. The police immediately come to check.`,
        `So the smart city goes beyond optics. You know they're installing those cameras all over Hong Kong, they were doing it that when I left, that's in Hong Kong, but over in mainland China it's not just the cameras everywhere, it's also, you know, you're mandated to call.`,
        `When I was in Hong Kong by the way, in the protest, the protesters would take their lasers and shine them in the cameras, and burn out the censors, actually my camera got a little damaged by it, but you know that's not a long term solution obviously..`,
        `But what I'm getting to is these smart cities are clearly coming, and they're already here to some degree, every time we use our cards and that sort of thing, but they're clearly taking it to a higher level of complete control. And again, this isn't conspiratorial, they say they're going to do it. And we talk about Mark Rutte, the Prime Minister of Netherlands, you can see Klaus Schwab going, "where do you find such Prime Ministers as Mark Rutte?"`,
      ],
      urls: [
        `https://twitter.com/TorontoWriter/status/1645453403852619779`
      ],
    },
    {
      description: [
        `<b>The WHO have released their plan for a global digital vaccine passport for any vaccine.</b>`,
        `This is unelected technocratic control aided and abetted by subservient governments. No one voted for this.`,
        `---`,
        `The European Union Times Newspaper: <b>WHO Releases Plan For Global Digital Vaccine Passports Funded By Bill Gates & Rockefeller Foundation</b>`,
        `The World Health Organization (WHO) has released a proposal backed by two major globalist organizations that serves as a blueprint for governments to implement a worldwide vaccine passport verification system.`,
        `The document, called "Digital Documentation of COVID-19 Certificates: Vaccination Status", funded by none other than the Bill and Melinda Gates Foundation and the Rockefeller Foundation, describes the technical guidance for governments to roll out the program to usher in a global digital ID - In the name of COVID, of course.`,
        `"This document lays out an approach for creating a signed digital version of a vaccination record for COVID-19 based on a core data set of key information to be recorded, and an approach for the digitial signature. The document leverages existing free and open standards, and is driven by the ethics, use cases and requirements for Digital Documentation of COVID-19 Certificates: Vaccination Status (DDCC:VS)."`,
        `"This document is therefore software-agnostic and provides a starting point for Member States to design, develop and deploy a DDCC:VS solution for national use in whichever format best suits their needs (eg. a paper card with a one-dimensional [ID] barcode or QR code stickers, or a fully functioning smartphone application developed internationally or locally)."`,
        `"The primary target audience of this document is national authorities tasked with creating over overseeing the development of a digital vaccination certificate solution for COVID-19. The document may also be useful to government partners such as local businesses, international organizations, non-governmental organizations and trade associations that may be required to support Member States in developing or deploying a DDCC:VS solution."`,
        `The DDCC:VS is meant for use at schools, at work, for international travel, and for contact tracing initiatives.`,
        `The document also shows its proposed vaccine passport template, with a QR code featured on the front, but further in the app shows personal information about the number of vaccines received.`,
        `A similar vaccine passport project in the works, called CommonPass, is also backed by the Rockefeller Foundation and the World Economic Forum (WEF).`,
        `It's notable how the only groups actively pushing for global vaccine passports are also under the main proponents of the so-called Great Reset, a post-human plan outlined by WEF founder Klaus Schwab which seeks to deindustrialize the West, abolish private property, introduce biometric surveillance, and move humanity to a diet of bugs in the name of fighting climate change.`,
        `---`,
        `<b>G20 Indonesia 2022:</b>`,
        `23. We recognize the need for strengthening local and regional health product manufacturing capabilities and cooperation as well as sustainable global and regional research and development networks to facilitate better access to VTDs globally, especially in developing countries, and underscore the importance of public-private partnership, and technology transfer and knowledge sharing on voluntary and mutually agreed terms.`,
        `We support the WHO mRNA Vaccine Technology Transfer hub as well as all as the spoke in all regions of the world with the objective of sharing technology and technical know-how on voluntary and mutually agreed terms.`,
        `We welcome joint research and joint production of vaccines, including enganced cooperation among developing countries.`,
        `We acknowledge the importance of shared technical standards and verification methods, under the framework of the IHR(2005), to facilitate seamless international travel, interoperability, and recognizing digital solutions and non-digital solutions, including proof of vaccinations.`,
        `We support continued international dialogue and collaboration on the establishment of trusted global digital health networks as part of the efforts to strengthen prevention and response to future pandemics, that should capitalize and build on the success of the existing standards and digital COVID-19 certificates.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1596813335407665152`,
        `https://eutimes.net/2021/09/who-releases-plan-for-global-digital-vaccine-passports-funded-by-bill-gates-rockefeller-foundation/`,
        `https://twitter.com/JamesMelville/status/1596813593390985216/photo/1`,
      ],
    },
  ],
  Discussions: [
    {
      description: [
        `<b>If you only have 10 minutes, watch this one.</b>`,
        `<b>Two UK doctors dismantle covid vaccines and an American doctor defendent of them for children under 5 years old.</b>`,
        `"I can assure the doctor that this vaccine is not like other childhood vaccinations, and cannot be considered in the same light.`,
        `This vaccine is extremely dangerous, it is not safe and effective, and it's potentially fatal.`,
        `So, the evidence does not support its use.. in children, in babies, in pregnant women, and actually in anybody at this stage because we have see the data emergent on the pharmacare vigilance databases, and the official data do not support the use of these genetic vaccines.`,
        `And if I could just remind you of how they work, they do not work the same way as other vaccines. It is a new technology that has not even sufficiently passed animal studies on safety. And yet we have rolled them out to billions of people around the world.`,
        `These new vaccines give our bodies a recipe to make spike protein, a foreign protein, a viral protein, with no off switch, for an indefinite period of time.`,
        `This vaccine does not stay in the shoulder muscle. It circulates around the body and gives our cells a recipe to make this spike protein, that then causes an inflammatory reaction throughout the body for an indefinite period.`,
        `The pharmaceutical companies have not disclosed all the ingredients in these vaccines, and we know from independent scientists with no conflicts of interest, that there are many undeclared contents, including graphene compounds and metallic objects, and these need to be revealed and disclosed by the pharmaceutical companies before we give these injections to anybody else, especially young children."`,
      ],
      urls: [
        `https://twitter.com/StayHungryPT/status/1548527487059218432`
      ],
    },
    {
      description: [
        `"It's my duty as a cardiologist to urgently inform every Dr, patient & member of the public that the mRNA product is a likely contributory factor in all unexpected cardiac arrests,heart attacks,strokes,cardiac arrhythmia & heart failure since 2021 until proven otherwise"`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1582595328867729409`
      ],
    },
    {
      description: [
        `<b>In COVID hearing, Pfizer director admits: covid vaccine was never tested on preventing transmission.</b>`,
        `"Get vaccinated for others" was always a lie. The only purpose of the COVID passport: forcing people to get vaccinated."`,
        `---`,
        `Rob Roos MEP:`,
        `"If you don't get vaccinated, you're anti-social - This is what the Dutch Prime Minister and Health Minister told us.`,
        `You don't get vaccinated just for yourself, but also for others - you do it for all of society. That's what they said.`,
        `Today, this turns out to be complete nonsense. In a COVID hearing in the European Parliament, one of the Pfizer directors just admitted to me: at the time of the introduction, the vaccine had never been tested on stopping the transmission of the virus.`,
        `<b>This removes the entire legal basis for the COVID passport. The COVID passport that lef to massive institutional discrimination, as people lost access to essential parts of society.</b>`,
        `I find this to be shocking - even criminal.`,
        `Please watch the video until the end.."`,
        `---`,
        `Rob Roos MEP:`,
        `"But to you, Ms. Small, I have the following short question, to which I would like to receive a clear response. I'll speak in English so there are no misunderstandings:`,
        `<b>Was the Pfizer COVID vaccine tested on stopping the transmission of the virus before it entered the market?</b>`,
        `If not, please say it clearly. If yes, are you willing to share the data with this committee?`,
        `And I really want a straight answer. Yes or no. And I'm looking forward to it. Thank you very much."`,
        `---`,
        `Janine. Small: Pfizer Director:`,
        `"Regarding the question around did we know (whether we knew) about stopping immunization before it entered the market?`,
        `<b>No, haha. These, emm, you know, we had to really move at the speed of science to really understand what is taking place in the market.</b>`,
        `---`,
        `Rob Roos MEP:`,
        `This is scandalous. Millions of people worldwide felt forced to get vaccinated because of the myth that <b>"you do it for others"</b>.`,
        `<b>Now, this turned out to be a cheap lie.</b>`,
        `------------`,
        `<b>Dr. John Campbell weighing in:</b>`,
        `"Pfizer Covid vaccine was NOT tested on stopping the transmission of the virus before it entered the market.`,
        `This was NOT done before the vaccine entered general rollout, despite us being assured that everything.. all the normal stages.. were carried out, as they would be for any new product, and yet it turns out now, we know what, however long it is now later, two years later or whatever, that this wasn't done.`,
        `This really is.. I think this is really quite scandalous. And I certainly feel personally let down by it, as I'm sure a lot of you do.`,
        `Continues reading: Ms. Small again, 'you know, we had to really move at the speed of science..'`,
        `<b>What I'd like to do now is pause and explain to you exactly what "the speed of science" means.. but I haven't got a flipping clue.</b>`,
        `It's just words without meaning. "The speed of science"?.. words without meaning. What does that mean? What's that supposed to mean? What's it supposed to convey? Just has no meaning to me at all - unless I'm a bit stupid of course.. maybe I'm just being a bit thick here and don't understand what "the speed of science" is.`,
        `But um.. <b>I've done two science degrees and a research degree and I've never heard the term..</b>`,
        `Anyway.. always need to learn new things..`,
      ],
      urls: [
        `https://twitter.com/Rob_Roos/status/1579759795225198593`,
        `https://www.youtube.com/watch?v=J6VbI8gOnUM`,
        `https://twitter.com/palminder1990/status/1579828305918058496`,
        `https://twitter.com/TrueNorthCentre/status/1579830040858329089`,
        `https://www.bitchute.com/video/o6ecHSN4izWF/`,
      ],
    },
    {
      description: [
        `<b>The @EU_Commission also knew that the vaccines are not stopping the spread of the virus!</b>`,
        `“If you want to have a vaccine that prevents transmission, best of luck. We could have got it, but it is not there yet”, a Commission's representative said in the COVID committee`,
        `Cristian Terhes MEP:`,
        `Pfizer and governments lied, people died. We know now for a fact that the COVID-19 vaccines are not stopping the transmission of the virus.`,
        `This was a truth known by both Pfizer and the governments.`,
        `Please watch carefully what the representative of the European Commission answered to one of my questions in the special COVID Committee of the European Parliament:`,
        `---`,
        `"The audit report states that the whole anti-vaccine strategy started from the presumption of using and I quote, "safe and effective vaccines".`,
        `We see now that these injections are not safe for many people, who suffer adverse effects, nor effective, since they do not stop the transmission of the virus, as it was just stated a few days ago in this commission, and they do not provide immunity from getting infected.`,
        `So the question that I address to you is the following:`,
        `What can be done to get a refund, in other words, to get the money back from these pharma companies who clearly lied and misled you and us, to purchase products that are not doing what they were marketed for. Thank you!`,
        `---`,
        `Wolfgang Philipp - European Commission:`,
        `Now, if you want to have a vaccine that prevents transmission, best of luck. We could have got it, but it is not there yet.`,
        `There is work going on in this direction but that is a completely different design of these vaccines. Just let me assure you on this one.`,
        `---`,
        `Watch now what the CEO of Pfizer said in a TV interview:`,
        `Albert Bourla - Pfizer CEO:`,
        `"There are lots of indications right now that they are telling us that <b>there is a protection against transmission</b> of the disease."`,
        `---`,
        `Contrary to what Mr. Bourla said in that TV interview, in a statement released to the media on October 13, 2022, Pfizer said the following, and I quote:`,
        `"Stopping transmission was not a study endpoint"`,
        `Thus, if the governments and the producers of these vaccines knew that they are not stopping the spread of the virus, why the politicians imposed vaccination through the Digital Green Certificate?`,
        `They all lied to us and all must be made accountable.`,
        `These injections are not stopping the spread of the virus.`,
      ],
      urls: [
        `https://twitter.com/CristianTerhes/status/1583012500232368128`
      ],
    },
    {
      description: [
        `Dr. Robert Malone:`,
        `It is not adequate to only consider the short term adverse events as related to the vaccine. We must acknowledge that the genetic covid19 genetic injections cause far more harm than good, and provide zero benefit relative to risk for the young and healthy.`,
        `They do not reduce covid19 infection, which is treatable and not terminal.`,
        `Furthermore, the most recent data demonstrates that you are more likely to become infected or have disease or even death if you've been vaccinated, compared to the unvaccinated people. This is shocking to hear, but it is what the data are showing us.`,
        `The data now show that these experimental gene therapy treatments can damage your children, as well as yourself.`,
        `They can damage your heart, your brain, your reproductive tissue, and your lungs. This can include permanent damage and disablement of your immune system.`,
      ],
      urls: [
        `https://twitter.com/BigBluexlt/status/1573494644012449793`
      ],
    },
    {
      description: [
        `Dr. Robert Malone speaking about Pfizer: In my opinion, withholding scientific data constitues fraud. This is scientific fraud.`,
        `Pfizer and the CDC committed fraud for willfully withholding critical data from the public resulting in harm and death to thousands. The CDC is spreading medical misinformation. What they have done is obscene. We are completely vindicated.`,
        `Pfizer and the CDC were hiding data that showed harm and death by injection. What's going on here is criminal. For the people who knew the data, but said nothing there will be legal consequences. Either you will be witnesses or defendants.`,
        `The FDA and the CDC had to be sued to release the data. The reluctance to release the data on a biological product that every person is being asked to take should make everyone concerned. DOD data shows clear increase in disease after injection.`,
      ],
      urls: [
        `https://twitter.com/LakovosJustice_/status/1522001676508581889`,
        `https://twitter.com/LakovosJustice_/status/1522001757378859010`,
        `https://twitter.com/LakovosJustice_/status/1522001827352432640`,
        `https://threadreaderapp.com/thread/1522001676508581889.html`,
      ],
    },
    {
      description: [
        `Pathologist Dr. Ryan Cole:`,
        `"We are literally doing the largest experiment on humanity ever done, not knowing the long term outcomes.`,
        `We only have a year and a half's data. And we have in the VAERS system, 30,000 deaths, well over 1,000,000 adverse reactions. We know that that system highly under-reports..`,
        `We have the EudraVigilance data, we have millions of adverse reactions, we have countless deaths from around the world, and in addition to that we have <b>all-cause death higher now in those who have received these shots, than those who didn't,</b> so we know there are very bad signals from this experimental therapy.`,
        `<b>These shot are all risk, no benefit.</b>`,
      ],
      urls: [
        `https://twitter.com/BLNewsMedia/status/1536044051966922755`,
        `https://rumble.com/v17c2ud-interview-a-lipid-nanoparticle-a-gene-is-a-nuclear-bomb-dr-ryan-cole-md.html`,
        `https://brightlightnews.com/a-lipid-nanoparticle-2/`,
      ],
    },
    {
      description: [
        `Miguel Escobar, physician assistant in healthcare for 15 years, with first hand covid experience speaking out against covid misinformation (July 27, 2021)`,
      ],
      urls: [
        `https://odysee.com/@NewsClipArchive/covid19-unfiltered-truth-about-wuhan-china-coronavirus-2021-08-05`,
        `https://www.bitchute.com/video/bqJd01kVL44B/`
      ],
    },
    {
      description: [
        `Dr. Dan Stock - specially trained in immunology, inflamation regulation.`,
        `<b>"Everything recommended by the CDC and the state board of health is actually contrary to all rules of science."</b>`,
        `Offers pro bono expert testimony to challenge covid measures.`
      ],
      urls: [
        `https://www.bitchute.com/video/JWAtXlFyDU0x/`
      ],
    },
    {
      description: [
        `<b>COVID Lockdowns: Never forget what has been done.</b>`,
      ],
      urls: [
        `https://threadreaderapp.com/thread/1508851849993986054.html`,
        `https://twitter.com/lladany/status/1508851849993986054`,
      ],
    },
    {
      description: [
        `David Redman: Former Executive Director of the Alberta Emergency Management Agency:`,
        `<b>Politicians ignored their own emergency management plans for c19, and instead followed a criminal cartel.</b>`,
        `(Additionally: Alberta's Failed COVID Management)`,
        `"Do you know the first world war at all? Battle of the Somme? The Battle of the Somme started on the first of June, ended when the snow fell in the fall.`,
        `The best military minds in the world, and I use this.. as a metaphor, the generals believed that on the first day of the Battle of the Somme, that if we shot a massive amount of artillery for 24 hours, that we would kill all the Germans and we would simply walk through their lines.`,
        `Well the first day didn't turn out that way, and we killed 100,000 soldiers, minimum, machine gunned to death because when the artillery came down, the Germans went in their foxholes and waited for the artillery to stop, came up, manned their machine guns and killed us all.`,
        `So a week later they said, ok.. <b>we didn't use enough artillery.</b> Let's use more.`,
        `So we tried it again, and it killed another 200,000.`,
        `Two weeks later we said, ok we need to start earlier, use more, and shoot longer. And they kept trying to find that perfect mix of artillery until they killed over a million men, and the winter snows stopped the lunacy.`,
        `<b>Let me tell you that's exactly what we're doing in covid.</b>`,
        `If only we had locked down sooner, locked down harder, and made it last longer, we would have had less deaths.`,
        `And we tried that in March, and we tried it in April, and then we tried it in October, November, December, January, February, and we're trying it again with the new variants, exactly the same. Maybe a little longer, maybe a little harder, maybe a little deeper..`,
        `And we still keep kill all our seniors over the age of 65 with multiple comormidities in long term care homes.`,
        `It's exactly the same because it seems to make sense.. if you use a shitload of artillery, you'll kill all the Germans. It really makes sense.`,
        `If you lockdown harder, you'll stop the spread, it really makes sense..`,
        `<b>Only it doesn't work."</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/iWDKDi6PTULq/`,
        `https://twitter.com/KoenSwinkels/status/1503867439989334019`,
        `https://twitter.com/KoenSwinkels/status/1503861885464195072`,
        `https://www.youtube.com/watch?v=VtM1f8j6ilQ`,
      ],
    },
    {
      description: [
        `<b>Dr. Robert Malone, MD - Inventor of mRNA technology<b>`,
        `The Joe Rogan Experience #1757`
      ],
      urls: [
        `https://open.spotify.com/episode/3SCsueX2bZdbEzRtKOCEyT`
      ],
    },
    {
      description: [
        `<b>Dr. Peter A. McCullough</b>`,
        `The Joe Rogan Experience #1747`,
        `Also Dr. Peter McCullough, MD's rumble channel:`,
      ],
      urls: [
        `https://open.spotify.com/episode/0aZte37vtFTkYT7b0b04Qz`,
        `https://rumble.com/PeterMcCulloughMD`,
      ],
    },
    {
      description: [
        `<b>Dr. Robert Malone - Neil Oliver Today</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/dYaLExqwee8/`
      ],
    },
    {
      description: [
        `Neil Oliver's latest covid monologue, this guy has a second to none way with words`,
        `<b>Tide turning. Who has been swimming naked?</b>`,
        `Neil Oliver's latest covid tyranny monologue, always a highlight of the week.`,
        `Neil Oliver: A different world`,
        `Neil Oliver: It's hard to tell yourself you've been taken for a fool, but open your eyes.`,
        `Neil Oliver: Badly, badly wrong. Biggest story in the UK`,
        `Neil Oliver: A Dose of Reality`,
      ],
      urls: [
        `https://gettr.com/post/ppzw34b042`,
        `https://gettr.com/post/pvku2ed3ea`,
        `https://www.bitchute.com/video/QNbUDeJjURXd/`,
        `https://www.bitchute.com/video/IbYiUlE6nK7o/`,
        `https://twitter.com/ryangerritsen/status/1604567075434991618`,
        `https://twitter.com/thevivafrei/status/1604832517986562048`,
        `https://twitter.com/ryangerritsen/status/1601977837858312193`,
      ],
    },
    {
      description: [
        `<b>Full mandate debate on Parliament Hill in Ottawa, Canada.</b>`,
        `A very important discussion of all aspects of Canadian vaccine mandates and the actual science of adverse effects.`
      ],
      urls: [
        `https://www.youtube.com/watch?v=DeTdxUSVD7A`,
        `https://parlvu.parl.gc.ca/Harmony/en/PowerBrowser/PowerBrowserV2/20220326/-1/36722`,
      ],
    },
    {
      description: [
        `People wake up when they are hurt. Woman opens up about being lied to about the covid vaccines.`,
        `"I got these shots because I trusted the government, and I trusted the health officials, and I did it because they told me that if I didn't, I wouldn't be able to see my grandparents.`,
        `I didn't even get to see them anyways.. what a fuckin' joke.`,
        `I also wanted you guys to know that when I got my first shot, and I'm scared to say this, I signed paperwork. They made me sign a form. And I cannot get myself retribution.`,
        `<b>I signed away all of their responsibility for what happens to me, because I trusted them.</b>`
      ],
      urls: [
        `https://twitter.com/RDeckard_2049/status/1544394318235832320`
      ],
    },
    {
      description: [
        `Christine Anderson - “People Need to Be Aware, There is Something Big Coming and it will turn out to be the biggest crime ever committed on humanity”`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1571489854164463618`
      ],
    },
    {
      description: [
        `<b>Dr Aseem Malhotra:</b>`,
        `"Having been double jabbed, and being one of the first to take the Pfizer vaccine, I have, after several months, critically appraising the data, speaking to eminent scientists at Oxford, Stanford and Harvard.`,
        `Speaking to two investigative medical journalists and being contacted by two Pfizer whistleblowers,`,
        `<b>reluctantly concluded that this vaccine is not complete safe and has unprecedented harms.</b>`,
        `<b>Which leads me to conclude that it needs to be suspended until all the raw data has been released for independent analysis.</b>`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1574986541302104065`,
        `http://insulinresistance.org`,
      ],
    },
    {
      description: [
        `Never forget how they treated people who didn't take the covid19 injection.`,
      ],
      urls: [
        `https://twitter.com/FreedomWorld_/status/1576204853390770176`,
        `https://twitter.com/FreedomWorld_/status/1576204968490840064`,
        `https://twitter.com/FreedomWorld_/status/1576205081426292736`,
      ],
    },
    {
      description: [
        `<b>Dr. Kulvinder Kaur MD: "Analysis suggests much lower pre-vac infection fatality rate in non-elderly popns than prev suggested"</b>`,
        `---`,
        `<b>[Age] | [Infection Survival Rate]`,
        `0 -19 | 99.9997%`,
        `20-29 | 99.997%`,
        `30-39 | 99.989%`,
        `40-49 | 99.965%`,
        `50-59 | 99.871%`,
        `60-69 | 99.499%`,
        `---`,
        `0-59 | 99.97%`,
        `0-69 | 99.93%`,
      ],
      urls: [
        `https://twitter.com/dockaurG/status/1582167280930107393`,
        `https://twitter.com/dockaurG/status/1582167283014959106`,
        `https://www.medrxiv.org/content/10.1101/2022.10.11.22280963v1.full`,
        `https://www.medrxiv.org/content/10.1101/2022.10.11.22280963v1.full.pdf`,
      ],
    },
    {
      description: [
        `Dr John Campbell goes through the government data`,
        `<b>24,440 NON-COVID UK excess deaths since May 2022</b>`,
        `We are now seeing more excess deaths a week than in 2020 or 2021.`,
      ],
      urls: [
        `https://twitter.com/djlange/status/1588294328962600961`
      ],
    },
    {
      description: [
        `Neil Oliver:`,
        `"For amnesty, all those culpable have to admit that they were wrong. There will have to be apologies. There will have to be criminal investigations."`,
        `"How can you seek an amnesty when you're still doing that which you are presumably seeking an amnesty for?"`,
      ],
      urls: [
        `https://twitter.com/hugh_mankind/status/1589400009425842176`
      ],
    },
    {
      description: [
        `"I'm not going to forget either."`,
        `Things to remember in the face of "covid amnesty".`
      ],
      urls: [
        `https://twitter.com/TomMarazzo/status/1589611291643203584`
      ],
    },
    {
      description: [
        `This video is in response to the The Atlantic's article about "pandemic amnesty".`,
        `"Look, they want everybody to forget and forgive everything that happened during the pandemic."`,
        `'We were all in the dark, we were all afraid'..`,
        `Like.. nah bitch.. like, that was like we all got kidnapped and put together on an island, and all yall bastards were like "we should eat them" and we're like "what the fu" and then now they took us off the island and we're just looking at you on the ride home like.. ( :| )  and you're like '(giggling) oh wasn't that crazy?' and we're like, "nah bitch, <b>you tried to eat us..</b>"`,
        `I didn't kick you out of no stores, I didn't ruin your job, I didn't tell you you couldn't come to the family reunion, I didn't tell you you had to drop dead of a heart attack 'cause you didn't get the jab so you're not welcome at this hospital..`,
        `There ain't nothing to forgive on my side baby boo. This is a you problem. And we're still talking about it.`,
      ],
      urls: [
        `https://twitter.com/just_mindy/status/1587148452055629826`
      ],
    },
    {
      description: [
        `TPM's Ari Hoffman @thehoffather reacts to an article in The Atlantic calling for COVID amnesty:`,
        `"You should be ashamed of yourself for even writing this article."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1587512813735489536`
      ],
    },
    {
      description: [
        `Ed Dowd: COVID and the Global Financial Collapse: A Tale of Catastrophes and Cover-Ups`,
      ],
      urls: [
        `https://rumble.com/v1mny36-ed-dowd-covid-and-the-global-financial-collapse-a-tale-of-catastrophes-and-.html`
      ],
    },
    {
      description: [
        `<b>Mistakes Were Not Made</b>`,
        `by Margaret Anna Alice`,
        `The Armenian genocide was not a mistake. Holdomor was not a mistake. The final solution was not a mistake. The great leap forward was not a mistake. The killing fields were not a mistake.`,
        `Name your genocide. It was not a mistake.`,
        `That includes the great democide of the 2020's. To imply otherwise is to give them the out they are seeking.`,
        `It was not botched, it was not bungled, it was not a blunder, it was not incompetence, it was not lack of knowledge, it was not spontaneous mass hysteria.`,
        `The planning occurred in plain sight. The planning is still ocurring in plain sight.`,
        `The philanthopaths bought the science. The modellers projected the lies. The testers concocted the crisis. The NGOs leased the academics. The scientists fabricated the findings. The mouthpieces spew the talking points. The organizations declared the emergency. The governments erected the walls. The departments rewrotes the rules. The governers quashed the rights. The politicians passed the laws. The bankers installed the control grid. The stooges laundered the money. The DoD placed the orders. The corporations fulfill their contracts. The regulators approve the solution. The laws shielded the contractors. The agencies ignored the signals. The behemoths consolidated the media. The psychologists crafted the messaging. The propagandists chanted the slogans. The fact-checkers smeared the dissidents. The censors silenced the questions. The jackboots stomped the dissenters.`,
        `The tyrants summoned, the puppeteers jerked, the puppets danced. The colluders implemented. The doctors ordered. The hospitals administered. [they] scripted. The bamboozled beated. The totalitarianized bullied. The covidians tattled. The parents surrendered. The good citizens believed and forgot.`,
        `This was calculated. This was formulated. This was focus-grouped. This was articulated. This was manufactured. This was falsified. This was coerced, this was inflicted, this was denied.`,
        `We were terrorized. We were isolated. We were gaslit. We were dehumanized. We were wounded. We were killed.`,
        `...`,
        `<b>Don't let them get away with it. Don't let them get away with it. Don't let them get away with it.</b>`,
      ],
      urls: [
        `https://twitter.com/unhealthytruth/status/1637636712711979020`
      ],
    },
    {
      description: [
        `<b>Dr. John Campbell: A credible explanation behind vaccine adverse events with Marc Girardot</b>`,
        `Superb review from Marc Girardot on inadvertent intravenous administration of vaccines. This evidence and analysis demands a response from regulators around the world. If this is not forthcoming, I consider the regulators negligent.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=mbZ6E2rhdcw`
      ],
    },
    {
      description: [
        `Town councillor in West Nipissing, Ontario: “we are deeply sorry for the Covid Vaccine mandates, we now know that these vaccines do not stop the spread and have a higher per capita infection rate compared to the unvaccinated, again we are deeply sorry”`,
      ],
      urls: [
        `https://twitter.com/bambkb/status/1631844657528225793`
      ],
    },
    {
      description: [
        `SNL host Woody Harrelson calls out Big Pharma over COVID and the vax.`,
      ],
      urls: [
        `https://twitter.com/EndWokeness/status/1629739199514521601`
      ],
    },
    {
      description: [
        `<b>Livid woman who lost her father to Remdesivir and malnutrition in the hospital yells:</b>`,
        `So, anybody heard about Kristin the Piston? Anybody heard about how my father was murdered with Remdesivir? Has anybody heard about how the hospital called me and blamed me and my mom because we weren't vaccinated, and that was the reason he died?`,
        `Anybody heard about how they put my dad on paliative care, and they never fed him, and they starved him to death? And they only gave him one can of Ensure in nine days? Anybody hear about that?`,
        `Anybody hear about how they put my dad in bilateral restraints, restraining him against his will, and popped him full of Remdesivir, against his and his family's wishes? While they called me and blamed me and told me he was dying because I wouldn't take the vaccine? Has anyone heard this story? Anyone? You heard this story yet?`,
        `You heard about how my dad was murdered in a worse way than people on death row? Do you hear me? People on death row are treated more humanely than my father. He wasn't able to use a goddamn phone. Are you listening to me?`,
        `People want me to act all nice about this shit man, fuck you! Fuck you. This shit is bullshit. They murdered my father, they injected him with poison, they lied to me, they blamed me. You want me to act normal?`,
      ],
      urls: [
        `https://twitter.com/BohemianAtmosp1/status/1623082067083894785`
      ],
    },
    {
      description: [
        `Jay Bhattacharya: Follow Science, Not Scientists`,
        `Brian Keating's INTO THE IMPOSSIBLE Podcast`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=iTnJNXYFg9M`
      ],
    },
    {
      description: [
        `Bret Weinstein Speaks with Dr. Aseem Malhotra on the Darkhorse Podcast`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=4MKQ0krjLpo`
      ],
    },
    {
      description: [
        `<b>WEF predicting “catastrophic” cyber attacks over the next two years.</b>`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1616548238093619200`
      ],
    },
    {
      description: [
        `<b>Elites planning to weaponize water.</b>`,
        `<b>DAVOS 2022 - Economist Mariana Mazzucato declared that the failure to vaccinate the entire world population against covid should lead to a review of the way of imposing the notion of common interest: it would thus be wiser according to her to refer to rather a water crisis</b>`,
        `So of course true with covid, right, we are all only as healthy as our neighbours, and our street, and our city, and our region, and our nation, and globally, and did we solve that? Like did we actually manage to vaccinate everyone in the world? No.`,
        `So highlighting water as a global commons and what it means to work together and see it both out of that kind of global commons perspective, but also to the self-interest perspective, because it is, it does have that parallel.. it's not only important but it's also important because we haven't managed to solve those problems which had similar attributes, and water is something that people understand.`,
        `You know, <b>climate change is a bit abstract,</b> some people understand it really well, some understand it a bit, some just don't understand it.. <b>Water: every kid knows how important it is to have water..</b> when you're playing football and you're thirsty, you need water.`,
        `So there's also something about really getting citizen engagement around this and really, in some ways, experimenting, with this notion of the common good, can we actually deliver this time in ways that we have failed miserably other times? And hopefully we won't keep failing on the other things, but anyway.`,
      ],
      urls: [
        `https://twitter.com/Xx17965797N/status/1642433788872458241`
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `In my view, the current health authorities should pause the covid 19 mass vaccine rollout program due to the risks associated with the vaccines.`,
      ],
      urls: [
        `https://twitter.com/ryangerritsen/status/1608317987437662208`
      ],
    },
    {
      description: [
        `The Highwire: Vaccinated apology to unvaccinated`,
        `An opinion piece from an Australian vaccinated writer:`,
        `If covid was a battlefield, it would still be warm with the bodies of the unvaccinated.`,
      ],
      urls: [
        `https://twitter.com/NewsNancy9/status/1553923421439856640`
      ],
    },
    {
      description: [
        `Dr. John Campbell: Myocarditis German evidence`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=j_DdSMn55cA`
      ],
    },
    {
      description: [
        `What happens when you compare Long COVID to Long ANY OTHER VIRUS? NEW PAPER in JAMA NETWORK OPEN`,
        `<b>Association of Initial SARS-CoV-2 Test Positivity With Patient-Reported Well-being 3 Months After a Symptomatic Illness</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=D4tlmsQFyxg`,
        `https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2799116`,
      ],
    },
    {
      description: [
        `Cross Sectional Studies FAIL For LONG COVID | Amazing new Lancet Paper!`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=bCi9Xpag4FM`
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `More vaccinated deaths than unvaccinated deaths from covid (US)`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=Jb2YMvfvm_M`
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Full analysis, vaccines and accidents`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=YIS9lIDmmQk`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `My yellow card report`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=Fb9E3cOPi-c`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Vaccine adverse reactions, reanalysis of mRNA trial data`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=JYR1wz-Cf_M`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Lessons from China and Africa`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=WHIXuW4gxMY`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Increased risk of stroke`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=YswaUoFEs8E`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Pfizer press release continues`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=-FK17NU4_r8`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Masks in hospital, don't work`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=zviCtcZjN-I`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Masks, no evidence they work`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=S3vY2LyQn1A`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Vaccination v excess deaths, correlation study`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=iyo2UNQcdpQ`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Post covid myocarditis?`,
        `<b>Post COVID-19 infection was not associated with either myocarditis or pericarditis</b>`
      ],
      urls: [
        `https://www.youtube.com/watch?v=CNics0Q_yyo`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Natural cross immunity confirmed`
      ],
      urls: [
        `https://www.youtube.com/watch?v=EaSU2mfNrIg`,
      ],
    },
    {
      description: [
        `<b>Russell L. Blaylock: COVID UPDATE: What is the truth?</b>`,
        `Neurosurgeon Russell Blaylock: "The C19 pandemic is 1 of most manipulated infectious disease events in history, characterized by official lies in an unending stream lead by govt bureaucracies, med assocs, med boards, the media, & international agencies"`,
        `---`,
        `The COVID-19 pandemic is one of the most manipulated infectious disease events in history, characterized by official lies in an unending stream lead by government bureaucracies, medical associations, medical boards, the media, and international agencies.[3,6,57] We have witnessed a long list of unprecedented intrusions into medical practice, including attacks on medical experts, destruction of medical careers among doctors refusing to participate in killing their patients and a massive regimentation of health care, led by non-qualified individuals with enormous wealth, power and influence.`,
        `For the first time in American history a president, governors, mayors, hospital administrators and federal bureaucrats are determining medical treatments based not on accurate scientifically based or even experience based information, but rather to force the acceptance of special forms of care and “prevention”—including remdesivir, use of respirators and ultimately a series of essentially untested messenger RNA vaccines. For the first time in history medical treatment, protocols are not being formulated based on the experience of the physicians treating the largest number of patients successfully, but rather individuals and bureaucracies that have never treated a single patient—including Anthony Fauci, Bill Gates, EcoHealth Alliance, the CDC, WHO, state public health officers and hospital administrators.[23,38]`,
        `...`,
      ],
      urls: [
        `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9062939/`,
        `https://twitter.com/andrewbostom/status/1527489960202346500`,
      ],
    },
    {
      description: [
        `Hello Darkness | Kate Wand | The Sound of Silence`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=qeafo5LCzAg`
      ],
    },
  ],
  Disinformation: [
    {
      description: [
      `<strong>Forbes article on the untrustworthiness of “fact-checker” Snopes.</strong>`,
      `“..exceptionally grave concerns about the internal workings of Snopes and why it is not more forthcoming about its assessment process.”`,
      ],
      urls: [
      `https://www.forbes.com/sites/kalevleetaru/2016/12/22/the-daily-mail-snopes-story-and-fact-checking-the-fact-checkers/?sh=62ed71da227f`,
      ],
    },
    {
      description: [
      `Illinois Health Official states plainly how a “covid death” is determined.`,
      `To the hypothetical example of someone in a hospice with a few weeks to live, then tests positive for covid, that WOULD be counted as a “covid death”.`,
      `Government officials are telling us plainly that the true numbers of people actually dying of covid and nothing else, are not the numbers being officially, and publicly reported.`,
      ],
      urls: [
      `https://twitter.com/ChickenGate/status/1424811210206322689`,
      ],
    },
    {
      description: [
      `Alberta Health Minister Deena Hinshaw says if a child isn't tested for covid, but is just home with an illness, they are counted in the list of covid case outbreaks.`,
      `“Ok. So now if my child stays home from school, which is what we are supposed to do if they get a sniffle, they will now be counted as having covid?”`,
      ],
      urls: [
      `https://twitter.com/kacey76/status/1441387859349577728`,
      ],
    },
    {
      description: [
      `(August 2021) In Canada, of the 26,810 people with <strong>deaths related to covid19</strong>: (for the entire pandemic.. from the beginning, to August in this case)`,
      `only 16 were <strong>below</strong> the age of <strong>20</strong>`,
      `only 84 were <strong>below</strong> the age of <strong>30</strong>. (including under 20)`,
      `22,544 were <strong>above</strong> the age of <strong>70</strong>. Which is <strong>84%</strong> of all the deaths <strong>related to</strong> covid19.`,
      `The majority of all these recorded deaths <strong>were not</strong> evenly spread around the country. They were concentrated in Ontario and Quebec. But not the entirety of the provinces, just a few key <strong>districts</strong>. So a literal handful of districts in Ontario and Quebec (some which had massive numbers from their mistake of putting covid patients alongside nursing home patients) are responsible for most of the entirety of Canada's covid-related death numbers, and 84% of these concentrated numbers were people above the age of 70.`,
      `These are real Canadian totals from the governement's own website. Do the unprecedented measures taken to “combat” this, especially for those <strong>under</strong> 60 or 70 years old, <strong>match the actual numbers</strong> actually recorded?`,
      `Are the <strong>585 total related deaths under age 50</strong> grounds to shut down every business most likely owned by that age group? To tear apart their families? To threaten them with their jobs.. their ability provide for their family?`,
      ],
      urls: [
      `https://twitter.com/odb47/status/1431401586568400896/photo/1`,
      `https://health-infobase.canada.ca/covid-19/epidemiological-summary-covid-19-cases.html`,
      ],
    },
    {
      description: [
        `<strong>In April 2020</strong>`,
        `two doctors, Dr. Erickson and Dr. Massih had a COVID-19 briefing.`,
        `They were slandered and their interview banned and scrubbed.`,
        `During their long discussion with reporters, Dr. Erickson noted he has spoken to numerous physicians who say they are being pressured to add COVID-19 to death certificates and diagnostic lists—even when the novel coronavirus appears to have no relation to the victim's cause of death.`,
        `“They say, 'You know, it's interesting. When I'm writing up my death report I'm being pressured to add Covid,'” Erickson said. “Why is that? Why are we being pressured to add Covid? To maybe increase the numbers, and make it look a little bit worse than it is?”`,
      ],
      urls: [
        `https://rumble.com/vrsj3b-most-banned-video-on-internet-dr.-erickson-covid-19-briefing-in-april-2020.html`
      ],
    },
    {
      description: [
      `Dr. Roger Hodkinson, Edmonton doctor: “This 'vaccine' is not safe.”`,
      ],
      urls: [
      `https://rumble.com/vka1wp-dr.-roger-hodkinson-edmonton-alberta-saturday-july-24-2021.html`,
      ],
    },
    {
      description: [
      `David E. Martin testifies at the German Corona Inquiry Committee July 9th, 2021`,
      `“not a novel coronavirus, or novel anything, it hasn't been novel 1999.”`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=ihjNDf32_Ac`,
      ],
    },
    {
      description: [
      `By memo of Aug. 31, CMOH Moore told Ontario's MOs that <strong>the vaccinated are just as infectious as the unvaccinated</strong> & recommends additional measures.`,
      `<strong>CMOH had knowledge & memo pre-dates Ontario's announcement of vaccine passports.</strong>`,
      ],
      urls: [
      `https://twitter.com/Roman_Baber/status/1455867129526460422`,
      `https://twitter.com/Roman_Baber/status/1455867129526460422/photo/1`,
      `https://twitter.com/Roman_Baber/status/1455867129526460422/photo/`,
      ],
    },
    {
      description: [
      `“No later than September 2019, the Canadian government was fully aware and participating in what was to be a global exercise, to test the readiness of the world, to deal with a respiratory pathogen pandemic, that was planned and published in September 2019, and the Canadian government was very much a part of that”`,
      ],
      urls: [
      `https://www.tiktok.com/@1businessmentor/video/7022297309966896390`,
      ],
    },
    {
      description: [
      `Alberta doctor says hospital capacity numbers manipulated by AHS`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=rG_M_aPrNi4`,
      ],
    },
    {
      description: [
      `Pfizer whistleblower leaks execs emails exposing suppression of covid vaccine information from public`,
      ],
      urls: [
      `https://www.bitchute.com/video/FUXGB5FzhPc/`,
      ],
    },
    {
      description: [
      `Auatralia force vaccinated people and are now saying that if you die from the vaccine, it's your own fault.`,
      `"people are responsible for their own bodies"`,
      ],
      urls: [
      `https://twitter.com/ChickenGate/status/1492673621344632835`,
      ],
    },
    {
      description: [
      `PROJECT VERITAS PART 1:`,
      `FDA OFFICIAL 'BLOW DART AFRICAN AMERICANS'`,
      ],
      urls: [
      `https://www.bitchute.com/video/Wr3vDLTem1nb/`,
      ],
    },
    {
      description: [
      `PROJECT VERITAS PART 2:`,
      `Federal Govt HHS Whistleblower Goes Public With Secret Recordings "Vaccine is Full of Sh*t"`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=obdI7tgKLtA`,
      ],
    },
    {
      description: [
      `PROJECT VERITAS`,
      `Pfizer Scientists: 'Your [COVID] Antibodies Are Better Than The [Pfizer] Vaccination.' `,
      `"...so, your antibodies are probably better at that point than the vaccination."`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=On5RYFbcxWY`,
      ],
    },
    {
      description: [
        `It's Vitamin D status, not comorbidities, that drive the severity of outcomes for COVID-19 disease.`,
        `Once again, our public health authorities are caught in a lie.`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1493967261027082242`,
        `https://www.youtube.com/watch?v=HLvDkYr3GaY`,
      ],
    },
    {
      description: [
        `<strong>Cross-reactive memory T cells associate with protection against SARS-CoV-2 infection in COVID-19 contacts</strong>`,
        `Cross-reactive immune responses to SARS-CoV-2 have been observed in pre-pandemic cohorts and proposed to contribute to host protection.`,
      ],
      urls: [
        `https://www.nature.com/articles/s41467-021-27674-x`
      ],
    },
    {
      description: [
        `Strong and clear statement made by Professor Christian Perronne`,
        `<strong>"Publications in the world's greatest journals were fraudulent."</strong>`
      ],
      urls: [
        `https://rumble.com/vsyb0y-strong-and-clear-statement-made-by-professor-christian-perronne-with-englis.html`
      ],
    },
    {
      description: [
      `Is the covid vaccine FDA Approval legit? Commentary by lawyer.`,
      `<strong>"The FDA is advertising, on Facebook and other places, that the FDA approved one is available to them locally. That's false."</strong>`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=aiUFPDAlsLU`,
      ],
    },
    {
      description: [
      `CBS insider exposes internal training.`,
      `"I challenge you to stop thinking in terms of objective journalism because, and we'll discuss why that's not really feasible anymore." - Multicultural Competency Trainer`,
      `"I don't really care if people trust us or not, you still got to do your job. So, I mean that's the least of my concerns whether they trust me or not." - News Anchor`,
      ],
      urls: [
      `https://www.bitchute.com/video/x4yJYMIpoy8/`,
      ],
    },
    {
      description: [
      `Is Biden's Vaccine Mandate Constitutional? Lawyer EXPLAINS`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=DO7LxVDl76Y`,
      ],
    },
    {
      description: [
      `American Thought Leaders:`,
      `<strong>PART 1: Dr. Robert Malone, mRNA Vaccine Inventor</strong>, on Latest COVID-19 Data, Booster Shots, and the Shattered Scientific 'Consensus'`,
      `<strong>PART 2: Dr. Robert Malone on Ivermectin, Escape Mutants, and the Faulty Logic of Vaccine Mandates</strong>`,
      ],
      urls: [
      `https://www.theepochtimes.com/dr-robert-malone-mrna-vaccine-inventor-on-latest-covid-19-data-booster-shots-and-the-shattered-scientific-consensus_3979206.html`,
      `https://www.theepochtimes.com/part-2-dr-robert-malone-on-ivermectin-escape-mutants-and-the-faulty-logic-of-vaccine-mandates_3981859.html`,
      ],
    },
    {
      description: [
      `Alberta Health Minister Deena Hinshaw lies about 14 year old dying of covid. Only after the outraged family publicly spoke about it, did she address her lie.`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=pZNbSttBeZE`,
      ],
    },
    {
      description: [
        `Ontario Health Minister Christine Elliot is accusing some doctors of spreading "misinformation about vaccines." Elliot urged the College of Physicians to do "everything" possible to censor these doctors.`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1483906563802869761`
      ],
    },
    {
      description: [
      `SARS had a case fatality rate of 10%. Alpha covid variant has a case fatality rate of 1.9%. Delta variant has 0.1% case fatality rate.`,
      `RCMP leaked zoom call with John Tory. Delta has 99.9% survival rate.`,
      ],
      urls: [
      `https://twitter.com/Cudabarbie1/status/1421250609852731394`,
      ],
    },
    {
      description: [
      `Bill Gates says vaccines are the "Clearly One Of The Best Investments I've Ever Been Involved In".`,
      `Other officials contradicting themselves over and over.`,
      `Follow the money. Total lies from the people who are telling us what to do.`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=q37AmWP_IqM`,
      `https://www.bitchute.com/video/PLHKgZuwsHPq/`,
      ],
    },
    {
      description: [
      `Ontario MOH Dr. Kieran Moore used the chilling phrase, “the unvaccinated amongst us”.`,
      `Mayo Clinic doctor says the T Cell / immune cell abnormalities, suggest vaccines were actually weakening the immune system of his patients.`,
      ],
      urls: [
      `https://twitter.com/Kirky861/status/1430735670536966144 `,
      ],
    },
    {
      description: [
      `Navy surgeon sounds the alarm on vaccines. Only 20 deaths <strong>with</strong> covid.`,
      `But they're vaccinating everyone. Already had tumors and 80 cases of myocarditis, which has a high mortality rate of ~66%.`,
      `<strong>“our vaccine program ostensibly killed more of our young, active duty people than covid did.”</strong>`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=BPkrU23DG5A`,
      ],
    },
    {
      description: [
      `Police try to kick mall patrons out for not showing their “papers”. Patrons end up schooling security and the police on why they're well within their rights ro refuse.`,
      ],
      urls: [
      `https://www.bitchute.com/video/fJoPhHsrkLpn/`,
      `Woman from video above explains how she refused “proof of vaccination”`,
      `https://rumble.com/vn8o9k-woman-from-viral-video-explains-how-she-refused-proof-of-vaccination.html`,
      `https://gameoncanada.org/`,
      ],
    },
    {
      description: [
      `Dr. Reiner Fuellmich`,
      `Poland House of Representatives' Investigation Committee: inauguration with dr Reiner Füllmich`,
      `<strong>“Our hearings proved the following results beyond any reasonable doubt."</strong>`,
      `1. Covid measures were never about health. We don't have a pandemic, we have a virus circulating that any in-tact human immune system can fight just as well as the flu. Apart from that there are very good alternative methods of treatment to prevent or treat this disease, such as vitamin c, vitamin d, zinc, possibly even ivermectin and others.`,
      `2. Our governments, at least almost all European governments, as well as the United States, are not acting in our best interest. But are largely under the control of the backers of the global corporations and ngo's.`,
      `As the Corona Committee, how did we come to this conclusion?”`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=hWdKRNaH2Fw`,
      ],
    },
    {
      description: [
      `Rand Paul and Dr. Fauci GO AT IT Over China, Infamous Wuhan Lab`,
      ],
      urls: [
      `https://rumble.com/vgvbon-rand-paul-and-dr.-fauci-go-at-it-over-china-infamous-wuhan-lab.html`,
      ],
    },
    {
      description: [
      `Evidence That US Government Targeted Red States With Deadly Batches of Vaccine`,
      `"howbadismybatch.com, a database of over 700,000 adverse reactions caused by the Pfizer, Moderna and Janssen vaccines. These vaccines have been deployed in different batches, officially referred to as "lots". This has allowed Former Head of Respiratory Research at Pfizer, Mike Yeadon's team to back-engineer big pharma's covid 19 vaccine deployment agenda in the US. What we learn is quite telling. Around 0.5% of all the batches are highly toxic, resulting in hospitalization, disability and death."`,
      `"Some states are experiencing as much as eleven times more vaccine deaths than other states... Red states received at least twice as much of the lethal batches as blue states did."`,
      ],
      urls: [
      `https://rumble.com/vt0deq-evidence-that-us-government-targeted-red-states-with-deadly-batches-of-vacc.html`,
      `https://howbadismybatch.com`,
      ],
    },
    {
      description: [
      `Can Zn Be a Critical Element in COVID-19 Treatment? <strong>(Published May 2020)</strong>`,
      `Hydroxychloroquine, a less toxic derivative of chloroquine, is effective in inhibiting SARS-CoV-2 infection in vitro <strong> (Published March 2020)</strong>`,
      `Chloroquine is a potent inhibitor of SARS coronavirus infection and spread<strong> (Published 2005)</strong>`,
      ],
      urls: [
      `https://link.springer.com/content/pdf/10.1007/s12011-020-02194-9.pdf`,
      `https://www.nature.com/articles/s41421-020-0156-0.pdf`,
      `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1232869/pdf/1743-422X-2-69.pdf`,
      ],
    },
    {
      description: [
      `Sharri Markson - SPECIAL INVESTIGATION: What Really Happened in Wuhan - Documentary`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=oh2Sj_QpZOA`,
      ],
    },
    {
      description: [
      `Dr. Andrew Kaufman, MD: The Naked Truth on the current situation`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=CCrCAPU6zjk`,
      ],
    },
    {
      description: [
      `Dr. Jay Bhattacharya, MD, PHD: The Harms of Lockdowns, The Dangers of Censorship, And A Path Forward`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=pNntSNmpOL4`,
      ],
    },
    {
      description: [
      `In 2012, Dr. Fauci said Gain of Function Research was <strong>worth the risk of a pandemic</strong>, revealed by journalist Sharri Markson.`,
      ],
      urls: [
      `https://rumble.com/vhr687-sharri-markson-reveals-dr.-fauci-said-gain-of-function-was-worth-risk-of-pa.html`,
      ],
    },
    {
      description: [
      `CVS Pharmacist admits he should not be giving covid 19 vaccines.`,
      `Admits the documentation / package insert given with the vaccine doesn't contain certain information / truncated.`,
      `"So why is it intentionally blank if it's all the safety studies?"`,
      `Pharmacist opens up folded documentation / package insert and it is completely blank.`,
      `"I.. I've got to get online and find it.."`,
      `"No that's ok, My last question was how it is informed consent when all of the safety studies, placebo safety studies are not listed from the manufacturer?"`,
      `Pharmacist: "... Tha.. that's a great question.. I would ta.. I would talk to.. I would talk to CVS about that 'cause..."`,
      `"So if you don't know what we're injecting into ourselves, I don't understand how that's informed consent."`,
      `Pharmacist: "... Tha.. you're, you're exac.. you're exactly right, and you are correct, I should not be giving these vaccines at all."`,
      `"Ok, why are you giving them?"`,
      `Pharmacist: "Because I am.. because I am told to and that's how.. because I am told to and I am told.. and.. and.. I.. and everything I have shown, including the patients that I have given it to, it is safe."`,
      `"It is safe and effective? What studies are you going by that states it is safe and effective? I know these are tough questions but I have to ask."`,
      `Pharmacist: "I know.. and I.. and I'm sorry but.. I.. I am.. I unfortunately cannot answer that.. and I feel.. and right now I'm feeling totally inadequate.."`,
      `( Also other various clips )`,
      ],
      urls: [
      `https://www.bitchute.com/video/kQ0udRiQfWkJ/`,
      ],
    },
    {
      description: [
      `All the experts promised us the vaccines stop the spread. "covid cannot use a vaccinated host to get more people", "you're ok, you're not going to get covid if you have these vaccinations" etc etc etc.`,
      `Never forget what they promised you.`,
      ],
      urls: [
      `https://twitter.com/akheriaty/status/1480917179138719753`,
      ],
    },
    {
      description: [
      `Vermont Emergency Rooms Overwhelmed With <strong>Asymptomatic People</strong>`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=JcL3MZxxuFM`,
      ],
    },
    {
      description: [
        `Alberta Health Services gross data manipulation, reclassifying over 16,000 active cases as "unvaccinated".`,
      ],
      urls: [
        `https://twitter.com/Martyupnorth_/status/1486115456213655553`
      ],
    },
    {
      description: [
        `Bayer Board Member says it's gene therapy.`,
        `"Ultimately the mRNA vaccines are an example for that sell of gene therapy.`,
        `I always like to say,<strong> if we had surveyed two years ago in the public, "would you be willing to take gene or cell therapy and inject it into your body", we would have probably had a 95% refusal rate."</strong>`,
      ],
      urls: [
        `https://twitter.com/EMC2_Relativity/status/1474971216767336449`
      ],
    },
    {
      description: [
      `Bret Weinstein's Dark Horse Podcast:`,
      `“Destruction of dissent”, Inverse of Manufacture of Consent (from Livestream #100)`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=JMqrhIC93tY`,
      ],
    },
    {
      description: [
      `More GOOD news about Omicron`,
      `“Omicron is extremely mild. The rest of the world has nothing to fear."`,
      `But you wouldn't know that from the reaction of Boris Johnson's government.`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=B0RZ3gext50`,
      ],
    },
    {
      description: [
      `Why Nordic Countries Are Lifting Coronavirus Restrictions EARLY`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=GytEUxgPKzc`,
      ],
    },
    {
      description: [
      `From the White House to Lancet... We aren being LIED TO and venerable institutions have been compromised.`,
      `A talk regarding the White House's holiday note: "...For the unvaccinated, you're looking at a winter of severe illness and death for yourselves, your families, and the hospitals you may soon overwhelm."`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=QV7DamQJucM`,
      ],
    },
    {
      description: [
      `Mask / vaccine mandates vs covid cases.`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=B7I7ITmxnbI`,
      `( ! ) removed from YT.  Interesting how it apparently violated “community guidelines” but it was just displaying publicly available data.`,
      ],
    },
    {
      description: [
      `Rand Paul grills Fauci`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=q6yPVZ6Khe0`,
      `(removed by YT at one point, but now appears to be reinstated.)`,
      ],
    },
    {
      description: [
      `Rand Paul Tells Fauci He Changed Website To 'Cover Your Ass' On Gain-Of-Function Research`,
      `Eco-Health Alliance, fuded by NIH in Wuhan, gain of function research.`,
      `“gain of function research looks like a gamble that civilization can't afford to risk.”`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=5AcwcdSVvVc`,
      ],
    },
    {
      description: [
      `'You Sir, Are The One Ignoring Science': Rand Paul Battles Becerra Over COVID-19 Rules`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=MI_Dm3pj3dQ`,
      ],
    },
    {
      description: [
      `The FDA is funded by the same Big Pharma & Corporations it regulates. What are the issues of the potential conflict of interests within this current system?`,
      `Russell Brand - why you can't trust big pharma:`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=7fQ6JklHjBc`,
      ],
    },
    {
      description: [
      `Gal Shalev (Ignorant Chimp) talks about FDA documents which lay out how "Pfizer themselves received just about 42,000 adverse events complaints... 26,000 confirmed medically."`,
      `"...doctors can't tell a patient that their adverse event is from the vaccine. If they do, they get fired."`,
      ],
      urls: [
      `https://www.bitchute.com/video/pEwL0KuvXh0j/`,
      ],
    },
    {
      description: [
      `Robert F. Kennedy Jr talks about history, Fauci, and his book`,
      ],
      urls: [
      `https://www.corbettreport.com/the-real-anthony-fauci-with-robert-f-kennedy-jr-video/`,
      ],
    },
    {
      description: [
        `Project Veritas:`,
        `Military Documents about Gain of Function contradict Fauci testimony under oath.`
      ],
      urls: [
        `https://www.youtube.com/watch?v=_zgoENmeddA`
      ],
    },
    {
      description: [
        `Dr. Fauci: "We may need to boost again."`,
        `"We don't know the durability of protection from the third shot."`
      ],
      urls: [
        `https://twitter.com/disclosetv/status/1485291308348579840`
      ],
    },
    {
      description: [
        `CDC Director on PCR tests:`,
        `<strong>These tests are not actually authorised for the purpose of evaluating contagiousness.</strong>`,
        `They ran the whole pandemic on those “unauthorised” PCR tests.`,
      ],
      urls: [
        `https://twitter.com/AussieVal10/status/1482972602792837123`
      ],
    },
    {
      description: [
        `Neil Oliver:`,
        `If we are to condemn husbands to scream their grief in cars in hospital car parks while wives die alone, are there any leaders left worthy of the trust of decent people?`,
        `But given unwisely, and in error, offered up to an undeserving receipient, and then abused by them, trust is as fragile as a soap bubble. In truth, trust is a one time only offer.`,
      ],
      urls: [
        `https://twitter.com/GBNEWS/status/1482433803255746561`
      ],
    },
    {
      description: [
        `Ontario's Chief Medical Officer Kieran Moore admits that COVID hospitalizations are an unreliable metric (citing a hospital in Kingston) <strong>despite public health using that statistic as their basis to justify lockdowns throughout the entire pandemic.</strong>`,
      ],
      urls: [
        `https://twitter.com/_ThePeoplesNews/status/1476684586595803138`
      ],
    },
    {
      description: [
        `<strong>“What the vaccines can't do anymore is prevent transmission."</strong>`,
        `CDC Director Walensky`,
      ],
      urls: [
        `https://twitter.com/drscott_atlanta/status/1480295777213857799`
      ],
    },
    {
      description: [
        `<b>"A lot of the people who are testing positive for Omicron, of course, are not sick.</b>`,
        `And this is and incredibly important message to get through. What we have right now is a very high number of positive PCR tests, but the hospitalization rate actually is fairly flat, as is our ICU rate, and again it's what the expectations are.`,
        `So I think for the province [Ontario] one of the things we have to recording really accurately is whether or not peeople are being admitted <i>because</i> of covid, or just with a positive test, <strong>because of course everybody gets screened, so anybody that would screen positive is reported as an admission.</strong>"`
      ],
      urls: [
        `https://twitter.com/snjnky/status/1480033877666193412`
      ],
    },
    {
      description: [
        `Doctor speaks publicly, <b>"Everyone who died with covid should be considered murdered."</b>`,
        `"You have now loaded your body with millions of spike proteins, and you are a ticking time bomb for cancer, blood clots, and whatever kind of ailment may come up in your body.`,
        `I'm tired of hearing people go and ask doctors can they be treated for covid and their only option is the vaccine, or go home, or go into the hospital where you are not going to make it out.`,
        `Early treatment has always worked. Please stop choosing fear and putting masks on our kids.`,
        `<b>The vaccine is not going to work, early treatment has always worked, and government mismanagement of patients is why people have died."</b>`
      ],
      urls: [
        `https://twitter.com/Suspici07891401/status/1479633913945468930`
      ],
    },
    {
      description: [
        `Fauci: "If you look at the children that are hospitalized, many of them are hospitalized with Covid, as opposed to because of Covid."`,
        `<b>...which is what people were saying two years ago, and getting banned for it.</b>`
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1476710278096166917`
      ],
    },
    {
      description: [
        `<b>Inside the Omicron fear factory</b>`,
        `Public health chiefs and the media are working overtime to gin up hysteria`,
      ],
      urls: [
        `https://spectatorworld.com/topic/inside-omicron-variant-fear-factory-new-york-covid/`
      ],
    },
    {
      description: [
        `<b>CDC:</b>`,
        `Newly updated CDC guidelines don't require testing at the end of isolation because PCR tests can stay positive for up to 12 weeks, CDC Director Dr. Rochelle Walensky.`,
        `<b>Justin Hart</b>`,
        `I can't even express how angry this makes me. Think of all the lives ruined, jobs lost, education squandered because of false positives. We've been sayin since summer 2020 that the PCR test can be positive at 5 days or 75 days. And ONLY JUST NOW is it being used to adjust policy.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1476242486100381700/photo/1`
      ],
    },
    {
      description: [
        `The "Covid Count" Narrative is Being Changed in REAL TIME`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=74dBUOZyK70`
      ],
    },
    {
      description: [
        `EXPLOSIVE: <b>Fauci & Collins "Quick & Devastating Takedown" of GBD</b>`,
        `w/Phil Magness & Kate Wand`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=1qDXOTZ_oHA`
      ],
    },
    {
      description: [
        `- Over-80s make up 4% of the population yet account for 62% of covid deaths (vs. 51% of all deaths)`,
        `- 73% of covid deaths have 3+ pre-existing conditions (only 4% with no pre-existing condition)`,
        `...Meaning that covid hastens death at the end of one's full natural life.`,
      ],
      urls: [
        `https://twitter.com/Milhouse_Van_Ho/status/1471861336770498561`
      ],
    },
    {
      description: [
        `Democrats, including Biden, criticizing vaccine when Trump was president, even stating they would not get vaccinated.`,
      ],
      urls: [
        `https://twitter.com/owenusa/status/1472001486620008449`
      ],
    },
    {
      description: [
        `Dr. Dan Stock - specially trained in immunology, inflamation regulation.`,
        `<b>"Everything recommended by the CDC and the state board of health is actually contrary to all rules of science."</b>`,
        `Offers pro bono expert testimony to challenge covid measures.`
      ],
      urls: [
        `https://www.bitchute.com/video/JWAtXlFyDU0x/`
      ],
    },
    {
      description: [
        `Physician assistant in healthcare for 15 years, with first hand covid experience speaking out against covid misinformation (July 27, 2021)`,
      ],
      urls: [
        `https://www.bitchute.com/video/bqJd01kVL44B/`
      ],
    },
    {
      description: [
        `<b>Twice as fast</b>`,
        `Everywhere is warming twice as fast as everywhere else.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=YUV8U3yBJ6U`
      ],
    },
    {
      description: [
        `CNN medical analyst:`,
        `"We have a very narrow window to tie reopening policy to vaccination status. Because <b>otherwise if everything is reopened, then what's the carrot going to be?</b> How are we going to incentivize people to actually get the vaccine?`
      ],
      urls: [
        `https://www.youtube.com/watch?v=Lhl4dUzXdic`
      ],
    },
    {
      description: [
        `Pfizer CEO: "And we know that the two doses of the vaccine offer very limited protection, if any. The three dose booster, they offer reasonable protection against hospitalization and deaths."`,
        `<b>Is the narrative collapsing? by Computing Forever</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/Tap6JpyO6gSD/`
      ],
    },
    {
      description: [
        `Alberta's own data shows that there are more vaccinated people hospitalized than unvaccinated people. Only 37% unvaccinated. 57% with complete covid vaccination.`,
      ],
      urls: [
        `https://web.archive.org/web/20220113003257/https://www.alberta.ca/stats/covid-19-alberta-statistics.htm#vaccine-outcomes`
      ],
    },
    {
      description: [
        `Dr Robert Malone talks to Candace Owens about Department of Defense whistleblowers who <b>claim that adverse events are being deleted from the medical database.</b>`,
      ],
      urls: [
        `https://gettr.com/post/ps1gqva7ed`
      ],
    },
    {
      description: [
        `<b>Unvaccinated, maskless Canadian clears US land border</b>, then tells health-nazi to pound sand and drives away.`,
      ],
      urls: [
        `https://www.bitchute.com/video/3W9vKOPprF7P/`
      ],
    },
    {
      description: [
        `Scientists find virus contains tiny chunk of DNA that matches sequence patented by Moderna THREE YEARS before pandemic began`,
      ],
      urls: [
        `https://www.dailymail.co.uk/news/article-10542309/Fresh-lab-leak-fears-study-finds-genetic-code-Covids-spike-protein-linked-Moderna-patent.html`
      ],
    },
    {
      description: [
        `CDC Caught Hiding Troves of Data: Not Publishing Critical Hospitalization, Booster, and Virus Data`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=wcgppoM5X5s`
      ],
    },
    {
      description: [
        `Biden: "You can't build a wall high enough to keep out a vaccine."`,
        `<b>"The vaccine can stop the spread of these diseases."</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1498855304284577796`
      ],
    },
    {
      description: [
        `RN talks about the strange way Justin and Sophie Trudeau got "vaccinated".`,
        `1. Zero landmarking.`,
        `2. Zero aspirating of syringe.`,
        `3. One-handed it like a dart.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=L0dEefedRGQ`
      ],
    },
    {
      description: [
        `Jimmy Dore's doctor tells him the media hysteria around ivermectin was false. Among other damning things.`,
      ],
      urls: [
        `https://twitter.com/backtolife_2022/status/1499269166934622209`
      ],
    },
    {
      description: [
        `It has now gone almost 3 weeks since Sweden REMOVED all restrictions.`,
        `The seven day average covid infections has COLLAPSED down 88.8%.`,
        `<b>Why is no one talking about the success of Sweden?</b>`,
      ],
      urls: [
        `https://twitter.com/PeterSweden7/status/1499073917805342727`
      ],
    },
    {
      description: [
        `Dang it Jeff, hold still!`,
      ],
      urls: [
        `https://twitter.com/Chicken__Queen/status/1500117540969844736`
      ],
    },
    {
      description: [
        `Inside out and upside down.`,
        `TRUDEAU: "we see a bit of a slippage in our democracies. Countries turning towards slightly more authoritarian leaders. Countries allowing increasing misinformation and disinformation to be shared on social media"`,
        `<b>Look who's talking.</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1500237989489696772`
      ],
    },
    {
      description: [
        `<b>Really pissed off nurse just realized she's been lied to about the Pfizer injection.</b>`,
        `9 pages of adverse effects.`,
      ],
      urls: [
        `https://www.bitchute.com/video/B6oqK4p24r2x/`
      ],
    },
    {
      description: [
        `Dr. Robert Malone says the initial batch of Pfizer documents show the “government has been well aware that they [the vaccines] are not fully safe and has hidden this information from us”`,
      ],
      urls: [
        `https://twitter.com/TheRaiderRandy/status/1500538132663463937`
      ],
    },
    {
      description: [
        `Russell Brand: The Narrative Has Changed`,
        `"In some cases, to get a "digital id" or a "green pass", you needed to have the two shot, or the three shots, and, <b>remember</b>, how the mainstream media and the government vehemently condemned unvaccinated people for being irresponsible, and spreading the disease.`,
        `Well this information suggests that they may have been <b>better protected</b> and <b>less likely to spread it</b> than some of the people that were criticizing them.`,
        `So doesn't that show you how irresponsible the corporate media and the government seem, not just in the message they were conveying, but the <b>manner of the message</b>, the social damage that was done, particularly now, <b>as the science suggests that they may have been wrong.</b>"`,
        `Notably, a 10-fold lower risk of subsequent infection was found in the people with natural immunity compared with those vaccinated in the Cleveland Clinic health system's study of over 52,000 employees.`,
        `These reports convey a high level of protection of natural immunity, at times comparable to 2-shot vaccines.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=LBw1csl4tk4`
      ],
    },
    {
      description: [
        `<b>Pfizer will have no immunity if fraud is proven.</b>`,
        `This is going to take down the FDA, the CDC, the NIH, many politicians careers, global governments will be shaken to the core.`,
        `<b>Not only will people sue, governments are going to sue.</b>`,
      ],
      urls: [
        `https://twitter.com/LakovosJustice/status/1501295965893976064`
      ],
    },
    {
      description: [
        `CDC, the ones who said people like Dr. Robert Malone were spreading misinformation, were themselves the ones spreading misinformation, and may well have committed medical fraud.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=tEhjzTy70s0`
      ],
    },
    {
      description: [
        `Ontario reveals deaths caused by COVID much lower than previously reported`,
        `The percentage of reported COVID deaths in Ontario that are actually totally unrelated to COVID is 4.2%, a number that surged to 8% during the Omicron wave.`,
        `<b>It’s disappointing that all of this data is only now being confirmed. It could have gone a long way in helping us agree to a more targeted response to the virus rather than the blunt, one-size-fits-all approach used the past two years.</b>`,
      ],
      urls: [
        `https://torontosun.com/opinion/columnists/furey-ontario-reveals-deaths-caused-by-covid-much-lower-than-previously-reported?utm_medium=Social&utm_source=Twitter#Echobox=1646866230`
      ],
    },
    {
      description: [
        `Newspapers asking about flawed PCR tests, <b>two years in</b>.`,
        `"Did flawed PCR tests convince us Covid was worse than it really was?"`
      ],
      urls: [
        `https://twitter.com/steuben_baron/status/1503386169664491530`
      ],
    },
    {
      description: [
        `Why the chaotic way deaths were recorded in the pandemic could mean thousands were wrongly blamed on Covid.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1505803609883267073/photo/1`
      ],
    },
    {
      description: [
        `They don't want to talk about Covid deaths in Canada anymore because of this chart right here.`,
        `<b>Canada's Pandemic of the Fully Vaccinated | 7 in every 10 Covid-19 Deaths are among the Fully Vaccinated according to official data and Trudeau's Government are trying to cover it up</b>`,
      ],
      urls: [
        `https://twitter.com/WakeAwake1/status/1506305497560887302/photo/1`
      ],
    },
    {
      description: [
        `Russell Brand: The Truth Is Coming Out`,
        `Re: Pfizer's 55,000 page release on their trials which the FDA wanted to keep hidden for 75 years.`
      ],
      urls: [
        `https://www.youtube.com/watch?v=sqD_JYAs7pU`
      ],
    },
    {
      description: [
        `Peterborough MOH Dr. Piggott (@twpiggott) and his Health Unit are brazenly lying to their community by claiming that "3 doses are required to be...fully vaccinated"`,
        `In Ontario, "fully vaccinated" means two doses of any of Pfizer, Moderna, and AZ, or one dose of Janssen.`,
      ],
      urls: [
        `https://threadreaderapp.com/thread/1507543580105187331.html`,
        `https://twitter.com/rpoconnor/status/1507543580105187331`,
      ],
    },
    {
      description: [
        `<b>Poison control issues warning about Covid-19 rapid antigen tests.</b>`,
      ],
      urls: [
        `https://twitter.com/TheNo1Waffler/status/1509222617819058185`
      ],
    },
    {
      description: [
        `<b>Fauci Admits Natural Immunity, Says He's Stepping Down Soon, Braces For Investigations</b`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=KzCZ6N0tzo8`
      ],
    },
    {
      description: [
        `<b>COVID infection tweets by politicians, etc are clearly scripted</b>`,
        `Yesterday, Minister of Innovation Francois-Philippe Champagne tweeted that he'd tested positive for COVID-19 and was subsequently called out for clearly copying and pasting BC Premier John Horgan's COVID-positive tweet.`,
        `“This morning I tested positive for COVID-19. Fortunately, my symptoms are mild and that is thanks to being fully vaccinated. I'm following public health guidelines, isolating and working from home,” he wrote.`,
        `Two days earlier, Horgan tweeted, “This morning I tested positive for COVID-19. Fortunately, my symptoms are mild and that is thanks to being fully vaccinated. I'm following public health guidance, isolating and working from home until my symptoms resolve.”,`
      ],
      urls: [
        `https://thecountersignal.com/covid-infections-tweets-are-clearly-scripted/`
      ],
    },
    {
      description: [
        `What is going on in Alberta and British Columbia?`,
        `<b>From Stats Can data, excess deaths in ages 0-44 as of Dec. '21 are MORE THAN 70% of expected deaths, since accelerating in Jul '21.</b>`,
        `This is the real "tsunami of death", and a public health emergency that must be investigated ASAP.`,
      ],
      urls: [
        `https://twitter.com/rubiconcapital_/status/1513654807168524291`,
        `https://threadreaderapp.com/thread/1513654807168524291.html`,
        `https://www150.statcan.gc.ca/n1/daily-quotidien/220210/dq220210b-eng.htm`,
        `https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1310078401`,
        `https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1310076801`,
      ],
    },
    {
      description: [
        `MSNBC on Elon and Twitter:`,
        `“[Elon] could actually <b>control what people think… that is our job</b>”`,
      ],
      urls: [
        `https://twitter.com/HeidiBriones/status/1515070325700005890`
      ],
    },
    {
      description: [
        `Dr. David Martin News Update`,
        `AP News reporter claims his claims are unfounded, but she didn't reference the evidence he had provided.`,
        `<b>Canada gets a kickback from every covid vaccine.</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=IH0Cd7ETges`
      ],
    },
    {
      description: [
        `Barack Obama casually, charismatically admitting to crimes against humanity and Nuremberg Code violations.`,
        `"..we've now, essentially, clinically tested *chuckles* the vaccine on billions of people worldwide.."`
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1517621377536339970`,
        `https://www.youtube.com/watch?v=MpcrM_y0_wI`,
      ],
    },
    {
      description: [
        `Sec. of Homeland Security Alejandro Mayorkas says a "Disinformation Governance Board" is being created to counter misinformation.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1519385344726257665`
      ],
    },
    {
      description: [
        `Who is actually spreading the MOST Misinformation/Disinformation in Canada?`,
        `<b>"I've never seem this amount of support in my life in this country for anything! If anything, this is actually the most unified and diverse group of Canadians I've ever seen, on message on the same point.</b>`,
        `<b>But $10 million, that most of these trickers have gotten, if all of this was a political party and a political movement it would be the most well funded one in the country. So what does that tell you?</b>`
      ],
      urls: [
        `https://twitter.com/CanAditude/status/1519464871359877120`
      ],
    },
    {
      description: [
        `Naomi Wolf's Explosive Reveal On The Pfizer Investigation`,
        `"When everyone was saying 'safe and effective' ..this screenshot [from VAERS] shows 3,385 deaths reported in 3 months..`,
        `Not only did Pfizer know it, but the FDA knew it, they knew they were harming people in the first months of 2021."`,
      ],
      urls: [
        `https://rumble.com/v13bg59-naomi-wolfs-explosive-reveal-on-the-pfizer-investigation.html`
      ],
    },
    {
      description: [
        `<b>Bill Gates belongs in prison</b>`,
        `Bill Gates: "It's a fairly low fatality rate, and it's a disease mainly of the elderly."`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=mqjyB_BJzhU`
      ],
    },
    {
      description: [
        `Canadian doctor pressured to change patient's death certificate to covid. Patient did not die of covid! She refused.`,
      ],
      urls: [
        `https://twitter.com/RiseOfTheProles/status/1532189242704678919`,
        `https://www.youtube.com/watch?v=-DeHytYQHYI`,
      ],
    },
    {
      description: [
        `Headline in newspaper: Child lost to Covid-19 - which is a blatant lie, as their own article spells out.`,
        `"A Victorian child aged under 10 has become Australia's youngest person to die with Covid-19. The Department of Health said the child, who was one of five people who died with coronavirus in the state on Saturday, <b>also had other "serious comorbidities"</b>.`,
        `<b>However, it was revealed that coronavirus was not the reason for either his hospital admission or death."</b>`,
      ],
      urls: [
        `https://media.gettr.com/group30/getter/2022/06/06/16/93e6a0c3-85a8-b67b-72c4-907237948d86/a43343db0fb6e3b9e3b24c51d60313e0.jpg`
      ],
    },
    {
      description: [
        `"If at any time in your life you've ever made fun of totalitarian regimes that broadcast lies into the homes of the population that they can't turn off, take a look at that. That's happening right now."`,
        `8 different mainstream television channels broadcasting the exact same thing.`
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1535061615195828224`
      ],
    },
    {
      description: [
        `First child myocarditis ad I've ever seen`,
      ],
      urls: [
        `https://twitter.com/anish_koka/status/1571186794644873217`
      ],
    },
    {
      description: [
        `Proof that creating propaganda in Canada pays.`,
        `From Natural Sciences and Engineering Research Council of Canada:`,
        `<b>Encouraging vaccine confidence in Canada - Competition results:</b>`,
        `- SickKids - Building COVID-19 Vaccine Confidence: Educating the Educators - $50,000`,
        `- McMaster University - Encouraging vaccine confidence among pregnant and breastfeeding Canadians - $50,000`,
        `- Mount Royal University - Encouraging Vaccine Confidence in School-aged Children Across Western Canada - $49,700`,
        `- University of Ottawa - Vaccine Confidence Workshops and events for Youth, Teachers and Families - $11,000`,
        `- University of Windsor - Students Igniting Vaccine Confidence Program in Windsor-Essex - $50,000`,
      ],
      urls: [
        `https://twitter.com/LisaPow33260238/status/1571650979447144450`
      ],
    },
    {
      description: [
        `- Here's the manipulative ad pushing vaccination on children that the City of Toronto published then deleted today.`,
        `- This video isn't a one-off. It's part of a multi-part campaign. In this video, the City of Toronto suggests you should keep family members from your infant unless the infant is vaccinated.`,
        `- In this edition of the City of Toronto's child vaccination campaign, you have to vaccinate your child so they can leave your house for school while you work from home.`,
        `- In this video, the City of Toronto tells you that unvaccinated children - not government lockdowns - are responsible for having to learn outside the classroom.`,
        `- In this ad, the City of Toronto concedes human connection is better than virtual connections - but of course this is only to tell you that you can get your kids vaccinated from six months of age onward.`,
        `The City of Toronto says the campaign "was intended to highlight the negative impact (the pandemic) has had on children and drive home the benefits of vaccine."`,
        `UPDATE: The City of Toronto has now pulled the whole series of videos off of its YouTube page.`,
        `It wasn't a lack of childhood vaccines that prevented children from going to school, experiencing human connection, and spending time with relatives. Government restrictions did this.`,
        `<b>Toronto's child jab ad campaign is despicable and dishonest</b>`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1572351263748005891`,
        `https://twitter.com/AndrewLawton/status/1572360439484358656`,
        `https://twitter.com/AndrewLawton/status/1572363695803748354`,
        `https://twitter.com/AndrewLawton/status/1572364236554117120`,
        `https://twitter.com/AndrewLawton/status/1572364581946953729`,
        `https://andrewlawton.substack.com/p/torontos-child-jab-ad-campaign-is`,
      ],
    },
    {
      description: [
        `<b>Vaccine incentives from mainstream companies and governments.</b>`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1574101661366247427`
      ],
    },
    {
      description: [
        `<b>Dr Aseem Malhotra: Hypocrisy flip flop.</b>`,
        `Calls not wanting to take the covid vaccine "irrational" and stems from false information: 2021-02-05`,
        `to:`,
        `There is a strong scientific, ethical and moral case to be made that covid 19 vaccines rollout must stop immediately: 2022-09-26 `
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1574834768369389576`
      ],
    },
    {
      description: [
        `WEF member explains how they partnered with Google to ensure UN resources were displayed first when people search for climate change: "We own the science."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1576591118858620929`
      ],
    },
    {
      description: [
        `Dozens of news channels in unison saying, <b>"This is very dangerous to our democracy"</b>.`,
        `Lockstep.`
      ],
      urls: [
        `https://twitter.com/akheriaty/status/1596331604070174727`
      ],
    },
    {
      description: [
        `COVID-19 Antigen test kit now updated to include Influenza A and B as well.`,
        `If the antigen test tests positive when encountering Influenza, how was Covid19 ever different?`
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1596566018700333062`
      ],
    },
    {
      description: [
        `Dr. Harvey Risch is Professor Emeritus of Epidemiology at the Yale School of Public Health. His work has been cited nearly 50k times. He's also an MD.`,
        `I asked him what the biggest lie of the last 29 months of COVID has been.`,
        'Criticism of FDA recommendations of outpatient treatment.',
        `"..it proves that they [FDA] do not have systematic evidence of outpatient ill-effects in using hydroxycholoquine in outpatients"`,
        `"Why is this the biggest lie, is because this was the crux of the whole pandemic in the first place."`,
      ],
      urls: [
        `https://twitter.com/SteveDeaceShow/status/1570462125398831105`,
        `https://rumble.com/v1k7fzt-this-is-the-biggest-lie-of-covidstan-steve-deace-show.html`,
      ],
    },
    {
      description: [
        `CIA Officer Frank Snepp Discusses Planting Stories in Vietnam`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=UwerBZG83YM`,
        `https://twitter.com/Snowden/status/1589606899569377282`
      ],
    },
    {
      description: [
        `<b>Finnish covid deaths OVER REPORTED by 62%</b>`,
      ],
      urls: [
        `https://petersweden.substack.com/p/finland-overreported`
      ],
    },
    {
      description: [
        `Dr Nagase came upon a copy of Canada's 1954 criminal code and discovered a law on 'spreading misinformation / fake news' as an actual crime - that same law was removed, by Canada's sitting Parliament in 2019, right before the plandemic emerged…`,
      ],
      urls: [
        `https://twitter.com/M0G39/status/1580764744369438720`
      ],
    },
    {
      description: [
        `<b>Typical misinformation regarding Danish COVID-numbers</b>`,
        `Much misinformation and misunderstandings exist regarding Danish COVID-19 numbers. On this page, we answer some of the most typical ones.`,
        `The COVID-19 pandemic has since its beginning been unpredictable in many ways. The SARS-CoV-2 virus mutates, and health authorities worldwide have continuously had to change their strategies e.g. regarding surveillance and treatment of the disease.`,
        `This also means that our data and our knowledge about the disease changes. Unfortunately, this leads to misunderstandings and misinterpretations regarding Danish COVID-19 numbers, which are shared through the media in general and social media in particular.`,
        `Below, we have collected and answered some of the more typical pieces of misinformation and misunderstandings. However, since the pandemic, and our data and knowledge change, so will this page. The information on this page is in other words snapshots of the Danish numbers and of COVID-19 in Denmark.`,
      ],
      urls: [
        `https://en.ssi.dk/covid-19/typical-misinformation-regarding-danish-covid-numbers`
      ],
    },
    {
      description: [
        `<b>Pfizer Recruits Star Power in Latest Vaccine Ad Push feat. Pink, Michael Phelps, Questlove, & Jean Smart</b>`,
        `The spot, “A Whole Different Ball Game,” has the four tossing around a red SARS-COV-2 shaped ball from frame to frame`,
      ],
      urls: [
        `https://twitter.com/TheChiefNerd/status/1622378957520633863`
      ],
    },
    {
      description: [
        `<b>Rep. Nancy Mace grills former Twitter executives, because of the Twitter Files.</b>`,
        `The Twitter files were not just about Hunter Biden's laptop, Twitter worked overtime to suppress accurate covid information.`,
        `Apparently the views of a Stanford Doctor are "disinformation" to you people.`,
        `I, along with many Americans, have long-term effects from covid, not only was I a "long-hauler", but I have effects from the vaccine. I wasn't the first shot, but it was the second shot that I now developed asthma, that has never gone away since I had the second shot. I have tremors in my left hand, and I have the occasional heart pain, that no doctor can explain. And I've had a battery of tests.`,
        `I have great regrets about getting the shot because of the health issues I now have that I don't think are ever going to go away.`,
        `Where did you go to medical school?`,
        `Ms. Gadde: I did not go to medical school.`,
        `I'm sorry?`,
        `Ms. Gadde: I did not go to medical school.`,
        `That's what I thought. Why do you think you or anyone else at Twitter has the medical expertise to censor a doctor's expert opinion?`,
        `Ms. Gadde: Our policies regarding covid were designed to protect individuals, --`,
        `You guys censored Hardard-educated doctors, Stanford-educated doctors, doctors that are educated in the best places in the world, and you silenced those voices!`,
        `You're not a doctor, right Ms. Gadde?`,
        `Ms. Gadde: No I'm not.`,
        `Ok, what makes you think you or anyone else at Twitter have the medical expertise to censor <b>actual, accurate, CDC data?</b>`,
        `Ms. Gadde: I'm not familiar with these particular situhations..`,
        `Yeah I'm sure you're not. It's not just about the laptop, this is about medical advice that expert doctors were trying to give Americans because social media companies like Twitter were silencing their voices.`,
        `Did the US government ever contact you, or anyone at Twitter, to pressure Twitter to moderate or censor certain tweets? Yes or no.`,
        `Ms. Gadde: We have a program--`,
        `Did the US government ever contact you, or anyone at Twitter to censor or moderate certain tweets, yes or no?`,
        `Ms. Gadde: We received legal demands to remove content from the platform from the US government, and government all around the world.`,
        `Thank god for Matt Taibbi, thank god for Elon Musk, for allowing us to show the world that <b>Twitter was basically a subsidiary of the FBI.</b>`
      ],
      urls: [
        `https://twitter.com/MattClarkReport/status/1624134526078226460`,
        `https://twitter.com/atensnut/status/1624449834840215553`,
        `https://twitter.com/RobertKennedyJr/status/1623397176339562510`,
      ],
    },
    {
      description: [
        `Australian paid covid actors not actual patients in hospitals.`,
      ],
      urls: [
        `https://twitter.com/DrLoupis/status/1623630720647282688`
      ],
    },
    {
      description: [
        `<b>Pfizer's COVID ad starring Michael Phelps, Pink, Jean Smart & Questlove</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1622612675493601282`
      ],
    },
    {
      description: [
        `Theresa Tam and Mrs. Claus advertise vaccines for children and want you to get vaccinated and wear a mask at your indoor gatherings.`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1605754986549215235`
      ],
    },
    {
      description: [
        `More Pfizer advertising. "Pfizer Girl"`,
      ],
      urls: [
        `https://twitter.com/JackPosobiec/status/1387787580968775685`
      ],
    },
    {
      description: [
        `Dr. Peter Hotez shilling for the vaccine with known lies.`,
      ],
      urls: [
        `https://twitter.com/FatEmperor/status/1621646296514797570`
      ],
    },
    {
      description: [
        `Martha Stewart stars in Pfizer's new COVID-19 booster ad`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1620819505856528384`
      ],
    },
    {
      description: [
        `<b>Dr. Scott Jensen:</b>`,
        `Expressed concern about how death certificates were being issued during covid.`,
        `They are NOW using Keith Ellison and the Attorney General's Office to take away my Medical License - If this can happen to me it can happen to you!`,
        `---`,
        `It's more sinister than that, @drscottjensen. If they *can* do it to you, they *will* do it to others. Others who don't have the same voice to scream out in their own defense. This is the full weaponizing and discrediting of the field of medicine`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1620421050348765191`,
        `https://twitter.com/drscottjensen/status/1620203440713469952`,
      ],
    },
    {
      description: [
        `<b>“Stroke season”.</b>`,
        `I am genuinely curious how a doctor only became aware of this so-called “stroke season” last year… The timing seems awful suspect. `,
        `---`,
        `“I never knew there was a stroke season after flu season, until my bosses & big Pharma told me there is a stroke season after flu season”.`,
        `So am I now allowed asking if people collapsing on air is a stroke? It's the season, after all, right?`
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1616853226258563072`,
        `https://twitter.com/thevivafrei/status/1616825564802826241`,
      ],
    },
    {
      description: [
        `CDC admits mRNA shots can cause myocarditis and blot clots. Also admits natural immunity is real. And concedes that the shots don't prevent infection or spread of the virus.`,
        `Therefore COVID “vaccine” mandates are immoral and unethical even according to govt. End all of them now.`,
      ],
      urls: [
        `https://twitter.com/RepThomasMassie/status/1616249150248452099`
      ],
    },
    {
      description: [
        `<b>CNN medical analyst Leana Wen: “Pregnant women, pregnant individuals, should be getting vaccinated.”</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1615379233940856833`
      ],
    },
    {
      description: [
        `Never forget when CDC's Walensky said "Vaccinated people do not carry the virus, don't get sick"`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1608928390144204800`
      ],
    },
    {
      description: [
        `<b>THE TWITTER FILES: HOW TWITTER RIGGED THE COVID DEBATE</b>`,
        `- By censoring info that was true but inconvenient to U.S. govt. policy`,
        `- By discrediting doctors and other experts who disagreed`,
        `- By suppressing ordinary users, including some sharing the CDC's *own data*`,
      ],
      urls: [
        `https://twitter.com/davidzweig/status/1607378386338340867`,
        `https://threadreaderapp.com/thread/1607378386338340867.html`,
      ],
    },
    {
      description: [
        `Libby Emmons at @minds Festival of Ideas talks about "misinformation"`,
        `"Anytime you disagree they wanna just call it misinformation."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1541422045275226112`
      ],
    },
    {
      description: [
        `<b>When Dr. Fauci actually told the truth</b>`,
        `---`,
        `January 28, 2020:`,
        `But the one thing historically people need to realize, that even if there is some asymptomatic transmission, in all the history of respiratory-born viruses of any type, asymptomatic transmission has never been the driver of outbreaks.`,
        `The driver of outbreaks is always a symptomatic person, even if there is a rare asymptomatic person who may transmit, an epidemic is not driven by asymptomatic carriers.`,
        `---`,
        `60 Minutes Interview: March 8, 2020:`,
        `Interviewer: There's a lot of confusion among people and misinformation surrounding face masks, can you discuss that?`,
        `Fauci: The masks are important for someone who is infected to prevent them from infecting someone else.`,
        `Now, when you see people, and look at the films in China or Korea, where everyone is wearing a mask, <b>right now in the United States, people should not be walking around with masks.</b>`,
        `I: You're sure? Because people are listening really closely to this.`,
        `F: No. Right now people should not be walking, there's no reason to be walking around with a mask.`,
        `When you're in the middle of an outbreak, wearing a mask might make people "feel" a bit better, and it might even block a droplet, but it's not providing the perfect protection that people think that it is.`,
        `And often, there are unintended consequences, people keep fiddling with the mask, and they keep touching their face.`,
        `I: And can you get some schmutz sort of staying inside there?`,
        `F: of-of-of-of of course. Of course. But when you think masks you should think of healthcare providers needing them, and people who are ill.`,
        `The people who, when you look at the films of foreign countries and you see 85% of the people wearing masks, that's fine. That's fine, I'm not against it, if you want to do it that's fine.`,
        `I: But it can lead to a shortage of masks.`,
        `F: Exactly, that's the point.`,
        `---`,
        `Bloomberg interview:`,
        `Interviewer: The best way for me to prevent getting an infectious disease and having to have you as my doctor is what? Wearing a mask?`,
        `Fauci: No, no, no.`,
        `I: If I see someone's ready to sneeze or cough do I walk away?`,
        `F: <b>No, you avoid all the paranoid aspects and do something positive.</b>`,
        `a. Diet, b. you don't smoke I know, I know you don't drink, at least not very much so that's pretty good. Get some exercise, I know you don't get as much exercise as you should. Get good sleep.`,
        `<b>I think that the normal, low-tech, healthy things are the best thing that you can do to stay healthy.</b>`,
        `---`,
        `C-SPAN interview 2004:`,
        `Interviewer: She's had the flu for 14 days, should she get a flu shot?`,
        `Fauci: <b>Well, no. If she's had the flu for 14 days she's as protected as anybody can be, because the best vaccination is to get infected yourself.</b>`,
        `If she really has the flu, if she really has the flu, she definitely doesn't need a flu vaccine. If she really has the flu.`,
        `I: You should not get it again-`,
        `F: No she doesn't need it! Because it's the most potent vaccination is getting infected yourself.`,
        `---`,
        `Interview with Mark Zuckerberg, early 2020:`,
        `This would not be the first time, if it happened, that a vaccine that looked good in initial safety, actually made people worse.`,
        `There was the history of the respiratory and -- virus vaccine in children, which paradoxically made the children worse.`,
        `One of the HIV vaccines that we tested several years ago, actually made individuals more likely to get infected.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1601978644384972800`
      ],
    },
    {
      description: [
        `"The idea that there's a central authority that can distinguish true from false, that can ex cathedra on high...And if you disagree, then you're a heretic. That is a dangerous, dangerous idea. Essentially, it's the return to a dark age."`,
        `Dr J Bhattacharya`,
      ],
      urls: [
        `https://twitter.com/katewand/status/1577965639750279169`,
        `https://www.youtube.com/watch?v=RChf-siETRY`,
      ],
    },
    {
      description: [
        `The Post Millenial's Libby Emmons:`,
        `Satire is intentional, you know one of the earliest satires of course was Thomas Moore's Utopia which described the perfect society, and then went on to show how imperfect and distopic it was.`,
        `And I think that's the whole thing, satire is intended to reveal truths or to inspire conversation, and to dig deeper into something.`,
        `<b>"Misinformation" is a catchphrase that's used by people in power to discredit people who disagree with them`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1541423608593088513`
      ],
    },
    {
      description: [
        `TPM's @libbyEmmons at @Minds Festival of Ideas:`,
        `"We look at the things that we are being told, and they seem absolutely absurd because they are and we are being asked to just believe them..."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1541413722085785601`
      ],
    },
    {
      description: [
        `<b>May 2020 ~ this is what psychopathic monster Matt Hancock said about Remdesivir.</b>`,
        `As I said at this podium last week, the very nature of scientific inquiry means that not every project will bear fruit. But I'm determined that we explore every possible avenue.`,
        `The Recovery Trial is the world's largest trial of potential coronavirus treatments. And because our NHS is a universal system, which we're all part of, we have some of the best data and can do some of the best research.`,
        `<b>Today I can announce that we are doing a new trial for selected NHS patients of an antiviral drug called Remdesivir.</b>`,
        `There have already been some promising results on coronavirus patients with early data suggesting it could shorten recovery time by around 4 days. As you can understand, we'll be prioritizing the use of this treatment where it will provide the greatest benefit.`,
        `This is probably the biggest step forward in the treatment of coronavirus since the crisis began. These are very early steps but we're determined to support the science and back the projects that show promise.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1601013786244108289`
      ],
    },
    {
      description: [
        `Bill Gates: A key goal of the vaccine is to stop transmission.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1600825113443647488`
      ],
    },
    {
      description: [
        `People were suspended from social media for saying what Bill Gates is saying here.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1600419071715311616`
      ],
    },
    {
      description: [
        `Kari Mullis, inventor of PCR, knew about the evil Dr. Fauci but unfortunately he died in September 2019`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1600528615778689026`
      ],
    },
    {
      description: [
        `<b>Tampa Bay Times: More deaths among vaccinated Americans not a reason to avoid vaccines, experts say.</b>`,
      ],
      urls: [
        `https://twitter.com/angelanashtn/status/1598676019585613829/photo/1`
      ],
    },
    {
      description: [
        `Government ad: An updated COVID vaccine can help protect you from the worst outcomes of COVID. If it's been over 2 months since your last dose, make a plan to get one now.`,
        `"They're doubling down. A booster every 2 months?`,
      ],
      urls: [
        `https://twitter.com/LfJeppesen/status/1599460360159129600`,
        `https://twitter.com/SecBecerra/status/1597591269903138817`,
      ],
    },
    {
      description: [
        `World MISLED by falsified data`,
        `It turns out Israel had NO effective monitoring system for adverse reactions to the Covid vaccine in 2021 when the world RELIED on their data to guide vaccine policy, including mandates.`,
      ],
      urls: [
        `https://twitter.com/OzraeliAvi/status/1598424195813150721`,
        `https://www.rebelnews.com/the_world_misled_by_falsified_israeli_data_says_journalist`,
      ],
    },
    {
      description: [
        `World Health Organisation Chief Scientist Dr. Soumya Swaminathan STILL lying saying mRNA vaccines are “extremely safe” for adults AND children.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1598747946811416576`
      ],
    },
    {
      description: [
        `CNN Technical Director Bragging About Election Interference`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=zghsXYUh7wM`
      ],
    },
    {
      description: [
        `CDC Lied about Vaccine Myocarditis Being Mild: 20% of Sudden Deaths Caused by Myocarditis`,
      ],
      urls: [
        `https://igorchudov.substack.com/p/cdc-lied-about-vaccine-myocarditis`
      ],
    },
    {
      description: [
        `“But we never said vaccines prevent transmission.”`,
        `REALLY?`,
        `Justin Trudeau: “Unvaccinated people shouldn't get on a plane or a train beside vaccinated people and put them at risk.”`,
      ],
      urls: [
        `https://twitter.com/DrEliDavid/status/1626328504915689474`
      ],
    },
    {
      description: [
        `Celebrity John Legend endorses Pfizer vaccines:`,
        `From Pfizer:`,
        `For John Legend, there is nothing better than being a dad and enjoying special moments with his family. That's why he considers his health a top priority and why he got an updated #COVID19 booster. His eligible family members got theirs too`,
      ],
      urls: [
        `https://twitter.com/pfizer/status/1625522404213551104`
      ],
    },
    {
      description: [
        `Dr. Simon Goddek:`,
        `Covid =  Flu.`,
        `Prove me wrong.`,
      ],
      urls: [
        `https://twitter.com/goddeketal/status/1625604543953227783`
      ],
    },
    {
      description: [
        `It's funny - but really not - how Dr. Sanjay Gupta thinks he can giggle, and hem & haw his way out of this. It's something a child does when confronted with their dishonesty. He is an untrustworthy propagandist - fitting that he works for a lying propagandist news network like CNN`,
        `<b>Joe Rogan asks Sanjay Gupta if it bothers him that CNN outright lied about Rogan taking horse dewormer to recover from covid.</b>`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1448620697270562820`,
        `https://twitter.com/ClayTravis/status/1448478540685463552`,
      ],
    },
    {
      description: [
        `CNN's Don Lemon and Sanjay Gupta try to do damage control after Joe Rogan humiliated them over Ivermectin lie`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1448722977873334278`
      ],
    },
    {
      description: [
        `<b>Dr. Deena Hinshaw apologizes and says Alberta teen didn't actually die from COVID</b>`,
        `Embarrassing walk-back from a public health official who used a tragic teenage death to advance a preferred policy narrative.`,
        `A timely, and sad, reminder that dying "with" the COVID virus does not mean dying "because of" COVID.`,
        `https://nationalpost.com/news/dr-deena-hinshaw-says-alberta-teen-didnt-actually-die-from-covid`,
      ],
      urls: [
        `https://twitter.com/rpoconnor/status/1448832181703430145`
      ],
    },
    {
      description: [
        `<b>National Post: Wuhan scientists sought to infect bats with coronavirus 18 months before first case, documents show</b>`,
        `"They also planned to create chimeric viruses genetically enhanced to infect humans more easily."`,
        `Nothing to see here. This is just regular research that scientists do everywhere. Don't spread conspiracy theories please.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1440739951478337549`
      ],
    },
    {
      description: [
        `<b>Alberta doctor says hospital capacity numbers manipulated by AHS</b>`,
        `<b>Western Standard</b>`,
      ],
      urls: [
        `https://www.westernstandard.news/news/watch-alberta-doctor-says-hospital-capacity-numbers-manipulated-by-ahs/article_ff292818-659e-5fa0-b5a0-a0aa0a20e740.html`
      ],
    },
    {
      description: [
        `<b>National Post: Military leaders saw pandemic as unique opportunity to test propaganda on Canadians: report</b>`,
        `A plan devised by the Canadian Joint Operations Command relied on propaganda techniques similar to those employed during the Afghan war`,
        `---`,
        `It walked like a psy-op, and talked like a psy-op. Now, we know for sure: it *was* a psy-op.`,
      ],
      urls: [
        `https://nationalpost.com/news/national/defence-watch/military-leaders-saw-pandemic-as-unique-opportunity-to-test-propaganda-techniques-on-canadians-forces-report-says/wcm/22733c97-39f0-4ba4-8a26-478af5e215f3`,
        `https://twitter.com/NatCitizens/status/1442514117680525325`,
      ],
    },
    {
      description: [
        `LeBron James says he got vaccinated after doing his "research."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1442929506491142148`,
      ],
    },
    {
      description: [
        `Comedy is dead.`,
        `Tonight Show | Stephen Colbert | "The Vax Scene"`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1443017326014738433`
      ],
    },
    {
      description: [
        `<b>Joe Biden getting a vaccine in front of fake windows on a stage:</b>`,
        `Do you want to know what creates “vaccine hesitancy”, @POTUS? THIS creates “vaccine hesitancy”. But you probably already knew that.`,
        `---`,
        `So it was on a stage. On a theatrical set, in front of fake windows. Was it really a nurse? And was it really an injection — or a theatrical prop?`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1443027058209210369`,
        `https://twitter.com/ezralevant/status/1443016518275637249`,
      ],
    },
  ],
  Documentaries: [
    {
      description: [
        `<b>Safe and Effective: A Second Opinion</b> (2022)`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=dIVZ5ssWB-o`
      ],
    },
    {
      description: [
        `<b>Uninformed Consent</b> (2022)`,
      ],
      urls: [
        `https://librti.com/view-video/uninformed-consent`
      ],
    },
    {
      description: [
      `<strong>Covid Land: The Lockdown</strong> (episode 1)`,
      ],
      urls:[
        `https://www.bitchute.com/video/mRHJhe1Wjbcr/`,
      ],
    },
    {
      description: [
      `<strong>Covid Land: The Mask</strong> (episode 2)`,
      ],
      urls:[
        `https://www.bitchute.com/video/Vs2McGGrsW9K/`,
      ],
    },
    {
      description: [
        `<b>The PCR Test Deception</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/gbD3J4kh5QKt/`
      ],
    },
    {
      description: [
        `<strong>The Time is Now</strong> (2021)`,
        `Doctors and other professionals speak about the unprecedented disinformation and medical malpractice.`,
        `"This will go down as one of the biggest, mass psychosis, diabolical, nihilistic, heartless times in human history.`
      ],
      urls: [
        `https://www.bitchute.com/video/EJMjGA9TcF2n/`
      ],
    },
    {
      description: [
        `<b>The Warning We Ignored</b>`,
        `Looking back at Yuri Bezmenov, Soviet KGB defector, speaking about subversion and taking countries from within, pitting its own sides against themselves.`
      ],
      urls: [
        `https://www.bitchute.com/video/M1B5sJmjuDsn/`
      ],
    },
    {
      description: [
        `<b>2000 Mules</b> (2022)`,
      ],
      urls: [
        `https://www.bitchute.com/video/2lwKCvbEZ5bo/`,
        `https://dinesh.locals.com/post/2083099/2000-mules`,
      ],
    },
    {
      description: [
        `<b>mRNA 'Vaccine' Genocide 2021-2022: Testimonies from the Victims and Medical Staff</b>`,
        `Tells the stories of people around the world who have been injured by the COVID jab.`,
      ],
      urls: [
        `https://www.bitchute.com/video/iZAnOd3LPC5w/`
      ],
    },
    {
      description: [
        `<b>LOCKDOWN: The Line Dividing Good and Evil</b>`,
        `Kate Wand`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=CWQ8iDizXlk`
      ],
    },
    {
      description: [
        `<b>Died Suddenly</b> (2022)`,
        `Numerous coroners and medical examiners show and describe the strange clots they are pulling out of deceased vaccinated people, which they've never seen before covid vaccines, and attribute to the person's death.`
      ],
      urls: [
        `https://rumble.com/v1wac7i-world-premier-died-suddenly.html`
      ],
    },
    {
      description: [
        `<b>Bill Gates Crimes</b>`,
        `U.S. patents show CDC ownership of Coronavirus. Both China and the U.S. involved in the creation of Wuhan SARS-CoV-2. Gates and CCP controlled WHO appoints criminal Tedros. CDC, FDA, CIA, NIH, Gates, Fauci, Baric, Rockefeller are all involved in Federal Crimes.`,
      ],
      urls: [
        `https://twitter.com/SpartaJustice/status/1631363125269209088`
      ],
    },
    {
      description: [
        `<b>Kate Wand:</b>`,
        `<b>AVIATION HIJACKED: The Great Reset</b>`,
        `---`,
        `<b>Aviation Hijacked - Part 1: Flight Plan</b>`,
        `In this in-depth exploration titled Aviation Hijacked, I hypothesize that the Canadian Aviation Industry has been effectively hijacked in an effort to align with globalist reshaping & social engineering goals`,
        `Part one, Flight Plan, introduces these goals, and the rest of the series will expose and explore the hijacking. `,
        `---`,
        `<b>Aviation Hijacked - Part 2: Flight Conditions</b>`,
        `“Trudeau has failed to give any money in bailouts with or without strings attached to the Canadian aviation industry.” `,
        `Part two, Flight Conditions, introduces the concept of green tyranny, and goes on to discuss the green strings and other bailout conditions attached to the aviation sector across Europe. It contrasts the bailout conditions in the USA, and continues to beg the question, what about Canada?`,
        `---`,
        `<b>Aviation Hijacked - Part 3: Pushback</b>`,
        `Part three, Pushback, explores how Bill Morneau was intensely pressured about a green recovery, his pushback and subsequent fall from grace. `,
        `His replacement minister of finance Chrystia Freeland and her bulldozing green ambitions for a brave new world, and Trudeau's delirious plans for decarbonization.`,
        `The pushback from aviation workers and their families, and the continued silent negligence of the Canadian government. `,
        `---`,
        `<b>Aviation Hijacked - Part 4: Mayday</b>`,
        `Part four, Mayday, concludes this series, coming full circle in exposing the link between the hijacking of Canadian Aviation and the centrally-planned globalist Great Reset. From climate fear-mongering, flygskam, de-carbonization and green tyranny, to the slippery slope towards state ownership for Canadian airlines, and collectivism for its citizens.`,
        `What will be the outcome for Canadian Aviation? Since this issue goes beyond one industry, what will be the outcome for the Canadian people, and beyond? Will we be able to take back the controls, harnessing our power as individuals? Or will we let the hijackers have their way?`,
        `---`,
        `<b>HIJACKED - The Great Reset</b>`,
        `ADDENDUM to AVIATION HIJACKED: The Great Reset`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=p4i3c6bCGYs`,
        `https://www.youtube.com/watch?v=URmBiUVjJLQ`,
        `https://www.youtube.com/watch?v=rKLeD58unjU`,
        `https://www.youtube.com/watch?v=37arrCb_RUM`,
        `https://www.youtube.com/watch?v=5xaSbeXm87M`,
      ],
    },
    {
      description: [
        `<b>Kate Wand:</b>`,
        `<b>Hygiene Socialism | For The Greater Good</b>`,
        `"Every tyrant convinces large numbers of the people under his rule that he uses force exclusively for the greater good." - Donald Boudreaux`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=LNtWvo-OgC4`
      ],
    },
    {
      description: [
        `Kate Wand:`,
        `<b>Collectivism Starves the People</b>`,
        `Central planners ALWAYS, without exception, starve and murder people when they attempt to collectivise and "transform" food systems.`,
        `Watch this short film on the Holodomor for a historical perspective. `,
      ],
      urls: [
        `https://twitter.com/katewand/status/1441130937949982724`,
        `https://www.youtube.com/watch?v=q62oMxUki5s`,
        `https://twitter.com/FoodSystems/status/1441068358275899399`,
      ],
    },
  ],
  Economy: [
    {
      description: [
        `Billionaires Are Predicting A Market Crash And SELLING EVERYTHING.`,
        `We must see markers in the world and economy and interpret the significance of them in the context of our current situations.`,
        `Why are so many rich people liquidating their shares? It would probably be useful to have a broader idea of fundamental shifts in the economy we all have to interact with, using abnormal behaviour like this as an indicator.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=ZzMYDrqrCjg`,
      ],
    },    
    {
      description: [
        `Burry: Everyone's Lying! The Market Has Just COLLAPSED...`,
        `A stoic look at the economy and markers showing the actual movement.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=Zok-XPA0Dt8`,
      ],
    },    
    {
      description: [
        `SUPPLY CHAIN CRISIS: GLOBAL SHUTDOWN - PROVIDING THE DARKEST WINTER POSSIBLE`,
        `The coming economic collapse and new great depression`,
      ],
      urls: [
        `https://www.bitchute.com/video/pEW5t90oBswO/`,
      ],
    },    
    {
      description: [
        `Alpha Investments: Rant video: <strong>Young people are screwed</strong>.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=68iEGD0Hmn0`,
      ],
    },    
    {
      description: [
        `Vancouver realtor, Steve Saretsky`,
        `Canadian Home Prices Accelerating into Rolling Lockdowns`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=WLzGuaYnBck`,
      ],
    },
    {
      description: [
        `JP Morgan CEO Screams Economic Collapse | Unseen Interview`,
        `"There is a hurricane coming for the American economy."`
      ],
      urls: [
        `https://www.youtube.com/watch?v=JNxhV40aIg4`
      ],
    },
    {
      description: [
        `"We Are About To Suffer WORSE Than I Thought..." — Peter Schiff's Last WARNING`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=bCTUu1lAEn4`
      ],
    },
    {
      description: [
        `Treasury Sec. Janet Yellen: "Probably could've used a better term than transitory..."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1534225614609293313`
      ],
    },
    {
      description: [
        `"The Crash Will Be WORSE Than 2008" | Peter Schiff's Last WARNING`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=N0txzRZFyWM`
      ],
    },
    {
      description: [
        `They're laughing at us, this is planned for the "Great Reset".`,
        `Timeline video with all of their quotes.`,
      ],
      urls: [
        `https://twitter.com/WallStreetSilv/status/1535407950575157250`
      ],
    },
    {
      description: [
        `<b>Next generation teacher describes the banking system and collapse:</b>`,
        `So I put $10,000 into the bank, they take my $10k, and the go get $100k. They borrow it from the Fed window.`,
        `They get 10 times the amount of our deposits that we put into the bank, they then get ten times the amount.`,
        `The then take that ten times the amount and they put it into Treasury Bonds or something else that's going to give them a yield of 7% or 6%, while they pay us, what? 0.0000 nothing percent.`,
        `Right. And then what happens? We get hip to it and say, "Hey, Mr. Silicon Valley Bank, can I have my money back?"`,
        `"Oh, we don't have it right now." "I want my money back." "I don't have it." Everybody: "I wany my money back, give us our money back."`,
        `Hold on, we got to go sell the Treasury Bonds that we invested in, that now, are upside down because the rates are through the roof.`,
        `*downwards whistle + crash*`,
        `And I don't have your money.`,
        `So what has to happen to their Ponzi scheme, that I would have went to jail if I did that? They get bailed out. Mr. Biden comes up and says, oh we're going to print more money.`,
        `I wonder why he's going to print more money. To devalue your dollar even more.`,
        `Because guess what they're doing next, to you? They're going to centralize banking. They're going to own crypto. They're going to regulate everything to be digital. So this is the first step to doing it, and they're going to own us.`,
        `We got to wake up, because that's what's happening. And next, there's a war going to go on between China or between Russia, and we're right in the middle, right? And then you look at these companies like BlackRock, does anybody ever hear of BlackRock? Or Vanguard?`,
        `Oh how coincidental, since 9/11 you own everything. Hm.. You own everything, you own Pfizer, you own Moderna, just coincidentally, but we don't know who the hell you are. You're never on TV, but you own Apple, you own Facebook, so you own everybody's identities, all everybody's information, you own Smith and Wesson, you own manufacturers of guns. We're going to war. It just seems you're everywhere at the right time, to make all the money and you're the biggest company in the world, but you're never on the news, we never hear about you.`,
        `Yeah man, we got serious problems.`,
      ],
      urls: [
        `https://twitter.com/ImMeme0/status/1637566484649000971`
      ],
    },
    {
      description: [
        `Another discussion on US mainstream media about the impending demise of the dollar as the world's reserve currency.`,
        `This is going to have a massive impact on our economy. Canada is not prepared and almost nobody here seems to even be aware of it.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1640393300887715842`
      ],
    },
    {
      description: [
        `"Rewire the entire global financial system for Net Zero."`,
        `~ Rishi Sunak`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1601874921613066240`
      ],
    },
    {
      description: [
        `"It feels like a treadmill living in Canada as a young person"`,
        `Mid 20's woman with a good career may not be able to afford her mortgage and condo fees after fees went up 75%`,
      ],
      urls: [
        `https://twitter.com/therealhebrahim/status/1644341294330683395`
      ],
    },
    {
      description: [
        `Stoic Finance: Blackrock: 2023 Economic COLLAPSE Will Bring NEW WORLD ORDER`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=Ud9OA10PDZk`
      ],
    },
  ],
  EmergenciesAct: [
    {
      description: [
        `<strong>Chrystia Freeland details how some Emergency Act powers will be made permanent.</strong>`,
        `(we actually thought she might wait a few days before enacting a permanent police state)`,
      ],
      urls: [
        `https://twitter.com/inklessPW/status/1494752731441123328`
      ],
    },
    {
      description: [
        `House of Commons debate on Emergencies Act.`,
        `<strong>You may have messed up as the Prime Minister when Alberta and Quebec agree.</strong>`,
        `“The PM has failed the test of leadership and the test of freedom”`,
      ],
      urls: [
        `https://twitter.com/johnwtomkinson/status/1494352457949798401`
      ],
    },
    {
      description: [
        `TRUDEAU Invokes EMERGENCIES ACT to Stop FREEDOM CONVOY in CANADA`,
        `Criminal defense attourney Robert Gouveia Esq.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=ky_mRGomXpk`
      ],
    },
    {
      description: [
        `Dr. Paul Alexander and Dr. Roger Hodkinson react to Justin Trudeau's Emergency Act declaration.`,
      ],
      urls: [
        `https://www.bitchute.com/video/tUCdFiA08ade/`
      ],
    },
    {
      description: [
        `Ex-RCMP officer Daniel Bulford on possible firearms being planted to discredit the protest, and to be used as a pretext to forcibly remove peaceful protesters.`,
      ],
      urls: [
        `https://www.bitchute.com/video/bTPUgELMyuWo/`
      ],
    },
    {
      description: [
        `Russell Brand talks about the convoy`,
        `<b>Trucker Protest Goes Global - “Hold The Line!”</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=jMC_8q49rlM`
      ],
    },
    {
      description: [
        `Interim Ottawa police chief: "If you are involved in this protest, we will actively look to identify you and follow up with financial sanctions and criminal charges, absolutely, this investigation will go on for months to come."`,
        `Who is this guy? Why did the police chief quit and this dictator take over? This is insane behaviour, who is pulling the strings?`,
      ],
      urls: [
        `https://gettr.com/post/pvlsp2246e`
      ],
    },
    {
      description: [
        `Neil Oliver's latest covid tyranny monologue, always a highlight of the week.`,
      ],
      urls: [
        `https://gettr.com/post/pvku2ed3ea`
      ],
    },
    {
      description: [
        `Trudeau says MPs who vote against the Emergencies Act indicate that "they don't trust the government to make incredibly momentous and important decisions"`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1495803301736103937`
      ],
    },
    {
      description: [
        `Freeland: "The way to get your account unfrozen is to stop being part of the blockade and occupation."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1495810602580729861`,
        `https://twitter.com/aginnt/status/1495830438891630592`,
      ],
    },
    {
      description: [
        `<b>Canadian were subjected to Emergencies Act by Justin Trudeau without justification for a problem he created, deliberately exacerbated and did nothing to resolve.</b>`,
        `Emergencies Act: An Autocratic Suspension of Civil Liberties Is a Dangerous Failure`
      ],
      urls: [
        `https://www.theepochtimes.com/emergencies-act-an-autocratic-suspension-of-civil-liberties-is-a-dangerous-failure_4290964.html`
      ],
    },
    {
      description: [
        `Trudeau: "Invoking the Emergencies Act has been necessary"`,
        `Everything he says are not only lies, but the opposite of what actually is happening. This is China's model.`
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1495830421095034881`
      ],
    },
    {
      description: [
        `OTTAWA Police HARASS Canadians as TRUDEAU Craves POWER`,
        `Watching the Watchers`,
        `Officers threatening to break down windows and doors in businesses, and yelling at old ladies. No joke.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=z-PZ4fEqi0w`
      ],
    },
    {
      description: [
        `Canadian Civil Liberties Association`,
        `We are deeply disappointed the government chose to make tonight's vote a matter of confidence. This morning, we asked the government to revoke the emergency declaration and barring that, to at minimum commit to a free vote in Parliament.`,
        `Instead the government made it a confidence matter and we have seen numerous government MPs express both publicly and in confidence to us that they would vote against the emergency declaration if given a chance.`,
        `Let's be clear: there is no legal justification for using the emergencies act. The broad powers the government has granted to police curtail Charter rights across the country. This risk of abuse is high.`,
        `<b>The emergency declaration should be immediately revoked.</b>`,
      ],
      urls: [
        `https://twitter.com/cancivlib/status/1495932825752809472`
      ],
    },
    {
      description: [
        `The Canadian parliament just ratified a declaration of the Emergencies Act in the absence of any existing emergency. Let that sink in`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1495975054466768896`
      ],
    },
    {
      description: [
        `Trudeau's deputy, Chrystia Freeland, had already said she plans to make the bank seizure powers permanent.`,
        `Today Trudeau said he will continue with the emergency, even though all the trucker blockades are long gone.`,
        `<b>He plans to make this a permanent emergency. It's a coup.</b>`,
      ],
      urls: [
        `https://twitter.com/ezralevant/status/1495815319784509452`
      ],
    },
    {
      description: [
        `Parliament approves Trudeau's use of Emergencies Act in response to freedom protests.`,
      ],
      urls: [
        `https://thepostmillennial.com/breaking-parliament-trudeau-emergencies-act-freedom-protests`
      ],
    },
    {
      description: [
        `Justin Trudeau's Canada. His father would be proud!`,
      ],
      urls: [
        `https://gettr.com/post/pvzvwo5b79`
      ],
    },
    {
      description: [
        `First Nations warrior flag is up in Ottawa, you know what this means right?`,
        `All clands of first nations are in accordance and will stand to protect the land and its citizens.`,
      ],
      urls: [
        `https://media.gettr.com/group24/getter/2022/02/21/18/e46a7240-8abd-2c05-9e52-64e5e367a4c6/735633e49eee456e8504ee2a66224ab6.jpg`
      ],
    },
    {
      description: [
        `Criminal Lawyer: I have received over 100 calls, emails & DMs from people who got charged last weekend in the Ottawa Police crackdown.`,
        `All Criminal Code charges.`,
        `ZERO offences alleged under the Emergencies Act or its regulations.`,
        `Fun fact: The Criminal Code existed before.`,
        `<b>Which means the criteria to induce the Emergencies Act was not met, as there were other means at the government's disposal.</b>`
      ],
      urls: [
        `https://twitter.com/DavidAnber/status/1496459914604158977`
      ],
    },
    {
      description: [
        `"Non-affiliated" Senator Marilou McPhedran says that elements inside the freedom convoy were "publicly proclaiming that they want a racial war" and that "if there isn't a racial war then we will all soon be forced to speak hebrew.", she adds, "it's an approximate quote".`,
        `Calls it a "seige of Ottawa".`
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1496290561598365706`
      ],
    },
    {
      description: [
        `<b>Liberal ad condemning Prime Minsiter Stephen Harper for announcing he wants to increase military presence in our cities.</b>`,
        `"Stephen Harper actually announced he wants to increase military presence in our citites.`,
        `Canadian cities.`,
        `Soldiers with guns.`,
        `In our cities.`,
        `In Canada.`,
        `We did not make this up.`
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1496557656521203718`
      ],
    },
    {
      description: [
        `<b>Donald Neil Plett Makes Historic Speech In Senate Over PM Trudeau's Emergencies Invocation</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=YIZO3OhqCUY`
      ],
    },
    {
      description: [
        `Trudeau announces end of Emergencies Act.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1496596174916182018`
      ],
    },
    {
      description: [
        `Deputy Prime Minister Chrystia Freeland says that some accounts will remain frozen depending on who the person was.`,
      ],
      urls: [
        `https://twitter.com/backtolife_2022/status/1496612154031677445`
      ],
    },
    {
      description: [
        `Pierre Poilievre: Trudeau backs down. Will drop Emergencies Act. Thank you to all who fought this abuse of power.`,
      ],
      urls: [
        `https://twitter.com/PierrePoilievre/status/1496596235851124742`
      ],
    },
    {
      description: [
        `<b>Alberta Premier Jason Kenney:</b>`,
        ` There never was an emergency that required these extra-judicial powers.`,
        `What a humiliation for those who were defending the indefensible 5 minutes ago.`,
        `<b>Alberta intends to proceed with an application for judicial review of the EA invocation to address this abuse of power.</b>`,
        `Those whose rights have been violated, and provinces whose jurisdiction has been violated without justification must have their day in Court.`,
        `The judiciary must be asked to establish limits on such arbitrary use of extraordinary police powers for the future.`,
      ],
      urls: [
        `https://twitter.com/jkenney/status/1496597305440587781`
      ],
    },
    {
      description: [
        `The temporary powers over bank accounts given to FINTRAC, were made permanent by Freeland.`,
        `So the Emergency is over, but the power will remain.`,
      ],
      urls: [
        `https://twitter.com/1125thinker/status/1496595504934887426`
      ],
    },
    {
      description: [
        `<b>The Telegraph: “Imagine the uproar if, back in 2020, President Trump had frozen bank accounts belonging to key figures in the Black Lives Matter movement.</b>”`,
        `Well, Trudeau did it with the truckers and all the fascists approved.`,
      ],
      urls: [
        `https://www.telegraph.co.uk/news/2022/02/21/liberal-outrage-trudeaus-monstrously-illiberal-behaviour/`
      ],
    },
    {
      description: [
        `Mayor of Ottawa suggests selling vehicles confiscated from freedom protesters`,
      ],
      urls: [
        `https://thepostmillennial.com/mayor-of-ottawa-suggests-selling-vehicles-confiscated-from-freedom-protesters`
      ],
    },
    {
      description: [
        `Lawyer commentary on Trudeau RECINDING Emergencies Act Declaration.`,
        `Trudeau RESCINDS Emergencies Act Declaration - CLOWN SHOW Continues - Viva Frei Live`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=gw7SlOcy3ew`
      ],
    },
    {
      description: [
        `This Hour has 22 Minutes`,
        `<b>When all else fails, invoke the Emergencies Act!</b>`
      ],
      urls: [
        `https://twitter.com/22_Minutes/status/1496535470133628932`
      ],
    },
    {
      description: [
        `Liberals cite CBC 'analysis' to justify freezing bank accounts`,
      ],
      urls: [
        `https://torontosun.com/opinion/columnists/furey-liberals-cite-cbc-analysis-to-justify-freezing-bank-accounts`
      ],
    },
    {
      description: [
        `RCMP gave banks police info on Ottawa protesters with list of accounts to freeze`,
      ],
      urls: [
        `https://www.ctvnews.ca/canada/rcmp-gave-banks-police-info-on-ottawa-protesters-with-list-of-accounts-to-freeze-1.5809615`
      ],
    },
    {
      description: [
        `One of the writers of the Emergency Measures Act, Perrin Beatty, speaks about its intent and (mis)use.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=dmZiXRJI3FA`
      ],
    },
    {
      description: [
        `Alberta is challenging the use of the Emergencies Act`,
      ],
      urls: [
        `https://www.alberta.ca/release.cfm?xID=8251910B93002-F260-5BF4-0113DC11E89F09C6`
      ],
    },
    {
      description: [
        `Former Ottawa police chief Peter Sloly testifies that he never requested invocation of the Emergencies Act to deal with the Freedom Convoy and isn't aware of anyone at Ottawa Police Service who did.`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1532391713897172993`
      ],
    },
    {
      description: [
        `Joint Special Committee Uncovers that No Charges were Laid under the Emergencies Act | May 2022`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=koFkhytjRME`
      ],
    },
    {
      description: [
        `Public Safety Minister Marco Mendicino's office is now claiming he was misunderstood when he said police advised the Liberal government to use the Emergencies Act`,
        `Here's a clip of him saying exactly that about a dozen times in the House of Commons`,
        `What's there to misunderstand?`,
      ],
      urls: [
        `https://twitter.com/cosminDZS/status/1534658582813106177`
      ],
    },
    {
      description: [
        `Justin Trudeau and Jagmeet Singh #SelloutSingh enacting the Emergencies Act on peacefully protesting Canadians, because they were embarrassing them. Is like calling the fire department, because you burnt your toast.`,
        `Disproportionate, unnecessary, and hopefully never abused again`,
      ],
      urls: [
        `https://twitter.com/RealPureLove/status/1534898213471199232/photo/1`
      ],
    },
    {
      description: [
        `In Liberal party members' own words, the Emergencies Act did not give the government power to freeze bank accounts, or direct fintrac or banks to freeze bank accounts.`,
      ],
      urls: [
        `https://twitter.com/howisthismylif/status/1522404807360851968`,
        `https://www.youtube.com/watch?v=zA4ZGr5IUvE`,
      ],
    },
    {
      description: [
        `<b>The Globe and Mail: Three police forces had doubts about use of Emergencies Act</b>`,
        `Police didn't ask for it, and a back channel for a negotiated deal was quashed by the Trudeau Government? If so, basically it lied to Canadians about the need to invoke the Act.`,
      ],
      urls: [
        `https://twitter.com/nspector4/status/1585222404699467777/photo/1`
      ],
    },
    {
      description: [
        `Crystia Freeland at the Convoy Hearing looking like she's stumbling on her "Kommandant notes"`,
      ],
      urls: [
        `https://twitter.com/liz_churchill7/status/1595882268005892097`
      ],
    },
    {
      description: [
        `Miller: So the purpose [of the regulations the federal government put into place] was to encourage Canadians to get vaccinated? To compel them to get vaccinated? Is that fair?`,
        `Freeland: Thats right.`,
      ],
      urls: [
        `https://twitter.com/RealAndyLeeShow/status/1595850779445059584`
      ],
    },
    {
      description: [
        `To invoke the Emergencies Act, the crisis at hand would have to rise to the bar of the threats in Sect 2 of the CSIS act.`,
        `The Director of CSIS repeatedly said it doesn't.`,
        `And he said using the EA might radicalize people.`,
      ],
      urls: [
        `https://twitter.com/SheilaGunnReid/status/1594722841798361091`
      ],
    },
    {
      description: [
        `Convoy lawyer Brendan Miller shows Windsor Mayor Drew Dilkens a document from CSIS predicting that if the Emergencies Act was invoked it may cause protesters to become violent.`,
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1589745220970749952`
      ],
    },
    {
      description: [
        `In this text conversation, Mary-Liz Power of Justin Trudeau's office says there might be an opportunity for Marco Mendicino to "get in on this growing narrative" of the convoy truckers being "extreme."`,
        `"<b>Frame the narrative</b>"`,
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1587121790215561216`,
        `https://twitter.com/AndrewLawton/status/1587119561966501891`,
        `https://threadreaderapp.com/thread/1587119561966501891.html`,
        `https://twitter.com/RealAndyLeeShow/status/1587116992334553088`,
      ],
    },
    {
      description: [
        `OPP says convoy was labelled a potential national security threat in part because of the "threat to [Canada's] reputation by virtue of coverage in the international media of what was transpiring in Canada."`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1582856439407575041`
      ],
    },
    {
      description: [
        `Ontario Provincial Police intelligence officer Pat Morris says there was "no intelligence to indicate that [convoy protesters] would be armed," dismissing discussions to the contrary as "hyperbole."`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1582843394161668096`
      ],
    },
    {
      description: [
        `Explosive cross examination of OPP intelligence officer during Emergencies Act hearings`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=a-KchDti9r0`
      ],
    },
    {
      description: [
        `Manager of Ottawa Emergency Services Kim Ayotte testifies that protesters were concerned about maintaining safety lanes and kept safety lanes open at all times during the protests to avoid blocking emergency service vehicles.`,
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1582485754134945792`
      ],
    },
    {
      description: [
        `Ottawa Mayor Jim Watson says he has no first-hand knowledge of attacks by Freedom Convoy protesters or of protesters ripping people's masks off.`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1582470197864267778`
      ],
    },
    {
      description: [
        `Ottawa Mayor @JimWatsonOttawa admitting he met with @gofundme and pressured them to freeze the convoy fundraiser, which they did. And for which he “lauded” them. The criminals are running the prison.`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1582371002885238787`
      ],
    },
    {
      description: [
        `<b>"I did not call people who are unvaccinated names."</b>`,
        `- Justin Trudeau`,
        `Watch this video. He did.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1596440340248293378`
      ],
    },
    {
      description: [
        `<b>Senator Denise Batters condems the use of the Emergencies Act.</b>`,
        `I stayed and worked in downtown Ottawa throughout the convoy protests. My office faces onto Wellington Street. Since 2020, I never felt safer walking downtown than I did during that time. The protesters I met were friendly and patriotic Canadians who just wanted to be heard.`,
        `I talked about my experience in my Senate Chamber Emergencies Act speech the day before PM Trudeau revoked the Emergencies Act.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1597359915127156736`,
        `https://www.facebook.com/sendenisebatters/videos/1231494187712743/`,
      ],
    },
    {
      description: [
        `They apologize when it's the commercially expedient thing to do. Then continue with the abuse when they think nobody's looking.`,
        `<b>Scotiabank apologizes for freezing bank accounts during the emergencies act</b>`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1616783782555549696`
      ],
    },
    {
      description: [
        `The media has once again launched their “majority of Canadians agree the Emergencies Act was the right thing to do” campaign.`,
        `Here are 4 much larger polls that say otherwise.`,
      ],
      urls: [
        `https://twitter.com/ryangerritsen/status/1599775621563154432/photo/1`
      ],
    },
    {
      description: [
        `Jody Thomas, the security advisor to Justin Trudeau who recommended the EA be invoked, admits she did not actually view the operational plan prepared by police on February 12th until two weeks ago, despite testifying during the public inquiry that the plan was “not complete.”`,
      ],
      urls: [
        `https://twitter.com/RealAndyLeeShow/status/1598496346272858118`
      ],
    },
    {
      description: [
        `'No evidence of a threat': Freedom Convoy lawyer says Trudeau unlawfully invoked Emergencies Act`,
      ],
      urls: [
        `https://thepostmillennial.com/no-evidence-of-a-threat-freedom-convoy-lawyer-says-trudeau-unlawfully-invoked-emergencies-act`
      ],
    },
  ],
  FollowTheMoney: [
    {
      description: [
        `FDA exec exposed by Project Veritas alleges COVID vaccines are a 'recurring fountain of revenue' for drug companies.`,
        `"Joe Biden wants to inoculate as many people as possible"`,
        `"You'll have to get an annual shot"`,
        `"They're[FDA] not going to not approve it”`,
      ],
      urls: [
        `https://thepostmillennial.com/breaking-fda-exec-exposed-by-project-veritas-alleges-covid-vaccines-are-a-recurring-fountain-of-revenue-for-drug-companies/`,
        `https://twitter.com/MyrnaMichelle/status/1493977156271185927`,
      ],
    },
    {
      description: [
        `Why was Pfizer partnering with the Chinese Communist Party's platform for vaccine passports & social credit scores the year before the COVID-19 pandemic started?`,
        `Natalie Winters - The National Pulse`
      ],
      urls: [
        `https://twitter.com/nataliegwinters/status/1476361056507158531`
      ],
    },
    {
      description: [
        `Jim Jordan Blows The Lid Off The CDC Corruption`,
        `<b>31,000 people spending 58 billion dollars a year... why hasn't our government done a study on natural immunity?</b>`,
      ],
      urls: [
        `https://rumble.com/vr7h39-jim-jordan-blows-the-lid-off-the-cdc-corruption.html`
      ],
    },
    {
      description: [
        `George Soros: "Covid-19 also helped legitimize instruments of control"`,
      ],
      urls: [
        `https://twitter.com/WallStreetSilv/status/1594918279801602049`
      ],
    },
    {
      description: [
        `<b>'Agency Capture on Steroids': There's an Incentive Not to Find Problems With Pharmaceuticals</b>`,
        `• The FDA receives 75% of its drug approval budget from the pharmaceutical industry.`,
        `• Almost 45% of the FDA's total budget comes from the pharmaceutical industry.`,
        `• Individual scientists within NIH are allowed to patent drugs and collect royalties. And those NIH scientists have received an estimated $350 million in royalties since 2009.`,
        `Robert Kennedy Jr: "So you have scientists who are supposed to be regulators who are actually making money on the product that they're supposed to be regulating."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1634721868090310656`
      ],
    },
    {
      description: [
        `<b>Ivermectin: The Truth - From Filmmaker Mikki Willis</b>`,
        `"There's a little-known federal law that says, 'You cannot give an emergency use authorization to a vaccine if there is any medication approved for any purpose that is shown effective against the target disease.' So if Tony Fauci or anybody had admitted that hydroxychloroquine or ivermectin are effective against COVID, it would have been illegal for them to give the emergency use authorization to the vaccines, and they could have never gotten them approved."`,
        `Of all the harmful misinformation spread over the last couple of years, one of the most disturbing false narratives was targeted at the Nobel Prize Winning, human medicine, Ivermectin.`,
        `What you're about to see will reveal the motive behind the smear campaign against one of the safest and most effective medicines of this era.`,
        `A medicine that, according to the numerous top scientists I've interviewed this year, <b>could have ended the pandemic before it began.</b>`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1634338660270108677`
      ],
    },
    {
      description: [
        `Moderna CEO Stéphane Bancel Admits They Paid Fauci's NIH $400 Million in Royalties for a Product the Government Mandated`,
        `"Indeed we recently made before Christmas last year a $400 Million payment to the NIH for an old patent that they had developed not related to COVID but [was] useful in development of the COVID vaccine to pay them for their work"`,
      ],
      urls: [
        `https://twitter.com/TheChiefNerd/status/1638567339456094209`,
        `https://twitter.com/TexasLindsay_/status/1638558264895102976`,
      ],
    },
    {
      description: [
        `<b>Senator Malcolm Roberts: Bill Gates bought the WHO and they are now recommending his products. It's that simple.</b>`,
      ],
      urls: [
        `https://twitter.com/DrLoupis/status/1639215231854338048`
      ],
    },
    {
      description: [
        `Robert Kennedy Jr tells Jimmy Dore the CIA, DOD, and Tony Fauci taught Chinese military scientists how to build weapons of mass destruction. Then Bill Gates, a former CIA director, and China's CDC director collaborated on how to censor a lab leak at Event 201 in Oct. 2019:`,
        `"Fauci funded the study that taught the Chinese military scientists, everything in China is dual-use, that lab is a military lab, and he taught them cutting-edge technology for building weapons of mass destruction. In other words, the study for how to create the clones and how to create a spike protein that could attach to a human lung and transplant it onto a coronavirus.`,
        `He also funded through Ralph Baric at the University of North Carolina a technique called seamless ligation which is a technique for hiding human tampering on that virus after you've done it. Fauci gave Baric $212 million, and Baric developed a technique for hiding the human tampering; Baric taught that to Shi Zhengli, the Chinese bat lady.`,
        `Fauci says we were doing this for vaccine development and countermeasures, but there is no justification in the world for funding somebody to create seamless ligation; in fact, it is the inverse of what you would do if your interest was public health. If your interest was bioweapons creation, and he was the czar of bioweapons since 2002, that's what you would do.`,
        `USAID gave ten times what Fauci gave. The DOD was there. Why were they in there teaching Chinese scientists how to build weapons of mass destruction? USAID is a CIA front group. Eco-health Alliance is a CIA front group. The CIA modeled this outbreak in 2019 twice, the second time at Event 201.`,
        `Who was at Event 201? Avril Haines co-hosted it with Bill Gates, and the head of the Chinese CDC, George Gao, was there. The virus was already circulating in Wuhan, nobody knew it, but George Gao had to know it; he was the head of the Chinese CDC and their number one expert on coronaviruses.`,
        `He comes to New York in October of 2019 and sits downs with Avril Haines, the former director of the CIA, today the Director of National Intelligence, the top spy in the country, and they do a four-part simulation, and the fourth part is George Gao and Avril Haines talking about how do we get social media to censor people if they say this is from a lab leak."`,
      ],
      urls: [
        `https://twitter.com/KanekoaTheGreat/status/1635429830291521536`
      ],
    },
    {
      description: [
        `Dr. Robert Redfield: "There's No Doubt That NIH Funded Gain-of-Function Research"`,
        `Ms. Malliotakis: "Is it likely that American tax dollars funded the gain-of-function research that created this virus?"`,
        `Dr. Redfield: "I think it did — not only from NIH but from the State Department, USAID, and from DOD."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1633491748893728768`
      ],
    },
    {
      description: [
        `<b>Foul Play With Death Certificates: Hospitals Made Record Profits</b>`,
        `Dr. Henry Ealy: "In Santa Clara County, California, and in Alameda County, California, they did a partial audit, and it lowered their [COVID] death certificates by 22% and 25%. Why? Because hospitals were counting everything they could as COVID ... When [they] put COVID down, [they] get more money for it."`,
        `<b>96% of all death certificates for covid had on average 4.0 comorbidities.</b>`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1632551125214699525`,
      ],
    },
    {
      description: [
        `<b>Dr. Martin Makary:</b>`,
        `The epicenter is only 5 miles from one of the only high-level virology labs in China. The doctors initially were arrested and forced to sign non-disclosure gag documents. The lab reports have been destroyed, they've not been turned over. The sequences reported to from the lab to the NIH database were deleted by a request from Chinese scientists that called over early on and said, 'delete those sequences we put in the database.'`,
        `And 2 leading virologists, maybe the 2 top virologists in the United States, told Dr. Fauci on his emergency call in January of 2020 when he was scrambling, soon after learning that the NIH was funding the lab, the both said that it was likely from the lab. Both scientists changed their tunes days later in the media, and then both scientists received 9 million dollars in subsequent funding from the NIH.`,
        `It's a no-brainer that it came from the lab.`,
      ],
      urls: [
        `https://twitter.com/RWMaloneMD/status/1630752670423175168`
      ],
    },
    {
      description: [
        `<b>Now that Bill Gates has cashed out his COVID vaccine stock for over $500 million he is now claiming that COVID vaccines are problematic.</b>`,
        `<b>Jimmy Dore calls him out on his new venture. It's not about health or science. It's about profit.</b>`,
        `He's off the covid vaccine, he's already cashed in his stock for the covid vaccine, he made $500,000,000 off his covid stock for the vaccine, while you got locked down and weren't allowed to go to work and make money, he bought $50 million worth of stock that went up 10 times in value. And now watch the next thing he says because he just cashed in that stock. So the new thing is nasal covid thing that blocks the infection, but watch this:`,
        `Bill Gates: We also need to fix the 3 problems of vaccines. The current vaccines are not infection blocking, they're not broad so when new variants come up you lose protection, and they have very short duration.`,
        `Jimmy Dore: So now he's shitting on the vaccine, and now he's pushing this new thing. Because I was like whoa he cashed out his stock on the vaccine and now he's invested in this new thing. Is he invested in this new thing? I don't know let's look.`,
        `This is the economic times industry: Gates Foundation to fund two companies for vaccines research. Bill and Melinda Gates Foundation will fund two Indian firms, Serum Institute of India and Bharat Biotech -  wait a minute, that's the people doing this: Bharat Biotech: India launches its first nasal Covid vaccine.`,
      ],
      urls: [
        `https://twitter.com/Xx17965797N/status/1642115942887956481`,
        `https://twitter.com/JamesMelville/status/1623953033854873602`,
      ],
    },
    {
      description: [
        `<b>Bernie Sanders talks about big pharma.</b>`,
        `In terms of Moderna, here's the story: This vaccine was discovered in partnership with the NIH, with a government agency supported by the taxpayers of this country.`,
        `In addition, the government put $1.9 billion into research and development for Moderna. And then guaranteed Moderna billions of dollars in sales.`,
        `And then as a thank you to the taxpayers of this country, what Moderna says is after we deplete the government supply of free vaccines, they're going to quadruple the price of that vaccine. And meanwhile they're going to charge whatever it is, $110 a vaccine, it costs them about $2 to produce that vaccine.`,
        `And then on top of all of that, in the last few years since this vaccine has been on the market, you've got the CEO of the company making billions of dollars, becoming a multi-billionaire, as well as other executives.`,
        `So the taxpayers of this country who put money into the vaccine in order to protect the health and lives of the American people, are now creating billionaires in a company that is going to quadruple prices for the American people.`,
        `The CEO of Moderna recently received a $926 million golden parachute.`
      ],
      urls: [
        `https://twitter.com/realstewpeters/status/1623506908534431746`
      ],
    },
    {
      description: [
        `State of emergency/pandemic has been used to empower governments, elites and WHO to take control over people's lives, economy, way of life, social issues through fear and coercion. This chart shows top 20 donors to WHO.`,
      ],
      urls: [
        `https://twitter.com/KLVeritas/status/1622126518116679680`
      ],
    },
    {
      description: [
        `Project Veritas:`,
        `Feb 2022: Chris Cole @US_FDA Exec reveals corrupt business practices with Big Pharma`,
        `"There's a money incentive for Pfizer & the drug companies to promote additional vaccinations"`,
        `"It'll be reoccurring fountain of revenue"`,
        `"They pay us hundreds of millions of dollars a year"`,
      ],
      urls: [
        `https://twitter.com/Project_Veritas/status/1613981709539704832`
      ],
    },
    {
      description: [
        `A prominent leader of the British Heart Foundation has conspired to cover-up research linking mNRA jabs with heart inflammation, says British MP`,
      ],
      urls: [
        `https://twitter.com/james_freeman__/status/1602768194308956160`
      ],
    },
    {
      description: [
        `So you were on Pfizer's payroll?`,
        `Yes.`,
        `And you were also on the “independent” Data Safety Monitoring Board for the Pfizer Vax trial?`,
        `Yes.`,
        `So it wasn't actually independent then?`,
        `No, it was independent.`,
      ],
      urls: [
        `https://twitter.com/DrSyedHaider/status/1604633165037027329`
      ],
    },
    {
      description: [
        `<b>Kids and Young People Targeted by Bill Gates' Exercise, "Catastrophic Contagion"</b>`,
        `Remember “Event 201”, a preparedness exercise featuring a coronavirus pandemic, conducted in October 2019 under the auspices of the World Economic Forum and Bill and Melinda Gates Foundation and involving China's CDC and others?`,
        `The timing was exquisite, and the COVID-19 pandemic started within weeks of the exercise and went on just as predicted.`,
        `Be aware that Bill Gates just conducted another exercise, aptly called “Catastrophic Contagion” (I am NOT kidding), on October 23, 2022. Bill himself showed up:`,
      ],
      urls: [
        `https://igorchudov.substack.com/p/kids-and-young-people-targeted-by`,
        `https://www.centerforhealthsecurity.org/our-work/exercises/2022-catastrophic-contagion/index.html`,
      ],
    },
    {
      description: [
        `Dr. Peterson Pierre, M.D.:`,
        `Hospitals get paid an enormous amount if they use remdesivir 20% surcharge on the entire hospital bill.`,
        `They also get paid extra if they use a ventilator & they get paid extra for COVID deaths. So for each patient you're looking at about a $400,000 - $500,000 bounty`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1601346807975796736`
      ],
    },
    {
      description: [
        `A new report by the Auditor General of Canada has found that the over-procurement of vaccines will result in the wastage of approximately 32.5 million doses of vaccines currently in inventory by the end of 2022, at a cost of about $1 billion to taxpayers.`,
        `The report estimates that as of May 31st, the average cost of one vaccine dose was $30. As a result, by the end of the audit, the Government of Canada had spent approximately $5 billion for the 169 million doses purchased, meaning almost one-fifth of that spending will be waste.`,
      ],
      urls: [
        `https://twitter.com/RealAndyLeeShow/status/1600146569835483137`,
        `https://twitter.com/RealAndyLeeShow/status/1600148943807328256`,
        `https://www.oag-bvg.gc.ca/internet/English/parl_oag_202212_09_e_44175.html`,
      ],
    },
    {
      description: [
        `<b>The Globe and Mail:</b>`,
        `<b>WHO employees perpetrated sexual crimes in Democratic Republic of the Congo during Ebola outbreak, report alleges</b>`,
        `21 employees of WHO, including doctors and epidemiologists, allegedly perpetrated sexual abuse and exploitation during an Ebola outbreak. Some even administered abortion pills to their victims when they became pregnant. My report on a "dark day" for WHO:`,
      ],
      urls: [
        `https://twitter.com/geoffreyyork/status/1442915360408104960`,
        `https://www.theglobeandmail.com/world/article-who-employees-perpetrated-sexual-crimes-in-democratic-republic-of-the/`,
      ],
    },
    {
      description: [
        `<b>Carole Vorderman exposing UK PM Rishi Sunak on insider trading</b>`,
        `Rishi Sunak, our Prime Minister, co-founded a hedge fund called Thaleem and no one's talking about it.`,
        `Thaleem invested, in the last few years, one billion pounds in Moderna shares.`,
        `Rishi Sunak said he has put all his assets into a blind trust. He has refused, and the Guardian was the last newspaper to report on this in November 2020, at that point, he refused to say whether he holds assets in Thaleem and therefore in Moderna.`,
        `So that man came out in front of 10 Downing Street, as our Prime Minsiter, and said, accountability, you know, transparency, all of this.. if you are true to your word, Rishi Sunak, tell us do you own, through Thaleem or any other mirrors, shares in Moderna? Because if you do, we are now talking about insider trade.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1619088235967451136`
      ],
    },
    {
      description: [
        `<b>Project Veritas:</b>`,
        `Pfizer Exploring "Mutating" COVID-19 Virus For New Vaccines`,
        `"Don't tell anyone this...There is a risk...have to be very controlled to make sure this virus you mutate doesn't create something...the way that the virus started in Wuhan, to be honest."`,
      ],
      urls: [
        `https://twitter.com/Project_Veritas/status/1618405890612420609`
      ],
    },
    {
      description: [
        `Nancy Pelosi calls Biden's "Build Back Better" agenda the "Obama Agenda"`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1442903654156013571`
      ],
    },
    {
      description: [
        `Kentucky primary care providers were bribed to suggest you should take the COVID vaccine. Practices receive cash bonuses per vaccinated member.`,
        `From Anthem Blue Cross and Blue Shield Medicaid: <b>COVID-19 Vaccine Provider Incentive Program</b>`,
      ],
      urls: [
        `https://twitter.com/RepThomasMassie/status/1646696738013454336/photo/1`
      ],
    },
  ],
  FoodSupplyChain: [
    {
      description: [
        `Get ready for food shortages. Trudeau, Schwab, Biden all saying we're in for a food and energy shortage, and a cyber attack.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=PtPNd_Gm408`
      ],
    },
    {
      description: [
        `Energy Crisis becomes a Food Crisis - Grow Food and Build Local Food Systems Now!`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=br2zz_v8wWg`,
      ],
    },
    {
      description: [
        `Ice Age Farmer - No Vax, No Food/Fuel in India - Food Withheld to Force Vaccinations`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=9ag7fOthBM8`,
      ],
    },
    {
      description: [
        `CEO: Food Prices to Explode NOW; FBI/NSA warn of Cyberattacks on Food & Water Systems`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=IBy16KTdJOI`,
      ],
    },
    {
      description: [
        `Farmers, Truckers STOPPED as Food Supply Collapses - Ice Age Farmer`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=g8V6CxBIdo8`,
      ],
    },
    {
      description: [
        `Maple lodge farm in Ontario kills and dumps 52,000 chickens.`,
        `Ordered to be killed, "all because the bullshit with the virus"`
      ],
      urls: [
        `https://twitter.com/randyhillier/status/1481749511844282372`
      ],
    },
    {
      description: [
        `Survival Lilly - Blackout coming, are you prepared?`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=LYHlH53gxS4`
      ],
    },
    {
      description: [
        `<b>Several very large food processing plants in the US have blown up/burned down in the past few days</b>`,
        `This is an odd coincidence … 18 U.S. food processing facilities burned down in the last six months. Azure Standard's HQ burned down yesterday.`,
      ],
      urls: [
        `https://twitter.com/GraduatedBen/status/1516851257381642244`,
        `https://threadreaderapp.com/thread/1516851257381642244.html`,
        `https://apnews.com/article/business-explosions-fires-winston-salem-8d56272f19e300671b68aca59d757153`,
        `https://www.wmur.com/article/crews-battled-fire-16-hours-conway-new-hampshire-41222/39707759`,
        `https://www.cnet.com/personal-finance/bird-flu-27-million-birds-dead/`,
        `https://www.cfindustries.com/newsroom/2022/union-pacific-shipping-restrictions`,
        `https://www.azfamily.com/2022/03/29/50000-lb-food-destroyed-after-fire-ripped-through-maricopa-food-pantry/`,
        `https://www.idahostatesman.com/news/nation-world/national/article260423897.html`,
        `https://www.newsweek.com/7-injured-explosion-fire-engulfs-food-plant-smoke-visible-miles-1681614`,
        `https://twitter.com/StogneBologne/status/1517212554279342080`,
        `https://twitter.com/WallStreetSilv/status/1516947217126506498`,
        `https://twitter.com/AnneMarieWTHR/status/1504152366161014784`,
        `https://twitter.com/JaimeNoyola/status/1514486173900558336`,
        `https://twitter.com/sandipseth/status/1503227080951042051`,

      ],
    },
    {
      description: [
        `May 27, 2022: Sounding the alarm on the global supply chain collapse.`,
        `It's a recipe of a perfect storm, happening all at once.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=Nfvym7UOJf0`
      ],
    },
    {
      description: [
        `Famine in 2022`,
        `Dr. John Campbell talks about the incoming global famine, and almost certain death from malnutrition from poor families and countries.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=kNv6LSpOqok`
      ],
    },
    {
      description: [
        `<b>Sri Lanka has banned the sale of gasoline to ordinary people. Military personnel with guns appeared at gas stations.</b>`,
        `Watch Sri Lanka. A plan is being implemented there`,
      ],
      urls: [
        `https://twitter.com/puritan_777/status/1543973452415115265`
      ],
    },
    {
      description: [
        `Anthrax found in water just 2 days after Canadien Feds "tested" water.`,
        `To control the food supply, is to control you.`,
      ],
      urls: [
        `https://twitter.com/_Tweetiez/status/1564411010458550273`,
        `https://regina.ctvnews.ca/anthrax-exposure-kills-nine-animals-in-southwest-sask-province-1.6040333`,
      ],
    },
    {
      description: [
        `CTV News: Farm animals in Saskatchewan die of Anthrax poisoning.`,
        `"Can be prevented by vaccination."`,
      ],
      urls: [
        `https://twitter.com/_Tweetiez/status/1564411322535641090`
      ],
    },
    {
      description: [
        `France… The manufactured food crisis rolls on with the worlds largest fresh product market (Rungis) going up in smoke.`,
        `Citadel “warehouse” fire? Nope. LIVE from Paris…the largest wholesale fresh produce market in the world, is on fire. Mysterious.`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1573997432257449990`,
        `https://twitter.com/CitadelAir/status/1574018988673323015`,
      ],
    },
    {
      description: [
        `Canadian Dairy farmers are forced to dump enormous quantities of milk in order to keep prices high.`,
        `I've been denouncing this for years. It continues because all the establishment parties are afraid of the supply management mafia.`,
        `---`,
        `Canadian government makes this dairy farmer in Ontario throw out 30,000L of milk, all because he's gone over his quota. The milk could be donated to the hospitals or homeless shelters or even food banks but nope pour it out. So we as consumers can pay $7L.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1621506289175072768`,
        `https://twitter.com/Fisherlady111/status/1620896460173877249`
      ],
    },
    {
      description: [
        `How did one of the largest egg production plants burn down during a national egg shortage?`,
      ],
      urls: [
        `https://twitter.com/ElijahSchaffer/status/1619839636221886465`
      ],
    },
    {
      description: [
        `World Economic Forum speaker encourages people to stop eating meat.`,
        `<b>"if a billion people stop eating meat"</b>`,
      ],
      urls: [
        `https://twitter.com/WallStreetSilv/status/1616299228803858436`
      ],
    },
    {
      description: [
        `It's a scam. The objective isn't to get the farmers to behave in an organic, responsible, ecologically apposite manner. Far from it. It's in order to bankrupt the farmers so that their land can be grabbed`,
        `Russell Brand`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1600392794283048960`
      ],
    },
    {
      description: [
        `GERMANY - is to comply almost immediately with the EU directive to end fertilisers, following the Netherlands`,
        `First it was energy and now it's food.`,
        `The Net Zero con will leave millions of citizens dependent on state handouts`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1599369617956225027`
      ],
    },
    {
      description: [
        `WEF & GOVERNMENTS - 4 Food Hubs.`,
        `Listen carefully, the ban of fertilisers will increase shortages and make food more expensive. They plan to implement a global system of food production, controlled by global corporations`,
        `Controlling people through food.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1599372376147652608`
      ],
    },
    {
      description: [
        `Netherlands: Farmers protest: This Dutch farmer drops truth bombs on the agenda to asset grab their land.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1598598430170947584`
      ],
    },
    {
      description: [
        `Netherlands has announced they will SEIZE 3000 farms in order to meet the 2030 climate goals.`,
        `Farmers will be FORCED to sell the land that they have owned for generations to the state.`,
        `This is Climate Communism.`,
      ],
      urls: [
        `https://twitter.com/PeterSweden7/status/1599065567167582208`
      ],
    },
    {
      description: [
        `Canadian farmer issues SOS about troubling policies the Canadian government is tabling.`,
      ],
      urls: [
        `https://twitter.com/MichelleLCatlin/status/1552680248663220225`
      ],
    },
    {
      description: [
        `Purina is the only supplier of chicken feed in some areas, and all hens stopped laying eggs. After they got off the food, they started laying again.`,
      ],
      urls: [
        `https://www.bitchute.com/video/K1pWm66jLABS/`
      ],
    },
    {
      description: [
        `<b>#NoFarmsNoFood</b>`,
        `Farmer, re: her TikTok post:`,
        `I hope you blow this shit up and listen, because I've told you again and again this administration's agenda is to control the food, and control the people.`,
        `Biden wants to push OSHA into an "extreme heat index" for outside workers. Do you know what the thinks is "hot"? 80 degrees Farenheit.`,
        `Let me tell you, here in Tennessee, it gets humid and I push past because guess what, my girls (cows) still got to get fed, and that's my priority, and I chose this life in agriculture to feed you and your families, and they are trying to put fear in our lives.`,
        `They're trying to kill off the American farmer. I know, we moved from California because regulations were getting so bad, and they were providing water to Los Angeles and San Fransisco instead of the central valley, and they're doing it across America, trying to regulate our land, take our land, take our water rights. It is happening. It is time to wake up and start supporting your local farmers and buy local.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1645340074186252288`
      ],
    },
    {
      description: [
        `Kate Wand:`,
        `<b>Collectivism Starves the People</b>`,
        `Central planners ALWAYS, without exception, starve and murder people when they attempt to collectivise and "transform" food systems.`,
        `Watch this short film on the Holodomor for a historical perspective. `,
      ],
      urls: [
        `https://twitter.com/katewand/status/1441130937949982724`,
        `https://www.youtube.com/watch?v=q62oMxUki5s`,
        `https://twitter.com/FoodSystems/status/1441068358275899399`,
      ],
    },
    {
      description: [
        `18,000 cattle dead, 1 person injured after Texas dairy farm explosion`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1646624339914047492`,
        `https://thepostmillennial.com/18000-cattle-dead-1-person-injured-after-texas-dairy-farm-explosion?utm_campaign=64466`,
      ],
    },
    {
      description: [
        `Dozens of firefighters are battling a fire at a food processing facility in Beamsville, ONTARIO, CANADA. Another coincidence!`,
      ],
      urls: [
        `https://twitter.com/AntonioTweets2/status/1647468549466648576`
      ],
    },
    {
      description: [
        `Another big fire at a chemical plant in the US, this time Georgia chosen.`,
      ],
      urls: [
        `https://twitter.com/Xx17965797N/status/1647515971773399040`
      ],
    },
  ],
  GlobalProtests: [
    {
      description: [
        `Brian Peckford, <strong>last living signer of Canada's Charter of Rights and Freedoms</strong>, at the Freedom Convoy protest in Victoria, British Columbia.`,
        `"... and we're not going to allow this Nation on the Northern part of North America to go down the drain because we have people who've gotten hungry for power, and have <strong>discarded the individual freedoms that you and I <i>own</i></strong>."`,
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1492739266337595392`,
      ],
    },
    {
      description: [
        `Students WALK OUT to protest mask mandates`,
        `"We're done".`,
      ],
      urls: [
        `https://twitter.com/OTURISK/status/1492933294534672387`,
      ],
    },
    {
      description: [
        `Brisbane streets come ALIVE with the sound of FREEDOM`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=W-EmElzRCLg`,
      ],
    },
    {
      description: [
        `Odessa police refuses to go against its people.`,
      ],
      urls: [
        `https://twitter.com/ninnyd101/status/1494944612456271873`
      ],
    },
    {
      description: [
        `Calgary is on fyre. Hundreds or thousands of people marching in the streets yelling "FREEDOM" (February 19, 2022)`,
      ],
      urls: [
        `https://twitter.com/DerAchsenZeit/status/1495165441374707713`
      ],
    },
    {
      description: [
        `Thousands of people in Belgium protest lockdowns and closures (Dec 26, 2021)`,
      ],
      urls: [
        `https://twitter.com/aginnt/status/1475248751581220866`
      ],
    },
    {
      description: [
        `People tear down barricades in Wuhan. Anti-lockdown protests are spreading to more and more cities in China.`,
      ],
      urls: [
        `https://twitter.com/disclosetv/status/1596822446643953664`
      ],
    },
    {
      description: [
        `Destroying PCR test booths in Lanzhou, 1500km east of Beijing.`,
      ],
      urls: [
        `https://twitter.com/alfonso_poza/status/1596824991097507840`
      ],
    },
    {
      description: [
        `Protests against China's zero-Covid lockdown in Guangzhou.`,
      ],
      urls: [
        `https://twitter.com/disclosetv/status/1596070693820829701`
      ],
    },
    {
      description: [
        `Police are using force against lockdown protests in China — similar to authorities in some Western countries in the past.`,
      ],
      urls: [
        `https://twitter.com/disclosetv/status/1596882656717045761`
      ],
    },
    {
      description: [
        `<b>Watch the Chinese people attack the COVID police.</b>`,
      ],
      urls: [
        `https://twitter.com/aginnt/status/1596975573167144962`
      ],
    },
    {
      description: [
        `The mainstream media are very keen to report and support the anti-lockdown and anti vaccine passports protests in China.`,
        `Sadly, they didn't do this when it happened in other countries. So here's a quick reminder of what they previously ignored.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1599332504108167168`
      ],
    },
    {
      description: [
        `U.K. - Government warns China over lockdown protests.`,
        `Here's a reminder of the U.K. government's response to London Lockdown protests.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1598580846587527168`,
        `https://twitter.com/cfw55cja/status/1598585650227412992`,
      ],
    },
    {
      description: [
        `Netherlands Police are using heavy machinery to tip over tractors with farmers INSIDE of them. When protesters peacefully stand against the machines, the police drag them into black vans.`,
        `Shocking Chinese style protest enforcement in a supposed western liberal-democracy.`,
      ],
      urls: [
        `https://twitter.com/TheRealKeean/status/1598674213690347521`
      ],
    },
    {
      description: [
        `Italian police stands down and stands in solidarity with protesters`,
      ],
      urls: [
        `https://twitter.com/betterworld_24/status/1442432313481121793`
      ],
    },
    {
      description: [
        `Jimmy Dore - French Protesters STORM BlackRock's Paris Headquarters`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=xqki_HI1ZRY`
      ],
    },
  ],
  GovernmentOverreach: [
    {
      description: [
        `Canada's Deputy Prime Minister, and World Economic Forum Young Global Leaders Alumni, Chrystia Freeland`,
        `Talks about controlling crowd funding websites and cryptocurrency under their <strong>Terrosist Financing Act</strong>.`,
        `They are not bringing in the army, but they are controlling transactions and freezing bank accounts.`,
      ],
      urls: [
        `https://twitter.com/nayibbukele/status/1493377382245441537`,
      ],
    },
    {
      description: [
      `Catherine Austin Fitts:`,
      `"If we allow, not just the mandates, but the vaccine passports, or digital IDs, or any part of what is considered to be the financial transaction control grid go into place, the combination of those systems together, much of which have been developed for the last thirty years, those systems together will combine into a control grid that can turn our home and our cars into a digital concentration camp."`,
      ],
      urls: [
      `https://twitter.com/backtolife_2022/status/1486073187481923586`,
      ],
    },
    {
      description: [
      `Head of the World Economic Forum Klaus Schwab at Harvard's John F. Kennedy School of Government in 2017:`,
      `“What we are very proud of, is that we penetrate the global cabinets of countries with our WEF Young Global Leaders… like Trudeau”`,
      `"he mentions Canada, Argentina and France as countries where they placed their pawns."`,
      ],
      urls: [
      `https://twitter.com/29Joujou/status/1486012900879781889`,
      ],
    },
    {
      description: [
      `New Brunswick Education Minister says children in school are <strong>the province's children.</strong>`,
      ],
      urls: [
      `https://twitter.com/ChickenGate/status/1431303976885096451`,
      ],
    },
    {
      description: [
      `San Diego woman on covid tyrrany. Audra Morgan delivers a supercharged message to the San Diego County, California, Board of Supervisors. She calls them out for supporting the Covid political agenda, ignoring the civil rights of citizens, and causing needless suffering and death.`,
      ],
      urls: [
      `https://www.bitchute.com/video/1BE7a83NGPNw/`,
      ],
    },
    {
      description: [
      `Parents of school children called “domestic terrorists”.`,
      `Parents Have Finally Had Enough, and Are Doing Something About It`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=Hph7tmLX0jE`,
      ],
    },
    {
      description: [
      `Mother loses parental rights because she's unvaccinated`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=mcCHnXcKE5s`,
      ],
    },
    {
      description: [
      `Kingston, Ontario Public Health Class order.`,
      `“Persons responsible for hosting social gatherings in private dwellings shall maintain a list of names and contact information for guests and, upon request, provide Kingston, Frontenac, and Lennox & Addington (KFL&A) Public Health with this information within 24 hours of the request, or other time specified, for the purposes of COVID-19 case and contact tracing.”`,
      `This is the Ontario government encouraging households and individuals to report private information about each other to the government.`,
      `When has this happened before in history?`,
      ],
      urls: [
      `https://www.kflaph.ca/Modules/News/index.aspx?feedId=f2a4adbc-2838-4b5d-a47c-bd9c8ef4ee2e&newsId=8ce0560d-2e9a-4673-a1c7-2ab5cd518b69`,
      ],
    },
    {
      description: [
      `Arizona Attourney General Mark Brnovich asks reporter about their STD status after COVID vaccine question`,
      `“Where does it stop? At my health information”`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=v0DW9m0q6sI`,
      ],
    },
    {
      description: [
      `Prince Charles claims a <strong>"vast military-style campaign" is required to marshal a "fundamental economic transition."</strong>`,
      ],
      urls: [
      `https://twitter.com/MaximeBernier/status/1455319251355590656`,
      ],
    },
    {
      description: [
      `Over 500 doctors penned letter to and against Victoria's Dan Andrews.`,
      ],
      urls: [
      `https://www.bitchute.com/video/G5d9yeNH6EVw/`,
      ],
    },
    {
      description: [
        `Dr. David Martin on Why Trudeau Won't Back Down`,
        `<strong>"This never was about injecting people for their health. This was about maintaining an illegal monopoly, and the reason we're calling it an illegal monopoly is because it's price fixing.</strong>`,
        `One of the reasons we have anti-monopoly laws is that it is illegal to set a price where you manage what is supposed to be competition. And the fact of the matter is, the two companies derived from the University of British Columbia's research, that developed the lipid nanoparticle which makes mRNA work, those companies both received the technology from Canada. Without Canada there is no injection, and the fact of the matter is they get paid from both cookie jars.`,
        `That was illegal in the turn of the last century in the United States, and it is illegal anywhere where you have a monopoly control of the global market.`,
        `Right now, courts are meeting on both sides of the border, to enforce his [Trudeau's] despotic commands, as well as provincial commands, to try to reign in these people who are peacefully protesting.`,
        `... but the fact of the matter is, this is felony violations of the law, this is domestic coersion, which is a felony, and any law enforcement, the royal canadian mounted police, any administrative fork anywhere in Canada can arrest him. He is actually comitting crimes."`
      ],
      urls: [
        `https://rumble.com/vupkuv-breaking-criminal-canadian-monopoly-dr.-david-martin-exposes-why-trudeau-wo.html`,
      ],
    },
    {
      description: [
        `Mom Confronts Superintendent with Her Own Lies on Mask Rule`,
        `"Unfortunately politics leads us to believe there is only one solution: masks.`,
        `Yet thousands of schools around the country have been open during the entire pandemic without masks, and <strong>no corresponding rise in serious illness.</strong>`,
        `There were no child coffins lined up, as some educators in this county suggested would be the case.`,
        `We are segregating children by vaccination status, and by religious exemption status, <strong>despite the wearing of masks.</strong>`,
        `So I ask you, if masks work, why don't they?"`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=2KuuyI1OWhk`,
      ],
    },
    {
      description: [
        `<strong>Could a "Morality Pill" Help Stop the Covid-19 Pandemic?</strong>`,
      ],
      urls: [
        `https://twitter.com/FromKalen/status/1490591765270728704`
      ],
    },
    {
      description: [
        `Klaus Schwab's World Economic Forum says "the COVID 19 pandemic has led to a heightened focus on the power of medical data, specifically so-called vaccine passports.`,
        `<strong>These passports by nature serve as a form of digital identity."</strong>`,
      ],
      urls: [
        `https://twitter.com/disclosetv/status/1493901389797408768`,
        `https://www3.weforum.org/docs/WEF_Advancing_towards_Digital_Agency_2022.pdf`,
      ],
    },
    {
      description: [
        `<strong>"We have to break through this idea that kids belong to their parents, or kids belong to their families".</strong>`,
        `PSA straight out of a socialist dreamworld.`
      ],
      urls: [
        `https://twitter.com/aimeeterese/status/1484204872480489473`
      ],
    },
    {
      description: [
        `Simple meme showing parallels of our current situation to a PSA for abusive relationships.`,
      ],
      urls: [
        `https://twitter.com/yanshufkadesh/status/1482440266942500867/photo/1`
      ],
    },
    {
      description: [
        `Re: the short lived but terrifying and unacceptable Quebec unvaccinated tax.`,
        `Quebec Dictator Legault Imposing TAX on the Unvaccinated? Viva Frei Vlawg`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=V9qR4W62TKs`
      ],
    },
    {
      description: [
        `<strong>"Life will become increasingly uncomfortable and more difficult" for those who reject COVID vaccines."</strong>`,
        `New Brunswick Premier Blaine Higgs`
      ],
      urls: [
        `https://twitter.com/RebelNewsOnline/status/1481746669679136772`
      ],
    },
    {
      description: [
        `<b>Ontario's top doctor advises against seeing your triple vaccinated grandparent if you're double vaccinated.</b>`,
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1471958495171948548`
      ],
    },
    {
      description: [
        `<b>Canada's parliament is already working on plans for future lockdowns.</b>`,
        `Chrystia Freeland: "Programs like Local Lockdown Support & the 🇨🇦 Worker Lockdown Benefit will help in the event of a new lockdown."`
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1471271221580873730`
      ],
    },
    {
      description: [
        `Will it take more than two shots to be considered fully vaccinated?`,
        `Canada's new Health Minister: "Probably...almost certainly."`,
      ],
      urls: [
        `https://twitter.com/Roman_Baber/status/1468561576840024071`
      ],
    },
    {
      description: [
        `Fauci: "I would prefer, and we all would prefer that people would be voluntarily getting vaccinated, but if they're not gonna do that, sometimes you've got to do things that are unpopular, but that clearly supersede individual choices..."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1468635333961175040`
      ],
    },
    {
      description: [
        `Shortly after Austria became first country to make COVID vaccines compulsory, EU chief Ursula von Der Leyen calls for dispensing with the Nuremberg Code and making vaccination mandatory across Europe.`,
        `Here's Ursula last month with Pfizer CEO Albert Bourla`,
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1466150597422321671`,
        `https://www.bbc.com/news/world-europe-59497462`,
      ],
    },
    {
      description: [
        `Austria: failure to get vaccinated results in penalities. Failure to pay penalties results in prison sentences.`,
        `Translation: get vaccinated, get bankrupt, or go to prison.`,
      ],
      urls: [
        `https://twitter.com/20LinksUnderSea/status/1468283162761568262/photo/1`
      ],
    },
    {
      description: [
        `They Demanded My “Papers” Today`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=nDZ-qWPVn3k`
      ],
    },
    {
      description: [
        `No Exceptions: A Story of Medical Tyranny in Australia`,
        `Bret Weinstein's Dark Horse Podcast`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=-qA0wZD0iPw`
      ],
    },
    {
      description: [
        `Thought The Pandemic Was BAD?! This Is What's NEXT! - Russell Brand`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=UG23kbdYm3o`
      ],
    },
    {
      description: [
        `Do you trust the government, who admits to spying on you, with your mental issues in an app?`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=qQHenUdsghU`
      ],
    },
    {
      description: [
        `Horrific goings on in West Australia as rules enforced this week <b>banning parents from visiting their own sick children in hospital</b> if they haven't been injected with the experimental covid jab!`,
      ],
      urls: [
        `https://gettr.com/post/prwcwv28af`
      ],
    },
    {
      description: [
        `WHO treaty to override a countries constitution in future “PANDEMICS”`,
        `WHO Constitution will take precedence over a country's constitution during natural disasters or pandemics.`,
        `This would make their “guidelines” mandatory to the public of all countries who sign up.`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1495876338523197445`
      ],
    },
    {
      description: [
        `Mayor of Ottawa suggests selling vehicles confiscated from freedom protesters`,
      ],
      urls: [
        `https://thepostmillennial.com/mayor-of-ottawa-suggests-selling-vehicles-confiscated-from-freedom-protesters`
      ],
    },
    {
      description: [
        `Frightening Resurfaced World Economic Forum Clip- We Are One Of The Last Generations Of Homo Sapiens`,
      ],
      urls: [
        `https://www.bitchute.com/video/ktXFZvGHiFr7/`
      ],
    },
    {
      description: [
        `Chinese style social credit system under the guide of Universal Basic Income - National Standard of Health Framework - mandatory vaccinations in Canada - trying to pass with`,
        `<b>BILL S-233</b>`,
        `<b>Red Alert Canada.`
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1497696527648706565`
      ],
    },
    {
      description: [
        `FOR WHOM THE BELL TOLLS Neil`,
      ],
      urls: [
        `https://twitter.com/BigBluexlt/status/1497668511799427076`
      ],
    },
    {
      description: [
        `Is Klaus Schwab the Most Dangerous Man in the World?`,
        `A look into how deeply rooted in global technology and government the World Economic Forum is.`
      ],
      urls: [
        `https://rumble.com/vwghvi-is-klaus-schwab-the-most-dangerous-man-in-the-world.html`
      ],
    },
    {
      description: [
        `Warning: Bill 67 - Jordan Peterson`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=iwQy-MVP4u0`
      ],
    },
    {
      description: [
        `Australia. It was for their own safety.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1508397826564313094`
      ],
    },
    {
      description: [
        `“Nobody will be safe, if not everybody is vaccinated...” says Klaus Schwab`,
      ],
      urls: [
        `https://twitter.com/GillianMcKeith/status/1512369253076324352`
      ],
    },
    {
      description: [
        `“You use lockdowns to get people vaccinated.” -Fauci`,
        `Right. Not because there's any science to justify them. To make people's lives miserable so they get vaccinated. Criminal.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1515005933587423242`
      ],
    },
    {
      description: [
        `Ottawa faces blowback for plan to regulate internet`,
        `<b>Newly released documents reveal Twitter Canada told government officials that a federal plan to create a new internet regulator with the power to block specific websites is comparable to drastic actions used in authoritarian countries like China, North Korea and Iran.</b>`,
      ],
      urls: [
        `https://www.theglobeandmail.com/politics/article-twitter-compared-liberal-governments-online-harms-plan-to-china-north/`
      ],
    },
    {
      description: [
        `World Economic Forum: You'll own nothing and you'll be happy.`,
      ],
      urls: [
        `https://www.bitchute.com/video/9IK8CBTqnMoQ/`
      ],
    },
    {
      description: [
        `Dr. Robert Malone:`,
        `I really feel that this covid situation is just a skirmish, and the bigger battle is against censorship and control, and a growing global totalitarianism.`
      ],
      urls: [
        `https://twitter.com/NewsNancy9/status/1522957675528556545`
      ],
    },
    {
      description: [
        `<b>Ukranians are exempt from Canada's COVID-19 vaccination entry requirements</b>`,
        `Canada-Ukraine Authorization for Emergency Travel: Government of Canada`,
      ],
      urls: [
        `https://www.canada.ca/en/immigration-refugees-citizenship/news/2022/03/canada-ukraine-authorization-for-emergency-travel.html`
      ],
    },
    {
      description: [
        `Klaus Schwab - "We have the means to impose the state of the world"`,
        `This is some megalomaniac supervillain level speech.`,
      ],
      urls: [
        `https://twitter.com/KevinForBOS/status/1528769489898246151`
      ],
    },
    {
      description: [
        `Alibaba Group president J. Michael Evans boasts at the World Economic Forum about the development of an "individual carbon footprint tracker" to monitor what you buy, what you eat, and where/how you travel.`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1529045188764921856`
      ],
    },
    {
      description: [
        `"Imagine the compliance."`,
        `WEF is EVIL - 5 minutes`
      ],
      urls: [
        `https://www.youtube.com/watch?v=w04LjNjPLso`
      ],
    },
    {
      description: [
        `On what scientific basis do Liberals forbid the unvaccinated to travel when their chief public health officer admits there is no evidence of virus transmission in planes? Are nastiness and vindictiveness a scientific explanation?`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1530947307616800768`
      ],
    },
    {
      description: [
        `New Zealand’s PM Jacinda Ardern stating only government information can be trusted.`,
        `Right out of 1984.`,
        `“Dismiss anything else, we will continue to be your single source of truth”`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1415550405728161794`
      ],
    },
    {
      description: [
        `“The difference of this Fourth Industrial Revolution is that it doesn't change what you are doing…it changes YOU…if you take the "Genetic-editing"…`,
        `-Klaus Schwab`,
      ],
      urls: [
        `https://twitter.com/liz_churchill_/status/1538182860925980675`
      ],
    },
    {
      description: [
        `<b>“We need a Single Global Order”</b>`,
        `Are you on the US or the Chinese side?`,
        `-Emmanuel Macron, November 2022`,
      ],
      urls: [
        `https://twitter.com/liz_churchill7/status/1594086309068406788`
      ],
    },
    {
      description: [
        `Miller: So the purpose [of the regulations the federal government put into place] was to encourage Canadians  vaccinated? To compel them to get vaccinated? Is that fair?`,
        `Freeland: Thats right.`,
      ],
      urls: [
        `https://twitter.com/RealAndyLeeShow/status/1595850779445059584`
      ],
    },
    {
      description: [
        `George Soros: "Covid-19 also helped legitimize instruments of control"`,
      ],
      urls: [
        `https://twitter.com/WallStreetSilv/status/1594918279801602049`
      ],
    },
    {
      description: [
        `Klaus Schwab: "China is a role model for many countries."`,
      ],
      urls: [
        `https://twitter.com/SebGorka/status/1595742330933215232`
      ],
    },
    {
      description: [
        `<b>Are We Ready for a New World Order?: World Government Summit (2022)</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=CDDlWKSQvS8`
      ],
    },
    {
      description: [
        `<b>William Makis states that the College of Physicians and Surgeons of Ontario 🇨🇦 is suggesting to Ontario doctors that "unvaccinated" people are mentally ill and in need of psychiatric medication.</b>`,
        `<b>Makis: "That is a very dangerous slippery slope that we're on."</b>`,
        `"So this has come out recently out of the College of Physicians and Surgeons of Ontario.`,
        `The College sent out a letter or a memo to all the doctors in Ontario, suggesting to them.. now so far they're not "mandating" it, they're just "suggesting" it.. that any of their unvaccinated patients, that they should consider that <b>they have a mental problem, and that they should be put on psychiatric medication.</b>`,
        `So far it's just a suggestion, but the College of Physicians and Surgeons of Ontario should not be making these kinds of suggestions, this is extremely unethical, and this is a very, very slippery slope, if they're suggesting that people who wish to have bodily automony, and don't want an experimental vaccine, that there may be something mentally wrong with them? That is a very very dangerous, slippery slope that we're on."`
      ],
      urls: [
        `https://twitter.com/PaulMitchell_AB/status/1593890035388284928`
      ],
    },
    {
      description: [
        `Klaus Schwab says the word won't be run by superpowers such as the US/China… it will be run by WEF stakeholders such as BlackRock/Bill Gates.`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1584344875964588033`
      ],
    },
    {
      description: [
        `WEF's Yuval Noah Harari “You will have a small elite that'll makes all the decisions even if it doesn't benefit the majority of the population…. If bad comes to worst, scientists will be the Noah's Ark for the elite… leaving the rest to drown"`,
      ],
      urls: [
        `https://twitter.com/Resist_05/status/1585080574712373250`
      ],
    },
    {
      description: [
        `<b>Bill Gates talks about "Death Panels"</b>`,
        `"You know, is spending a million dollars on that last three months of life for that patient, would it be better not to lay off those ten teachers, and, the, make that tradeoff in medical costs?`,
        `But that's called the "Death Panel", uh, and you're not supposed to have that discussion..`,
        `Society is making, we're making that tradeoff, because of huge medical costs that are not examined to see which ones actually have no benefit whatsoever, and because of pension generosity, we will be laying off over 100,000 teachers, which, you know, I'm very much against that, uh, and the whole AFT will agree with me on that."`
      ],
      urls: [
        `https://www.bitchute.com/video/7SprNopB0i1o/`
      ],
    },
    {
      description: [
        `<b>Klaus Schwab:</b>`,
        `<b>“The Chinese model is certainly a very attractive model for a number of countries”</b>`,
        `..from the very man who adorns his home with a statue of Lenin.`,
        `---`,
        `Reporter: From the G20, you were there meeting some of the leaders as well, Professer Schwab, what do you make of the result, finally that put something as a statement, and it seems quite positive with all the voices included.`,
        `Klaus Schwab: I think it's positive, it's already positive through the fact that everybody agreed about the statement, which we haven't had the last years.`,
        `<b>Now the base has been formed, but we have to go one step further.</b>`,
        `We have to have a strategic move. We have to construct the world of tomorrow. It's a systemic transformation of the world. So we have to define how the world should look like, which we want to come out of this transformation period.`,
        `<b>I respect China's achievements, which are tremendous, over the last over 40 years. I think it's a role model for many countries,</b> but I think also we should leave it to each country to make its own decision what system it wants to adopt, and I think we should be very careful in imposing these systems, <b>but the Chinese model is certainly a very attractive model for quite a number of countries."</b>`,
      ],
      urls: [
        `https://twitter.com/org_scp/status/1595183581713907718`
      ],
    },
    {
      description: [
        `<b>If you think covid was brutal…just wait until the WHO Treaty is signed…you will have 48 hours to comply with Dr. Tedros</b>`,
        `Dr. Robert Malone:`,
        `"Let me go over the key points: why you should care.`,
        `This this is-- remember. This is proposed by the Biden administration. These clauses are proposed by our executive branch, by our president.`,
        `First off, increase surveillance:`,
        `Under article 5, the WHO will develop early warning criteria that will allow it to establish a risk assessment for a member state, which means it can use the type of modeling, simulation and predictions that exaggerated the risk from covid19 over two years ago.`,
        `Once the WHO creates an assessment, unilaterally, it will communicate it to intergovernmental organizations and other member states. They will then have a 48 hour deadline.. This is how this is going to work..`,
        `Within 48 hours, and really less, they will have to make a "go/no go" decision about complying with the mandate, which is entirely under the control of Tedros, the Director General of the WHO World Health Organization, has the authority, under this statute, to circumvent the US Constitution and to activate a dominant role over the US Constitution, by the Constitution of the World Health Organization, and he can do this unilaterally.`,
        `<b>Remember, the two major donors to the World Health Organization currently, are the CCP, Communist China, Bill and Melinda Gates Foundation, Bill Gates. Bill Gates is placing $250,000,000 into the WHO. Those two control the WHO, full stop.</b>`,
        `What this bill does, is it hands over control of the American Contitution and circumvent states rights, this is really a power play by the executive branch to create a pathway, whereby they can get the WHO to do their bidding and to overturn the states rights.`,
      ],
      urls: [
        `https://twitter.com/liz_churchill8/status/1629926611968569345`
      ],
    },
    {
      description: [
        `<b>Depopulation is the solution to everything according to the World Economic Forum (WEF)</b>`,
        `<b>Jane Goodall speaking on a WEF stage:</b>`,
        `We cannot hide away from human population growth, because, you know, it underlies so many of the other problems.`,
        `All these things we talk about wouldn't be a problem if there was the size of population that there was 500 years ago.`,
      ],
      urls: [
        `https://twitter.com/DrLoupis/status/1637870859728695296`
      ],
    },
    {
      description: [
        `Albert Burla, President and CEO of Pfizer, said this recently at the Economics Club of Chicago:`,
        `“I really believe that better days are ahead because covid was, for me, like a rehearsal"...`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1632843738128220160`
      ],
    },
    {
      description: [
        `Australian news in 2020 - officially declares "New World Order"`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1632962997865029633`
      ],
    },
    {
      description: [
        `One week after his leaked text message, <b>UK Health Minister Matt Hancock spreads fear on national television as he "deploys the new variant" and "frightens the pants out of everyone."</b>`,
        `"The new variant is out of control... It's important for everybody to act like they might have the virus... The cases, as you've seen from the graphs, have absolutely rocketed in the last few days... Given how much faster this new variant spreads, it will be very difficult to keep it under control until we have the vaccine rolled out."`,
        `---`,
        `Leaked messages show UK Health Minister Matt Hancock planning to “deploy the new variant” in COVID messaging to “frighten the pants out of everyone” in order to “get proper behavior change” in the public's compliance with lockdown measures.`,
      ],
      urls: [
        `https://twitter.com/KanekoaTheGreat/status/1632244896659800064`,
        `https://twitter.com/MichaelPSenger/status/1632150149811273728`,
      ],
    },
    {
      description: [
        `UN Secretary-General: "We'll call for action from everywhere with influence on the spread of 'mis- and disinformation' on the Internet."`,
      ],
      urls: [
        `https://twitter.com/disclosetv/status/1622622594468806657`
      ],
    },
    {
      description: [
        `<b>Livid woman who lost her father to Remdesivir and malnutrition in the hospital yells:</b>`,
        `So, anybody heard about Kristin the Piston? Anybody heard about how my father was murdered with Remdesivir? Has anybody heard about how the hospital called me and blamed me and my mom because we weren't vaccinated, and that was the reason he died?`,
        `Anybody heard about how they put my dad on paliative care, and they never fed him, and they starved him to death? And they only gave him one can of Ensure in nine days? Anybody hear about that?`,
        `Anybody hear about how they put my dad in bilateral restraints, restraining him against his will, and popped him full of Remdesivir, against his and his family's wishes? While they called me and blamed me and told me he was dying because I wouldn't take the vaccine? Has anyone heard this story? Anyone? You heard this story yet?`,
        `You heard about how my dad was murdered in a worse way than people on death row? Do you hear me? People on death row are treated more humanely than my father. He wasn't able to use a goddamn phone. Are you listening to me?`,
        `People want me to act all nice about this shit man, fuck you! Fuck you. This shit is bullshit. They murdered my father, they injected him with poison, they lied to me, they blamed me. You want me to act normal?`,
      ],
      urls: [
        `https://twitter.com/BohemianAtmosp1/status/1623082067083894785`
      ],
    },
    {
      description: [
        `<b>The President of the European Research Council, @mleptin, in front of a World Economic Forum (WEF) backdrop, declared that efforts to vaccinate the whole population shouldn't be approached as a scientific endeavor, but as warfare.</b>`,
        `<b>"So, no science. Let's remember that."</b>`,
        `Perhaps interestingly, two of the countries which were most successful in getting good coverage of vaccination, based this not at all on getting their citizens to try and understand the science. One is Bhutan, which where they were very successful in preparing a campaign, and involved and were senstitive to the country's needs, the citizens' needs, involved, informing the religious establishment, and in fact using them, in finding the right time and day. And they got fantastic coverage No science was explained.`,
        `The other example I know of is Portugal, where the campaign was handed a retired army general. And the army general just treated the country as his troops and he "rallied the troops".`,
        `<b>He declared it as a "war" that the country, in patriotic passion, was going to fight together.</b> And they had up there, I think they were leading Europe if not the world.`,
        `So.. No science.`,
      ],
      urls: [
        `https://twitter.com/goddeketal/status/1623058597797335040`
      ],
    },
    {
      description: [
        `CDC Medical Officer Dr David Berglund discussing TRACKING the unjabbed:`,
        `“There has been interest expressed in being able to TRACK PEOPLE who are NOT IMMUNISED OR PARTIALLY IMMUNISED”`,
      ],
      urls: [
        `https://twitter.com/MaajidNawaz/status/1622184853163708419`
      ],
    },
    {
      description: [
        `"The biggest question in economics and politics of the coming decade will be, what to do with all these useless people..."`,
        `- Yuval Harari, Israeli Professor and WEF advisor`,
      ],
      urls: [
        `https://twitter.com/goddeketal/status/1621944170255421442`
      ],
    },
    {
      description: [
        `Theresa Tam and Mrs. Claus advertise vaccines for children and want you to get vaccinated and wear a mask at your indoor gatherings.`,
      ],
      urls: [
        `https://twitter.com/AndrewLawton/status/1605754986549215235`
      ],
    },
    {
      description: [
        `New Zealand's new PM who said he wants to go out and find you if you haven't been vaccinated`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1616567498177904642`
      ],
    },
    {
      description: [
        `<b>Elites planning to weaponize water.</b>`,
        `<b>DAVOS 2022 - Economist Mariana Mazzucato declared that the failure to vaccinate the entire world population against covid should lead to a review of the way of imposing the notion of common interest: it would thus be wiser according to her to refer to rather a water crisis</b>`,
        `So of course true with covid, right, we are all only as healthy as our neighbours, and our street, and our city, and our region, and our nation, and globally, and did we solve that? Like did we actually manage to vaccinate everyone in the world? No.`,
        `So highlighting water as a global commons and what it means to work together and see it both out of that kind of global commons perspective, but also to the self-interest perspective, because it is, it does have that parallel.. it's not only important but it's also important because we haven't managed to solve those problems which had similar attributes, and water is something that people understand.`,
        `You know, <b>climate change is a bit abstract,</b> some people understand it really well, some understand it a bit, some just don't understand it.. <b>Water: every kid knows how important it is to have water..</b> when you're playing football and you're thirsty, you need water.`,
        `So there's also something about really getting citizen engagement around this and really, in some ways, experimenting, with this notion of the common good, can we actually deliver this time in ways that we have failed miserably other times? And hopefully we won't keep failing on the other things, but anyway.`,
      ],
      urls: [
        `https://twitter.com/Xx17965797N/status/1642433788872458241`
      ],
    },
    {
      description: [
        `World Economic Forum speaker encourages people to stop eating meat.`,
        `<b>"if a billion people stop eating meat"</b>`,
      ],
      urls: [
        `https://twitter.com/WallStreetSilv/status/1616299228803858436`
      ],
    },
    {
      description: [
        `Project Veritas:`,
        `Feb 2022: Chris Cole @US_FDA Exec reveals corrupt business practices with Big Pharma`,
        `"There's a money incentive for Pfizer & the drug companies to promote additional vaccinations"`,
        `"It'll be reoccurring fountain of revenue"`,
        `"They pay us hundreds of millions of dollars a year"`,
      ],
      urls: [
        `https://twitter.com/Project_Veritas/status/1613981709539704832`
      ],
    },
    {
      description: [
        `<b>Former UK Prime Minister Tony Blair Calls For A Digital Database To Monitor Who Is Vaccinated And Who Is Not For A Future “Pandemic” During Live Broadcast At Klaus Schwab's World Economic Forum</b>`,
        `You need to know who's been vaccinated and who hasn't been. Some of the vaccines that will come down the line will be multiple shots, so you've got to have, for reasons to do with healthcare more generally, but certainly for a pandemic or for vaccines, <b>you've got to have a proper digital infrastructure,</b> and many countries don't have that, in fact most countries don't have that.`,
      ],
      urls: [
        `https://twitter.com/healthbyjames/status/1616150104074747915`
      ],
    },
    {
      description: [
        `<b>Klaus Schwab vows the world will be run by "stakeholders" not countries:</b>`,
        `"What we are seeing is a merger between the systems of the West and the CCP..."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1616441180291584001`
      ],
    },
    {
      description: [
        `This is the most Orwellian thing I've ever seen.`,
        `Klaus Schwab: “History is at a turning point. We do know that global energy systems, food systems and supply chains will be deeply affected”`,
      ],
      urls: [
        `https://twitter.com/ALilyBit/status/1612833728396509186`
      ],
    },
    {
      description: [
        `<b>"Klaus Schwab - November 14, 2022: We are intentionally causing chaos internationally in order to reach the global technocratic governance that we want. Peasants be damned"</b>`,
        `Of course, if you look at all the challenges, we can speak about the multi-crisis: economic, political, social, ecological, and institutional crisis.`,
        `But actually what we have to confront is a deep, systemic and structural restructuring of our world. And this will take some time.`,
        `<b>And the world will look differently after we have gone through this transition process.</b>`,
        `Politically, the driving forces for this political transformation, of course, is the transition into a multi-polar world which has a tendency to make our world much more fragmented.`,
        `For these reasons, events like this one, the G20 and so on, are the very important connectors to avoid a too-great segmentation.`,
      ],
      urls: [
        `https://twitter.com/listen_2learn/status/1605272527340707871`
      ],
    },
    {
      description: [
        `Trudeau: "There are some guns, yes, that we're going to have to take away from people who were using them to hunt."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1605620141047283720`,
        `https://thepostmillennial.com/trudeau-will-ban-some-hunting-guns-that-are-too-dangerous-in-other-contexts`,
      ],
    },
    {
      description: [
        `<b>British MP Andrew Bridgen: "Pfizer told me unequivocally, you will be having to take these vaccines every 3 months for the rest of your life"</b>`,
        `British MP Andrew Bridgen called a debate in the House of Commons this week, stating that he believes that the Covid vaccines are causing significant damage to many people who are taking them. In his view, the risks of damage from the vaccine outweigh significantly any good they might be doing.`,
        `In this special interview, The Rev'd Dr. Jamie Franklin speaks with Andrew about what brought him to this viewpoint, the salient points of his argument and the evidence in its favour, the lack of interest from the corporate media, and the ethics of vaccinating children including babies as young as six-months old (which has now been approved by the UK's MHRA).`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1605380847481372672`,
        `https://rumble.com/v214gw1-special-interview-with-british-mp-andrew-bridgen.html`,
      ],
    },
    {
      description: [
        `“They're being placed in metal boxes…they're being treated worse than Prisoners…” -Modern day "Chinese Ghettos" praised by the World Economic Forum`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1602162641639997442`
      ],
    },
    {
      description: [
        `HEAR for yourself! Pfizer's CEO #Bourla discusses with great pride at #WEF with #KlausSchab that their goal of reducing the population by 50% set in Jan of 2019 he felt was achievable by 2023! #WEF member audience with glee!`,
      ],
      urls: [
        `https://twitter.com/Tweet4USA123/status/1601607612537401346`
      ],
    },
    {
      description: [
        `<b>WEF Wants To Slaughter Millions of Pet Cats and Dogs To Fight Climate Change</b>`,
        `The World Economic Forum is now calling for millions of cats and dogs worldwide to be slaughtered in an effort to reduce the “carbon pawprint” they produce as a result of eating meat.`,
        `The WEF, which has ordered mainstream media outlets to begin pushing the narrative, wants to introduce an international policy that would require the majority of pet owners to euthanize their animals.`,
      ],
      urls: [
        `https://rumble.com/v1zvd5y-wef-wants-to-slaughter-millions-of-pet-cats-and-dogs-to-fight-climate-chang.html`
      ],
    },
    {
      description: [
        `NEW ZEALAND- The State owns your children now. The judge has ruled in favour of health authorities & have given them guardianship of a baby boy, who's parents simply wanted unvaccinated blood for a transfusion. Even though the blood was available. State control is absolute.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1600400220558196736`,
      ],
    },
    {
      description: [
        `Canterbury City Council is proposing to divide the city into five different districts, with drivers unable to cross between zones without being fined. So much for freedom of movement. Instead this is another step towards petty authoritarianism.`,
        `<b>Controversial plans to stop cars travelling between neighbourhoods in Canterbury press ahead despite being branded a 'draconian ban on free movement'</b>`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1600076657615699968`,
        `https://www.dailymail.co.uk/news/article-11352725/Controversial-plans-stop-cars-travelling-neighbourhoods-Canterbury-press-ahead.html`,
      ],
    },
    {
      description: [
        `<b>“What people will remember from the Covid Crisis is that we agreed to be surveilled… all the time… and most importantly… this is when surveillance… went under the skin…” -Unelected Eugenicist, Yuval Harari - lead advisor to Klaus Schwab</b>`,
        `I think maybe in a couple of decades when people look back, the thing they will remember from the covid crisis is, this is the moment when everything went digital, and this was the moment when everything became monitored.`,
        `That we agreed to be surveilled, all the time, not just in authoritarian regimes, but even in democracies, and maybe most importantly, we started going under the skin.`,
        `I think the big process that's happening right now in the world is "hacking human beings", we have the ability to hack humans, to understand deeply what's happening within you, what makes you go.`,
        `And for that, the most important data is not what you read and who you meet and what you buy, it's what is happening inside your body.`,
        `So we had these two big revolutions, the science revolution or the info-tech revolution, and the revolution in the biological sciences, <b>and they're still separated, but they're about to merge.</b>`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1599924213875458050`
      ],
    },
    {
      description: [
        `Netherlands has announced they will SEIZE 3000 farms in order to meet the 2030 climate goals.`,
        `Farmers will be FORCED to sell the land that they have owned for generations to the state.`,
        `This is Climate Communism.`,
      ],
      urls: [
        `https://twitter.com/PeterSweden7/status/1599065567167582208`
      ],
    },
    {
      description: [
        `<b>There's always a scene in a James Bond movie where the megalomaniac villain gives a speech outlining their sinister plans for global domination.</b>`,
        `Can you imagine that in ten years when we are sitting here and we have an implant in our brains, and I can immediately feel, because you will all have implants, I can measure your brainwaves and I can immediately tell you how the people react, or I can feel how the people react to you, is it imaginable?`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1598608073974611968`,
        `https://twitter.com/realstewpeters/status/1646918883511611392`,
      ],
    },
    {
      description: [
        `Forced injection in China.`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1598532846188109824`
      ],
    },
    {
      description: [
        `CANADA - CCP playbook.`,
        `Public health Canada confirmed, it secretly accessed location data for 33 million mobile devices (87% of the population) to spy on their movements.`,
        `how very erm… China ish`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1598258257910431745`
      ],
    },
    {
      description: [
        `<b>Munk Debates - Nov 30, 2022: Douglas Murray's eloquent takedown of Canadian legacy media.</b>`,
        `Your Prime Minister decided in advance that these people were, all the modern excommunications, they were nazis, they were white supremacists, they were anti-semites, they were probably homophobes, they were misogynists, they were probably transphobes, etc etc etc, in all the things you do in the modern political age, you just want to defenestrate somebody who's awkward to you.`,
        `And then he brings in the Emergency Powers Act, now.. at such a time, what would the mainstream media do? It would question it. It would question it. The Canadian mainstream media did not. The Canadian mainstream media acted as an arm and chorus of the Canadian government.`,
        `I will give you a couple of examples, but ladies and gentlemen I could go on for hours with examples of this.`,
        `You had a CBC host describing the freedom convoy as quote, "a feral mob".`,
        `You had a Toronto Star columnist saying, quote, sorry for the language, "it's a homegrown hate farm that was then jet fuelled by an American right wing funded rat fucking operation." Jesus, they can't even write at these papers anymore..`,
        `CBC said that 2 indigenous women were so scared to go outside in Ottawa because of racist violence, didn't bother to mention that indigenous drummers had led the truckers in an O'Canada rendition.`,
        `The National Observer said that the many black and indigenous freedom convoy supporters were in fact, duped by the truckers.`,
        `The Globe and Mail reporter said, "my 13 year old son told me to tell protesters I'm not a Jew, after fear of anti-semetic violence," without mentioning that one of the leaders of the convoy was himself Jewish.`,
        `Now, why is this so rancid? Utterly utterly rancid and corrupt?`,
        `Because in this country, your media, your mainstream media is funded by the government. A totally corrupted system.`,
        `In 2018, oh an election year what a coincidence, the Canadian media has given $595 million over 5 years. The Toronto Star estimated it was going to be getting $3 million from the government in the first half of the year. It went on and on.`,
        `See, the government in Canada can tell people to, they can tell the banks to shut down peoples' bank accounts. Oh yeah. Your government can do that and if you're happy with that, just think about what would happen if the shoe was on the other foot. The government can do that, but in Canada, they can also tell the media what to do. And the media does the bidding of the Canadian government. That isn't a free society's media. I've seen free countries all my life, but this, in a developed, liberal democracy like Canada is a disgrace.`,
        `We're not saying don't read the mainstream media, we're just saying don't trust them.`,
      ],
      urls: [
        `https://twitter.com/WeAreCanProud/status/1598334823591866369`
      ],
    },
    {
      description: [
        `CCP Covid troops known as Big Whites are launching a raid in Guangzhou`,
      ],
      urls: [
        `https://twitter.com/JackPosobiec/status/1597948908931452928`
      ],
    },
    {
      description: [
        `World Government Summit Panel Discusses the 'Shock' Needed for the World Order Transformation`,
        `"To me the big question is how are we going to go through this transformation...it cannot be gradual, it has to be driven by a certain shock that will happen"`,
      ],
      urls: [
        `https://twitter.com/TheChiefNerd/status/1626024751922642945`
      ],
    },
    {
      description: [
        `<b>"Who masters those technologies, in some way, will be the master of the world."</b>`,
        `~ Klaus Schwab (World Government Summit 2023)`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1625672137800396800`
      ],
    },
    {
      description: [
        `Elon Musk speaks out against the idea of a “World Government” at the “World Government Summit” and warns it could lead to civilizational collapse.`,
      ],
      urls: [
        `https://twitter.com/alx/status/1625732016896458755`
      ],
    },
    {
      description: [
        `Jacinda Ardern, New Zealand Prime Minister:`,
        `Questions about "what do we do if someone refuses to be tested?"`,
        `Well you can't now.`,
        `If someone refuses in our facilities to be tested, they have to keep staying.`,
        `So they won't be able to leave after 14 days, they have to stay on for another 14 days.`,
        `<b>So it's a pretty good incentive. You either get your tests done and make sure you're clear, or we will keep you in a facility longer.</b>`,
        `So I think most people will look at that and say, "I'll take the, I'll take the test.`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1618193559152381952`
      ],
    },
    {
      description: [
        `Dr. Tedros just warned that Covid still needs to be taken seriously and that there's lots of dangerous misinformation being spread. He further declared that the next global crisis will be CLIMATE CHANGE.`,
        `Congrats, WHO, the transition is almost completed.`,
      ],
      urls: [
        `https://twitter.com/goddeketal/status/1617904750141526016`
      ],
    },
    {
      description: [
        `In Russia last year, 400 people were arrested for things that they said on social media. 400 people in Russia. Obviously this country is very different, how many people do you think were arrested in Britain for things they said on social media last year? Take a guess.`,
        `Interviewer: I have no idea.`,
        `J: 3,300.`,
        `I: Really? Arrested for what they said on social media? Really? What sort of things get you arrested?`,
        `J: Well one example I give on my show is there was a young woman from Liverpool called Chelsea Russell. Her friend was killed in a car crash, 19 year old woman, and she posted the lyrics of his favorite song on her Instagram, the lyrics. And it was a rap song. So the lyrics contain several instances of the n-word. She was arrested, prosecuted, found guilty, given 500 hours of community service and a fine, tagged, and for a year she was on the 8pm to 8am curfew.`,
        `I: My goodness. In Britain?`,
        `J: In Britain. In 2018`,
      ],
      urls: [
        `https://twitter.com/themaxstoic/status/1617542924023042048`
      ],
    },
    {
      description: [
        `Australian police, saving another citizen from Covid:`,
      ],
      urls: [
        `https://twitter.com/DrEliDavid/status/1440770631671300106`
      ],
    },
    {
      description: [
        `As of September 23, 2021 in Canada:`,
        `---`,
        `Coronavirus cases: 1,591,679`,
        `Deaths: 27,559`,
        `Recovered: 1,519,306`,
        `---`,
        `Population of Canada: 38,000,000`,
        `---`,
        `1.73% death rate OF 4.19% infection rate = 0.07% chance of dying from covid.`,
        `In other words:`,
        `Your chances of surviving covid are 99.93% AT ANY AGE OR WITH ANY COMORBIDITIES</b>`,
      ],
      urls: [
        `https://twitter.com/TheManFromAunt/status/1441230858053062656`
      ],
    },
    {
      description: [
        `Dr. Mahathir Mohamad - Former Prime Minister of Malaysia`,
        `<b>Malaysia's former Prime Minister, Dr. Mahathir Mohamad said, that the Trans-Pacific-Partnership or TPPA is a New World Order strategy by a powerful pack of people led by the US to dominate the world economy.</b>`,
        `<b>Speaking at the International Conference titled, the "New World Order" - a recipe for WAR or PEACE! (9 March 2015 - Putrajaya International Convention Centre) organized by the Putrajaya Global Peace Foundation, Dr. Mahathir said, globalization and borderless trade are being used to establish a one world government.</b>`,
        `Dr. Mahathir Mohamad:`,
        `Basically, it is about having a world government. We should abolish all states, all nations, all borders, and have a fully one world government.`,
        `And that world government is to be by seven people - elites. People who are very rich, very intelligent, very powerful in many ways. They are the ones who will govern the world.`,
        `There was not much talk about democracy, or choice of leaders, instead there was to be a government by these elites who will impose their rules on everyone in this world.`,
        `And for those who are unwilling to submit to them, there will be punishment.`,
        `And the peace that we will get from this, is the peace of the graveyard. Because the intention is also to reduce the number of people in this world.`,
        `At a time when a new world order was enunciated, the population of this world was only 3 billion. The intention was to reduce it to 1 billion. Now the population of the world is 7 billion.`,
        `There will be a need to kill many billions of people, or to starve them to death, or to prevent them from giving birth, in order to reduce the population of this world.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1442267383457058817`
      ],
    },
  ],
  GrandJury : [
    {
      description: [
        `<strong>Day 3 - PCR Test</strong> - If you watch only one, perhaps watch this one.`,
        `<strong>A professional disassembling of the lies we've been told internationally, on which the entirety of the last two years is reliant on.</strong>`,
        `Highly respected professionals in medicine describing in detail the misuse of the PCR test, misdiagnosis of the cause of serious illness, and not just deliberate promotion of toxic drugs known to cause kidney failure, but the purposeful witholding and demonization of drugs and treatments which in some cases possess a 100% success rate.`,
      ],
      urls: [
        `https://odysee.com/@GrandJury/Grand-Jury-Day-3-en-online`,
      ],
    },
    {
      description: [
        `<strong>Day 4 - Injections</strong> - All are important, but this one is also required listening.`,
        `Devastating testimony about the ineffectiveness and toxicity of the covid vaccine, and the catastophic potentials of what could happen in the future.`
      ],
      urls: [
        `https://odysee.com/@GrandJury/Grand-Jury-Day-4-online`
      ],
    },
    {
      description: [
        `Watch the rest of the Grand Jury proceedings:`,
        `<b>1. Opening Statements</b>`,
        `<b>2. Historical and Geopolitical Backdrop</b>`,
        `<b>3. PCR Test</b>`,
        `<b>4. Injections</b>`,
        `<b>5. Financial Destruction</b>`,
        `<b>6. Eugenics + closing arguments and outlook</b>`,
      ],
      urls: [
        `https://www.grand-jury.net`,
        `https://odysee.com/@GrandJury/Grand-Jury-1-EN`,
        `https://odysee.com/@GrandJury/Grand-Jury-Day-2-online_1`,
        `https://odysee.com/@GrandJury/Grand-Jury-Day-3-en-online`,
        `https://odysee.com/@GrandJury/Grand-Jury-Day-4-online`,
        `https://odysee.com/@GrandJury/Grand-Jury-Day-5-online_1`,
        `https://odysee.com/@GrandJury/Grand-Jury-Day-6-en-online`,
      ],
    },
    {
      description: [
        `Reiner Fuellmich update (Feb 25, 2022)`,
      ],
      urls: [
        `https://www.bitchute.com/video/G8Sq3rmvwrvA/`
      ],
    },
    {
      description: [
        `Older Reiner Fuellmich videos`,
      ],
      urls: [
        `https://www.bitchute.com/video/A5I7lDiqoLf7/`,
        `https://rumble.com/vsga27-worldwide-dr.-reiner-fuellmich-on-upcoming-criminal-case-against-bill-gates.html`,
      ],
    },
  ],
  LegalCases: [
    {
      description: [
        `<b>Nuremberg Code (1947)</b>`,
        `<b>Permissable Medical Experiments</b>`,
        `The great weight of the evidence before us to effect that certain types of medical experiments on human beings, when kept within resonably well-defined bounds, conform to the ethics of the medical profession generally. The protagonists of the practice of human experimentation to justify their views on the basis that such experiments yield results for the good of society that are unprocurable by other methods or means of study. All agree, however, that certain basic principles must be observed in order to satisfy moral, ethical and legal concepts:`,
        `1.`,
        `<b>The voluntary consent of the human subject is absolutely essential. This means that the person involved should have legal capacity to give consent; should be so situated as to be able to exercise free power of choice, without the intervention of any element of force, fraud, deceit, duress, overreaching, or other ulterior form of constraint or coercion; and should have sufficient knowledge and comprehension of the elements of the subject matter involved as to enable him to make an understanding and enlightened decision.</b> This latter element requires that before the acceptance of an affirmative decision by the experimental subject there should be made known to him the nature, duration, and purpose of the experiment; the method and means by which it is to be conducted; all inconveniences and hazards reasonably to be expected; and the effeccts upon his health or person which may possibly come from his participation in the experiment.`,
        `The duty and responsibility of ascertaining the quality of the consent rests upon each individual who initiates, directs, or engages in the experiment. It is a personal duty and responsibility which may not be delegated to another with impunity.`,
        `2.`,
        `The experiment should be such as to yield fruitful results for the good of society, unprocurable by other methods or means of study, and not random or unnecessary in nature.`,
        `3.`,
        `The experiment should be so designed and based on the results of animal experimentation and a knowledge of the natural history of the disease or other problem under stufy that the anticipated results justify the performance of the experiment.`,
        `4.`,
        `<b>The experiment should be so conducted as to avoid all unnecessary physical and mental suffering and injury.</b>`,
        `5.`,
        `No experiment should be conducted where there is a priori reason to believe that death or disabling injury will occur; except, perhaps, in those experiments where the experimental physicians also serve as subjects.`,
        `6.`,
        `<b>The degree of risk to be taken should never exceed that determineed by the humanitarian importance of the problem to be solved by the experiment.</b>`,
        `7.`,
        `Proper preparations should be made and adequate facilities provided to protect the experimental subject against even remote possibilities of injury, disability or death.`,
        `8.`,
        `The experiment should be conducted only by scientifically qualified persons. The highest degree of skill and care should be required through all stages of the experiment of those who conduct or engage in the experiment.`,
        `9.`,
        `During the course of the experiment the human subject should be at liberty to bring the experiment to an end if he has reached the physical or mental state where continuation of the experiment seems to hum to be impossible.`,
        `10.`,
        `<b>During the course of the experiment the scientist in charge must be prepared to terminate the experiment at any stage, if he has probably cause to believe, in the exercise of the good faith, superior skill and careful judgement required of hum, that a continuation of the experiment is likely to result in injury, disability, or death ot the experimental subject.</b>`,
      ],
      urls: [
        `https://twitter.com/TruthPunch2/status/1645827730620162049/photo/1`
      ],
    },
    {
      description: [
        `A devastating decision from Missouri declaring all coercive Covid measures to be illegal. 2021-11-24`,
      ],
      urls: [
        `https://twitter.com/jeffreyatucker/status/1463521153033641986`,
        `https://bloximages.newyork1.vip.townnews.com/kmov.com/content/tncms/assets/v3/editorial/0/85/085be368-4ca2-11ec-8ca9-634fb8ef3e90/619d5946b9df6.pdf.pdf`,
      ]
    },
    {
      description: [
        `Dr. Reiner Fuellmich, German lawyer, member of the German Coronavirus Inquiry Committee.`,
        `Has evidence that pandemic is crime against humanity.`,
        `“They are making so many mistakes, we are close to a tipping point”`,
      ],
      urls: [
        `https://www.bitchute.com/video/1338kLyYzeAp/`,
      ]
    },
    {
      description: [
        `International Criminal Court to many high ranking officials, with violations of the Nuremburg Code, and multiple violations of the Rome Statue.`,
      ],
      urls: [
        `https://www.docdroid.com/WUjv6iw/icc-complaint-7-1-pdf`
      ],
    },
    {
      description: [
        `Canadian Lawyers: Our free society is at risk.`,
        `<b>The Free North Declaration</b>`
      ],
      urls: [
        `https://www.youtube.com/watch?v=jl6OKIUnxRk`
      ],
    },
    {
      description: [
        `<b>Legal defense for Children's Health Defense Canada - Re: "Mandatory Vaccinations" for Students in Residence</b>`,
        `Points include:`,
        `Not authorized for general use`,
        `Not effective in risk reduction`,
        `Not necessary for age group`,
        `University/College policy contrary to law`,
        `Breach of privacy laws and health consent`,
      ],
      urls: [
        `https://www.constitutionalrightscentre.ca/20CRC16/wp-content/uploads/2021/08/CHDC-Letter-Ontario-UniversitiesColleges.pdf`
      ],
    },
    {
      description: [
        `Canadian lawsuit against vaccine passports`,
        `An international organized crime has assaulted our very human beings and has injected false narratives which has resulted in our political leaders to take the wrong path addressing the COVID-19 pandemic. While our government officials have good intentions, however, policies are severely flawed.`
      ],
      urls: [
        `http://covid19-lawsuit.ca/`
      ],
    },
    {
      description: [
        `Victory Against Hamilton School Board's Vaccine Mandate`,
      ],
      urls: [
        `https://brightlightnews.com/1936-2/`
      ],
    },
    {
      description: [
        `<b>Vaccine Notice of Liability to Employer</b>`,
        `As my employer you are not a medical professional and, therefore, you are unlawfully practising medicine by prescribing, recommending, and/or using coercion to insist employees submit to the experimental medical treatment for Covid-19, namely being injected with one of the experimental gene therapies commonly referred to as a “vaccine”`,
      ],
      urls: [
        `https://awakening.s3.amazonaws.com/wp-content/uploads/2021/08/11133734/Notice-of-Liability-Employees.pdf`
      ],
    },
    {
      description: [
        `Justice Centre for Constitutional Freedoms`,
        `<b>Understanding your fundamental rights and freedoms in a world filled with fear.</b>`,
      ],
      urls: [
        `https://www.jccf.ca/wp-content/uploads/2021/08/Justice-Centre-Understanding-your-rights-and-freedoms.pdf`
      ],
    },
    {
      description: [
        `<b>COVID-19 Workplace Vaccinations Mandates: What are your employment rights?</b>`,
        `From employment lawyer.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=YvQWdmqGIAI&t=1s`
      ],
    },
    {
      description: [
        `Reiner Fuellmich Update: We are starting to win in Courts`,
      ],
      urls: [
        `https://rumble.com/vkp0qf-reimer-fullmich-update-we-are-starting-to-win-in-courts.html`
      ],
    },
    {
      description: [
        `<b>The Free North Declaration</b>`,
        `Canada is supposed to be a free country governed by the rule of law. Restore it now or risk losing it for good.`,
        `We are Canadian lawyers. In our country, civil liberties are under unprecedented attack. Governments, public health authorities, universities, public and private employers, municipalities, and businesses are trampling Canadians' rights and freedoms. Our free society is at risk.`,
      ],
      urls: [
        `https://freenorthdeclaration.ca/`
      ],
    },
    {
      description: [
        `Co-parent lawsuit battling between mother and father disagreeing on giving their children covid vaccines`,
        `[1] When did it become illegal to ask questions? Especially in the courtroom?`,
        `[2] And when did it become unfashionable for judges to receive answers? Especially when children's lives are at stake?`,
        `[3] How did we lower our guard and let the words "unacceptable beliefs" get paired together? In a democracy? On the Scales of Justice?`,
        `[4] Should judges sit back as the concept of "Judicial Notice" gets hijacked from a rule of evidence to a substitute for evidence?`,
        `[5] And is "misinformation" even a real word? Or has it become a crass, self-serving tool to pre-empt scrutiny and discredit your opponent? To de-legitimize questions and strategically avoid giving answers?`,
        `[7] ...when society demonizes and punishes anyone who disagrees--or even dares to ask really important questions--the resulting polarization, disrespect, and simmering anger can have devastating consequences...`,
        `<b>[36] Children may not have wisdom. But they have Charter rights and undeniable emotions.</b>`,
      ],
      urls: [
        `https://www.canlii.org/en/on/onsc/doc/2022/2022onsc1198/2022onsc1198.html`
      ],
    },
    {
      description: [
        `'Not demonstrably justified': High Court upholds challenge to Police and NZDF vaccination mandates, terminations suspended`,
        `New Zealand High Court ENDS Jacinda Ardern's Vaccine Mandate: “It's a Gross Violation of Human Rights”`,
      ],
      urls: [
        `https://www.nzherald.co.nz/nz/not-demonstrably-justified-high-court-upholds-challenge-to-police-and-nzdf-vaccination-mandates-terminations-suspended/LMAUM7LZWV6FFQWAKKJFLKYLIE/`,
        `https://newspunch.com/new-zealand-high-court-ends-jacinda-arderns-vaccine-mandate-its-a-gross-violation-of-human-rights/`,
      ],
    },
    {
      description: [
        `<b>Pfizer will have no immunity if fraud is proven.</b>`,
        `This is going to take down the FDA, the CDC, the NIH, many politicians careers, global governments will be shaken to the core.`,
        `<b>Not only will people sue, governments are going to sue.</b>`,
      ],
      urls: [
        `https://twitter.com/LakovosJustice/status/1501295965893976064`
      ],
    },
    {
      description: [
        `CDC, the ones who said people like Dr. Robert Malone were spreading misinformation, were themselves the ones spreading misinformation, and may well have committed medical fraud.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=tEhjzTy70s0`
      ],
    },
    {
      description: [
        `<b>Court facing compelling testimony from Charter signatory Brian Peckford as action proceeds to strike down vaccine travel ban</b>`,
        `In his sworn affidavit, Mr. Peckford states: “What I find perhaps the most disturbing is that the federal government has mandated a two-tiered society where one group of people has benefits while another group is disadvantaged. As a person who has chosen not to receive the new medical treatment, I am all of a sudden treated as an outcast, labelled a “racist” and “misogynist”, and as an undesirable person not fit to be seated with vaccinated people on an airplane … The Covid-19 vaccinated are allowed to travel by airplane and to see their families and the unvaccinated are not. This is not the Canada I know and love, and this type of segregation causes me utmost sadness.”`,
      ],
      urls: [
        `https://www.jccf.ca/court-facing-compelling-testimony-from-charter-signatory-brian-peckford-as-action-proceeds-to-strike-down-vaccine-travel-ban/`
      ],
    },
    {
      description: [
        `Life insurance for wealthy businessman who died after taking the covid injection, refuses to pay out because they define the injection as a "medical experiment"`,
        `Side effects are well known and made public, there is no way the gentleman could NOT have known the side effects, and willingly chose to get the vaccine, died as a result, and because it was a "choice", they're calling it <b>a suicide.</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/IVT8oEZ8DuRO/`
      ],
    },
    {
      description: [
        `U.S. Senate expert witness testimony Dr. Peter McCullough states that 95% of American deaths could have been avoided. Autopsy confirms two teenage boys die on days 3 and 4 after the shot, the parents are horrified. This push is resulting in deaths of children.`,
      ],
      urls: [
        `https://twitter.com/LakovosJustice/status/1507570790530752515`
      ],
    },
    {
      description: [
        `"Due to the scale of the rollout, it now appears tens of thousands of practitioners have repeatedly performed medical treatments, properly termed, gross medical and or professional negligence with respect to patients receiving the COVID19 injectables, where each practitioner has no immunity from government whatsoever.`,
        `So these practitioners are therefore personally and professionally liable to actions of medical negligence from their patients receiving the COVID19 injectables, particularly those patients who subsequently died or suffered adverse side effects from the COVID19 injectables.`,
        `Additionally, due to the illegal nature of the APRA and National Boards Joint Statement, it does also appear that the public offices of APRA and the National Boards responsible for the creation and publication of the 9th March 2021 statement <b>are now legally exposed to the action of misfeasance in public office.</b>`,
        `<b>As the harm to COVID19 vaccine victims was foreseeable,</b> in terms of these still remaining experimental gene based therapies, <b>these vaccine victims and future victims who later develop vaccine related injuries and illnesses, can sue the public offices of APRA and the National Boards in their personal capacity.</b>`,
        `A further liability in the same public offices appears available to those registered practitioners who improperly administered the COVID19 injectables, in breach of their codes of conduct.`,
        `Should those health practitioners subsequently be sued by their patients and they have to pay damages to their patients, then those health practitioners may in turn be able to sue the public offices of APRA and the National Boards for coercing and threatening them to ignore their codes of conduct.`,
        `Such a legal action again would be the tort of <b>misfeasance in public office.</b>`,
      ],
      urls: [
        `https://twitter.com/TomMarazzo/status/1575826952627306496`
      ],
    },
    {
      description: [
        `Children's jab centre closed down`,
      ],
      urls: [
        `https://gettr.com/post/p1su43o7544`
      ],
    },
    {
      description: [
        `The house of cards is tumbling as Christine Anderson MEP talks to a full house about the criminal investigations underway.`,
        `Von Ursula whose husband is linked to Pfiser is under pressure to explain contracts valued at 71bn euro/10 doses per person.`,
        `Christine Anderson: "The people have been lied to. It was a gigantic lie, and on this lie, everything that governments, especially in the western democracies did to infringe on people's rights, to take away their freedom, to lock them in their homes, imposing curfews.. all of this was based on that gigantic <b>lie</b>."`,
      ],
      urls: [
        `https://twitter.com/SaiKate108/status/1589473132938948608`,
        `https://t.me/christineanderson/752`,
      ],
    },
    {
      description: [
        `Naomi Wolf:`,
        `"Wow, so are you saying, Mr. Dowd, immunity will be lifted because of fraud, and so everyone who's been harmed by the mRNA vaccines CAN sue Pfizer, that's what you foresee?"`,
        `Edward Dowd:`,
        `"It's not even that, so a lot of these governments across the globe entered into contracts with Pfizer and Moderna, and if fraud is proven, it vidiates all contracts, and that's case law, when you enter into a contract and one party has been defrauded, the contract's broken, so immunity will be broken.`,
        `And governments.. not only will individuals sue, governments are going to sue. This is.. the daisy chain effects from this are mind-boggling, and it's almost like, that's why I say we're on our own for now, because it'll take down the FDA, the CDC, the NIH. It'll take down many politicians' careers, many global governments will be shaken to the core, this is a problem.`,
      ],
      urls: [
        `https://twitter.com/Ellenwalker65/status/1577428861079506944`,
        `https://rumble.com/vwjmjm-bombshell-naomi-wolf-interviews-edward-dowd-about-pfizer-fraud-and-criminal.html`,
      ],
    },
    {
      description: [
        `<b>A criminal case has been brought against Alain Berset, the President of Switzerland.</b>`,
        `<b>Gross negligence in the best case scenario or with intent to do harm in the worst case; consequences which include death of citizens after C19 vaccination.</b>`,
        `"At this juncture I'd like to say something very important, which is also important for your program.`,
        `We're talking about Alain Berset in this case, about me filing charges, and why he benefits from the presumption of innocence at all times. It's a running case, I'm not the prosecutor, I'm not the judge, just to make this point clear.`,
        `I expect, again, I went to the police station as soon as I found out that something is really, really wrong. Factually wrong. Grave error. Minimum gross negligence, if not intentional.`,
        `I went to the police because I learned if you go to the police with something urgent, it goes quick. I have no money and time for lawyers to file and another week to lose. If I find something that prevents other people's harm, I should do it fast... and I want to see action.`,
        `<b>If this stuff kills people, potentially, I mean.. it should be stopped yesterday, so to speak.</b>`,
      ],
      urls: [
        `https://twitter.com/SerrynaWhitesi1/status/1622067339893301248`,
        `https://t.co/d4Wa0weG5A`,
      ],
    },
    {
      description: [
        `1.TWITTER FILES #19`,
        `The Great Covid-19 Lie Machine`,
        `Stanford, the Virality Project, and the Censorship of “True Stories”`,
      ],
      urls: [
        `https://twitter.com/mtaibbi/status/1636729166631432195`
      ],
    },
    {
      description: [
        `Kim Iversen and Robert Barnes discuss the ongoing Pfizer lawsuit and the legal hurdles for those harmed by the COVID-19 mRNA vaccines`,
        `"We have sued because Pfizer lied to the DOD, the FDA, the American people, lied to their stockholders, lied to the people that took the drug, and said it was a safe and effective vaccine for prevention of COVID-19. What they delivered is something that is dangerous, ineffective, doesn't serve as a vaccine, and doesn't, in fact, prevent COVID-19."`,
      ],
      urls: [
        `https://twitter.com/KanekoaTheGreat/status/1634302180109991936`
      ],
    },
    {
      description: [
        `Moderna CEO tells Sen. Rand Paul that they paid the NIH $400M in royalties last year.`,
      ],
      urls: [
        `https://twitter.com/TexasLindsay_/status/1638558264895102976`
      ],
    },
    {
      description: [
        `Japanese Professor Sues the Government for Hiding Inconvenient Truths About the Jab`,
        `Prof. Fukushima: "This is a historically serious problem that threatens the very existence of the nation of Japan."`,
        `"As a medical doctor ... I had no choice but to dare to take legal action."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1622434385290006530`
      ],
    },
    {
      description: [
        `<b>Christine Anderson @AndersonAfDMdEP makes EU COVID Special Committee Sweat: How Many Deaths Per Doses Administered?</b>`,
        `"If you can't provide the numbers off the top of your head, I would have to conclude that you simply don't care — or you're still working for the EFPIA, one of the lobby organizations of the biggest pharmaceutical companies in Europe."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1642676431497854983`
      ],
    },
    {
      description: [
        `Canadian Tribunal finds that refusal to be vaccinated is not "misconduct" in denial of EI benefits case`,
        `Tribunal says exercising individual right to decide what goes into one's body isn't wrong or punishable!`,
        `---`,
        `Unvaccinated Worker Denied Employment Insurance for Jab Refusal Wins Tribunal Appeal`,
        `"The individual has the final say in whether they accept any medical treatment.”`,
      ],
      urls: [
        `https://twitter.com/josephmhickey/status/1604985237032820738`,
        `https://ocla.ca/refusal-to-be-vaccinated-not-misconduct-in-new-sst-decision/`,
        `https://twitter.com/NChartierET/status/1604989709897326592`,
        `https://www.theepochtimes.com/unvaccinated-worker-denied-employment-insurance-for-jab-refusal-wins-tribunal-appeal_4934003.html`,
      ],
    },
    {
      description: [
        `<b>Denmark does NOT recommend the covid vax to under 50s.</b>`,
        `Yet when Andrew Bridgen MP clearly laid out concerns in the UK parliament it was dismissed as 'conspiracy theory'.`,
        `Corruption & ignorance is rife & endangering lives.`,
      ],
      urls: [
        `https://twitter.com/djlange/status/1603405247862423562`
      ],
    },
    {
      description: [
        `Dr. Scott Jensen:`,
        `They are NOW using Keith Ellison and the Attorney General's Office to take away my Medical License - If this can happen to me it can happen to you!`,
        `AFter he expressed concerns about how death certificates were being written.`,
      ],
      urls: [
        `https://twitter.com/drscottjensen/status/1620203440713469952`
      ],
    },
    {
      description: [
        `Pfizer CEO found guilty of misleading public`,
        `A UK regulatory body found Pfizer CEO Albert Bourla guilty of making misleading statements about his company's vaccine.`,
      ],
      urls: [
        `https://thecountersignal.com/pfizer-ceo-found-guilty-of-misleading-public/`,
        `https://www.pmcpa.org.uk/cases/completed-cases/auth35911221-a-complaint-on-behalf-of-usforthem-v-pfizer/`,
      ],
    },
    {
      description: [
        `'See You in Court, Criminals!' Pfizer Whistleblower Fraud Case is Being Taken to the Next Level`,
        `"Pfizer has fought 'discovery' in its court battle with Brook Jackson every step of the way. If she is successful in her court hearing in March, this charade will come to an end"`,
      ],
      urls: [
        `https://twitter.com/kylenabecker/status/1627118900042244096`,
        `https://thekylebecker.substack.com/p/see-you-in-court-criminals-pfizer`,
      ],
    },
    {
      description: [
        `Dr. David E. Martin:`,
        `What I can say is we have three different law enforcement agencies who in fact are working with us right now of the development of the very first criminal indictment against one of the sociopaths that architected this global campaign of terror.`,
      ],
      urls: [
        `https://rumble.com/v153ybt-dr.-david-e-martin-gives-explosive-jaw-dropping-information-in-canadian-zoo.html`
      ],
    },
    {
      description: [
        `"I found out today that I have no choice but to vote "Aye" or I will be arrested and charged with a felony."`,
        `Ron Gould of the Mohave County Board of Supervisors was told he would be arrested if he did not certify the election.`,
        `Mohave was certified under duress.`,
      ],
      urls: [
        `https://twitter.com/KariLakeWarRoom/status/1597380690597023744`
      ],
    },
    {
      description: [
        `France's Senate rejects Covid vaccine mandates with overwhelming majority: 262 Senators voted against and 64 Senators shamefully voted in favour.`,
        `Le Sénat rejette la proposition de loi #VaccinationObligatoire contre le Sars-CoV 2 : 262 voix contre, 64 voix pour. #DirectSénat`,
      ],
      urls: [
        `https://twitter.com/dockaurG/status/1448678683552763905`,
        `https://twitter.com/publicsenat/status/1448331160661241862`,
      ],
    },
    {
      description: [
        `Chris Sky in September 2021 at the Trucker Convoy:`,
        `If everybody goes and files notices of liability, if everbody goes and files lawsuits, oh and by the way if you're having trouble finding a lawyer here's why, the government won't tell you this, but government insiders tell me everything.. if you're having trouble finding a great lawyer it's because our government used our tax dollars to retain off the best law firms in the country.`,
        `All the ones that they knew had the power and resources to fight back against these wrongful dismissals and wrongful terminations, they retain them.`,
        `So when you end up pulling these law firms, they just simply tell you they can't help you. And it gives you the impression that there's no one out there to fight for you.`,
        `And it's because the government literally bought them off.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1441884103545167874`
      ],
    },
    {
      description: [
        `<b>Pastor Artur Pawlowski ARRESTED again after landing at Calgary airport</b>`,
      ],
      urls: [
        `https://twitter.com/RebelNewsOnline/status/1442610528073838595`,
        `https://twitter.com/thevivafrei/status/1442611665204355073`
      ],
    },
  ],
  Lockdowns: [
    {
      description: [
        `David Redman: Former Executive Director of the Alberta Emergency Management Agency:`,
        `<b>Politicians ignored their own emergency management plans for c19, and instead followed a criminal cartel.</b>`,
        `(Additionally: Alberta's Failed COVID Management)`,
        `"Do you know the first world war at all? Battle of the Somme? The Battle of the Somme started on the first of June, ended when the snow fell in the fall.`,
        `The best military minds in the world, and I use this.. as a metaphor, the generals believed that on the first day of the Battle of the Somme, that if we shot a massive amount of artillery for 24 hours, that we would kill all the Germans and we would simply walk through their lines.`,
        `Well the first day didn't turn out that way, and we killed 100,000 soldiers, minimum, machine gunned to death because when the artillery came down, the Germans went in their foxholes and waited for the artillery to stop, came up, manned their machine guns and killed us all.`,
        `So a week later they said, ok.. <b>we didn't use enough artillery.</b> Let's use more.`,
        `So we tried it again, and it killed another 200,000.`,
        `Two weeks later we said, ok we need to start earlier, use more, and shoot longer. And they kept trying to find that perfect mix of artillery until they killed over a million men, and the winter snows stopped the lunacy.`,
        `<b>Let me tell you that's exactly what we're doing in covid.</b>`,
        `If only we had locked down sooner, locked down harder, and made it last longer, we would have had less deaths.`,
        `And we tried that in March, and we tried it in April, and then we tried it in October, November, December, January, February, and we're trying it again with the new variants, exactly the same. Maybe a little longer, maybe a little harder, maybe a little deeper..`,
        `And we still keep kill all our seniors over the age of 65 with multiple comormidities in long term care homes.`,
        `It's exactly the same because it seems to make sense.. if you use a shitload of artillery, you'll kill all the Germans. It really makes sense.`,
        `If you lockdown harder, you'll stop the spread, it really makes sense..`,
        `<b>Only it doesn't work."</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/iWDKDi6PTULq/`,
        `https://twitter.com/KoenSwinkels/status/1503867439989334019`,
        `https://twitter.com/KoenSwinkels/status/1503861885464195072`,
        `https://www.youtube.com/watch?v=VtM1f8j6ilQ`,
      ],
    },
    {
      description: [
        `A Literature Review and Meta-Analysis of the Effects of Lockdowns on COVID-19 Mortality`,
        `By Jonas Herby, Lars Jonung, and Steve H. Hanke`,
        `"While this meta-analysis concludes that lockdowns have had little to no public health effects, they have imposed enormous economic and social costs where they have been adopted. In consequence, <strong>lockdown policies are ill-founded and should be rejected as a pandemic policy instrument</strong>."`,
      ],
      urls: [
        `https://sites.krieger.jhu.edu/iae/files/2022/01/A-Literature-Review-and-Meta-Analysis-of-the-Effects-of-Lockdowns-on-COVID-19-Mortality.pdf`,
      ],
    },
    {
      description: [
        `Scientists Told Us NOT to LOCKDOWN, And Yet...`,
        `Before the coronavirus pandemic, lockdowns were considered a draconian treatment for a pandemic that was largely shunned by the scientific community. Now politicians are using them like parking tickets to keep the coronavirus from spreading and overwhelming hospitals.`,
        `America Uncovered episode on what "the science" says about lockdowns, why lockdowns became popular, and who's been breaking their own lockdown rules.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=TcXO8Xt8cpw`,
      ],
    },
    {
      description: [
        `The Guardian: Children born during pandemic have lower IQs, US study finds`,
        `The cost of lockdowns.`,
      ],
      urls: [
        `https://www.theguardian.com/world/2021/aug/12/children-born-during-pandemic-have-lower-iqs-us-study-finds`,
      ],
    },
    {
      description: [
        `<b>Fauci admits that isolation is not good for society.</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1476263676067864585`
      ],
    },
    {
      description: [
        `<b>The Deafening Silence of Economists During COVID-19 and the Abandonment of Logic</b>`,
        `Donald Boudreaux - American Thought Leaders`
      ],
      urls: [
        `https://www.theepochtimes.com/donald-boudreaux-the-deafening-silence-of-economists-during-covid-19-and-the-abandonment-of-logic_4143488.html`
      ],
    },
    {
      description: [
        `<b>Canada's parliament is already working on plans for future lockdowns.</b>`,
        `Chrystia Freeland: "Programs like Local Lockdown Support & the 🇨🇦 Worker Lockdown Benefit will help in the event of a new lockdown."`
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1471271221580873730`
      ],
    },
    {
      description: [
        `Dr. Paul Alexander : No Evidence that "Outrageous" Lockdowns Help Stop the Spread of COVID`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=6nfqrQW-EcA`
      ],
    },
    {
      description: [
        `<b>Jay Bhattacharya: The Case Against Lockdowns</b>`,
        `Lex Fridman Podcast #254`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=oIOGUYOPAsA`
      ],
    },
    {
      description: [
        `The Vindication of D.A. Henderson`,
        `We were going to invent pandemic planning.” Those were the words of Dr. Rajeev Venkayya in 2005 when he headed the White House bioterrorism study group under George W. Bush. “We want to use all instruments of national power to confront this threat,” Venkayya told colleagues in the administration, as reported by Michael Lewis in his book The Premonition.`,
        `That was the birth of the idea of national lockdown for pathogenic threat. To mainstream epidemiologists, the idea seemed crazy and potentially ruinous at the time, a fact that only emboldened its creators. Venkayya's colleague computer scientist Robert Glass told Lewis:`,
        `I asked myself, “Why didn't these epidemiologists figure it out?” They didn't figure it out because they didn't have tools that were focused on the problem. They had tools to understand the movement of infectious diseases without the purpose of trying to stop them.`,
        `Another convert to the idea, Dr. Carter Mecher, a man who was highly instrumental in provoking school shutdowns March 17, 2020, summed up the idea:`,
        `“If you got everyone and locked each of them in their own room and didn't let them talk to anyone, you would not have any disease.”`,
        `Now there's an idea: universal solitary confinement!`,
      ],
      urls: [
        `https://brownstone.org/articles/the-vindication-of-d-h-henderson/`
      ],
    },
    {
      description: [
        `Sage scientist:`,
        `Lockdowns an idea “concocted by China and WHO” were “flawed, highly damaging, morally wrong” and “made a bad situation worse”`,
        `“Harmful restrictions imposed even when it became clear not needed at cost to lives, livelihoods and society”`,
      ],
      urls: [
        `https://twitter.com/thelucyjohnston/status/1505540126788788225/photo/1`
      ],
    },
    {
      description: [
        `<b>The Unintended Consequences of COVID-19 Vaccine Policy: Why Mandates, Passports, and Segregated Lockdowns May Cause more Harm than Good</b>`,
        `University of Toronto Faculty of Law`,
      ],
      urls: [
        `https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4022798`,
        `https://www.law.utoronto.ca/events/unintended-consequences-covid-19-vaccine-mandates-why-they-may-cause-more-harm-good`
      ],
    },
    {
      description: [
        `“You use lockdowns to get people vaccinated.” -Fauci`,
        `Right. Not because there's any science to justify them. To make people's lives miserable so they get vaccinated. Criminal.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1515005933587423242`
      ],
    },
    {
      description: [
        `Those sedentary lockdowns were super!`,
        `Wait...`,
        `"Low muscle strength was independently associated with elevated risk of all-cause mortality... indicating the importance of muscle strength in predicting aging-related health outcomes in older adults."`,
        `Nothing to see here. Certainly, nothing that might mean lockdowns are health-destroying for older folks...`,
        `<b>"In conclusion, low muscle mass were predictors of long-term mortality in nonagenarian and centenarian women."</b>`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1515115203238633473`,
        `https://pubmed.ncbi.nlm.nih.gov/28991040/`,
        `https://www.nature.com/articles/s41598-019-38893-0`,
        `https://threadreaderapp.com/thread/1515115203238633473.html`,
      ],
    },
    {
      description: [
        `"We inverted precautionary principle of trying to minimize harm by doing the one thing that we knew would cause harm: lockdowns.. What we do see very sadly.. harms unfolded.. We sit watching devastation that we knew these measures would cause 2yrs ago”`,
        `—Oxford Prof Sunetra Gupta`,
      ],
      urls: [
        `https://twitter.com/dockaurG/status/1501029167479115784`,
        `https://twitter.com/SunetraGupta/status/1533183842336514051`,
      ],
    },
    {
      description: [
        `"We inverted precautionary principle of trying to minimize harm by doing the one thing that we knew would cause harm: lockdowns..`,
        `What we do see very sadly.. harms unfolded.. We sit watching devastation that we knew these measures would cause 2yrs ago”`,
        `— Oxford Prof Sunetra Gupta`,
      ],
      urls: [
        `https://twitter.com/dockaurG/status/1501029167479115784`
      ],
    },
    {
      description: [
        `<b>Those who did this now want amnesty.</b>`,
        `Elderly people crying that they're not allowed to hug their children, but can't understand why.`,
        `Elder abuse.`,
      ],
      urls: [
        `https://twitter.com/brixwe/status/1594500468889329665`
      ],
    },
    {
      description: [
        `This chatter about kids mental health not being impacted by school closures and lockdowns is offside...`,
        `Dad speaks about the mental health of youth.`,
        `"Talking about our kids lives not being impacted by the lockdowns, school closures, because suicide rates weren't impacted...`,
        `This is atrocious. This is classless. Anybody with children, anybody that's talked to any mental health professional that deals with youth, can tell you that this has been dramatic, the impacts on their mental health.. Anxiety, depression, eating disorders.. without question.`,
      ],
      urls: [
        `https://twitter.com/kwadwo777/status/1578410778335518723`
      ],
    },
    {
      description: [
        `"Complete failure of govts to even consider collateral costs of lockdowns—no cost-benefit analysis.. Radical experiment on human life.. Deafening silence of human rights lobby..really is quite remarkable"`,
        `— Lord Sumption - Former British Supreme Court Justice`,
      ],
      urls: [
        `https://twitter.com/dockaurG/status/1596325248991121408`
      ],
    },
    {
      description: [
        `<b>LOCKDOWN: The Line Dividing Good and Evil</b>`,
        `Kate Wand`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=CWQ8iDizXlk`
      ],
    },
    {
      description: [
        `<b>The true price of lockdown<b/>`,
        `Child became so terrified of outside, his friends, wasn't allowed to see anyone.`,
        `Talks about his local area being plunged into "local lockdown" and just leading up to that he hung himself in a park.`,
        `This was purely for fear. This was a child not at risk of dying from covid, but they allowed him to believe that he was.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1633179858472783872`
      ],
    },
    {
      description: [
        `This article by Angus Dagleish is an absolute barn burner:`,
        `<b>'Knew Covid had been engineered to make it infectious to humans but were told to shut-up'</b>`,
        `Suppression of debate has "cost... at least a trillion pounds, given the disastrous legacy of lockdown on education, mental health and misdiagnosis leading to early deaths from cardiac and cancer."`,
      ],
      urls: [
        `https://twitter.com/DrJBhattacharya/status/1620824716507492352`,
        `https://www.express.co.uk/comment/expresscomment/1729287/covid-19-infection-china-cover-up-conspiracy-USA-wuhan-laboratory-man-made-virus-weapon`,
      ],
    },
    {
      description: [
        `German Minister of Health Karl Lauterbach paves the way for "climate lockdowns" using Covid-restrictions as a model.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1620102173286666242`
      ],
    },
    {
      description: [
        `Brendan Eich: Lockdowns failed by every measure.`,
        `Zerocovid loons have no answer, and can only object (as do communists and other cultists of abstract, a priori ideologies) that <b>real lockdowns weren't really tried hard enough.</b>`,
      ],
      urls: [
        `https://twitter.com/BrendanEich/status/1599900855150452762`
      ],
    },
    {
      description: [
        `Climate lockdowns:`,
        `Remeber when the media said that "climate lockdowns" were just a baseless conspiracy theory?`,
        `And then India went ahead and imposed them anyway?`,
        `Well now defacto climate lockdowns for a major UK city have just been announced.`,
        `Yep. They're actually doing it. The Daily Skeptic reports <b>Oxford County Councillors to Introduce Trial Climate Lockdown in 2024</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=IaXvc2EdWKw`
      ],
    },
    {
      description: [
        `Fraser Institute study:`,
        `“Widespread, economy-wide lockdown policies were a disaster. They had only marginal effects on the ultimate number of deaths, but imposed enormous costs.”`,
        `<b>COVID-19 - Lessons We Should Have Learned - Collected Essays`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1617556226623311872`,
        `https://www.fraserinstitute.org/sites/default/files/covid-19-lessons-essay5-lockdown-a-final-assessment.pdf`,
      ],
    },
  ],
  Masks: [
    {
      description: [
        `More than 150 Comparative Studies and Articles on Mask Ineffectiveness and Harms`,
        `It is not unreasonable to conclude that surgical and cloth masks, used as they currently are being used (without other forms of PPE protection), have no impact on controlling the transmission of Covid-19 virus. Current evidence implies that face masks can be actually harmful. The body of evidence indicates that face masks are largely ineffective.`,
      ],
      urls: [
        `https://brownstone.org/articles/more-than-150-comparative-studies-and-articles-on-mask-ineffectiveness-and-harms/`
      ],
    },
    {
      description: [
        `<b>Dr. José Luis Gettor on the adverse effects of prolonged mask use in children</b>`,
        `Children, moreover, have an oxygen consumption that goes from two to three and more times that of an adult.`,
        `This is easy. When an adult has 60 beats per minute, a child has 120.`,
        `Why? Because he needs more oxygen; because he has an increased metabolism; because he's growing; because he's developing, and the cell needs more food. The cell's food is oxygen.`,
        `<b>It's indeed harmful to cover a child's face so that he doesn't breathe.</b>`,
        `Some will say to me, "Doctor, but I see many children who breathe with it, and it doesn't hurt them." What type of harm are we talking about?`,
        `We can talk about immediate harm, which I have seen. I've seen them: loss of consciousness, head trauma, seizures, delirium. I have seen this because I attend 20 schools here in San Rafael.`,
        `These are immediate damages, but we can also talk about mid-term damages. Mid-term damage includes depersonalization, lack of facial expression, no communicating with the teacher or classmates. These are mid-term damages which can be seen after a few weeks.`,
        `And there are also long-term damages, which have been studied. There's research done by Margareta Griesz-Brisson, who's a German neurophysiologist on long-term damage in children. Because of this chronic hypoxia. And with the consequence of hypercapnia.`,
        `<b>Hypoxia is the lack of oxygen. Hypercapnia is excess carbon dioxide. Excess carbon dioxide kills neurons.</b>`,
        `And children, as time goes by... Tests have been done, for example, in Asian countries. In China, masks have been used for decades. And it can be seen how children's IQ <b>decreases</b> over time.`,
        `It's important to see who's interested in children not having a high IQ.`,
        `I'll defend until the last day of my life that human beings need oxygen to be able to live.`,
      ],
      urls: [
        `https://www.bitchute.com/video/Lu0181B6aFwo/`
      ],
    },
    {
      description: [
        `“Not seeing someone's facial expressions activate our stress hormones because we can't decode them. It generates anxiety for babies seeing a mother with a neutral face and it impairs their mirror neuron growth, which are essential to form to be able to feel empathy. What do you think a c hild who grows up without empathy will be? Can you imagine being a child today? Looking at faces that don't smile.”`,
      ],
      urls: [
        `https://twitter.com/4taranov20/status/1454858129896706052`,
        `https://twitter.com/4taranov20/status/1454859231492853762`,
      ],
    },    
    {
      description: [
        `Bonnie Henry, BC Health, as late as September 2020 explicitly NOT recommending masks for long periods of time “in any situation”.`,
      ],
      urls: [
        `https://twitter.com/leonarddoyle99/status/1450856484670218245`,
      ],
    },    
    {
      description: [
        `Masks Seriously Harm Children's Linguistic Development with Meghan Mansell and Dr. Naomi Wolf`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=7Uf_ADtGTIY`,
      ],
    },    
    {
      description: [
        `Psychological Warfare Being Bestowed On High School Kids.`,
        `Student threatened to be kicked out of school for not wearing a mask. School lies and says they have the authority to dictate this, when upon further inspection, they actually don't.`,
      ],
      urls: [
        `https://rumble.com/vnn8of-psychological-warfare-being-bestowed-on-high-school-kids.html`,
      ],
    },
    {
      description: [
        `Father speaks at school board about how his severely disabled daughter was still forced to wear a mask even though it brought her great trauma!`,
      ],
      urls: [
        `https://gettr.com/post/pz8j1e2cd1`
      ],
    }, 
    {
      description: [
        `4th grader makes speech to school board about required masks.`,
      ],
      urls: [
        `https://www.bitchute.com/video/7RoP2Hwc8WRV/`,
      ],
    },    
    {
      description: [
        `Dr. Leana Wen, CNN's Medical Analyst:`,
        `"... don't wear a cloth mask, <strong>cloth masks are little more than facial decorations,</strong> there's no place for them in line of Omicron."`,
      ],
      urls: [
        `https://www.bitchute.com/video/y-neGkjc88k/`,
      ],
    },
    {
      description: [
        `Mom Confronts Superintendent with Her Own Lies on Mask Rule`,
        `"Unfortunately politics leads us to believe there is only one solution: masks.`,
        `Yet thousands of schools around the country have been open during the entire pandemic without masks, and <strong>no corresponding rise in serious illness.</strong>`,
        `There were no child coffins lined up, as some educators in this county suggested would be the case.`,
        `We are segregating children by vaccination status, and by religious exemption status, <strong>despite the wearing of masks.</strong>`,
        `So I ask you, if masks work, why don't they?"`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=2KuuyI1OWhk`,
      ],
    },  
    {
      description: [
        `Students WALK OUT to protest mask mandates`,
        `"We're done".`,
      ],
      urls: [
        `https://twitter.com/OTURISK/status/1492933294534672387`,
      ],
    },  
    {
      description: [
        `Children hate masks. Screaming with joy when told they don't have to wear masks anymore.`,
      ],
      urls: [
        `https://twitter.com/Breaking911/status/1492010506537873424`,
      ],
    },
    {
      description: [
        `The hypocrisy of celebrities with no masks in a stadium with a mask mandate, vs kids in California being forced to wear them in school.`,
      ],
      urls: [
        `https://twitter.com/ClayTravis/status/1493028002007035916`,
      ],
    },  
    {
      description: [
        `CNN medical expert Leana Wen on mandatory face masks:`,
        `"The science has changed."`,
        `"I don't think we should be looking at case counts at all at this point."`,
        `<strong>"There actually is a harm that we should be discussing about children continuing to mask.</strong>`
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1491065164749287427`,
      ],
    }, 
    {
      description: [
        `Man's story of a little girl telling her mom "look mom, no mask", then tells him "I really like your face."`,
        `<strong>"It got to me, about how much damage we're doing to these kids, that it's not even normal for them to see somebody's face."</strong>`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1491231878636097536`
      ],
    },
    {
      description: [
        `So, what was the point?`,
        `<b>Cloth masks allow 90% of particles to filter through giving them little ability to prevent  COVID transmission, study finds</b>`,
        `Cloth masks barely filter any particles, making them near-useless for prevention of Covid spread`,
      ],
      urls: [
        `https://www.dailymail.co.uk/health/article-10565993/Cloth-masks-allow-90-particles-filter-giving-little-ability-prevent-Covid.html`
      ],
    },
    {
      description: [
        `<b>The Mask: Remembering Slavery, Understanding Trauma</b>`,
        `This essay by Grada Kilomba was presented to the Bicentenary of the Abolishment of the Atlantic Slave Trade, at the National Maritime Museum, in London.`,
        `The mask, she writes, symbolizes colonialism.`,
        `It represents the white <b>sadistic politics of conquest and domination</b>, and its brutal regimes of <b>silencing the so called "Other."</b>`,
        `<b>Slave Tortures: The Mask, Scold's Bridle, or Brank</b>`,
        `Images and descriptions of the types of masks slaves were forces to wear.`
      ],
      urls: [
        `https://www.africavenir.org/nc/news-details/article/the-mask-remembering-slavery-understanding-trauma.html`,
        `https://usslave.blogspot.com/2011/09/slave-tortures-mask-scolds-bridle-or.html`,
      ],
    },
    {
      description: [
        `<b>Covid Land: The Mask (episode 2) Documentary</b>`,
        `"The masses have never thristed after truth. They turn aside from evidence that is not to their taste, preferring to deify error, if error seduces them. Whoever can supply them with illusions is easily their master; whoever attempts to destroy their illusions is always their victim." - Gustave le Bon (1895)`,
      ],
      urls: [
        `https://www.bitchute.com/video/Vs2McGGrsW9K/`,
      ],
    },
    {
      description: [
        `Another woman was told that if she did not wear her mask, she would be refused medical assistance. Her partner, viewing her obvious distress, removed her mask at some point, but the staff told him off.`,
        `“I had the same mask during the whole labour; I was unable to breathe properly and therefore unable to push for 12 hours. I suffered a lot and the gynaecologist had to use medical spatulas to help my son out.`,
        `“As a result, my son was born with a lump and with amniotic fluid in his lungs and he was transferred to another hospital. As for me, I have a tear that is still healing and trauma for me and my partner.`,
        `<b>I don't imagine I will ever be able to give birth again.”</b>`,
        `If that is not institutionalized abuse, I do not know what is.`,
      ],
      urls: [
        `https://katewand.substack.com/p/obstetrical-violence`
      ],
    },
    {
      description: [
        `An article/chart that suggest the state of Missouri's health dept suppressed data that "found masks work" has been making the rounds lately with a few particular posts getting thousands of likes and retweets.`,
        `In reality, the data completely contradict what they're saying.`,
      ],
      urls: [
        `https://threadreaderapp.com/thread/1467141861886369796.html`,
        `https://twitter.com/The_OtherET/status/1467141861886369796`,
      ],
    },
    {
      description: [
        `Year round mask-wearing societies had upticks seasonally. Airborne viral particulate can;t be stopped by cloth. I can't believe to have to type this.`,
        `Look back at CDC guidance for H1N1 airborne viral pandemic. No mention of masks.`,
      ],
      urls: [
        `https://twitter.com/ALittleTweet/status/1467182428137549825`
      ],
    },
    {
      description: [
        `<b>Masks are theater</b>`,
        `What happens when the live TV broadcasting of politicians calling for the continuation of mask mandates starts earlier than expected...`,
        `Everyone on camera scrambling to put their masks on because they didn't realize they were on camera.`,
      ],
      urls: [
        `https://twitter.com/VallachianR/status/1437835477000749056`
      ],
    },
    {
      description: [
        `Celebrities at the mask-mandated SuperBowl, not wearing masks. Rules for thee, not for me.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=TpdJwXAP5tI`
      ],
    },
    {
      description: [
        `Mother goes for the jugular of the school board over mask mandates.`,
        
      ],
      urls: [
        `https://gettr.com/post/pvzff44d60`
      ],
    },
    {
      description: [
        `The NDP and their special interest groups were angry that the mask mandate on school kids was lifted 3 weeks ago.`,
        `The NDP Labour Federation sued us to force kids to be masked. The teachers union threatened to do the same.`,
        `<b>3 weeks later, school outbreaks have plummeted.</b>`,
      ],
      urls: [
        `https://twitter.com/jkenney/status/1498397843836518409`
      ],
    },
    {
      description: [
        `It's our job to NOT accommodate their fears. Bret Weinstein's Dark Horse Podcast.`,
        `"You've disordered the development of children, and now you're going to take what they claim as the god's honest truth, and we need to change what we do to accommodate their desires and fears?`,
        `No, it's our job to do the <b>opposite</b>. It's our job to NOT accommodate their fears and to help them undo some of the damage that we have done to them."`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=cfdeaxpTUD8`
      ],
    },
    {
      description: [
        `Matt Strauss: I'm a doctor. Here's why I'm done with masking`,
        `Many self-styled “experts” are demanding mask mandates be reinstituted in Ontario. We need to be very careful. In modern medicine, our knowledge base is founded on RCTs. At present, the best evidence we have suggests that cloth masks are of almost no value and community masking is of little value to individuals under 50. Anyone who promotes a return to cloth masking or masking for the sake of children would appear to be out of touch with this knowledge base and therefore, definitionally, not an expert.`,
      ],
      urls: [
        `https://nationalpost.com/opinion/matt-strauss-im-a-doctor-heres-why-im-done-with-masking`
      ],
    },
    {
      description: [
        `Passengers on a Delta Airlines flight cheer after the end of the federal mask mandate is announced.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1516223444299526150`
      ],
    },
    {
      description: [
        `FEDERAL JUDGE Rules BIDEN Airplane Mask Mandates UNCONSTITUTIONAL. Lawyer's Opinion Review`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=cfLn01xODyM`
      ],
    },
    {
      description: [
        `An Office Manager from Florida and a Gates-Funded Professor Are Censoring Studies Linking Mask Usage to Increased Deaths.`,
        `“The findings presented in this short communication suggest that countries with high levels of mask compliance did not perform better than those with low mask usage in the six-month period that encompassed the second European wave of COVID-19…”`,
        `“Moreover, the moderate positive correlation between mask usage and deaths in Western Europe also suggests that the universal use of masks may have had harmful unintended consequences…”`,
      ],
      urls: [
        `https://thenationalpulse.com/2022/06/06/politifcked-an-office-manger-from-florida-and-a-gates-funded-professor-are-censoring-studies-linking-mask-usage-to-increased-deaths/`
      ],
    },
    {
      description: [
        `Mask theatrics in the Canadian House of Commons`,
      ],
      urls: [
        `https://twitter.com/CanAditude/status/1534998520410816513`
      ],
    },
    {
      description: [
        `CDC no longer recommends universal masking in health facilities`,
      ],
      urls: [
        `https://thehill.com/policy/healthcare/3661963-cdc-no-longer-recommends-universal-masking-in-health-facilities/`
      ],
    },
    {
      description: [
        `<b>'I would never support a mask mandate now': Five questions with an infectious diseases expert</b>`,
        `In an effort to gain a better understanding of the situation and the calls for a return to mask mandates, I turned to a well-known infectious diseases expert and physician who has worked tirelessly to better inform the public during this challenging era of both viral and social contagion.`,
        `<b>Q: There's a lot of noise out there right now, with that same group of 'Covid zero' activists calling for a return to mask mandates, particularly for kids. Would you support a return to mask mandates this winter?</b>`,
        `A: Definitely not. We have gotten to almost the three-year mark of this pandemic. The whole world has some form of immunity or the other. What is the goal of a mask mandate at this point? On a population level, we are not going to stop or even slow down transmission. The mask mandate of the past has outlived its expiry date. There is no need for one going forward. I would never support a mask mandate now.`,
      ],
      urls: [
        `https://acceptableviews.substack.com/p/i-would-never-support-a-mask-mandate`
      ],
    },
    {
      description: [
        `Concerned father speaks to Ottawa Public School trustees about Nili Kaplan-Myrth's cruel motion to mandate masks at school.`,
      ],
      urls: [
        `https://twitter.com/canmericanized/status/1595204494740328448`
      ],
    },
    {
      description: [
        `<b>MANDATES VETOED: Alberta Education Minister Adriana LaGrange asked if she'll allow school boards ability to set mask mandates.</b>`,
        `"Many of the health challenges that we're addressing today, not only came from the challenges of the learning disruption, but I also hear from parents, and teachers, and children themselves that it was the masking that contributed to those challenges, and to those feelings of anxiety, some have had issues with breathing properly, learning properly.. so of course the very reason we're here today is to address the mental health and wellness of our students, and ensure they are very much able to learn in person as much as possible.`
      ],
      urls: [
        `https://twitter.com/canmericanized/status/1593390966282412033`
      ],
    },
    {
      description: [
        `<b>Mask mandates in schools: Premier's Statement</b>`,
        `"Our government will not permit any further masking mandates of children in Alberta's K-12 education system. The detrimental effects of masking on the mental health, development and education of children in classroom settings is well understood, and we must turn the page on what has been an extremely difficult time for children, along with their parents and teachers.`,
        `"I have directed our Justice minister to assess whether an appeal of Thursday's Kings Bench Court decision is appropriate, and have instructed our government's ministers of Justice, Health and Education to alert me to any legislative or regulatory changes that may be necessary to reaffirm or clarify our government's full authority with respect to this and other health and education matters."`
      ],
      urls: [
        `https://www.alberta.ca/release.cfm?xID=849096DD15AB5-E385-CA84-7B8F4CF97A916DE9`
      ],
    },
    {
      description: [
        `World leaders treated masks as pure theater.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1631926187759902722`
      ],
    },
    {
      description: [
        `Hypocrisy flip flop of health officials on the use of masks.`,
        `From "very weak" and "no protection at all", to becoming "mandatory".`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1631923137636868096`
      ],
    },
    {
      description: [
        `“My daughter stood firm in her decision to no longer wear a mask to school. And for that, she was suspended 11 times. Think about how absurd that sounds. That even the New York Times recently had to admit that masks were essentially ineffective and useless."`,
        `Covid restrictions created an inhumane and disgraceful neglect of children's welfare.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1631288273665421313`
      ],
    },
    {
      description: [
        `New Cochrane review (gold standard of evidence-based medicine) on efficacy of masks concludes:`,
        `“The pooled results of RCTs did not show a clear reduction in respiratory viral infection with the use of medical/surgical masks.”`,
      ],
      urls: [
        `https://twitter.com/DrEliDavid/status/1620333259711459328`,
        `https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD006207.pub6/full?s=08`,
      ],
    },
    {
      description: [
        `<b>Stephanie Warriner died after being CHOKED TO DEATH by security in a Toronto hospital for having her MASK too low.</b>`,
        `Coroner's report ruled choking was direct cause: she died "due to restraint asphyxia following struggle & exertion”`,
        `Guards were ACQUITTED`,
      ],
      urls: [
        `https://twitter.com/MaajidNawaz/status/1615311562301923328`,
        `https://hotair.com/david-strom/2023/01/16/outrageous-but-unsurprising-canadian-judge-tosses-case-against-guards-who-killed-a-woman-for-not-wearing-a-mask-n524001`,
      ],
    },
    {
      description: [
        `Health Canada has issued an advisory and asked distributors to recall masks sold with graphene or biographene in them over potential inhalation risks.`,
        `Another "conspiracy theory" becomes fact from CTV.`,
      ],
      urls: [
        `https://twitter.com/Aceso76/status/1642544874217168896`,
        `https://www.ctvnews.ca/mobile/health/health-canada-issues-advisory-for-face-masks-containing-graphene-1.5372822`,
      ],
    },
    {
      description: [
        `Korean news item describing damage done to children's language development by masking.`,
        `Once again 180 degree turn by 3 year cheerleader for vaccination and masking.`,
      ],
      urls: [
        `https://twitter.com/ptr12343/status/1605748504889942017`
      ],
    },
    {
      description: [
        `L.A. County is considering another indoor mask mandate.`,
        `The decision will be made by Dr. Barbara Ferrer, an unelected, non-medical doctor who previously banned outdoor dining without showing any data/science to support it, then ordered TVs be shut off at businesses on top of it.`,
      ],
      urls: [
        `https://twitter.com/BillFOXLA/status/1603910126448033793`
      ],
    },
    {
      description: [
        `Remember when governments banned plastic straws to save the planet?`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1600762486038241281/photo/1`
      ],
    },
    {
      description: [
        `The Independent:`,
        `<b>1.6 billion disposable masks entered the ocean in 2020 and will take 450 years to biodegrade</b>`,
        `Waste from throwaway masks created a mass of plastic waste around 7 per cent of the size of the Great Garbage Plastic Patch`,
      ],
      urls: [
        `https://www.independent.co.uk/climate-change/news/masks-ocean-covid-plastic-b1893830.html`
      ],
    },
    {
      description: [
        `People being asked to leave at heated debate at Hamilton-Wentworth School Board mtg on mask mandate`,
      ],
      urls: [
        `https://twitter.com/canmericanized/status/1599960245240897536`
      ],
    },
    {
      description: [
        `Reporter: <b>"Isn't time to really realize that masks may really not be as effective as we try to make them out to be?"</b>`,
        `COVID Response Coordinator Ashish Jha: "Higher quality masks work better than lower quality masks. [Biden] wears high quality masks."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1550210803776856067`
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Masks in hospital, don't work`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=zviCtcZjN-I`,
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `Masks, no evidence they work`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=S3vY2LyQn1A`,
      ],
    },
    {
      description: [
        `<b>"The major efficacy of a mask is that it causes alarm in the other person so you stay away from each other."</b>`,
        `Laurie Garrett is an American science journalist and former Senior Fellow in the Council on Foreign Relations. Garrett has been a leading voice promoting draconian Covid mandates for the last two years—especially masks. In a video that's resurfaced from the National Academy of Medicine in 2018, Garrett gives us the real rationale for mask mandates.`,
        `"There's only a couple of countries that have ever really done large-scale studies to try and figure out what might work. Japan, it may not surprise you, is one of them. In one of their large studies they basically showed that the masks, it seemed like the major efficacy of a mask is that it causes alarm in the other person and so you stay away from each other. And that's what I think happened with SARS. When I was in the SARS epidemic I saw everywhere all over Asia people started wearing these masks, and it is alarming, when you walk down the street and everyone coming toward you has a mask on, you definitely do social distancing, you definitely—it's just a gut thing. But did the mask really help them? Did the mask keep the virus out? Almost certainly not. If the virus was around their face, the mask would not have made a difference."`,
      ],
      urls: [
        `https://michaelpsenger.substack.com/p/the-major-efficacy-of-a-mask-is-that?s=r`,
        `https://boriquagato.substack.com/p/none-of-the-experts-really-believed`,
        `https://www.youtube.com/watch?v=sSExbHTS3nE`,
      ],
    },
    {
      description: [
        `Behind Closed-Door Testimony, Fauci Unable to Name Any Studies Showing Masks Work`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=c_aNdH3aDzQ`
      ],
    },
    {
      description: [
        `<b>Tim Horton's employee won't give woman in drive through her coffee if she doesn't have a mask on.</b>`,
        `Woman: Hi`,
        `Employee: Hi do you have a mask?`,
        `W: No I don't.`,
        `E: I can give you one.`,
        `W: Uh.. you can give me one?`,
        `E: I can give you one, I need you to wear a mask.`,
        `W: So you can hand me a mask..`,
        `E: I can hand you one, yeah.`,
        `W: But you can't hand me the drink without a mask?`,
        `E: ... y-you've gotta wear a mask.`,
        `W: How does that make any sense?`,
        `E: ... that's what. that's what I.. I just need you to wear a mask.. and you.. do you mind?`,
        `W: Well if you can hand me a mask, why can't you just hand me the drink?`,
        `E: ... I can hand you the mask.`,
      ],
      urls: [
        `https://twitter.com/MrGamatos/status/1449088771408990218`
      ],
    },
    {
      description: [
        `Justice Centre for Constitutional Freedoms:`,
        `<b>Know your rights on mandatory facemasks</b>`,
        `---`,
        `If someone is unable to wear a mask because of a mental disability, such as claustrophobia, a business would be engaging in illegal discrimination if it denied services to such a person for not wearing a mask, and did not provide some form of reasonable accommodation.`,
        `A requirement to wear a mask as a condition of employment, or as a condition to receive a service, may be discriminatory toward people with exemptions. If a business denies such a person service or employment because they refuse to wear a mask, that denial may form a legally valid basis for a human rights complaint.`,
        `Some people refuse to wear a mask for religious reasons. Other people cannot or should not wear masks because of various medical and health conditions. Many of the municipal bylaws are worded broadly enough to exempt those with “health concerns” or ” health conditions”, including mental conditions like claustrophobia. Laws must not disproportionately punish the vulnerable who are unable to wear masks.`,
        `---`,
        `There are jurisdictional questions regarding mask bylaws, as well. Cities have no inherent jurisdiction to enact laws because they are entirely creatures of statute: their power is delegated from provinces through legislation. If a province has chosen not to enact mandatory masking requirements, then what empowers a municipality to do so?  The answer may well be “nothing”.`,
        `---`,
        `If asked to wear a mask, you can reply, “I can't wear a mask.” A store or company can ask if you have a doctor's note due to ignorance of the law, however, you are under no legal obligation to provide a note, discuss your medical condition, or get into detail about why you cannot or will not wear a mask. The exception to this is if you file a Human Rights Complaint. You are likely going to be required to provide proof as part of the complaint process.`,
        `---`,
        `No one can physically force you to cover your face in a free country, however you may have to shop in stores that welcome all customers regardless of disability or condition. Stores may choose to enforce the mask bylaw on their premises, but customers will likewise choose not to spend their hard-earned money in that store. In this economy, can stores afford to turn away customers?`,
        `---`,
        `Importantly, medical offices, hospitals, and nursing homes are the most difficult places to exercise a mask exemption. These are places full of sick people, where the risk of catching any kind of cold, flu or virus is high and can result in death. However, Alberta Health Services produced a COVID information instruction sheet  which clearly stated: “No patient shall be denied service in AHS because they cannot or will not wear a mask.” This has since been removed from the original website.`,
        `---`,
        `In short, the local municipal governments are on shaky legal ground in imposing mask by-laws. Businesses in mandatory-mask municipalities are only enforcing what they are told to do, and businesses that voluntarily impose mask requirements have a right to do so as private, non-government entities, with several “human rights” caveats.`,
        `Know your rights.`,
        `---`,
        `So what can the average person do?`,
        `1. Do not wear a mask, and exercise your exemption.`,
        `2. Hope that other people in Alberta become tired or frustrated with mask-wearing and refuse to comply in very large numbers.`,
        `3. Write your various levels of government demanding mandatory masks be removed from the bylaws; object vigorously to the mandate. Do not just write once. Flood their office, and your local member of parliament, city officials, mayors with alderman, clear, short concise objection letters and make it clear this is a voting issue to you. (Shorter is better. Writing a three-page letter to these government officials is less effective than writing 3 paragraphs. Keep it short and pointed.)`,
        `Protest. In LARGE numbers. Not hundreds. Thousands.`,
        `Wait for legal challenges to the mask mandates that do not go after masks, but attack the mandates on the basis of freedom of expression, free speech, parental rights, or freedom of religion.`,
        `When you are outright refused service and not accommodated, for each store that discriminates, file a human rights complaint with the your provincial human rights commission.`,
        `Shop in stores that have made customers welcome even if they do not or cannot wear a face covering, and refuse to patronize stores that do not. Ensure you write the head offices of these stores telling them that you are patronizing their store because of their decision to welcome all.`,
      ],
      urls: [
        `https://www.jccf.ca/know-your-rights-on-mandatory-facemasks/`,
        `https://www.jccf.ca/wp-content/uploads/2020/11/Know-your-rights-on-mandatory-facemasks-.pdf`,
      ],
    },
    {
      description: [
        `A new study has found there is no evidence that masks—including so-called “high-quality” masks such as N95 and KN95 masks—protect the vulnerable from COVID.`,
        `<b>The Telegraph: No evidence face masks protected vulnerable from Covid, health officials admit</b>`,
      ],
      urls: [
        `https://twitter.com/michaelpsenger/status/1646244247375609856`,
        `https://www.telegraph.co.uk/news/2023/04/12/face-masks-protect-no-evidence-covid-lockdown/`,
      ],
    },
  ],
  Mandates: [
    {
      description: [
      `Dr. Naomi Wolf dire warning about vaccine passports in March 2021`,
      `“Once vaccine passports are accepted, there is no more choice. There is not more resistance.”`,
      `“The vaccine passport system is <strong>the same as a social credit system</strong>”.`,
      `Watch Dr Naomi Wolf Discuss <strong>"Why Vaccine Passports Equal Slavery Forever"</strong>.`,
      `These are already in Canada.`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=pWoDbGNt4wg`,
      ],
    },
    {
      description: [
      `Free Speech Banned In Australia.`,
      `<strong>“Even vaccinated people who speak against the vaccine are absolutely 'anti-vaxxers'”.</strong>`,
      `Government officials speaking literally of thought-crime. Compelled speech. They don't allow for a range of perspectives. They are aggressive to you if you don't use their mandated language. This is more what I'd expect to see in a place like North Korea.`,
      `Look at his crazed eyes. And aggressive tone and demeanor. We have to contend with the fact that this type of person is who we must obey, though we may never tolerate being spoken to in this way in person. Are you convinced he's acting that way only because he cares about his people and their health?`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=UPYe-cwNjPY`,
      ],
    },
    {
      description: [
      `AUSTRALIA - The army is now transferring positive Covid cases and contacts in the Northern Territories to 'Quarantine Camps' by army truck.`,
      `So it wasn't a conspiracy theory then. `,
      `Do you remember how the holocaust started? It was 6 years of slowly removing the diseased, unclean Jews from society, tranferring them by government-funded orgs like the army, incrementally. <strong>Over 6 years.</strong>`,
      ],
      urls:[
        `https://twitter.com/BernieSpofforth/status/1462740215571918849`,
      ],
    },
    {
      description: [
      `British Columbia covid quarantine camps being built`,
      ],
      urls:[
        `https://www.bitchute.com/video/c2dbUkQdOYBM/`,
      ],
    },
    {
      description: [
      `Luc Montagnier, Nobel Prize winner in Medicine, refuses to be vaccinated. Speaks about it for 10 minutes.`,
      ],
      urls:[
        `https://odysee.com/@TruthPills:5/luc-montagnier-hold-up:6`,
      ],
    },
    {
      description: [
      `Creator of mRNA technology, Dr. Robert Malone, speaks about mandates and global totalitarianism.`,
      ],
      urls:[
        `https://rumble.com/vpmqap-the-future-of-global-totalitarianism-is-here.html`,
      ],
    },
    {
      description: [
      `Austrian police checking restaurant goers' “papers”. `,
      `<strong>“Papers Please”</strong>. Remember Germany in the 1930s?`,
      ],
      urls:[
        `https://twitter.com/DoctorOntario/status/1466092055025836042`,
      ],
    },
    {
      description: [
      `Liberal writer, Naomi Wolf speaks about the dangers of vaccine mandates, saying a possible crime against humanity may be being committed.`,
      ],
      urls:[
        `https://rumble.com/vpmp8d-possible-crime-against-humanity-being-committed.html`,
      ],
    },
    {
      description: [
      `German Health Official announces all Germans will be <strong>Vaccinated, Cured or Dead</strong>.`,
      `+ Mandatory vaccinations in Austria.`,
      ],
      urls:[
        `https://rumble.com/vpmq2d-german-health-official-announces-all-germans-will-be-vaccinated-cured-or-de.html`,
      ],
    },
    {
      description: [
      `If you haven't seen the footage of the international protests against vaccine mandates, they are huge, frequent, and apparently aren't covered by our local media.`,
      ],
      urls:[
        `https://rumble.com/vpmour-international-riots-are-becoming-a-revolution.html`,
        `https://rumble.com/vpmofr-hundreds-of-thousands-rebel-against-coercion.html`,
      ],
    },
    {
      description: [
      `Massive protests in a ton of countries against vaccine mandates.`,
      `Are all these people just “misinformed idiots” like our government tells us?`,
      ],
      urls:[
        `https://twitter.com/NeverSleever/status/1460124343766237186`,
      ],
    },
    {
      description: [
      `Austria will send people to concentration-camp-style “quarantine camps” if the refuse a forced injection from the government.`,
      `Many mainstream sources.`,
      ],
      urls:[
        `https://www.bitchute.com/video/lqhzfYJROZuJ/`,
      ],
    },
    {
      description: [
      `Austrian man calls for help regarding forced vaccination before christmas. Lockdown for unvaccinated is already in place.`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=BZkdbWcav2E`,
      ],
    },
    {
      description: [
      `Nova Scotia's chief medical officer describes the unvaccinated as angry, selfish people who are "believing misinformation and false narratives."`,
      `The government has now labeled its own people as its enemies, as groups to which it's “ok” to be aggressive to, to publicly shame, and to one-sidedly discredit with its own misinformation.`,
      ],
      urls:[
        `https://twitter.com/RebelNewsOnline/status/1461474401845760005`,
      ],
    },
    {
      description: [
      `Teacher of 30+ years gets fired for not getting the vaccine or complying with rapid testing.`,
      `Heartbreaking. Government getting rid of the people who care the most, replacing them with who will comply the easiest.`,
      ],
      urls:[
        `https://twitter.com/ChickenGate/status/1461179856104873988`,
      ],
    },
    {
      description: [
      `Australian nurses about to lose their jobs over not getting the covid vaccine: "THE HOSPITALS ARE FULL OF VACCINATED PATIENTS", "30 OUT OF 38 IN ICU ARE VACCINATED"`,
      `<strong>Says firmly “Serious adverse effects. Serious.”</strong>`,
      ],
      urls:[
        `https://www.bitchute.com/video/vWqrYtWRrtxh/`,
      ],
    },
    {
      description: [
      `A warning about vaccine passports from a litigator in August 2021:`,
      `Ep. 8: Social Division - The Tyranny of the Vaccine Passport`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=dAQa3Sjff70`,
      ],
    },
    {
      description: [
      `Man refused antibodies because he is white.`,
      ],
      urls:[
        `https://www.bitchute.com/video/P3SIDl13XL3c/`,
      ],
    },
    {
      description: [
      `Employment lawyer tells CTV employer's do NOT have the right to terminate an employee based on vaccination status.`,
      ],
      urls:[
        `https://twitter.com/nisbett_jon/status/1449410264470806529`,
      ],
    },
    {
      description: [
      `“No later than September 2019, the Canadian government was fully aware and participating in what was to be a global exercise, to test the readiness of the world, to deal with a respiratory pathogen pandemic, that was planned and published in September 2019, and the Canadian government was very much a part of that”`,
      ],
      urls:[
        `https://www.tiktok.com/@1businessmentor/video/7022297309966896390`,
      ],
    },
    {
      description: [
      `Canadian lawyer: The “Vaccine Passport” is in Effect in Quebec - My Thoughts`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=8bCVU870Hq8`,
      ],
    },
    {
      description: [
      `Walmart in Quebec is BANNING Unvaccinated from Entering`,
      `"I'm sorry sir they are imposing this on us."`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=PtTqEV5aWWY`,
      ],
    },
    {
      description: [
        `Ontario health minister Christine Elliott says the province has <strong>no plans to drop its vaccine passport system or mask mandate.</strong>`,
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1491464573874061316`
      ],
    },
    {
      description: [
        `Quebec Health Minister Christian Dubé admits that vaccine passports and mandatory masks have no end in sight, and <strong>will remain “tools” to ensure a return to “normal life”</strong> when another wave hits.`,
      ],
      urls: [
        `https://twitter.com/TheMarieOakes/status/1491125346619772929`
      ],
    },
    {
      description: [
      `Alberta WCB says you can claim compensation for an adverse vaccine reaction as a result of compulsory immunization by a company.`,
      `However many businesses either withhold this information from their employees, or deceptively state otherwise or the opposite.`,
      ],
      urls:[
        `https://www.wcb.ab.ca/assets/pdfs/workers/WFS_COVID-19_vaccine_reactions.pdf`,
      ],
    },
    {
      description: [
      `Alex Lucifero, employment lawyer on workplace vaccine requirements`,
      ],
      urls:[
        `https://stlawyers.ca/blog-news/alex-lucifero-employment-lawyer-on-workplace-vaccine-requirements/`,
        `https://twitter.com/nisbett_jon/status/1449410264470806529`,
      ],
    },
    {
      description: [
      `Samfiru Tumarkin LLP Employment Lawyers: Can My Employer Mandate the COVID-19 Vaccine?`,
      ],
      urls:[
        `https://stlawyers.ca/coronavirus-knowledge-centre/employer-mandatory-vaccinations/#fired`,
      ],
    },
    {
      description: [
      `Samfiru Tumarkin LLP Employment Lawyers: COVID-19 Workplace Vaccinations Mandates: What are your employment rights?`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=YvQWdmqGIAI`,
      ],
    },
    {
      description: [
      `'We Are Going To Contest That Immediately': DeSantis Fires Back At Federal COVID-19 Vaccine Mandate`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=MYIIlCDILas`,
      ],
    },
    {
      description: [
      `Russell Brand - THIS Is What History WARNS Us`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=_8ZcOWYsE7M`,
      ],
    },
    {
      description: [
      `Reuters - Germany to limit public life for the unvaccinated.`,
      `This should hopefully sound familiar, and therefore disturbing.`,
      ],
      urls:[
        `https://www.reuters.com/world/europe/german-region-hardest-hit-by-covid-surge-plans-partial-lockdown-report-2021-11-18/`,
      ],
    },
    {
      description: [
      `Old man who survived German fascism and communist systems, says he had more freedom then than he has now in Canada.`,
      ],
      urls:[
        `https://twitter.com/Caring_Canuck/status/1460375315902078984`,
      ],
    },
    {
      description: [
      `Robert Gruler, criminal defense lawyer talks about Biden's national vaccine mandates and its problems.`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=vEay9FNBxH4`,
      ],
    },
    {
      description: [
      `Dr. Renier Fullmich talks about the disinformation about PCR tests and therefore the pandemic itself, and actions they are taking.`,
      ],
      urls:[
        `https://www.bitchute.com/video/8zYmvJCpGC5r/`,
      ],
    },
    {
      description: [
      `Los Angeles County Sheriff Alex Villanueva Warns Of 'Mass Exodus' Over Vaccine Mandate`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=HvoJGviRRXA`,
      ],
    },
    {
      description: [
      `Matthew McConaughey Stuns NY Times When Asked About Mandate for Kids | Direct Message | Rubin Report`,
      ],
      urls: [
        `https://rumble.com/vp11x4-matthew-mcconaughey-stuns-ny-times-when-asked-about-mandate-for-kids-direct.html`,
      ],
    },
    {
      description: [
      `The Orwellian State of our Universities - Gad Saad - Canadian professor`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=TZdLl8y8bow`,
      ],
    },
    {
      description: [
      `We regret to inform you that your cancer operation has been cancelled. But we "may" be able to do chemo.`,
      `If that isn't Canadian healthcare collapsing, I don't know what the hell it is.`,
      ],
      urls:[
        `https://twitter.com/JayNakoda/status/1482321930225172485`,
      ],
    },
    {
      description: [
      `Israel has one of the most authoritarian vaccine / lockdown / quarantine / censoring laws in the world.`,
      `A WARNING FROM ISRAEL ABOUT VACCINE PASSPORTS AND APARTHEID`,
      `Not letting children as young as 16 take their exams without the vaccine.`,
      `No malls or theatres for individuals without vaccination.`,
      `Disclosure of private medial records to local municipalities.`,
      ],
      urls:[
        `https://www.bitchute.com/video/tXpVvIkRKCy2/`,
      ],
    },
    {
      description: [
      `DeSantis Responds To Biden's New OSHA Rule Setting Jan. 4 Deadline For Vaccine Mandate.`,
      `From 15 days to slow the spread to, get the jab or you lose your job.`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=uMlcnmS46SA`,
      ],
    },
    {
      description: [
      `WHAT THE ACTUAL FUCK? BY MAX IGAN`,
      ],
      urls:[
        `https://www.bitchute.com/video/0PNL5NdPMoJV/`,
      ],
    },
    {
      description: [
      `“New World Order” for decades and decades`,
      ],
      urls:[
        `https://www.youtube.com/watch?v=riTzKucQt0k`,
      ],
    },
    {
      description: [
        `Senator Rand Paul On Truckers, COVID Tyranny, Hypocrites, And Civil Disobedience`,
        `"So there a benefit from having had the disease, and there's a benefit from having had the vaccine. But people should be allowed to make this choice, and it's wrongheaded to make these mandates on the doctors and nurses because many of them have already had the disease, they actually have <strong>better immunity than the vaccine</strong> and yet we're still punishing them."`,
        `"... it was reported that so many doctors and nurses had covid, they were letting them continue to work while they were positive with covid.`,
        `So what would you rather have? A doctor who is actually coughing up covid, or a doctor that's already had it from two years ago, doesn't have a vaccine, but isn't sick? We're actually letting sick doctors with covid work, as opposed to letting who's unvaccinated work."`
      ],
      urls:[
        `https://www.youtube.com/watch?v=eabr9uHEDxc`,
      ],
    },
    {
      description: [
        `Alberta Premier Jason Kenney: Early next week, the COVID cabinet committee will approve a plan to lift restrictions, starting with the Restrictions Exemption Program.`,
        `<strong>We can - and must - get on with our lives, restore our freedoms, and live with joy, not fear.</strong>`
      ],
      urls: [
        `https://twitter.com/jkenney/status/1489635789763866624`
      ],
    },
    {
      description: [
        `Doug Ford recorded saying, "we're pulling these passports"`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=5I-eoIlU_zw`
      ],
    },
    {
      description: [
      `Covid Land: The Lockdown (episode 1) Documentary`,
      ],
      urls:[
        `https://www.bitchute.com/video/mRHJhe1Wjbcr/`,
      ],
    },
    {
      description: [
      `CovidLand: The Mask (episode 2) Documentary`,
      ],
      urls:[
        `https://www.bitchute.com/video/Vs2McGGrsW9K/`,
      ],
    },
    {
      description: [
        `<strong>DESANTIS: Firing unvaccinated nurses and doctors is "absolutely insane."</strong>`,
        `"The medical mandate for the nurses and the doctors, what they're trying to do is absolutely insane.`,
        `In other states they have fired nurses for not having vaxx, even though most of them have natural immunity.`,
        `So they fire them, but now they're short-handed, so what are they doing? They're bringing back on the job vaccinated nurses who are currently covid-positive.`,
        `<strong>So if you're unvaccinated, naturally immune and uninfected, they fire you, but if you're covid-positive and vaxxed, which we know most of the people who are covid-positive now are vaxxed, they are going back on the job.</strong>`,
        `And it just shows you that CMS mandate is absolutely insane, especially given the ineffectiveness of these shots to actually stop transmission."`
      ],
      urls: [
        `https://twitter.com/ElectionWiz/status/1481999532997197830`
      ],
    },
    {
      description: [
        `Man's son gets evicted from Ronald McDonald House because his father isn't vaccinated.`,
        `Hitler's willing executioners come in the most subtle forms. Regular humans. Following rules.`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1481105206976864257`,
        `https://twitter.com/thevivafrei/status/1481100941235531780`,
      ],
    },
    {
      description: [
        `Last year, the owners of these shoes were heroes saving the lives of Albertans, according to AHS.`,
        `This year, AHS wants us to believe that they are a danger to Albertans.`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1473128807481577476`
      ],
    },
    {
      description: [
        `<b>Unvaccinated man denied access to purchase food at a farmers market in Toronto, Canada.</b>`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1472762865207713793`
      ],
    },
    {
      description: [
        `Boeing suspends its vaccine mandate for employees.`,
        `<b>Translation: Boeing needs the expertise and experience of its employees more than the employees need Boeing.</b>`,
        `Companies knew this all along and needed to not be “the first” to exercise common business sense.`
      ],
      urls: [
        `https://twitter.com/_kelvinchew/status/1471925504232075282`
      ],
    },
    {
      description: [
        `Victory Against Hamilton School Board's Vaccine Mandate`,
      ],
      urls: [
        `https://brightlightnews.com/1936-2/`
      ],
    },
    {
      description: [
        `Will it take more than two shots to be considered fully vaccinated?`,
        `Canada's new Health Minister: "Probably...almost certainly."`,
      ],
      urls: [
        `https://twitter.com/Roman_Baber/status/1468561576840024071`
      ],
    },
    {
      description: [
        `Shortly after Austria became first country to make COVID vaccines compulsory, EU chief Ursula von Der Leyen calls for dispensing with the Nuremberg Code and making vaccination mandatory across Europe.`,
        `Here's Ursula last month with Pfizer CEO Albert Bourla`,
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1466150597422321671`,
        `https://www.bbc.com/news/world-europe-59497462`,
      ],
    },
    {
      description: [
        `LEAKED. In Canada, gov flowchart to systematically mass violate individual bodily integrity, security of the person, and fundamental rights.`,
        `Either employyee is put on Leave Without Pay, or "the employee is compliant, must update their attestation and continues their works as usual and any temporary measures in place can be lifted 2 weeks after their second dose."`,
      ],
      urls: [
        `https://twitter.com/denisrancourt/status/1468430915793739786/photo/1`
      ],
    },
    {
      description: [
        `They Demanded My “Papers” Today`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=nDZ-qWPVn3k`
      ],
    },
    {
      description: [
        `Why do we need vaccine passports?`,
        `Canadian Covid Care Alliance goes through the evidence why we DON'T`,
      ],
      urls: [
        `https://www.bitchute.com/video/GEA1VvYOGHXl/`
      ],
    },
    {
      description: [
        `<b>COVID-19 vaccine now mandatory to get euthanized in Germany</b>`,
        `Amid rising COVID infections and the looming possibility of mandatory vaccination across Europe, the German Euthanasia Association Verein Sterbehilfe is ahead of the curve. In a statement made this week, the organization declared it will only euthanize those who have been vaccinated or recovered from the disease.`,
      ],
      urls: [
        `https://nationalpost.com/news/covid-19-vaccine-now-mandatory-to-get-euthanized-in-germany/`
      ],
    },
    {
      description: [
        `The South Africa President refuses vaccine mandates and vaccine passports.`,
      ],
      urls: [
        `https://twitter.com/aginnt/status/1467158278895509513`
      ],
    },
    {
      description: [
        `<b>"Advised that the minimum dose interval for booster jabs should be halved, from six months, to three months."</b>`,
        `Coming to Canada soon: Booster shots after three months instead of six. Fourth shot now available.`,
        `And don't forget: You will lose your #VaccinePassport and become a non-person if you don't keep up with the times.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1466061209875189761`
      ],
    },
    {
      description: [
        `Lawyer Robert Barnes speaks about the courts, legalitites, and environment around covid measures / mandates / etc.`,
      ],
      urls: [
        `https://www.bitchute.com/video/0FUgCUiYfIYH/`,
        `https://www.bitchute.com/video/D6zJk8dxIE0w/`,
      ],
    },
    {
      description: [
        `<b>It's over in the UK</b>`,
        `Paul Joseph Watson`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=RAUOM5V2IYE`
      ],
    },
    {
      description: [
        `Croatian MEP Mislav Kolakušić is one of the few decent people speaking in the EU Parliament.`,
        `<b>"Covid passes are one of the dumbest ideas in human history"</b>`,
      ],
      urls: [
        `https://gettr.com/post/pvi76c7440`
      ],
    },
    {
      description: [
        `Look at how fast these lying, hypocrite politicians throw employers under the bus for following their orders… no honour among scoundrels. Forget “rehiring”. Any employer who fired someone for not being vaccinated should get sued. As should the government.`,
        `<b>Ontario health says "a mandate was not our direction".</b>`,
        `Backpedaling cowards.`
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1497715577498091524`
      ],
    },
    {
      description: [
        `Tomorrow (March 1, 2022) Alberta will move forward as the first province to drop almost all COVID-19 restrictions.`,
        `It's a safe and prudent plan to get back to normal. - Jason Kenney, Premier of Alberta`,
      ],
      urls: [
        `https://twitter.com/jkenney/status/1498497000307712009`
      ],
    },
    {
      description: [
        `The NDP and their special interest groups were angry that the mask mandate on school kids was lifted 3 weeks ago.`,
        `The NDP Labour Federation sued us to force kids to be masked. The teachers union threatened to do the same.`,
        `<b>3 weeks later, school outbreaks have plummeted.</b>`,
      ],
      urls: [
        `https://twitter.com/jkenney/status/1498397843836518409`
      ],
    },
    {
      description: [
        `Jason Keneny`,
        `Federal travel restrictions are hurting our economy with no measurable public health benefit.`,
        `It's time to learn to live with COVID, and that means ending unnecessary restrictions.`,
        `We will be introducing a motion calling on Ottawa to drop pointless travel restrictions.`,
        `Quotes many health authorities in Canada and around the world.`,
      ],
      urls: [
        `https://twitter.com/jkenney/status/1500588295234850818`
      ],
    },
    {
      description: [
        `<b>The Unintended Consequences of COVID-19 Vaccine Policy: Why Mandates, Passports, and Segregated Lockdowns May Cause more Harm than Good</b>`,
        `University of Toronto Faculty of Law`,
      ],
      urls: [
        `https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4022798`,
        `https://www.law.utoronto.ca/events/unintended-consequences-covid-19-vaccine-mandates-why-they-may-cause-more-harm-good`
      ],
    },
    {
      description: [
        `<b>Full mandate debate on Parliament Hill in Ottawa, Canada.</b>`,
        `A very important discussion of all aspects of Canadian vaccine mandates and the actual science of adverse effects.`
      ],
      urls: [
        `https://www.youtube.com/watch?v=DeTdxUSVD7A`,
        `https://parlvu.parl.gc.ca/Harmony/en/PowerBrowser/PowerBrowserV2/20220326/-1/36722`,
      ],
    },
    {
      description: [
        `Chief Ontario Public Health Kieran Moore passes the buck to individual employers. This was never a vaccine law`,
        `Incredible!  The Ontario government is now saying they never had a vaccine mandate. - They obviously don't want to deal with the fall out of employment and health lawsuits that will be coming their way.`,
      ],
      urls: [
        `https://rumble.com/vxyzo7-ontario-public-health-chief-admits-there-were-no-vax-mandates.html`
      ],
    },
    {
      description: [
        `UK Health Secretary: Zero Covid has been a disaster`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=E7yZZKlg-m8`,
        `https://unherd.com/thepost/sajid-javid-zero-covid-has-been-a-disaster/`,
      ],
    },
    {
      description: [
        `The Public Health Agency says there "aren't any specific numbers" to justify keeping the mandates in place.`,
        `Why? Because it's not science. It's political science.`,
      ],
      urls: [
        `https://twitter.com/MikeBarrettON/status/1535049994562043907`
      ],
    },
    {
      description: [
        `The moment when the government's expert witness who was relied upon to justify vax mandates for travel-Director General at Transport Canada's “Covid Recovery Team”-is stunned into silence and has to admit there was no document supporting a recommendation for mandates`,
      ],
      urls: [
        `https://twitter.com/LionAdvocacy/status/1556761269000085504`,
        `https://threadreaderapp.com/thread/1556761269000085504.html`,
      ],
    },
    {
      description: [
        `COVID-19 Antigen test kit now updated to include Influenza A and B as well.`,
        `If the antigen test tests positive when encountering Influenza, how was Covid19 ever different?`
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1596566018700333062`
      ],
    },
    {
      description: [
        `An Estimated 30,000 Americans Were Killed by Ventilators & Iatrogenesis in April 2020`,
      ],
      urls: [
        `https://michaelpsenger.substack.com/p/an-estimated-30000-americans-were`
      ],
    },
    {
      description: [
        `Never forget what they did to people for "health and safety"`,
      ],
      urls: [
        `https://twitter.com/hodgetwins/status/1589341654858616832`
      ],
    },
    {
      description: [
        `"I'm not going to forget either."`,
        `Reasons against "covid amnesty".`
      ],
      urls: [
        `https://twitter.com/TomMarazzo/status/1589611291643203584`
      ],
    },
    {
      description: [
        `<b>Town councillor in West Nipissing, Ontario delivers an appology at their meeting from the Vaccine mandates.</b>`,
        `“This policy caused alot of animosity within our work force and community” `,
        `“The general public now knows today that the vaccine doesn't not stop the spread of Covid-19”`,
      ],
      urls: [
        `https://twitter.com/therobprimo1/status/1632020511088582656`
      ],
    },
    {
      description: [
        `L.A. County is considering another indoor mask mandate.`,
        `The decision will be made by Dr. Barbara Ferrer, an unelected, non-medical doctor who previously banned outdoor dining without showing any data/science to support it, then ordered TVs be shut off at businesses on top of it.`,
      ],
      urls: [
        `https://twitter.com/BillFOXLA/status/1603910126448033793`
      ],
    },
    {
      description: [
        `This man Matt Strickland @MattForVA  kept his business open during Covid and ignored the mandates, so the state suspended his licenses and just raided his business.`,
      ],
      urls: [
        `https://twitter.com/hodgetwins/status/1599740496003272704`,
        `https://www.wusa9.com/article/news/local/virginia/restaurant-owner-defies-covid-orders-loses-license-continues-to-sell-alcohol-search-and-seizure/65-a10cf0c0-2339-4bdd-85a4-46c5e7b16ea7`,
      ],
    },
    {
      description: [
        `A fully vaccinated Canadian senior was given a 14-day quarantine order because she did not use the Trudeau Government's ArriveCAN app. “I shouldn't have to go through this, no Canadian should," she said.`,
      ],
      urls: [
        `https://twitter.com/elie_mcn/status/1550175201085865993`
      ],
    },
    {
      description: [
        `Jacinda Ardern, New Zealand Prime Minister:`,
        `Questions about "what do we do if someone refuses to be tested?"`,
        `Well you can't now.`,
        `If someone refuses in our facilities to be tested, they have to keep staying.`,
        `So they won't be able to leave after 14 days, they have to stay on for another 14 days.`,
        `<b>So it's a pretty good incentive. You either get your tests done and make sure you're clear, or we will keep you in a facility longer.</b>`,
        `So I think most people will look at that and say, "I'll take the, I'll take the test.`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1618193559152381952`
      ],
    },
    {
      description: [
        `<b>Justice Centre for Constitutional Freedoms (JCCF)<b>`,
        `<b>Mandatory Covid Vaccine FAQ</b>`,
        `In Canada, people have a right to decide what medical treatment they receive or do not receive. The Covid shots are new, and clinical trials will not be complete until 2023. As such, the Covid shots are experimental. Each of the shots also contain warnings from Health Canada, including warnings about pericarditis, myocarditis, and thrombosis. It is unthinkable that British Columbia is requiring these experimental injections as a condition of full participation in society, yet this is what the BC government is doing. The Justice Centre opposes this profound violation of peoples' constitutional rights. Moreover, the vaccinated and the unvaccinated both spread Covid-19 in equal measure, as members of both groups carry the same high viral loads when infected, according to data from the Center for Disease Control (CDC).`,
        `---`,
        `If these mandates are not challenged for violating our Charter rights and freedoms, or if courts side with the government, this will open the door to even greater government control over Canadians’ rights and freedoms, and our bodies.`,
        `---`,
        `We are unaware of any previous circumstance where a pharmaceutical company has rushed a product to market and has no liability for deaths or injuries, where no long-term studies have been conducted, where governments have induced, coerced and threatened people to get it, and where Canadians may lose their jobs and civil liberties for refusing to take it. This is unprecedented.`,
        `---`,
        `The Justice Centre sees these laws as unconstitutional, full stop. Seeking exemptions to the law implies that the underlying law is valid, and it is our position that it is not. Canadians have constitutionalized protections for conscience, religion, and security of the person including bodily autonomy. Mandatory vaccine policies and legal discrimination against the unvaccinated minority are blatant violation of Charter rights and freedoms.`,
      ],
      urls: [
        `https://www.jccf.ca/mandatory-covid-vaccine-faqs/`,
        `https://www.jccf.ca/wp-content/uploads/2021/09/Mandatory-Covid-Vaccine-FAQ.pdf`,
      ],
    },
    {
      description: [
        `<b>Justice Centre for Constitutional Freedoms (JCCF)<b>`,
        `<b>Vaccine passports betray freedom-loving Albertans</b>`,
      ],
      urls: [
        `https://www.jccf.ca/vaccine-passports-betray-freedom-loving-albertans/`
      ],
    },
    {
      description: [
        `September 22, 2021`,
        `"So Denmark removes all restrictions and covid infections fall 50% in ten days.`,
        `Everything is open. No one is wearing masks. People go to restaurants and events.`,
        `Danes are now living life like normal."`,
      ],
      urls: [
        `https://twitter.com/PeterSweden7/status/1440715785366736902`
      ],
    },
    {
      description: [
        `<b>CBC: 172 Windsor, Ont., hospital staff suspended without pay for not getting COVID-19 shots</b>`,
        `September 22, 2021`,
      ],
      urls: [
        `https://www.cbc.ca/news/canada/windsor/dozens-windsor-essex-hospital-staff-1.6185369`
      ],
    },
    {
      description: [
        `Nursing shortage prevents BC's nurse union from supporting vaccine mandate`,
      ],
      urls: [
        `https://thepostmillennial.com/nursing-shortage-bc-union-vaccine-mandate/`
      ],
    },
    {
      description: [
        `Strange isn't it.`,
        `<b>Quebec and BC both brought in vax passports before Ontario but they still have higher cases counts.</b>`,
        `I thought that one tool was the solution to everything!`,
      ],
      urls: [
        `https://twitter.com/brianlilley/status/1441126959367426050`
      ],
    },
    {
      description: [
        `Alberta puts pause on further lifting COVID-19 restrictions until Sept. 27: Hinshaw | FULL | Global News`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=8SlUxgM0yp4`
      ],
    },
    {
      description: [
        `Stavanger, Norway celebrates the end of all Norway's COVID restrictions with a huge party and fireworks. (september 26, 2021)`,
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1442214156279508992`
      ],
    },
    {
      description: [
        `September 7, 2021: "Quebec to require vaccine passports for hospital visitors as COVID-19 cases climb."`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1442877156304900098`
      ],
    },
    {
      description: [
        `October 2, 2021: <b>15,000 Quebec healthcare workers face suspension without pay for being unvaccinated</b>`,
      ],
      urls: [
        `https://thepostmillennial.com/15000-quebec-healthcare-workers-suspension-unvaccinated`
      ],
    },
    {
      description: [
        `Sep 27, 2021`,
        `Harvard Business School just shuttered in-person classes due to an allegedly "distressing" surge in "cases" among its overwhelmingly vaccinated student population.`,
        `Now all students must submit to "surveillance testing" three times a week. The cycle of elite stupidity continues`,
        `---`,
        `<b>The Harvard Crimson</b>`,
        `"If you need to interact with many people in a single day, keep your mask on, limit each interaction to under 15 minutes, and don't stand closer than necessary," Nguyen wrote, advising that students keep the number of people closer than 6 feet from them "as low as possible".`,
        `Last Friday, Cambridge announced it would reinstate its indoor mask mandate, effective Friday. The same day, Boston began to require coverings in all indoor public settings.`,
      ],
      urls: [
        `https://twitter.com/mtracey/status/1442614746738348033`
      ],
    },
    {
      description: [
        `<b>Flashback to Trudeau saying the federal government will pay for provincial vaccination programs.</b>`,
        `We're going to work with provinces and territories who want to move forward on vaccination certifications, on vaccination passports, so that everyone can be safe.`,
        `And what's more, the federal government has announced we're going to pay for the development of those <b>privilages that you get once you get vaccinated.</b>`,
        `<b>Because everyone needs to get vaccinated, and *points away* "those people" are putting us all at risk.</b>`,
      ],
      urls: [
        `https://twitter.com/Phil2284/status/1645660217936596992`
      ],
    },
  ],
  MedicallyAssistedDeath: [
    {
      description: [
        `A man with a rare neurological disease requests for assisted home care, instead he is being offered a medically assisted death.`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1591979383723819008`
      ],
    },
    {
      description: [
        `<b>William Makis states that the College of Physicians and Surgeons of Ontario 🇨🇦 is suggesting to Ontario doctors that "unvaccinated" people are mentally ill and in need of psychiatric medication.</b>`,
        `<b>Makis: "That is a very dangerous slippery slope that we're on."</b>`,
        `"So this has come out recently out of the College of Physicians and Surgeons of Ontario.`,
        `The College sent out a letter or a memo to all the doctors in Ontario, suggesting to them.. now so far they're not "mandating" it, they're just "suggesting" it.. that any of their unvaccinated patients, that they should consider that <b>they have a mental problem, and that they should be put on psychiatric medication.</b>`,
        `So far it's just a suggestion, but the College of Physicians and Surgeons of Ontario should not be making these kinds of suggestions, this is extremely unethical, and this is a very, very slippery slope, if they're suggesting that people who wish to have bodily automony, and don't want an experimental vaccine, that there may be something mentally wrong with them? That is a very very dangerous, slippery slope that we're on."`
      ],
      urls: [
        `https://twitter.com/PaulMitchell_AB/status/1593890035388284928`
      ],
    },
    {
      description: [
        `Dr. Louis Roy of the Quebec College of Physicians suggests babies under 1 with "severe deformations" and "very grave and severe medical syndromes" should be eligible for assisted death.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1578515411095932928`
      ],
    },
    {
      description: [
        `<b>Bill Gates talks about "Death Panels"</b>`,
        `"You know, is spending a million dollars on that last three months of life for that patient, would it be better not to lay off those ten teachers, and, the, make that tradeoff in medical costs?`,
        `But that's called the "Death Panel", uh, and you're not supposed to have that discussion..`,
        `Society is making, we're making that tradeoff, because of huge medical costs that are not examined to see which ones actually have no benefit whatsoever, and because of pension generosity, we will be laying off over 100,000 teachers, which, you know, I'm very much against that, uh, and the whole AFT will agree with me on that."`
      ],
      urls: [
        `https://www.bitchute.com/video/7SprNopB0i1o/`
      ],
    },
    {
      description: [
        `Canadian COMMERCIAL for assisted suicide feels dystopian and demonic.`,
      ],
      urls: [
        `https://twitter.com/BowTiedRanger/status/1596957492940275712`
      ],
    },
    {
      description: [
        `<b>Just another day in the life of a Canadian in which its government is trying to kill them what is going on here in Canada this is genocide by one's own hand</b>`,
        `Woman is recommended MAID for anxiety and depression from MAID rep.`,
      ],
      urls: [
        `https://twitter.com/PushieJim/status/1621538454591995906`
      ],
    },
    {
      description: [
        `People are shocked by this Liberal Govs creation of the loosest Medical Assistance in Dying (MAiD) laws in the world. Now a national charity suggests MAiD expansions for 12 year old kids.`,
        `Canadians need support to live, not invitations to end their life.`,
      ],
      urls: [
        `https://twitter.com/LeslynLewis/status/1621218380891004929`,
        `https://www.dyingwithdignity.ca/blog/pr_mature_minors/`,
      ],
    },
    {
      description: [
        `Young Canadian man shares how he feels about MAiD (Medical Assisted in Dying) being offered with such a low threshold to be eligible. `,
      ],
      urls: [
        `https://twitter.com/ohcanadawakeup/status/1642345594265149440`
      ],
    },
    {
      description: [
        `Amazing what some doctors consider rewarding work…`,
        `"It's the most rewarding work we've ever done": Canadian doctor who's euthanized 400 people proudly shares how she helped kill man deemed incapable of choosing assisted suicide - as another physician says she's helped 300 die.`,
      ],
      urls: [
        `https://twitter.com/annmcelhinney/status/1612338297023651841`
      ],
    },
    {
      description: [
        `<b>CBC Report: Medically assisted deaths could save millions in health care spending.</b>`,
        `Across Canada, journal calculates up to $136.8 M in savings`,
        `Doctor-assisted death could reduce annual health-care spending across the country by between $34.7 million and $136.8 million, according to a report published in the Canadian Medical Association Journal on Monday.`,
        `Like $1 - $4 of savings per Canadian.`,
        `---`,
        `For contrast, at the G20 Summit in Bali, Indonesia, Prime Minister Justin Trudeau announced that Canada will provide $500 million in additional military assistance for Ukraine to assist the Armed Forces in defending their country against Russia's brutal and unjustifiable invasion`,
        `From 5 to 14 times the maximum savings calculated for MAID in Canada.`,
        `MAID isn't about not having enough money to spend.`,
      ],
      urls: [
        `https://www.cbc.ca/news/canada/manitoba/medically-assisted-death-could-save-millions-1.3947481`
      ],
    },
    {
      description: [
        `Woman featured in pro-euthanasia commercial wanted to live, say friends`,
        `'I feel like I'm falling through the cracks so if I'm not able to access health care am I then able to access death care?' Hatch said in a CTV interview`,
      ],
      urls: [
        `https://nationalpost.com/news/canada/woman-euthanasia-commercial-wanted-to-live`
      ],
    },
    {
      description: [
        `Penny Daflos:`,
        `By now you've likely seen the Simons ad w a woman talking about dying in a fairytale setting. It's been called dystopian, disturbing, glamourizing euthanasia. To me it's also baffling. I spent hours talking to Jennyfer as she fought to LIVE a little longer`,
        `Jennyfer Hatch reqd anonymity as she spoke about finding "deathcare" much easier than healthcare in @Fraserhealth Her situation was complex, but ultimately our systems let her down In June she weighed 89lbs, was facing homelessness w a looming eviction`,
        `Jennyfer had Ehlers-Danlos, a rare, poorly-understood condition in Canada bc few medical professionals study or treat it. Another BC woman, the same age w different complications, also spoke out about her struggles for treatment and consideration of MAiD`,
        `When "Kat" spoke about MAiD, she hoped the social safety net would catch her. It did not. But she did trigger national awareness of what advocates had been warning: poverty + disability + illness + expanding medical assistance in dying = a dangerous combo `,
        `Simons tells me the ad campaign has come to an end this week and that's why the video is no longer on YouTube. That may be, but American media have just become aware of the film and it's become a big talking point for right-wing commentators`,
        `Jennyfer and I had spoken for many hours about her struggles and her goals before "Kat" told her story. She was angry, frustrated, wanted to prompt change for others since she was slowly dying. She was, however, ineligible for palliative care. None of that is in the Simons piece`,
        `Some people are criticizing Jennyfer's decision to be involved, but one anti-MAiD advocate is glad she found peace and a way to capture something beautiful in her final days on her own terms. Really, it's the Simons sponsorship still doesn't make much sense to anyone`,
        `Polling shows most Canadians support having the option of a medically-assisted death if they're terminally ill and suffering. But what about mental suffering? That's coming`,
        `Denise in Toronto is another example of a disabled person whose poverty is a major factor in seeking MAiD. Are Canadians ok with that? Do companies want to uphold a flawed system where some feel death is their only escape from suffering driven by poverty?`,
        `These are complex issue but I think the Simons video (the full version is 3 mins) hits different knowing Jennyfer's backstory. It's still disturbing and she'd anticipated the controversy, which her friend said is part of the reason she did it`,
      ],
      urls: [
        `https://twitter.com/PennyDaflos/status/1598736821651136512`,
        `https://bc.ctvnews.ca/b-c-woman-behind-dystopian-commercial-found-death-care-easier-than-health-care-1.6177877`,
        `https://bc.ctvnews.ca/easier-to-let-go-without-support-b-c-woman-approved-for-medically-assisted-death-speaks-out-1.5937496`,
        `https://bc.ctvnews.ca/after-years-waiting-for-surgery-b-c-woman-considering-medically-assisted-death-1.5922445`,
        `https://bc.ctvnews.ca/outraged-and-distraught-reaction-to-disabled-b-c-woman-s-approval-for-medically-assisted-death-1.5939338`,
        `https://www.ctvnews.ca/health/canada-should-delay-maid-for-people-with-mental-disorders-psychiatrists-1.6177908`,
        `https://toronto.ctvnews.ca/toronto-woman-in-final-stages-of-maid-application-after-nearly-a-decade-long-search-for-housing-1.6145487`,
        `https://bc.ctvnews.ca/b-c-woman-behind-dystopian-commercial-found-death-care-easier-than-health-care-1.6177877`,
      ],
    },
    {
      description: [
        `Canada offers disabled veteran “aid to die” when she asks for a stairlift`,
        `---`,
        `Checking in on the liberal paradise that is Canada. <b>Disabled Canadian Army veteran Paralympian blasts government for offering to EUTHANIZE her when she complained about how long it was taking for install chairlift at her home.`,
      ],
      urls: [
        `https://twitter.com/EndWokeness/status/1599270657589530625`,
        `https://www.dailymail.co.uk/news/article-11497589/Paraplegic-Canadian-veteran-says-government-caseworker-offered-euthanasia.html`,
        `https://twitter.com/Babygravy9/status/1599070958459015171`,
      ],
    },
    {
      description: [
        `After assisted suicide was legalized in Canada, many severely ill patients have found themselves effectively forced into "voluntary" euthanasia by healthcare costs, or even hospitals just refusing to treat them.`,
        `<b>Euthanasia is now the sixth leading cause of death in Canada.</b>`,
        `Possibly related: In 2005, the Canadian Supreme Court said wait times for health care in Canada were so long that they violated patients' "liberty, safety and security".`,
        `In 2021, the Fraser Institute found that the median wait time to see a specialist after a referral in Canada was 25.6 weeks.`,
        `---`,
        `Ontario man who was denied assisted home care and told it would cost more than $1,500 per day to keep him in the hospital. He actually secretly recorded hospital staff encouraging him to kill himself.`,
        `A veteran was offered euthanasia by a hospital in Canada because he has PTSD.`,
        `A disabled veteran and paralympian was offered medically assisted suicide as an alternative to a stairlift in Canada.`,
        `Canada is euthanizing 10,000 people per year.`,
      ],
      urls: [
        `https://twitter.com/ReviewsPossum/status/1558624091439042562`
      ],
    },
    {
      description: [
        `<b>Medical experts call on government to delay expansion of MAID for mental illness</b>`,
        `The association representing the lead psychiatrists at Canada's 17 medical schools is calling on the federal government to delay the expansion of assisted dying to people with mental illness, joining an increasingly vocal group of doctors who say proper safeguards are not yet in place`,
      ],
      urls: [
        `https://www.theglobeandmail.com/canada/article-maid-delay-mental-illness-medical-experts`
      ],
    },
    {
      description: [
        `Canada harvests more organs from euthanized patients than any other nation in the world`,
        `The Canadian Institute for Health Information states that this new means of acquiring organs now accounts for six percent of all transplants from the deceased in Canada in 2021.`,
      ],
      urls: [
        `https://thepostmillennial.com/canada-harvests-more-organs-from-euthanized-patients-than-any-other-nation-in-the-world`
      ],
    },
    {
      description: [
        `MAID in Canada: “Non violent medical intervention”`,
        `MAID in Third Reich: “Mercy deaths”`,
        `MAID in other words: “State-sponsored medicalized mass murder”`,
        `---`,
        `<b>The Nazi Physicians as Leaders in Eugenics and “Euthanasia”: Lessons for Today</b>`,
        `---`,
        `<b>Centre for suicide prevention infographic:</b>`,
        `What is the difference between suicide and Medical Assisted in Dying (MAID) in Canada?`,
        `1.`,
        `Suicide: people thinking about suicide do not actually want to die, they want the pain of living to end.`,
        `MAID: hastens death for people in irremediable circumstances whose death is reasonably foreseeable.`,
        `2.`,
        `Suicide: often carried out alone and in secrecy, leaving loved ones with devastating grief.`,
        `MAID: involves decision-making by medical personnel and the option to involve loved ones.`,
        `3.`,
        `Suicide: often violet and carried out by people who do not enter or are misunderstood by the medical system.`,
        `MAID: non-violent medical intervention.`,
        `4.`,
        `Suicide: with appropriate support and care, recovery is possible.`,
        `MAID: increased palliative care can help ease the suffering of dying, though death remains reasonably foreseeable.`,
      ],
      urls: [
        `https://twitter.com/katewand/status/1626087497234018305`,
        `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5719686/`,
      ],
    },
    {
      description: [
        `<b>Canada soon to allow euthanasia for the mentally ill</b>`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1618327450978770945/photo/2`
      ],
    },
    {
      description: [
        `On March 17, 2023, Canada will expand MAiD for mental illness without any plan to provide comprehensive access to mental healthcare for people who desperately need it.`,
        `In other words, it will be easier to access MAiD than to access mental healthcare. This is beyond alarming.`,
      ],
      urls: [
        `https://twitter.com/birgitomo/status/1597363287335010304`,
        `https://www.canada.ca/en/health-canada/services/medical-assistance-dying.html`,
      ],
    },
    {
      description: [
        `Dying with Dignity - Canada:`,
        `12 year olds making the state-sanctioned decision to end their lives.`,
        `---`,
        `DWDC acknowledges that Canadian society will likely expect a minimum age for mature minrs in the legislation, even though the emphasis at common law is on capacity and maturity and not chronological age.`,
        `<b>For this reason, DWDC asks that Parliament amend the existing age requirement of 18 years of age to extend it to persons: "at least 12 years of age and capable of making decisions with respect to their health."</b>`,
        `As with adults, there should be a presumption of capacity for these minors.`,
      ],
      urls: [
        `https://twitter.com/Golden_Pup/status/1597337449944190977/photo/1`
      ],
    },
  ],
  ModernDayConcentrationCamps: [
    {
      description: [
        `Co-founder of Mozilla and CEO of Brave browser, Brendan Eich, retweeting image of Australian “quarantine” / concentration camp.`,
      ],
      urls: [
        `https://twitter.com/BrendanEich/status/1466563113315041280`,
      ],
    },
    {
      description: [
        `Australian “quarantine” / concentration camps have taken promo shots of happy people in the camps.`,
        `These images side by side, constrasted with the actual, more tyrannical situation.`,
        `"Think they'll fall for it?"`,
      ],
      urls: [
        `https://twitter.com/biddle_leo/status/1465915250306211841`,
      ],
    },
    {
      description: [
        `Ontario - “confirming additional $23.7 million of federal funding to operate <strong>isolation sites</strong> in Ontario.`,
        `Additional “1,600 rooms to be created”`,
      ],
      urls: [
        `https://twitter.com/AnonCitizenUK/status/1465641166209269762`,
      ],
    },
    {
      description: [
        `Man speaks from heavily monitored Australian quarantine camp`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1476742676674400283`
      ],
    },
    {
      description: [
        `Quarantine facility in Trenton Ontario`,
        `"People are being held against their will here, there is security"`
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1472310756905529351`
      ],
    },
    {
      description: [
        `Covid detention camp in Canada`,
        `Man held against his will for at least 3 days, being given no answers as to when he's allowed to get out, forced to eat non-nourishment as food. Security coaxes videographer back in their room.`
      ],
      urls: [
        `https://twitter.com/WaltonDesousa/status/1468784532224712710`
      ],
    },
    {
      description: [
        `Meanwhile in Howard Springs Quarantine Camp`,
        `"This is an official warning that you have to stay on your balcony and obey the rules while you're here."`
      ],
      urls: [
        `https://twitter.com/OzraeliAvi/status/1466715838967205892`
      ],
    },
    {
      description: [
        `Hidden camera footage of forced internment camps in Australia.`,
        `"You can't leave your balcony to go to the fence to talk to somebody else. That's just obvious"`,
        `<b>"Again, it doesn't have to make sense."</b>`
      ],
      urls: [
        `https://www.bitchute.com/video/NCWl8dhIRY24/`
      ],
    },
    {
      description: [
        `Millions of chinese people are living in covid quarantine camps now! 2022/1/9`,
      ],
      urls: [
        `https://twitter.com/songpinganq/status/1480157037681995779`
      ],
    },
    {
      description: [
        `<b>Millions of chinese people are living in covid quarantine camps now! 2022/1/9</b>`,
        `<b>For those who don't know what china's covid quarantine camps looks like. This may help you.</b>`,
        `You need to pay for your own isolation in China.`,
        `This is how chinese government feed covid inmates. Like feeding cattles!`,
        `From April 2020 these covid quarantine camps started to be set up everywhere.`,
        `This is how chinese covid inmates in a camp interacting with esch other. The movie'' war for the planet of the apes'', anyone remember?`,
        `Sometimes kids will be locked alone without their parents guardianship.`,
        `This is how chinese government will forcibly take you to covid quarantine camp If you don't comply...`,
        `Most times chinese government will take you to covid quarantine camp with bus in the middle of night. I guess even they themselves think this behavior is a little shade,so they usually try not to get attention ( take people away in the night).`,
        `Tianjin city. Countless buses are taking people away to covid quarantine camps. 2022/1/16`,
        `This is how authority treat those who escape from city's lockdown and want to go back to their hometown`,
        `Henan province Ningling county - These two ( three ) men had already spent 21 days in this quarantine camp, but the security guards don't let them leave. Why? Because you need to pay off your quarantine expenses before they discharge you from Covid camps in China. 2022-06-24`,
        `Beijing - This quarantine camp is for foreigners. The quarantine expense here is much higher, about three times as Chinese ones. It starts from 600 yuan ( 90 dollars ) per day. Government finished its construction yesterday and today it's full of Covid inmates now. 2022-06-25`,
        `Jiangsu province Nantong city - You need to pay 250 yuan ( 37.5 dollars ) a day for your 21 days's food and accommodation in this quarantine camp.`,
      ],
      urls: [
        `https://twitter.com/songpinganq/status/1480157517485203463`
      ],
    },
  ],
  PastParallels: [
    {
      description: [
      `<strong>CARING CORRUPTED - The Killing Nurses of The Third Reich:</strong>`,
      `Cizik School of Nursing has created a REMI Platinum Award-winning documentary film that tells the grim cautionary tale of nurses who participated in the Holocaust and abandoned their professional ethics during the Nazi era. The 56-minute film, Caring Corrupted: the Killing Nurses of the Third Reich, casts a harsh light on nurses who used their professional skills to murder the handicapped, mentally ill and infirm at the behest of the Third Reich and directly participated in genocide.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=Rz8ge4aw8Ws`,
      ],
    },    
    {
      description: [
      `<strong>How did ordinary citizens become murderers?</strong>`,
      `<strong>United States Holocaust Memorial Museum</strong>`,
      `In the Holocaust era, countless ordinary people acted in ways that aided and hindered the persecution and murder of Jews and other targeted groups within Nazi Germany and across Europe. `,
      ],
      urls: [
        `https://www.youtube.com/watch?v=92UfAJr7790`,
      ],
    },
    {
      description: [
        `<strong>Pierre Trudeau's “Just watch me” interview in 1970 addressing the War Measures Act, from the kidnappings of the October Crisis.</strong>`,
        `Trudeau: "There's a lot of bleeding hearts around who don't like to see people with helmets and guns, all I can say is go on and bleed, but it's more important to keep law and order in this society than to be worried about weak-kneed people who don't like the looks of a--"`,
        `Reporter: "At any cost? At any cost? How far would you go with that, how far would you extend that?"`,
        `Trudeau: "Just watch me."`,
        `Reporter: "At reducing civil liberties? ... to that extent?"`,
        `Trudeau: "To what extent?"`,
        `Reporter: "Well if you extend this, and you say ok, you're going to do anything to protect them... include wiretapping? Reducing other civil liberties in some ways?"`,
        `Trudeau: "Yes I think the society must take every means at its disposal to defend itself against the, uh, emergent of a parallel power which defies the elected power in the country, and I think that goes to <i>any distance</i>."`,
        `"And I repeat, it's only weak-kneed bleeding hearts who are afraid to take these measures." Pierre Trudeau 1970`,
      ],
      urls: [
        `https://twitter.com/oldcanadaseries/status/1490269516080861191`,
      ],
    },
    {
      description: [
        `<strong>"And that's how it is done ... step by step ... slowly."</strong> - Auschwitz survivor 94-year-old Marian Turski`,
        `"And they become acquainted with that thought, familiar with the idea that they are different people, that they are alien people, that they are <strong>the people that carry germs that cause pandemics...</strong> and this now is a horror."`
      ],
      urls: [
        `https://twitter.com/JorJorWell/status/1481029996508553223`
      ],
    },
    {
      description: [
        `<b>LOCKDOWN: The Line Dividing Good & Evil | Kate Wand</b>`,
        `It began with park benches, Jews cannot sit here. A sign at a swimming pool, Jews cannot enter. Gradually, the people were acclimated to the idea that someone could be excluded, that a group could be stigmatized.`,
      ],
      urls: [
        `https://twitter.com/stacey_rudin/status/1470135226793926660`,
        `https://www.youtube.com/watch?v=CWQ8iDizXlk`,
      ],
    },
    {
      description: [
        `History repeating itself. It took Nazi Germany 5 years to take away everyone's guns in the name of safety. From 1938 to 1943.`,
      ],
      urls: [
        `https://twitter.com/CanAditude/status/1531430423171047425`
      ],
    },
    {
      description: [
        `Bill Maher: If you're part of today's woke revolution, you need to study the part of revolutions where they spin out of control.`,
      ],
      urls: [
        `https://twitter.com/billmaher/status/1621741159201800192`
      ],
    },
    {
      description: [
        `Analogy of the downward spiral of socialism.`,
      ],
      urls: [
        `https://twitter.com/markmaycot/status/1614720293838135296`
      ],
    },
    {
      description: [
        `Cartoon from 60 years ago talks about unions and government control.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1554143800410472455`
      ],
    },
    {
      description: [
        `MAID in Canada: “Non violent medical intervention”`,
        `MAID in Third Reich: “Mercy deaths”`,
        `MAID in other words: “State-sponsored medicalized mass murder”`,
        `---`,
        `<b>The Nazi Physicians as Leaders in Eugenics and “Euthanasia”: Lessons for Today</b>`,
        `---`,
        `<b>Centre for suicide prevention infographic:</b>`,
        `What is the difference between suicide and Medical Assisted in Dying (MAID) in Canada?`,
        `1.`,
        `Suicide: people thinking about suicide do not actually want to die, they want the pain of living to end.`,
        `MAID: hastens death for people in irremediable circumstances whose death is reasonably foreseeable.`,
        `2.`,
        `Suicide: often carried out alone and in secrecy, leaving loved ones with devastating grief.`,
        `MAID: involves decision-making by medical personnel and the option to involve loved ones.`,
        `3.`,
        `Suicide: often violet and carried out by people who do not enter or are misunderstood by the medical system.`,
        `MAID: non-violent medical intervention.`,
        `4.`,
        `Suicide: with appropriate support and care, recovery is possible.`,
        `MAID: increased palliative care can help ease the suffering of dying, though death remains reasonably foreseeable.`,
      ],
      urls: [
        `https://twitter.com/katewand/status/1626087497234018305`,
        `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5719686/`,
      ],
    },
  ],
  Politics: [
    {
      description: [
        `Tim Gielen Follows the shareholders for the largest companies. Only a few own almost every industry humans depend on, and in practice are more powerful than governments.`,
      ],
      urls: [
        `https://rumble.com/vn7lf5-monopoly-who-owns-the-world-must-see.html`,
      ],
    },
    {
      description: [
        `Joe Biden, who hastily pulled out of Afghanistan, leaving all the army's weapons and vehicles to the taliban, talks in 2007 about how doing what he did in 2021 is a terrible idea.`,
      ],
      urls: [
        `https://www.bitchute.com/video/4Qdokp1VKDMl/`,
      ],
    },
    {
      description: [
        `Candace Owens speaks about the destruction of families and the historical parallels where this was a purposeful tactic for government control.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=wsGlsrpre4Y`,
      ],
    },
    {
      description: [
        `'We're nowhere near a wake-up call': Michael Pillsbury on the CCP's (Chinese Communist Party)'s plans to surpass the US.`,
        `Chinese hypersonic missile testing.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=sJnq82wFS8E`,
      ],
    },
    {
      description: [
        `Canada's Cold War | Kate Wand | Bruce Pardy`,
        `Essay on Socialism in Canada and quickly-creeping authoritarian / socialist control.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=RFQ_Er1-f3M`,
      ],
    },
    {
      description: [
        `A Wing and a Prayer`,
        `Jordan Peterson speaks critically about the current government(s)`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=PcM3Y8kACo4`,
      ],
    },
    {
      description: [
        `Body Language: Body Language - Senator Ted Cruz Vs AG Merrick Garland`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=3TyLpp_vMCo`,
      ],
    },
    {
      description: [
        `Is all of this is more dangerous than we think? Are we actually currently living in some kind of Orwellian nightmare?`,
      ],
      urls: [
        `https://www.bitchute.com/video/kiRMmKb3k4KK/`,
      ],
    },
    {
      description: [
        `Ex irs agent tells it like it is:`,
        `Award winning lady working for the irs realizes “there is no law, and the biggest fraud was the irs.”`,
      ],
      urls: [
        `https://rumble.com/vk6ock-start-asking-questions.html`,
      ],
    },
    {
      description: [
        `FUREY: Canada, it's long overdue that we drop the COVID alarmism cycle`,
        `“The problem is it's our new Pavlovian response. The past 20 months have been habit forming. Shutting down schools, for example, is not a normal thing to do. Yet we've normalized it.”`,
      ],
      urls: [
        `https://torontosun.com/opinion/columnists/furey-canada-its-long-overdue-that-we-drop-the-covid-alarmism-cycle`,
      ],
    },
    {
      description: [
        `Awaken with JP - Biden Achieves Lowest Approval Rating Ever!`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=oJOFvBg3PSo`,
      ],
    },
    {
      description: [
        `Awaken with JP - What It's Like to Believe Everything the Media Tells You`,
      ],
      urls: [
        `https://rumble.com/vnp1l3-what-its-like-to-believe-everything-the-media-tells-you.html`,
      ],
    },
    {
      description: [
        `Awaken with JP - The Omicron Variant is Here! What You Need to Know`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=zyY7NmbBz0A`
      ],
    },
    {
      description: [
        `Kamala Harris tells joke, no one laughs, she comments, “Everyone's laughing”`,
      ],
      urls: [
        `https://rumble.com/vfva39-cringe-warning-kamalas-joke-falls-flat.html`,
      ],
    },
    {
      description: [
        `EARLY WARNING THE FULL MOVIE ABOUT TODAY MADE IN 1981. THIS IS PRETTY CREEPY.`,
      ],
      urls: [
        `https://www.bitchute.com/video/miK1VZaWCmxR/`,
      ],
    },
    {
      description: [
        `Left leaning Bari Weiss, who thought covid was everywhere and washed everything all the time, says it's now a "pandemic of bureaucracy".`,
      ],
      urls: [
        `https://twitter.com/justin_hart/status/1484898960024096768`
      ],
    },
    {
      description: [
        `Joe Biden:`,
        `<strong>"As one computer said, if you're on the train, and they say Portal Bridge, you know you better make other plans..."</strong>`
      ],
      urls: [
        `https://rumble.com/volaal-incoherent-bonehead-strikes-again.html`
      ],
    },
    {
      description: [
        `Croatian MEP Mislav Kolakušić addressing the French president Emmanuel Macron in the European Parliament: (looking right at him)`,
        `"President Macron, considering many restrictions of citizens' rights and freedoms in France during the pandemic, I ask of you only one thing:`,
        `<strong>While presiding over the EU, do everything exactly the opposite of what you were doing in France.</strong>`,
        `On the other hand, today you said that you are proud there is no death penalty in Europe. Tens of thousands of citizens have died due to vaccine side effects.`,
        `<strong>Mandatory vaccinations represent death penalty and its execution for many citizens.</strong> This has to remain a choice for every citizen.`,
        `Murder is murder. Those of you who don't know that and haven't educated yourselves, take a look at the official data of the European Health Organization (EMA). Thank you."`
      ],
      urls: [
        `https://twitter.com/Based_Croatia/status/1484426702344359937`
      ],
    },
    {
      description: [
        `Kamala Harris explains what Google search is.`,
      ],
      urls: [
        `https://twitter.com/AShihipar/status/1481770216736628742`
      ],
    },
    {
      description: [
        `Democrats, including Biden, criticizing vaccine when Trump was president, even stating they would not get vaccinated.`,
      ],
      urls: [
        `https://twitter.com/owenusa/status/1472001486620008449`
      ],
    },
    {
      description: [
        `<b>Biden reads a script and says “and the message said, end of message” out loud at the end of his speech for Bob Dole's funeral.</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1469044685909991431`
      ],
    },
    {
      description: [
        `<b>Alberta Premier Jason Kenney discusses The Great Reset</b>`,
        `"The Great Reset is actually the name of a book by a very prominent advocate of it, his name is Klaus Schwab, he sent me a copy, I guess he sent one to probably every government leader around the world."`,
        `"He says we should 'seize the opportunity to reimagine the world, and radically change policies"`
      ],
      urls: [
        `https://www.youtube.com/watch?v=QQ9-wAReUlA`
      ],
    },
    {
      description: [
        `Joe Biden goes on rambling stroll through his own confusion.`,
      ],
      urls: [
        `https://gettr.com/post/ppelhl11b5`
      ],
    },
    {
      description: [
        `The Kremlin says the DNR and LNR have asked Putin to “help beat back the aggression of the Ukrainian armed forces to avoid victims among the civilian population and a humanitarian catastrophe in the Donbas”, per Russian wires.`,
        `This could be it.`,
      ],
      urls: [
        `https://threadreaderapp.com/thread/1496587959881113600.html`
      ],
    },
    {
      description: [
        `<b>UKRAINE PRESS RELEASE ABOUT JOE BIDEN / Hunter / Burisma / Money Laundering</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/B7sQCDRKpN1O`
      ],
    },
    {
      description: [
        `Biden gets corrected after saying "the First Lady's husband" has tested positive for covid.`,
        `That would be him.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1503859057152733193`
      ],
    },
    {
      description: [
        `What Happened to People Who Volunteered to Fight For Ukraine?`,
        `Passports torn up, volunteers thrown to the front lines as cannon fodder.`
      ],
      urls: [
        `https://www.youtube.com/watch?v=1udDZIByHd4`
      ],
    },
    {
      description: [
        `Kamala Harris: The significance to the passage of time`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=Z_oJPGA92HQ`
      ],
    },
    {
      description: [
        `Joe Biden:`,
        `And now's the time when things are shifting, we're gonna, there gonna be a new world order out there.`,
        `And we've got to lead it.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=_C5eN8PvEOU`
      ],
    },
    {
      description: [
        `So This Is The Endgame: Russell Brand`,
        `<b>Joe Biden has publicly said that he wants a New World Order and a regime change in Russia.</b>`,
        `<b>But if you think that he wants a New World Order and a regime change in Russia, you're a conspiracy theorist!</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=iCfkKQiTI-M`
      ],
    },
    {
      description: [
        `HUNTER BIDEN Made MILLIONS Selling ACCESS to CHINESE`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=g4dQvY7E3OE`
      ],
    },
    {
      description: [
        `Joe Biden:`,
        `“America is a nation that can be defined by a single word: iwasanafoothimafootift”`,
        `"I was in the the foothills of the Himalayas with Xi Jinping, traveling with him, that's when I traveled 17,000 miles when I was Vice President. I don't know that for a fact."`,
      ],
      urls: [
        `https://twitter.com/greg_price11/status/1512476276120072200`,
        `https://twitter.com/Robertopedia/status/1512504511973179401`,
      ],
    },
    {
      description: [
        `BIDEN Secret Service INFILTRATED by FAKE DHS AGENTS as PROSECUTORS SCRAMBLE`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=1HsWTH8PKDY`
      ],
    },
    {
      description: [
        `"This is an investigation of Joe Biden, the President of the United States, and why he lied to the American people about his knowledge and participation in his family's international business schemes.`,
        `Committee republicans have uncovered evidence of federal crimes committed by and to the benefit of members of the President's family.`,
        `These include: - Conspiracy to defrauding the United States - wirefraud - conspiracy to commit wirefraud - violation of the Foreign Agents Registration Act - violation of the Foreign Corrupt Practices Act - <b>violations of the Trafficking Victims Protection Act</b> - tax evasion - money laundering - and conspiracy to commit money laundering.`,
        `The Biden family's business dealings implicate a wide range of criminality, from <b>human trafficking</b> to potential violations of the constitution."`
      ],
      urls: [
        `https://twitter.com/VolumePrintcess/status/1593277947690962944`
      ],
    },
    {
      description: [
        `David Rockefeller admitting Henry Kissinger was his protégé & Klaus Schwab admitting Henry Kissinger was his mentor.`,
      ],
      urls: [
        `https://twitter.com/FBNHistory/status/1579812258611593217`
      ],
    },
    {
      description: [
        `Intelligence Agencies "Have Six Ways From Sunday Of Getting Back At You" - Chuck Shumer`,
      ],
      urls: [
        `https://twitter.com/liz_churchill8/status/1600648533736030209`
      ],
    },
    {
      description: [
        `(very) creepy Joe Biden`,
      ],
      urls: [
        `https://twitter.com/DC_Draino/status/1615444277546344480`
      ],
    },
    {
      description: [
        `Kamala Harris: "The significance of the passage of time, right? The significance of the passage of time. So when you think about it, there is great significance to the passage of time ... and there's such great significance to the passage of time..."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1505967874799714310`
      ],
    },
    {
      description: [
        `Newt Gingrich: “Kamala Harris may be the dumbest person ever elected Vice President in American history”`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1507030643460485120`
      ],
    },
    {
      description: [
        `Ron DeSantis makes Dr. Ladapo the new Surgeon General in Florida. Watch this and you'll wish he was in charge of COVID policy for the United States.`,
        `"Florida will completely reject fear as a way of making policies"`,
      ],
      urls: [
        `https://twitter.com/robbystarbuck/status/1440511149003313158`
      ],
    },
  ],
  ProfessionalsSpeakingOut: [
    {
      description: [
        `U.S. Senate expert witness testimony Dr. Peter McCullough states that 95% of American deaths could have been avoided. Autopsy confirms two teenage boys die on days 3 and 4 after the shot, the parents are horrified. This push is resulting in deaths of children.`,
      ],
      urls: [
        `https://twitter.com/LakovosJustice/status/1507570790530752515`
      ],
    },
    {
      description: [
        `Over 500 doctors penned letter to and against Victoria's Dan Andrews.`,
      ],
      urls: [
        `https://www.bitchute.com/video/G5d9yeNH6EVw/`,
      ],
    },
    {
      description: [
        `Ontario Officer Speaks To Her Sworn Oath`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=GnM54l6zOsg`,
      ],
    },
    {
      description: [
        `Louisiana Nurse (RN) with 17 years experience:`,
        `"We have had more children die from the covid vaccine than of covid itself."`,
        `"I am extremely concerned with the idea of mandating this vaccine for our children."`,
      ],
      urls: [
        `https://www.bitchute.com/video/mGqjRvYsBhyl/`,
      ],
    },
    {
      description: [
        `<strong>Ottawa police officer</strong> calls for the chief to resign after investigating the #ConvoyToOttawa protesters`,
        `"You sir are an embarassment."`,
        `"Treating the truckers like they're terrorists. I've been to Ottawa. I've seen what's gone on down there.`,
        `I've seen the people first hand shoveling the sidewalks, shoveling the roads, picking up garbage. I've watched the food be handed out to the homeless. Those truck drivers and the people of the convoy have done more for the homeless people in that city than that poor excuse for a mayor has done in I don't know how long.`,
        `I've watched blankets being given out. Food, friendship being offered to those that need it.`,
        `<strong>I don't see terrorists, I see people who give a care about their country.</strong>"`,
      ],
      urls: [
        `https://twitter.com/Krommsan/status/1492340604310753280`,
      ],
    },
    {
      description: [
        `Corporal Bulford Resigns From His Position of Personal Security for Justin Trudeau`,
        `"I have drawn my line in the sand. No more silence and compliance from me."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1490418278157717518`
      ],
    },
    {
      description: [
        `<strong>Teacher Speaks Out: The Children Are Not Alright</strong>`,
        `Stacey Lance | Speakeasy VIII w/Kate Wand`,
        `Kids telling teachers, "Miss, I know you can't do anything.. but I can't do this anymore. I can't live like this anymore."`
      ],
      urls: [
        `https://www.youtube.com/watch?v=AKm3rN6PZs8`
      ],
    },
    {
      description: [
        `Ontario doctor resigns over forced vaccines, says 80% of ER patients with mysterious issues had both shots`,
      ],
      urls: [
        `https://www.lifesitenews.com/news/ontario-er-doctor-resigns-over-mandatory-vaccines-and-falsehoods/`
      ],
    },
    {
      description: [
        `Dr. Aaron Kheriaty:`,
        `"With vaccine mandates and vaccine passports, we are seeing the emergence of a Biosecurity Surveillance Regime."`,
      ],
      urls: [
        `https://gettr.com/post/pqa1gzda0c`
      ],
    },
    {
      description: [
        `Army physician and aerospace medicine specialist calls on Pentagon to order all pilots who have received COVID-19 vaccine to be grounded - Sept 2021`,
        `"After I reported to my command my concerns that, in one morning I had to ground three out of three pilots due to vaccine injuries, the next day my patients were cancelled, my charts were pulled for review, and I was told that I would not be seeing acute patients anymore, just healthy pilots for their flight physical."`,
      ],
      urls: [
        `https://twitter.com/BuzzKent1776/status/1477317112607780864`
      ],
    },
    {
      description: [
        `Canadian Lawyers: Our free society is at risk.`,
        `<b>The Free North Declaration</b>`
      ],
      urls: [
        `https://www.youtube.com/watch?v=jl6OKIUnxRk`
      ],
    },
    {
      description: [
        `<b>Open letter to Alberta Health Services (AHS) from health care professionals.</b>`,
        `This open letter is in response to your announcement of mandatory full vaccination for all AHS staff by Oct 31, 2021. We represent a wide range of vaccinated and unvaccinated health care professionals from multiple disciplines, who are deeply concerned about these mandatory vaccinations.`,
      ],
      urls: [
        `https://drive.google.com/file/d/11kSyxmt1YNLM8e9MDbn3-5uN7lQl8NNq/view`
      ],
    },
    {
      description: [
        `Dr. Dan Stock - specially trained in immunology, inflamation regulation.`,
        `<b>"Everything recommended by the CDC and the state board of health is actually contrary to all rules of science."</b>`,
        `Offers pro bono expert testimony to challenge covid measures.`
      ],
      urls: [
        `https://www.bitchute.com/video/JWAtXlFyDU0x/`
      ],
    },
    {
      description: [
        `Miguel Escobar, physician assistant in healthcare for 15 years, with first hand covid experience speaking out against covid misinformation (July 27, 2021)`,
      ],
      urls: [
        `https://odysee.com/@NewsClipArchive/covid19-unfiltered-truth-about-wuhan-china-coronavirus-2021-08-05`,
        `https://www.bitchute.com/video/bqJd01kVL44B/`
      ],
    },
    {
      description: [
        `Dr. Rochagné Kilian, an ER doctor in Owen Sound, Ontario/Canada, resigned in protest of mandatory vaccines and the corruption of our health services.`,
        `"At least 80% of the ER patients in the past three months were double-vaxxed", says Dr. Kilian of her informal survey of patients entering the ER with serious medical issues. "How many people are we going to kill if we keep following this narrative?"`,
      ],
      urls: [
        `https://www.bitchute.com/video/ce7IVyrgtYrm/`
      ],
    },
    {
      description: [
        `Dr. Julie Ponesse, Ethics Professor at Western, with a background in Bioethics.`,
        `<b>"I have some serious ethical concerns about the current covid situation."`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=4MWsBc1w-VM`
      ],
    },
    {
      description: [
        `Doctor speaks publicly, <b>"Everyone who died with covid should be considered murdered."</b>`,
        `"You have now loaded your body with millions of spike proteins, and you are a ticking time bomb for cancer, blood clots, and whatever kind of ailment may come up in your body.`,
        `I'm tired of hearing people go and ask doctors can they be treated for covid and their only option is the vaccine, or go home, or go into the hospital where you are not going to make it out.`,
        `Early treatment has always worked. Please stop choosing fear and putting masks on our kids.`,
        `<b>The vaccine is not going to work, early treatment has always worked, and government mismanagement of patients is why people have died."</b>`
      ],
      urls: [
        `https://twitter.com/Suspici07891401/status/1479633913945468930`
      ],
    },
    {
      description: [
        `Four doctors respond to the question, "what's in the shot?"`,
      ],
      urls: [
        `https://www.bitchute.com/video/RFhxfWFAH1WF/`
      ],
    },
    {
      description: [
        `Canadian radio show host leaves a final message by speaking the truth on air before leaving the show.`,
        `<b>"If you think the Freedom Convoy in Ottawa is a racist movement... you have been tricked.</b>`,
      ],
      urls: [
        `https://gettr.com/post/ptw1282c47`
      ],
    },
    {
      description: [
        `Retired RCMP Officer Speaks Out: Officer's Oath, Lawful Orders, People's Courage (Feb 13 2022)`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=9ZKzhWw3f38`
      ],
    },
    {
      description: [
        `Croatian MEP Mislav Kolakušić is one of the few decent people speaking in the EU Parliament.`,
        `<b>"Covid passes are one of the dumbest ideas in human history"</b>`,
      ],
      urls: [
        `https://gettr.com/post/pvi76c7440`
      ],
    },
    {
      description: [
        `Members of the European Parliament Are Finally Calling Out The World Economic Forum's "Great Reset"`,
      ],
      urls: [
        `https://www.bitchute.com/video/oq4tkaHCDqeB/`
      ],
    },
    {
      description: [
        `<b>Really pissed off nurse just realized she's been lied to about the Pfizer injection.</b>`,
        `9 pages of adverse effects.`,
      ],
      urls: [
        `https://www.bitchute.com/video/B6oqK4p24r2x/`
      ],
    },
    {
      description: [
        `The Vindication of D.A. Henderson`,
        `We were going to invent pandemic planning.” Those were the words of Dr. Rajeev Venkayya in 2005 when he headed the White House bioterrorism study group under George W. Bush. “We want to use all instruments of national power to confront this threat,” Venkayya told colleagues in the administration, as reported by Michael Lewis in his book The Premonition.`,
        `That was the birth of the idea of national lockdown for pathogenic threat. To mainstream epidemiologists, the idea seemed crazy and potentially ruinous at the time, a fact that only emboldened its creators. Venkayya's colleague computer scientist Robert Glass told Lewis:`,
        `I asked myself, “Why didn't these epidemiologists figure it out?” They didn't figure it out because they didn't have tools that were focused on the problem. They had tools to understand the movement of infectious diseases without the purpose of trying to stop them.`,
        `Another convert to the idea, Dr. Carter Mecher, a man who was highly instrumental in provoking school shutdowns March 17, 2020, summed up the idea:`,
        `“If you got everyone and locked each of them in their own room and didn't let them talk to anyone, you would not have any disease.”`,
        `Now there's an idea: universal solitary confinement!`,
      ],
      urls: [
        `https://brownstone.org/articles/the-vindication-of-d-h-henderson/`
      ],
    },
    {
      description: [
        `David Redman: Former Executive Director of the Alberta Emergency Management Agency:`,
        `<b>Politicians ignored their own emergency management plans for c19, and instead followed a criminal cartel.</b>`,
        `(Additionally: Alberta's Failed COVID Management)`,
        `"Do you know the first world war at all? Battle of the Somme? The Battle of the Somme started on the first of June, ended when the snow fell in the fall.`,
        `The best military minds in the world, and I use this.. as a metaphor, the generals believed that on the first day of the Battle of the Somme, that if we shot a massive amount of artillery for 24 hours, that we would kill all the Germans and we would simply walk through their lines.`,
        `Well the first day didn't turn out that way, and we killed 100,000 soldiers, minimum, machine gunned to death because when the artillery came down, the Germans went in their foxholes and waited for the artillery to stop, came up, manned their machine guns and killed us all.`,
        `So a week later they said, ok.. <b>we didn't use enough artillery.</b> Let's use more.`,
        `So we tried it again, and it killed another 200,000.`,
        `Two weeks later we said, ok we need to start earlier, use more, and shoot longer. And they kept trying to find that perfect mix of artillery until they killed over a million men, and the winter snows stopped the lunacy.`,
        `<b>Let me tell you that's exactly what we're doing in covid.</b>`,
        `If only we had locked down sooner, locked down harder, and made it last longer, we would have had less deaths.`,
        `And we tried that in March, and we tried it in April, and then we tried it in October, November, December, January, February, and we're trying it again with the new variants, exactly the same. Maybe a little longer, maybe a little harder, maybe a little deeper..`,
        `And we still keep kill all our seniors over the age of 65 with multiple comormidities in long term care homes.`,
        `It's exactly the same because it seems to make sense.. if you use a shitload of artillery, you'll kill all the Germans. It really makes sense.`,
        `If you lockdown harder, you'll stop the spread, it really makes sense..`,
        `<b>Only it doesn't work."</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/iWDKDi6PTULq/`,
        `https://twitter.com/KoenSwinkels/status/1503867439989334019`,
        `https://twitter.com/KoenSwinkels/status/1503861885464195072`,
        `https://www.youtube.com/watch?v=VtM1f8j6ilQ`,
      ],
    },
    {
      description: [
        `Dr. Rob Johnson, BC Dentist:`,
        `When you've been conditioned by the absolute non-stop fear porn, fear mongering propaganda of our government controlled media to believe that everything you are being asked to do is for the greater good, then there is no line that you won't cross.`,
        `Informed consent must be freely given.`,
      ],
      urls: [
        `https://www.bitchute.com/video/y34NhPruLIRy/`
      ],
    },
    {
      description: [
        `American Airline Pilot Bob Snow: "under duress I received the vaccine."`,
        `Forced jab destroyed his life. Had he collapsed 30 minutes earlier it would have destroyed 300 others lives.`,
        `I'm in the ICU in Dallas, this is what the vaccine has done for me. I will probably never fly again, based on the criteria the FAA establishes for pilots.`,
        `I was hoping to teach my daughter to fly, she wants to be a pilot.. that will probably never happen.`,
        `All courtesy of the vaccine.`,
        `This is unacceptable and I am one of the victims.`
      ],
      urls: [
        `https://twitter.com/fynn_fan/status/1516887105145819147`
      ],
    },
    {
      description: [
        `Canadian doctor pressured to change patient's death certificate to covid. Patient did not die of covid! She refused.`,
      ],
      urls: [
        `https://twitter.com/RiseOfTheProles/status/1532189242704678919`,
        `https://www.youtube.com/watch?v=-DeHytYQHYI`,
      ],
    },
    {
      description: [
        `Pathologist Dr. Ryan Cole:`,
        `"We are literally doing the largest experiment on humanity ever done, not knowing the long term outcomes.`,
        `We only have a year and a half's data. And we have in the VAERS system, 30,000 deaths, well over 1,000,000 adverse reactions. We know that that system highly under-reports..`,
        `We have the EudraVigilance data, we have millions of adverse reactions, we have countless deaths from around the world, and in addition to that we have <b>all-cause death higher now in those who have received these shots, than those who didn't,</b> so we know there are very bad signals from this experimental therapy.`,
        `<b>These shot are all risk, no benefit.</b>`,
      ],
      urls: [
        `https://twitter.com/BLNewsMedia/status/1536044051966922755`,
        `https://rumble.com/v17c2ud-interview-a-lipid-nanoparticle-a-gene-is-a-nuclear-bomb-dr-ryan-cole-md.html`,
        `https://brightlightnews.com/a-lipid-nanoparticle-2/`,
      ],
    },
    {
      description: [
        `"Sue Your Doctor" - They've Violated Their Oath and Failed to Give Informed Consent`,
        `Dr. Ryan Cole: "If you are injured by a shot or you know somebody who's injured by a shot, you need to file a suit against a physician, because nobody in the world got informed consent."`,
      ],
      urls: [
        `https://twitter.com/VaccinatedFox/status/1538535524352262145`
      ],
    },
    {
      description: [
        `Nurse in Cardio Unit fails to acquiesce in medical coverup, wants to know why child heart injuries were not being reported to VAERS?`,
        `Result: FIRED.`,
      ],
      urls: [
        `https://twitter.com/OshZosh/status/1571138932246720512`
      ],
    },
    {
      description: [
        `<b>Dr Aseem Malhotra:</b>`,
        `"Having been double jabbed, and being one of the first to take the Pfizer vaccine, I have, after several months, critically appraising the data, speaking to eminent scientists at Oxford, Stanford and Harvard.`,
        `Speaking to two investigative medical journalists and being contacted by two Pfizer whistleblowers,`,
        `<b>reluctantly concluded that this vaccine is not complete safe and has unprecedented harms.</b>`,
        `<b>Which leads me to conclude that it needs to be suspended until all the raw data has been released for independent analysis.</b>`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1574986541302104065`,
        `http://insulinresistance.org`,
      ],
    },
    {
      description: [
        `Dr. Drew changes his tune on Covid Vaccines.`,
      ],
      urls: [
        `https://twitter.com/TheMilkBarTV/status/1579807052884770816`
      ],
    },
    {
      description: [
        `Dr. Aseem Malhotra:`,
        `"I was under the impression that the vaccine would prevent transmission. We now know that is completely false and we don't even know if it was true in the beginning"`,
        `<b>Policymakers must pause the roll out. History will NOT be in their side & the public will not forgive them for it.</b>"`,
        `---`,
        `"I was under the impression that the vaccine would prevent transmission. We obviously now know that's completely false, not even true, we don't even know if it was true from the beginning.`,
        `We had data on hospitalizations and death actually. For 100,000 vaccinated vs unvaccinated. So we're able to separate it by age group.`,
        `So, you have to vaccinate 230 people over the age of 80 to prevent one covid death.`,
        `520 if you are 70 - 79`,
        `1300 if you are 60 - 69`,
        `2600 if you are 50 - 59`,
        `This was a number of very eminent scientists associated at the BMJ, and what they found in the original trials where everything is corrected, it's supposed to be the gold standard..`,
        `..the risk of serious adverse events that were reported in the vaccine group was significantly higher than the risk of people being hospitalized with covid.`,
        `This was the original, this was Pfizer and Moderna trials combined, and think about this for a second..`,
        `<b>It was these trials alone, before we had any of the data, that led to the approval and rollout of the vaccine.. not just rollout.. Coersion.Mandates. People being threatened with their jobs.</b>"`,
        `---`,
        `"Why, when we've got a serious adverse event rate, which is probably in the range of at least 1-in-800 to 1-in-1000, which is good quality data, why is it not being pulled, when other vaccines - swineflu vaccine 1976 was pulled because it cause Guillain-Barré Syndrome in 1-in-100,000 - Rotavirus vaccine was pulled in 1989 because it caused a form of bowel obstruction 1-in-10,000 -`,
        `We're talking serious adverse events of 1-in-800."`,
        `---`,
        `"Now it's high time that policy makers around the world put an end to the mandates.`,
        `History will not be on their side, and the public not forgive them for it.`,
        `---`,
        `<b>What we're dealing with now, with hindsight, is perhaps the greatest miscarriage of medical science we will witness in our lifetime.</b>`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1577895492264116225`
      ],
    },
    {
      description: [
        `<b>"Military vaccine mandates are dangerous and deadly and they must stop immediately" ~ Lt. Col. Theresa Long, MD</b>`,
        `"Senator, in 2021 I came before you as a whistleblower reporting catastrophic increases in illnesses, and injuries across the DoD being reported in the DMed.`,
        `These concerns were brushed aside by the DoD as the 'data glitches' in our defense medical surveillance sytem. It was reported that this computer glitch was fixed.`,
        `Per your request, for an update, last night I ran a query in the defence medical epidemioligy database, looking at all illnesses and injuries across the DoD.`,
        `As you can see, the total number of reportable events went from 110,000 in 2020 to over 200,000 in 2022. The vaccine was introduced into the military in Janurary of 2021.`,
        `A reportable event is defined as 'an inherit significant threat to public health and military operations.' A reportable event represents severe life-threatening, clinical manifestations that disrupt military training and deployment.`,
        `These numbers are consistent with the statistically significant rise in vaccine adverse events on service members as provided to me by the CDC, showing 34,000 reports and 119 deaths. Compare this, Senator, to the 93 deaths of service member that were attributed to SARS-COV2 infection.`,
        `<b>Clearly the risk of the vaccine has already outweighed the benefit.</b>`,
        `<b>Military vaccine mandates are dangerous and deadly, and they must stop immediately."</b>`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600940304781127681`
      ],
    },
    {
      description: [
        `1.TWITTER FILES #19`,
        `The Great Covid-19 Lie Machine`,
        `Stanford, the Virality Project, and the Censorship of “True Stories”`,
      ],
      urls: [
        `https://twitter.com/mtaibbi/status/1636729166631432195`
      ],
    },
    {
      description: [
        `text`,
      ],
      urls: [
        `https://twitter.com/CartlandDavid/status/1633795406785130499`
      ],
    },
    {
      description: [
        `<b>COVID-19 Vaccines Can Cause "Permanent Disabilities": German Health Minister</b>`,
        `German Minister of Health Karl Lauterbach, who once claimed that COVID-19 vaccination was free of side effects, admitted last week that he was wrong, saying adverse reactions occur at a rate of one in 10,000 doses and can cause “severe disabilities.”`,
        `On Aug. 14, 2021, Lauterbach said on Twitter that the vaccines had “no side effects,” further questioning why some Germans refused to get vaccinated against COVID-19.`,
        `During an interview with German channel ZDF's “Heute Journal” on March 12, Lauterbach was asked by anchor Christian Sievers about the claim he made in the summer of 2021, confronting the health minister with his previous tweet that stated the shots are virtually free of side effects.`,
        `Lauterbach responded that the tweet was “misguided” and an “exaggeration” he made at the time, noting that it “did not represent my true position.”`,
      ],
      urls: [
        `https://www.theepochtimes.com/covid-19-vaccines-can-cause-permanent-disabilities-says-german-health-minister_5129027.html`
      ],
    },
    {
      description: [
        `<b>Shocking claim: 80% of pilots have taken the covid jab, and healthy pilots are suffering from myocarditis and "dropping dead on flights"</b>`,
        `I'd just like to make you aware of things that are going on currently in the aviation industry. We're an organization that stood up to protect individuals' rights, to protect against the vaccine mandates that have been illegal from the start, and I'm going to talk to you about the harm that's being done now.`,
        `We have people that have died from Remdesivir, airline pilots that I am familiar with, but more importantly we have had forced vaccination programs that now have vaccinated over 80% of your airline pilots, and we're seeing the fallout from that.`,
        `Incredible injuries: people who look just like me and my age group are having myocarditis, dropping dead on flights. In 12 days we had 4 incapacitations. That's just been over the past 2 weeks. Many more at home. And the skies aren't nearly as safe as they used to be, and yet everyone's is turning their head, and irresponsible doctors are pushing these vaccines, the airline CEOs and management are going along with it.`,
        `There has been a significant reduction of ethics, both in the hospitals and across large corporations and across our government. And it's going to be people like you who are here today who are going to stand up and make a fundamental difference, and help to bring change.`,
        `Some of these stories.. we have pilot Cody Flynn, gets his first Pfizer shot, 50 hours later he's flying his aircraft, passes out, doesn't remember landing the airplane.`,
        `Back in April 2022 an American Airlines pilot, captian Bob Snow, a man who was forced to get a vaccine against his will to keep his job, he lands his American Airlines 1067 in Dallas Fort-Worth. 6 minutes after that aircraft touched down on the runway, he collapses in the flight deck from cardiac arrest. Fortunately captain Bob Snow is still with us today due to the quick thinking of some passengers on that flight.`,
        `We also had captain Patrick Board who wasn't so lucky. American Airlines 3556, this was back in November 2022, departing out of Chicago. Within 90 seconds of that aircraft being cleared for takeoff, he's incapacitated, unresponsive in the captain's seat. The first officer who was a very experienced check airman, did an exceptional job of bringing that aircraft back around and landing it.`,
        `These are just a few of the stories that have been public. There are many more just like them that you're not hearing about. There's a massive coverup underway.`,
        `What you're seeing at the hospitals, what you're seeing at the airlines and across the board, I think most people aren't aware of just how significant these adverse events are. These medical protocols have destroyed so many lives`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1639034486930251780`
      ],
    },
    {
      description: [
        `Ann Vandersteel slams Sarasota memorial hospital board — DEMANDS protocols be changed on Remdesivir immediately..`,
        `<b>Reveals a 2019 NIH study on Remdesivir WAS STOPPED because "53-86% of the patients died"</b>`,
      ],
      urls: [
        `https://twitter.com/ChuckCallesto/status/1638587965336166410`
      ],
    },
    {
      description: [
        `<b>Robert Redfield, former director of CDC, says he tried to explain to Fauci — who's an immunologist and not a virologist like him — that this virus isn't natural</b>`,
        `I tried to explain to Dr. Fauci, who's an immunologist, that this virus, SARS and MERS, when they infected man from the intermediate host, cat in the case of SARS and camel in the case of MERS, they never learned how to go human to human.`,
        `So those original outbreaks are less than 1,000 people, and the epidemics died.`,
        `So when everyone thought this was SARS-like, well.. it's going to die too.. But this virus was immediately the most infectious virus, not the most probably right behind measles, virus that we've ever seen infect man. So I immediately said, wait a second, this isn't natural.`,
        `<b>And then you go back and look at the literature and you find in 2014 this lab actually published a paper that they put the H2 receptor into humanized mice so they can infect human tissue.</b>`,
        `<b>And then you learn that the new covid, which came from bats, now can hardly replicate in bats!</b> So how does that happen?`,
        `I said that my view as a virologist, again, my hypothesis, and I never discredit them for their hypothesis it's the spillover, was that this was most likely come from the lab, and we need to aggressively investigate both hypotheses.`,
      ],
      urls: [
        `https://twitter.com/TexasLindsay_/status/1633498173950898179`,
        `https://www.youtube.com/watch?v=g4rF91BeSJU`,
      ],
    },
    {
      description: [
        `<b>Dr. Robert Redfield, the former CDC Director, talks about three suspicious events that took place at the Wuhan lab in September 2019:</b>`,
        `"In Sept. 2019, three things happened in that lab. One is they deleted the sequences. Highly irregular, researchers don't like to do that. The second thing is they changed the command and control from civilian to military. Highly unusual. The third, which is very telling, is they let a contractor redo the ventilation system in that laboratory. Clearly, there was strong evidence that a significant event happened in that laboratory in September."`,
      ],
      urls: [
        `https://twitter.com/KanekoaTheGreat/status/1633499775734788096`,
        `https://twitter.com/theRealShark4/status/1633570143438618626`,
        `https://www.youtube.com/watch?v=g4rF91BeSJU`,
      ],
    },
    {
      description: [
        `<b>COVID Origins Hearing: Former CDC Director Robert Redfield's Opening Statements</b>`,
        `"Based on my initial analysis of the data, I came to believe, and I still believe today, that it indicates that COVID-19 more likely was the result of an accidental lab leak than a result of a natural spillover event."`,
        `"It's my opinion that we should call for a moratorium on gain-of-function research until we have a broader debate."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1633482321557958663`,
        `https://www.youtube.com/watch?v=g4rF91BeSJU`,
      ],
    },
    {
      description: [
        `Rep. Brad Wenstrup: “Has gain-of-function stopped a pandemic in your opinion?”`,
        `Dr. Robert Redfield: “No, in the contrary, I think it probably caused the greatest pandemic our world has seen.”`,
      ],
      urls: [
        `https://twitter.com/charliekirk11/status/1633504631493111808`
      ],
    },
    {
      description: [
        `Fauci was conspiring from the very start:`,
        `Dr. Robert Redfield: "I didn't know there was a Feb. 1st conference call until the FOIA came out, and I was quite upset as the CDC director that I was excluded... they came up with a single narrative which is antithetical to science."`,
      ],
      urls: [
        `https://twitter.com/CitizenFreePres/status/1633498530689191937`
      ],
    },
    {
      description: [
        `Dr. Robert Redfield: "There's No Doubt That NIH Funded Gain-of-Function Research"`,
        `Ms. Malliotakis: "Is it likely that American tax dollars funded the gain-of-function research that created this virus?"`,
        `Dr. Redfield: "I think it did — not only from NIH but from the State Department, USAID, and from DOD."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1633491748893728768`
      ],
    },
    {
      description: [
        `Former CDC Director just admitted that Dr. Fauci created COVID in a Chinese lab using American tax dollars and then lied to Congress about it`,
      ],
      urls: [
        `https://twitter.com/bennyjohnson/status/1633542979754287127`
      ],
    },
    {
      description: [
        `<b>Re: adding the mRNA covid injection to a child's vaccination schedule, starting at 6 months old. "What do you think will happen?"</b>`,
        `<b>John O'Looney: Funeral director / Embalmer:</b>`,
        `I think it can only end in one way: civil war.`,
        `And when the masses have caught on, in a land full of guns, it can only end one way.`,
        `It can only end one way, because you've lost your family, you've got nothing else to live for.. At least to my mind, that's the way I look at, if I lost my family, I got nothing to live for. Nothing.`,
        `So, I would be full of deadly rage, and I think that is what is going to happen.`,
        `And it's very interesting, when I met with Sir Graham Brady.. at the end of the meeting it was put to him by more than one person that was there, [high calibre doctors], and it was put to him that <b>when the masses find out what you've done.. what you've done, there'll be civil war.</b>`,
        `<b>And he acknowledged that and he said, the government are preparing. They are preparing. They're actually surprised it hasn't already kicked off. And this was in September 2021.</b>`,
        `So I would suggest, that the way this is going, when the masses do finally catch on, there'll be civil war, I genuinely believe that.. and what a terrible thought. What a terrible thought. I hope these people have got deep bunkers.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1633578529802575874`
      ],
    },
    {
      description: [
        `<b>Dr. Martin Makary: Select Subcommittee Roundtable: Examining COVID Policy Decisions</b>`,
        `The CDC and the FDA and people at the NIH, made up their mind before the trials were completed.`,
        `They decided babies were gonna get vaccines before the study was done, and when it came out it found no statistically significant different in efficacy between the 2 groups, and they just authorized it anyway.`,
        `Why are we even doing trials, why do we even have an FDA, why not just have king Fauci decree that everyone's going to need this?`,
        `And public health excluded anyone who had a different opinion. Ask any one of us that had different opinions than the establishment, if we were invited, ever, to the Public Health Association or university forums.`,
        `We had published collectively, 400 articles on COVID, including peer reviewed articles in JAMA, and yet we were excluded. So of course it looks like there's a consensus. But do you know that 40% of rural pediatricians in America do NOT recommend the COVID vaccine? How would you know that? That's a number that comes from a CDC discussion forum.`,
        `Public health officials and the CDC are proposing that a 12 year old girl get 65 COVID vaccines in her average lifetime. Based on what?`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1631918064244756480`,
        `https://twitter.com/VigilantFox/status/1630686806055239680`,
        `https://rumble.com/v2bc3bi-select-subcommittee-roundtable-examining-covid-policy-decisions-full-unedit.html`,
      ],
    },
    {
      description: [
        `Town councillor in West Nipissing, Ontario: “we are deeply sorry for the Covid Vaccine mandates, we now know that these vaccines do not stop the spread and have a higher per capita infection rate compared to the unvaccinated, again we are deeply sorry”`,
      ],
      urls: [
        `https://twitter.com/bambkb/status/1631844657528225793`
      ],
    },
    {
      description: [
        `<b>Dr. Martin Makary:</b>`,
        `The epicenter is only 5 miles from one of the only high-level virology labs in China. The doctors initially were arrested and forced to sign non-disclosure gag documents. The lab reports have been destroyed, they've not been turned over. The sequences reported to from the lab to the NIH database were deleted by a request from Chinese scientists that called over early on and said, 'delete those sequences we put in the database.'`,
        `And 2 leading virologists, maybe the 2 top virologists in the United States, told Dr. Fauci on his emergency call in January of 2020 when he was scrambling, soon after learning that the NIH was funding the lab, the both said that it was likely from the lab. Both scientists changed their tunes days later in the media, and then both scientists received 9 million dollars in subsequent funding from the NIH.`,
        `It's a no-brainer that it came from the lab.`,
      ],
      urls: [
        `https://twitter.com/RWMaloneMD/status/1630752670423175168`
      ],
    },
    {
      description: [
        `Dr. Martin Kulldorff -- At the beginning of the pandemic, I was scared for about 10 minutes.`,
      ],
      urls: [
        `https://twitter.com/CitizenFreePres/status/1631526482072809473`
      ],
    },
    {
      description: [
        `Two Leading Virologists Appear to Have Been Bought off to Shut Up About Lab Leak Theory`,
        `Dr. @MartyMakary: "Two leading virologists ... told Dr. Fauci on his emergency call in January of 2020 when he was scrambling soon after learning that the NIH was funding the lab. They both said that it was likely from the lab. Both scientists changed their tunes days later in the media. And then both scientists received $9 million subsequent in funding from the NIH."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1630675980636286977`
      ],
    },
    {
      description: [
        `Dr. Malone Reveals Study By Fauci Admitting mRNA Vaccines Hardly Work.`,
      ],
      urls: [
        `https://rumble.com/v28wfde-dr.-malone-reveals-study-by-fauci-admitting-mrna-vaccines-hardly-work..html`
      ],
    },
    {
      description: [
        `Nurse realizes that they were instructed and gave covid patients end of life medication immediately.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1614430974766641152`
      ],
    },
    {
      description: [
        `Dr. John Campbell:`,
        `In my view, the current health authorities should pause the covid 19 mass vaccine rollout program due to the risks associated with the vaccines.`,
      ],
      urls: [
        `https://twitter.com/ryangerritsen/status/1608317987437662208`
      ],
    },
    {
      description: [
        `<b>“Nervous system failures, cancers and Myocarditis… "Well…I got the shot…and nothing happened…" so…there's 5 reasons…” -Orange County Board of Commissioners.</b>`,
        `<b>Dr. Kevin Stillwagon to the “I Got the jab and I'm Fine” Crowd</b>`,
        `It creates a toxic spike protein in your body that can cause adverse reactions including nervous system disorders, cancers, and worst of all, myocarditis that can lead to sudden death.`,
        `So you might be thinking, "those adverse reaction people are crazy, I got the shot and nothing bad happened to me." I'll give you 5 reasons why that is.`,
        `First, you have no idea where the tip of that needle is when they push that plunger. If it's in a vein or capillary bed, those particles will rapidly spread to your heart and to your brain, increasing your chances of neurological and cardiac symptoms.`,
        `Secondly, you have no idea how many particles are in that syringe. It could vary ten-fold based on how it was prepared, and the more particles you get the greater the chance of an adverse reaction. So maybe you just got lucky.`,
        `Third, there is polyethylene glycol in the shot that keeps the particles from sticking together. If it degrades, and it does, you could get injected with coagulating goo that can cause a deadly stroke or a heart attack within minutes or hours.`,
        `Fourth, the mRNA that's in the shot can degrade also, so it won't even make the spike protein at all, lessening your chance of an adverse reaction.`,
        `Fifth, the more acidic your body is, the more spike proteins you will make. And that will increase the chance of an adverse reaction. So maybe you got lucky again, and your body, due to your diet or medications was less acidic and you made less spike proteins.`,
        `<b>But you can only be lucky for so long. The effects of this shot are cumulative, so my advice is to never, ever get one of these shots again.</b>`,
        `And if you did get one, get your dedimer levels checked for microclotting, and troponent checked for myocarditis. These problems can be asymptomatic and result in chronic disease or death in 2 - 5 years if you don't do something about it. Thank you.`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1604914194528100352`,
        `https://twitter.com/_Janey_J/status/1605275795123245056`,
      ],
    },
    {
      description: [
        `James Thorp, MD discusses the unprecedented harms he has seen in his practice from the Covid-19 vaccines.`,
        `Unprecedented increase in menstrual abnormalities.`
      ],
      urls: [
        `https://rumble.com/v1zgtmd-ive-seen-an-unprecedented-increase-in-menstrual-abnormalities.-dr.-james-th.html`
      ],
    },
    {
      description: [
        `President of the international vascular society raises concerns about covid vaccines in relation to cardiovascular problems.`,
        `"It would be great if someone can show us the light of where to go from here"`,
        `We must pause the mRNA jab now to stop more unnecessary harm`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1603757880221499394`
      ],
    },
    {
      description: [
        `Former CDC Director finally throws Fauci into Potomac, alleges COVID came from Wuhan lab that Fauci FUNDED— host left STUNNED on LIVE TV`,
      ],
      urls: [
        `https://twitter.com/bennyjohnson/status/1604943195057840129`
      ],
    },
    {
      description: [
        `General Practitioner & father Dr David Cartland calls for immediate suspension of the mRNA jabs.`,
        `Safety signal of harm is "unprecedented"`,
        `"We need to stop these shots immediately pending an urgent investigation for the sake of our next generation & children"`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1603997793286119424`
      ],
    },
    {
      description: [
        `The U.S. Vaccine Adverse Events Reporting System VAERS gutted its safety data by removing crucial foreign data, and Dr. Jessica Rose helped discover a troubling and under-reported finding:`,
        `mRNA-injection-induced myocarditis is happening in all ages.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1601897106075164679`,
        `https://brightlightnews.com/interview-vaers-adverse-events-data-gutted-dr-jessica-rose/`,
        `https://rumble.com/v2022dg-interview-vaers-adverse-events-data-gutted-dr.-jessica-rose.html`,
      ],
    },
    {
      description: [
        `"We should halt the covid vaccination programme and launch an investigation into the serious adverse events"`,
        `Another Consultant Cardiologist Dean Patterson weighs in`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1602081143570006019`
      ],
    },
    {
      description: [
        `"It's very clear - unequivocal. In normal circumstances, we wouldn't even be debating this. This vaccine would have been pulled a long time ago. It probably should never have been approved in the first place."`,
        `- Dr. Aseem Malhotra`,
      ],
      urls: [
        `https://twitter.com/JanJekielek/status/1601704378976505856`,
        `https://www.theepochtimes.com/dr-aseem-malhotra-from-vaccine-pusher-to-vaccine-debunker-how-i-changed-my-mind-about-the-covid-19-jab_4915606.html`,
      ],
    },
    {
      description: [
        `Pediatrician Dr Renata Moon MD:`,
        `I have a government that's telling me that I have to say safe and effective and if I don't, my license is at threat - We're seeing an uptick in myocarditis, we're seeing an uptick in adverse reactions, something is extremely wrong.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600747817697280000`
      ],
    },
    {
      description: [
        `Dr. Paul Alexander, MSc, PhD:`,
        `Those who have conducted this vaccination programme have made a catastrophic mistake`,
        `Those who are vaccinated are getting infected repeatedly, so much so that... the variants are put underpressure and natural selection will select for these variants, so much so that if you kept this program going, you are going to keep this pandemic for many many more years.`,
        `In other words, this vaccine program, they way it was rolled out, the way it continues, will keep producing one variant after the next.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600966415057440768`
      ],
    },
    {
      description: [
        `Dr. James Thorp, MD`,
        `What I have seen in my medical practice has been a substantial increase in infertility, miscarriage, fetal death & fetal malformation. What we have seen is catastrophic`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600921076644470784`
      ],
    },
    {
      description: [
        `Dr Peter McCullough`,
        `The pathway to prevent anymore harm is all the vaccines need to be pulled off the market, withdrawn - that needs to happen immediately. What's at stake here is death`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600645475937419265`
      ],
    },
    {
      description: [
        `The excess mortality has shifted so much that it's pretty phenomenal`,
        `Ed Dowd`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600642550221737984`
      ],
    },
    {
      description: [
        `Associate Prof. of Viral immunology Dr. Byram Bridle on blood transfusions from vaccinated people`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600439760962523136`
      ],
    },
    {
      description: [
        `Dr. Shawn Brooks MD in 2021:`,
        `You've got 6 months…to 3-5 years, that's it, and your children will be sterilized`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1600202464979931136`
      ],
    },
    {
      description: [
        `<b>Christine Anderson member of European Parliament sends Out An S.O.S. To The World</b>`,
        `I am answering your SOS call. I will do whatever I can to make it known to the world that your once free and liberal democracy has been transformed into a totalitarian regime, which tramples on human rights, civil liberties, and the rule of law.`,
        `I'm emploring all of you around the world who still think your governments are looking out for your best interest.`,
        `<b>At no point in history, have the people forcing others into compliance, been the "good guys".</b>`,
        `<b>The welfare of humanity has always been the alibi of tyrants.</b>`,
        `Do you not realize that this vaccine does not protect you from covid? It does however, protect you from governmental oppression. For now, that is. But don't think for even a second, that this is not going to change tomorrow.`,
        `What will you tell your grandchildren? Will you tell them you didn't know? Will you tell them you were just following orders?`,
        `You need to understand, it isn't about breaking the "4th wave", it is all about breaking <b>people</b>.`,
      ],
      urls: [
        `https://twitter.com/freemoneyman420/status/1599487419530629120`
      ],
    },
    {
      description: [
        `C19 Vaccine Injury Conference: Puerto Rico Health Summit. Many doctors speaking out.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1599836087127416832`
      ],
    },
    {
      description: [
        `Dr. Ryan Cole:`,
        `I got a call from a an OB/GYN from a highly-jabbed state, She said, Is there a better system than VAERS because I am seeing leiomyosarcomas in the vaginal tract and in the uterus at rates I have never seen in my career.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1599540215722278913`
      ],
    },
    {
      description: [
        `<b>Gerard Rennick, Senator for Queensland, Australia:</b>`,
        `Well well well, here we are, the end of 2022 and we've had over 10 million cases of covid. The Australian Health Department, of course, stopped counting around September sometime because I think it was getting too embarrassing to admit that despite, with over 20 million people being vaccinated, over half the country had caught covid.`,
        `Whatever happened to protecting you? Whatever happend to protecting you?`,
        `But we don't want to talk about that anymore. We'll just pull it off the website and not discuss it. We will not discuss it.`,
        `And then we've got the excess deaths that Senator Babet talked about before. We had 8,706 extra deaths last year, despite the fact that New South Wales locked down for three months. So in theory, the deaths should have been lower, like they were in 2020. But let's not count 2020 in the ABS figuresm let's pretend nothing happened there.`,
        `Almost 140,000 jab injuries, more than all the injuries reported from vaccines since 1971, more than all the injuries put together. You've got an injury rate that's three times higher, and yet the TGA don't want to look at the signal.`,
        `The whole point of having a database where doctors report these injuries, where they tick the box suspected, and as the doctors say, they don't fill these forms out because they don't have the spare time. They don't have a lazy 20 or 30 minutes sitting around filling these forms out if someone fell off a bike. No, they're ticking these boxes because they believed that the vaccine caused the injury that they are reporting.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1598586862733500416`
      ],
    },
    {
      description: [
        `Dr. Robert Malone, MD:`,
        `It is not adequate to only consider the short term adverse events as related to the vaccine. We must acknowledge that the genetic, covid 19 genetic injections, cause far more harm than good, and provide zero benefit, relative to risk for the young and healthy. They do not reduce covid 19 infection, which is treatable and not terminal.`,
        `Furthermore the most recent data demonstrates that you are more likely to become infected or have disease or even death if you've been vaccinated, compared to the unvaccinated people. This is shocking to hear but it is what the data are showing us.`,
        `<b>The data now show that these experimental gene therapy treatments can damage your children, as well as yourself. They can damage your heart, your brain, your reproductive tissue, and your lungs. This can include permanent damage and disablement of your immune system.</b>`,
      ],
      urls: [
        `https://twitter.com/the_melmarrone/status/1524578490556682241`
      ],
    },
    {
      description: [
        `Insurance CEO, Todd Callender.`,
        `As a large insurance group, we underwrite morbidity risk. Based on what we are seeing, the rates right now, excess mortality is at 84%, and excess every kind of disease at 1100%. 2022 we are expecting upwards of 5000%`,
      ],
      urls: [
        `https://twitter.com/davejbirtwistle/status/1555166723766099972`
      ],
    },
    {
      description: [
        `Dr. John Campell: Time to pause covid mass vaccination`,
      ],
      urls: [
        `https://rumble.com/v22ugd2-time-to-pause-covid-mass-vaccination.html`
      ],
    },
    {
      description: [
        `<b>As an Oncologist I Am Seeing People With Stable Cancer Rapidly Progress After Being Forced to Have a Booster</b>`,
      ],
      urls: [
        `https://dailysceptic.org/2022/11/26/as-an-oncologist-i-am-seeing-people-with-stable-cancer-rapidly-progress-after-being-forced-to-have-a-booster/`
      ],
    },
    {
      description: [
        `Physician who treated > 4000 covid patients says he's seen hundreds of vax injuries including chest pain, cancer & even pregnancy loss.`,
        `"I have to apologise on behalf of my profession for refusing to listen to patients with vax injuries"`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1627219966691885066`
      ],
    },
    {
      description: [
        `Dr. Aseem Malhotra:`,
        `"The primary driver of excess deaths right now is the vaccine"`,
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1626012435722252289`,
        `https://www.youtube.com/watch?v=xG5nQhWx4aI`,
      ],
    },
    {
      description: [
        `<b>Dr. Roger Hodkinson:</b>`,
        `Q: Should we forgive and forget?`,
        `R: Absolutely not.`,
        `I'm full of vengeance. I am vengeful. It's not a time to say I'm sorry, it's a time to put these bastards in jail.`,
        `I'm calling it "The Big Kill". We've seen the biggest kill ever in medicine's history, directly because of the intervention of these idiots into healthcare. And it's the physicians, it's not just the politicians, it's the physicians, themselves who are principally culpable because they have allowed government's jackboots to influence how they manage their patients, which has never, ever happened before.`,
        `First do no harm and informed consent.`,
        `How can you do this? How can you look into the eyes of a pregnant woman and tell her that this experimental product is safe? How can you do that as a physician?`,
        `No. Any physician who has done that, should be in jail.`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1625582761586339840`
      ],
    },
    {
      description: [
        `Dr. Robert Malone weighs in after Project Veritas exposed how Pfizer is attempting to mutate the COVID-19 virus.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1618790406992416769`
      ],
    },
    {
      description: [
        `Calls to suspend covid 19 vaccines in South Africa because of safety concerns hits mainstream news`,
        `"Let's stop jabbing the people"`,
        `"We know the pharmaceutical industry has a long history of criminal behaviour. We are here to protect the public" `,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1618204753271611394`
      ],
    },
    {
      description: [
        `Dr Masanori Fukushima, Professor Emeritus at Kyoto University, warns about vax harms to the 🇯🇵 Ministry of Health:`,
        `"You are ignoring science! It's a disaster. You spend billions on the vaccine & force people to inject it...due to the vax, natural immunity has been suppressed"`,
      ],
      urls: [
        `https://twitter.com/hugh_mankind/status/1597663704044408832`
      ],
    },
    {
      description: [
        `Dr. Peter McCullough: COVID Jab Death Count "Is Worse Than a War"`,
      ],
      urls: [
        `https://rumble.com/v10pfqn-dr.-peter-mccullough-covid-jab-death-count-is-worse-than-a-war.html`
      ],
    },
    {
      description: [
        `Western Standard, November 17, 2020: with Roger Hodkinson`,
        `<b>Senior doc says Alberta politicians 'playing medicine.' media driving 'hysteria'</b>`,
        `A top Edmonton doctor in virology says Albertans “are being led down the garden path” by government health officials in their efforts to stop the COVID-19 virus.`,
        `Hodkinson is the CEO of Western Medical Assessments, and has been the company's medical director for over 20 years. He received his general medical degrees from Cambridge University in the U.K., and then became a Royal College certified pathologist in Canada (FRCPC) following a residency in Vancouver.`,
        `“There is utterly unfounded public hysteria driven by the media and politicians. It's outrageous. This is the greatest hoax every perpetrated on an unsuspecting public.`,
        `“There is absolutely nothing to be done to contain this virus other than protecting your more vulnerable people. It should be thought of as nothing more than a bad flu season.`,
      ],
      urls: [
        `https://www.westernstandard.news/news/senior-doc-says-alberta-politicians-playing-medicine-media-driving-hysteria/article_9db4c519-d774-5c1c-acbc-e040028efc60.html`
      ],
    },
    {
      description: [
        `September 23, 2021`,
        `<b>Dr. Scott Atlas on Vaccine Mandates for Children, Natural Immunity, and Florida's COVID-19 Surge</b>`,
        `<b>American Thought Leaders with Jan Jekielek</b>`,
        `<b>“To me, it's unconscionable that a society uses its children as shields for adults. Children do not have a significant risk from this illness… Are we [as] a society, a civilization … going to inject our children with an experimental drug that they don't have a significant benefit from, to shield ourselves?”</b>`,
        `<b>— Dr. Scott Atlas</b>`,
        `In this episode, we sit down with public health policy expert Dr. Scott Atlas to discuss the ethics of vaccinating children, especially given that data has shown children do not significantly spread the virus compared to adults.`,
        `We also discuss the underlying reasons behind the recent COVID-19 surge in Florida as well as the efficacy of natural immunity.`,
      ],
      urls: [
        `https://www.theepochtimes.com/dr-scott-atlas-on-vaccine-mandates-for-children-natural-immunity-and-floridas-covid-19-surge_4013490.html?`
      ],
    },
    {
      description: [
        `Orlando Magic's Jonathan Isaac says he isn't getting the covid vaccine because he has natural immunity and data shows he isn't at risk at all from covid.`,
      ],
      urls: [
        `https://twitter.com/ClayTravis/status/1442852144646922246`
      ],
    },
    {
      description: [
        `Washington Wizards Star Bradley Beal is dropping MASSIVE Red Pills on the NBA.`,
        `"Why are vaccinated people still getting covid?"`,
      ],
      urls: [
        `https://twitter.com/NotAlexSheppard/status/1442708917251887104`
      ],
    },
    {
      description: [
        `<b>Vitamin D3 and Zinc</b>`,
        `This was a video that Dr Zelenko made before he died. `,
        `His brother asked me to post it. `,
        `It may be one of his most important pieces, as when we live in a world where bioterrorism is a real threat and Covid has reminded us of the true depth of evil that man can descend into - Dr. Zelenko's treatment protocols are essential for national security & defence.`,
      ],
      urls: [
        `https://twitter.com/CKellyUAP/status/1645430545025994752`
      ],
    },
  ],
  RightsFreedoms: [
    {
      description: [
        `Brian Peckford, last living signer of Canada's Charter of Rights and Freedoms, at the Freedom Convoy protest in Victoria, British Columbia.`,
        `"... and we're not going to allow this Nation on the Northern part of North America to go down the drain because we have people who've gotten hungry for power, and have discarded the <strong>individual freedoms that you and I own</strong>."`,
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1492739266337595392`,
      ],
    },
    {
      description: [
        `<strong>Canadian Constitutional Crisis | Brian Peckford</strong>`,
        `The last living writer of the Canadian Charter of Rights and Freedoms`,
        `The Jordan B. Peterson Podcast S4: E78`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=EdhFuMDLBDM`
      ],
    },
    {
      description: [
        `He helped write the Charter. Now he says the government is violating it.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=YtrYy3t4XgA`
      ],
    },
    {
      description: [
        `<b>Justice Centre for Constitutional Freedoms (JCCF)<b>`,
        `<b>Mandatory Covid Vaccine FAQ</b>`,
        `In Canada, people have a right to decide what medical treatment they receive or do not receive. The Covid shots are new, and clinical trials will not be complete until 2023. As such, the Covid shots are experimental. Each of the shots also contain warnings from Health Canada, including warnings about pericarditis, myocarditis, and thrombosis. It is unthinkable that British Columbia is requiring these experimental injections as a condition of full participation in society, yet this is what the BC government is doing. The Justice Centre opposes this profound violation of peoples' constitutional rights. Moreover, the vaccinated and the unvaccinated both spread Covid-19 in equal measure, as members of both groups carry the same high viral loads when infected, according to data from the Center for Disease Control (CDC).`,
        `---`,
        `If these mandates are not challenged for violating our Charter rights and freedoms, or if courts side with the government, this will open the door to even greater government control over Canadians’ rights and freedoms, and our bodies.`,
        `---`,
        `We are unaware of any previous circumstance where a pharmaceutical company has rushed a product to market and has no liability for deaths or injuries, where no long-term studies have been conducted, where governments have induced, coerced and threatened people to get it, and where Canadians may lose their jobs and civil liberties for refusing to take it. This is unprecedented.`,
        `---`,
        `The Justice Centre sees these laws as unconstitutional, full stop. Seeking exemptions to the law implies that the underlying law is valid, and it is our position that it is not. Canadians have constitutionalized protections for conscience, religion, and security of the person including bodily autonomy. Mandatory vaccine policies and legal discrimination against the unvaccinated minority are blatant violation of Charter rights and freedoms.`,
      ],
      urls: [
        `https://www.jccf.ca/mandatory-covid-vaccine-faqs/`,
        `https://www.jccf.ca/wp-content/uploads/2021/09/Mandatory-Covid-Vaccine-FAQ.pdf`,
      ],
    },
    {
      description: [
        `Justice Centre for Constitutional Freedoms:`,
        `<b>Know your rights on mandatory facemasks</b>`,
        `---`,
        `If someone is unable to wear a mask because of a mental disability, such as claustrophobia, a business would be engaging in illegal discrimination if it denied services to such a person for not wearing a mask, and did not provide some form of reasonable accommodation.`,
        `A requirement to wear a mask as a condition of employment, or as a condition to receive a service, may be discriminatory toward people with exemptions. If a business denies such a person service or employment because they refuse to wear a mask, that denial may form a legally valid basis for a human rights complaint.`,
        `Some people refuse to wear a mask for religious reasons. Other people cannot or should not wear masks because of various medical and health conditions. Many of the municipal bylaws are worded broadly enough to exempt those with “health concerns” or ” health conditions”, including mental conditions like claustrophobia. Laws must not disproportionately punish the vulnerable who are unable to wear masks.`,
        `---`,
        `There are jurisdictional questions regarding mask bylaws, as well. Cities have no inherent jurisdiction to enact laws because they are entirely creatures of statute: their power is delegated from provinces through legislation. If a province has chosen not to enact mandatory masking requirements, then what empowers a municipality to do so?  The answer may well be “nothing”.`,
        `---`,
        `If asked to wear a mask, you can reply, “I can't wear a mask.” A store or company can ask if you have a doctor's note due to ignorance of the law, however, you are under no legal obligation to provide a note, discuss your medical condition, or get into detail about why you cannot or will not wear a mask. The exception to this is if you file a Human Rights Complaint. You are likely going to be required to provide proof as part of the complaint process.`,
        `---`,
        `No one can physically force you to cover your face in a free country, however you may have to shop in stores that welcome all customers regardless of disability or condition. Stores may choose to enforce the mask bylaw on their premises, but customers will likewise choose not to spend their hard-earned money in that store. In this economy, can stores afford to turn away customers?`,
        `---`,
        `Importantly, medical offices, hospitals, and nursing homes are the most difficult places to exercise a mask exemption. These are places full of sick people, where the risk of catching any kind of cold, flu or virus is high and can result in death. However, Alberta Health Services produced a COVID information instruction sheet  which clearly stated: “No patient shall be denied service in AHS because they cannot or will not wear a mask.” This has since been removed from the original website.`,
        `---`,
        `In short, the local municipal governments are on shaky legal ground in imposing mask by-laws. Businesses in mandatory-mask municipalities are only enforcing what they are told to do, and businesses that voluntarily impose mask requirements have a right to do so as private, non-government entities, with several “human rights” caveats.`,
        `Know your rights.`,
        `---`,
        `So what can the average person do?`,
        `1. Do not wear a mask, and exercise your exemption.`,
        `2. Hope that other people in Alberta become tired or frustrated with mask-wearing and refuse to comply in very large numbers.`,
        `3. Write your various levels of government demanding mandatory masks be removed from the bylaws; object vigorously to the mandate. Do not just write once. Flood their office, and your local member of parliament, city officials, mayors with alderman, clear, short concise objection letters and make it clear this is a voting issue to you. (Shorter is better. Writing a three-page letter to these government officials is less effective than writing 3 paragraphs. Keep it short and pointed.)`,
        `Protest. In LARGE numbers. Not hundreds. Thousands.`,
        `Wait for legal challenges to the mask mandates that do not go after masks, but attack the mandates on the basis of freedom of expression, free speech, parental rights, or freedom of religion.`,
        `When you are outright refused service and not accommodated, for each store that discriminates, file a human rights complaint with the your provincial human rights commission.`,
        `Shop in stores that have made customers welcome even if they do not or cannot wear a face covering, and refuse to patronize stores that do not. Ensure you write the head offices of these stores telling them that you are patronizing their store because of their decision to welcome all.`,
      ],
      urls: [
        `https://www.jccf.ca/know-your-rights-on-mandatory-facemasks/`,
        `https://www.jccf.ca/wp-content/uploads/2020/11/Know-your-rights-on-mandatory-facemasks-.pdf`,
      ],
    },
    {
      description: [
        `<b>The vaccine passport is all smoke and mirrors. Cops walk away with their tail between their legs</b>`,
        `A group refuses vaccine passport at eaton centre foodcourt, schooled the cops on the law`,
        `---`,
        `Stand your ground and know your rights.`,
        `You are not trespassing if you are a paying customer conducting the business' services publically offered.`,
        `You need to confidently assert this or the cops will take advantage of an unaware individual and tresspass them.`,
        `You cannot be arrested or charged for not having proof of vaccination. This is illegal.`,
        `They can only and will only attempt to tresspass you.`
      ],
      urls: [
        `https://twitter.com/howisthismylif/status/1440918601825914880`,
        `https://www.bitchute.com/video/fJoPhHsrkLpn/`,
      ],
    },
  ],
  RussiaUkraine: [
    {
      description: [
        `People marching through central Moscow this evening chanting “No to War!”`,
      ],
      urls: [
        `https://twitter.com/mjluxmoore/status/1496906522080731139`
      ],
    },
    {
      description: [
        `I can't believe this didn't work.`,
      ],
      urls: [
        `https://twitter.com/kshahrooz/status/1496899251963584517`
      ],
    },
    {
      description: [
        `<b>Is Russia actually bombing US Biolabs in Ukraine?</b>`,
        `Russia has been saying at least since April 2021 that the US has been creating "bio-weapons" at their border.`,
        `Maps indicating US Biolabs in Ukraine correspond with bombing locations.`,
        `Putin also continues to call it a “special military operation”.`,
        `Says he wants to demilitarize and “denazify” the country but not occupy it. What does he mean by that? What is he really targeting?`
      ],
      urls: [
        `https://threadreaderapp.com/thread/1496745429672857602.html`
      ],
    },
    {
      description: [
        `<b>Embassy of the Russian Federation in Canada</b>`,
        `The sanctions announced by the Canadian Cabinet against the President of Russia and the Minister of Foreign Affairs of Russia is an unprecedentedly unfriendly and at the same time absurd step. It contradicts all principles of interstate relations and diplomatic ethics. In this way, the Cabinet is approaching the point of an irreparable severance of bilateral ties. Response will follow.`,
        `In a broader context, we would like to draw attention to the face that in 2014 Canada shyly turned a blind eye to the overthrow of the democratically elected President V. Yanukovych by means of an armed coup. He was unpopular, but the West recognized the democratic nature of his election. And then the same West, including Canada, trampled on its own vociferously declared principles of non-recognition of the illegal overthrow of democratically elected leaders. This is the first.`,
        `Secondly, for eight years Canada not only ignored the rise of neo-Nazism in Ukraine and the genocide unleashed by the Kiev regime against the Russian people in the east of the country, it encouraged and helped these killings. Canada trained military formations, including neo-Nazi radicals (this is a documented fact), who then went to kill innocent people in Donbass. In this sense, have the decision-makers in Ottawa ever realized that they have blood on their hands?`,
        `Thirdly, Russia is fighting neither Ukraine or Ukrainians. Russia is conducting a special military operation to denazify Ukraine. The Canadian government, acting as one of the most zealous sponsors and defenders of the Kiev regime (the same which raised the symbols of the SS divisions and portraits of Hitler's collaborators Bandera and Shukhevych as a thing to be proud of), in fact, protects this Nazism.`,
        `<b>Thus, this Cabinet writes its name into the darkest pages of history and shames the memory of a generation of brave Canadians who gave their lives in the common struggle for victory over Nazism in World War II.</b>`,
      ],
      urls: [
        `https://twitter.com/RussianEmbassyC/status/1497397975479463936/photo/1`
      ],
    },
  ],
  SchoolBoards: [
    {
      description: [
        `Vail school board walks out of meeting.`,
        `Paents elect new school board and vote to end mask mandate`,
      ],
      urls: [
        `https://rumble.com/vg6zcx-vail-school-board-walks-out-of-meeting-parents-elect-new-school-board-and-v.html`
      ],
    },
    {
      description: [
        `Dr. Dan Stock - specially trained in immunology, inflamation regulation.`,
        `<b>"Everything recommended by the CDC and the state board of health is actually contrary to all rules of science."</b>`,
        `Offers pro bono expert testimony to challenge covid measures.`
      ],
      urls: [
        `https://www.bitchute.com/video/JWAtXlFyDU0x/`
      ],
    },
    {
      description: [
        `Miguel Escobar, physician assistant in healthcare for 15 years, with first hand covid experience speaking out against covid misinformation (July 27, 2021)`,
      ],
      urls: [
        `https://odysee.com/@NewsClipArchive/covid19-unfiltered-truth-about-wuhan-china-coronavirus-2021-08-05`,
        `https://www.bitchute.com/video/bqJd01kVL44B/`
      ],
    },
    {
      description: [
        `Doctor speaks publicly, <b>"Everyone who died with covid should be considered murdered."</b>`,
        `"You have now loaded your body with millions of spike proteins, and you are a ticking time bomb for cancer, blood clots, and whatever kind of ailment may come up in your body.`,
        `I'm tired of hearing people go and ask doctors can they be treated for covid and their only option is the vaccine, or go home, or go into the hospital where you are not going to make it out.`,
        `Early treatment has always worked. Please stop choosing fear and putting masks on our kids.`,
        `<b>The vaccine is not going to work, early treatment has always worked, and government mismanagement of patients is why people have died."</b>`
      ],
      urls: [
        `https://twitter.com/Suspici07891401/status/1479633913945468930`
      ],
    },
    {
      description: [
        `Ottawa Public School Board Trustee candidate & childhood vaccine enthusiast Nili Kaplan-Myrth exerts her privilege & makes stunning admission she sent her child to private school "where he could get 1-on-1 education."`,
      ],
      urls: [
        `https://twitter.com/canmericanized/status/1577817654685061122`
      ],
    },
    {
      description: [
        `This woman's speech at a school board meeting - after a school sent emails advertising a "family friendly" drag show for kids (sponsored by a 21+ gay bar) - is so good `,
      ],
      urls: [
        `https://twitter.com/wokal_distance/status/1580421155663450112`
      ],
    },
    {
      description: [
        `Adriana La Grange - Alberta "minister of education" exposed for FORCING CP "curriculum" after Fort McMurray school board just said no!`,
      ],
      urls: [
        `https://twitter.com/chrissaccoccia1/status/1623772530514485248`
      ],
    },
    {
      description: [
        `Fitness Influencer ERUPTS On NJ School Board`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=W1aqp9ttrJ8`
      ],
    },
  ],
  SocialDivision: [
    {
      description: [
        `Bill Maher commening on Justin Trudeau's comments of "do we tolerate these people", and "those holding unacceptable views".`,
        `Guest: "I'm surprised to hear that Trudeau said those things."`,
        `Maher: "... you didn't see the black face?"`,
      ],
      urls: [
        `https://twitter.com/ezralevant/status/1492485385708052484`,
      ],
    },
    {
      description: [
        `Mount Sinai Long Beach hospital fired 72 healthcare staff due to vaccine mandate, and then closed down due to staff shortage. `,
      ],
      urls: [
        `https://twitter.com/DrEliDavid/status/1463610474482872320`,
      ],
    },
    {
      description: [
        `Man gets literally dragged out of the hospital room where his pregnant wife is about to give birth, for not submitting to a rapid test.`,
      ],
      urls: [
        `https://twitter.com/Sarah47178947/status/1460348076061868033`,
      ],
    },
    {
      description: [
        `The Guardian: Children born during pandemic have lower IQs, US study finds`,
        `The cost of lockdowns.`,
      ],
      urls: [
        `https://www.theguardian.com/world/2021/aug/12/children-born-during-pandemic-have-lower-iqs-us-study-finds`,
      ],
    },
    {
      description: [
        `"There was a lesson to learn from the Holocaust, we're always reminded that "never forget", we'd learned our lesson, there's a lesson to be learned. It's like ok fair enough man... what was the lesson? That's the question. What was the lesson?`,
        `<strong>And the lesson is... you're the Nazi. That's the lesson.</strong>`,
        `...it's like, really? ...really? ...oh god that's a terrible lesson... but... I don't see another lesson.`,
        `It's you...`,
        `No one wants to learn that, and that's what I've been teaching my students since 1993, it's like...`,
        `<strong>If you were there... that would've been you.</strong>`,
        `You think, well I'd be Oscar Schindler, I'd be resucing the Jews... it's like... no, I'm afraid not.`,
        `You'd at least not be saying anything. And you might also be actively participating. ...You might also enjoy it. You never know."`
      ],
      urls: [
        `https://twitter.com/calvinrobinson/status/1470129331309588489`
      ],
    },
    {
      description: [
        `<strong>The quiet part out loud...</strong>`,
        `Doug Little: "My position is we must make the lives of the unvaccinated a total misery and just keep escalating the exclusions until we crush the resistance and break their spirit in order to force compliance and so they learn not to resist government mandates."`,
      ],
      urls: [
        `https://twitter.com/gddub/status/1480221017557581824/photo/1`
      ],
    },
    {
      description: [
        `<b>Mattias Desmet on Our Grave Situation</b>`,
        `"The real reason why people buy into the story, even if it is utterly absurd, is not because they believe in the narrative, it is because the narrative leads to the new social bond."`
      ],
      urls: [
        `https://www.youtube.com/watch?v=CRo-ieBEw-8`
      ],
    },
    {
      description: [
        `<b>Justin Trudeau calls millions of unvaccinated Canadians misogynists, and racists who don't "believe" in science.</b>`,
        `He has denigrated Canadians as disease-carriers many times, as a divisive ploy to get elected. His statement about science proves that he doesn't even know what it is.`,
      ],
      urls: [
        `https://twitter.com/GregoryBedaux/status/1476542142478700552`
      ],
    },
    {
      description: [
        `<b>NYPD kicks out child from restaurant for not having a vaccine passport.</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1475841496322555906`
      ],
    },
    {
      description: [
        `Reporter: "If someone in your family isn't vaccinated, should you ask them not to show up?"`,
        `Fauci: "Yes, I would do that."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1473469424212615169`
      ],
    },
    {
      description: [
        `On a flight from Tampa to Atlanta, a woman physically assaulted a old man for not having his mask on (while her mask was off).`,
      ],
      urls: [
        `https://twitter.com/TheMarieOakes/status/1474621078072922116`
      ],
    },
    {
      description: [
        `Nadine Ness Has A Message For Justin Trudeau and Also For MPs`,
        `A message condeming Trudeau's messages of divisive and hate. You're not in danger if you come into contact someone who is vaccinated or not.`,
        `<b>Is it justified? Is exluding your loved ones really justified?</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=tJPnYnS2fzg`
      ],
    },
    {
      description: [
        `<b>Ontario's top doctor advises against seeing your triple vaccinated grandparent if you're double vaccinated.</b>`,
      ],
      urls: [
        `https://twitter.com/TrueNorthCentre/status/1471958495171948548`
      ],
    },
    {
      description: [
        `<b>Unvaccinated man denied access to purchase food at a farmers market in Toronto, Canada.</b>`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1472762865207713793`
      ],
    },
    {
      description: [
        `Dr. Kieran Moore, Ontario`,
        `<b>"A basic means of protecting individuals is stopping the mixing of unvaccinated and vaccinated."</b>`,
        `Appalling language.`,
      ],
      urls: [
        `https://twitter.com/rubiconcapital_/status/1468292629817438209`
      ],
    },
    {
      description: [
        `Father who's medically exempt from the covid jab because of a heart condition, refused entry to watch his daughter play basketball.`,
      ],
      urls: [
        `https://gettr.com/post/prv8iib645`
      ],
    },
    {
      description: [
        `Back To The 1930s`,
        `Unvaccinated banned from stores or are foridden to buy certain type of products.`,
        `A look at data and contradictory statements from officials.`,
      ],
      urls: [
        `https://newtube.app/TonyHeller/7gdvt43`,
      ]
    },
    {
      description: [
        `Toronto Star prints: "I have no empathy left for the willfully unvaccinated. Let them die. Unvaccinated patients do not deserve ICU beds." etc etc..`,
      ],
      urls: [
        `https://twitter.com/1ightbring3r/status/1503407763149361154/photo/1`
      ],
    },
    {
      description: [
        `It's our job to NOT accommodate their fears. Bret Weinstein's Dark Horse Podcast.`,
        `"You've disordered the development of children, and now you're going to take what they claim as the god's honest truth, and we need to change what we do to accommodate their desires and fears?`,
        `No, it's our job to do the <b>opposite</b>. It's our job to NOT accommodate their fears and to help them undo some of the damage that we have done to them."`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=cfdeaxpTUD8`
      ],
    },
    {
      description: [
        `Delta airlines telling a passenger he must take off his "fuck Biden" hoodie or they'll suspend the full flight and add the gentleman to the no fly list? Wtf!`,
      ],
      urls: [
        `https://gettr.com/post/pz9vgzf83b`
      ],
    },
    {
      description: [
        `Vice News:`,
        `We may as well admit it: a lot of people don't care if anti-vaxxers die anymore. If anything, they think it's funny.`,
        `Making fun of dead anti-vaxxers is now mainstream.`,
      ],
      urls: [
        `https://media.gettr.com/group11/getter/2022/03/21/18/6da1bdce-087d-9abe-4789-78a199e987e2/1ea5f2721d819522e0b8385621291a20.jpg`
      ],
    },
    {
      description: [
        `How come they're allowed to pass off correlation as if it's causation and we aren't?`,
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1515330648419946497`
      ],
    },
    {
      description: [
        `Toronto Star headlines from August 2021`,
        `I have no empathy left for the willfully unvaccinated. Let them die.`,
        `Unvaccinated patients do not deserve ICU beds.`,
      ],
      urls: [
        `https://twitter.com/ezralevant/status/1516017582972809217/photo/1`
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan:`,
        `You would expect most research on the vaccines to be on their safety and efficacy, right? A search of recent research shows that this is clearly not the case. What is it about? The research is focused on the psychology of the unvaccinated and how to get us vaccinated.`,
        `I periodically scan Google Scholar for the newest research on the vaccines. If you type “vaccine” in the search bar, and sort the list by date so that the newest research is on top. This is a sampling of the newest research at the top of the list.`,
      ],
      urls: [
        `https://threadreaderapp.com/thread/1518044232526741505.html`,
        `https://twitter.com/ScienceWDrDoug/status/1518044232526741505`,
      ],
    },
    {
      description: [
        `It's almost like there's a single editor passing out the same assignment to every news agency.`,
        `"Mixing" with unvaccinated people "increases risk" for vaccinated.`
      ],
      urls: [
        `https://twitter.com/thackerpd/status/1519278893043265546/photo/1`
      ],
    },
    {
      description: [
        `Marked as unvaxxed and segregated from his peers by Canadian Cadets this boy was among those made to stand behind a fence and watch while the rest of the group went for a flight.`,
      ],
      urls: [
        `https://twitter.com/livewiththeshed/status/1524178859582701568`
      ],
    },
    {
      description: [
        `<b>Ukranians are exempt from Canada's COVID-19 vaccination entry requirements</b>`,
        `Canada-Ukraine Authorization for Emergency Travel: Government of Canada`,
      ],
      urls: [
        `https://www.canada.ca/en/immigration-refugees-citizenship/news/2022/03/canada-ukraine-authorization-for-emergency-travel.html`
      ],
    },
    {
      description: [
        `Justice Centre calls on Albertans to petition Smith to save unvaxxed transplant patient`,
        `..Sheila Annette Lewis' case — a terminally ill Alberta grandmother in need of a life-saving organ transplant, but being denied the treatment due to her choice to remain unvaccinated against COVID-19.`,
      ],
      urls: [
        `https://www.westernstandard.news/alberta/justice-centre-calls-on-albertans-to-petition-smith-to-save-unvaxxed-transplant-patient/article_e8de7dde-6514-11ed-a07a-e369af3736ee.html?utm_campaign=blox&utm_source=twitter&utm_medium=social`
      ],
    },
    {
      description: [
        `<b>Re: URGENT: Discrimination of the non vaccinated and the refusal to transport by EMS in Alberta- Oct 23, 2022</b>`,
        `Alberta Paramedics refusing transport based on Vaccine Status!`,
        `The Paramedics supervisor stated, "The paramedic has the right to refuse based on their vaccine status if they feel unsafe from that individual's vaccine status."`,
      ],
      urls: [
        `https://twitter.com/KarenAnnDickson/status/1587056278354153472`
      ],
    },
    {
      description: [
        `Justin Trudeau being divisive for 21 seconds.`,
      ],
      urls: [
        `https://twitter.com/midnightriderV2/status/1584193977297043457`
      ],
    },
    {
      description: [
        `From 2005, Kamau Kambon, who taught Africana Studies 241 in the Spring 2005 semester at North Carolina State University, calls for white genocide`,
      ],
      urls: [
        `https://twitter.com/realstewpeters/status/1638606910717829135`
      ],
    },
    {
      description: [
        `<b>The College of Physicians and Surgeons in Ontario is basically telling doctors to prescribe drugs or refer patients to a psychiatrist if they don't want the vaccine. This is horrific. Yet another reason for lowered trust in our health care system.</b>`,
        `"It is also important that physicians work with their patients to manage anxieties related to the vaccine and not enable avoidance behaviour. In cases of serious concern, responsible use of prescription medications and/or referral to psychotherapy are available options."`,
      ],
      urls: [
        `https://twitter.com/NadineGNess/status/1577456104858341376`,
        `https://www.cpso.on.ca/en/Physicians/Your-Practice/Physician-Advisory-Services/COVID-19-FAQs-for-Physicians`,
      ],
    },
    {
      description: [
        `"The desire was perfect safety... We essentially treat each other as biohazards in a bid to stay away from the virus. When someone says, these things are incredibly destructive, the scapegoating mechanism is exactly what happened."`,
        `Dr. J. Bhattacharya`,
      ],
      urls: [
        `https://twitter.com/katewand/status/1577663740811661312`,
        `https://www.youtube.com/watch?v=RChf-siETRY`,
      ],
    },
    {
      description: [
        `Columbia University medical students chant the mantra of critical race theory. This is the DEI cult in the wild.`,
      ],
      urls: [
        `https://twitter.com/realchrisrufo/status/1625290540802781184`
      ],
    },
    {
      description: [
        `New Zealand Intelligence booklets have been released telling the public that if they suspect their friends or family are opposing government policies, including COVID measures, they should be reported as they are a terrorist.`,
      ],
      urls: [
        `https://twitter.com/freemoneyman420/status/1603767373437304833`,
        `https://rumble.com/v20xyuq-december-16-2022.html`,
      ],
    },
    {
      description: [
        `Don Lemon said unvaccinated Americans should be shamed for “believing the lies on the internet instead of science, or leave them behind.”`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1616837815979114502`
      ],
    },
    {
      description: [
        `<b>Stephanie Warriner died after being CHOKED TO DEATH by security in a Toronto hospital for having her MASK too low.</b>`,
        `Coroner's report ruled choking was direct cause: she died "due to restraint asphyxia following struggle & exertion”`,
        `Guards were ACQUITTED`,
      ],
      urls: [
        `https://twitter.com/MaajidNawaz/status/1615311562301923328`,
        `https://hotair.com/david-strom/2023/01/16/outrageous-but-unsurprising-canadian-judge-tosses-case-against-guards-who-killed-a-woman-for-not-wearing-a-mask-n524001`,
      ],
    },
    {
      description: [
        `New Zealand Intelligence booklets have been released telling the public that if they suspect their friends or family are opposing government policies, including COVID measures, they should be reported as they are a terrorist.`,
      ],
      urls: [
        `https://rumble.com/v20xyuq-december-16-2022.html`
      ],
    },
    {
      description: [
        `“The Unvaccinated are Criminals and they MUST be stripped of their jobs…” - Sean Penn`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1604318072843673600`
      ],
    },
    {
      description: [
        `Gene Simmons: “You don't have as many rights as you think … Shut up, be respectful, and get a vaccine.”`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1604136450769158144`
      ],
    },
    {
      description: [
        `Canada develops its own version of "psychosis"`,
        `How odd Possibly because of that small fringe minority...`,
      ],
      urls: [
        `https://twitter.com/Bel_B30/status/1603090993439555586`
      ],
    },
    {
      description: [
        `Nature article:`,
        `<b>Discriminatory attitudes against unvaccinated people during the pandemic</b>`,
      ],
      urls: [
        `https://www.nature.com/articles/s41586-022-05607-y`
      ],
    },
    {
      description: [
        `The media in Canada : Why are we under attack?`,
        `Could it be that Canadians are tired of being attacked by you?`,
      ],
      urls: [
        `https://twitter.com/ryangerritsen/status/1600863693784633344/photo/1`
      ],
    },
    {
      description: [
        `Rob Stewart only allowing triple-vaccinated guests into Christmas party as he blasts "killer" ant-vaxxers.`,
        `Later on..`,
        `Rod Stewart revelas 11-yearl-old son rushed to hospital with suspected heart attack.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1600543405117181952`
      ],
    },
    {
      description: [
        `FLASHBACK: Trudeau says "This country is a country of openness, of respect, of compassion, of the rule of law, of the rights of the individuals, of freedom … Freedom to do what you want with your body"`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1600548071129354240`
      ],
    },
    {
      description: [
        `Duke University surgeons are refusing a 14-year-old girl a kidney transplant because she has not received the Covid shot `,
      ],
      urls: [
        `https://twitter.com/TomFitton/status/1600540375021948928`,
        `https://alexberenson.substack.com/p/duke-university-surgeons-are-refusing`,
      ],
    },
    {
      description: [
        `China - Citizens with a red QR code are not allowed to participate in society and are then rounded up and sent off to "quarantine" camps.`,
        `It's not about safety. It's all about totalitarian control.`,
      ],
      urls: [
        `https://twitter.com/JamesMelville/status/1599743750888075264`
      ],
    },
    {
      description: [
        `CTV guest condemns unvaccinated, blames them for schools not being open.`,
        `"Health advocate with a side of hate."`,
      ],
      urls: [
        `https://twitter.com/canmericanized/status/1598140237427138561`
      ],
    },
    {
      description: [
        `- NZ PM Jacinda Ardern puts New Zealand under lockdown for single case of covid.`,
        `- NZ PM admits to treating unvaxxed as second-class citizens.`,
        `- Jacinda Ardern resigns and PM of New Zealand`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=4_S6TDT-CQk`
      ],
    },
    {
      description: [
        `The same bureaucrats who imposed vaccine passports are now suddenly refusing to declare their status, citing “private medical information”.`,
        `You can't make this stuff up.`,
      ],
      urls: [
        `https://twitter.com/OzraeliAvi/status/1626344045953044480`
      ],
    },
    {
      description: [
        `Article heading from "IQfy":`,
        `They knew: why didn't the unvaccinated do more to warn us?`,
      ],
      urls: [
        `https://twitter.com/ClownWorld_/status/1618727573617516554`
      ],
    },
    {
      description: [
        `<b>Tony Blair:</b>`,
        `If you're eligible, and you've got no health reason for not being vaccinated, you're not just irresponsible, I mean, you're an idiot! I'm sorry, that's.. truthfully you are!`,
        `I mean, because, this Omicron variant is so.. uh.. contagious, you know, if you're unvaccinated and you're in circulation.. you're going to get it.`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1618155004715753472`
      ],
    },
    {
      description: [
        `US Gov posts study on official website, which concludes:`,
        `<b>Adverse effects from mRNa injections are due to stress caused by 'anti-vaxxers'!</b>`,
      ],
      urls: [
        `https://twitter.com/AndersonAfDMdEP/status/1597323393325748224`,
        `https://pubmed.ncbi.nlm.nih.gov/36381188/`,
      ],
    },
    {
      description: [
        `Biden: "Let's be clear — vaccination requirements should not be another issue that divides us."`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1448697551318835203`
      ],
    },
    {
      description: [
        `<b>Tim Horton's employee won't give woman in drive through her coffee if she doesn't have a mask on.</b>`,
        `Woman: Hi`,
        `Employee: Hi do you have a mask?`,
        `W: No I don't.`,
        `E: I can give you one.`,
        `W: Uh.. you can give me one?`,
        `E: I can give you one, I need you to wear a mask.`,
        `W: So you can hand me a mask..`,
        `E: I can hand you one, yeah.`,
        `W: But you can't hand me the drink without a mask?`,
        `E: ... y-you've gotta wear a mask.`,
        `W: How does that make any sense?`,
        `E: ... that's what. that's what I.. I just need you to wear a mask.. and you.. do you mind?`,
        `W: Well if you can hand me a mask, why can't you just hand me the drink?`,
        `E: ... I can hand you the mask.`,
      ],
      urls: [
        `https://twitter.com/MrGamatos/status/1449088771408990218`
      ],
    },
    {
      description: [
        `<b>Dr. Deena Hinshaw apologizes and says Alberta teen didn't actually die from COVID</b>`,
        `Embarrassing walk-back from a public health official who used a tragic teenage death to advance a preferred policy narrative.`,
        `A timely, and sad, reminder that dying "with" the COVID virus does not mean dying "because of" COVID.`,
        `https://nationalpost.com/news/dr-deena-hinshaw-says-alberta-teen-didnt-actually-die-from-covid`,
      ],
      urls: [
        `https://twitter.com/rpoconnor/status/1448832181703430145`
      ],
    },
    {
      description: [
        `"It's very polite of COVID that it stops being a deadly supervirus whenever politicians or the rich and famous have travel plans or a big party to attend."`,
      ],
      urls: [
        `https://twitter.com/MichaelPSenger/status/1439997909840191492`
      ],
    },
    {
      description: [
        `A senior is kicked out of a McDonalds in Toronto after not being able to provide proof of vaccination`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1440760771005140997`
      ],
    },
    {
      description: [
        `<b>CBC: 172 Windsor, Ont., hospital staff suspended without pay for not getting COVID-19 shots</b>`,
        `September 22, 2021`,
      ],
      urls: [
        `https://www.cbc.ca/news/canada/windsor/dozens-windsor-essex-hospital-staff-1.6185369`
      ],
    },
    {
      description: [
        `Sep 22, 2021`,
        `"My 4 year old son was denied entrance to his program today because accompanying parent must provide proof of vax.`,
        `I left 🇨🇳 and moved back to 🇨🇦 in 2014 to raise my kids here. Never dreamed I would witness what we're seeing play out in our country and society.`,
        `Disgusted."`,
      ],
      urls: [
        `https://twitter.com/thekylefree/status/1440795006059511813`
      ],
    },
    {
      description: [
        `Egg thrown at French President Emmanuel Macron`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1442490685777608705`
      ],
    },
    {
      description: [
        `<b>College of Physicians and Surgeons of Ontario (CPSO) council member is publicly suggesting treatments should be withheld from unvaccinated patients who aren't deserving of the expense.</b>`,
        `Are you starting to see how compromised this organization is?`,
        `This doctor is the one tasked with upholding the standards of the profession`,
      ],
      urls: [
        `https://twitter.com/DrP_MD/status/1442463045419032580`
      ],
    },
    {
      description: [
        `Greta Thunberg says “blah blah blah” and people cheer.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1442884989113323520`
      ],
    },
    {
      description: [
        `Should we be surprised? Dividing Canadians and inflaming tensions has been the official goal of all governments for months.`,
        `"Tensions high between vaccinated and unvaccinated in Canada: Poll"`,
        `No shit.`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1442626175193468933`,
        `https://torontosun.com/news/national/poll-tensions-high-between-vaccinated-and-unvaccinated-in-canada`,
      ],
    },
    {
      description: [
        `Biden says unvaccinated people are causing “an awful lot of damage”`,
      ],
      urls: [
        `https://rumble.com/vn12rp-biden-says-unvaccinated-people-are-causing-an-awful-lot-of-damage.html`
      ],
    },
    {
      description: [
        `<b>(Unhinged) Keith Olbermann makes it very clear.</b>`,
        `As he is getting a shot: "Time to stop coddling people who won't get the shot."`,
        `Holds up his booster card: "Booster shot. Mission accomplished.`,
        `And it is.. it is time to stop coddling them. The ones who won't get the damn shot already.`,
        `And our first step, you and I, is symbols, the language we use, we call these people "vaccine hesitant", "vaccine skeptics", "anti-vax", we say they're protesting mandates and passports, "they're making a personal choice", they're waiting for more information, they're making a medical decision.`,
        `BULLSHIT.`,
        `They're afraid. They're afraid to get vaccinated.`,
        `Stop feeding their egos about what they're doing, stop legitimizing it. "Vaccine hesitant"? They're afraid! "Vaccine skeptics"? They're afraid. "Anti-vax" they're afraid. "They're protesting mandates and passports" they're afraid. They're making a personal choice? They're afraid. They're waiting for more information, afraid! They're making a medical decision to be AFRAID!`,
        `The snowflakes are afraid! Afraid of the vaccine. Afraid of being proved wrong. Afraid of doing what anybody else in the world tells them to do. Afraid of needles.`,
        `So no more pleasant euphemisms about "what's going on" here, apart from the people who have legitimate, medical complications about vaccines.`,
        `<b>We have to stop coddling the morons who will not get the shot.</b>`,
        `We start by calling them what they are: They. Are all. Snowflakes. And cowards. And idiots. And losers. And most importantly, they. Are. AFRAID!`,
      ],
      urls: [
        `https://twitter.com/betterworld_24/status/1444296354486964228`
      ],
    },
    {
      description: [
        `<b>Flashback to Trudeau saying the federal government will pay for provincial vaccination programs.</b>`,
        `We're going to work with provinces and territories who want to move forward on vaccination certifications, on vaccination passports, so that everyone can be safe.`,
        `And what's more, the federal government has announced we're going to pay for the development of those <b>privilages that you get once you get vaccinated.</b>`,
        `<b>Because everyone needs to get vaccinated, and *points away* "those people" are putting us all at risk.</b>`,
      ],
      urls: [
        `https://twitter.com/Phil2284/status/1645660217936596992`
      ],
    },
  ],
  SocialEngineering: [
    {
      description: [
        `Fact Checking the “Fact Checkers”`,
        `Mark Dice shows the contradictions and falsities of “fact checking” websites.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=24H5-18aCDU`,
      ],
    },
    {
      description: [
        `Social engineering in TV commercials`,
      ],
      urls: [
        `https://www.bitchute.com/video/bxmnbeURBlV4/`,
      ],
    },
    {
      description: [
        `The Big Lie - How to Enslave the World`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=6VfJ0BJvt7Y`,
      ],
    },
    {
      description: [
        `Russell Brand talks about Joe Rogan talking about CNN's True Agenda, with their apparent disinformation campaign against Ivermectin, a medication which is effective at treating covid19`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=h5xqP-aPwOU`,
      ],
    },
    {
      description: [
        `<strong>On Joe Rogan, Dr Robert Malone suggests we are living through a mass formation psychosis.</strong>`,
        `He explains how and why this could happen, and its effects. He draws analogy to 1920s/30s Germany “they had a highly intelligent, highly educated population, and they went barking mad”`,
        `"When you have a society that has become decoupled from each other, and has free-floating anxiety, and a sense that things don't make sense, we can't understand it. And then their attention gets focused by a leader or series of events... <strong>on one small point, just like hypnosis</strong>... they literally become hypnotized, and can be led anywhere.`,
        `And one of the aspects of that phenomenon, is the people that they identify as their leaders, the ones typically that come in and say, "you have this pain and I can solve it for you. I and I alone... can fix this problem for you"... then they will follow that person though hell- it doesn't matter whether they lied to them, or whatever. The data are irrelevant.`,
        `And furthermore, anybody who questions that narrative is to be immediately attacked, they are "the other". This is central to mass-formation-psychosis.`,
        `And this <strong>is</strong> what has happened. We had all those conditions."`
      ],
      urls: [
        `https://twitter.com/MythinformedMKE/status/1477091670424526852`,
        `https://open.spotify.com/episode/3SCsueX2bZdbEzRtKOCEyT`,
      ],
    },
    {
      description: [
        `One tiny step at a time...`,
      ],
      urls: [
        `https://twitter.com/aginnt/status/1468273632140939265`
      ],
    },
    {
      description: [
        `Julian Assange 2011:`,
        `"Nearly every war that has started in the last 50 years have been a result of media lies, the media could have stopped it if they searched deep enough, if they hadn't reprinted government propaganda, they could've stopped it, but what does that mean?...Populations don't like wars.. and populations have to be fooled into wars..."`
      ],
      urls: [
        `https://gettr.com/post/pz9xxn60a6`
      ],
    },
    {
      description: [
        `World Economic Forum: You'll own nothing and you'll be happy.`,
      ],
      urls: [
        `https://www.bitchute.com/video/9IK8CBTqnMoQ/`
      ],
    },
    {
      description: [
        `Dozens of news channels in unison saying, <b>"This is very dangerous to our democracy"</b>.`,
        `Lockstep.`
      ],
      urls: [
        `https://twitter.com/akheriaty/status/1596331604070174727`
      ],
    },
    {
      description: [
        `Dr. Thomas Binder Interview - How Psychology Was Weaponized To Suppress Truth In The Age Of COVID`,
      ],
      urls: [
        `https://rumble.com/v1jowcx-dr.-thomas-binder-interview-how-psychology-was-weaponized-to-suppress-truth.html`
      ],
    },
    {
      description: [
        `Columbia University medical students chant the mantra of critical race theory. This is the DEI cult in the wild.`,
      ],
      urls: [
        `https://twitter.com/realchrisrufo/status/1625290540802781184`
      ],
    },
    {
      description: [
        `<b>The President of the European Research Council, @mleptin, in front of a World Economic Forum (WEF) backdrop, declared that efforts to vaccinate the whole population shouldn't be approached as a scientific endeavor, but as warfare.</b>`,
        `<b>"So, no science. Let's remember that."</b>`,
        `Perhaps interestingly, two of the countries which were most successful in getting good coverage of vaccination, based this not at all on getting their citizens to try and understand the science. One is Bhutan, which where they were very successful in preparing a campaign, and involved and were senstitive to the country's needs, the citizens' needs, involved, informing the religious establishment, and in fact using them, in finding the right time and day. And they got fantastic coverage No science was explained.`,
        `The other example I know of is Portugal, where the campaign was handed a retired army general. And the army general just treated the country as his troops and he "rallied the troops".`,
        `<b>He declared it as a "war" that the country, in patriotic passion, was going to fight together.</b> And they had up there, I think they were leading Europe if not the world.`,
        `So.. No science.`,
      ],
      urls: [
        `https://twitter.com/goddeketal/status/1623058597797335040`
      ],
    },
    {
      description: [
        `<b>Pfizer's COVID ad starring Michael Phelps, Pink, Jean Smart & Questlove</b>`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1622612675493601282`
      ],
    },
    {
      description: [
        `Bullshit alert on gas stove hate.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1615717433418911744`
      ],
    },
    {
      description: [
        `<b>The Lester Holt Anthony Fauci Interview is 4D Level Propaganda - Viva Frei Vlawg</b>`,
        `We are going to break down the levels of propaganda in this, so that you on your own can hopefully identify the propaganda in the future, know how you are being manipulated by mainstream media, and potentially, hopefully avoid being so manipulated.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=p3aJHR7QtDo`
      ],
    },
    {
      description: [
        `Spencer Fernando: How Many Other Government Propaganda Techniques Have Been Used To Keep Canadians In A State Of Fear & Panic?`,
        `<b>A fearful populace = a more powerful government. Politicians know this, and they are exploiting this.</b>`,
      ],
      urls: [
        `https://spencerfernando.com/2021/09/28/how-many-other-government-propaganda-techniques-have-been-used-to-keep-canadians-in-a-state-of-fear-panic/`
      ],
    },
  ],
  SubstandardTrials: [
    {
      description: [
      `"A CRAZY MESS"!! Pfizer Whistleblower SLAMS Vaccine Trials`,
      ],
      urls: [
      `https://www.youtube.com/watch?v=g-pqpauJ90Y`,
      ],
    },
    {
      description: [
      `"We have not evaluated the safety of the aluminum nanoparticle, and its injection."`,
      `"The kind of aluminuum that we put into vaccines is a different kind of aluminum than we see environmentally. This is called a nanoparticle. And nanoparticles bind really tightly to the bacteria antigens, the virus antigens, of food protein antigens and any other contaminants that are in the vaccines that we may not know about.`,
      `And we know that the biochemical properties of nanoparticles is that they are capable of entering the brain. And so we have not evaluated the safety of the aluminum nanoparticle and its injection, and where it goes when it gets into the body and whether it gets into the brain.`,
      `Do vaccine ingredients belong in the brain? No. Do they get into the brain? No one has ever studied it, but animal studies using the same chemicals that are in vaccines that we give to children, directly demonstrate that the vaccines ingredients <strong>do enter the brain.</strong>"`,
      ],
      urls: [
      `https://www.bitchute.com/video/hllgZcnksL7G/`,
      ],
    },
    {
      description: [
        `<b>The Pfizer mRNA vaccine: pharmacokinetics and toxicity</b>`,
        `Michael Palmer, MD and Sucharit Bhakdi, MD`,
        `We summarize the findings of an animal study which Pfizer submitted to the Japanese health authorities in 2020, and which pertained to the distribution and elimination of a model mRNA vaccine. <b>We show that this study clearly presaged grave risks of blood clotting and other adverse effects.</b>`,
        `The failure to monitor and assess these risks in the subsequent clinical trials, and the grossly negligent review process in conjunction with the emergency use authorizations, have <b>predictably resulted in an unprecedented medical disaster.</b>`
      ],
      urls: [
        `https://d3n8a8pro7vhmx.cloudfront.net/randyhillier/pages/958/attachments/original/1628198186/Pfizer-pharmacokinetics-and-toxicity.pdf`
      ],
    },
    {
      description: [
        `Dr Fauci Discusses Vaccine Safety`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=81nsjv7E-OE`
      ],
    },
    {
      description: [
        `Russell Brand: The Truth Is Coming Out`,
        `Re: Pfizer's 55,000 page release on their trials which the FDA wanted to keep hidden for 75 years.`
      ],
      urls: [
        `https://www.youtube.com/watch?v=sqD_JYAs7pU`
      ],
    },
    {
      description: [
        `Dr. John Campbell - The illusion of evidence based medicine`,
        `"..How appalling that a court must <b>order</b> the release of clinical information."`,
        `Academics become agents for the promotion of commercial products.`,
        `"We need complete freedom of speech in the scientific domain, and right now we have not got that.. in the scientific domain or in the medical domain."`,
        `"The actual people doing the regulating receive funding from industry, and use industry funded and performed trials to approve drugs.. again it's completely circular."`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=vZlZIXHT0yA`
      ],
    },
    {
      description: [
        `Barack Obama casually, charismatically admitting to crimes against humanity and Nuremberg Code violations.`,
        `"..we've now, essentially, clinically tested *chuckles* the vaccine on billions of people worldwide.."`
      ],
      urls: [
        `https://twitter.com/thevivafrei/status/1517621377536339970`,
        `https://www.youtube.com/watch?v=MpcrM_y0_wI`,
      ],
    },
    {
      description: [
        `Dr. Robert Malone speaking about Pfizer: In my opinion, withholding scientific data constitues fraud. This is scientific fraud.`,
        `Pfizer and the CDC committed fraud for willfully withholding critical data from the public resulting in harm and death to thousands. The CDC is spreading medical misinformation. What they have done is obscene. We are completely vindicated.`,
        `Pfizer and the CDC were hiding data that showed harm and death by injection. What's going on here is criminal. For the people who knew the data, but said nothing there will be legal consequences. Either you will be witnesses or defendants.`,
        `The FDA and the CDC had to be sued to release the data. The reluctance to release the data on a biological product that every person is being asked to take should make everyone concerned. DOD data shows clear increase in disease after injection.`,
      ],
      urls: [
        `https://twitter.com/LakovosJustice_/status/1522001676508581889`,
        `https://twitter.com/LakovosJustice_/status/1522001757378859010`,
        `https://twitter.com/LakovosJustice_/status/1522001827352432640`,
        `https://threadreaderapp.com/thread/1522001676508581889.html`,
      ],
    },
    {
      description: [
        `Dr. Clare Craig explains why the FDA should NOT have granted approval for roll-out in the 6 month to 4 yr old children cohort`,
        `<b>"This trial should have been deemed null and void. The regulators are failing to do their job."</b>`,
        `"I want to take you through the evidence that Pfizer just presented to the FDA on 6 month to 4 year old children.`,
        `There's an awful lot about this trial that has shocked me and I think will shock you too.`,
        `The trial recruited 4,526 children, age from 6 months to 4 years old. 3,000 of these children did not make it to the end of the trial.`,
        `That is a huge number, two thirds of them.`,
        `Why was there this drop off? That needs to be answered, and without an answer to that, on that basis alone, this trial should be deemed null and void.`,
        `So what did the trial show?`,
        `Well, they defined severe covid as children who had a slightly raised heart rate, or fewer breaths per minute.`,
        `There were 6 children aged 2 to 4 who had severe covid in the vaccine group, but only one in the placebo group. So on that basis, the likelihood that this vaccine is actually causing severed covid is higher than the likelihood that it isn't.`,
        `There was actually one child who was hospitalized in this trial. They had a fever and a seizure. THey had been vaccinated.`,
        `So now let's turn to what the defined as "any covid", and what they did was to utterly twist the data.`,
        `They vaccinated the children and they waited three weeks after the first dose before the second dose. In that three week period, 34 of the vaccinated children got covid, and only 13 in the placebo group, <b>which worked out as a 30% increased chance of catching covid in that three week period if you were vaccinated.</b> So they ignored that data.`,
        `And then there was an eight week gap between the second dose and the third dose, where again, children were getting plenty of covid in the vaccine arm. So they ignored that data.`,
        `There was then seceral weeks after the third dose which they also ignored, which meant that <b>in the end they had ignored 97% if the covid that occurred during the trial</b> and they just looked at tiny numbers - so tiny. <b>In the end they were comparing 3 children in the vaccine arm who had covid with 7 in the placebo arm and they said that this showed the vaccine was effective.</b>`,
        ``,
        `So they measured how many of these children actually managed to catch covid twice in the two-month follow up period. And there were 12 children who had covid twice, and all but once of them were vaccinated. Mostly with three doses.`,
        `So you have to wonder what on earth they're thinking when the claim of reduction in covid was only 4 children and here we have 12 children who got covid twice, 11 of them vaccinated.`,
        `So let's just recap.`,
        `They recruited 4,500 children, 3,000 of them dropped out. And in the end they're claiming this vaccine works on the basis of three covid cases versus 7, a difference of 4 children only.`,
        `And all of this on a backdrop of a disease which doesn't affect children, and with no long-term safety data.`,
        `We have to ask how an ethics committee could have approved this trial in babies. Babies are not at risk from covid.`,
        `And now we have Pfizer, who are presenting this as evidence to the FDA in order to apply for an Emergency Use Authorization.`,
        `Emergency Use Authorization is meant for a situation where there's a risk of serious injury or death.`,
        `Now, children under 5 are not at risk of serious injury or death from covid, in fact in their own trial, they had to make up other ways of measuring the problem, because there was no serious injury or death.`,
        `Now, originally these products were sold as actually also reducing transmission.`,
        `Now it would be completely unethical to use young children as a human shield, but we know know that they don't reduce transmission. The WHO have stopped claiming that they reduce transmission. So that argument doesn't apply either.`,
        `Now, if we just turn to safety, what they did is they followed up patients for 6 weeks before unblinding them and vaccinating them. So the children who'd had placebo, the control group, were followed up for an average fo 6 weeks and then given the vaccine.`,
        `So that's your safety control gone forever.`,
        `The fact that this trial existed at all is unbelievable.`,
        `There are other issues in there which I haven't highlighted, but those are the key ones.`,
        `<b>Parents should be demanding that the decision makers explain themselves.</b>`,
      ],
      urls: [
        `https://twitter.com/hartgroup_org/status/1537458392414969856`,
        `https://twitter.com/hartgroup_org/status/1537458427349303299`,
      ],
    },
    {
      description: [
        `British Medical Journal Investigation re: covid vac clinical trials in the British Medical Journal, one of the oldest & prestigious peer-reviewed medical/scientific journals in the world:`,
        `<b>"FDA oversight of clinical trials is 'grossly inadequate' say experts"</b>`,
        `"FDA is 'endangering public health' by not being candid re violations uncovered during clinical trial site inspections.. Lack of transparency and data sharing doesn't allow physicians/scientists to confirm data indep & make comprehensive risk-benefit assessments"`,
        `"The FDA has a long history of failing adequately to oversee clinical trial sites.. Some 65% of FDA's funding for the evaluation of drugs comes from industry user fees and in return the agency has mandated deadlines for decisions on new product applications"`,
      ],
      urls: [
        `https://www.bmj.com/content/379/bmj.o2628`,
        `https://twitter.com/dockaurG/status/1593133224473276417`,
        `https://twitter.com/dockaurG/status/1593133355029377025`,
        `https://twitter.com/dockaurG/status/1593133584726241280`,
      ],
    },
    {
      description: [
        `Moderna/Pfizer's C19-V clinical trials involving children identified elevated rate of Respiratory Syncytial Virus (RSV). FDA's vaccine committee even voiced concerns. RSV now spiking in children. CDC's befuddling advice on RSV is to recommend more C19-V.`,
      ],
      urls: [
        `https://twitter.com/AaronSiriSG/status/1589722232842223616`,
        `https://icandecide.org/press-release/ican-demands-answers-from-the-cdc-about-spike-in-rsv-rates/`,
      ],
    },
    {
      description: [
        `<b>Still 'unknown' safety, efficacy in Pfizer contracts (Slovenia)</b>`,
      ],
      urls: [
        `https://www.normanfenton.com/post/still-unknown-safety-efficacy-in-pfizer-contracts-slovenia`
      ],
    },
    {
      description: [
        `<b>In COVID hearing, Pfizer director admits: covid vaccine was never tested on preventing transmission.</b>`,
        `"Get vaccinated for others" was always a lie. The only purpose of the COVID passport: forcing people to get vaccinated."`,
        `---`,
        `Rob Roos MEP:`,
        `"If you don't get vaccinated, you're anti-social - This is what the Dutch Prime Minister and Health Minister told us.`,
        `You don't get vaccinated just for yourself, but also for others - you do it for all of society. That's what they said.`,
        `Today, this turns out to be complete nonsense. In a COVID hearing in the European Parliament, one of the Pfizer directors just admitted to me: at the time of the introduction, the vaccine had never been tested on stopping the transmission of the virus.`,
        `<b>This removes the entire legal basis for the COVID passport. The COVID passport that lef to massive institutional discrimination, as people lost access to essential parts of society.</b>`,
        `I find this to be shocking - even criminal.`,
        `Please watch the video until the end.."`,
        `---`,
        `Rob Roos MEP:`,
        `"But to you, Ms. Small, I have the following short question, to which I would like to receive a clear response. I'll speak in English so there are no misunderstandings:`,
        `<b>Was the Pfizer COVID vaccine tested on stopping the transmission of the virus before it entered the market?</b>`,
        `If not, please say it clearly. If yes, are you willing to share the data with this committee?`,
        `And I really want a straight answer. Yes or no. And I'm looking forward to it. Thank you very much."`,
        `---`,
        `Janine. Small: Pfizer Director:`,
        `"Regarding the question around did we know (whether we knew) about stopping immunization before it entered the market?`,
        `<b>No, haha. These, emm, you know, we had to really move at the speed of science to really understand what is taking place in the market.</b>`,
        `---`,
        `Rob Roos MEP:`,
        `This is scandalous. Millions of people worldwide felt forced to get vaccinated because of the myth that <b>"you do it for others"</b>.`,
        `<b>Now, this turned out to be a cheap lie.</b>`,
        `------------`,
        `<b>Dr. John Campbell weighing in:</b>`,
        `"Pfizer Covid vaccine was NOT tested on stopping the transmission of the virus before it entered the market.`,
        `This was NOT done before the vaccine entered general rollout, despite us being assured that everything.. all the normal stages.. were carried out, as they would be for any new product, and yet it turns out now, we know what, however long it is now later, two years later or whatever, that this wasn't done.`,
        `This really is.. I think this is really quite scandalous. And I certainly feel personally let down by it, as I'm sure a lot of you do.`,
        `Continues reading: Ms. Small again, 'you know, we had to really move at the speed of science..'`,
        `<b>What I'd like to do now is pause and explain to you exactly what "the speed of science" means.. but I haven't got a flipping clue.</b>`,
        `It's just words without meaning. "The speed of science"?.. words without meaning. What does that mean? What's that supposed to mean? What's it supposed to convey? Just has no meaning to me at all - unless I'm a bit stupid of course.. maybe I'm just being a bit thick here and don't understand what "the speed of science" is.`,
        `But um.. <b>I've done two science degrees and a research degree and I've never heard the term..</b>`,
        `Anyway.. always need to learn new things..`,
      ],
      urls: [
        `https://twitter.com/Rob_Roos/status/1579759795225198593`,
        `https://www.youtube.com/watch?v=J6VbI8gOnUM`,
        `https://twitter.com/palminder1990/status/1579828305918058496`,
        `https://twitter.com/TrueNorthCentre/status/1579830040858329089`,
        `https://www.bitchute.com/video/o6ecHSN4izWF/`,
      ],
    },
    {
      description: [
        `MATT HANCOCK - lied to Parliament. He claimed he'd ordered a vitamin D trial & it did not “appear to have any impact”`,
        `Officials now admit NO trials took place! & studies now show it reduces the severity of Covid.`,
        `<b>Since the Covid-19 epidemic started multiple studies have repeatedly shown a link to Vitamin D deficiency yet when Matt Hancock was asked about it he WRONGLY said a British study had found the opposite. Is he ignorant or incompetent?</b>`,
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1594819033559404545`,
        `https://www.dailymail.co.uk/news/article-8793235/Matt-Hancock-wrngly-claims-vitamin-D-doesnt-work-Covid-19.html`,
      ],
    },
    {
      description: [
        `CDC finally caves and hands over their V-Safe data.`,
        `7.7% ( 782,900 people ) out of the 10 million included in the data, required medical care after taking the "safe and effective" COVID jabs that so many have been coerced into taking.`,
      ],
      urls: [
        `https://twitter.com/DreaHumphrey/status/1577830265824907264`
      ],
    },
    {
      description: [
        `Dr. Aseem Malhotra:`,
        `"I was under the impression that the vaccine would prevent transmission. We now know that is completely false and we don't even know if it was true in the beginning"`,
        `<b>Policymakers must pause the roll out. History will NOT be in their side & the public will not forgive them for it.</b>"`,
        `---`,
        `"I was under the impression that the vaccine would prevent transmission. We obviously now know that's completely false, not even true, we don't even know if it was true from the beginning.`,
        `We had data on hospitalizations and death actually. For 100,000 vaccinated vs unvaccinated. So we're able to separate it by age group.`,
        `So, you have to vaccinate 230 people over the age of 80 to prevent one covid death.`,
        `520 if you are 70 - 79`,
        `1300 if you are 60 - 69`,
        `2600 if you are 50 - 59`,
        `This was a number of very eminent scientists associated at the BMJ, and what they found in the original trials where everything is corrected, it's supposed to be the gold standard..`,
        `..the risk of serious adverse events that were reported in the vaccine group was significantly higher than the risk of people being hospitalized with covid.`,
        `This was the original, this was Pfizer and Moderna trials combined, and think about this for a second..`,
        `<b>It was these trials alone, before we had any of the data, that led to the approval and rollout of the vaccine.. not just rollout.. Coersion.Mandates. People being threatened with their jobs.</b>"`,
        `---`,
        `"Why, when we've got a serious adverse event rate, which is probably in the range of at least 1-in-800 to 1-in-1000, which is good quality data, why is it not being pulled, when other vaccines - swineflu vaccine 1976 was pulled because it cause Guillain-Barré Syndrome in 1-in-100,000 - Rotavirus vaccine was pulled in 1989 because it caused a form of bowel obstruction 1-in-10,000 -`,
        `We're talking serious adverse events of 1-in-800."`,
        `---`,
        `"Now it's high time that policy makers around the world put an end to the mandates.`,
        `History will not be on their side, and the public not forgive them for it.`,
        `---`,
        `<b>What we're dealing with now, with hindsight, is perhaps the greatest miscarriage of medical science we will witness in our lifetime.</b>`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1577895492264116225`
      ],
    },
    {
      description: [
        `Dr. Naomi Wolf:`,
        `"The statement that it was safe and effective, it wasn't based on any studies involving pregnant women.`,
        `<b>Pregnant women were excluded from the trials.</b>`,
        `The statement that it was safe and effective for pregnant women was based on a study of fourty-four (44) French rats, followed for forty-two (42) days, the baby rats were sacrificed in utero, they weren't allowed to get born so the scientists could assess if they were developing normally, eating, thriving..`,
        `And the scientists concluded that the fetal rats were fine. Those scientists and doctors were employees and shareholders of Pfizer.`,
        `In the Pfizer documents there's a section that shows that 270 women got pregnant nonetheless, even though they were excluded from the trials. Pfizer lost the records of 232 of them.. of the 38 women who's records Pfizer followed to comclusion, which they're supposed to do for all of them by law, 28 of those mothers lost their babies.`,
        `<b>28 of 32 lost their babies. (87.5%)</b>`,
        `And we know the mechanism for this. Dr. Robert Chandler broke down the biodistribution, which confirms what Dr. Victree referenced, a Japanese Pfizer biodistribution study of over a year ago, that shows indeed that <b>the materials don't stay in the injection site,</b> that all the spokesmodels swore that they did, <b>but in fact they travelled throughout the body within 15 minutes and concentrate especially in the liver, the adrenals, the spleen, and the ovaries.</b>`,
        `But what Dr. Chandler found, is that in the 48 hours that this experiment lasted, (and why did they cut it off at 48 hours, why didn't they let it go on for a week, or two, three weeks..) in the ovaries, the lipid nanoparticles and the spike protien, and the mRNA just accumulate and accumulate and accumulate, like 1800 times in the ovaries.`,
        `And what I want to say is, lipid nanoparticles are designed to cross every membrane in the human body. They were designed to cross the blood-brain barrier, and bring medicine to tumors for people with brain tumors, but healthy women who are pregnant were assured that this material could not affect their baby, but in fact a 2018 study out of China (and China shows up a lot in this alliance with Pfizer's manufacturing and distribution), <b>they knew that lipid nanoparticles accumulate in the ovaries, testes, and affect reproduction.</b>`,
        `So what you're getting is, some nanoparticles and the spike protein with the first injection, more in the second injection, more in the booster, more in the second booster, and <b>we don't see any mechanism whereby this material LEAVES the ovaries.</b>`
      ],
      urls: [
        `https://twitter.com/NewsNancy9/status/1577071559818514432`
      ],
    },
    {
      description: [
        `1.TWITTER FILES #19`,
        `The Great Covid-19 Lie Machine`,
        `Stanford, the Virality Project, and the Censorship of “True Stories”`,
      ],
      urls: [
        `https://twitter.com/mtaibbi/status/1636729166631432195`
      ],
    },
    {
      description: [
        `The Pfizer documents shows the rate of miscarriage of women in their first trimester is around the 80-82% rate.`,
        `“It actually works better than the abortion pill” ~ Dr Elizabeth Mumper`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1630275380547317760`
      ],
    },
    {
      description: [
        `Dr. John Campbell Reviews Newly Released Pfizer Biodistribution Data from Jan 2021`,
        `The injection does not stay in the injection site, and can be found from the ovaries and testes to the brain to bone marrow.`,
        `"I think we can be certain this information was on Anthony Fauci's desk...Bear in mind all this was known before the vaccines were approved...I'm struggling for words."`,
      ],
      urls: [
        `https://twitter.com/TheChiefNerd/status/1639609343824781312`,
        `https://www.youtube.com/watch?v=fVNFFtmb9gA`,
      ],
    },
    {
      description: [
        `Dr. John Campbell Reviews Newly Released Pfizer Biodistribution Data from Jan 2021`,
        `The injection does not stay in the injection site, and can be found from the ovaries and testes to the brain to bone marrow.`,
        `"I think we can be certain this information was on Anthony Fauci's desk...Bear in mind all this was known before the vaccines were approved...I'm struggling for words."`,
      ],
      urls: [
        `https://twitter.com/TheChiefNerd/status/1639609343824781312`,
        `https://www.youtube.com/watch?v=fVNFFtmb9gA`,
      ],
    },
    {
      description: [
        `<b>This is why the CDC Director should resign immediately.</b>`,
        `If you looked at that MHRA report for the public, you will see that they didn't do any fertility studies. Pages 16 to 21 are of particular interest for people to look at if they're interested.`,
        `<b>They did no fertility studies, and they didn't even do fertility studies in animals.</b> They were exempy. The studies and trials were exempt, which is most peculiar.`,
        `They didn't do any studies in pregnant women and yet it was pushed on pregnant women, and we know from the Pfizer studies themselves that they said to the women in the studies, "you must not get pregnant, you must use contraception, you mustn't get pregnant under any circumstances. And human nature being what it is, 25 women did get pregnant, and out of the 25 pregnancies there were 15 miscarriages, which means that's 58% miscarriage rate for women who were vaccinated and became pregnant. And out of the 9 babies that were born, 5 had congenital abnormalities.`,
        `So this is a complete disaster, absolute disaster, and we've seen miscarriage and stillborn baby rates go up.. Enormously actually.`,
        `In Scotland, the normal rate is 2 in 1,000, and it's gone up to 4.9 in 1,000 which is the highest level for over 10 years.`,
      ],
      urls: [
        `https://twitter.com/TexasLindsay_/status/1630430122435129344`
      ],
    },
    {
      description: [
        `<b>Texas State Sentate, May 2021:</b>`,
        `<b>Covid jab: they skipped all animal trials because all animals were dying, and went directly to humans.</b>`,
        `Q: Have you seen a vaccine that was put out for the public that skipped the animal tests?`,
        `A: Never before, especially for children.`,
        `Q: And what I've read, they actually started the animal tests, and because the animals were dying, they stopped the tests. Folks I think that's important to understand here, that what we're talking about is the American people are now the guinea pigs. <b>This is the test program going on.</b> They didn't do the human testing, and they stopped the animal tests because the animals were dying, and then they turned it out to the public. And we are now looking at businesses that want to mandate that this experimental vaccine be given to people as a condition of their employment.. and yet we have this death count that continues to rise and be totally ignored.`,
      ],
      urls: [
        `https://twitter.com/mjwrew/status/1628897076594573312`
      ],
    },
    {
      description: [
        `Dr. @P_McCulloughMD: The Vaccine Campaign Is a Complete Failure, Should Have Stopped in January 2021`,
        `Pfizer knew about 1223 deaths within 90 days after the vax rollout.`,
        `The FDA saw the data, didn't stop it, and "attempted to block that information to America for 55 years."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1629207580701601793`
      ],
    },
    {
      description: [
        `This is the summary analysis of the 50 000 pages Pfizer trial report, The report that Pfizer tried to hide, read the summary analysis you will see why.`,
        `These experimental "vaccines"should never have been given emergency approval by anyone.`,
      ],
      urls: [
        `https://twitter.com/ABridgen/status/1622482813382426624`
      ],
    },
    {
      description: [
        `Pediatric & Pregnancy Safety Studies for Pfizer mRNA Covid vaccine will not be completed until 2027 and 2025.`,
        `Mothers & children are #Pfizer lab rats.`,
      ],
      urls: [
        `https://twitter.com/DonaldBestCA/status/1608315990139625474`,
        `https://www.rebelnews.com/safety_data_for_novel_covid_19_injections_still_years_away_fda_docs_show`,
      ],
    },
    {
      description: [
        `"It's very clear - unequivocal. In normal circumstances, we wouldn't even be debating this. This vaccine would have been pulled a long time ago. It probably should never have been approved in the first place."`,
        `- Dr. Aseem Malhotra`,
      ],
      urls: [
        `https://twitter.com/JanJekielek/status/1601704378976505856`,
        `https://www.theepochtimes.com/dr-aseem-malhotra-from-vaccine-pusher-to-vaccine-debunker-how-i-changed-my-mind-about-the-covid-19-jab_4915606.html`,
      ],
    },
    {
      description: [
        `Barak Obama:`,
        `Despite the fact that we've now.. essentially.. <b>clinically tested the vaccine on.. billions of people worldwide.</b>`,
      ],
      urls: [
        `https://twitter.com/liz_churchill9/status/1601772612367314944`
      ],
    },
    {
      description: [
        `Quote from the Saskatchewan Health Authority:`,
        `"COVID-19 vaccines are still relatively new, and despite the high volumnes of administered doses, long-term side effects are still unknown at this time"`,
      ],
      urls: [
        `https://twitter.com/xoxobala/status/1528375635588128769/photo/1`
      ],
    },
  ],
  Vaccines: [
    {
      description: [
        `Pfizer CEO`,
        `"And we know that two doses of the vaccine offer very limited protection, if any.`,
        `The three doses with a booster, they offer reasonable protection against hospitalization and deaths, and less protection against the infection."`,
      ],
      urls: [
        `https://twitter.com/TracyK2017/status/1480831893562138629`,
      ],
    },
    {
      description: [
        `Dr. Richard Fleming - How The Vaxx is Breaking Down the Body`,
      ],
      urls: [
        `https://www.theburningplatform.com/tag/dr-richard-fleming/`,
        `https://threadreaderapp.com/thread/1504574674021367812.html`,
        `https://twitter.com/GhostOfNakadai/status/1504574674021367812`,
        `https://twitter.com/GhostOfNakadai/status/1504581674587377668`,
        `https://twitter.com/GhostOfNakadai/status/1504584294844579840`,
        `https://twitter.com/GhostOfNakadai/status/1504585203880284164`,
        `https://twitter.com/GhostOfNakadai/status/1504588070590595081`,
        `https://twitter.com/GhostOfNakadai/status/1504589408921604105`,
        `https://twitter.com/GhostOfNakadai/status/1504594797306290179`,
        `https://twitter.com/GhostOfNakadai/status/1504596364432445445`,
      ],
    },
    {
      description: [
        `Dr. Paul Alexander.`,
        `I think the key point he was making is had we been using a vaccine that could sterilize the virus, that could stop transmission, we would not be in this situation.`,
        `The problem here is this vaccine does not stop infection, does not sterilize the virus, it does not stop the transmission. You can never, ever get the population to herd immunity 100% with these vaccines. Impossible.`
      ],
      urls: [
        `https://twitter.com/derekjamesfrom/status/1485861022661447681`
      ],
    },
    {
      description: [
        `CDC Study: Natural Immunity Provides Significantly More Protection Against COVID Than Vaccination Only`,
      ],
      urls: [
        `https://ijr.com/cdc-study-natural-immunity-significantly-protection-covid/`,
        `https://www.cdc.gov/mmwr/volumes/71/wr/mm7104e1.htm`,
      ],
    },
    {
      description: [
        `Our alliance of over 500 independent Canadian doctors, scientists, and health care practitioners is committed to providing quality, balanced, evidence-based information to the Canadian public about COVID-19 so that hospitalizations can be reduced, lives saved, and our country safely restored to normal as quickly as possible.`,
        `<strong>The Pfizer inoculations for covid-19 do MORE HARM THAN GOOD</strong>.`,
      ],
      urls: [
        `http://trudeauknows.ca/wp-content/uploads/2021/12/The-COVID-19-Inoculations-More-Harm-Than-Good-REV-Dec-16-2021.pdf`
      ],
    },
    {
      description: [
        `“This is contributing to an increase in cases, and they're predominantly occurring in vaccinated individuals.” - Australia`,
      ],
      urls: [
        `https://twitter.com/MaximeBernier/status/1469720895350620165`
      ],
    },
    {
      description: [
        `Dr. McCullough - Must see covid powerpoint - "This is a test of wills"`,
        `<b>"No one who values their health should ever commit the body to a series of a multi-year injections of genetic therapy."</b>`
      ],
      urls: [
        `https://www.bitchute.com/video/eTTkaLbbITfH/`
      ],
    },
    {
      description: [
        `Ontario provincial website data shows more covid cases among fully vaccinated than unvaccinated.`,
      ],
      urls: [
        `https://twitter.com/KnowledgeAcqui1/status/1476224675042643968`
      ],
    },
    {
      description: [
        `<b>Harvard-Led Study Explodes Myths About 'Vaccines' Stopping the Spread - But It's Worse Than That</b>`,
        `At the country-level, there appears to be no discernable relationship between percentage of population fully vaccinated and new COVID-19 cases in the last 7 days.`,
        `In fact, the trend line suggests a marginally positive association such that countries with higher percentage of population fully vaccinated have higher COVID-19 cases per 1 million people.`,
        `There is a curious phenomenon taking place across the United States and around the globe: Covid-19 case rates increasing alongside vaccination rates.`,
      ],
      urls: [
        `https://beckernews.com/22-harvard-study-explodes-myths-about-vaccines-stopping-the-spread-but-its-even-worse-than-that-43431/`,
        `https://www.scribd.com/document/545827611/Increases-in-COVID-19-are-unrelated-to-levels-of-vaccination-across-68-countries-and-2947-counties-in-the-United-States`
      ],
    },
    {
      description: [
        `Dying COVID-19 Patient Recovers After Court Orders Hospital to Administer Ivermectin`,
      ],
      urls: [
        `https://www.theepochtimes.com/mkt_app/dying-covid-19-patient-recovers-after-court-orders-hospital-to-administer-ivermectin_4130754.html`
      ],
    },
    {
      description: [
        `Naturally Immune People 99.6% Protected from Re-infection for 9 months: Major Study`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=qczfMc2JS8E`
      ],
    },
    {
      description: [
        `Pfizer CEO: "And we know that the two doses of the vaccine offer very limited protection, if any. The three dose booster, they offer reasonable protection against hospitalization and deaths."`,
        `<b>Is the narrative collapsing? by Computing Forever</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/Tap6JpyO6gSD/`
      ],
    },
    {
      description: [
        `Ivermectin Prophylaxis Used for COVID-19: A Citywide, Prospective, Observational Study of 223,128 Subjects Using Propensity Score Matching`,
        `"In this citywide ivermectin prophylaxis program, a large, statistically significant decrease in mortality rate was observed after the program began among the entire population of city residents. When comparing subjects that used ivermectin regularly, non-users were two times more likely to die from COVID-19 while ivermectin users were 7% less likely to be infected with SARS-CoV-2 (p = 0.003).`,
        `Due to the well-established, long-term safety profile of ivermectin, with rare adverse effects, the absence of proven therapeutic options to prevent death caused by COVID-19, and lack of effectiveness of vaccines in real-life all-cause mortality analyses to date, we recommend that ivermectin be considered as a preventive strategy, in particular for those at a higher risk of complications from COVID-19 or at higher risk of contracting the illness, not as a substitute for COVID-19 vaccines, but as an additional tool, particularly during periods of high transmission rates."`,
      ],
      urls: [
        `https://www.cureus.com/articles/82162-ivermectin-prophylaxis-used-for-covid-19-a-citywide-prospective-observational-study-of-223128-subjects-using-propensity-score-matching`,
        `https://assets.cureus.com/uploads/original_article/pdf/82162/20220118-32145-1aq0rt1.pdf`,
      ],
    },
    {
      description: [
        `Reporter struggles to announce the news that a new Omicron sub-variant “BA.2” is floating around and apparently even more transmissible, and more likely to infect "vaccinated" people`,
      ],
      urls: [
        `https://gettr.com/post/prujz13a35`
      ],
    },
    {
      description: [
        `Dr. Robert Malone: "They are now normalizing testing for Auto Immune Deficiency Syndrome"`,
      ],
      urls: [
        `https://gettr.com/post/pvlg3be513`
      ],
    },
    {
      description: [
        `New JAMA paper show Ivermectin blows the COVID vaccines out of the water`,
        `<b>Efficacy of Ivermectin Treatment on Disease Progression Among Adults With Mild to Moderate COVID-19 and Comorbidities</b>`,
      ],
      urls: [
        `https://stevekirsch.substack.com/p/new-jama-paper-show-ivermectin-blows`,
        `https://jamanetwork.com/journals/jamainternalmedicine/fullarticle/2789362`,
      ],
    },
    {
      description: [
        `You are 70% less likely to die if you take Ivermectin vs Remdesivir.`,
        `<b>Ivermectin was associated with reduced mortality vs remdesivir - OR 0.308 - p <0.0001</b>`,
        `p <0.0001 means these results arriving by chance is 1 in 10,000.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=rfyOihhAD4A`,
        `https://www.cureus.com/articles/82162-ivermectin-prophylaxis-used-for-covid-19-a-citywide-prospective-observational-study-of-223128-subjects-using-propensity-score-matching`,
        `https://www.sciencedirect.com/journal/international-journal-of-infectious-diseases/vol/116/suppl/S`,
        `https://www.sciencedirect.com/science/article/pii/S1201971221009887`,
      ],
    },
    {
      description: [
        `Peter McCullough, MD MPH`,
        `In my practice, the use of nasal/oral rinses with dilute povidone iodine or dilute hydrogen peroxide has been the biggest advance for both prevention and as a adjunct to early treatment.  Supported by 12 clinical studies of which 3 are high quality RCT's.`,
      ],
      urls: [
        `https://twitter.com/P_McCulloughMD/status/1501166050490171397`
      ],
    },
    {
      description: [
        `Russell Brand: The Narrative Has Changed`,
        `"In some cases, to get a "digital id" or a "green pass", you needed to have the two shot, or the three shots, and, <b>remember</b>, how the mainstream media and the government vehemently condemned unvaccinated people for being irresponsible, and spreading the disease.`,
        `Well this information suggests that they may have been <b>better protected</b> and <b>less likely to spread it</b> than some of the people that were criticizing them.`,
        `So doesn't that show you how irresponsible the corporate media and the government seem, not just in the message they were conveying, but the <b>manner of the message</b>, the social damage that was done, particularly now, <b>as the science suggests that they may have been wrong.</b>"`,
        `Notably, a 10-fold lower risk of subsequent infection was found in the people with natural immunity compared with those vaccinated in the Cleveland Clinic health system's study of over 52,000 employees.`,
        `These reports convey a high level of protection of natural immunity, at times comparable to 2-shot vaccines.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=LBw1csl4tk4`
      ],
    },
    {
      description: [
        `Ivermectin in Africa and Brazil. Ivermectin consistently shows it's useful for covid.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=Gz4adJXLHgA`
      ],
    },
    {
      description: [
        `The CDC Director was always in way over her head. Here she frankly admits that what they touted as definitive vax efficacy was really just colored by optimistic wishful thinking.`,
        `"We had too little caution and too much optimism..."`,
        `Speak for yourself. Those of us who had more caution and less optimism were silenced.`,
        `Strikingly frank answer from CDC director Walensky on them being too bullish on vaccines early on:`,
        `“Nobody said 'waning' when this vaccine is going to work, 'oh well maybe it'll wear off.' Nobody said 'well, what if…it's not as potent against the next variant'”`,
        `She continues: "I have frequently said 'we're going to lead with the science'…I think public heard that as 'science is foolproof. Science is black and white.'…The truth is science is grey. And science is not always immediate."`,
      ],
      urls: [
        `https://twitter.com/zoeharcombe/status/1500756711950409729`,
        `https://twitter.com/AlexThomp/status/1499808632443224068`,
        `https://twitter.com/AlexThomp/status/1499810656056397831`,
      ],
    },
    {
      description: [
        `Dr. John Campbell: "COVID-19: Why Are We Ignoring Infection-Acquired Immunity?`,
        `...because we are. Why is this happening? I think I know part of the answer to this. Part of the answer, I'm not saying it's all the answer, but part of the answer, is governments still want to encourage as much vaccination as possible, and I think that if governments feel they introduce nuance into this, then the public are so stupid that they won't understand.`,
        `Well I don't think you're stupid, and I want the full facts so I can make decisions.`,
        `<b>We need to move away from this one-size-fits-all strategy, and that's what I'm going to be giving evidence for.`,
        `We need to individualize the care that we're giving people."</b>`
      ],
      urls: [
        `https://www.youtube.com/watch?v=kkjBi7dOTAg`
      ],
    },
    {
      description: [
        `<b>9 in 10 COVID Deaths Are in Vaccinated People: Report</b>`,
        `A report released by the UK government has confirmed that 9 out of every 10 deaths related to COVID-19 are found in those who are fully vaccinated. Although the virus variant is the same and the UK approved only one different vaccine (AstraZeneca) from the United States, the data in the U.S. are different. This may be due in large part to the CDC definition used to identify who is “vaccinated.”`,
      ],
      urls: [
        `https://www.theepochtimes.com/9-in-10-covid-deaths-are-in-vaccinated-people-report_4339503.html`,
        `https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1016465/Vaccine_surveillance_report_-_week_36.pdf`,
      ],
    },
    {
      description: [
        `Science with Dr. Doug Corrigan`,
        `<b>Spike Protein from the Vaccine - Where Does it Go? What Does it Do to my Body?</b>`,
        `In this audio podcast, I go over the science of the mRNA vaccines. I discuss the science of how they work, and the dynamics of the Spike protein. Where does it go in the body? How long does it last? How much Spike protein is produced? What are the health impacts? How is this different from a COVID infection? All of these questions and more are answered in this podcast.`
      ],
      urls: [
        `https://anchor.fm/sciencewithdrdoug/episodes/PODCAST1---Spike-Protein-from-the-Vaccine---Where-Does-it-Go--What-Does-it-Do-to-my-Body-e1fgh0c`
      ],
    },
    {
      description: [
        ` Why do jabbed people still become infected and transmit the virus? As this recent Science paper shows, jabbed people are lacking IgA/IgG mucosal immunity. They also show that mucosal immunity is substantially gained only after a jabbed person has a natural infection.`,
        `The only way a jabbed person can gain long lasting and sterilizing immunity that prevents further infection and transmission is to become naturally infected.`,
        `<b>Also: Article from Science.</b>`,
        `Characterization of immune responses in fully vaccinated individuals following breakthrough infection with the SARS-CoV-2 delta variant`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1502793603684388864`,
        `https://threadreaderapp.com/thread/1502793603684388864.html`,
        `https://www.science.org/doi/full/10.1126/scitranslmed.abn6150`,
      ],
    },
    {
      description: [
        `Canadian government had pre-bought 10+ shots per person in Canada.`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=dD5nDKuTee8`
      ],
    },
    {
      description: [
        `New Hampshire House approves bill to allow pharmacists to dispense ivermectin for COVID-19`,
      ],
      urls: [
        `https://www.wmur.com/article/new-hampshire-house-bill-pharmacists-dispense-ivermectin-covid-19/39456443`
      ],
    },
    {
      description: [
        `Russell Brand: The Truth Is Coming Out`,
        `Re: Pfizer's 55,000 page release on their trials which the FDA wanted to keep hidden for 75 years.`
      ],
      urls: [
        `https://www.youtube.com/watch?v=sqD_JYAs7pU`
      ],
    },
    {
      description: [
        `Potentially Reduced Brain Immunity Caused by Pfizer Vaccine (Polish In-vitro Preprint)`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=QswE7SY8_Y4`
      ],
    },
    {
      description: [
        `Life insurance for wealthy businessman who died after taking the covid injection, refuses to pay out because they define the injection as a "medical experiment"`,
        `Side effects are well known and made public, there is no way the gentleman could NOT have known the side effects, and willingly chose to get the vaccine, died as a result, and because it was a "choice", they're calling it <b>a suicide.</b>`,
      ],
      urls: [
        `https://www.bitchute.com/video/IVT8oEZ8DuRO/`
      ],
    },
    {
      description: [
        `Remember this study from Denmark? It shows negative vaccine efficacy after the antibodies wane, which means an increased rate of infection if you're jabbed. Data from other countries shows the same trend. Why? Is this evidence that the jab is impairing the immune system?`,
        `<b>Vaccine effectiveness against SARS-CoV-2 infection with the Omicron or Delta variants following a two-dose or booster BNT162b2 or mRNA-1273 vaccination series: A Danish cohort study</b>`,
        `<b>Phase I/II study of COVID-19 RNA vaccine BNT162b1 in adults</b>`,
      ],
      urls: [
        `https://threadreaderapp.com/thread/1507770289643560965.html`,
        `https://twitter.com/ScienceWDrDoug/status/1507770289643560965`,
        `https://www.medrxiv.org/content/10.1101/2021.12.20.21267966v3.full`,
        `https://www.nature.com/articles/s41586-020-2639-4.pdf`,
      ],
    },
    {
      description: [
        `<b>The Unintended Consequences of COVID-19 Vaccine Policy: Why Mandates, Passports, and Segregated Lockdowns May Cause more Harm than Good</b>`,
        `University of Toronto Faculty of Law`,
      ],
      urls: [
        `https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4022798`,
        `https://www.law.utoronto.ca/events/unintended-consequences-covid-19-vaccine-mandates-why-they-may-cause-more-harm-good`
      ],
    },
    {
      description: [
        `U.S. Senate expert witness testimony Dr. Peter McCullough states that 95% of American deaths could have been avoided. Autopsy confirms two teenage boys die on days 3 and 4 after the shot, the parents are horrified. This push is resulting in deaths of children.`,
      ],
      urls: [
        `https://twitter.com/LakovosJustice/status/1507570790530752515`
      ],
    },
    {
      description: [
        `<b>Poison control issues warning about Covid-19 rapid antigen tests.</b>`,
      ],
      urls: [
        `https://twitter.com/TheNo1Waffler/status/1509222617819058185`
      ],
    },
    {
      description: [
        `Kroger and Wal Mart pharmacists confidently say covid vaccines are safe, may give you a headache for a day, but fall silent and walk away when given Pfizer's own list of 1790 adverse reactions which they were forced to release under court order, after they tried to keep it hidden for 75 years.`,
      ],
      urls: [
        `https://www.bitchute.com/video/dyr0IxZ6Pi7E/`,
        `https://www.bitchute.com/video/JsTRI8AJaEwu/`,
        `https://www.bitchute.com/video/WKAdcM5Xz0mi/`,
      ],
    },
    {
      description: [
        `NEW PFIZER DOCS. DUMP - This is what we've seen so far:`,
        `Pfizer's trials show that natural immunity is 100% effective`,
        `Decrease in white blood cells were observed after dose 1, (immune system attack)`,
        `Adverse events were MORE SEVERE in the younger age group`,
        `<b>They knew this and still jabbed the masses with no informed consent, saying it's safe and effective.</b>`,
      ],
      urls: [
        `https://twitter.com/Christy1Gaga/status/1510104480993497089`
      ],
    },
    {
      description: [
        `In 2022, Pfizer and Moderna need to make sure governments purchase and take delivery of ~3 billion vaccine doses, totalling revenue of ~USD$50 billion.`,
        `Otherwise they will have to break their promises to Wall Street.`,
      ],
      urls: [
        `https://twitter.com/rubiconcapital_/status/1510738608537448451`
      ],
    },
    {
      description: [
        `Babies refusing breast milk of vaccinated mothers.`,
        `Dr Carrie Madej.`,
        `“Mother's breastmilk of a double vacc¡nated mother was tested after the baby was failing to thrive. The test showed the breast milk to be 80% Inorganic and the toxicity levels 8 out of 10.”`,
      ],
      urls: [
        `https://twitter.com/TheNo1Waffler/status/1510941747815325701`
      ],
    },
    {
      description: [
        `The Miami Open. Every tennis player, fully vaccinated.`,
        `<b>Fifteen players</b> could not finish the tournament, including two favourites to win the tournament.`,
      ],
      urls: [
        `https://twitter.com/ChickenGate/status/1511407209514782722`,
        `https://twitter.com/ChickenGate/status/1511407214279413767`
      ],
    },
    {
      description: [
        `Crazed Trudeau is really excited about getting the vaccine.`,
        `"It it it it HITS YOU"`,
      ],
      urls: [
        `https://twitter.com/stillgray/status/1510467380413353989`
      ],
    },
    {
      description: [
        `Dr. Sherri Tenpenny`,
        `We know the mechanism why so many people getting the shot are getting sick.`,
        `The spike protein may continue to be produced in your body indefinitely.`,
      ],
      urls: [
        `https://twitter.com/freedomfight369/status/1509867121056092161`
      ],
    },
    {
      description: [
        `Vaccinated Have Up To SIX Times the Infection Rate of Unvaccinated, New Zealand Government Data Show`,
      ],
      urls: [
        `https://dailysceptic.org/2022/04/09/vaccinated-have-up-to-six-times-the-infection-rate-of-unvaccinated-new-zealand-government-data-show/`
      ],
    },
    {
      description: [
        `What is going on in Alberta and British Columbia?`,
        `<b>From Stats Can data, excess deaths in ages 0-44 as of Dec. '21 are MORE THAN 70% of expected deaths, since accelerating in Jul '21.</b>`,
        `This is the real "tsunami of death", and a public health emergency that must be investigated ASAP.`,
      ],
      urls: [
        `https://twitter.com/rubiconcapital_/status/1513654807168524291`,
        `https://threadreaderapp.com/thread/1513654807168524291.html`,
        `https://www150.statcan.gc.ca/n1/daily-quotidien/220210/dq220210b-eng.htm`,
        `https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1310078401`,
        `https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1310076801`,
      ],
    },
    {
      description: [
        `We are at a dangerous moment and these mRNA products need to be withdrawn.`,
      ],
      urls: [
        `https://twitter.com/john88354238/status/1514761575131152387`
      ],
    },
    {
      description: [
        `Dr. Peter McCullough, MD MPH`,
        `PFE knew large number of deaths were occurring shortly after taking it and they took no steps to mitigate risk.  For large program, ~50 unexplained deaths would be the trigger for voluntary product recall to figure out what went wrong.  They did nothing, the FDA did nothing!`,
      ],
      urls: [
        `https://twitter.com/P_McCulloughMD/status/1515429523034103812`
      ],
    },
    {
      description: [
        `Recording of AstraZeneca CEO Pascal Soriot "Millions of [Immunocompromised] People Can't Be Vaxxed.."`,
      ],
      urls: [
        `https://www.bitchute.com/video/Lk0OJwZwE5g/`
      ],
    },
    {
      description: [
        `SARS-CoV-2 Vaccination and Myocarditis in a Nordic Cohort Study of 23 Million Residents`,
        `Question: Is SARS-CoV-2 messenger RNA (mRNA) vaccination associated with risk of myocarditis?`,
        `Findings: In a cohort study of 23.1 million residents across 4 Nordic countries, risk of myocarditis after the first and second doses of SARS-CoV-2 mRNA vaccines was highest in young males aged 16 to 24 years after the second dose. For young males receiving 2 doses of the same vaccine, data were compatible with between 4 and 7 excess events in 28 days per 100,000 vaccinees after second-dose BNT162b2, and between 9 and 28 per 100,000 vaccinees after second-dose mRNA-1273.`,
      ],
      urls: [
        `https://jamanetwork.com/journals/jamacardiology/fullarticle/2791253`
      ],
    },
    {
      description: [
        `How many other Canadians are having the same thoughts as her?`,
        `"I did what I had to do, I got the two shots, I got the booster, now there's a fourth booster... I'm not getting it. I'm done. The pharmaceutical companies are making trillions of dollars."`,
      ],
      urls: [
        `https://twitter.com/realmonsanto/status/1518575795089854464`,
      ],
    },
    {
      description: [
        `Naomi Wolf's Explosive Reveal On The Pfizer Investigation`,
        `"When everyone was saying 'safe and effective' ..this screenshot [from VAERS] shows 3,385 deaths reported in 3 months..`
      ],
      urls: [
        `https://rumble.com/v13bg59-naomi-wolfs-explosive-reveal-on-the-pfizer-investigation.html`
      ],
    },
    {
      description: [
        `Finally we have evidence, how Pfizer came to calculate a 95% vaccine efficacy.`,
        `Real efficacy is likely only 19%, with a very bad side effect profile of up to 0.8% severe side effects, as reported by German Hospital Study Charité`,
      ],
      urls: [
        `https://twitter.com/USMortality/status/1521914418451337217`,
        `https://threadreaderapp.com/thread/1521914418451337217.html`,
      ],
    },
    {
      description: [
        `Fully Vaccinated Gorilla Dies of Multiple Organ Failure`,
      ],
      urls: [
        `https://igorchudov.substack.com/p/fully-vaccinated-gorilla-dies-of`
      ],
    },
    {
      description: [
        `Dr. Robert Malone speaking about Pfizer: In my opinion, withholding scientific data constitues fraud. This is scientific fraud.`,
        `Pfizer and the CDC committed fraud for willfully withholding critical data from the public resulting in harm and death to thousands. The CDC is spreading medical misinformation. What they have done is obscene. We are completely vindicated.`,
        `Pfizer and the CDC were hiding data that showed harm and death by injection. What's going on here is criminal. For the people who knew the data, but said nothing there will be legal consequences. Either you will be witnesses or defendants.`,
        `The FDA and the CDC had to be sued to release the data. The reluctance to release the data on a biological product that every person is being asked to take should make everyone concerned. DOD data shows clear increase in disease after injection.`,
      ],
      urls: [
        `https://twitter.com/LakovosJustice_/status/1522001676508581889`,
        `https://twitter.com/LakovosJustice_/status/1522001757378859010`,
        `https://twitter.com/LakovosJustice_/status/1522001827352432640`,
        `https://threadreaderapp.com/thread/1522001676508581889.html`,
      ],
    },
    {
      description: [
        `Mysterious spike in N.B. deaths in 2021 not due to COVID, health minister says`,
        `Excess deaths in New Brunswick, not due to Covid. The period was July to November, 2021. Who wants to take a guess what New Brunswick was doing in spring to summer, 2021?`,
      ],
      urls: [
        `https://www.cbc.ca/news/canada/new-brunswick/covid-deaths-health-minister-shephard-1.6451680`,
        `https://twitter.com/thevivafrei/status/1525248485431312388`,
        `https://twitter.com/thevivafrei/status/1525249132268597256`,
      ],
    },
    {
      description: [
        `Bill Gates: "we will have to prepare for the next one [pandemic], that I'd say will get attention this time" *smiles*`,
      ],
      urls: [
        `https://twitter.com/backtolife_2022/status/1528491390392492032`
      ],
    },
    {
      description: [
        `Fauci's thoughts on the smallpox vaccine in 2017`,
        `"The idea of 'everybody should take it' didn't make any sense."`
      ],
      urls: [
        `https://twitter.com/Storiesofinjury/status/1528280596962848768`
      ],
    },
    {
      description: [
        `No evidence of myocarditis after covid in the unvaccinated.`,
        `..and a reminder of when the incidence of myocarditis rose.`,
      ],
      urls: [
        `https://twitter.com/ClareCraigPath/status/1532061985612787712`,
        `https://twitter.com/ClareCraigPath/status/1532062586199748608/photo/1`,
        `https://www.mdpi.com/2077-0383/11/8/2219`,
        `https://jamanetwork.com/journals/jama/fullarticle/2782900`,
      ],
    },
    {
      description: [
        `<b>Rich People in Spain, Including Pharmaceutical Company President, Caught Buying Fake COVID Vaccine Certificates</b>`,
        `Many questions remain over the false Covid-19 vaccination certificates bought by stars, sports personalities and business leaders`,
      ],
      urls: [
        `https://summit.news/2022/06/09/rich-people-in-spain-including-pharmaceutical-company-president-caught-buying-fake-covid-vaccine-certificates/`,
        `https://euroweeklynews.com/2022/05/27/2200-prominent-spanish-personalties-investigated-for-false-covid-19-vaccination/`
      ],
    },
    {
      description: [
        `Pathologist Dr. Ryan Cole:`,
        `"We are literally doing the largest experiment on humanity ever done, not knowing the long term outcomes.`,
        `We only have a year and a half's data. And we have in the VAERS system, 30,000 deaths, well over 1,000,000 adverse reactions. We know that that system highly under-reports..`,
        `We have the EudraVigilance data, we have millions of adverse reactions, we have countless deaths from around the world, and in addition to that we have <b>all-cause death higher now in those who have received these shots, than those who didn't,</b> so we know there are very bad signals from this experimental therapy.`,
        `<b>These shot are all risk, no benefit.</b>`,
      ],
      urls: [
        `https://twitter.com/BLNewsMedia/status/1536044051966922755`,
        `https://rumble.com/v17c2ud-interview-a-lipid-nanoparticle-a-gene-is-a-nuclear-bomb-dr-ryan-cole-md.html`,
        `https://brightlightnews.com/a-lipid-nanoparticle-2/`,
      ],
    },
    {
      description: [
        `<b>Canada declares "fully vaccinated" now means 3 injections, stating "two doses don't work anymore."</b>`,
        `Trudeau's health minister Jean-Yves Duclos says two-doses of the Covid vaccine “doesn't work anymore.”`,
        `The government is “transitioning” to a new definition of what it means to be “fully-vaccinated.”#cdnpoli`,
        `Where's peer-reviewed evidence to back any of these claims from a man with ZERO medical/scientific training/experience?`,
        `Where's acknowledgement that natural Tcell immunity is robust, broad, durable?`,
        `Where's clinical evidence for “booster” vac based SOLELY on lab antibody titre?`,
      ],
      urls: [
        `https://twitter.com/disclosetv/status/1537018213170651137`,
        `https://twitter.com/TrueNorthCentre/status/1536814753825402884`,
        `https://twitter.com/dockaurG/status/1536818055082295296`,
      ],
    },
    {
      description: [
        `The data published by Ontario is very clear. The boosted are getting infected at the highest rates. Now unvaccinated, and vaccinated are showing same case rates (these are population adjusted).`,
        `<b>Data says do not get boosters.</b>`,
      ],
      urls: [
        `https://twitter.com/ssbawa/status/1537099614096175105/photo/1`
      ],
    },
    {
      description: [
        `FLASHBACK: Fauci says "when people are vaccinated, they can feel safe that they are not going to get infected."`,
        `Fully-vaxxed, double-boosted Fauci still got Covid.`,
      ],
      urls: [
        `https://twitter.com/TPostMillennial/status/1537430895702880256`
      ],
    },
    {
      description: [
        `Why would every COVID vaccine maker choose the spike protein? It seems they chose it on purpose, knowing it would hurt you.`,
      ],
      urls: [
        `https://twitter.com/SpacemanSteven/status/1537546073870409728`
      ],
    },
    {
      description: [
        `17 month old baby in the Vax trial had a seizure after the first dose, a rash and a 104+ temp...and ANOTHER seizure.`,
        `They still gave her Dose #2`,
        `"She went on to stay in the study and receive a second dose without subsequent seizure."`,
      ],
      urls: [
        `https://twitter.com/Bronson76023518/status/1537599300401758210`,
        `https://rumble.com/v18s8bh-horrific-story-of-a-17-month-old-baby-in-vax-trial.html`,
      ],
    },
    {
      description: [
        `British Columbia Dr. Charles Hoffe, MD`,
        `<b>"I have been horrified to see what the covid shots have done to my own patients."</b>`,
        `In April 2021 Dr Hoffe alerted B.C. public health & Bonnie Henry to serious vx injuries in his own practice of 2000 people. 12 were disabled.`,
        `He also discovered the use of the D dimer test to detect blood clotting caused covid vaccines.`,
        `Bonnie Henry silenced him.`,
        `<b>Bonnie Henry knew there was a problem with the vx. They ALL knew.</b>`,
        `Dr Hoffe is a hero.`,
        `Bonnie Henry is a criminal.`,
      ],
      urls: [
        `https://rumble.com/vuysnl-people-are-waking-to-govt-deceit-and-control-dr.-charles-hoffe-md.html`,
        `https://threadreaderapp.com/thread/1538030278228299777.html`,
        `https://twitter.com/howisthismylif/status/1538030278228299777`,
        `https://twitter.com/howisthismylif/status/1538030286562131968`,
        `https://twitter.com/howisthismylif/status/1538030286562131968/photo/1`,
        `https://twitter.com/howisthismylif/status/1538030286562131968/photo/2`,
      ],
    },
    {
      description: [
        `TV panel about Covid Vaccine injury payments.`,
        `Myocarditis, loss of limbs, death, etc.`,
      ],
      urls: [
        `https://twitter.com/LukerTherese/status/1538438918588313603`
      ],
    },
    {
      description: [
        `Peer reviewed research conclusively shows a significant and prolonged decline in sperm concentration and motility following the jab.`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1538590221847207937`,
        `https://onlinelibrary.wiley.com/doi/pdf/10.1111/andr.13209`,
        `https://threadreaderapp.com/thread/1538590221847207937.html`,
        `https://threadreaderapp.com/thread/1452342997295771649.html`,
        `https://threadreaderapp.com/thread/1538590221847207937.html`,
      ],
    },
    {
      description: [
        `Dr. Clare Craig explains why the FDA should NOT have granted approval for roll-out in the 6 month to 4 yr old children cohort`,
        `<b>"This trial should have been deemed null and void. The regulators are failing to do their job."</b>`,
        `"I want to take you through the evidence that Pfizer just presented to the FDA on 6 month to 4 year old children.`,
        `There's an awful lot about this trial that has shocked me and I think will shock you too.`,
        `The trial recruited 4,526 children, age from 6 months to 4 years old. 3,000 of these children did not make it to the end of the trial.`,
        `That is a huge number, two thirds of them.`,
        `Why was there this drop off? That needs to be answered, and without an answer to that, on that basis alone, this trial should be deemed null and void.`,
        `So what did the trial show?`,
        `Well, they defined severe covid as children who had a slightly raised heart rate, or fewer breaths per minute.`,
        `There were 6 children aged 2 to 4 who had severe covid in the vaccine group, but only one in the placebo group. So on that basis, the likelihood that this vaccine is actually causing severed covid is higher than the likelihood that it isn't.`,
        `There was actually one child who was hospitalized in this trial. They had a fever and a seizure. THey had been vaccinated.`,
        `So now let's turn to what the defined as "any covid", and what they did was to utterly twist the data.`,
        `They vaccinated the children and they waited three weeks after the first dose before the second dose. In that three week period, 34 of the vaccinated children got covid, and only 13 in the placebo group, <b>which worked out as a 30% increased chance of catching covid in that three week period if you were vaccinated.</b> So they ignored that data.`,
        `And then there was an eight week gap between the second dose and the third dose, where again, children were getting plenty of covid in the vaccine arm. So they ignored that data.`,
        `There was then seceral weeks after the third dose which they also ignored, which meant that <b>in the end they had ignored 97% if the covid that occurred during the trial</b> and they just looked at tiny numbers - so tiny. <b>In the end they were comparing 3 children in the vaccine arm who had covid with 7 in the placebo arm and they said that this showed the vaccine was effective.</b>`,
        ``,
        `So they measured how many of these children actually managed to catch covid twice in the two-month follow up period. And there were 12 children who had covid twice, and all but once of them were vaccinated. Mostly with three doses.`,
        `So you have to wonder what on earth they're thinking when the claim of reduction in covid was only 4 children and here we have 12 children who got covid twice, 11 of them vaccinated.`,
        `So let's just recap.`,
        `They recruited 4,500 children, 3,000 of them dropped out. And in the end they're claiming this vaccine works on the basis of three covid cases versus 7, a difference of 4 children only.`,
        `And all of this on a backdrop of a disease which doesn't affect children, and with no long-term safety data.`,
        `We have to ask how an ethics committee could have approved this trial in babies. Babies are not at risk from covid.`,
        `And now we have Pfizer, who are presenting this as evidence to the FDA in order to apply for an Emergency Use Authorization.`,
        `Emergency Use Authorization is meant for a situation where there's a risk of serious injury or death.`,
        `Now, children under 5 are not at risk of serious injury or death from covid, in fact in their own trial, they had to make up other ways of measuring the problem, because there was no serious injury or death.`,
        `Now, originally these products were sold as actually also reducing transmission.`,
        `Now it would be completely unethical to use young children as a human shield, but we know know that they don't reduce transmission. The WHO have stopped claiming that they reduce transmission. So that argument doesn't apply either.`,
        `Now, if we just turn to safety, what they did is they followed up patients for 6 weeks before unblinding them and vaccinating them. So the children who'd had placebo, the control group, were followed up for an average fo 6 weeks and then given the vaccine.`,
        `So that's your safety control gone forever.`,
        `The fact that this trial existed at all is unbelievable.`,
        `There are other issues in there which I haven't highlighted, but those are the key ones.`,
        `<b>Parents should be demanding that the decision makers explain themselves.</b>`,
      ],
      urls: [
        `https://twitter.com/hartgroup_org/status/1537458392414969856`,
        `https://twitter.com/hartgroup_org/status/1537458427349303299`,
      ],
    },
    {
      description: [
        `"Sue Your Doctor" - They've Violated Their Oath and Failed to Give Informed Consent`,
        `Dr. Ryan Cole: "If you are injured by a shot or you know somebody who's injured by a shot, you need to file a suit against a physician, because nobody in the world got informed consent."`,
      ],
      urls: [
        `https://twitter.com/VaccinatedFox/status/1538535524352262145`
      ],
    },
    {
      description: [
        `TN House Health Subcommittee doctors:`,
        `"700,000 people in the study in Israel, just so you know, that showed that <b>the double vaxxed were 27 times more likely to get reinfected.</b>`,
        `The vaccine, even if we talk about that, is not stopping infection, it's not stopping transmission, if you look at the studies in England, in Scotland, in the northern countries in Europe where they get real data, that they're actually, <b>the triple vaccinated are the most likely to die.</b>`,
        `The bottom line is that as we go forward, that Natural Immunity is long, broad and durable.`,
        `and I don't know if you've mentioned it, but <b>we have SARS COV-1 patients who still have immunity 18 years later.</b> Let that sinnk in. 18 years later they still had immunity from SARS COV-1 to SARS COV-2. This is long, broad, durable immunity.`,
        `So, what I want to say in closing is, <b>Natural Immunity should be considered legally at least equal to vaccinated immunity, and the immunity is likely life long.</b>"`
      ],
      urls: [
        `https://twitter.com/CanAditude/status/1541897502173044736`
      ],
    },
    {
      description: [
        `<b>Dr. Simone Gold</b>`,
        `Public health policy has been substantially corrupted by pharmaceutical interests.`,
        `They manufactured studies to enable greater profits, at the expense of citizens throughout the world.`,
        `We cannot allow this to happen again.`,
      ],
      urls: [
        `https://twitter.com/drsimonegold/status/1559308909738860546`
      ],
    },
    {
      description: [
        `<b>Youth Cannot Be Mandated to Get COVID-19 Vaccine, Ontario Court Rules</b>`,
      ],
      urls: [
        `https://www.theepochtimes.com/youth-cannot-be-mandated-to-get-covid-19-vaccine-ontario-court-rules_4670680.html`
      ],
    },
    {
      description: [
        `Fauci: "the most potent vaccination is getting infected yourself"`,
      ],
      urls: [
        `https://twitter.com/ksorbs/status/1560238181273915392`
      ],
    },
    {
      description: [
        `Pfizer boss Albert Bourla backs down completely and now maintains that the "mRNA vaccine" technology was not sufficiently proven when they launched the Covid one. He says they "convinced him" but he wasn't sure.`,
        `He is recognizing it is not safe.`,
      ],
      urls: [
        `https://twitter.com/constantin_t/status/1566804381634383873`
      ],
    },
    {
      description: [
        `Fauci on covid boosters: "We don't have time to do a clinical trial"`,
      ],
      urls: [
        `https://twitter.com/urhomie4l/status/1567785327594721281`
      ],
    },
    {
      description: [
        `Denmark ENDS Covid vaccinations for almost everyone under 50`,
      ],
      urls: [
        `https://alexberenson.substack.com/p/denmark-ends-covid-vaccinations-for-981`
      ],
    },
    {
      description: [
        `Ivermectin emails inside Public Health Agency of Canada`,
        `For treating covid, the Canadian government says ivermectin is unsafe, ineffective, and not to use it… but the science says otherwise, and so do some PHAC staff.`,
        `Did Dr. Theresa Tam - Canada's so-called Chief Public Health Officer - know of this finding by her own staffer that ivermectin could save lives? Yes, it appears the above overview was put together at the chief's request.`
      ],
      urls: [
        `https://scoopsmcgoo.substack.com/p/ivermectin-emails-from-the-public`,
        `https://scoopsmcgoo.substack.com/p/phac-emails-re-ivermectin-2`,
      ],
    },
    {
      description: [
        `New preprint from institutions like Oxford, Harvard, & Johns Hopkins. The jab causes net harm in 18-29 year olds.:`,
        `“per COVID-19 hospitalisation prevented in previously uninfected young adults, we anticipate 18 to 98 serious adverse events..” `,
      ],
      urls: [
        `https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4206070`,
        `https://twitter.com/ScienceWDrDoug/status/1570480296662810626/`,
      ],
    },
    {
      description: [
        `Dr. Harvey Risch is Professor Emeritus of Epidemiology at the Yale School of Public Health.`,
        `His work has been cited nearly 50k times. He's also an MD. I asked him what the biggest lie of the last 29 months of COVID has been. Hold onto your butts.`,
      ],
      urls: [
        `https://twitter.com/SteveDeaceShow/status/1570462125398831105`,
        `https://rumble.com/v1k7fzt-this-is-the-biggest-lie-of-covidstan-steve-deace-show.html`
      ],
    },
    {
      description: [
        `A new report prepared for the Liberal Party of Canada shows that the vaccines have no benefit for those under 60`,
        `Conclusion: "The Ontario data show that vaccination currently makes little difference in terms of hospitalization and death rates for those below age 60."`,
      ],
      urls: [
        `https://stevekirsch.substack.com/p/a-new-report-prepared-for-liberal`,
        `https://www.skirsch.com/covid/LiberalPartyReport.pdf`,
      ],
    },
    {
      description: [
        `Senator Ron Johnson exposes the last false claim of the mandated program. No RCT demonstrated reductions in hospitalization or death.  No valid non randomized studies support that claim. Johnson blows it wide open with UK data.`,
      ],
      urls: [
        `https://twitter.com/P_McCulloughMD/status/1571271461695901701`
      ],
    },
    {
      description: [
        `Dr. Robert Malone:`,
        `It is not adequate to only consider the short term adverse events as related to the vaccine. We must acknowledge that the genetic covid19 genetic injections cause far more harm than good, and provide zero benefit relative to risk for the young and healthy.`,
        `They do not reduce covid19 infection, which is treatable and not terminal.`,
        `Furthermore, the most recent data demonstrates that you are more likely to become infected or have disease or even death if you've been vaccinated, compared to the unvaccinated people. This is shocking to hear, but it is what the data are showing us.`,
        `The data now show that these experimental gene therapy treatments can damage your children, as well as yourself.`,
        `They can damage your heart, your brain, your reproductive tissue, and your lungs. This can include permanent damage and disablement of your immune system.`,
      ],
      urls: [
        `https://twitter.com/BigBluexlt/status/1573494644012449793`
      ],
    },
    {
      description: [
        `<b>“Breast milk…turned blue-green” -Dr. Naomi Wolf</b>`,
        `"Breast milk of vaccinated women, in the Pfizer documents, four (4) women's breast milk turned blue-green... which the doctor says, could signify a problem with blood.`,
        `And one poor baby who was nursing from a vaccinated mom went into convulsions and died at the ER, in the Pfizer documents.`,
      ],
      urls: [
        `https://twitter.com/liz_churchill1_/status/1574551182315593728`
      ],
    },
    {
      description: [
        `COVID-19 Antigen test kit now updated to include Influenza A and B as well.`,
        `If the antigen test tests positive when encountering Influenza, how was Covid19 ever different?`
      ],
      urls: [
        `https://twitter.com/BernieSpofforth/status/1596566018700333062`
      ],
    },
    {
      description: [
        `Prominent Virologist Dr. A. Oveta Fuller, who advocated for emergency use authorisation of three Covid vaccines, has died suddenly from a brief unknown illness.`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1595329885915979777`
      ],
    },
    {
      description: [
        `<b>The Norwegian Health Minister has said that she will NOT be getting the booster.</b>`,
        `Norwegian Health authorities are NOT giving a recommendation that younger adults should take the booster. Leading doctors have said they won't take the booster themselves.`,
      ],
      urls: [
        `https://twitter.com/PeterSweden7/status/1595749714078613504`
      ],
    },
    {
      description: [
        `Health Canada, Top doctors, Politicians knew prior to June 30th 2021 that these vaccines could and would damage people's hearts yet they still coerced and forced people to take it.`,
      ],
      urls: [
        `https://twitter.com/WeHaveNoRights2/status/1593084274202521600`
      ],
    },
    {
      description: [
        `Canada Nurse whistle blower says she's seen:`,
        `- Others forcing COVID vaccine on elderly while they scream "no"`,
        `- Suicide patients after they died were tested for COVID`,
        `- Many dying of heart attacks shortly after getting vaccine`,
        `- Full-term miscarriages 5-days after vaccine`,
      ],
      urls: [
        `https://threadreaderapp.com/convos/1588769481069072384`
      ],
    },
    {
      description: [
        `British Medical Journal Investigation re: covid vac clinical trials in the British Medical Journal, one of the oldest & prestigious peer-reviewed medical/scientific journals in the world:`,
        `<b>"FDA oversight of clinical trials is 'grossly inadequate' say experts"</b>`,
        `"FDA is 'endangering public health' by not being candid re violations uncovered during clinical trial site inspections.. Lack of transparency and data sharing doesn't allow physicians/scientists to confirm data indep & make comprehensive risk-benefit assessments"`,
        `"The FDA has a long history of failing adequately to oversee clinical trial sites.. Some 65% of FDA's funding for the evaluation of drugs comes from industry user fees and in return the agency has mandated deadlines for decisions on new product applications"`,
      ],
      urls: [
        `https://www.bmj.com/content/379/bmj.o2628`,
        `https://twitter.com/dockaurG/status/1593133224473276417`,
        `https://twitter.com/dockaurG/status/1593133355029377025`,
        `https://twitter.com/dockaurG/status/1593133584726241280`,
      ],
    },
    {
      description: [
        `Dr. Harvey Risch is Professor Emeritus of Epidemiology at the Yale School of Public Health. His work has been cited nearly 50k times. He's also an MD.`,
        `I asked him what the biggest lie of the last 29 months of COVID has been.`,
        'Criticism of FDA recommendations of outpatient treatment.',
        `"..it proves that they [FDA] do not have systematic evidence of outpatient ill-effects in using hydroxycholoquine in outpatients"`,
        `"Why is this the biggest lie, is because this was the crux of the whole pandemic in the first place."`,
      ],
      urls: [
        `https://twitter.com/SteveDeaceShow/status/1570462125398831105`,
        `https://rumble.com/v1k7fzt-this-is-the-biggest-lie-of-covidstan-steve-deace-show.html`,
      ],
    },
    {
      description: [
        `<b>4070% increase in VAERS miscarriage reports from 2019 to 2021.</b>`,
      ],
      urls: [
        `https://twitter.com/PeterSweden7/status/1590685952930091009/photo/1`
      ],
    },
    {
      description: [
        `EU regulator recommends adding heavy periods to side effects of mRNA COVID shots.`,
      ],
      urls: [
        `https://www.reuters.com/business/healthcare-pharmaceuticals/ema-panel-lists-heavy-menstrual-bleeding-side-effect-moderna-pfizer-shots-2022-10-28/`,
        `https://twitter.com/thevivafrei/status/1593068710126063616/photo/1`
      ],
    },
    {
      description: [
        `New pre-print: "Pediatric COVID-19 cases were generally mild. ... Pediatric reinfections rarely led to hospitalization (0.5% vs. 1.3% during primary infections) and none resulted in death."`,
        `--Medic et al. 2022`,
        `Medic et al. Incidence, risk and severity of SARS-CoV-2 reinfections in children and adolescents: a population-level study between March 2020 and July 2022. medRxiv 2022.10.09.22280690; doi:`,
      ],
      urls: [
        `https://twitter.com/DrJBhattacharya/status/1591970162210922496`,
        `https://twitter.com/METRICStanford/status/1591499142043381760`,
        `https://www.medrxiv.org/content/10.1101/2022.10.09.22280690v1`
      ],
    },
    {
      description: [
        `"Clotting of the arteries just does not happen, this has not been seen in the last 150 years of embalmers' reports.`,
        `These are being seen in the people who died suddenly post covid injection"`,
        `- Dr Chris Shoemaker`,
        `"But there is a very strong pathology group out of Germany which is analyzing all of this, and I've seen their slides, and the slides show this incredible, what they call, lymphosoytidic reaction - lymphosytosis reaction - to the vaccine, this is only to the vaccine that they're looking at.`,
        `The entire lymphatic system, lymphosytosis system of your body, all the lipidtites, they're driven crazy by the presence of what's on this vaccine.`,
        `And they're driven so crazy that they start tearing into the spleen, or tearing into the wall of the aorta, or tearing into the kidney, doing <b>ridiculous thing to multiple organs..</b>`,
        `And the dead people, and believe me, these are <b>dead people</b> that the pathologists are looking at, some of the dead people have horrific lymphocytic changes in their brain. Some, it's the heart. Some, it's their aorta. Some, it's the spleen.`,
        `<b>Some, it's ovaries. Horrific changes in the ovaries and the reproductive systems of women.</b>`,
        `And it's horrible to look at under the microscope.`
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1590054491210256384`,
        `https://twitter.com/_Janey_J/status/1590054667580772354`,
        `https://rumble.com/v1s8le2-world-council-for-health-general-assembly-64.html`,
      ],
    },
    {
      description: [
        `<b>Children are 100X more likely to die after just 1 shot within 8 months (according to UK data)</b>`,
        `This is not a mistake; this is a mass crime against humanity, mass murder and assault`,
        `<b>~ Dr. Mark Trozzi</b>`,
        `"Please do not let your child be poisoned, damaged, have their life shortened, or be killed.`,
        `These fraudulently promoted, dangerous genetic injections alter human cell activity, such that our own cells manufacture, on mass, the most poisonous, and patented, element of the SARS COV 2 virus: the spike protein.`,
        `In addition to poisoning many ACE2 receptor-rich tissues and organs throughout our bodies, this spike protein also causes mass immune system failure.`,
        `It worsens coronavirus infections, poisons many organs such as our brain, heart, and reproductive organs.`,
        `And as bas as all that is, it also causes a massive, pseudo-autoimmune disease.. <b>imagine a child's own immune system attacks their own cells, even their immune system cells, because they have been genetically hacked and are producing the foreign, poison spike protein, which marks them for attack by our own body's immune system.</b>`
      ],
      urls: [
        `https://rumble.com/v1rr2uc-line-in-the-sand-children-war-and-duty.html`,
        `https://twitter.com/_Janey_J/status/1588964562774421505`,
        `https://twitter.com/_Janey_J/status/1588964607640866816`,
      ],
    },
    {
      description: [
        `Dr John Campbell goes through the government data`,
        `<b>24,440 NON-COVID UK excess deaths since May 2022</b>`,
        `We are now seeing more excess deaths a week than in 2020 or 2021.`,
      ],
      urls: [
        `https://twitter.com/djlange/status/1588294328962600961`
      ],
    },
    {
      description: [
        `<b>Dr. Kulvinder Kaur MD: "Analysis suggests much lower pre-vac infection fatality rate in non-elderly popns than prev suggested"</b>`,
        `---`,
        `<b>[Age] | [Infection Survival Rate]`,
        `0 -19 | 99.9997%`,
        `20-29 | 99.997%`,
        `30-39 | 99.989%`,
        `40-49 | 99.965%`,
        `50-59 | 99.871%`,
        `60-69 | 99.499%`,
        `---`,
        `0-59 | 99.97%`,
        `0-69 | 99.93%`,
      ],
      urls: [
        `https://twitter.com/dockaurG/status/1582167280930107393`,
        `https://twitter.com/dockaurG/status/1582167283014959106`,
        `https://www.medrxiv.org/content/10.1101/2022.10.11.22280963v1.full`,
        `https://www.medrxiv.org/content/10.1101/2022.10.11.22280963v1.full.pdf`,
      ],
    },
    {
      description: [
        `"Doctors baffled by increase in 'Sudden Adult Death Syndrome'"`,
      ],
      urls: [
        `https://twitter.com/EvaVlaar/status/1585585405797310464`
      ],
    },
    {
      description: [
        `NY State Supreme Court reinstates all fired unvaccinated employees, orders backpay, says the state violated rights, acted arbitrary and capricious, notes:`,
        `<b>"Being vaccinated does not prevent an individual from contracting or transmitting Covid-19."</b>`,
      ],
      urls: [
        `https://twitter.com/MarinaMedvin/status/1584930051166208000`,
        `https://eddsa.blob.core.usgovcloudapi.net/public/85163_2022_George_Garvey_et_al_v_City_of_New_York_et_al_DECISION___ORDER_ON_37.pdf`,
      ],
    },
    {
      description: [
        `"It's my duty as a cardiologist to urgently inform every Dr, patient & member of the public that the mRNA product is a likely contributory factor in all unexpected cardiac arrests,heart attacks,strokes,cardiac arrhythmia & heart failure since 2021 until proven otherwise"`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1582595328867729409`
      ],
    },
    {
      description: [
        `- Pfizer CEO: “The efficacy of our vaccine in children is 80%”`,
        `- Reporter: “Are you talking about efficacy to prevent severe disease or to prevent infection?”`,
        `- Pfizer CEO: <b>"To prevent infection"`,
      ],
      urls: [
        `https://twitter.com/DrEliDavid/status/1582256734264926208`
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan:`,
        `Global study by Ioannidis (Stanford) shows the median COVID Infection Fatality Rates (IFR) for the unvaccinated and previously uninfected were:`,
        `0.0003% at 0-19 yrs`,
        `0.003% at 20-29 yrs`,
        `0.011% at 30-39 yrs`,
        `0.035% at 40-49 yrs`,
        `0.129% at 50-59 yrs`,
        `0.501% at 60-69 yrs`,
        `Ioannidis @ Stanford is one of the most published and well respected epidemiologists in the world.`,
        `<b>0.0003% is 1 in every 333,333.</b>`
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1582348873879519232`,
        `https://twitter.com/ScienceWDrDoug/status/1582348876018638848`,
        `https://twitter.com/ScienceWDrDoug/status/1582349956123279361`,
        `https://www.medrxiv.org/content/10.1101/2022.10.11.22280963v1`,
      ],
    },
    {
      description: [
        `Dr. Roger Hodkinson`,
        `20 Million Dead from the Jab, 2.2 Billion Injuries - Analyst Estimates`,
        `"What I'm about to tell you is intuitively ridiculous. That's why it's so incredibly important that I say this.`,
        `These numbers are best estimates at this time, using government data, for the global consequences of the clot-shot, in terms of death and morbidity, otherwise known as serious adverse events, such as heart attacks, strokes, .. etc.`,
        `We've been focusing, for good reason, on North America's statistics during the last two and a half years, but this has extrapolated that into the total negative effect of the clot-shots, and these are the numbers:`,
        `Global deaths directly attributed to the vaccine: 20 million.`,
        `2 Billion serious adverse reactions of the type I've described.`
      ],
      urls: [
        `https://vigilantfox.substack.com/p/20-million-dead-from-the-jab-22-billion`
      ],
    },
    {
      description: [
        `Dr. Drew changes his tune on Covid Vaccines.`,
      ],
      urls: [
        `https://twitter.com/TheMilkBarTV/status/1579807052884770816`
      ],
    },
    {
      description: [
        `CDC finally caves and hands over their V-Safe data.`,
        `7.7% ( 782,900 people ) out of the 10 million included in the data, required medical care after taking the "safe and effective" COVID jabs that so many have been coerced into taking.`,
      ],
      urls: [
        `https://twitter.com/DreaHumphrey/status/1577830265824907264`
      ],
    },
    {
      description: [
        `Dr. Aseem Malhotra:`,
        `"I was under the impression that the vaccine would prevent transmission. We now know that is completely false and we don't even know if it was true in the beginning"`,
        `<b>Policymakers must pause the roll out. History will NOT be in their side & the public will not forgive them for it.</b>"`,
        `---`,
        `"I was under the impression that the vaccine would prevent transmission. We obviously now know that's completely false, not even true, we don't even know if it was true from the beginning.`,
        `We had data on hospitalizations and death actually. For 100,000 vaccinated vs unvaccinated. So we're able to separate it by age group.`,
        `So, you have to vaccinate 230 people over the age of 80 to prevent one covid death.`,
        `520 if you are 70 - 79`,
        `1300 if you are 60 - 69`,
        `2600 if you are 50 - 59`,
        `This was a number of very eminent scientists associated at the BMJ, and what they found in the original trials where everything is corrected, it's supposed to be the gold standard..`,
        `..the risk of serious adverse events that were reported in the vaccine group was significantly higher than the risk of people being hospitalized with covid.`,
        `This was the original, this was Pfizer and Moderna trials combined, and think about this for a second..`,
        `<b>It was these trials alone, before we had any of the data, that led to the approval and rollout of the vaccine.. not just rollout.. Coersion.Mandates. People being threatened with their jobs.</b>"`,
        `---`,
        `"Why, when we've got a serious adverse event rate, which is probably in the range of at least 1-in-800 to 1-in-1000, which is good quality data, why is it not being pulled, when other vaccines - swineflu vaccine 1976 was pulled because it cause Guillain-Barré Syndrome in 1-in-100,000 - Rotavirus vaccine was pulled in 1989 because it caused a form of bowel obstruction 1-in-10,000 -`,
        `We're talking serious adverse events of 1-in-800."`,
        `---`,
        `"Now it's high time that policy makers around the world put an end to the mandates.`,
        `History will not be on their side, and the public not forgive them for it.`,
        `---`,
        `<b>What we're dealing with now, with hindsight, is perhaps the greatest miscarriage of medical science we will witness in our lifetime.</b>`,
      ],
      urls: [
        `https://twitter.com/DrAseemMalhotra/status/1577895492264116225`
      ],
    },
    {
      description: [
        `Trudeau did everything he could to make sure Canadians couldn't get a hold of Ivermectin or Hydroxychloroquine, but he made sure every hospital had plenty of Remdesivir. Here is a list of evidence.`,
        `- Remdesivir is causing many diagnosed Covid Patients to Die.`,
        `- Summary of the Evidence for Ivermectin in COVID-19`,
        `- 187 ivermectin COVID-19 studies, 138 peer reviewed, 93 comparing treatment and control groups.`,
        `- How Billions in COVID Stimulus Funds Led Hospitals to Prioritize 'Treatments' That Killed, Rather Than Cured`,
        `- There are now significant clinical trial data (see summaries at http://c19early.com) to support use of medications such as ivermectin and/or fluvoxamine`,
        `- Detroit's Ford Hospital Defends Hydroxychloroquine Study`,
        `- Attempt to discredit landmark British ivermectin study. Medical disinformation from the BBC.`,
        `- Dr. Fauci elevated remdesivir as the best drug for COVID while at the same time poisoning all debate about hydroxychloroquine (HCQ).`,
        `- Is Hospital Protocol Harming Covid Patients?`,
        `- The apparent safety and low cost suggest that ivermectin is likely to have a significant impact on the SARS-CoV-2 pandemic globally.`,
        `- Does Ivermectin really work against Covid-19?`,
        `- Outrageous! FDA Backtracks During Trial and Now Claims 'Not Taking Ivermectin for COVID-19' was Merely a Recommendation`,
        `- Remdesivir shouldn't be used on hospitalized Covid-19 patients, WHO advises. Remdesivir has no meaningful effect on mortality or reducing the need for mechanical ventilation, an expert panel said.`,
        `- <b>Covid 19 deaths, or Remdesivir deaths?</b> - At six months of this one-year study, the safety board for the study found Remdesivir had the highest death rate of all four experimental drugs and pulled the drug “It didn't even make it to the end of the study because it was so deadly.`,
      ],
      urls: [
        `https://twitter.com/JohnLeePettim13/status/1594723313108008961`,
        `https://threadreaderapp.com/thread/1594723313108008961.html`,
        `https://oh17.com/2021/12/14/remdesivir-is-causing-many-diagnosed-covid-patients-to-die/`,
        `https://covid19criticalcare.com/wp-content/uploads/2021/08/SUMMARY-OF-THE-EVIDENCE-BASE-FINAL.pdf`,
        `https://c19ivm.org/`,
        `https://alethonews.com/2022/01/25/how-billions-in-covid-stimulus-funds-led-hospitals-to-prioritize-treatments-that-killed-rather-than-cured/`,
        `https://c19early.org/`,
        `https://canadiancovidcarealliance.org/wp-content/uploads/2021/07/DEAR_DOCTOR_INFORMED_CONSENT_for_IVM_and_FLVUSEforCOVID_v520210618.pdf`,
        `https://www.deadlinedetroit.com/articles/25291/detroit_s_ford_hospital_defends_study_on_hydroxychloroquine_in_wake_of_trump_s_controversial_disclosure`,
        `https://www.hartgroup.org/bbc-ivermectin/`,
        `https://deeprootsathome.com/dr-bryan-ardis-gives-life-saving-protocol-here/`,
        `https://rumble.com/vnge19-is-hospital-protocol-harming-covid-patients.html`,
        `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8248252/`,
        `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8248252/pdf/ajt-28-e434.pdf`,
        `https://www.indiatoday.in/india-today-insight/story/does-ivermectin-really-work-against-covid-19-1806807-2021-05-25`,
        `https://survivalmagazine.org/news/outrageous-fda-backtracks-during-trial-and-now-claims-not-taking-ivermectin-for-covid-19-was-merely-a-recommendation/`,
        `https://www.nbcnews.com/health/health-news/remdesivir-shouldn-t-be-used-hospitalized-covid-19-patients-who-n1248320`,
        `https://myvalleynews.com/blog/2022/02/03/covid-19-deaths-or-remdesivir-deaths/`,
      ],
    },
    {
      description: [
        `Dr. Naomi Wolf:`,
        `"The statement that it was safe and effective, it wasn't based on any studies involving pregnant women.`,
        `<b>Pregnant women were excluded from the trials.</b>`,
        `The statement that it was safe and effective for pregnant women was based on a study of fourty-four (44) French rats, followed for forty-two (42) days, the baby rats were sacrificed in utero, they weren't allowed to get born so the scientists could assess if they were developing normally, eating, thriving..`,
        `And the scientists concluded that the fetal rats were fine. Those scientists and doctors were employees and shareholders of Pfizer.`,
        `In the Pfizer documents there's a section that shows that 270 women got pregnant nonetheless, even though they were excluded from the trials. Pfizer lost the records of 232 of them.. of the 38 women who's records Pfizer followed to comclusion, which they're supposed to do for all of them by law, 28 of those mothers lost their babies.`,
        `<b>28 of 32 lost their babies. (87.5%)</b>`,
        `And we know the mechanism for this. Dr. Robert Chandler broke down the biodistribution, which confirms what Dr. Victree referenced, a Japanese Pfizer biodistribution study of over a year ago, that shows indeed that <b>the materials don't stay in the injection site,</b> that all the spokesmodels swore that they did, <b>but in fact they travelled throughout the body within 15 minutes and concentrate especially in the liver, the adrenals, the spleen, and the ovaries.</b>`,
        `But what Dr. Chandler found, is that in the 48 hours that this experiment lasted, (and why did they cut it off at 48 hours, why didn't they let it go on for a week, or two, three weeks..) in the ovaries, the lipid nanoparticles and the spike protien, and the mRNA just accumulate and accumulate and accumulate, like 1800 times in the ovaries.`,
        `And what I want to say is, lipid nanoparticles are designed to cross every membrane in the human body. They were designed to cross the blood-brain barrier, and bring medicine to tumors for people with brain tumors, but healthy women who are pregnant were assured that this material could not affect their baby, but in fact a 2018 study out of China (and China shows up a lot in this alliance with Pfizer's manufacturing and distribution), <b>they knew that lipid nanoparticles accumulate in the ovaries, testes, and affect reproduction.</b>`,
        `So what you're getting is, some nanoparticles and the spike protein with the first injection, more in the second injection, more in the booster, more in the second booster, and <b>we don't see any mechanism whereby this material LEAVES the ovaries.</b>`
      ],
      urls: [
        `https://twitter.com/NewsNancy9/status/1577071559818514432`
      ],
    },
    {
      description: [
        `J.P. Sears satire about "Unknown Cause of Death" being the leading cause of death in Alberta, Canada.`,
      ],
      urls: [
        `https://twitter.com/TexasLindsay_/status/1635504155631538178`
      ],
    },
    {
      description: [
        `“There are virtually no deaths from this disease in people who have vitamin D levels in their blood above 50 ng/mL [nanograms per milliliter],”`,
        `Dr. Robert Malone MD told me in May of '22.`,
        `“There's actually many studies out now, including double-blind randomized placebo-controlled trials.”`,
      ],
      urls: [
        `https://twitter.com/JanJekielek/status/1635670402583101441`,
        `https://twitter.com/RWMaloneMD/status/1635675747301081088`,
      ],
    },
    {
      description: [
        `<b>Dr. Teresa Tam - Canada:</b>`,
        `"REGARDLESS OF RISK FACTORS, we encourage anyone who has not yet received their primary covid19 series, or their fourth, 2022 booster doses, to get these vaccinations."`,
      ],
      urls: [
        `https://twitter.com/AntonioTweets2/status/1635501193123225601`
      ],
    },
    {
      description: [
        `<b>Deception and Lies - Senator Babet's Bombshell Revelation on Covid Vaccination in Australia</b>`,
        `Senator Babet says that the Australian people were duped into believing that mRNA injections would protect them from Covid. But now, the data is showing that the vaccinated population is actually more vulnerable to the virus than the unvaccinated.`,
        `"The Australian people were initially told that mRNA injections would stop them getting infected with Covid, would stop them spreading it, and most importantly would stop them dying from it. We were told that we were selfish if we were not willing to be vaccinated and that we could kill grandma or kill grandpa. Around 97.5% of Australians over 16 followed the government advice and have had at least one dose of mRNA injection, yet the only data that accurately identifies the vaccination status of those who got Covid [...] showed quite clearly that the vaccinated are more likely to be infected, hospitalized, and die of Covid."`,
      ],
      urls: [
        `https://twitter.com/goddeketal/status/1638885872769679360`
      ],
    },
    {
      description: [
        `<b>Recipients of Bill Gates' Favorite Vaccine Were Found to Die at 10x the Rate as Unvaccinated Children</b>`,
        `A Danish study looked at thirty years of records. And they found that girls who had received the DTP vaccine were ten times more likely to die than unvaccinated children.`,
        `"And they were dying not of things that anybody had ever associated with a vaccine," stated Robert Kennedy Jr."`,
        `"They were dying of anemia and bilharzia (snail fever) and malaria and dysentery (bloody diarrhea) and pulmonary respiratory issues like pneumonia. And nobody had ever connected the dots ... until the Danish scientists came in and actually looked at the data."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1634740549671432192`
      ],
    },
    {
      description: [
        `<b>Dr. Martin Makary: Select Subcommittee Roundtable: Examining COVID Policy Decisions</b>`,
        `The CDC and the FDA and people at the NIH, made up their mind before the trials were completed.`,
        `They decided babies were gonna get vaccines before the study was done, and when it came out it found no statistically significant different in efficacy between the 2 groups, and they just authorized it anyway.`,
        `Why are we even doing trials, why do we even have an FDA, why not just have king Fauci decree that everyone's going to need this?`,
        `And public health excluded anyone who had a different opinion. Ask any one of us that had different opinions than the establishment, if we were invited, ever, to the Public Health Association or university forums.`,
        `We had published collectively, 400 articles on COVID, including peer reviewed articles in JAMA, and yet we were excluded. So of course it looks like there's a consensus. But do you know that 40% of rural pediatricians in America do NOT recommend the COVID vaccine? How would you know that? That's a number that comes from a CDC discussion forum.`,
        `Public health officials and the CDC are proposing that a 12 year old girl get 65 COVID vaccines in her average lifetime. Based on what?`,
      ],
      urls: [
        `https://twitter.com/DavidWolfe/status/1631918064244756480`,
        `https://rumble.com/v2bc3bi-select-subcommittee-roundtable-examining-covid-policy-decisions-full-unedit.html`,
      ],
    },
    {
      description: [
        `Moderna Shot Causes SIX TIMES More Myocarditis Cases in Men Under 40 Than COVID Itself, According to American Heart Association`,
        `• Second Dose of Moderna - <b>97 additional myocarditis cases per million.</b>`,
        `• COVID-19 Itself - <b>16 additional myocarditis cases per million.</b>`,
        `Jimmy Dore: "So that guy [Stéphane Bancel] is pretending to not know what I know. Now I'm a pothead comedian in my garage, and somehow I know that, but the head of Moderna doesn't know that."`,
      ],
      urls: [
        `https://twitter.com/VigilantFox/status/1639701146141958148`
      ],
    },
    {
      description: [
        `<b>Low Serum 25-hydroxyvitamin D (Vitamin D) Level Is Associated With Susceptibility to COVID-19, Severity, and Mortality: A Systematic Review and Meta-Analysis</b>`,
        `Vitamin D regulates the immune system and is a potential lifesaver. Research published by FrontiersIn supports this.`,
        `So why aren't politicians and media advocating for everyone in the world to have access to this safe and non-experimental hormone?`,
      ],
      urls: [
        `https://twitter.com/goddeketal/status/1620859663783690253`,
        `https://www.frontiersin.org/articles/10.3389/fnut.2021.660420/full`,
      ],
    },
    {
      description: [
        `<b>Dr. John Campbell: Vitamin D, now conclusive</b>`,
      ],
      urls: [
        `https://www.youtube.com/watch?v=x5sc7G4s4CY`
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan: How long is Spike expressed after injection?`,
        `Since Spike is toxic, you would think that there would be ample amounts of pharmacokinetic data to answer this question, but the answer is they did virtually nothing in humans.`,
        `I link my previous threads on what is known:`,
        `---`,
        `-- <b> Thread 1 on Duration of Spike Expression In Human Body</b> --`,
        `This shows Spike protein and S1 in the plasma of vaccinated people for up to 28 days after injection. Levels peak at ~150 pg/mL, which is in the concentration range of Spike measured in COVID patients. Now, who said Spike remains at the injection site or in the cell membrane?`,
        `The Pfizer Vaxx shows a similar profile, with circulating plasma Spike concentration returning to baseline in less than month after first and second dose. They claim 14 ug/mL peak, but describe how this high level doesn't correlate with Ogata et al.`,
        `This paper shows Spike plasma concentration in COVID patients. For all practical purposes, vaccinated people are expressing levels of Spike protein that circulate throughout their blood at levels in line with a COVID infection.`,
        `And we know that S1 protein crosses blood brain barrier via adsorptive mediated transcytosis in mice and is taken up into the parenchymal brain space.`,
        `And we know Spike protein is toxic to human cardiac pericytes via CD147`,
        `And we know Spike protein is toxic to vascular endothelial tissue via ACE2.`,
        `And we know ACE2 is highly expressed in human testes and epididymis, in particular in Leydig cells, Sertoli cells, and spermatogonia. Does the vaccine affect male fertility? We don't know. It hasn't been tested.`,
        `Another paper now showing circulating spike protein for 4 Months, present in circulating exosomes`,
        `Spike expression 3 Months post jab`,
        `---`,
        `-- <b> Thread 2 on Duration of Spike Expression In Human Body</b> --`,
        `This research proves that the mRNA vaccines induce circulating Spike protein (not localized to injection site) for up to 4 months. Spike is released from cells via the induction of exosomes, which are extracellular ~100nm sized vesicles used in cell-to-cell communication.`,
        `“Results demonstrated induction of circulating exosomes expressing spike protein on day 14 after vaccination followed by Abs 14 d after the second dose.”`,
        `“Exosomes with spike protein, Abs to SARS-CoV-2 spike, and T cells secreting IFN-γ and TNF-α increased following the booster dose.”`,
        `“Transmission electron microscopy of exosomes also demonstrated spike protein Ags on their surface.”`,
        `“Exosomes with spike protein and Abs decreased in parallel after four months.” (I would like to know the mechanism of how Spike protein is still present after 4 months).`,
        `“These results demonstrate an important role of circulating exosomes with spike protein for effective immunization following mRNA-based vaccination.”`,
        `Exosomes can also carry genetic information, such as RNA. Exosomes are known to be taken up by distant cells. Therefore, it's possible that the mRNA from the vaccine could be shuttled to other tissues via the exosome transport pathway.`,
        `---`,
        `Spike protein measured in skin lesions 3 months!!! post mRNA jab.`,
        `“Therefore, the Spike Protein could be the culprit for immunosuppression that allowed VZV reactivation and its persistence, as well as skin vasculopathy and thrombosis.”`,
        `“the stabilization of RNA by substituting methyl-pseudouridine for all the uridine nucleotides for BNT162b2 might result in long-time production of the encoded Spike Protein from any cells, persistently affecting the microenvironment for the protective immune system..”`,
        `In other words, they stabilized the mRNA by using an abnormal substitute for one of the chemical letters in the RNA alphabet. This enables the mRNA to persist in cells for a long period of time, and for Spike Protein to be expressed from the mRNA for an extended period.`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1606336805162328067`,
        `https://twitter.com/ScienceWDrDoug/status/1440883038741749766`,
        `https://twitter.com/ScienceWDrDoug/status/1450631274142609413`,
        `https://twitter.com/ScienceWDrDoug/status/1607479027429670915`,
        `https://academic.oup.com/cid/article/74/4/715/6279075`,
        `https://mdpi-res.com/d_attachment/sensors/sensors-21-05857/article_deploy/sensors-21-05857.pdf`,
        `https://www.medrxiv.org/content/10.1101/2020.07.20.20156372v1.full.pdf`,
        `https://www.nature.com/articles/s41593-020-00771-8`,
        `https://www.biorxiv.org/content/10.1101/2020.12.21.423721v2`,
        `https://www.ahajournals.org/doi/10.1161/CIRCRESAHA.121.318902`,
        `https://www.frontiersin.org/articles/10.3389/fphys.2020.574761/full`,
        `https://journals.aai.org/jimmunol/article/207/10/2405/234284/Cutting-Edge-Circulating-Exosomes-with-COVID-Spike`,
        `https://www.science.org/doi/10.1126/science.aau6977`,
      ],
    },
    {
      description: [
        `Nurse's heads up on Remdesivir with hospitals trying to confuse people by using a different name for it: Veklury`,
        `Patients are declining Remdesivir but being given Veklury.`,
        `Why would they do this?`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1605493527529410560`
      ],
    },
    {
      description: [
        `The WHO estimates that 15 million excess deaths occurred in 2020 and 2021, which is 3 times the number of reported deaths due to COVID, and we all know that COVID deaths were over-reported.`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1603537125042954240`,
        `https://www.newscientist.com/article/2351763-nearly-15-million-excess-deaths-occurred-globally-in-2020-and-2021/`,
      ],
    },
    {
      description: [
        `<b>Reddit: How did I get it worse than my unvaccinated friend?</b>`,
        `I'm triple boosted (Pfizer), 22, and an everagely healthy individual, but still got pretty heavily sick and felt most of the symptoms including a severe symptom of blue nail beds. Meanwhile my unvaccinated friend was tired and had a light cough.`,
        `I am a full believer in vaccines and was even going to get a 4th shot soon. I still can't believe it was this intense though and think I might've had to go into the hospital if I wasn't vaccinated. I'm just very confused about hoe it was like this, is it really just by chance this happened?`,
        `I'm just deeply saddened that all my efforts over the past 2.5 years, sanitizing quite literally an hour a day, keeping a mask on, social distancing, refusing to hang out, not being in crowds, isolating, and I still managed to catch it and become so sick especially during finals.`,
        `Thank you for hearing me our and for any replies as to how I managed to get sicker.`,
      ],
      urls: [
        `https://twitter.com/UncleNestor22/status/1601565738728587265/photo/1`
      ],
    },
    {
      description: [
        `Brownstone Institute:`,
        `<b>New Autopsy Report Reveals Those Who Died Suddenly Were Likely Killed by the Covid Vaccine</b>`,
        `-- Autopsy-based histopathological characterization of myocarditis after anti-SARS-CoV-2-vaccination --`,
      ],
      urls: [
        `https://brownstone.org/articles/new-autopsy-report-reveals-those-who-died-suddenly-were-likely-killed-by-the-covid-vaccine/`,
        `https://link.springer.com/article/10.1007/s00392-022-02129-5`,
      ],
    },
    {
      description: [
        `New paper makes strong case that COVID booster mandates in young adults (as in many US universities), caused net harm, and must be judged unethical.`,
        `This should not have happened.`,
        `---`,
        `Analysis suggests Covid-19 boosters likely to cause a net clinical harm to young adults(18-29 yr), where total severe adverse events (SAEs) will outweigh Covid hospitalizations averted.`,
        `Booster mandates are unethical because:`,
        `In April, Paul Offit stated in a NEJM editorial:"It is now incumbent on CDC to determine who most benefits from booster dosing & educate the public about the limits of mucosal vaccines"`,
        `CDC/FDA has NOT conducted risk-benefit analysis for young people`,
        `So, we took up the challenge.`,
        `Over 300 universities & colleges were (as of Sept 2022) mandating boosters across North America (1000 with 2-dose Covid-19 mandates still in place). Is this a science-based policy? Is it ethically justified?`,
        `Using CDC/Pfizer data,we estimate 31,207-42,836 previously UNINFECTED adults 18-29 yrs must be boosted to prevent 1 C19 hospitalization over 6 months.But we also estimate 18.5 serious adverse events`,
        `We also anticipate 1,430-4,626 cases of grade ≥3 reactogenicity per C19 hospitalization averted, which prevents people from conducting daily activities. `,
        `We anticipate 1.5 to 4.6 booster-associated myopericarditis cases in males (typically requiring hospitalisation) per Covid hospitalization averted.`,
        `We then outline 5 ethical arguments against booster mandates at institutions of higher education in North America.`,
        `1) The CDC has been negligent and it is incumbent on the agency to conduct an updated Omicron risk-benefit analysis for this age group. `,
        `2) Our analysis (see above) shows the alarming possibility that mandates may result in a net expected harm to young people. This violates the 'do no harm principle.' Our estimates assume NO ONE has been infected w Covid. So: real-world benefits likely to be less favourable.`,
        `3) Vaccine mandates are not proportionate public policy. The benefits are unlikely to outweigh the harms by any significant degree to justify liberty restrictions. Current vaccines do not durably stop transmission. `,
        `4) Booster mandates violate the reciprocity principle. US vaccine injury schemes are completely inadequate and have failed to provide support for rare but severe Covid-19 vaccine injuries.`,
        `5) Booster mandates have wider social harms. Students and faculty risk being dis-enrolled or fired. Mandates have caused backlash, resistance & anger. This has further damaged the trustworthiness & credibility of scientific institutions/universities`,
        `We consider counter-arguments against our position, such as a desire to 'socialize' compliance & increase safety on campus. We find these arguments sorely lacking in a scientific or ethical basis. European countries have more appropriate policies for boosters in young pls`,
        `Lastly, we make the point that although our risk-benefit analysis is focused on boosters in young adults, our analysis is relevant to other university, workplace & school Covid-19 vaccine mandates, esp given high prior infection rate among the US population. `,
      ],
      urls: [
        `https://twitter.com/jflier/status/1599896697479778304`,
        `https://twitter.com/KevinBardosh/status/1599866819988578304`,
        `https://jme.bmj.com/content/early/2022/12/05/jme-2022-108449`,
        `https://www.nejm.org/doi/full/10.1056/NEJMe2203329`,
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan:`,
        `They show that sera from some individuals who are jabbed with mRNA exhibits:`,
        `1) No neutralization of Omicron.`,
        `2) Antibody Dependent Enhancement (ADE) of infection. `,
        `ADE is a process where the presence of the antibodies actually amplify the infection rather than neutralize it.`,
        `Absence of neutralization and presence of ADE lead the authors to conclude “These results suggest that the rapid spread of Omicron around the world may in part result from the lack of cross-neutralization against Omicron and some ADE activity of sera after vaccination.”`,
        `Figure 3. In this assay, any # above a 1 on the vertical axis represents an enhancement of infection (ADE). You can see that ADE persists at early and later times post jab, and over a wide range of antibody concentrations.`,
        `To read about how ADE works go here. This is an article I wrote back in August of 2020 warning of the risk for ADE`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1599977709068697600`,
        `https://www.nature.com/articles/s41598-022-19993-w`,
        `https://sciencewithdrdoug.com/2020/08/01/is-a-coronavirus-vaccine-a-ticking-time-bomb/`,
      ],
    },
    {
      description: [
        `Government Data Proves There Is A Vaccine Induced Pandemic`,
      ],
      urls: [
        `https://rumble.com/v24l3ci-government-data-proves-there-is-a-vaccine-induced-pandemic.html`
      ],
    },
    {
      description: [
        `Dr Peter McCullough`,
        `It looks like the messenger RNA IS transferring from the vaccinated to the unvaccinated`,
      ],
      urls: [
        `https://twitter.com/_Janey_J/status/1598398431667650575`
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan:`,
        `This study in mice shows that repeated boosting of the RBD Spike antigen causes a reversal in immune memory response and immune tolerance. In other words, the immune system is being conditioned to accept the virus as self, presumably leading to a permanent state of infection.`,
        `<b>Extended SARS-CoV-2 RBD booster vaccination induces humoral and cellular immune tolerance in mice</b>`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1615916706840580098`,
        `https://www.sciencedirect.com/science/article/pii/S2589004222017515`,
      ],
    },
    {
      description: [
        `Dr. Doug Corrigan:`,
        `Hands down, the importance of this observation eclipses all others.`,
        `This is more disastrous than myocarditis, blood clots, strokes, or cancer. Why?`,
        `Because this spells a lowering of the birth rate, and a lowering of the birth rate spells population collapse —> end of humanity.`,
        `<b>Peer reviewed research conclusively shows a significant and prolonged decline in sperm concentration and motility following the jab.</b>`,
        `It is statistically certain that sperm function has declined at 75-150 days post jab. Even after 150 days, it is statistically doubtful that sperm function has recovered at all. Just look at the data in their paper.`,
        `They need to perform a complete DNA sequence of the genetic material extracted from post-jab sperm and show absence of spike DNA integration.`,
        `in any event, the theory that this is being used as a means of population reduction is gaining more credibility.`,
        `Keep in mind that this data is only for 2 jabs. They do not look at the case of ongoing boosters, which is most certainly guaranteed to perpetuate loss of sperm function ad infinitum.`,
        `This is one potential mechanism that would account for loss of sperm function: "we know ACE2 is highly expressed in human testes and epididymis, in particular in Leydig cells, Sertoli cells, and spermatogonia. Does the vaccine affect male fertility? We don't know. It hasn't been tested."`,
        `It's amazing what you can learn about the long term safety of a jab when you do absolutely no long term safety studies and use billions of people as your Guinea pig for 18 months. Just make sure to tell the Guinea pigs that it's absolutely safe and you're immune from liability.`,
        `---`,
        `<b>Coronavirus Disease-19 Infection: Implications on Male Fertility and Reproduction</b>`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1627059107789434881`,
        `https://twitter.com/ScienceWDrDoug/status/1538590221847207937`,
        `https://onlinelibrary.wiley.com/doi/abs/10.1111/andr.13209`,
        `https://twitter.com/ScienceWDrDoug/status/1440883054822760448`,
        `https://www.frontiersin.org/articles/10.3389/fphys.2020.574761/full`,
      ],
    },
    {
      description: [
        `Florida Surgeon General issued an official “Health Alert on mRNA COVID Vaccine Safety”:`,
        `“In Florida alone there was a 1,700% increase in VAERS reports after the release of the COVID vaccine. The reporting of life-threatening conditions increased over 4,400%.”`,
      ],
      urls: [
        `https://twitter.com/DrEliDavid/status/1626312589939548160`,
        `https://www.floridahealth.gov/newsroom/2023/02/20230215-updated-health-alert.pr.html`,
      ],
    },
    {
      description: [
        `<b>Emergence of a New Creutzfeldt-Jakob Disease: 26 Cases of the Human Version of Mad-Cow Disease, Days After a COVID-19 Injection</b>`,
        `<b>Dr. Doug Corrigan:</b>`,
        `A new rapidly progressing human version of mad cow disease and subsequent death in 26 patients is directly correlated with the jab in this newly published paper. `,
        `“we highlight the presence of a prion region in the spike protein of the original SARS-CoV-2, and in all the “vaccine” variants built from the Wuhan virus”`,
        `“The prion region in the spike of SARS-CoV-2 has a density of mutations eight times greater than that of the rest of the spike, and, yet, strangely that entire prion region disappears completely in the Omicron variant.”`,
        `“we present 26 cases of Creuzfeldt-Jacob Disease, all diagnosed in 2021 with the first symptoms appearing within an average of 11.38 days after a Pfizer, Moderna, or AstraZeneca COVID-19 injection.”`,
        `“we hope to work out the etiopathogenesis critical to understanding this new and much more rapidly developing human prion disease.”`,
        `“..we believe it is correct to infer that the injections caused the disease in these 26 cases. If so, they have probably also caused a many other cases that have gone undiagnosed because of their rapid progression to death. ”`,
        `“By late 2021, 20 had died within 4.76 months of the offending injection. Of those, 8 died suddenly within 2.5 months confirming the rapid progression of this accelerated form of Creuzfeldt-Jacob Disease.”`,
        `“By June 2022, 5 more patients had died, and at the time of this current writing, only 1 remains still alive.”`,
      ],
      urls: [
        `https://twitter.com/ScienceWDrDoug/status/1618098168763420673`
      ],
    },
    {
      description: [
        `Quote from the Saskatchewan Health Authority:`,
        `"COVID-19 vaccines are still relatively new, and despite the high volumnes of administered doses, long-term side effects are still unknown at this time"`,
      ],
      urls: [
        `https://twitter.com/xoxobala/status/1528375635588128769/photo/1`
      ],
    },
    {
      description: [
        `<b>HYDROXYCHLOROQUINE WAS SAVING LIVES WHEN THEY BANNED IT</b>`,
        `Just released retrospective study from France (30,000+ patients) has found that HCQ + Azithromycin was REDUCING  DEATHS  by 71% when given early and by 45% when given in hospital - before they banned it.`,
        `And we known,  that was essential to get to  obtain  emergency use authorisation for the experimental shots.`,
        `If the world had followed the science on HCQ,  there  would have been no need for lockdowns, no economic damage, no printing of money, a  huge  reduction  in  covid deaths and  no  vaccine  injuries.`,
        `Further those that fabricated studies to  discredit HCQ  (Surgishere)  and those that ran studies designed  to  fail  (Oxford) by  overdosing  -  SHOULD  BE  PLACED  UNDER  ARREST`,
        `---`,
        `<b>Early Treatment with Hydroxychloroquine and Azithromycin: A "Real-Life" Monocentric Retrospective Cohort Study of 30,423 COVID-19 Patients</b>`,
      ],
      urls: [
        `https://twitter.com/CKellyUAP/status/1645201209287143424`,
        `https://www.medrxiv.org/content/10.1101/2023.04.03.23287649v1`,
      ],
    },
    {
      description: [
        `Toronto Public Health getting ready to vaccinate an age group for which vaccines are still unapproved, when COVID largely unaffects that group, so that already-vaccinated adults can feel more comfortable. Got it.`,
        `---`,
        `City of Toronto:`,
        `Toronto Public Health gets ready to vaccinate Toronto children 5 to 11 years of age against COVID-19.`,
      ],
      urls: [
        `https://twitter.com/rpoconnor/status/1442505700199047168`,
        `https://twitter.com/cityoftoronto/status/1442492472924459009`,
        `https://www.toronto.ca/news/toronto-public-health-gets-ready-to-vaccinate-toronto-children-5-to-11-years-of-age-against-covid-19/`,
      ],
    },
  ],
};
