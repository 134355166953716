import { Chapter } from '../models';
import { links } from './links';

export const chapter: {[key: string]: Chapter} = {
  AbuseOfChildren: {
    id: 'abuse-of-children',
    title: 'Child Abuse',
    links: links.AbuseOfChildren
  },
  AdverseEffectsGenerally: {
    id: 'general-adverse-covid-vaccine-effects',
    title: 'Adverse Covid Vaccine Effects',
    links: links.AdverseEffectsGenerally
  },
  AdverseEffectsInChildren: {
    id: 'adverse-covid-vaccine-effects-in-children',
    title: 'Adverse Covid Vaccine Effects in Children',
    links: links.AdverseEffectsInChildren
  },
  AlbertasOwnData: {
    id: 'albertas-own-data',
    title: 'Alberta\'s Own Data',
    links: links.AlbertasOwnData,
    intro: [
      `<b>Data collected from Alberta's own website shows total Covid-19 deaths are comparable with past influenza seasons, with children being significantly less prone to severe outcomes.</b>`,
      `As of December 2021, in Alberta, a province with a population of about 4,400,000 people, for the entire duration of the pandemic, there has been only <b>1 death under the age of 10</b> "related" to covid, and including this single death, only <b>2 deaths under the age of 20</b>.`,
      `Comparing hospital admissions, ICU admissions, and death counts per 100,000 people, between recent past influenza seasons and Covid-19 data, we see children are much more likely to die of the flu than covid, and adults between the ages of 20 and 65 show little difference in mortality rate from past flu seasons.`,
      `Albertans <b>under 50 years old</b> account for only <b>4%</b> of all covid-related deaths, while seniors <b>above 70 years old</b> account for <b>over 75%</b>.
      <br/>Alberta's life expectancy is between 75 and 81 years old, averaging around 78 for both women and men.
      <br/>Over <b>52%</b> of covid-related deaths occurred in Albertans <b>over 80 years old</b>, which means <b>over half</b> of all reported covid-related deaths in Alberta occurred in people <b>older than the average life expectancy</b>.`,
      
    ]
  },
  CanadianGovernment: {
    id: 'canadian-government',
    title: 'The Canadian Government',
    links: links.CanadianGovernment
  },
  CanadianTruckerConvoy: {
    id: 'canadian-trucker-convoy-is-peaceful',
    title: 'The Canadian Trucker Convoy is Peaceful, Not Racist',
    links: links.CanadianTruckerConvoy
  },
  Censorship: {
    id: 'censorship',
    title: 'Censorship',
    links: links.Censorship
  },
  DigitalId: {
    id: 'digital-id',
    title: 'Digital ID',
    links: links.DigitalId
  },
  Disinformation: {
    id: 'disinformation-count-inflation',
    title: 'Disinformation / Count Inflation',
    links: links.Disinformation
  },
  Documentaries: {
    id: 'documentaries',
    title: 'Documentaries',
    links: links.Documentaries
  },
  Economy: {
    id: 'economy-market',
    title: 'Economy / Market',
    links: links.Economy
  },
  EmergenciesAct: {
    id: 'emergencies-act',
    title: 'Emergencies Act',
    links: links.EmergenciesAct
  },
  FollowTheMoney: {
    id: 'follow-the-money',
    title: 'Follow the Money',
    links: links.FollowTheMoney
  },
  FoodSupplyChain: {
    id: 'food-supply-chain-crisis',
    title: 'Food / Supply Chain Crisis',
    links: links.FoodSupplyChain
  },
  GlobalProtests: {
    id: 'global-protests',
    title: 'Global Protests',
    links: links.GlobalProtests
  },
  GovernmentOverreach: {
    id: 'overreach',
    title: 'Overreach',
    links: links.GovernmentOverreach
  },
  GrandJury: {
    id: 'grand-jury',
    title: 'Grand Jury',
    links: links.GrandJury,
    intro: [
      `<strong>A group of international lawyers and a judge are conducting a criminal investigation modelled after the United States Grand Jury proceedings</strong> in order to present to the public all available evidence of COVID-19 Crimes Against Humanity to date against "leaders, organizers, instigators and accomplices" who aided, abetted or actively participated in the formulation and execution of a common plan for a pandemic.`
    ]
  },
  Discussions: {
    id: 'discussions',
    title: 'Discussions',
    links: links.Discussions
  },
  LegalCases: {
    id: 'legal-cases-decisions',
    title: 'Legal Cases / Decisions',
    links: links.LegalCases
  },
  Lockdowns: {
    id: 'lockdowns-are-ineffective-and-damaging',
    title: 'Lockdowns are Ineffective and Damaging',
    links: links.Lockdowns
  },
  Mandates: {
    id: 'mandates-passports',
    title: 'Mandates / Passports',
    links: links.Mandates
  },
  Masks: {
    id: 'masks-are-ineffective-and-damaging',
    title: 'Masks are Ineffective and Damaging',
    links: links.Masks
  },
  MedicallyAssistedDeath: {
    id: 'medically-assisted-death',
    title: 'Medically Assisted Death',
    links: links.MedicallyAssistedDeath
  },
  ModernDayConcentrationCamps: {
    id: 'modern-day-concentration-camps',
    title: 'Modern Day Concentration Camps',
    links: links.ModernDayConcentrationCamps
  },
  PastParallels: {
    id: 'past-parallels',
    title: 'Past Parallels',
    links: links.PastParallels
  },
  Politics: {
    id: 'politics',
    title: 'Politics',
    links: links.Politics
  },
  ProfessionalsSpeakingOut: {
    id: 'professionals-speaking-out',
    title: 'Professionals Speaking Out',
    links: links.ProfessionalsSpeakingOut
  },
  RussiaUkraine: {
    id: 'russia-ukraine',
    title: 'Russia / Ukraine',
    links: links.RussiaUkraine
  },
  RightsFreedoms: {
    id: 'rights-freedoms',
    title: 'Rights and Freedoms',
    links: links.RightsFreedoms
  },
  SchoolBoards: {
    id: 'school-boards',
    title: 'School Board Meetings',
    links: links.SchoolBoards
  },
  SocialDivision: {
    id: 'social-division',
    title: 'Social Division',
    links: links.SocialDivision
  },
  SocialEngineering: {
    id: 'social-engineering',
    title: 'Social Engineering',
    links: links.SocialEngineering
  },
  SubstandardTrials: {
    id: 'substandard-vaccine-trials',
    title: 'Substandard Vaccine Trials',
    links: links.SubstandardTrials
  },
  Vaccines: {
    id: 'covid-vaccines-are-ineffective-and-damaging',
    title: 'Covid Vaccines are Ineffective and Damaging',
    links: links.Vaccines
  },
};
