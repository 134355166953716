import { FC } from 'react';
import { Route } from "wouter";
import { C19 } from './pages/c19/C19';

const App: FC = () => (
  <>
    <Route path="/">
      <table className="ab-table"><tr><td><a href="/c19">c19</a></td></tr></table>
    </Route>
    <Route path="/c19" component={C19} />
  </>
);

export default App;
