import { ChapterGroup } from '../models';
import { chapter } from './chapter';

export const contents: ChapterGroup[] = [
  {
    id: 'overwhelmed',
    title: 'If Nothing Else',
    chapters: [
      chapter.GrandJury,
      chapter.Discussions,
      chapter.Documentaries,
      chapter.AlbertasOwnData,
    ]
  },
  {
    id: 'direct-effects',
    title: 'Direct Effects',
    chapters: [
      chapter.AbuseOfChildren,
      chapter.AdverseEffectsInChildren,
      chapter.AdverseEffectsGenerally,
    ]
  },
  {
    id: 'not-as-advertised',
    title: 'Not as Advertised',
    chapters: [
      chapter.Disinformation,
      chapter.Masks,
      chapter.Lockdowns,
      chapter.Vaccines,
      chapter.SubstandardTrials,
    ]
  },
  {
    id: 'control-grid',
    title: 'Control Grid',
    chapters: [
      chapter.Mandates,
      chapter.GovernmentOverreach,
      chapter.DigitalId,
      chapter.MedicallyAssistedDeath,
      chapter.SocialDivision,
      chapter.SocialEngineering,
      chapter.Censorship,
      chapter.FollowTheMoney,
      chapter.PastParallels,
      chapter.ModernDayConcentrationCamps,
    ]
  },
  {
    id: 'will-not-be-televised',
    title: 'Will Not Be Televised',
    chapters: [
      chapter.CanadianGovernment,
      chapter.CanadianTruckerConvoy,
      chapter.EmergenciesAct,
      chapter.GlobalProtests,
    ]
  },
  {
    id: 'legal-reality',
    title: 'Legal Reality',
    chapters: [
      chapter.RightsFreedoms,
      chapter.LegalCases,
      chapter.ProfessionalsSpeakingOut,
      // chapter.SchoolBoards,
    ]
  },
  {
    id: 'surrounding-environment',
    title: 'Surrounding Environment',
    chapters: [
      chapter.Economy,
      chapter.FoodSupplyChain,
      // chapter.Politics,
      // chapter.RussiaUkraine,
    ]
  }
];
