import { ChapterView } from './components/ChapterView';
import { ContentsLink } from './components/ContentsLink';
import { Contents } from './components/Contents';
import { contents } from './data';

export const C19 = () => (
  <div className="page-container">
    <ContentsLink />
    <Contents />
    {contents.map(({ chapters }) => chapters.map((chapter, i) => <ChapterView {...chapter} key={`chapter_${i}`} />))}
  </div>
);
