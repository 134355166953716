import { links, chapter } from '../data';

const chapterUrlCount: number = Object.keys(links)
  .map(key => links[key].map(link => link.urls && link.urls?.length ? link.urls.length : 0))
  .map(groupCount => groupCount.length ? groupCount.reduce((a, b) => a + b) : 0)
  .reduce((a, b) => a + b);
const chapterTopicCount: number = Object.keys(links).map(key => links[key].length).reduce((a, b) => a + b);
const chapterCount: number = Object.keys(chapter).length;

export const Stats = () => (
  <ul className="stats-container">
    <li>
      <h3>Chapters</h3>
      <h4>{chapterCount}</h4>
    </li>
    <li>
      <h3>Topics</h3>
      <h4>{chapterTopicCount}</h4>
    </li>
    <li>
      <h3>Urls</h3>
      <h4>{chapterUrlCount}</h4>
    </li>
  </ul>
);