import { FC } from 'react';
import { Chapter } from '../models';

export const ChapterView: FC<Chapter> = ({ id, title, links, intro }) => (
  <div id={id}>
    <h2><a href={`#${id}`}>{title}</a></h2>
    {intro?.length && (
      <div className="intro">
        {intro.map((p, j) => <p dangerouslySetInnerHTML={{ __html: p }} key={`p_${j}`} />)}
      </div>
    )}
    <ul>
      {links.map(({ description, urls }, i) => (
        <li key={`link_${i}`}>
          {description.map((p, j) => <p dangerouslySetInnerHTML={{ __html: p }} key={`p_${j}`} />)}
          {urls?.map((a, k) => <a href={a} target="_blank" rel="noreferrer" key={`a_${k}`}>{a}</a>)}
        </li>
      ))}
    </ul>
  </div>
);